import React, { useState, useEffect } from "react";
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Typography from "@material-ui/core/Typography";
import toastr from "toastr";
import { SpinnerCircular } from "spinners-react";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import InputLabel from "@material-ui/core/InputLabel";
import { useTranslation } from "react-i18next";
import SettingsIcon from '@material-ui/icons/Settings';

import { makeStyles } from "@material-ui/core/styles";
import FormControl from "@material-ui/core/FormControl";

/********Services********* */
import GeneralService from "../../Services/GeneralService";

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
  },
  margin: {
    margin: theme.spacing(1),
  },
  withoutLabel: {
    marginTop: theme.spacing(3),
  },
  formControl: {
    width: "100%",
    marginTop: "1rem",
  },
}));

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

export default function MetricPreference(props) {
  const [open, setOpen] = useState(false);
  const { t, i18n } = useTranslation();
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [langError, setLangError] = useState(false);
  const [isEmailNotificationEnabledError, setIsEmailNotificationEnabledError] =
    useState(false);

  /************General functions********** */
  const [values, setValues] = useState({
    lang: "es",
    isEmailNotificationEnabled: true,
  });

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    props.handleClose();
    setOpen(false);
  };

  useEffect(() => {
    setLoading(true);
    GeneralService.getPreferences()
      .then((response) => {
        if (response.data) {
          setValues({
            lang: response.data.lang ? response.data.lang : "es",
            isEmailNotificationEnabled: response.data.isEmailNotificationEnabled
              ? response.data.isEmailNotificationEnabled
              : false,
          });
        }

        setLoading(false);
      })
      .catch(() => {
        toastr.error(
          t(
            "admin.header-dropdown-dialog-get-preference-error",
            "There was an error getting the preferences"
          )
        );
        setLoading(false);
      });
    // eslint-disable-next-line
  }, []);

  const handleChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
  };

  const onFormSubmit = () => {
    setLangError(false);
    setIsEmailNotificationEnabledError(false);

    if (values.lang === "") {
      setLangError(true);
      toastr.error(
        t(
          "admin.header-dropdown-dialog-lang-message",
          "The language is required"
        )
      );
      return;
    }

    if (!props.type) {
      if (values.isEmailNotificationEnabled === "") {
        setIsEmailNotificationEnabledError(true);
        toastr.error(
          t(
            "admin.header-dropdown-dialog-notification-message",
            "The notification is required"
          )
        );
        return;
      }
    }

    setLoading(true);

    var json;

    if (!props.type) {
      json = {
        lang: values.lang,
        isEmailNotificationEnabled: values.isEmailNotificationEnabled,
      };
    } else {
      json = {
        lang: values.lang,
      };
    }
    GeneralService.savePreferences({ ...json })
      .then((response) => {
        i18n.changeLanguage(response.data.lang ? response.data.lang : "es");
        toastr.success(
          t(
            "admin.header-dropdown-dialog-preference-success",
            "Preferences saved successfully"
          )
        );
        setLoading(false);
        handleClose();
      })
      .catch(() => {
        toastr.error(
          t(
            "admin.header-dropdown-dialog-preference-error",
            "An error occurred while trying to save the preferences"
          )
        );
        setLoading(false);
      });
  };

  return (
    <div>
      <div className="p-0 m-0" onClick={handleClickOpen}>
        <SettingsIcon className="icon-menu-item" /> {t("admin.header-dropdown-dialog-preference-title", "Preferences")}
      </div>

      <Dialog
        maxWidth="sm"
        fullWidth={true}
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <DialogTitle id="customized-dialog-title" onClose={handleClose}>
          {t("admin.header-dropdown-dialog-preference-title", "Preferences")}
        </DialogTitle>
        <DialogContent dividers>
          {loading && (
            <div className="div-spinner-modal">
              <Container maxWidth="lg" className={classes.container}>
                <Grid container spacing={1}>
                  <SpinnerCircular size={90} />
                </Grid>
              </Container>
            </div>
          )}

          {!loading && (
            <>
              <FormControl variant="outlined" className={classes.formControl}>
                <InputLabel id="lang-select-outlined-label">
                  {t("admin.header-dropdown-dialog-lang-input", "Language")}
                </InputLabel>
                <Select
                  labelId="group-mutiple-name-label"
                  error={langError}
                  id="lang"
                  name="lang"
                  label={t(
                    "admin.header-dropdown-dialog-lang-input",
                    "Language"
                  )}
                  value={values.lang}
                  onChange={handleChange("lang")}
                >
                  <MenuItem value={"es"}>
                    {t(
                      "admin.header-dropdown-dialog-lang-input-item-spanish",
                      "Spanish"
                    )}
                  </MenuItem>
                  <MenuItem value={"en"}>
                    {t(
                      "admin.header-dropdown-dialog-lang-input-item-english",
                      "English"
                    )}
                  </MenuItem>

                  <MenuItem value={"po"}>
                    {t(
                      "admin.header-dropdown-dialog-lang-input-item-portuguese",
                      "Portuguese"
                    )}
                  </MenuItem>

                  <MenuItem value={"fr"}>
                    {t(
                      "admin.header-dropdown-dialog-lang-input-item-french",
                      "French"
                    )}
                  </MenuItem>

                  <MenuItem value={"it"}>
                    {t(
                      "admin.header-dropdown-dialog-lang-input-item-italian",
                      "Italian"
                    )}
                  </MenuItem>

                  <MenuItem value={"ja"}>
                    {t(
                      "admin.header-dropdown-dialog-lang-input-item-japanese",
                      "Japanese"
                    )}
                  </MenuItem>
                </Select>
              </FormControl>

              {!props.type && (
                <FormControl variant="outlined" className={classes.formControl}>
                  <InputLabel id="isEmailNotificationEnabled-select-outlined-label">
                    {t(
                      "admin.header-dropdown-dialog-notifications-input",
                      "Enable notifications"
                    )}
                  </InputLabel>
                  <Select
                    labelId="group-mutiple-name-label"
                    error={isEmailNotificationEnabledError}
                    id="isEmailNotificationEnabled"
                    name="isEmailNotificationEnabled"
                    label={t(
                      "admin.header-dropdown-dialog-notifications-input",
                      "Enable notifications"
                    )}
                    value={values.isEmailNotificationEnabled}
                    onChange={handleChange("isEmailNotificationEnabled")}
                  >
                    <MenuItem value={true}>
                      {t(
                        "admin.header-dropdown-dialog-notifications-input-item-yes",
                        "Yes"
                      )}
                    </MenuItem>
                    <MenuItem value={false}>
                      {t(
                        "admin.header-dropdown-dialog-notifications-input-item-no",
                        "No"
                      )}
                    </MenuItem>
                  </Select>
                </FormControl>
              )}
            </>
          )}
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleClose} color="secondary">
            {t("admin.header-dropdown-dialog-actions-cancel", "Cancel")}
          </Button>
          <Button autoFocus color="primary" onClick={onFormSubmit}>
            {t("admin.header-dropdown-dialog-actions-save", "Save")}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
