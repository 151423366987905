import React from "react";
import MenuItem from "../../components/MenuItem";
import { useTranslation } from "react-i18next";
import environment from "../../libs/environment";

import "./styles.scss";

const SideMenu = (props) => {
  const { t } = useTranslation();

  return (
    <div
      className={
        "side-menu-wrapper " +
        (localStorage.getItem("sesion") &&
        JSON.parse(localStorage.getItem("sesion")).souceType === "TALKDESK"
          ? "side-menu-wrapper-custom"
          : "")
      }
    >
      <MenuItem
        name={t("menu.rigth-list-item-home", "Home")}
        link={"panel"}
        src={`${environment.motivarnosBackend}/file/download/home.svg?pathName=img`}
        icon={`${environment.motivarnosBackend}/file/download/home.svg?pathName=img`}
        key={"Dashboard"}
      />

      {props.options.map((data, index) => {

        return (
          <MenuItem
            name={
              data.name === "Trivias"
                ? t("menu.rigth-list-item-trivia", "Trivia")
                : data.name === "menu.admin.letter.soup" || data.name === "Sopa de letras"
                ? t("menu.rigth-list-item-lettersoup", "Sopa de letras")
                : data.name === "Badges" || data.name === "Insignias"
                ? t("menu.rigth-list-item-badges", "Badges")
                : data.name === "Metrics" || data.name === "Métricas"
                ? t("menu.rigth-list-item-metrics", "Metrics")
                : data.name === "Challenges" || data.name === "Desafíos"
                ? t("menu.rigth-list-item-challenges", "Challenges")
                : data.name === "Missions" || data.name === "Misiones"
                ? t("menu.rigth-list-item-missions", "Missions")
                : data.name === "Bets" || data.name === "Apuestas"
                ? t("menu.rigth-list-item-bets", "Bets")
                : data.name === "Tournaments" || data.name === "Torneos"
                ? t("menu.rigth-list-item-tournaments", "Tournaments")
                : data.name === "Awards" || data.name === "Premios"
                ? t("menu.rigth-list-item-awards", "Awards")
                : data.name === "Ranking"
                ? t("menu.award-category", "Award")
                : data.link === "/hall-fame"
                ? t("hall-of-fame", "Hall of Fame")
                : data.link === "/coaching"
                ? t("coaching", "Coaching")
                : data.link === "/social"
                ? t("social", "Social")
                : ""
            }
            link={data.link.substring(1)}
            src={environment.motivarnosBackend + data.image}
            icon={data.image}
            key={index}
          />
        );
      })}
    </div>
  );
};

SideMenu.defaultProps = {
  options: [],
};

export default SideMenu;
