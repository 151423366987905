import React from "react";
import { AutoRotatingCarousel } from "material-auto-rotating-carousel";
import { Slide } from "material-auto-rotating-carousel";
const Button = require("@material-ui/core/Button").default;
export default function CarouselKeys() {
  const [state, setState] = React.useState(false);

  return (
    <div
      style={{
        position: "relative",
        width: "100%",
        height: "100%",
        display: "flex",
      }}
    >
      <Button
        variant="outlined"
        color="primary"
        onClick={() => setState({ open: true })}
      >
        Detalle
      </Button>

      <AutoRotatingCarousel
        label="Cerrar"
        open={state.open}
        onClose={() => setState({ open: false })}
        onStart={() => setState({ open: false })}
        style={{ position: "absolute" }}
      >
        <Slide
          media={
            <img src="http://www.icons101.com/icon_png/size_256/id_80975/GoogleInbox.png" />
          }
          title="José Ortega"
          subtitle="6000 puntos (Ganador)"
        />
        <Slide
          media={
            <img src="http://www.icons101.com/icon_png/size_256/id_76704/Google_Settings.png" />
          }
          title="Edith Blanco"
          subtitle="3000 (Perdedor)"
        />
      </AutoRotatingCarousel>
    </div>
  );
}
