import React, { useState, useEffect } from "react";
import { Document, Page, pdfjs } from "react-pdf";
import { Box, Grid, Button } from "@material-ui/core";

import { useLocation, useHistory } from "react-router-dom";
import { SpinnerCircular } from "spinners-react";
import ReactPlayer from "react-player/youtube";
import CircularProgress from "@material-ui/core/CircularProgress";
import { useTranslation } from "react-i18next";
import Tooltip from "@material-ui/core/Tooltip";
import LinkIcon from "@material-ui/icons/Link";
import toastr from "toastr";

import environment from "../../libs/environment";
import incorrecto from "../../assets/img/incorrecto.svg";
import correcto from "../../assets/img/correcto.svg";
import ganaste from "../../assets/img/ganaste.svg";

import MeetingRoomIcon from '@material-ui/icons/MeetingRoom';
import ForumIcon from '@material-ui/icons/Forum';
import TimerIcon from '@material-ui/icons/Timer';

import QuizzesServices from "../../Services/Quizzes/Global";
import PlayerQuizzesServices from "../../Services/Quizzes/Player";

import FileService from "components/app/admin/Services/FileService";

import "./styles.scss";
import Loader from "../../components/Loader";
import Dialog from "../../components/Dialog";
import Sparks from "../../components/Sparks";
import {
  DialogActions,
  DialogContent,
  DialogTitle
} from "../../components/app/admin/common/Dialogs/DialogStyled/DialogStyled";
import UploadButton from "./UploadButton";
import { calculateSize } from "../../libs/helpers";

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const MAX_FILE_SIZE = 1000000;

const Question = (props) => {
  const [answered, setAnswered] = useState(null);
  const [open, setOpen] = useState(false);
  const [viewed, setViewed] = useState(false);
  const [start, setStart] = useState(false);
  const [openConfirmUploadModal, setOpenConfirmUploadModal] = useState(false);
  const [uploadFile, setUploadFile] = useState(null);
  const [isUploadingFile, setIsUploadingFile] = useState(false);
  const [freeText, setFreeText] = useState("");
  const [optionSelected, setOptionSelected] = useState(null);
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);

  const [{
    props_id,
    data_id,
  }, setAnswerInfo] = useState({
    props_id: null,
    data_id: null,
  });

  const { t } = useTranslation();
  const tagTextAnswers = props.question.answers;

  const uploadAnswer = props.question.answers.find(answer => answer.isFileAnswer);

  useEffect(() => {
    setViewed(true);
    handleStart();
    // eslint-disable-next-line
  }, []);

  const handleOpen = () => {
    setOpen(!open);
  };

  const handleViewed = () => {
    setViewed(true);
  };

  const handleStart = () => {
    setStart(true);
  };

  const handleAnswer = (QuestionId, AnswerId) => {
    if (props.answering === false) {
      props.handleAnswer(QuestionId, { id: AnswerId });
      setAnswered(AnswerId);
    }
  };

  const handleUploadChange = (evt) => {
    let fileSelected = evt.target.files[0];

    if (fileSelected.type.split("/")[0] + "" === "image") {
      setOpenConfirmUploadModal(true);
      setUploadFile(fileSelected);
    }

  }

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }

  const handleOptionSelected = (id, props_id, data_id) => {
    setOptionSelected(id);
    setAnswerInfo({
      props_id: props_id,
      data_id: data_id,
    })
  }

  const handleCancelUploadFile = () => {
    setUploadFile(null);
    setOpenConfirmUploadModal(false);
  }

  const handleConfirmDialogUploadFile = (answerId) => {
    const MAX_WIDTH = 1200;
    const MAX_HEIGHT = 1200;
    const MIME_TYPE = "image/jpeg";
    const QUALITY = 0.7;
    setIsUploadingFile(true);

    const blobURL = window.URL.createObjectURL(uploadFile);

    const img = new Image();
    img.src = blobURL;

    img.onload = function () {
      const [newWidth, newHeight] = calculateSize(img, MAX_WIDTH, MAX_HEIGHT);
      const canvas = document.createElement("CANVAS");
      canvas.width = newWidth;
      canvas.height = newHeight;

      const ctx = canvas.getContext("2d");
      ctx.drawImage(img, 0, 0, newWidth, newHeight);
      canvas.toBlob(
        (blob) => {
          if (!blob || blob.size > MAX_FILE_SIZE) {
            toastr.error(
              t(
                "admin.mission-user-file-size-error",
                "File must be 3Mb size or less."
              )
            );
            return;
          }

          const formData = new FormData();
          formData.append("file", blob, uploadFile.name);
          FileService.uploadFile(formData, "trivia_answer")
            .then((response) => {
              const answerUrl = response.data.url;
              props.handleAnswer(props.id, { file: answerUrl, id: answerId });
            })

            .then(() => {
              setUploadFile(null)
              setOpenConfirmUploadModal(false)
            })
            .catch((error) => {
              toastr.error(t("admin.mission-message-error", "Fatal error."));
            })
            .finally(() => {
              setIsUploadingFile(false);
            });
        },
        MIME_TYPE,
        QUALITY
      );
    };
  }

  return (
    <>
      {start ? (
        <>
          <div className="question-wrapper">
            <div className="question card-model">
                <div className="number-question">
                {((props.openDialog === false && window.innerWidth < 400) || window.innerWidth > 400)  && (
                  <span className="icon-number-question">
                    {props.questionNumber+1}
                  </span>
                )}
                </div>
                {props.openDialog === false ? (
                  <>
                    <div className="question-title">
                      {props.question.text}
                    </div>
                    {(props.question.mediaType === "URL") && (
                      <Tooltip
                        title={
                          props.question.mediaType === "URL"
                            ? t(
                              "menu.trivia-panel-dialog-test-question-add-dialog-input-go-url",
                              "Go to link"
                            )
                            : ""
                        }
                      >
                        <a
                          target="_blank"
                          without
                          rel="noopener noreferrer"
                          href={`${environment.motivarnosBackend}${props.question.mediaLink}`}
                          className="d-flex"
                        >
                          <LinkIcon className="link-icon-pdf" />
                        </a>
                      </Tooltip>
                    )}
                    <div className="clue-container pointer" onClick={handleOpen}></div>
                  </> 
                ) : (
                  <div className="question-title question-type">
                    {props.verifyCorrect(props.answerData.selectedAnswers) === 1 ? (
                    
                      <>
                        <img src={correcto} alt="" />
                        <div className="response-message">YEAH! LO LOGRASTE</div>
                        <div className="message-title m-t-corret-colors">RESPUESTA CORRECTA</div>
                      </>
                    ) : (
                      <>
                        <img src={incorrecto} alt="" />
                        <div className="response-message">OOH! LO SENTIMOS</div>
                        <div className="message-title m-t-incorret-colors">RESPUESTA INCORRECTA</div>
                      </>
                    )}
                  </div>
                )}
            </div>
          </div>
          {props.openDialog === false ? (
            <>
              {(props.question.mediaType === "PDF") && (
                <Tooltip
                  title={
                    props.question.mediaType === "URL"
                      ? t(
                        "menu.trivia-panel-dialog-test-question-add-dialog-input-go-url",
                        "Go to link"
                      )
                      : ""
                  }
                >
                  <Box flex={true} flexDirection="column" className="mb-2">
                    <Box component="div" overflow="visible" height="100%">
                      <div className="d-flex mb-1">
                        {pageNumber !== 1 && (
                          <Button
                            variant="contained"
                            color="primary"
                            onClick={() => setPageNumber(pageNumber - 1)}
                          >
                            {t("previous-page.label", "Previous page")}
                          </Button>
                        )}
                        {pageNumber !== numPages && (
                          <Button
                            className="ml-1"
                            variant="contained"
                            color="primary"
                            onClick={() => setPageNumber(pageNumber + 1)}
                          >
                            {t("next-page.label")}
                          </Button>
                        )}
                      </div>

                      <div>
                        <Document
                          file={`${environment.motivarnosBackend}${props.question.mediaLink}`}
                          onLoadSuccess={onDocumentLoadSuccess}
                          loading={t("loading-page", "Loading page...")}
                        >
                          <Page width="600" pageNumber={pageNumber} />
                        </Document>
                        <p>
                          {pageNumber && numPages
                            ? t("page-counter-label") +
                            " " +
                            pageNumber +
                            " " +
                            t("page-counter-label-of", "of") +
                            " " +
                            numPages
                            : ""}
                        </p>
                      </div>
                    </Box>
                  </Box>
                </Tooltip>
              )}
              <div className="options-wrapper">
                <ul className="options" style={{justifyContent: tagTextAnswers.length % 2 == 0 ? 'center' : ''}}>
                  {
                    tagTextAnswers.map((data, index) => {
                      return (
                        <li
                          style={{width: tagTextAnswers.length % 2 == 0 ? '50%' : '30%'}}
                          className={
                            "option " +
                            (answered === data.id
                              ? props.typeResponse === "NOT_ANSWERED"
                                ? ""
                                : props.typeResponse === "INCORRECT"
                                  ? "option-incorrect"
                                  : "option-correct"
                              : "")
                          }
                          key={data.id}
                          onClick={() => handleOptionSelected(index, props.id, data.id)}

                        >
                          <div 
                            className="option-item" 
                            style={{
                              backgroundColor: optionSelected === index || optionSelected === null ? optionSelected === index ? '#661EC8' : '#000000' : '#EDEDED', 
                              marginLeft: index % 2 != 0 || tagTextAnswers.length % 2 != 0 ? '0.8rem' : '', 
                              marginRight: index % 2 == 0 || tagTextAnswers.length % 2 != 0 ? '0.8rem' : ''
                            }}
                          >
                            <div 
                              className={`option-item-inner ${(optionSelected === index || optionSelected === null )? 'option-item-inner-color' : 'option-item-inner-color-deselected'}`}
                              style={{borderColor: optionSelected === index || optionSelected === null ? optionSelected === index ? '#824ACF' : '#3E3E3E' : '#9D9D9D'}}
                            >
                              {data.text}
                            </div>
                          </div>
                        </li>
                      );
                    })
                  }
                </ul>
                <div className="footer-option">
                  <div class="option option-comprobar">
                    <div class="option-item button-comprobar" style={{backgroundColor: optionSelected === null ? '#EDEDED' : '#08AF7F'}}>
                      <div 
                        class="option-item-inner option-item-inner-color-deselected" 
                        style={{
                          borderColor: optionSelected === null ? '#9D9D9D' : '#0DCF97', 
                          cursor: optionSelected === null ? 'default' : 'pointer'
                        }}
                        onClick={() => handleAnswer(props_id, data_id)}
                      >
                        CONFIRMAR
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {
                uploadAnswer &&
                <UploadButton
                  onUpload={handleUploadChange}
                  uploadFile={uploadFile}
                >
                  Importa tu foto para responder
                </UploadButton>
              }
              {
                uploadAnswer &&
                <Dialog
                  maxWidth="sm"
                  fullWidth
                  onClose={handleCancelUploadFile}
                  aria-labelledby="customized-dialog-title"
                  open={openConfirmUploadModal}
                >

                  <DialogTitle id="customized-dialog-title" onClose={handleCancelUploadFile}>
                    Subir Archivo
                  </DialogTitle>

                  <DialogContent dividers>
                    <Grid container spacing={1}>
                      {
                        isUploadingFile ?
                          <span className="spinner-container">
                            <SpinnerCircular />
                          </span> :
                          <Grid item xs={12} md={12} lg={12}>
                            ¿Confirma la subida del archivo elegido ?
                          </Grid>
                      }
                    </Grid>
                  </DialogContent>
                  <DialogActions>
                    <Button onClick={handleCancelUploadFile} color="secondary">
                      {t("menu.trivia-panel-dialog-add-test-button-close", "Close")}
                    </Button>
                    <Button color="primary" onClick={() => handleConfirmDialogUploadFile(uploadAnswer.id)}>
                      {t("confirm.label", "Confirm")}
                    </Button>
                  </DialogActions>
                </Dialog>
              }
            </>
          ) : (
            <div className="options-wrapper">
              {(props.openDialog === true && props.verifyCorrect(props.answerData.selectedAnswers) !== 1) && (
                <>
                  <div className="correct-response-message">La respuesta correcta es: </div>
                  <ul className="options" style={{justifyContent: tagTextAnswers.length % 2 == 0 ? 'center' : ''}}>
                    {props.question.answers.filter((elem => elem.isCorrectAnswer)).map((answer, index) => (
                      <li
                        style={{width: tagTextAnswers.length % 2 == 0 ? '50%' : '30%'}}
                        className={"option"}
                        key={index}
                      >
                        <div 
                          className="option-item" 
                          style={{
                            cursor: 'auto',
                            backgroundColor: '#661EC8',
                            marginLeft: index % 2 != 0 || tagTextAnswers.length % 2 != 0 ? '0.8rem' : '', 
                            marginRight: index % 2 == 0 || tagTextAnswers.length % 2 != 0 ? '0.8rem' : ''
                          }}
                        >
                          <div 
                            className={`option-item-inner option-item-inner-color`}
                            style={{cursor: 'auto', borderColor: '#824ACF'}}
                          >
                            {answer.text}
                          </div>
                        </div>
                      </li>
                    ))}
                  </ul>
                </>
              )}

              <div className="footer-option">
                <div class="option option-comprobar">
                  <div class="option-item button-comprobar" style={{backgroundColor: '#0A5EFF'}}>
                    <div 
                      class="option-item-inner button-continuar option-item-inner-color-deselected" 
                      onClick={props.handleCloseDialog}
                    >
                      CONTINUAR
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </>
      ) : (
        <div className="video-container">
          {props.question.type === "video" ? (
            <ReactPlayer
              url={props.question.source}
              style={{ height: "auto", width: "100%" }}
              onEnded={handleViewed}
            />
          ) : (
            <div className="image-container">
              <img src={props.question.source} alt="" />
            </div>
          )}
          <Button
            onClick={handleStart}
            style={{ marginTop: "1em" }}
            disabled={!viewed}
          >
            Siguiente
          </Button>
        </div>
      )}
    </>
  );
};

const YoutubePlayer = ({videoSrc}) => {
  const srcToEmbed = src => {
    const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/;
    const match = src.match(regExp);

    return (match && match[2].length === 11)
      ? match[2]
      : null;
  }

  return (
      <iframe
        id="player"
        height="315"
        width="500"
        src={`https://www.youtube.com/embed/${srcToEmbed(videoSrc)}`}
        title="YouTube video player"
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
      ></iframe>
  )
}

const Quiz = () => {

  const location = useLocation();
  const history = useHistory();

  const URLinterface = new URLSearchParams(location.search);
  const id = URLinterface.get("id");
  const { t } = useTranslation();

  const [openDialog, setOpenDialog] = useState(false);
  const [data, setData] = useState(null);
  const [questions, setQuestions] = useState([]);
  const [IndexActive, setIndexActive] = useState(0);
  const [loading, setLoading] = useState(true);
  const [time, setTime] = useState(null);
  const [timeLimit, setTimeLimit] = useState(null);
  const [answerData, setAnswerData] = useState(null);
  const [answering, setAnswering] = useState(false);
  const [typeResponse, setTypeResponse] = useState("NOT_ANSWERED");
  const [triviaName, setTriviaName] = useState("");

  const handleOpenDialog = () => {
    setOpenDialog(!openDialog);
  };

  const handleCloseDialog = () => {
    nextQuestion();
    handleOpenDialog();
  };

  const handleAnswer = (questionId, answer) => {
    setAnswering(true);
    setLoading(true);

    PlayerQuizzesServices.answerQuestion({
      id: questionId + "",
      selectedAnswers: [{ ...answer }],
      isLast: IndexActive >= (data.questions.length-1),
    })
      .then((val) => {
        setAnswerData(val.data);
        if (val.data && val.data.selectedAnswers[0].state === "CORRECT") {
          let session = { ...JSON.parse(localStorage.getItem("sesion")) };
          let coinBalance =
            parseInt(session.user.coinBalance) + parseInt(val.data.totalPoints);
          session.user.coinBalance = coinBalance;
          localStorage.setItem("sesion", JSON.stringify({ ...session }));

          setTypeResponse("CORRECT");
        }
        if (val.data && val.data.state === "PENDING") setTypeResponse("PENDING");

        if (val.data && val.data.state === "INCORRECT") {
          setTypeResponse("INCORRECT");
        }

        handleOpenDialog();
        setLoading(false);
        setAnswering(false);
      })
      .catch(() => {
        setLoading(false);
        setAnswering(false);
      });
  };

  const handleFinish = (t,scoreId) => {
    if (t === 0) {
      //setLoading(true);
      QuizzesServices.expireTrivia(scoreId)
      .finally(()=>history.push("/quizzes"));
    }
  };

  var count;

  const startTimer = (dataId) => {
    count = setInterval((scoreId) => {
      setTime((time) => {
        handleFinish(time - 1, scoreId);
        return time - 1;
      });
    }, 1000, dataId);
  };

  const desordenarTrivias = (questions) => {

    let new_questions = questions;

    if(new_questions.length > 1) {
      new_questions = new_questions.sort(function(a,b) {return (Math.random()-0.5)});
    }

    new_questions.forEach((elem, index) => {
      new_questions[index].question.answer = elem.question.answers.sort(function(a,b) {return (Math.random()-0.5)});
    });

    return new_questions;
  }

  useEffect(() => {
    QuizzesServices.Apply({ trivia: { id: id } })
      .then((resp) => {
        setQuestions(desordenarTrivias(resp.data.questions))
        setData(resp.data);
        setLoading(false);
        setTime(resp.data.trivia.timeInSec)
        setTriviaName(resp.data.trivia.name)
        if(resp.data.trivia.timeInSec > 0) {
          startTimer(resp.data.id);
        }
      })
      .catch((error) => {
        if (error.response && error.response.status === 422) {
          toastr.error(
            t("trivia-answered", "This trivia has already been answered")
          );
        } else {
          toastr.error(
            t(
              "response-login-500.label",
              "There was an error, please try again later"
            )
          );
        }
        history.push("/quizzes");
      });

    return () => {
      window.clearInterval(count);
    };
    // eslint-disable-next-line
  }, []);

  const nextQuestion = () => {
    setTypeResponse("NOT_ANSWERED");
    setIndexActive(prevState => prevState + 1);
  };

  const verify = (question) => {
    if (question === undefined) {
      return;
    }
    if (question.selectedAnswers === null) return;
    if (question.selectedAnswers.length > 0) nextQuestion();
    if (question.question.answers.length === 0) nextQuestion();
  };

  const verifyCorrect = (data) => {
      console.group("verifyCorrect")
      for (let i = 0; i < data.length; i++) {
        console.log("correct answering??")
        
        if (data[i].state === "INCORRECT") {
          return -1;
        }

        if (data[i].state === "PENDING") {
          return 0;
        }
    }
    console.groupEnd("verifyCorrect")

    return 1;
  };

  const handleEndMessage = () => {
    handleFinish(0);
  };

  const handleCancel = () => {
    PlayerQuizzesServices.cancel({ id: data.id })
      .then((resp) => { 
        history.push("/quizzes");
      })
      .catch((error) => {
      });
  }

  if (loading)
    return (
      <span className="spinner-container">
        <SpinnerCircular />
      </span>
    );

  return (
    <div className="quiz-wrapper card-model">
      <div className="quiz-controls">
        <div className="trivia-name"><ForumIcon style={{marginRight: '10px'}}/>{triviaName.charAt(0).toUpperCase() + triviaName.slice(1)}</div>
        <div className="right-side">
          <div className="time-progress-bar-container">
            <div className="time-progress-bar">
              <div
                style={{
                  width: `${(IndexActive / questions.length) * 100}%`,
                  height: "2em",
                }}
                className="progress"
              >
              <strong style={{color: 'white'}}>{IndexActive !== 0 ? `${IndexActive} / ${questions.length}`: ''}</strong>
              </div>
            </div>
          </div>
          {time > 0 &&
            <div className="time-seconds-bar">
              <TimerIcon />{time + 's'}
            </div>
          }
          {data.trivia.time > 0 ? (
            <span className="time-counter-wrapper" style={{ display: "flex" }}>
              <span>{t("time-limit.label", "Time limit")}</span>
              {"" +
                (Math.trunc(time / 60) > 9
                  ? Math.trunc(time / 60)
                  : "0" + Math.trunc(time / 60)) +
                ":" +
                (Math.trunc(time % 60) > 9
                  ? Math.trunc(time % 60)
                  : "0" + Math.trunc(time % 60))}
              <span className="time-circular-progress-container">
                <CircularProgress
                  variant="determinate"
                  size={"6rem"}
                  color={"var(--warm-purple)"}
                  value={Math.trunc((time / timeLimit) * 100)}
                />
              </span>
            </span>
          ) : (
            ""
          )}
          <span className="exit-button-wrapper">
            <Button style={{ marginRight: "1em" }} onClick={handleCancel} >
              <MeetingRoomIcon fill="#CC3134"/>
            </Button>
          </span>
        </div>
      </div>

      {loading ? (
        <Loader />
      ) : questions.length > 0 ? (
        <>
          {questions[IndexActive] !== undefined
            ? verify(questions[IndexActive])
            : ""}
          {questions[IndexActive] !== undefined ? (
            <>
              {questions[IndexActive].question.mediaType ===
                "IMAGE_URL" && (
                  <img
                    className="image-custom-width mb-2"
                    src={`${environment.motivarnosBackend}${questions[IndexActive].question.hintMediaLink}`}
                    alt="Question"
                  />
                )}

              {questions[IndexActive].question.mediaType ===
                "VIMEO_VIDEO" && (
                  <YoutubePlayer videoSrc={questions[IndexActive].question.hintMediaLink} />
                )
              }
              <Question
                answerData={answerData}
                verifyCorrect={verifyCorrect}
                openDialog={openDialog}
                questionNumber={IndexActive}
                question={questions[IndexActive].question}
                id={questions[IndexActive].id}
                handleAnswer={handleAnswer}
                nextQuestion={nextQuestion}
                answering={answering}
                typeResponse={typeResponse}
                handleCloseDialog={handleCloseDialog}
              />
            </>
          ) : (
            <>
              <div className="question-wrapper">
                <div className="question card-model end-message">
                  <Sparks show={true} />
                  <div className="image-container">
                    <img src={ganaste} alt="" />
                  </div>
                  <h3>FELICIDADES HAS TERMINADO LA TRIVIA</h3>
                </div>
              </div>
              <div className="options-wrapper">
                <div className="footer-option">
                  <div class="option option-comprobar">
                    <div class="option-item button-comprobar" style={{backgroundColor: '#0A5EFF'}}>
                      <div 
                        class="option-item-inner option-item-inner-color-deselected" 
                        style={{
                          borderColor: '#002B67', 
                          cursor: 'pointer'
                        }}
                        onClick={handleEndMessage}
                      >
                        FINALIZAR
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}
        </>
      ) : (
        <>
          <div className="question-wrapper">
            <div className="question card-model end-message">
              <div className="image-container">
                <img src={incorrecto} alt="" />
              </div>
              <h3>NO HAY PREGUNTAS PARA RESPONDER</h3>
            </div>
          </div>
          <div className="options-wrapper">
            <div className="footer-option">
              <div class="option option-comprobar">
                <div class="option-item button-comprobar" style={{backgroundColor: '#0A5EFF'}}>
                  <div 
                    class="option-item-inner option-item-inner-color" 
                    style={{
                      borderColor: '#002B67', 
                      cursor: 'pointer'
                    }}
                    onClick={handleEndMessage}
                  >
                    FINALIZAR
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default Quiz;
