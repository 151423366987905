export const RESET_STATE = "RESET_STATE";
export const RESET_STORE = "RESET_STORE";

export const TOURNAMENTS_LIST_REQUEST = "TOURNAMENTS_LIST_REQUEST";
export const TOURNAMENTS_LIST_SAVED = "TOURNAMENTS_LIST_SAVED";

export const TOURNAMENTS_LIST_SAVE = "TOURNAMENTS_LIST_SAVE";
export const TOURNAMENTS_LIST_UPDATE = "TOURNAMENTS_LIST_UPDATE";
export const TOURNAMENTS_LIST_DELETE = "TOURNAMENTS_LIST_DELETE";

export const TOURNAMENTS_LIST_CHARGING = "TOURNAMENTS_LIST_CHARGING";
export const TOURNAMENTS_SAVE_CHARGING = "TOURNAMENTS_SAVE_CHARGING";

export const TOURNAMENTS_LIST_ERROR = "TOURNAMENTS_LIST_ERROR";
