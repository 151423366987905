import React from "react";

import "./styles.scss";
import avatar from "../../assets/img/user.svg";

const Avatar = (props) => {
  return (
    <div
      className={props.className ? `avatar-comp-wrapper ${props.className}` : 'avatar-comp-wrapper'}
      style={{ ...props.style, width: props.size, height: props.size }}
      ref={el => {
        if(el != null)  {
          el.style.setProperty('width', props.size, 'important');
          el.style.setProperty('height', props.size, 'important');
        }
      }}
    >
      {props.avatar !== null ? (
        <img className="user-avatar" src={props.avatar} alt="" />
      ) : (
        <img src={avatar} alt="" />
      )}
    </div>
  );
};

Avatar.defaultProps = {
  avatar: null,
};

export default Avatar;
