import React from "react";
import "./sparks.scss";

const Sparks = ({mission, show}) => {
	if (show) {
		if (mission) {
			return (
				<div>
					<div style={{left: "100px"}} className="firework-1"></div>
					<div style={{left: "50px"}} className="firework-2"></div>
					<div style={{left: "-100px"}} className="firework-3"></div>
					<div style={{left: "-200px"}} className="firework-4"></div>
				</div>
			)
		} else {
			return (
				<div>
					<div className="firework-1"></div>
					<div className="firework-2"></div>
					<div className="firework-3"></div>
					<div className="firework-4"></div>
					<div className="firework-5"></div>
					<div className="firework-6"></div>
					<div className="firework-7"></div>
					<div className="firework-8"></div>
					<div className="firework-9"></div>
					<div className="firework-10"></div>
					<div className="firework-11"></div>
					<div className="firework-12"></div>
					<div className="firework-13"></div>
					<div className="firework-14"></div>
					<div className="firework-15"></div>
					<div className="firework-16"></div>
					<div className="firework-17"></div>
					<div className="firework-18"></div>
				</div>
			)
		}
	} else {
		return null;
	}
}

export default Sparks;