import React, { useState, useEffect } from "react";
import TextField from "@material-ui/core/TextField/TextField";
import Button from "@material-ui/core/Button/Button";
import { login, loginIntegracion, loginVerifiedUser } from "./AuthenticationActions";
import { useDispatch, useSelector } from "react-redux";
import Loading from "../../common/controls/LoadingWindow";
import GoogleLoginButton from "./GoogleLoginButton";
import { FormattedMessage } from "react-intl";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import Container from "@material-ui/core/Container/Container";
import DialogTitle from "../../common/controls/DialogTitle";
import { Grid, Link, Paper } from '@material-ui/core'
import { textStyles, buttonStyles, inputStyles, labelStyles } from "../../styles/styles";
import Error from "../../common/errorHandler/ErrorHandler";
import RecoverPassword from "../recover-password/RecoverPassword";
import { Box } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import store from "store2";
import Logo from "../../../../assets/logos/logo.png";
import { openSignUp } from "../landing/store/LandingReducer";


let companyName = "";

const useReduxSelector = () =>
  useSelector((state) => ({
    isLoading: state.authReducer.isLoading,
    signupCompleted: state.authReducer.signupCompleted,
    error: state.authReducer.error,
  }));

const LoginLanding = ({ padding }) => {
  const dispatch = useDispatch();
  const classesText = textStyles();
  const classesLabel = labelStyles();
  const classesButton = buttonStyles();
  const classesInput = inputStyles();
  const { isLoading, error } = useReduxSelector();
  const [company, setCompany] = useState("");
  const [user, setUser] = useState("");
  const [password, setPassword] = useState("");
  const [openModal, setOpenModal] = useState(false);
  const [openRecoverPassword, setOpenRecoverPassword] = useState(false);
  const [reloadGoogleLogin, setReloadGoogleLogin] = useState(false);
  const { t } = useTranslation();

  const isValid = user && password && company;
  const loginHandler = () => {
    dispatch(
      login({
        company: company,
        username: user,
        password: password,
      })
    ).then((e) => {
      if (e && e.error) {
      } else {
        if (e === "OK") {
          window.location.reload();
        } else {
          if (e && e.termsAndConditions) {
            store("termsAndConditions", e.termsAndConditions);
          }
          store("responseLogin", e);
        }
      }
    });
  };

  const handleCompanyName = async (value) => {
    setCompany(value);
    companyName = value;
  }

  const loginGmailHandler = (data) => {
    dispatch(
      login({
        tokenId: data.credential,
        useremail: data.Is ? data.Is.ot : "",
        company: company,
        type: "gmail",
      })
    ).then((e) => {
      if (e && e.error) {
        setReloadGoogleLogin(true);
      } else {
        if (e === "OK") {
          window.location.reload();
        } else {
          if (e && e.termsAndConditions) {
            store("termsAndConditions", e.termsAndConditions);
          }
          store("responseLogin", e);
        }
        //window.location.reload();
      }
    });
  };

  const loginHandlerVerified = () => {
    const responseLogin = store("responseLogin");

    if (responseLogin) {
      dispatch(loginVerifiedUser({ ...responseLogin }));
    }
  };

  const loginReject = () => {
    store('termsAndConditions', null)
    setOpenModal(true)
  };

  const loginHandlerIntegracion = () => {
    dispatch(
      loginIntegracion({
        company: company,
        username: user,
        password: password,
        type: "talkdesk",
      })
    );
  };

  const GenesysHandler = () => {
    window.location.href = "https://appfoundry.genesys.com/filter/genesyscloud/listing/937b0061-6e82-4c62-8847-d2b279718adb";
  };

  const getError = (error) => {
    if (error && error.error) {
      if (error.error.messageCode === "auth.invalidusername") {
        return t("response-login-401.label", "Invalid username/password");
      } else if (error.error.messageCode === "No value present") {
        return t(
          "response-login-500-company.label",
          "Write the name of the company, please"
        );
      } else if (
        error.error.messageCode === "auth.gmail.multiple.user.no.company"
      ) {
        return t(
          "response-login-500-company.label",
          "Write the name of the company, please"
        );
      } else if (error.error.messageCode === "auth.no.web.login.allowed") {
        return t(
          "response-login-401-not-allowed.label",
          "Web login is not allowed"
        );
      } else {
        return t(
          "response-login-500.label",
          "There was an error, please try again later"
        );
      }
    } else {
      return t(
        "response-login-500.label",
        "There was an error, please try again later"
      );
    }
  };

  const paperStyle = {
    padding: padding === 0 ? padding : (padding !== 20) ? padding : 20,
    width: "50vh",
    margin: "0 auto",
    backgroundColor: "#fafafa",
    boxShadow: "None"
  }

  return (
    <>
      {isLoading && <Loading open={true}></Loading>}
        <Grid>
          <Paper style={paperStyle}>
            <DialogContent>
              <form onSubmit={loginHandler}>
                <Container
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    flexGrow: 1,
                    justifyContent: "center",
                  }}
                >
                  {!isLoading && error && (
                    <>
                      <Error
                        style={{ width: "17vw", alignSelf: "center" }}
                        message={getError(error)}
                      ></Error>
                    </>
                  )}´
                  <div style={{display: 'flex', justifyContent: 'center'}}>
                    <img src={Logo} alt="logo-header" style={{width:'20%'}} />
                  </div>
                  <div style={{display: 'flex', justifyContent: 'center'}}>
                    <span>¿No tienes una cuenta?
                    <Link style={{cursor: 'pointer', color: '#0C94B1', fontWeight: 'bold'}} onClick={() => dispatch(openSignUp())}> Registrate</Link>
                    </span>
                  </div>
                  <label className={classesLabel.label}>{t("company.label")}</label>
                  <TextField
                    value={company}
                    onChange={(e) => handleCompanyName(e.target.value)}
                    className={classesInput.textField}
                    style={{ marginBottom: 12, backgroundColor: "#F6F8FA" }}
                    variant="outlined"
                    fullWidth
                    size="small"
                  />
                  <label className={classesLabel.label}>{t("user.label")}</label>
                  <TextField
                    value={user}
                    onChange={(e) => setUser(e.target.value)}
                    className={classesInput.textField}
                    variant="outlined"
                    fullWidth
                    size="small"
                  />
                  <label className={classesLabel.label}>{t("password.label")}</label>
                  <TextField
                    type="password"
                    variant="outlined"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    className={classesInput.textField}
                    fullWidth
                    size="small"
                  />
                  <Link
                    onClick={(event) => {
                      setOpenRecoverPassword(true);
                      event.preventDefault();
                    }}
                    style={{
                      fontSize: "14px",
                      fontWeight: 'bold',
                      color: "#0C94B1",
                      marginTop: '5px',
                      cursor: 'pointer',
                      marginBottom: '14px'
                    }}
                  >
                    <FormattedMessage
                      id="login.recoverPassword"
                      defaultMessage={t("forgot-your-password.label")}
                    />
                  </Link>
                  <Button
                    type="submit"
                    disabled={!isValid}
                    onClick={loginHandler}
                    style={{
                      margin: "8px 0",
                      backgroundColor:  "#EAF0F6",
                      fontSize:  "1.3rem",
                      fontWeight: "bold",
                    }}
                    fullWidth
                  >
                    <FormattedMessage
                      id="login.confirmBtnz"
                      defaultMessage={t("confirm.label")}
                      className={classesText.textButton}
                      style={{ color: "#3508e7" }}
                    />
                  </Button>
                  <hr style={{
                    width: "100%",
                    border: "0.5px solid #EEEEEE",
                    marginTop: "30px",
                    marginBottom: "30px",
                  }}/>
                  <div style={{display: "flex", justifyContent: "space-between"}}>
                    <Button
                      onClick={GenesysHandler}
                      className={classesButton.buttonGenesys}
                    >
                      <FormattedMessage
                        id="login.genesys"
                        defaultMessage={"AppFoundry Listing"}
                      />
                    </Button>
                  <Button
                    disabled={!company}
                    onClick={loginHandlerIntegracion}
                    style={{ alignSelf: "center" }}
                    className={classesButton.buttonTalkdesk}
                    >
                    <FormattedMessage
                      id="login.talkdesk"
                      defaultMessage={t("talkdesk-login.label")}
                      className={classesText.textButtonButtonTalkdesk}
                      />
                  </Button>
                  </div>
                </Container>
              </form>
              <GoogleLoginButton
                reloadGoogleLogin={reloadGoogleLogin}
                shape="pill"
                size="large"
                text="continue_with"
                theme="outline"
                onSuccess={loginGmailHandler}
                company={company}
              />
            </DialogContent>
            { 
            <RecoverPassword
              open={openRecoverPassword}
              onClose={() => setOpenRecoverPassword(false)}
            ></RecoverPassword>
            }
        </Paper>
      </Grid>
      {store("termsAndConditions") !== null && (
        <>
          <Dialog open={openModal===false}>
            <DialogTitle
              style={{
                display: "flex",
                justifyContent: "center",
                paddingTop: "1em",
              }}
            >
              Terms & Conditions
            </DialogTitle>
            <DialogContent>
              <Box flex={true} flexDirection="column" className="mb-2">
                <Box component="div" overflow="visible" height="100%">
                  <div
                    dangerouslySetInnerHTML={{
                      __html: store("termsAndConditions"),
                    }}
                  ></div>

                  <div className="d-flex">
                    <Button
                      type="submit"
                      onClick={loginHandlerVerified}
                      style={{ alignSelf: "center", color: "#3508e7" }}
                      className={classesButton.buttonPurple}
                    >
                      <FormattedMessage
                        id="login.confirmBtn"
                        defaultMessage={t("confirm.label")}
                        className={classesText.textButton}
                        style={{ color: "#3508e7" }}
                      />
                    </Button>

                    <Button
                      type="submit"
                      onClick={loginReject}
                      style={{
                        alignSelf: "center",
                        color: "#e30733",
                        marginLeft: "1rem",
                      }}
                      className={classesButton.buttonRed}
                    >
                      <FormattedMessage
                        id="login.confirmBtn"
                        defaultMessage={t("reject.label", "Reject")}
                        className={classesText.textButton}
                        style={{ color: "#e30733" }}
                      />
                    </Button>
                  </div>
                </Box>
              </Box>
            </DialogContent>
          </Dialog>
        </>
      )}
    </>
  );
};

export default LoginLanding;
