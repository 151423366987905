import axios from "axios";
import environment from "../../../libs/environment";

export default {
  getMetric: (id1, id2) =>
    axios({
      method: "GET",
      url: `${environment.motivarnosBackend}/metricconf/userformatch?user.id_in=${id1},${id2}`,
    }),
  GetUsers: () =>
    axios({
      method: "GET",
      url: `${environment.motivarnosBackend}/user`,
    }),

  GetUsersNotOwn: () =>
    axios({
      method: "GET",
      url: `${environment.motivarnosBackend}/user?id_not=own`,
    }),
  CreateBet: (body) =>
    axios({
      method: "POST",
      url: `${environment.motivarnosBackend}/bet`,
      data: body,
    }),
  GetBets: () =>
    axios({
      method: "GET",
      url: `${environment.motivarnosBackend}/bet`,
    }),
  GetHistory: (condition) =>
    axios({
      method: "GET",
      url: `${environment.motivarnosBackend}/bet?isProcessed=` + condition,
    }),
  AcceptBet: (body) =>
    axios({
      method: "PUT",
      url: `${environment.motivarnosBackend}/bet/accept`,
      data: body,
    }),
  RejectBet: (body) =>
    axios({
      method: "PUT",
      url: `${environment.motivarnosBackend}/bet/reject`,
      data: body,
    }),
};
