import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import Dialog from "../Dialog";

import "./styles.scss";

import angry from "../../assets/emojis/angry-2.svg";
import sad from "../../assets/emojis/sad-2.svg";
import neutral from "../../assets/emojis/neutral-2.svg";
import smiley from "../../assets/emojis/smile-2.svg";
import stars from "../../assets/emojis/stars-2.svg";

import FeelServices from "../../Services/Feeling/Player";
import { SpinnerCircular } from "spinners-react";
import toastr from "toastr";

const FeelModal = (props) => {
  const [fetching, setFetching] = useState(false);
  const { t } = useTranslation();

  const handleFeel = (feeling) => {
    setFetching(true);
    FeelServices.SetFeeling({ feeling: feeling })
      .then((response) => {
        setFetching(false);
        props.onClose();
      })
      .catch((error) => {
        setFetching(false);
        toastr.error("Ha ocurrido un error.");
      });
  };

  return (
    <Dialog open={props.open} onClose={props.onClose}>
      {fetching ? (
        <span className="spinner-container">
          <SpinnerCircular />
        </span>
      ) : (
        <React.Fragment>
          <h3
            style={{
              marginBottom: "0",
              color: "var(--warm-purple)",
              textAlign: "center",
            }}
          >
            {JSON.parse(localStorage.getItem("sesion")) &&
            JSON.parse(localStorage.getItem("sesion")).company
              .feelingsPopupLabel
              ? JSON.parse(localStorage.getItem("sesion")).company
                  .feelingsPopupLabel
              : t("how-do-you-feel-today.label", "How do you feel today")}
          </h3>
          <div className="how-i-feel-wrapper">
            <span
              className="emoji-how-feel pointer"
              onClick={() => {
                handleFeel("ANGRY");
              }}
            >
              <img src={angry} alt="" />
            </span>
            <span
              className="emoji-how-feel pointer"
              onClick={() => {
                handleFeel("SAD");
              }}
            >
              <img src={sad} alt="" />
            </span>
            <span
              className="emoji-how-feel pointer"
              onClick={() => {
                handleFeel("NEUTRAL");
              }}
            >
              <img src={neutral} alt="" />
            </span>
            <span
              className="emoji-how-feel pointer"
              onClick={() => {
                handleFeel("FINE");
              }}
            >
              <img src={smiley} alt="" />
            </span>
            <span
              className="emoji-how-feel pointer"
              onClick={() => {
                handleFeel("HAPPY");
              }}
            >
              <img src={stars} alt="" />
            </span>
          </div>
        </React.Fragment>
      )}
    </Dialog>
  );
};

export default FeelModal;
