import axios from "axios";
import environment from "../../libs/environment";

export default {
  GetData: () =>
    axios({
      method: "GET",
      url: `${environment.motivarnosBackend}/dashboard`,
    }),

}