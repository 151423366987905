import * as betsTypes from "../types/betsTypes";

import BetsService from "../components/app/admin/Services/BetsService";

const {
  RESET_STATE,
  BETS_LIST_REQUEST,
  BETS_LIST_SAVED,
  BETS_LIST_CHARGING,
  BETS_LIST_ERROR,
} = betsTypes;

export const getBetsRequest = (payload) => async (dispatch, getState) => {
  try {
    const { pages } = getState().betsReducer;
    if (!pages.includes(payload)) {
      dispatch({
        type: BETS_LIST_CHARGING,
      });
      const responseLogin = await BetsService.getBets(payload, 999);
      dispatch({
        type: BETS_LIST_REQUEST,
        payload: { ...responseLogin.data, number: payload },
      });
    } else {
      dispatch({
        type: BETS_LIST_SAVED,
      });
    }
  } catch (error) {
    dispatch({
      type: BETS_LIST_ERROR,
      payload: error.response ? error.response.data : error,
    });
  }
};

export const resetState = () => async (dispatch) => {
  dispatch({
    type: RESET_STATE,
  });
};
