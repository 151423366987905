import React, { useEffect, useState } from "react";
import { SpinnerCircular } from "spinners-react";
import Grid from "@material-ui/core/Grid";
import Skeleton from "@material-ui/lab/Skeleton";

const SkeletonSocial = ({ numItems }) => {

    return (
        <>

            {
                Array(numItems).fill(7).map(() => (
                    <Grid item xs={12} lg={8} md={8}>
                        <div className="new-cart-wrapper mt-2" xs={12} lg={8} md={8}>

                            <div className="skeleton-social-content new-cart-content" >
                                <div
                                    className="skeleton-social-second-item"
                                >
                                    <Skeleton variant="circle" width={40} height={40} />
                                    <Skeleton variant="text" width={"100%"} />
                                </div>

                                <div
                                    className="skeleton-social-main-item"
                                >
                                    <Skeleton variant="rect" width={"100%"} height={210} />
                                </div>

                                <div
                                    className="skeleton-social-second-item"
                                >
                                    <Skeleton variant="circle" width={40} height={40} />
                                    <Skeleton variant="text" width={"100%"} />
                                </div>

                            </div>


                            <div className="new-cart-footer">
                                {/* notification vote icons */}

                                <div className="vote">

                                    <div className="pointer mr-05">
                                        <Skeleton className="icon-vote-skeleton" variant="circle" />
                                    </div>

                                    <div className="pointer mr-05" role="button">
                                        <Skeleton className="icon-vote-skeleton" variant="circle" />
                                    </div>

                                    <div className="pointer mr-05">
                                        <Skeleton className="icon-vote-skeleton" variant="circle" />
                                    </div>

                                </div>

                                {/* notification name */}
                                <h4 className="text-center">
                                    <Skeleton variant="text" width={100} />
                                </h4>

                                {/* Date */}
                                <span>
                                    <Skeleton variant="text" width={90} />
                                </span>

                            </div>

                        </div>
                    </Grid>
                ))
            }
        </>

    );
};

export default SkeletonSocial;
