import * as tournamentsTypes from "../types/tournamentsTypes";

import TournamentService from "../components/app/admin/Services/TournamentService";

const {
  RESET_STATE,
  TOURNAMENTS_LIST_REQUEST,
  TOURNAMENTS_LIST_SAVED,
  TOURNAMENTS_LIST_SAVE,
  TOURNAMENTS_LIST_UPDATE,
  TOURNAMENTS_LIST_DELETE,
  TOURNAMENTS_LIST_CHARGING,
  TOURNAMENTS_SAVE_CHARGING,
  TOURNAMENTS_LIST_ERROR,
} = tournamentsTypes;

export const getTournamentsRequest =
  (payload) => async (dispatch, getState) => {
    try {
      const { pages } = getState().tournamentsReducer;
      if (!pages.includes(payload)) {
        dispatch({
          type: TOURNAMENTS_LIST_CHARGING,
        });
        const responseLogin = await TournamentService.getTournament(
          payload,
          999
        );
        dispatch({
          type: TOURNAMENTS_LIST_REQUEST,
          payload: { ...responseLogin.data, number: payload },
        });
      } else {
        dispatch({
          type: TOURNAMENTS_LIST_SAVED,
        });
      }
    } catch (error) {
      dispatch({
        type: TOURNAMENTS_LIST_ERROR,
        payload: error.response ? error.response.data : error,
      });
    }
  };

export const saveTournamentRequest =
  (payload) => async (dispatch, getState) => {
    dispatch({
      type: TOURNAMENTS_SAVE_CHARGING,
    });

    try {
      const responseLogin = await TournamentService.createTournament(payload);
      const { tournaments } = getState().tournamentsReducer;
      const tournamentsUpdated = [responseLogin.data, ...tournaments];

      dispatch({
        type: TOURNAMENTS_LIST_SAVE,
        payload: tournamentsUpdated,
      });
      return "SUCCESS";
    } catch (error) {
      dispatch({
        type: TOURNAMENTS_LIST_ERROR,
        payload: error.response ? error.response.data : error,
      });
      return "ERROR";
    }
  };

export const updateTournamentRequest =
  (payload) => async (dispatch, getState) => {
    dispatch({
      type: TOURNAMENTS_SAVE_CHARGING,
    });

    try {
      await TournamentService.editTournament(payload);
      const { tournaments } = getState().tournamentsReducer;

      const tournamentsUpdated = [...tournaments];
      const findById = (test) => test.id === payload.id;
      const index = tournamentsUpdated.findIndex(findById);
      tournamentsUpdated[index] = {
        ...tournamentsUpdated[index],
        ...payload,
      };

      dispatch({
        type: TOURNAMENTS_LIST_UPDATE,
        payload: tournamentsUpdated,
      });
      return "SUCCESS";
    } catch (error) {
      dispatch({
        type: TOURNAMENTS_LIST_ERROR,
        payload: error.response ? error.response.data : error,
      });
      return "ERROR";
    }
  };

export const deleteTournamentRequest = (payload) => async (dispatch) => {
  dispatch({
    type: TOURNAMENTS_LIST_CHARGING,
  });

  try {
    await TournamentService.deleteTournament(payload);

    dispatch({
      type: TOURNAMENTS_LIST_DELETE,
      payload: payload,
    });
    return "SUCCESS";
  } catch (error) {
    dispatch({
      type: TOURNAMENTS_LIST_ERROR,
      payload: error.response ? error.response.data : error,
    });
    return { error: error.response };
  }
};

export const resetState = () => async (dispatch) => {
  dispatch({
    type: RESET_STATE,
  });
};
