import React, { useEffect, useState } from "react";

import { SpinnerCircular } from "spinners-react";

import PerformanceCart from "../../components/PerformanceCart";
import MetricServices from "../../Services/Metrics/Player";
import EmptyMessage from "../../components/EmptyMessage";

import "./styles.scss";

const Metrics = () => {
  const [metrics, setMetrics] = useState(null);
  const [metricsRelated, setMetricsRelated] = useState(null);

  useEffect(() => {
    const { user: { id }} = JSON.parse(localStorage.getItem("sesion"));
    MetricServices.getMetrics()
      .then((resp) => {
        setMetrics(resp.data.content);
      })
      .catch((error) => {});

    MetricServices.getCollaboratorsByLeaders(0, 100,'desc',id)
      .then((collaborators_response) => {
        if (collaborators_response.data.content.length > 0) {
          MetricServices.getMetricRelated()
          .then((metrics_response) => {
            const METRICS_FILTERED = [];
            metrics_response.data.content.forEach((elem) => {
              const INDEX = collaborators_response.data.content.findIndex((colaborador) => colaborador.id == elem.user.id)

              if(INDEX !== -1) {
                METRICS_FILTERED.push(metrics_response.data.content[INDEX]);
              }
            });

            setMetricsRelated(METRICS_FILTERED)

          })
          .catch((error) => {});
        }
      })
      .catch((error) => {});
  }, []);

  return (
    <div className="metrics-wrapper">
      <div className="metric-title" >METRICAS</div>
      {metrics == null ? (
        <span className="spinner-container">
          <SpinnerCircular />
        </span>
      ) : (
        <>
          {metrics.length === 0 ? (
            <EmptyMessage />
          ) : (
            <>
              {metrics.map((data, index) => (
                <PerformanceCart
                  key={index}
                  data={{
                    title: data.metricConf.name,
                    milestone: data.targetValue,
                    value: data.value,
                    percentage: data.percent,
                    id: data.id,
                  }}
                />
              ))}
            </>
          )}
        </>
      )}
      {metricsRelated != null && (
        <>
          {metricsRelated.length !== 0 && (
            <>
              {metricsRelated.map((data, index) => (
                <PerformanceCart
                  key={index}
                  data={{
                    title: data.metricConf.name,
                    milestone: data.targetValue,
                    value: data.value,
                    percentage: data.percent,
                    id: data.id,
                  }}
                />
              ))}
            </>
          )}
        </>
      )}

    </div>
  );
};

export default Metrics;
