import {
  Button,
  Container,
  Dialog,
  DialogContent,
  TextField,
} from "@material-ui/core";
import React, { useState } from "react";
import { FormattedMessage } from "react-intl";
import DialogTitle from "../../../components/app/common/controls/DialogTitle";
import {
  buttonStyles,
  inputStyles,
} from "../../../components/app/styles/styles";
import { Redirect } from "react-router-dom";
import { exposedUserPanelReducer } from "../../../components/app/admin/sections/pages/userPanel/store/UserPanelReducer";
import useAsyncReducer from "../../../components/app/useAsyncReducer";
import { useDispatch, useSelector } from "react-redux";

import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";

import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import IconButton from "@material-ui/core/IconButton";
import AttachmentIcon from "@material-ui/icons/Attachment";
import TransferList from "components/app/admin/common/TransferList";
import TimeInput from "components/app/common/fields/TimeInput";

import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import Typography from "@material-ui/core/Typography";
import DialogActions from "@material-ui/core/DialogActions";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import SearchIcon from "@material-ui/icons/Search";

const useReduxSelector = () =>
  useSelector((state) => ({
    session: state.authReducer.session,
  }));
const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
  },
}));
const TriviaEdit = (props) => {
  const classes = useStyles();
  const buttonClass = buttonStyles();
  const dispatch = useDispatch();
  const { session } = useReduxSelector();
  useAsyncReducer([exposedUserPanelReducer]);
  const inputClasses = inputStyles();

  const [teams, setTeams] = React.useState([]);

  const [model, setModel] = React.useState({
    name: "Test",
    description: null,
    triviaType: "USE_TIME", //SIMPLE OR USE_TIME
    time: null,
    teams: [
      { id: 1, name: "Grupo 1" },
      { id: 2, name: "Grupo 2" },
    ],
    qtyQuestions: 5,
    users: [
      { id: 1, name: "Maicol" },
      { id: 2, name: "Rodrigo" },
      { id: 3, name: "Jesus" },
      { id: 4, name: "Raul" },
      { id: 5, name: "Rogelio" },
    ],
    assignUserType: "team", //team or user

    mediaType: "",
  });

  const handleClose = () => {};

  console.info("state" + props.xs);

  const triviMediaType = [
    "YOUTUBE_VIDEO",
    "VIMEO_VIDEO",
    "FILE",
    "URL",
    "IMAGE_URL",
  ];

  const [redirect, setRedirect] = useState("");
  const triviaType = ["SIMPLE", "USE_TIME"];
  const triviaQtyQuestions = [0, 3, 5, 10, 15];
  const triviaTeams = [
    { id: 3, name: "Grupo 3" },
    { id: 4, name: "Grupo 4" },
  ];
  return (
    <>
      {redirect !== "" ? <Redirect to={redirect}></Redirect> : null}
      <Dialog
        open={true}
        fullScreen
        onClose={() => {
          setRedirect("/admin");
        }}
      >
        <DialogTitle
          onClose={() => {
            setRedirect("/admin");
          }}
        >
          <h2>
            <FormattedMessage
              id="trivia"
              defaultMessage="Trivia"
            ></FormattedMessage>
          </h2>
          <label hidden>{JSON.stringify(model)}</label>
        </DialogTitle>
        <DialogContent>
          <Container
            id="trivia-edit-container"
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            <Grid container spacing={3}>
              <Grid item sm={12} md={12}>
                <FormControl fullWidth>
                  <TextField
                    //className={inputClasses.lightPinkInput}
                    required
                    label={
                      <FormattedMessage
                        id="trivia.name"
                        defaultMessage="Name"
                      />
                    }
                    value={model.name}
                    onChange={(e) =>
                      setModel({ ...model, name: e.target.value })
                    }
                    inputProps={{ maxLength: 50 }}
                    variant="outlined"
                  ></TextField>
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <FormControl fullWidth>
                  <TextField
                    //className={inputClasses.lightPinkInput}
                    label={
                      <FormattedMessage
                        id="trivia.description"
                        defaultMessage="Description"
                      />
                    }
                    multiline
                    inputProps={{ maxLength: 256 }}
                    variant="outlined"
                    value={model.description}
                    onChange={(e) =>
                      setModel({ ...model, description: e.target.value })
                    }
                  ></TextField>
                </FormControl>
              </Grid>
              <Grid item xs={12} md={6}>
                <FormControl variant="outlined" fullWidth>
                  <InputLabel id="trivia-type-label">
                    <FormattedMessage
                      id="trivia.type.label"
                      defaultMessage="Type"
                    />
                  </InputLabel>

                  <Select
                    labelId="trivia-type-label"
                    id="demo-simple-select"
                    //value={age}
                    label={
                      <FormattedMessage
                        id="trivia.type.label"
                        defaultMessage="Type"
                      />
                    }
                    value={model.triviaType}
                    onChange={(e) =>
                      setModel({ ...model, triviaType: e.target.value })
                    }
                  >
                    {triviaType.map((v) => {
                      return (
                        <MenuItem value={v}>
                          <FormattedMessage
                            id={"trivia.type." + v}
                            defaultMessage={v}
                          />
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
              </Grid>
              <>
                {"USE_TIME" === model.triviaType ? (
                  <Grid item xs={12} md={6}>
                    <FormControl variant="outlined" fullWidth>
                      <TimeInput
                        title={
                          <FormattedMessage
                            id="trivia.time"
                            defaultMessage="Time"
                          />
                        }
                        value={model.timeInSec}
                        onChange={(e) => setModel({ ...model, timeInSec: e })}
                      ></TimeInput>
                    </FormControl>
                  </Grid>
                ) : (
                  <Grid item xs="0" md={6}>
                    <FormControl variant="outlined" fullWidth>
                      <Typography> </Typography>
                    </FormControl>
                  </Grid>
                )}
              </>

              <Grid item xs={12} md={6}>
                <FormControl fullWidth>
                  <TextField
                    //className={inputClasses.lightPinkInput}

                    disabled
                    label={
                      <FormattedMessage
                        id="trivia.image"
                        defaultMessage="Image"
                      />
                    }
                    variant="outlined"
                    InputProps={{
                      endAdornment: (
                        <>
                          <input
                            accept="image/*"
                            className={classes.input}
                            id="icon-button-file"
                            type="file"
                            hidden
                          />
                          <label htmlFor="icon-button-file">
                            <IconButton
                              color="primary"
                              aria-label="upload picture"
                              component="span"
                            >
                              <AttachmentIcon />
                            </IconButton>
                          </label>
                        </>
                      ),
                    }}
                  ></TextField>
                </FormControl>
              </Grid>

              <Grid item xs={12} md={6}>
                <FormControl variant="outlined" fullWidth>
                  <InputLabel id="trivia-qty-question-label">
                    <FormattedMessage
                      id="trivia.qtyquestion"
                      defaultMessage="Quantity of questions"
                    />
                  </InputLabel>

                  <Select
                    labelId="trivia-type-label"
                    id="trivia-qtyquestions-select"
                    label={
                      <FormattedMessage
                        id="trivia-type-label"
                        defaultMessage="Quantity of questions"
                      />
                    }
                    value={model.qtyQuestions}
                    onChange={(e) =>
                      setModel({ ...model, qtyQuestions: e.target.value })
                    }
                  >
                    {triviaQtyQuestions.map((v) => {
                      return (
                        <MenuItem value={v}>
                          <FormattedMessage
                            id={"trivia.qtyquestion.select." + v}
                            defaultMessage={`Show ${v ? v : "all"} questions`}
                          />
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
              </Grid>

              <Grid xs={12}>
                <FormControl fullWidth>
                  <ExpansionPanel>
                    <ExpansionPanelSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1a-content"
                      id="trivia-header"
                    >
                      <Typography className={classes.heading}>
                        Asignar
                      </Typography>
                    </ExpansionPanelSummary>
                    <ExpansionPanelDetails>
                      <Grid container spacing={3}>
                        <Grid xs={5} textAlign="center">
                          <FormControl fullWidth>
                            <label style={{ textAlign: "center" }}>
                              <FormattedMessage
                                id="trivia.name"
                                defaultMessage="Name"
                              />
                            </label>
                          </FormControl>
                        </Grid>
                        <Grid xs={2}></Grid>
                        <Grid xs={5}>
                          <FormControl fullWidth>
                            <label style={{ textAlign: "center" }}>
                              <FormattedMessage
                                id="trivia.name"
                                defaultMessage="Name"
                              />
                            </label>
                          </FormControl>
                        </Grid>
                        <Grid xs={12}>
                          <FormControl fullWidth>
                            <TransferList
                              right={model.teams}
                              left={triviaTeams}
                              onChange={(left, right) => {
                                setModel({ ...model, teams: right });
                              }}
                            ></TransferList>
                          </FormControl>
                        </Grid>

                        <Grid item xs={12} md={5}>
                          <FormControl fullWidth>
                            <TextField
                              //className={inputClasses.lightPinkInput}
                              label={
                                <FormattedMessage
                                  id="trivia.searchuser"
                                  defaultMessage="Image"
                                />
                              }
                              variant="outlined"
                              InputProps={{
                                endAdornment: (
                                  <>
                                    <input
                                      className={classes.input}
                                      id="icon-user-search"
                                      type="button"
                                      onClick={console.info("test")}
                                    />
                                    <label htmlFor="icon-user-search">
                                      <IconButton
                                        color="primary"
                                        component="span"
                                      >
                                        <SearchIcon />
                                      </IconButton>
                                    </label>
                                  </>
                                ),
                              }}
                            ></TextField>
                          </FormControl>
                        </Grid>
                        <Grid md={7} textAlign="center"></Grid>

                        <Grid xs={5} justify="center" alignItems="flex-end">
                          <FormControl fullWidth>
                            <label style={{ textAlign: "center" }}>
                              <FormattedMessage
                                id="trivia.add.allusers"
                                defaultMessage="All users"
                              />
                            </label>
                          </FormControl>
                        </Grid>
                        <Grid xs={2}></Grid>
                        <Grid xs={5} justify="center" alignItems="flex-end">
                          <FormControl fullWidth>
                            <label style={{ textAlign: "center" }}>
                              <FormattedMessage
                                id="trivia.add.allteams"
                                defaultMessage="Name"
                              />
                            </label>
                          </FormControl>
                        </Grid>
                        <Grid xs={12}>
                          <FormControl fullWidth>
                            <TransferList
                              right={[]}
                              left={model.users}
                            ></TransferList>
                          </FormControl>
                        </Grid>
                      </Grid>
                    </ExpansionPanelDetails>
                  </ExpansionPanel>
                </FormControl>
              </Grid>
            </Grid>
          </Container>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleClose} color="primary">
            <FormattedMessage id="trivia.undo" defaultMessage="Descartar" />
          </Button>
          <Button onClick={handleClose} color="primary" autoFocus>
            <FormattedMessage id="trivia.save" defaultMessage="Guardar" />
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default TriviaEdit;
