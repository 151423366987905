import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import RUG from "react-upload-gallery";
import Grid from "@material-ui/core/Grid";
import { ColorPicker } from "material-ui-color";
import Button from "@material-ui/core/Button";

// Add style manually
import "react-upload-gallery/dist/style.css"; // or scss

const useStyles = makeStyles((theme) => ({
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    height: "100vh",
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
}));

export default function Design() {
  const classes = useStyles();

  return (
    <main className={classes.content}>
      <div className={classes.appBarSpacer} />
      <Container maxWidth="lg" className={classes.container}>
        <Grid container spacing={1}>
          <Button
            className="button-table"
            variant="contained"
            color="secondary"
          >
            Diseño
          </Button>
          <Grid item xs={12} md={12} lg={12}>
            <RUG
              rules={{
                limit: 1,
              }}
              action="/api/upload" // upload route
              source={(response) => response.source} // response image source
            />
          </Grid>
          <hr className="divider" />
          <Grid item xs={12} md={12} lg={12}>
            <ColorPicker defaultValue="Transparente" />
          </Grid>
        </Grid>
      </Container>
    </main>
  );
}
