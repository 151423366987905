import React from "react";
import { withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import { SpinnerCircular } from "spinners-react";
import { withTranslation } from "react-i18next";

import Container from "@material-ui/core/Container";

import HallFameQuarter from "./HallFameQuarter";
import HallFameTab from "./HallFameTab";

import toastr from "toastr";

/********Services***** */
import HallFameService from "../../Services/HallFame";

import "toastr/build/toastr.min.css";
import "./HallFame.css";

const styles = (theme) => ({
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
  },

  paper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
  },
  fixedHeight: {
    height: 240,
  },
  root: {
    "& > *": {
      marginTop: theme.spacing(2),
    },
  },
  margin: {
    margin: theme.spacing(1),
  },
  textField: {
    width: "50ch",
    marginBottom: "5px",
  },
});

class HallFame extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      hallFame: null,
    };
  }

  componentDidMount() {
    this.getHallFame();
  }

  getHallFame() {
    this.setState({ loading: true });
    HallFameService.getHallFame()
      .then((response) => {
        this.setState({ hallFame: response.data });
        this.setState({ loading: false });
      })
      .catch(() => {
        toastr.error(
          this.props.t(
            "hall-fame-message-error",
            "There was an error trying to acquire the Hall of Fame"
          )
        );
        this.setState({ loading: false });
      });
  }

  /***********GENERAL FUNCTIONS************* */

  render() {
    const { loading, hallFame } = this.state;
    const { classes } = this.props;

    return (
      <main className={classes.content}>
        <Container maxWidth="lg">
          {loading && (
            <div className="div-spinner">
              <Grid container spacing={1}>
                <SpinnerCircular size={90} />
              </Grid>
            </div>
          )}

          <div style={{
  fontSize: '1.6rem',
  marginTop: '20px',
  marginRight: '20px',
  marginLeft: '-20px',
  paddingBottom: '10px',
  display: 'flex',
  flexWrap: 'wrap'
}}
></div>
          {!loading && hallFame && (
            <HallFameQuarter hallFame={hallFame.historicalRanking} />
          )}

          {!loading && hallFame && hallFame.monthRankings && (
            <HallFameTab hallFame={hallFame.monthRankings} />
          )}
        </Container>
      </main>
    );
  }
}

export default withTranslation()(
  withStyles(styles, { withTheme: true })(HallFame)
);
