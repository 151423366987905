const DEFAULT_HEADERS = {
  Accept: "application/json",
  "Content-Type": "application/json",
};

const jsonFetch = ({
  token = null,
  endpoint,
  method = "GET",
  jsonBody = null,
  headers = DEFAULT_HEADERS,
}) => {
  let config = {
    method,
    body: jsonBody ? JSON.stringify(jsonBody) : null,
    headers: {
      ...headers,
      token: token ? `${token}` : null,
    },
  };
  return new Promise((resolve, reject) =>
    fetch(endpoint, config)
      .then(async (response) => {
        let responseBody = await response.text();
        try {
          const json = JSON.parse(responseBody);
          if (response.headers.get("token")) {
            json.token = response.headers.get("token");
          }
          if (response.ok) {
            resolve(json);
            return;
          }

          const error = { status: response.status, ...json };
          reject(error);
        } catch {
          resolve(responseBody);
          return;
        }

        return;
      })
      .catch((error) => {
        reject(error);

        return;
      })
  );
};

const isJsonResponse = (response) => {
  const contentType = response.headers.get("content-type");
  return contentType && contentType.indexOf("application/json") !== -1;
};

export default jsonFetch;
