import React from "react";
import { withStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import { Checkbox } from '@material-ui/core';
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import Container from "@material-ui/core/Container";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import toastr from "toastr";
import { SpinnerCircular } from "spinners-react";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import User from "../../common/Dialogs/User";
import { NotRegistersFound } from "../../common/NotRegistersFound";
import Tooltip from "@material-ui/core/Tooltip";
import environment from "../../../../../libs/environment";
import { withTranslation } from "react-i18next";
import TextField from "@material-ui/core/TextField";
import ArrowDownwardIcon from "@material-ui/icons/ArrowDownward";
import ArrowUpwardIcon from "@material-ui/icons/ArrowUpward";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import TablePagination from "@material-ui/core/TablePagination";
import UsersImport from "../../common/Dialogs/UsersImport";
import ImportUser from "../../common/Dialogs/ImportsUser";

import {
  usersRequest,
  teamsRequest,
  secondaryTeamsRequest,
} from "../../../../../actions/generalActions";

import {
  getUsersRequest,
  getUsersActiveRequest,
  getUsersBlockedRequest,
  getUsersFilteredRequest,
  getUsersFilteredRequest2,
  getUsersFilteredRequest3,
  getUsersFilteredRequested,
  getUsersFilteredRequested2,
  getUsersFilteredRequested3,
  resetState,
  disableUserRequest,
} from "../../../../../actions/usersActions";

/********Services******** */
import UserServices from "../../Services/UserService";
import ImportUserService from "../../Services/ImportUserService";

/*************Icons******** */

import BackupIcon from "@material-ui/icons/Backup";
import CloudDownloadIcon from "@material-ui/icons/CloudDownload";
import DeleteIcon from "@material-ui/icons/Delete";
import ViewListIcon from "@material-ui/icons/ViewList";

import ReportButton from "../../common/Dialogs/ReportButton";

import "toastr/build/toastr.min.css";

const styles = (theme) => ({
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
  },
  fixedHeight: {
    height: 240,
  },
  root: {
    "& > *": {
      marginTop: theme.spacing(2),
    },
  },
  margin: {
    margin: theme.spacing(1),
  },
});

class UserPanel extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
    loading: false,
    viewImportUser: false,
    rowsPerPage: 5,
    order: "desc",
    filter: "",
    selectedOption: "Todos", // Estado para la opción seleccionada del filtro
    page: 0,
    usersImportDialogOpen: false,
    userImports: [],
    reportLoginByUserParams: {dateFrom: null, dateTo: null}
  };
}
handleFilterChange(value) {
  const { selectedOption } = this.state;

  // Actualiza el estado del filtro
  this.setState({ filter: value });

  // Determina la acción a realizar según la opción seleccionada
  switch (selectedOption) {
    case "Activos":
      // Si la opción seleccionada es "Activos", llama a getUsers2 con el filtro y el estado "activo"
      this.getUsers2(0, value, "activo");
      break;
    case "Bloqueados":
      // Si la opción seleccionada es "Bloqueados", llama a getUsers3 con el filtro y el estado "bloqueado"
      this.getUsers3(0, value, "bloqueado");
      break;
    default:
      // De lo contrario, asume que es "Todos" y llama a getUsers con el filtro y cualquier estado
      this.getUsers(0, value, this.state.order);
  }
}


  componentDidMount() {
    if (!this.props.generalReducer.teams) {
      this.props.teamsRequest();
    }
    if (!this.props.generalReducer.users) {
      this.props.usersRequest();
    }
    if (!this.props.generalReducer.secondary_teams) {
      this.props.secondaryTeamsRequest();
    }
    this.getUsers(0, "", this.state.order);
  }

  handleChange(event, value) {
    const { filter, order, clickedOnce, selectedOption } = this.state;
    
    this.setState({ page: value });
    
    if (clickedOnce) {
      if (selectedOption === "Activos") {
        this.getUsers2(value, filter, order);
      } else if (selectedOption === "Bloqueados") {
        this.getUsers3(value, filter, order);
      } else {
        this.getUsers(value, filter, order);
      }
    } else {
      if (selectedOption === "Activos") {
        this.getUsers2(value, filter, order);
      } else if (selectedOption === "Bloqueados") {
        this.getUsers3(value, filter, order);
      } else {
        this.getUsers(value, filter, order);
      }
    }
  }
  
  handleChangeOrder(selectedOption) {
    // Cambia el orden de acuerdo al estado actual
    const newOrder = this.state.order === "desc" ? "asc" : "desc";
    this.setState({ order: newOrder });
  
    // Resetea el estado y establece page en 0
    this.props.resetState();
    this.setState({ page: 0 });
  
    // Determina la acción a tomar según el valor de selectedOption
    if (selectedOption === "Activos") {
      // Si la opción seleccionada es "Activos", llama a getUsers2 con el nuevo orden
      this.getUsers2(0, this.state.filter, newOrder);
    } else if (selectedOption === "Bloqueados") {
      // Si la opción seleccionada es "Bloqueados", llama a getUsers3 con el nuevo orden
      this.getUsers3(0, this.state.filter, newOrder);
    } else {
      // De lo contrario, asume que es "Todos" y llama a getUsers con el nuevo orden
      this.getUsers(0, this.state.filter, newOrder);
    }
  }
  
  
  

 
  handleChangeOrder2(event) {
    const selectedOption = event.target.value;
    this.setState({ selectedOption, page: 0, order:"desc" }); // Establece la página en 0 al seleccionar una nueva opción
    const value = event.target.value;
  
    // Agregar el carácter especial "\n" al filtro
    let updatedFilter = this.state.filter;
    if (updatedFilter.length > 0) {
      updatedFilter += "\n";
    }
  
    if (this.state.clickedOnce) {
      // Si ya se hizo clic una vez, cambia según el valor seleccionado
      this.setState({ clickedOnce: false }, () => {
        this.props.resetState();
        if (value === "Todos") {
          this.getUsers(0, updatedFilter);
        } else if (value === "Activos") {
          this.getUsers2(0, updatedFilter);
        } else if (value === "Bloqueados") {
          this.getUsers3(0, updatedFilter);
        }
      });
    } else {
      // Si es la primera vez que se hace clic, cambia según el valor seleccionado
      this.setState({ clickedOnce: true }, () => {
        this.props.resetState();
        if (value === "Todos") {
          this.getUsers(0, updatedFilter);
        } else if (value === "Activos") {
          this.getUsers2(0, updatedFilter);
        } else if (value === "Bloqueados") {
          this.getUsers3(0, updatedFilter);
        }
      });
    }
  }
  
  
  

 


  /***********Pre-import CSV*************** */
  handleImport(e) {
  
    this.setState({ loading: true });
    const formData = new FormData();
    formData.append("file", e.target.files[0]);
    ImportUserService.setImportUser(formData)
      .then((response) => {
        this.setState({ userImports: response });
        this.setState({ loading: false });
        this.setState({ usersImportDialogOpen: true });
      })
      .catch((error) => {
        if (error.response.status === 422) {
          toastr.error(error.response.data.message);
        } else {
          toastr.error(
            this.props.t(
              "menu.metric-panel-message-error-import",
              "An error occurred while trying to pre-import the CSV"
            )
          );
        }
        this.setState({ loading: false });
      });
  }

  /***********GENERAL FUNCTIONS************* */

  downloadUsers() {
    this.setState({ loading: true });
    UserServices.downloadUsers()
      .then((response) => {
        var blob = response.data;
        var link = document.createElement("a");
        link.href = window.URL.createObjectURL(new Blob([blob]));
        link.download = "Users.csv";
        link.click();

        this.setState({ loading: false });
      })
      .catch(() => {
        toastr.error(
          this.props.t(
            "admin.user-panel-message-download-users",
            "An error occurred while trying to download users"
          )
        );
        this.setState({ loading: false });
      });
  }

  downloadComments() {
    this.setState({ loading: true });
    UserServices.downloadComments()
      .then((response) => {
        var blob = response.data;
        var link = document.createElement("a");
        link.href = window.URL.createObjectURL(new Blob([blob]));
        link.download = "Users.csv";
        link.click();

        this.setState({ loading: false });
      })
      .catch(() => {
        toastr.error(
          this.props.t(
            "admin.user-panel-message-download-users",
            "An error occurred while trying to download users"
          )
        );
        this.setState({ loading: false });
      });
  }

  getUsers(number = 0, filter = "", order = "desc") {
    this.setState({ filter: filter });

    if (filter === "" || !filter) {
      this.setState({ loading: false });

      if (this.state.filtering === true) {
        this.props.resetState();
        this.setState({ filtering: false });
        this.setState({ page: 0 });
      }
      this.props.getUsersRequest({
        number: number + 1,
        rowsPerPage: 5,
        order,
      });
    } else {
      if (filter.length > 3) {
        this.setState({ loading: true });

        const user_requested = this.props.usersReducer.users_requested.find((element) => element.filter == filter)

        //console.log('asi esta actualmente: ', this.props.usersReducer)

        if(user_requested != undefined) {

          this.props.getUsersFilteredRequested({
            user_requested: user_requested,
            number: number + 1,
            rowsPerPage: 5,
            filter,
            order,
          })

          this.setState({ loading: false });

          return;
        }
        
        setTimeout(() => {
          if (filter == this.state.filter) {
            if (this.state.filtering !== true) {
              this.props.resetState();
              this.setState({ filtering: true });
              this.setState({ page: 0 });
            }

            this.setState({ loading: false });
      
            this.props.getUsersFilteredRequest({
              number: number + 1,
              rowsPerPage: 5,
              filter,
              order,
            });
          }
        }, '2000');  
      }

    }
  }
  getUsers2(number = 0, filter = "", order = "desc") {
    this.setState({ filter: filter });

    if (filter === "" || !filter) {
      this.setState({ loading: false });

      if (this.state.filtering === true) {
        this.props.resetState();
        this.setState({ filtering: false });
        this.setState({ page: 0 });
      }
      this.props.getUsersActiveRequest({
        number: number + 1,
        rowsPerPage: 5,
        order,
      });
    } else {
      if (filter.length > 3) {
        this.setState({ loading: true });

        const user_requested = this.props.usersReducer.users_requested.find((element) => element.filter == filter)

        //console.log('asi esta actualmente: ', this.props.usersReducer)

        if(user_requested != undefined) {

          this.props.getUsersFilteredRequested2({
            user_requested: user_requested,
            number: number + 1,
            rowsPerPage: 5,
            filter,
            order,
          })

          this.setState({ loading: false });

          return;
        }
        
        setTimeout(() => {
          if (filter == this.state.filter) {
            if (this.state.filtering !== true) {
              this.props.resetState();
              this.setState({ filtering: true });
              this.setState({ page: 0 });
            }

            this.setState({ loading: false });
      
            this.props.getUsersFilteredRequest2({
              number: number + 1,
              rowsPerPage: 5,
              filter,
              order,
            });
          }
        }, '2000');  
      }

    }
  }
  getUsers3(number = 0, filter = "", order = "desc") {
    this.setState({ filter: filter });

    if (filter === "" || !filter) {
      this.setState({ loading: false });

      if (this.state.filtering === true) {
        this.props.resetState();
        this.setState({ filtering: false });
        this.setState({ page: 0 });
      }
      this.props.getUsersBlockedRequest({
        number: number + 1,
        rowsPerPage: 5,
        order,
      });
    } else {
      if (filter.length > 3) {
        this.setState({ loading: true });

        const user_requested = this.props.usersReducer.users_requested.find((element) => element.filter == filter)

        //console.log('asi esta actualmente: ', this.props.usersReducer)

        if(user_requested != undefined) {

          this.props.getUsersFilteredRequested3({
            user_requested: user_requested,
            number: number + 1,
            rowsPerPage: 5,
            filter,
            order,
          })

          this.setState({ loading: false });

          return;
        }
        
        setTimeout(() => {
          if (filter == this.state.filter) {
            if (this.state.filtering !== true) {
              this.props.resetState();
              this.setState({ filtering: true });
              this.setState({ page: 0 });
            }

            this.setState({ loading: false });
      
            this.props.getUsersFilteredRequest3({
              number: number + 1,
              rowsPerPage: 5,
              filter,
              order,
            });
          }
        }, '2000');  
      }

    }
  }

  reportHandleChange(value) {
    console.info(value);    
    if (value.target.name == 'dateFrom') {
      this.setState({reportLoginByUserParams: {dateFrom: value.target.value, dateTo: this.state.reportLoginByUserParams.dateTo }});

    } else if (value.target.name == 'dateTo') {
      this.setState({reportLoginByUserParams: {dateFrom: this.state.reportLoginByUserParams.dateFrom , dateTo: value.target.value }});

    }
  }
  /***********CRUD FUNCTIONS************* */

  async deleteUser(id) {
    let status;
    await this.props.disableUserRequest(id).then((r) => (status = r));

    if (status === "SUCCESS") {
      toastr.success(
        this.props.t(
          "admin.user-panel-message-success-delete-users",
          "User deleted successfully"
        )
      );
      return;
    }

    if (status.error) {
      this.props.t(
        "admin.user-panel-message-error-delete-users",
        "An error occurred while deleting the user"
      );
    }
  }

  render() {
    const { loading, rowsPerPage, page, viewImportUser, filter, order, usersImportDialogOpen, userImports } =
      this.state;
    const { classes, t } = this.props;
    const { selectedOption } = this.state;

    return (
      <main className={classes.content}>
        <div className={classes.appBarSpacer} />
        <Container maxWidth="lg" className={classes.container}>
          <Grid container spacing={1}>
            <User
              name=""
              lastName=""
              email=""
              role=""
              team=""
              teams={
                this.props.generalReducer.teams
                  ? this.props.generalReducer.teams.content
                  : []
              }
              users={
                this.props.generalReducer.users
                  ? this.props.generalReducer.users.content
                  : []
              }
              secondaryTeams={
                this.props.generalReducer.secondary_teams
                  ? this.props.generalReducer.secondary_teams.content
                  : []
              }
            />

            <Button
              className="button-table ml-1"
              variant="contained"
              color="primary"
            >
              <BackupIcon className="mr-1" />

              <label htmlFor="avatar">
                {t("menu.user-panel-button-import-csv", "Import CSV")}
              </label>
              <input
                type="file"
                className="fileInputUser"
                onChange={(e) => this.handleImport(e)}
                id="avatar"
                name="avatar"
              />
            </Button>

            <a
              href={`${environment.motivarnosBackend}/file/download/Groups_and_Users_Template.xlsx?pathName=files`}
              className="link-button"
            >
              <Button
                className="button-table ml-1"
                variant="contained"
                color="primary"
              >
                <CloudDownloadIcon className="mr-1" />
                {t(
                  "menu.user-panel-button-download-template",
                  "Download Template"
                )}
              </Button>
            </a>

            <Button
              className="button-table ml-1 mr-1"
              variant="contained"
              color="primary"
              onClick={() => this.downloadUsers()}
            >
              <CloudDownloadIcon className="mr-1" />
              {t("menu.user-panel-button-download-csv", "Download CSV")}
            </Button>

            {/* <Button
              className="button-table ml-1 mr-1"
              variant="contained"
              color="primary"
              onClick={() => this.downloadComments()}
            >
              <CloudDownloadIcon className="mr-1" />
              {t("menu.user-panel-button-download-comments", "Download Comments")}
            </Button> */}

            <Link to="/report/userPanel">
              <Button
                className="button-table mr-1"
                variant="contained"
                color="secondary"
              >
                <ViewListIcon className="mr-1" />
                {t("report.label", "Report")}
              </Button>
            </Link>
            
            <Link style={{marginRight: "10px", marginLeft: "-16px"}} className="link-custom" to="/user/conditions">
              <Button
                className="button-table ml-1"
                variant="contained"
                color="secondary"
              >
                <ViewListIcon className="mr-1" />
                {t("report-last-login.label", "Last login report")}
              </Button>
            </Link>

            <ImportUser />

            <ReportButton
              className="button-table mr-1"
              variant="contained"
              color="secondary"
              title="Reporte de Login Por Equipos"
              params={this.state.reportLoginByUserParams}
              reportName="MotivarnosLoginsUserReport"
            >
            
            <TextField
              onChange={e => this.reportHandleChange(e)}
              type="date"
              name="dateFrom"
              id="dateFrom"
              label={t(
                "report-logins-user-datefrom",
                "Desde"
              )}
              variant="outlined"
              className="mt-2"
              fullWidth
            />
            
            <TextField
              onChange={e => this.reportHandleChange(e)}
              type="date"
              name="dateTo"
              id="dateFrom"
              label={t(
                "report-logins-user-dateTo",
                "Hasta"
              )}
              variant="outlined"
              className="mt-2"
              fullWidth
            />
            
            </ReportButton>

            <TextField
      style={{ marginTop: "-5px", marginLeft: "-14px" }}
      id="outlined-basic"
      label={t("menu.metric-panel-search", "Search")}
      variant="outlined"
      value={filter}
      name="filter"
      className="custom-text-field-user"
      onChange={(e) => this.handleFilterChange(e.target.value)} // Usa el método de manejo de cambio de filtro
    />

          </Grid>
          {(loading || this.props.usersReducer.users_charging) &&
            !viewImportUser && (
              <div className="div-spinner">
                <Grid container spacing={1}>
                  <SpinnerCircular size={90} />
                </Grid>
              </div>
            )}

          {!loading &&
            !this.props.usersReducer.users_charging &&
            (this.props.usersReducer.users &&
            this.props.usersReducer.users.length > 0 ? (
              <Grid container spacing={1}>
                <TableContainer component={Paper}>
                  <Table className={classes.table} aria-label="simple table">
                    <TableHead>
                      <TableRow>
                      <TableCell
  align="left"
  onClick={() => this.handleChangeOrder(selectedOption)} // Pasa el valor del selector como argumento
  className="cursor-pointer"
>
  {order === "desc" ? (
    <ArrowUpwardIcon />
  ) : (
    <ArrowDownwardIcon />
  )}
  {t("admin.user-panel-table-user", "User")}
</TableCell>

                        <TableCell align="left">
                          {t("admin.user-panel-table-name", "Name")}
                        </TableCell>
                        <TableCell align="left">
                          {t("admin.user-panel-table-position", "Position")}
                        </TableCell>
                        <TableCell align="left">
                          {t("admin.user-panel-table-team", "Team")}
                        </TableCell>
                        <TableCell align="left">
                          {t(
                            "admin.user-panel-user-dialog-input-secondary-team",
                            "Secondary group"
                          )}
                        </TableCell>
                        <TableCell align="left">
                          Categoría de premiación
                        </TableCell>
                        <TableCell align="left" className="cursor-pointer"><span style={{ marginLeft: '-1px' }}>
          {t("admin.user-panel-table-state", "State")}
        </span>
        <Select
          value={selectedOption}
          onChange={(event) => this.handleChangeOrder2(event)}
          style={{ width: '80px', marginLeft: '10px' }}
        >
          <MenuItem value={"Todos"}>Todos</MenuItem>
          <MenuItem value={"Activos"}>Activos</MenuItem>
          <MenuItem value={"Bloqueados"}>Bloqueados</MenuItem>
        </Select>
        
      </TableCell>
                        <TableCell align="left">
                          {t("admin.user-panel-table-actions", "Actions")}
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {this.props.usersReducer.users
                        .slice(
                          page * rowsPerPage,
                          page * rowsPerPage + rowsPerPage
                        )
                        .map((row) => {
                          return (
                            <TableRow key={row.id}>
                              <TableCell align="left">{row.username}</TableCell>
                              <TableCell align="left">
                                {row.name + " " + row.lastName}
                              </TableCell>
                              <TableCell align="left">
                                {row.position === 3
                                  ? "Colaborador"
                                  : row.position === 2
                                  ? "Team Leader"
                                  : row.position === 1
                                  ? "Team Manager"
                                  : row.position === 4
                                  ? "Manager"
                                  : row.position === 5
                                  ? "Sr Manager"
                                  : row.position === 6
                                  ? "Director"
                                  : "N/A"}
                              </TableCell>
                              <TableCell align="left">
                                {row.team ? row.team.name : "N/A"}
                              </TableCell>
                              <TableCell align="left">
                                {row.secondaryTeam
                                  ? row.secondaryTeam.name
                                  : "N/A"}
                              </TableCell>
                              <TableCell align="left">
                                {row.awardCategory
                                  ? row.awardCategory
                                  : "N/A"}
                              </TableCell>
                              <TableCell align="left">
                                {row.isActive
                                  ? t(
                                      "admin.header-dropdown-view-conditions-table-active-state",
                                      "Active"
                                    )
                                  : t(
                                      "admin.header-dropdown-view-conditions-table-blocked-state",
                                      "Blocked"
                                    )}
                              </TableCell>

                              <TableCell align="left">
                                <div style={{display: "flex", justifyContent: "space-between"}}>
                                  <User
                                    typeModal="modalEdit"
                                    {...row}
                                    page={page}
                                    teams={
                                      this.props.generalReducer.teams
                                        ? this.props.generalReducer.teams.content
                                        : []
                                    }
                                    users={
                                      this.props.generalReducer.users
                                        ? this.props.generalReducer.users.content
                                        : []
                                    }
                                    secondaryTeams={
                                      this.props.generalReducer.secondary_teams
                                        ? this.props.generalReducer
                                            .secondary_teams.content
                                        : []
                                    }
                                    ownSecondaryTeam={row.secondaryTeam
                                      ? row.secondaryTeam
                                      : null
                                    }
                                  />
                                  {row.isActive && (
                                    <Tooltip
                                      title={t("admin.actions-delete", "Delete")}
                                    >
                                      <IconButton
                                        aria-label="delete-user"
                                        onClick={() => this.deleteUser(row.id)}
                                      >
                                        <DeleteIcon
                                          fontSize="small"
                                        />
                                      </IconButton>
                                    </Tooltip>
                                  )}
                                </div>
                              </TableCell>
                            </TableRow>
                          )}
                        )
                      }
                    </TableBody>
                  </Table>
                  <TablePagination
                    className="mt-1"
                    rowsPerPageOptions={[5]}
                    component="div"
                    count={this.props.usersReducer.totalElements}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={(event, value) =>
                      this.handleChange(event, value)
                    }
                  />
                </TableContainer>
              </Grid>
            ) : (
              <>
                <Grid container spacing={1}>
                  <Grid item xs={12} md={12} lg={12}>
                    <NotRegistersFound />
                  </Grid>
                </Grid>
              </>
            ))}
        </Container>
        {usersImportDialogOpen && 
        <UsersImport 
          userImports={userImports}
        />}
      </main>
    );
  }
}

const mapStateToProps = ({ generalReducer, usersReducer }) => ({
  generalReducer,
  usersReducer,
});
const mapDispatchToProps = {
  teamsRequest,
  secondaryTeamsRequest,
  usersRequest,
  getUsersRequest,
  getUsersActiveRequest,
  getUsersBlockedRequest,
  getUsersFilteredRequest,
  getUsersFilteredRequest2,
  getUsersFilteredRequest3,
  getUsersFilteredRequested,
  getUsersFilteredRequested2,
  getUsersFilteredRequested3,
  resetState,
  disableUserRequest,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(withStyles(styles, { withTheme: true })(UserPanel)));