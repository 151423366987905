import React, { useState, useContext } from "react";
import TermsAndConditions from "../controls/TermsAndConditions";
import Button from "@material-ui/core/Button/Button";
import DialogActions from "@material-ui/core/DialogActions";
import { FormattedMessage } from "react-intl";
import { useTranslation } from 'react-i18next';

const TermsAndConditionsButton = (props) => {

    const [openTermsAndConditions, setOpenTermsAndConditions] = useState(false);
    const { t, i18n } = useTranslation();
    
    return <> 
            <TermsAndConditions termsAndConditions={props.termsAndConditions} actionPostTermsAndConditions = {props.actionPostTermsAndConditions} open = {openTermsAndConditions} onClose = { () => setOpenTermsAndConditions(false) }/>
            
            <DialogActions style={{ display: "flex", flexGrow: 1, justifyContent: "center" }}>
                
                <Button
                    onClick={() => setOpenTermsAndConditions(true)}
                    className={props.className}
                    disabled={!props.goToTermsAndConditions}
                >
                    <FormattedMessage
                    id="landing.confirmBtn"
                    defaultMessage={t('confirm.label','Confirm')}
                    />
                </Button>
            </DialogActions>
        </>
}
export default TermsAndConditionsButton;