import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import Slide from "@material-ui/core/Slide";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import InputLabel from "@material-ui/core/InputLabel";
import { SpinnerCircular } from "spinners-react";
import toastr from "toastr";
import Button from "@material-ui/core/Button";
import Tooltip from "@material-ui/core/Tooltip";
import { format } from "date-fns";
import { useTranslation } from "react-i18next";
import { AppWrapper } from "../GlobalStyles";
import FindRegistersDialog from "../../common/Dialogs/FindRegistersDialog";

import { connect } from "react-redux";

import {
  saveNewRequest,
  updateNewRequest,
} from "../../../../../actions/newsActions";

import environment from "../../../../../libs/environment";

/*********Icons********* */
import AddIcon from "@material-ui/icons/Add";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import EditIcon from "@material-ui/icons/Edit";

/********Services********* */
import FileService from "../../Services/FileService";

/**********Hooks********** */
import { useFormatProps } from "../../hooks/useFormatProps";

const useStyles = makeStyles((theme) => ({
  appBar: {
    position: "relative",
  },
  formControl: {
    width: "100%",
    marginTop: "1rem",
  },
  container: {
    paddingTop: theme.spacing(4),
  },
  title: {
    marginLeft: theme.spacing(2),
    color: "black",
    flex: 1,
  },
  root: {
    "& > *": {
      margin: theme.spacing(1),
    },
  },
  input: {
    display: "none",
  },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function NewDialog(props) {
  /***************Styles************ */
  const classes = useStyles();
  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    getContentAnchorEl: () => null,
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };
  /***************Styles************ */

  /***********General functions and constants******** */

  const [open, setOpen] = React.useState(false);
  const [loading, setLoading] = useState(false);

  const [titleError, setTitleError] = useState(false);
  const [messageError, setMessageError] = useState(false);
  const [teamsError, setTeamsError] = useState(false);
  const [usersError, setUsersError] = useState(false);
  const [expirationDateError, setExpirationDateError] = useState(false);
  const [dateFromError, setCreatedError] = useState(false);
  const [isActiveError, setIsActiveError] = useState(false);
  const [imageObject, setImageObject] = useState(null);
  const [imageObjectFromDialog, setImageObjectFromDialog] = useState(true);

  const { t } = useTranslation();
  const [teamsHooks, rowsHooks] = useFormatProps(
    props.rowsTeams ? props.rowsTeams : [],
    props.rowsUsers ? props.rowsUsers : []
  );

  function formatPropsEdit(array) {
    let arrayFormatted = [];
    array.forEach((element) => {
      arrayFormatted.push(element.id);
    });
    return arrayFormatted;
  }

  const [
    {
      title,
      message,
      expirationDate,
      users,
      teams,
      dateFrom,
      isActive,
      teamsProps,
      usersProps,
      image,
      link,
      videoLink,
    },
    setState,
  ] = useState({
    title: props.title ? props.title : "",
    message: props.message ? props.message : "",
    expirationDate: props.expirationDate
      ? format(new Date(props.expirationDate), "yyyy-MM-dd")
      : format(new Date(), "yyyy-MM-dd"),
    users: formatPropsEdit(props.users ? props.users : []),
    teams: formatPropsEdit(props.teams ? props.teams : []),
    dateFrom: props.dateFrom
      ? format(new Date(props.dateFrom), "yyyy-MM-dd")
      : format(new Date(), "yyyy-MM-dd"),
    isActive: props.isActive ? props.isActive : false,
    teamsProps: teamsHooks && teamsHooks.length > 0 ? teamsHooks : [],
    usersProps: rowsHooks && rowsHooks.length > 0 ? rowsHooks : [],
    image: props.image ? props.image : "",
    link: props.link ? props.link : "",
    videoLink: props.videoLink ? props.videoLink : "",
  });

  const handleClickOpen = () => {
    setOriginalState(true);
  };

  function handleChange({ target: { name, value } }) {
    setState((prevState) => ({ ...prevState, [name]: value }));
  }

  /***********************New functions********************* */

  function setTeams(teams) {
    setState((prevState) => ({ ...prevState, 'teams': teams }));
  }

  function setUsers(users) {
    console.log(users)
    setState((prevState) => ({ ...prevState, 'users': users }));
  }

  function setName(option) {
    return (option.name == '' || option.name  == null) ? t(
      "admin.user-panel-user-dialog-input-select-without-name",
      "Without name") : option.name;
  }

  const handleClose = () => {
    setOriginalState();
  };

  function setOriginalState(open = false) {
    setState((prevState) => ({
      ...prevState,
      title: props.title ? props.title : "",
      message: props.message ? props.message : "",
      expirationDate: props.expirationDate
        ? format(new Date(props.expirationDate), "yyyy-MM-dd")
        : format(new Date(), "yyyy-MM-dd"),
      users: formatPropsEdit(props.users ? props.users : []),
      teams: formatPropsEdit(props.teams ? props.teams : []),
      dateFrom: props.dateFrom
        ? format(new Date(props.dateFrom), "yyyy-MM-dd")
        : format(new Date(), "yyyy-MM-dd"),
      isActive: props.isActive ? props.isActive : false,
    }));
    setOpen(open);
  }
  /***********General functions and constants******** */

  /***************************CRUD functions*************** */
  async function onFormSubmit() {
    setTitleError(false);
    setMessageError(false);
    setExpirationDateError(false);
    setCreatedError(false);
    setTeamsError(false);
    setUsersError(false);
    setIsActiveError(false);

    if (title === "") {
      setTitleError(true);
      toastr.error(
        t(
          "menu.new-panel-dialog-message-error-title-required",
          "The title is required"
        )
      );
      return;
    }

    if (message === "") {
      setMessageError(true);
      toastr.error(
        t(
          "menu.new-panel-dialog-message-error-message-required",
          "The message is required"
        )
      );
      return;
    }

    if (dateFrom === "") {
      setCreatedError(true);
      toastr.error(
        t(
          "menu.new-panel-dialog-message-error-creation-required",
          "Creation date is required"
        )
      );
      return;
    }

    if (expirationDate === "") {
      setExpirationDateError(true);
      toastr.error(
        t(
          "menu.new-panel-dialog-message-error-expiration-required",
          "The expiration date is required"
        )
      );
      return;
    }

    if (
      (teams === "" || teams.length === 0 || !teams) &&
      (users === "" || users.length === 0 || !users)
    ) {
      setTeamsError(true);
      setUsersError(true);

      toastr.error(
        t(
          "menu.trivia-panel-dialog-add-test-message-error-add-group",
          "You must add groups or players"
        )
      );
      return;
    }

    if (isActive === "") {
      setIsActiveError(true);
      toastr.error(
        t(
          "menu.trivia-panel-dialog-add-test-message-error-active",
          "You must choose if it is active or not"
        )
      );
      return;
    }

    /***********Format variables****** */
    let teamsFormatted = [];
    teams.forEach((element) => {
      teamsFormatted.push({
        id: element,
      });
    });

    let usersFormatted = [];
    users.forEach((element) => {
      usersFormatted.push({
        id: element,
      });
    });

    let urlImage = imageObject ? "" : image;

    if (image && image !== "") {
      if (imageObject) {
        setLoading(true);
        const formData = new FormData();
        formData.append("file", imageObject);
        await FileService.uploadFile(formData, "news")
          .then((response) => {
            urlImage = response.data.url;
            toastr.success(
              t(
                "menu.trivia-panel-dialog-add-test-message-success-add-image",
                "Image added successfully"
              )
            );
          })
          .catch(() => {
            toastr.error(
              t(
                "menu.trivia-panel-dialog-add-test-message-error-add-image",
                "An error occurred while trying to add the image"
              )
            );
          });
      }
    }

    let json = {
      title: title,
      message: message,
      expirationDate: expirationDate + "T00:00:00",
      users: usersFormatted,
      teams: teamsFormatted,
      dateFrom: dateFrom + "T00:00:00",
      isActive: isActive,
      image: urlImage,
      link: link,
      videoLink: videoLink,
    };

    setLoading(true);

    let status;

    if (props.typeModal === "modalEdit") {
      await props
        .updateNewRequest({ ...json, id: props.id })
        .then((r) => (status = r));
    } else {
      await props.saveNewRequest(json).then((r) => (status = r));
    }

    if (status.status === "ERROR") {
      if (status.error && status.error.status === 422) {
        toastr.error(status.error.data.message);
      } else {
        toastr.error(
          t(
            "menu.new-panel-dialog-message-error-notification-saved",
            "An error occurred while trying to save the notification"
          )
        );
      }
      setLoading(false);
    } else {
      toastr.success(
        t(
          "menu.new-panel-dialog-message-success-notification-saved",
          "Notification saved successfully"
        )
      );
      setLoading(false);
      handleClose();
    }
  }

  function handleChangeImage(e) {
    if (e.target.files && e.target.files[0]) {
      setState((prevState) => ({ ...prevState, image: e.target.files[0] }));
      setImageObject(e.target.files[0]);
      setImageObjectFromDialog(false);
      readURL(e);
    }
  }

  function readURL(e) {
    if (e.target.files && e.target.files[0]) {
      var reader = new FileReader();

      reader.onload = function (e) {
        document
          .getElementById("img-designed")
          .setAttribute("src", e.target.result);
      };
      reader.readAsDataURL(e.target.files[0]);
    }
  }

  return (
    <>
      {props.typeModal === "modalEdit" ? (
        <Tooltip title={t("admin.actions-edit", "Edit")}>
          <EditIcon
            fontSize="small"
            onClick={handleClickOpen}
            className="cursor-pointer"
          />
        </Tooltip>
      ) : (
        <Button
          className="button-table mr-1"
          variant="contained"
          color="secondary"
          onClick={handleClickOpen}
        >
          <AddIcon />
          {t("menu.new-panel-dialog-notifications", "News")}
        </Button>
      )}

      <Dialog
        fullScreen
        open={open}
        onClose={handleClose}
        TransitionComponent={Transition}
      >
        <AppWrapper>
          <AppBar className={classes.appBar}>
            <Toolbar>
              <IconButton edge="start" onClick={handleClose} aria-label="close">
                <CloseIcon />
              </IconButton>
              <Typography variant="h6" className={classes.title}>
                {t(
                  "menu.new-panel-dialog-new-notifications",
                  "New notification"
                )}
              </Typography>
            </Toolbar>
          </AppBar>

          {loading && (
            <div className="div-spinner-modal">
              <Container maxWidth="lg" className={classes.container}>
                {" "}
                <Grid container spacing={1}>
                  <SpinnerCircular size={90} />
                </Grid>
              </Container>
            </div>
          )}

          {!loading && (
            <Container maxWidth="lg" className={classes.container}>
              <Grid container spacing={1}>
                <Grid item xs={12} md={6} lg={6}>
                  <TextField
                    onChange={handleChange}
                    error={titleError}
                    value={title}
                    name="title"
                    id="title"
                    label={t(
                      "menu.new-panel-dialog-title-notifications",
                      "Notification title"
                    )}
                    variant="outlined"
                    className="mt-2"
                    fullWidth
                  />
                </Grid>

                <Grid item xs={12} md={6} lg={6}>
                  <TextField
                    onChange={handleChange}
                    value={link}
                    name="link"
                    id="link"
                    label={t(
                      "menu.new-panel-dialog-link-notifications",
                      "Novelty link"
                    )}
                    variant="outlined"
                    className="mt-2"
                    fullWidth
                  />
                </Grid>

                <Grid item xs={12} md={6} lg={6}>
                  <TextField
                    onChange={handleChange}
                    value={videoLink}
                    name="videoLink"
                    id="videoLink"
                    label={t(
                      "menu.new-panel-dialog-vide-link-notifications",
                      "Novelty Video"
                    )}
                    variant="outlined"
                    className="mt-2"
                    fullWidth
                  />
                </Grid>

                <Grid item xs={12} md={6} lg={6}>
                  <TextField
                    onChange={handleChange}
                    error={messageError}
                    value={message}
                    name="message"
                    id="message"
                    label={t(
                      "menu.new-panel-dialog-message-notifications",
                      "Notification message"
                    ) + ` (${(message? message.length: '0')}/650)`}
                    variant="outlined"
                    className="mt-2"
                    fullWidth
                    inputProps={{ maxLength: 650 }}
                  />
                </Grid>
              </Grid>
              <Grid container spacing={1}>
                <Grid item xs={12} md={6} lg={6}>
                  <TextField
                    id="dateFrom"
                    name="dateFrom"
                    label={t("menu.new-panel-start-date", "Start date")}
                    type="date"
                    variant="outlined"
                    className="mt-2"
                    fullWidth
                    value={dateFrom}
                    error={dateFromError}
                    onChange={handleChange}
                  />
                </Grid>

                <Grid item xs={12} md={6} lg={6}>
                  <TextField
                    id="expirationDate"
                    name="expirationDate"
                    label={t("menu.new-panel-due-date", "Due date")}
                    type="date"
                    variant="outlined"
                    className="mt-2"
                    fullWidth
                    value={expirationDate}
                    error={expirationDateError}
                    onChange={handleChange}
                  />
                </Grid>
              </Grid>
              <Grid container spacing={1}>

                {teamsProps && teamsProps.length > 0 && (
                  <FindRegistersDialog
                    setValue={setTeams}
                    setName={setName}
                    label={t(
                      "admin.user-panel-user-dialog-input-groups",
                      "Groups"
                    )}
                    rowsSelected={teams}
                    rows={teamsProps}
                  />
                )}

                {props.rowsUsers && props.rowsUsers.length > 0 && (
                  <FindRegistersDialog
                    setValue={setUsers}
                    label={t(
                      "menu.trivia-panel-dialog-add-test-select-players",
                      "Players"
                    )}
                    rowsSelected={users}
                    rows={props.rowsUsers}
                  />
                )}


                <Grid
                  item
                  xs={12}
                  md={6}
                  lg={6}
                  className="padding-custom-button"
                >
                  <Grid container spacing={1}>
                    <Grid item xs={10} md={10} lg={10}>
                      <div className="h-100">
                        <input
                          accept="image/*"
                          className={classes.input}
                          id="contained-button-file"
                          type="file"
                          onChange={(e) => handleChangeImage(e)}
                        />
                        <label htmlFor="contained-button-file">
                          <Button
                            variant="contained"
                            color="primary"
                            component="span"
                            className="button-table h-100 w-100"
                          >
                            {!image || image === ""
                              ? " " +
                                t(
                                  "menu.trivia-panel-dialog-add-test-input-image-upload",
                                  "Upload image"
                                )
                              : t(
                                  "menu.trivia-panel-dialog-add-test-input-image-change",
                                  "Change image"
                                )}
                          </Button>
                        </label>
                      </div>
                    </Grid>

                    <Grid
                      item
                      xs={2}
                      md={2}
                      lg={2}
                      className="div-img-designed"
                    >
                      {image && image !== "" && !imageObjectFromDialog && (
                        <img
                          id="img-designed"
                          className="img-predesigned"
                          src=""
                          alt=""
                        />
                      )}
                      {image && image !== "" && imageObjectFromDialog && (
                        <img
                          id="img-designed"
                          className="img-predesigned"
                          src={`${environment.motivarnosBackend}${image}`}
                          alt=""
                        />
                      )}
                    </Grid>
                  </Grid>
                </Grid>

                <Grid item xs={12} md={6} lg={6}>
                  <FormControl
                    variant="outlined"
                    className={classes.formControl}
                  >
                    <InputLabel id="isActive-select-outlined-label">
                      {t(
                        "admin.header-dropdown-view-conditions-table-state",
                        "State"
                      )}
                    </InputLabel>
                    <Select
                      onChange={handleChange}
                      error={isActiveError}
                      value={isActive}
                      labelId="isActive"
                      id="isActive"
                      name="isActive"
                      label={t(
                        "admin.header-dropdown-view-conditions-table-state",
                        "State"
                      )}
                    >
                      <MenuItem value={true}>
                        {t(
                          "admin.header-dropdown-view-conditions-table-active-state",
                          "Active"
                        )}{" "}
                      </MenuItem>
                      <MenuItem value={false}>
                        {t(
                          "admin.header-dropdown-view-conditions-table-blocked-state",
                          "Blocked"
                        )}
                      </MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>
              <Button
                className="button-table mt-2 mr-1"
                onClick={handleClose}
                variant="contained"
                color="secondary"
              >
                {t("menu.trivia-panel-dialog-add-test-button-close", "Close")}
              </Button>
              <Button
                className="button-table mt-2"
                variant="contained"
                color="primary"
                onClick={() => onFormSubmit()}
              >
                {t(
                  "menu.new-panel-dialog-generate-novelty",
                  "Generate novelty"
                )}
              </Button>
            </Container>
          )}
        </AppWrapper>
      </Dialog>
    </>
  );
}

const mapDispatchToProps = {
  saveNewRequest,
  updateNewRequest,
};

export default connect(null, mapDispatchToProps)(NewDialog);
