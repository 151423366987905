import React, { useState } from "react";
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import CloseIcon from "@material-ui/icons/Close";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import { SpinnerCircular } from "spinners-react";
import toastr from "toastr";
import Pagination from "@material-ui/lab/Pagination";
import { useTranslation } from "react-i18next";

import Tooltip from "@material-ui/core/Tooltip";
import Grid from "@material-ui/core/Grid";
import Container from "@material-ui/core/Container";
import { NotRegistersFound } from "../NotRegistersFound";

/********Services********* */
import GeneralService from "../../Services/GeneralService";

/*******Services******* */
import environment from "../../../../../libs/environment";

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  textField: {
    width: "50ch",
    marginBottom: "5px",
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

export default function ImageDialog(props) {
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [rows, setRows] = useState([]);
  const [numberPages, setNumberPages] = useState(1);
  const [page, setPage] = useState(1);
  const { t } = useTranslation();

  const handleClickOpen = () => {
    setOpen(true);
    getImages();
  };
  const handleClose = () => {
    setOpen(false);
  };

  function handleImage(image) {
    props.setImageDialog(image);
    setOpen(false);
  }

  /***********Data Binding Form******* */

  function handleChange(event, value) {
    setPage(value);
    getImages(value);
  }

  function getImages(number = 1) {
    setLoading(true);
    GeneralService.getBadgesImages(number)
      .then((response) => {
        setRows(response.data.content);
        setNumberPages(response.data.totalPages);
        setLoading(false);
      })

      .catch(() => {
        toastr.error(
          t(
            "admin.mission-image-message-error-get-images",
            "An error occurred while trying to get the images"
          )
        );
        setLoading(false);
      });
  }

  return (
    <>
      <Button
        onClick={handleClickOpen}
        variant="contained"
        color="primary"
        component="span"
        className="button-table h-100 w-100"
      >
        {t("admin.lettersoup-image-dialog-default", "Default picture")}
      </Button>

      <Dialog
        maxWidth="sm"
        fullWidth={true}
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <DialogTitle id="customized-dialog-title" onClose={handleClose}>
          {t("admin.mission-image-dialog-predesigned", "Predesigned images")}
        </DialogTitle>

        <DialogContent dividers>
          {loading && (
            <div className="div-spinner-modal">
              <Container maxWidth="lg">
                <Grid container spacing={1}>
                  <SpinnerCircular size={90} />
                </Grid>
              </Container>
            </div>
          )}
          {!loading &&
            (rows && rows.length > 0 ? (
              <Container maxWidth="lg">
                <Grid container spacing={1}>
                  {rows.map((row, index) => (
                    <Tooltip
                      title={t(
                        "admin.mission-image-select-image",
                        "Select image"
                      )}
                      key={index}
                    >
                      <Grid
                        item
                        xs={6}
                        md={6}
                        lg={4}
                        onClick={() => handleImage(row.url)}
                      >
                        <img
                          className="cursor-pointer img-image"
                          src={`${environment.motivarnosBackend}${row.url}`}
                        />
                      </Grid>
                    </Tooltip>
                  ))}
                  <Grid item xs={12} md={12} lg={12}>
                    <Pagination
                      count={numberPages}
                      page={page}
                      color="primary"
                      onChange={(event, value) => handleChange(event, value)}
                    />
                  </Grid>
                </Grid>
              </Container>
            ) : (
              <>
                <Grid container spacing={1}>
                  <Grid item xs={12} md={12} lg={12}>
                    <NotRegistersFound />
                  </Grid>
                </Grid>
              </>
            ))}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="secondary">
            {t("menu.trivia-panel-dialog-add-test-button-close", "Close")}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
