import * as pointsCoinsTypes from "../types/pointsCoinsTypes";

const {
  RESET_STATE,
  RESET_STORE,
  POINTSCOINS_LIST_REQUEST,
  POINTSCOINS_LIST_SAVED,
  POINTSCOINS_LIST_CHARGING,
  POINTSCOINS_LIST_ERROR,
} = pointsCoinsTypes;

const INITIAL_STATE = {
  pointsCoins: [],
  error_pointsCoins: false,
  pointsCoins_charging: false,
  filter: "",
  totalElements: 0,
  totalPages: 1,
  pages: [],
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case POINTSCOINS_LIST_REQUEST:
      return {
        ...state,
        pointsCoins_charging: false,
        pointsCoins:
          state.filter !== action.payload.filter
            ? [...action.payload.content]
            : [...state.pages].includes(action.payload.number)
            ? [...state.pointsCoins]
            : [...state.pointsCoins, ...action.payload.content],
        totalElements: action.payload.totalElements,
        totalPages: action.payload.totalPages,
        filter: action.payload.filter,
        pages: [...state.pages].includes(action.payload.number)
          ? [...state.pages]
          : [...state.pages, action.payload.number],
        error_pointsCoins: false,
      };

    case POINTSCOINS_LIST_CHARGING:
      return { ...state, pointsCoins_charging: true, error_pointsCoins: false };

    case POINTSCOINS_LIST_ERROR:
      return {
        ...state,
        error_pointsCoins: action.payload,
        pointsCoins_charging: false,
        pointsCoins_save_charging: false,
      };

    case POINTSCOINS_LIST_SAVED:
      return {
        ...state,
        pointsCoins_charging: false,
        error_pointsCoins: false,
      };

    case RESET_STATE:
      return {
        ...state,
        error_pointsCoins: false,
        pointsCoins_charging: false,
        pointsCoins_save_charging: false,
      };

    case RESET_STORE:
      return {
        ...state,
        pointsCoins: [],
        error_pointsCoins: false,
        pointsCoins_charging: false,
        pointsCoins_save_charging: false,
        totalElements: 0,
        totalPages: 1,
        pages: [],
      };

    default:
      return state;
  }
};
