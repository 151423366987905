import React, { useState } from "react";
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Typography from "@material-ui/core/Typography";
import { SpinnerCircular } from "spinners-react";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@material-ui/core/styles";
import Tooltip from "@material-ui/core/Tooltip";
import VisibilityIcon from "@material-ui/icons/Visibility";
import { es } from "date-fns/locale";
import GeneralFunctions from "../../libs/GeneralFunctions";

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
  },
  margin: {
    margin: theme.spacing(1),
  },
  withoutLabel: {
    marginTop: theme.spacing(3),
  },
  formControl: {
    width: "100%",
    marginTop: "1rem",
  },
}));

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

export default function DetailsTournament(props) {
  const classes = useStyles();

  const [open, setOpen] = useState(false);
  const { t, i18n } = useTranslation();
  const [loading] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Tooltip
        title={t("menu.metric-panel-dialog-show-detail", "Show details")}
      >
        <VisibilityIcon
          fontSize="small"
          className="cursor-pointer"
          onClick={handleClickOpen}
        />
      </Tooltip>

      <Dialog
        maxWidth="sm"
        fullWidth={true}
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <DialogTitle id="customized-dialog-title" onClose={handleClose}>
          {t("menu.metric-panel-dialog-show-detail", "Show details")}
        </DialogTitle>
        <DialogContent dividers>
          {loading && (
            <div className="div-spinner-modal">
              <Container maxWidth="lg" className={classes.container}>
                <Grid container spacing={1}>
                  <SpinnerCircular size={90} />
                </Grid>
              </Container>
            </div>
          )}

          {!loading && (
            <>
              <h3 className="mb-0 mt-0">{t("created-by", "Created by")}</h3>
              <p className="mb-0 mt-0"> {props.data.createdBy}</p>

              <h3 className="mb-0">
                {t("tournament-type", "Tournament type")}
              </h3>
              <p className="mb-0 mt-0">
                {" "}
                {props.data.conditionType === "GREAT_THAN"
                  ? t("me-above.label", "Me above")
                  : t("me-below.label", "Me below")}
              </p>

              <hr className="hr-styles" />
              <h3 className="mb-0 mt-0">
                {t("admin.user-matches", "Matches")}
              </h3>

              {props.data.matches && props.data.matches.length > 0 && (
                <>
                  {props.data.matches.map((match, index) => (
                    <div key={index}>
                      <h3 className="mb-0">
                        {t("admin.user-match", "Match")} {index + 1} (
                        {GeneralFunctions.formatNewDate(
                          match.date1,
                          i18n.language === "es" ? es : ""
                        )}
                        )
                      </h3>
                      <p className="mb-0 mt-0">
                        {" "}
                        {match.status === "COMPLETED" &&
                          t("menu.award-report-table-completed", "Completed")}
                        {match.status === "VOIDED" &&
                          t("menu.award-report-table-rejected", "Rejected")}
                        {match.status === "IN_PROGRESS" &&
                          t(
                            "menu.award-report-table-in-progress",
                            "In progress"
                          )}
                      </p>

                      {match.player1 && match.player2 ? (
                        <>
                          <h3 className="mb-0">
                            {t(
                              "menu.trivia-panel-dialog-add-test-select-players",
                              "Players"
                            )}
                          </h3>
                          <p className="mb-0 mt-0">
                            -{" "}
                            {match.player1 &&
                              match.player1.name + " " + match.player1.lastName}
                          </p>
                          <p className="mb-0 mt-0">
                            -{" "}
                            {match.player2 &&
                              match.player2.name + " " + match.player2.lastName}
                          </p>

                          <h3 className="mb-0">
                            {t("winners.label", "Winners")}
                          </h3>

                          {match.winner ? (
                            <>
                              <p className="mb-0 mt-0">
                                -{" "}
                                {match.winner.name +
                                  " " +
                                  match.winner.lastName}{" "}
                              </p>
                            </>
                          ) : (
                            <p className="mb-0 mt-0">
                              - {t("not-winners.label", "No winners")}
                            </p>
                          )}
                        </>
                      ) : (
                        <h3 className="mb-0">
                          {t(
                            "menu.trivia-panel-dialog-add-test-select--without-players",
                            "Without Players"
                          )}
                        </h3>
                      )}

                      <hr className="hr-styles" />
                    </div>
                  ))}
                </>
              )}
            </>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="secondary">
            {t("menu.trivia-panel-dialog-add-test-button-close", "Close")}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
