import React from 'react';

import './styles.scss';

const Loader = (props) => {
    return (
        <div className='loader-wrapper'>
            <span className='loader-item'/>
            <span className='loader-item'/>
            <span className='loader-item'/>
            <span className='loader-item'/>
            <span className='loader-item'/>
        </div>
    )
}

export default Loader;