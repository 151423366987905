import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Redirect } from "react-router-dom";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import Button from "@material-ui/core/Button";
import {
  Container,
  Dialog,
  DialogActions,
  DialogContent,
} from "@material-ui/core";
import DialogTitle from "../../../../common/controls/DialogTitle";
import { buttonStyles } from "../../../../styles/styles";
import { useDispatch, useSelector } from "react-redux";
import ObjectiveData from "./ObjectiveData";
import BasicData from "./BasicData";
import FinishPage from "./FinishPage";
import { createMetric } from "./service/MetricService";
import useAsyncReducer from "../../../../useAsyncReducer";
import { exposedMetricsReducer } from "./store/MetricsReducer";

function getSteps() {
  return [
    "Basic data of the metric",
    "Set metric points and ranges",
    "Finish and continue",
  ];
}

const useReduxSelector = () =>
  useSelector((state) => ({
    name: state.metricsReducer?.name,
    frequency: state.metricsReducer?.frequency,
    description: state.metricsReducer?.description,
    type: state.metricsReducer?.type,
    metricType: state.metricsReducer?.metricType,
    points: state.metricsReducer?.points,
    objective: state.metricsReducer?.objective,
    lowRange: state.metricsReducer?.lowRange,
    mediumRange: state.metricsReducer?.mediumRange,
    highRange: state.metricsReducer?.highRange,
    YesNoRange: state.metricsReducer?.YesNoRange,
    session: state.authReducer.session,
  }));

const MetricsModal = (props) => {
  useAsyncReducer([exposedMetricsReducer]);
  const {
    name,
    frequency,
    description,
    type,
    metricType,
    points,
    objective,
    lowRange,
    mediumRange,
    highRange,
    YesNoRange,
    session,
  } = useReduxSelector();

  const buttonClasses = buttonStyles();
  const [activeStep, setActiveStep] = useState(0);
  const steps = getSteps();
  const [open, setOpen] = useState(props.open);
  const [redirect, setRedirect] = useState("");
  const isValidBasic =
    (name !== undefined || name !== " ") &&
    frequency !== undefined &&
    type !== undefined &&
    points !== undefined;
  const isValidRanges =
    activeStep === 1 &&
    ((lowRange !== undefined &&
      mediumRange !== undefined &&
      highRange !== undefined) ||
      YesNoRange !== undefined);
  const isValid = isValidBasic && (isValidRanges || activeStep !== 1);

  console.log(isValid);
  function getStep(step) {
    switch (step) {
      case 0:
        return <BasicData></BasicData>;
      case 1:
        return <ObjectiveData></ObjectiveData>;
      case 2:
        return <FinishPage></FinishPage>;
      default:
        return "Unknown step";
    }
  }

  const handleNext = () => {
    console.log(activeStep);
    if (points === 0 && activeStep === 0) {
      setActiveStep(activeStep + 2);
      return;
    }
    if (isValidRanges) {
      createMetric({
        description: description,
        frequency: frequency,
        highRange: highRange,
        lowRange: lowRange,
        yesNoRange: YesNoRange,
        mediumRange: mediumRange,
        metricType: metricType,
        name: name,
        objective: objective,
        points: points,
        token: session.token,
        type: type,
      });
    }
    setActiveStep((prevActiveStep) =>
      prevActiveStep < steps.length ? prevActiveStep + 1 : prevActiveStep
    );
  };
  const handlePrevious = () => {
    if (points === 0 && activeStep === 2) {
      setActiveStep(0);
      return;
    }
    setActiveStep((prevActiveStep) =>
      prevActiveStep > 0 ? prevActiveStep - 1 : prevActiveStep
    );
  };
  return (
    <>
      {redirect !== "" ? <Redirect to={redirect}></Redirect> : null}
      <Dialog open={open} fullScreen={true}>
        <DialogTitle onClose={() => setRedirect("/main")}>
          <Container>
            <Stepper activeStep={activeStep}>
              {steps.map((label, index) => {
                const stepProps = {};
                const labelProps = {};
                return (
                  <Step key={label} {...stepProps}>
                    <StepLabel {...labelProps}>{label}</StepLabel>
                  </Step>
                );
              })}
            </Stepper>
          </Container>
        </DialogTitle>
        <DialogContent>{getStep(activeStep)}</DialogContent>
        <DialogActions>
          <Container
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            <Button
              onClick={() => {
                setRedirect("/metricsPanel");
              }}
              className={buttonClasses.buttonRed}
            >
              Skip
            </Button>
            <Button
              onClick={handlePrevious}
              className={buttonClasses.buttonPink}
            >
              Previous
            </Button>
            <Button
              disabled={!isValid}
              onClick={
                activeStep < steps.length - 1
                  ? handleNext
                  : () => {
                      setRedirect("/metricsPanel");
                    }
              }
              className={buttonClasses.buttonPurple}
            >
              {activeStep >= 2 ? "Finish" : "Next"}
            </Button>
          </Container>
        </DialogActions>
      </Dialog>
    </>
  );
};
export default MetricsModal;
