import React, { useState } from "react";

import PropTypes from 'prop-types';
import MaskedInput from 'react-text-mask';

import { FormattedMessage } from "react-intl";
import { TextField } from "@material-ui/core";
import { useTranslation } from 'react-i18next';

function TextMaskCustom(props) {
    const { inputRef, ...other } = props;
  
    return (
      <MaskedInput
        {...other}
        ref={(ref) => {
          inputRef(ref ? ref.inputElement : null);
        }}
        mask={['(', /[1-9]/, /\d/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/]}
        placeholderChar={'\u2000'}
        showMask
      />
    );
  }
  
  TextMaskCustom.propTypes = {
    inputRef: PropTypes.func.isRequired,
  };

const PhoneControl = (props) => {

    const [phone, setPhone] = useState(props.phone);
    const { t, i18n } = useTranslation();

    const changePhone = (phone) => {
      setPhone(phone);
      if(props.onChange) {
          props.onChange(phone);
      }
    }

    return <TextField
                id="maskExample"
                label={<FormattedMessage
                  id="landing.phoneLabel"
                  defaultMessage={t('phone.label','Phone')}
                />}
                className={props.className}
                style={{marginBottom:12}}
                variant="outlined"
                InputProps={{
                  inputComponent: TextMaskCustom,
                  value: phone,
                  onChange: (e) => changePhone(e.target.value),
                }}
              />

}
export default PhoneControl;