import React, { useState, useEffect } from "react";
import { merge } from "lodash";
import ReactApexChart from "react-apexcharts";
import { useTranslation } from "react-i18next";

import ServiceDashboard from "../Services/ServiceDashboard";
import { SpinnerCircular } from "spinners-react";
import { NotRegistersFound } from "../common/NotRegistersFound";
import Grid from "@material-ui/core/Grid";
import Container from "@material-ui/core/Container";
import Pagination from "@material-ui/lab/Pagination";

// material
import { Box, Card, CardHeader } from "@material-ui/core";
// utils
import { fNumber } from "./libs/formatNumber";
//
import { BaseOptionChart } from "./libs";

// ----------------------------------------------------------------------

export default function ChallengesBar() {
  const { t } = useTranslation();

  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(1);
  const [numberPages, setNumberPages] = useState(1);

  const [chartData, setChartData] = useState(null);
  const [chartOptions, setChartOptions] = useState(
    merge(BaseOptionChart(), {
      tooltip: {
        marker: { show: false },
        y: {
          formatter: (seriesName) => fNumber(seriesName),
          title: {
            formatter: (seriesName) => `${seriesName}: `,
          },
        },
      },
      plotOptions: {
        bar: {
          vertical: true,
          barHeight: "28%",
          borderRadius: 2,
        },
      },
      xaxis: {
        categories: null,
      },
      yaxis: {
        labels: {
          formatter: (value) => {
            return parseInt(value);
          },
        },
      },
    })
  );

  useEffect(() => {
    getTournamentCompact();
    // eslint-disable-next-line
  }, []);

  const getTournamentCompact = (number = 1) => {
    ServiceDashboard.tournamentCompact(number)
      .then((response) => {
        if (response.data && response.data.content.length > 0) {
          setDataChart(response.data.content);
          setNumberPages(response.data.totalPages);
        }
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  };

  const setDataChart = (data) => {
    let dataArray = [...data];
    let categoriesData = [];

    let activePercentData = [];

    for (let i = 0; i < dataArray.length; i++) {
      activePercentData.push(dataArray[i].matchCompletedCount);
      categoriesData.push(dataArray[i].name);
    }

    let chartOptionsOriginal = { ...chartOptions };
    chartOptionsOriginal.xaxis.categories = categoriesData;
    setChartOptions(chartOptionsOriginal);

    setChartData([
      {
        name: t("admin.user-matches", "Matches"),
        type: "column",
        data: activePercentData,
      },
    ]);
  };

  const handleChange = (event, value) => {
    setPage(value);
    setLoading(true);
    getTournamentCompact(value);
  };

  return (
    <>
      {loading ? (
        <Container>
          <Grid container spacing={1} className="justify-content-center">
            <SpinnerCircular size={90} height={570} />
          </Grid>
        </Container>
      ) : (
        <>
          {chartData && chartOptions ? (
            <Card>
              <CardHeader title={t("matches-played.label", "Matches played")} />
              <Box sx={{ mx: 3 }} dir="ltr">
                <ReactApexChart
                  type="bar"
                  series={chartData}
                  options={chartOptions}
                  height={420}
                />
                <Pagination
                  className="mt-2 mb-2"
                  count={numberPages}
                  page={page}
                  color="primary"
                  onChange={(event, value) => handleChange(event, value)}
                />
              </Box>
            </Card>
          ) : (
            <Grid container spacing={1}>
              <Grid item xs={12} md={12} lg={12}>
                <NotRegistersFound />
              </Grid>
            </Grid>
          )}
        </>
      )}
    </>
  );
}
