export const RESET_STATE = "RESET_STATE";
export const RESET_STORE = "RESET_STORE";

export const AWARDS_LIST_REQUEST = "AWARDS_LIST_REQUEST";
export const AWARDS_LIST_SAVED = "AWARDS_LIST_SAVED";

export const AWARDS_LIST_SAVE = "AWARDS_LIST_SAVE";
export const AWARDS_LIST_UPDATE = "AWARDS_LIST_UPDATE";
export const AWARDS_LIST_DELETE = "AWARDS_LIST_DELETE";

export const AWARDS_LIST_CHARGING = "AWARDS_LIST_CHARGING";
export const AWARDS_SAVE_CHARGING = "AWARDS_SAVE_CHARGING";

export const AWARDS_LIST_ERROR = "AWARDS_LIST_ERROR";
