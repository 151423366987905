import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Box from "@material-ui/core/Box";
import HallFameQuarter from "./HallFameQuarter";
import { useTranslation } from "react-i18next";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={3}>{children} </Box>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
}));

export default function HallFameTab({ hallFame }) {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);
  const { t } = useTranslation();

  const [month] = React.useState([
    t("january", "January"),
    t("february", "February"),
    t("march", "March"),
    t("april", "April"),
    t("may", "May"),
    t("june", "June"),
    t("july", "July"),
    t("august", "August"),
    t("september", "September"),
    t("october", "October"),
    t("november", "November"),
    t("december", "December"),
  ]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div className={classes.root}>
      <AppBar position="static">
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="simple tabs example"
        >
          {hallFame.map((row, index) => {
            return <Tab label={month[row.month - 1]} {...a11yProps(index)} />;
          })}
        </Tabs>
      </AppBar>

      {hallFame.map((row, index) => {
        return (
          <>
            <TabPanel value={value} index={index} key={index} style={{ marginBottom: "4em" }}>
              <HallFameQuarter hallFame={row.ranking} />
            </TabPanel>
          </>
        );
      })}
    </div>
  );
}
