import React, { useState } from "react";
import Button from "../../../components/Button";
import Dialog from "../../../components/Dialog";

import star from "../../../assets/img/star.svg";
import LiveHelpIcon from '@material-ui/icons/LiveHelp';
import ThumbUpAltIcon from '@material-ui/icons/ThumbUpAlt';
import resumen_trivia from "../../../assets/img/resumen-trivia.svg";
import TableChartIcon from '@material-ui/icons/TableChart';
import AlarmIcon from '@material-ui/icons/Alarm';
import ControlPointDuplicateIcon from '@material-ui/icons/ControlPointDuplicate';
import CheckIcon from '@material-ui/icons/Check';
import ClearIcon from '@material-ui/icons/Clear';
import PeopleIcon from '@material-ui/icons/People';

import "./styles.scss";
import "toastr/build/toastr.min.css";

const SummaryDialog = (props) => {
  const [open, setOpen] = useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  return (
    <>
    {!open ? (
      <>
        <Button className="button-activity" onClick={handleOpen}>
          {/*<div className="text-inner-button-activity">VER RESUMEN</div>*/}
          VER RESUMEN
          <div className="icon-inner-button-activity"><TableChartIcon style={{fill: 'white', marginLeft: '8px'}}/></div>
        </Button>
      </>
    ) : (
      <Dialog
        onClose={handleClose}
        open={open}
        className="resumen-dialog-wrapper"
      >
        <div className="resumen-wrapper">
          <img className="osito-resumen" src={resumen_trivia} alt="" />
          <span className="resumen-title">
            Resumen de Trivia
          </span>

          <div className="stars-conteainer">
            <span>
              <img src={star} alt="" />
            </span>
            <span>
              <img src={star} alt="" />
            </span>
            <span>
              <img src={star} alt="" />
            </span>
          </div>
        </div>
        <div className="resumen-info">
          <div className="resumen-info-item">
            <div className="resumen-info-item-icon"><LiveHelpIcon className="quantity-asnwers"/></div>
            <div className="resumen-info-item-description">Cantidad de Preguntas</div>
            <div className="resumen-info-item-quantity">{props.data.totalQuestion}</div>
          </div>
          <div className="resumen-info-item">
            <div className="resumen-info-item-icon"><CheckIcon className="check"/></div>
            <div className="resumen-info-item-description">Respuestas Correctas</div>
            <div className="resumen-info-item-quantity">{props.data.correctAnswerQty}</div>
          </div>
          <div className="resumen-info-item">
            <div className="resumen-info-item-icon"><ClearIcon className="clear"/></div>
            <div className="resumen-info-item-description">Respuestas Incorrectas</div>
            <div className="resumen-info-item-quantity">{props.data.inCorrectAnswerQty}</div>
          </div>
          <div className="resumen-info-item">
            <div className="resumen-info-item-icon"><ThumbUpAltIcon className="goals"/></div>
            <div className="resumen-info-item-description">Respuestas Vencidas</div>
            <div className="resumen-info-item-quantity">{props.data.expiredAnswerQty}</div>
          </div>
          <div className="resumen-info-item">
            <div className="resumen-info-item-icon"><ControlPointDuplicateIcon className="auto-mode"/></div>
            <div className="resumen-info-item-description">Puntos Totales</div>
            <div className="resumen-info-item-quantity">{props.data.totalPoints}</div>
          </div>
          <div className="resumen-info-item">
            <div className="resumen-info-item-icon"><PeopleIcon className="groups"/></div>
            <div className="resumen-info-item-description">Puntos de Equipo</div>
            <div className="resumen-info-item-quantity">{props.data.pointsByTeam}</div>
          </div>
          <div className="resumen-info-item">
            <div className="resumen-info-item-icon"><AlarmIcon className="time"/></div>
            <div className="resumen-info-item-description">Tiempo de respuesta</div>
            <div className="resumen-info-item-quantity">{props.data.triviaAnswerTimeStr}</div>
          </div>
        </div>
        <div className="resumen-footer">
          <Button
            className={"button-activity"}
            onClick={handleClose}
          >
            VOLVER A TRIVIAS
          </Button>
        </div>
      </Dialog>
    )}
  </>
  );
};


export default SummaryDialog;
