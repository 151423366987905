import React from "react";
import Avatar from "@material-ui/core/Avatar";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import ListItemText from "@material-ui/core/ListItemText";
import Dialog from "@material-ui/core/Dialog";
import { useTranslation } from "react-i18next";

const LanguageSelector = ({ handleClose, open }) => {
  const { i18n } = useTranslation();

  const handleChange = (loc) => {
    i18n.changeLanguage(loc);
    handleClose();
  };

  return (
    <Dialog fullWidth onClose={handleClose} open={open}>
      <List>
        <ListItem
          button
          onClick={() => handleChange("en")}
          key={"en"}
          className="list-language"
        >
          <ListItemAvatar>
            <Avatar>EN</Avatar>
          </ListItemAvatar>
          <ListItemText primary={"English"} />
        </ListItem>

        <ListItem
          button
          onClick={() => handleChange("es")}
          key={"es"}
          className="list-language"
        >
          <ListItemAvatar>
            <Avatar>ES</Avatar>
          </ListItemAvatar>
          <ListItemText primary={"Español"} />
        </ListItem>
      </List>
    </Dialog>
  );
};

export default LanguageSelector;
