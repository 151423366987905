import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Button from "../../components/Button";
import gift from "../../assets/img/gift-box.svg";
import money from "../../assets/img/money.svg";
import stock from "../../assets/img/box.svg";
import PrizeServices from "../../Services/Prize/Player";
import { SpinnerCircular } from "spinners-react";
import toastr from "toastr";
import EmptyMessage from "../../components/EmptyMessage";
import { es } from "date-fns/locale";

import GeneralFunctions from "components/app/admin/libs/GeneralFunctions";

import "./styles.scss";

const PrizeCart = (props) => {
  const [fetching, setFetching] = useState(false);

  const [userSession] = useState(JSON.parse(localStorage.getItem("sesion")));
  const { t } = useTranslation();

  const handlePrizeRequest = (id) => {
    setFetching(true);
    PrizeServices.requestPrize({
      giftItem: { id: id },
    })
      .then((resp) => {
        props.onUpdate();
        setFetching(false);
        const newSessionState = userSession;
        newSessionState.user.coinBalance = newSessionState.user.coinBalance - props.points;
        localStorage.setItem('sesion', JSON.stringify(newSessionState))
        props.changeSessionState(newSessionState);
      })
      .catch((error) => {
        if (error.response.status === 422) {
          toastr.error(error.response.data.message);
        } else {
          toastr.error("Ha ocurrido un error obteniendo los premios.");
        }
        setFetching(false);
      });
  };

  return (
    <div
      className={
        `prize-cart-wrapper ` +
        (userSession.user &&
        userSession.user.coinBalance &&
        userSession.user.coinBalance < props.points
          ? "bg-without-points"
          : "")
      }
    >
      {props.stock ? (
        <div className="stock-counter">
          <img src={stock} alt="" />
          {props.stock}
        </div>
      ) : (
        ""
      )}
      <div
        className="prize-img-container"
        style={{ backgroundImage: `url(${props.img})` }}
      ></div>
      <div className="prize-content">
        <span className="prize-points">
          <span className="gift-icon">
            <img src={gift} alt="" />
          </span>
          {props.points} {t("coins.label")}
        </span>
        <span className="prize-title">{props.title}</span>
        <span className="prize-description">{props.description}</span>

        {userSession.user &&
          userSession.user.coinBalance &&
          userSession.user.coinBalance >= props.points && (
            <span className="prize-btn">
              <Button
                onClick={() => handlePrizeRequest(props.id)}
                disabled={fetching}
              >
                {props.btnText}
              </Button>
            </span>
          )}
      </div>
    </div>
  );
};

PrizeCart.defaultProps = {
  points: "",
  title: "",
  description: "",
  btnText: "",
  img: null,
  stock: null,
};

const Prizes = (props) => {
  const [sesion, setSesion] = useState(null);
  const [prizes, setPrizes] = useState(null);
  const [prizesHistory, setPrizesHistory] = useState(null);
  const { t, i18n } = useTranslation();

  const getData = () => {
    setPrizes(null);
    setPrizesHistory(null);

    PrizeServices.getPrizes()
      .then((resp) => {
        setPrizes(resp.data.content);
      })
      .catch((error) => {
        toastr.error("Ha ocurrido un error obteniendo los premios.");
      });

    PrizeServices.getPrizesHistory()
      .then((resp) => {
        setPrizesHistory(resp.data.content);
      })
      .catch((error) => {
        toastr.error("Ha ocurrido un error obteniendo historial de premios.");
      });
  };

  const changeSessionState = (newSessionState) => {
    setSesion(newSessionState)
  }

  useEffect(() => {
    let userSesion = JSON.parse(localStorage.getItem("sesion"));
    setSesion(userSesion);

    getData();
  }, []);

  function replaceAll(string, search, replace) {
    if (string === null) return "";
    return string.split(search).join(replace);
  }

  if (sesion === null) return "";
  else
    return (
      <div className="prizes-wrapper">
       {/*  <div className="sum-points">
          <img src={money} alt="" /> {sesion.user.coinBalance}
        </div> */}
        <div className="prizes-title">{t("awards.label.title")}</div>
        <div className="prizes-content">
          {prizes === null ? (
            <span className="spinner-container">
              <SpinnerCircular />
            </span>
          ) : (
            prizes.map((data, index) => {
              return (
                <PrizeCart
                  id={data.id}
                  points={data.price}
                  title={data.name}
                  description={data.description}
                  btnText={t("redeem.label")}
                  img={"/api" + data.image}
                  stock={data.actualStock}
                  key={index}
                  changeSessionState={changeSessionState}
                  onUpdate={() => {
                    getData();
                  }}
                />
              );
            })
          )}
          {prizes !== null && prizes.length === 0 ? <EmptyMessage /> : ""}
        </div>
        <div className="prizes-title-report">{t("report.label.title")}</div>

        {prizesHistory === null ? (
          <span className="spinner-container">
            <SpinnerCircular />
          </span>
        ) : prizesHistory !== null && prizesHistory.length === 0 ? (
          <EmptyMessage />
        ) : (
          <div className="prizes-history-container">
            <table className="prizes-history-table">
              <thead>
                <tr>
                  <th>{t("product.label")}</th>
                  <th>{t("coins.label")}</th>
                  <th>{t("request.label")}</th>
                  <th>{t("status.label")}</th>
                </tr>
              </thead>

              <tbody>
                {prizesHistory.map((data, index) => {
                  let regex = /T(.)*/;
                  let date = replaceAll(
                    data.giftItem.updated,
                    "-",
                    "/"
                  ).replace(regex, "");
                  return (
                    <tr className="table-content" key={index}>
                      <td>{data.giftItem.name}</td>
                      <td>{data.giftItem.price}</td>
                      <td>{GeneralFunctions.formatNewDate(date, es)}</td>
                      <td>
                        <span className="prize-status">
                          {data.status === "COMPLETED" &&
                            t("delivered.label", "Delivered")}
                          {data.status === "VOIDED" &&
                            t("rejected.label", "Rejected")}
                          {data.status === "IN_PROGRESS" &&
                            t("order.label", "Order")}
                        </span>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        )}
      </div>
    );
};

export default Prizes;
