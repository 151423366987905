import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";

import TriviasResponses from "../charts/TriviasResponses";
import SkillsByTeams from "../charts/SkillsByTeams";
import BadgesByTeams from "../charts/BadgesByTeams";
import ChallengesBar from "../charts/ChallengesBar";
import MissionsApproved from "../charts/MissionsApproved";
import TournamentPlayed from "../charts/TournamentPlayed";

/*********TABLES********* */
import TableRanking from "./DashboardTables/TableRanking";
import TableAwards from "./DashboardTables/TableAwards";

const useStyles = makeStyles((theme) => ({
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
  },
  fixedHeight: {
    height: 240,
  },

  containerDefault: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
  },

  containerDefaultMetrics: {
    height: "100%",
  },
}));

export default function Dashboard() {
  const classes = useStyles();

  return (
    <main className={classes.content}>
      <div className={classes.appBarSpacer} />
      {
        <Container maxWidth="lg" className={classes.container}>

          <Grid container spacing={3}>
            <Grid item xs={12} sm={7} md={7}>
              <TriviasResponses />
            </Grid>

            <Grid item xs={12} sm={5} md={5}>
              <SkillsByTeams />
            </Grid>

            <Grid item xs={12} sm={6} md={6}>
              <BadgesByTeams />
            </Grid>

            <Grid item xs={12} sm={6} md={6}>
              <MissionsApproved />
            </Grid>
            <Grid item xs={12} sm={12} md={12}>
              <TableRanking />
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
              <TournamentPlayed />
            </Grid>

            <Grid item xs={12} sm={6} md={6}>
              <ChallengesBar />
            </Grid>
            <Grid item xs={12} sm={12} md={12}>
              <TableAwards />
            </Grid>
          </Grid>
        </Container>
      }
    </main>
  );
}
