import { createStore, applyMiddleware } from "redux";
import thunk from "redux-thunk";
import { createReducer } from "./index";
import { composeWithDevTools } from "redux-devtools-extension";

export default function configureStore(initialState) {
  let store = createStore(
    createReducer(),
    initialState,
    composeWithDevTools(applyMiddleware(thunk))
  );
  store.asyncReducers = {};
  return store;
}

export const injectAsyncReducer = (store, name, asyncReducer) => {
  store.asyncReducers[name] = asyncReducer;
  store.replaceReducer(createReducer(store.asyncReducers));
};
