import React from "react";
import { es } from "date-fns/locale";
import { withStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import Container from "@material-ui/core/Container";
import Button from "@material-ui/core/Button";
import toastr from "toastr";
import { SpinnerCircular } from "spinners-react";
import TextField from "@material-ui/core/TextField";
import { withTranslation } from "react-i18next";
import GeneralFunctions from "../../libs/GeneralFunctions";
import TablePagination from "@material-ui/core/TablePagination";

import { connect } from "react-redux";

import {
  getPointsCoinsRequest,
  getPointsCoinsFilteredRequest,
} from "../../../../../actions/pointsCoinsActions";

import "toastr/build/toastr.min.css";
/********Dialogs***** */
import DownloadTransactionsReportDialog from "../../common/Dialogs/DownloadTransactionsReportDialog";

import { NotRegistersFound } from "../../common/NotRegistersFound";

/*****Componentes */

/********Services***** */
import ReportsService from "../../Services/ReportsService";
/*********Icons********* */
import ViewListIcon from "@material-ui/icons/ViewList";

const styles = (theme) => ({
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
  },
  fixedHeight: {
    height: 240,
  },
  root: {
    "& > *": {
      marginTop: theme.spacing(2),
    },
  },
  margin: {
    margin: theme.spacing(1),
  },
  textField: {
    width: "50ch",
    marginBottom: "5px",
  },
});

class PointsCoins extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      filter: "",
      page: 0,
      rowsPerPage: 5,
      filter: ""
    };
  }

  componentDidMount() {
    this.props.getPointsCoinsRequest({ number: 1, reload: false });
  }

  handleChange(event, value) {
    this.setState({ page: value });
    const { filter } = this.state;
  
    if (filter !== "" && filter) {
      this.props.getPointsCoinsFilteredRequest({
        number: value + 1,
        filter: filter,
      });
    } else {
      this.props.getPointsCoinsRequest({ number: value + 1, reload: false });
    }
  }
  

  filterValue(number, value) {
    this.setState({ filter: value });
    if (value.length > 3 || value.length == 0) {
      this.setState({ loading: true });
    }
    
    setTimeout(() => {
      if (value == this.state.filter) {
        if (value && (value.length > 3 || value.length == 0)) {
          this.setState({ page: 0 });
          this.setState({ loading: false });

          this.props.getPointsCoinsFilteredRequest({
            number: number,
            filter: value,
          });
        }
        
        if (value === "") {
          this.setState({ page: 0 });

          setTimeout(() => {
            this.setState({ loading: false });
            this.props.getPointsCoinsRequest({ number: 1, reload: true });
          }, 2000);
        }
      }
    }, '2000');  
  }
  
  downloadAccountTransaction() {
    this.setState({ loading: true });
    ReportsService.downloadAccountTransaction()
      .then((response) => {
        var blob = response.data;
        var link = document.createElement("a");
        link.href = window.URL.createObjectURL(new Blob([blob]));
        link.download = "Transaction.csv";
        link.click();
        this.setState({ loading: false });
      })
      .catch(() => {
        toastr.error(
          this.props.t(
            "menu.points-coins-message-error-download-transactions",
            "An error occurred while trying to download transactions"
          )
        );
        this.setState({ loading: false });
      });
  }

  render() {
    const { loading, filter, page, rowsPerPage } = this.state;
    const { classes, t, i18n } = this.props;

    return (
      <main className={classes.content}>
        <div className={classes.appBarSpacer} />

        {/*********************OTHER TABLE********************* */}

        <Container maxWidth="lg" className={classes.container}>
          <Grid container spacing={1}>
            <Grid item xs={12} md={6} lg={3} style={{display: 'flex', minWidth: '300px'}}>
              <Button
                className="button-table"
                variant="contained"
                color="secondary"
                style={{width: '100%'}}
              >
                <ViewListIcon className="mr-1" />
                {t(
                  "menu.points-coins-report-transactions",
                  "Report transactions"
                )}
              </Button>
            </Grid>
            
            <Grid item xs={12} md={6} lg={3} style={{display: 'flex', minWidth: '300px'}}>
              <DownloadTransactionsReportDialog
                rowsSelected={[]}
                rows={[]}
              />
            </Grid>

            <Grid item xs={12} md={6} lg={3} style={{display: 'flex', minWidth: '300px'}}>
              <TextField
                id="filterAccountTransaction-outlined-basic"
                label={t("menu.metric-panel-search", "Search")}
                variant="outlined"
                value={filter}
                name="filterAccountTransaction"
                className={classes.textField}
                onChange={(e) => this.filterValue(1, e.target.value)}
                style={{width: '100%'}}
              />
            </Grid>
          </Grid>
          {(loading || this.props.pointsCoinsReducer.pointsCoins_charging) && (
            <div className="div-spinner">
              <Grid container spacing={1}>
                <SpinnerCircular size={90} />
              </Grid>
            </div>
          )}
          {!loading &&
            !this.props.pointsCoinsReducer.pointsCoins_charging &&
            (this.props.pointsCoinsReducer.pointsCoins &&
            this.props.pointsCoinsReducer.pointsCoins.length > 0 ? (
              <Grid container spacing={1}>
                <TableContainer component={Paper}>
                  <Table className={classes.table} aria-label="simple table">
                    <TableHead>
                      <TableRow>
                        <TableCell align="left">
                          {t("user.label", "User")}
                        </TableCell>
                        <TableCell align="left">
                          {t("type.label", "Type")}
                        </TableCell>
                        {/*<TableCell align="left">
                          {t("coins.label", "Coins")}
            </TableCell>*/}
                        <TableCell align="left">
                          {t("points.label", "Points")}
                        </TableCell>

                        {/*<TableCell align="left">
                          {t(
                            "menu.points-coins-table-balance-coins",
                            "Balance coins"
                          )}
                          </TableCell>*/}
                        <TableCell align="left">
                          {t(
                            "annual-cumulative",
                            "annual cumulative"
                          )}
                        </TableCell>

                        <TableCell align="left">
                          {t(
                            "admin.header-dropdown-view-conditions-table-team",
                            "Team"
                          )}
                        </TableCell>

                        <TableCell align="left">
                          {t("date.label", "Date")}
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {this.props.pointsCoinsReducer.pointsCoins
                        .slice(
                          page * rowsPerPage,
                          page * rowsPerPage + rowsPerPage
                        )
                        .map((row) => (
                          <TableRow key={row.id}>
                            <TableCell align="left">
                              {row.user.username}
                            </TableCell>

                            <TableCell align="left">
                              {row.type === "TRIVIA" &&
                                t("menu.rigth-list-item-trivia", "Trivia")}
                              {row.type === "METRIC" &&
                                t("menu.metric-panel-title", "Metric")}

                              {row.type === "AVATAR" &&
                                t("menu.points-coins-table-avatar", "Avatar")}
                              {row.type === "GIFT_ITEM" &&
                                t("menu.award-panel-dialog-title", "Award")}

                              {row.type === "CHALLENGE" &&
                                t(
                                  "menu.points-coins-table-challenge",
                                  "Challenge"
                                )}
                              {row.type === "TOURNAMENT" &&
                                t(
                                  "menu.points-coins-table-tournament",
                                  "Tournament"
                                )}

                              {row.type === "BET" &&
                                t("menu.points-coins-table-bet", "Bet")}
                              {row.type === "INSIGNIA" &&
                                t("menu.points-coins-table-badge", "Badge")}

                              {row.type === "MISSION" &&
                                t("menu.points-coins-table-mission", "Mission")}
                              {row.type === "MANUAL" &&
                                t(
                                  "menu.points-coins-table-handbook",
                                  "Handbook"
                                )}
                            </TableCell>

                            <TableCell align="left">{row.amountCoin}</TableCell>
                            {/*<TableCell align="left">
                              {row.amountPoint}
                                </TableCell>*/}

                            <TableCell align="left">
                              {row.finalAmountCoin
                                ? row.finalAmountCoin
                                : "N/A"}
                            </TableCell>
                            {/*<TableCell align="left">
                              {row.finalAmountPoint
                                ? row.finalAmountPoint
                                : "N/A"}
                              </TableCell>*/}

                            <TableCell align="left">
                              {row.user.teamName ? row.user.teamName : "N/A"}
                            </TableCell>

                            <TableCell align="left">
                              {GeneralFunctions.formatNewDate(
                                row.date,
                                i18n.language === "es" ? es : ""
                              )}
                            </TableCell>
                          </TableRow>
                        ))}
                    </TableBody>
                  </Table>
                  <TablePagination
                    className="mt-1"
                    rowsPerPageOptions={[5]}
                    component="div"
                    count={this.props.pointsCoinsReducer.totalElements}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={(event, value) =>
                      this.handleChange(event, value)
                    }
                  />
                </TableContainer>
              </Grid>
            ) : (
              <>
                <Grid container spacing={1}>
                  <Grid item xs={12} md={12} lg={12}>
                    <NotRegistersFound />
                  </Grid>
                </Grid>
              </>
            ))}
        </Container>
      </main>
    );
  }
}

const mapStateToProps = (pointsCoinsReducer) => pointsCoinsReducer;
const mapDispatchToProps = {
  getPointsCoinsRequest,
  getPointsCoinsFilteredRequest,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(withStyles(styles, { withTheme: true })(PointsCoins)));
