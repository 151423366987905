import React, { useState } from "react";
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Typography from "@material-ui/core/Typography";
import { useTranslation } from "react-i18next";
import Tooltip from "@material-ui/core/Tooltip";
import VisibilityIcon from "@material-ui/icons/Visibility";

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

export default function DetailsSupervisors(props) {
  const [open, setOpen] = useState(false);
  const { t } = useTranslation();

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Tooltip title={t("supervised.teams", "Supervised teams")}>
        <VisibilityIcon
          fontSize="small"
          className="cursor-pointer"
          onClick={handleClickOpen}
        />
      </Tooltip>

      <Dialog
        maxWidth="sm"
        fullWidth={true}
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <DialogTitle id="customized-dialog-title" onClose={handleClose}>
          {t("supervised.teams", "Supervised teams")} (
          {props.supervisedTeams && props.supervisedTeams.length})
        </DialogTitle>
        <DialogContent dividers>
          <>
            {props.supervisedTeams && props.supervisedTeams.length > 0 ? (
              props.supervisedTeams.map((supervisedTeam, index) => (
                <div key={index}>
                  <h4 className="mb-0 mt-0">{supervisedTeam.name}</h4>
                </div>
              ))
            ) : (
              <h4 className="d-flex">
                {t("not-supervised.teams", "Has no supervised teams")}
              </h4>
            )}
          </>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="secondary">
            {t("menu.trivia-panel-dialog-add-test-button-close", "Close")}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
