import Metrics from "../api/Metrics";

const createMetric = ({
  name,
  frequency,
  description,
  type,
  metricType,
  points,
  objective,
  lowRange,
  yesNoRange,
  mediumRange,
  highRange,
  token,
}) => {
  console.log(token);
  Metrics(token)
    .createMetric({
      body: {
        nombre: name,
        descripcion: description,
        frecuencia: frequency,
        objetivo: objective,
        tipo: type,
        flagsumarpuntos: points,
        flagautocalculada: 0,
        rangobajopuntos: lowRange?.points,
        rangobajoini: lowRange?.from,
        rangobajofin: lowRange?.to,
        rangomediopuntos: mediumRange?.points,
        rangomedioini: mediumRange?.from,
        rangomediofin: mediumRange?.to,
        rangoaltopuntos: highRange?.points,
        rangoaltoini: highRange?.from,
        rangoaltofin: highRange?.to,
        puntossinrangos: yesNoRange?.points,
      },
      metricType: metricType,
    })
    .then((val) => {
      console.log(val);
    })
    .catch((e) => {
      console.log(e);
    });
};

const listMetric = async ({ token }) => {
  let metrics = await Metrics(token).listMetric();
  return metrics.map((value) => {
    return {
      id: value.id,
      name: value.nombre,
      frequency: value.frecuencia,
      type: value.tipo,
    };
  });
};
const identifyType = (type) => {
  const metricTypes = {
    HORARIA: "tiempo",
    BOOLEANO: "booleano",
    MULTIPLICADOR: "multiplicador",
    DECIMAL: "decimal",
    PORCENTAJE: "porcentaje",
  };
  return metricTypes[type];
};
const showMetric = async ({ token, idmetrica }) => {
  let metric = await Metrics(token).searchMetric(idmetrica);
  return {
    name: metric.nombre,
    description: metric.descripcion,
    frequency: metric.frecuencia,
    objective: metric.objetivo,
    metricType: identifyType(metric.tipo),
    points: metric.flagsumarpuntos,
    lowRange: {
      points: metric.rangobajopuntos,
      from: metric.rangobajoini,
      to: metric.rangobajofin,
    },
    mediumRange: {
      points: metric.rangomediopuntos,
      from: metric.rangobajoini,
      to: metric.rangobajofin,
    },
    highRange: {
      points: metric.rangoaltopuntos,
      from: metric.rangoaltoini,
      to: metric.rangoaltofin,
    },
    YesNoRange: {
      points: metric.puntossinrangos,
    },
  };
};

export { createMetric, listMetric, showMetric };
