import React, { useState } from "react";
import { login } from "../auth/AuthenticationActions";
import { useDispatch, useSelector } from "react-redux";
import { Box } from "@material-ui/core";
import { useTranslation } from "react-i18next";

import GoogleLoginButton from "../auth/GoogleLoginButton";
import Loading from "../../common/controls/LoadingWindow";
import Error from "../../common/errorHandler/ErrorHandler";

import peyastars from "../../../../assets/img/peyastars-2.png";
import BotForm from "../../../BotForm";

import { useLocation } from "react-router-dom";


const useReduxSelector = () =>
  useSelector((state) => ({
    isLoading: state.authReducer.isLoading,
    signupCompleted: state.authReducer.signupCompleted,
    error: state.authReducer.error,
  }));

const CustomLanding = () => {
  const dispatch = useDispatch();

  const location = useLocation();
  const redirect = location.pathname.replace('/peyastars','');

  const [reloadGoogleLogin, setReloadGoogleLogin] = useState(false);
  const { isLoading, error } = useReduxSelector();
  const { t } = useTranslation();

  const loginGmailHandler = (data) => {
    dispatch(
      login({
        tokenId: data.credential,
        useremail: data.Is ? data.Is.ot : "",
        company: "PedidosYa",
        type: "gmail",
      })
    ).then((e) => {
      if (e && e.error) {
        setReloadGoogleLogin(true);
      } else {
        window.location.replace(redirect? redirect: '/');
      }
    });
  };

  const getError = (error) => {
    if (error && error.error) {
      if (error.error.messageCode === "auth.invalidusername") {
        return t("response-login-401.label", "Invalid username/password");
      } else if (error.error.messageCode === "No value present") {
        return t(
          "response-login-500-company.label",
          "Write the name of the company, please"
        );
      } else if (
        error.error.messageCode === "auth.gmail.multiple.user.no.company"
      ) {
        return t(
          "response-login-500-company.label",
          "Write the name of the company, please"
        );
      } else if (error.error.messageCode === "auth.no.web.login.allowed") {
        return t(
          "response-login-401-not-allowed.label",
          "Web login is not allowed"
        );
      } else {
        return t(
          "response-login-500.label",
          "There was an error, please try again later"
        );
      }
    } else {
      return t(
        "response-login-500.label",
        "There was an error, please try again later"
      );
    }
  };

  return (
    <div style={{backgroundColor: '#FA0050', backgroundImage: 'none'}} className="custom-landing-bg d-flex justify-content-center">
      <div>
        <div className="peyastars-img d-flex justify-content-center">
          {" "}
          <Box
            component="img"
            src={peyastars}
            sx={{ width: 200, backgroundColor: 'white', padding: '10px', borderRadius: '10px', marginBottom: '70px' }}
          />
        </div>
        {isLoading && <Loading open={true}></Loading>}
        {!isLoading && error && (
          <>
            <Error
              style={{ width: "200px", alignSelf: "center", marginBottom: "6px" }}
              message={getError(error)}
            ></Error>
          </>
        )}
        <GoogleLoginButton
          reloadGoogleLogin={reloadGoogleLogin}
          shape="pill"
          size="large"
          text="continue_with"
          theme="outline"
          onSuccess={loginGmailHandler}
        />
         <div 
            style={{
              zIndex: "30",
              bottom: "30px",
              right: "30px",
              position: "fixed",
            }}
          >
          <BotForm from="landing" type="custom"/>
        </div>
      </div>
    </div>
  );
};

export default CustomLanding;
