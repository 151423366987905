import React, { useEffect, useState } from "react";
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import CloseIcon from "@material-ui/icons/Close";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import { useTranslation } from "react-i18next";
import Tooltip from "@material-ui/core/Tooltip";
import SearchIcon from "@material-ui/icons/Search";
import { makeStyles } from "@material-ui/core/styles";
import Checkbox from '@material-ui/core/Checkbox';
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Grid from "@material-ui/core/Grid";
import AddIcon from "@material-ui/icons/Add";
import GetAppIcon from '@material-ui/icons/GetApp';
import environment from "../../../../../libs/environment";
import toastr from "toastr";

import "toastr/build/toastr.min.css";

const styles = (theme) => ({
	root: {
		margin: 0,
		padding: theme.spacing(2),
		display: 'flex',
	},
	closeButton: {
		position: "absolute",
		right: theme.spacing(1),
		top: theme.spacing(1),
		color: theme.palette.grey[500],
	},
	textField: {
		width: "50ch",
		marginBottom: "5px",
	},
});

const useStyles = makeStyles((theme) => ({
	menuContainerDialogContent: {
		display: 'flex',
		width: '800px'	
	},
	menuContainerLeftSide: {
		width: '60%',
		borderStyle: 'solid',
		borderColor: '#BCB8B8',
		borderWidth: '0px 2px 0px 0px'
	},
	menuContainerRightSide: {
		width: '40%',
		paddingLeft: '20px'
	},
	menuContainerDialog: {
		'& .MuiPaper-root': {
			width: '100%',
			maxWidth: '800px', // Set your width here
		},
	},
	usersContainer: {
		marginTop: '20px',
		overflowY: 'auto',
    	height: '300px',
	},
	menuContainerRightSideTitle: {
		fontWeight: '700'
	},
	menuItemsContainerRightSide: {
		marginTop: '20px',
		height: '340px',
    	overflowY: 'auto',
	},
	menuItemsRightSide: {
		backgroundColor: '#EAF0F6',
		borderStyle: 'solid',
		borderWidth: '1px',
		borderColor: '#CBD6E2',
		borderRadius: '4px',
		justifyContent: 'space-between',
    	display: 'flex',
    	padding: '8px 10px',
		marginBottom: '15px',
		marginRight: '20px',
	},
	menuItemsRight: {
		color: '#7D98B6',
		width: '20px',
    	textAlign: 'center',
    	cursor: 'pointer',
	},
	formControl: {
		width: "90%",
		margin: "0px",
		'& .MuiInputBase-formControl': {
			marginTop: '0px !important',
		},
		//marginTop: "1rem",
  	},
	formControlDialog: {
		display: 'flex !important',
    	flexDirection: 'row',
		width: '90%',
		minWidth: '70%',
		'& .MuiInputBase-formControl': {
			width: '90%;',
			minWidth: '70%',
		},
	}
}));

const DialogTitle = withStyles(styles)((props) => {
	const { children, classes, onClose, ...other } = props;
	return (
		<MuiDialogTitle disableTypography className={classes.root} {...other}>
			<Typography variant="h6">{children}</Typography>
			{onClose ? (
				<IconButton
					aria-label="close"
					className={classes.closeButton}
					onClick={onClose}
				>
					<CloseIcon />
				</IconButton>
			) : null}
		</MuiDialogTitle>
	);
});

const DialogContent = withStyles((theme) => ({
	root: {
		padding: theme.spacing(2),
		width: '800px'
	},
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
	root: {
		margin: 0,
		padding: theme.spacing(1),
	},
}))(MuiDialogActions);

export default function FindRegistersDialog(props) {

	const [open, setOpen] = useState(false);
	const [rows, setRows] = useState([]);

	const classes = useStyles();

	const { t } = useTranslation();

	useEffect(() => {
		setRows(props.rows
			.map((elem) => ({name: elem.label == '' ? 'Sin Nombre' : elem.label , id: elem.value, active: false}))
		);
	}, []);

	const handleDownload = () => {

		const triviasId = rows.filter((elem) => elem.active).map((elem) => elem.id).join(",");

		if(triviasId == '') {
			toastr.error('Debe seleccionar almenos una trivia');
		}
		let params = { _format : "XLSX" };
		
		const sesion = JSON.parse(localStorage.getItem("sesion"));
		if (sesion && sesion.token) {
		  //add token
		  params._token = sesion.token;
		}
		let query = Object.keys(params)
		  .filter((k) => params[k])
		  .map(function (key) {
			return encodeURIComponent(key) + "=" + encodeURIComponent(params[key]);
		  })
		  .join("&");

		query = 'triviaId=' + triviasId + "&" + query;
		
		window.open(
		  `${environment.motivarnosBackend}/report/execute/MotivarnosTriviaAnaliticoRespuestas?${query}`
		);

	}

	const handleClickOpen = () => {
		setOpen(true);
	};
	const handleClose = () => {
		setOpen(false);
	};

	function handleClickSelectAll() {
		setRows(rows.map((elem) => ({name: elem.name, id: elem.id, active: true})));
	}

	/***********Data Binding Form******* */

	function handleChange(event, value) {

		if (value) {
			let valueArray = [...rows];

			let indice = valueArray.findIndex((elem) => (elem.id == value.props.value));

			valueArray[indice].active = !valueArray[indice].active;

			setRows([...valueArray]);
		}
	}

	function changeChecked(id) {
		if (id) {
			let valueArray = [...rows];

			let indice = valueArray.findIndex((elem) => (elem.id == id));

			if (indice != -1) {
				valueArray[indice] = {
					id: valueArray[indice].id,
					name: valueArray[indice].name,
					active: valueArray[indice].active ? false : true
				};  
			}

			setRows([...valueArray]);
		}
	}

	return (
		<>
		 <Grid xs={4} style={{display: 'flex', marginTop: '-5px'}}>
            {/*<FormControl
              variant="outlined"
              className={classes.formControl + ' ml-1'}
            >
              <Select
                label={props.label}
                multiple
				value={rows.filter((elem) => elem.active).map((elem) => (elem.id))}
                onChange={handleChange}
                disabled={props.fetching}
				MenuProps={{
					style: { width: '400px' },
				}}
			
              >
                {props.rows.map((row) => (
                  <MenuItem key={row.value} value={row.value}>
                    {row.label
                      ? row.label
                      : t(
                          "admin.user-panel-user-dialog-input-select-without-name",
                          "Without name"
                        )}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>*/}

            {props.rows && props.rows.length > 0 && (
              <>
			  	<Tooltip title={t("search.label", "Search")} disableFocusListener>
					<Button
						className="button-table ml-1"
						style={{marginTop: '5px'}}
						variant="contained"
						color={props.button ? "default" : "primary"}
						onClick={handleClickOpen}
					>
						<SearchIcon />
					</Button>
				</Tooltip>

				<Dialog
					disableEscapeKeyDown
					open={open}
					onClose={(event, reason) => {
						if (reason !== "backdropClick") {
							handleClose();
						}
					}}
					className={classes.menuContainerDialog}
				>
					<DialogTitle id="customized-dialog-title" onClose={handleClose}>
						{t("search.label", "Search")}
					</DialogTitle>

					<DialogContent className={classes.menuContainerDialogContent}>
						<div className={classes.menuContainerLeftSide}>
							<FormControl
								variant="outlined"
								className={classes.formControl + ' ' + classes.formControlDialog}
							>
								<InputLabel id="users-select-outlined-label">
									{props.label}
								</InputLabel>
								<Select
									label={props.label}
									multiple
									value={rows.filter((elem) => elem.active).map((elem) => (elem.id))}
									onChange={(event, value) => handleChange(event, value)}
									MenuProps={{
										style: { width: '400px' },
									}}
								>
								{props.rows.map((option) => {
									return (
										<MenuItem key={option.label} value={option.value}>
											{option.label}
										</MenuItem>
									)
								})}
								</Select>
								<Tooltip title={t("menu.trivia-panel-dialog-test-analytic-download", "Descargar Todo")}>
									<Button
										className="button-table ml-1"
										variant="contained"
										color="primary"
										onClick={() => handleDownload()}
										style={{marginTop: '5px', width: '10%'}}
									>
										<GetAppIcon />
									</Button>
								</Tooltip>
								<Tooltip title={t("add-all.label", "Add all")}>
									<Button
										className="button-table ml-1"
										variant="contained"
										color="primary"
										onClick={() => handleClickSelectAll("teams")}
										style={{marginTop: '5px', width: '10%'}}
									>
										<AddIcon />
									</Button>
								</Tooltip>
							</FormControl>
							{rows.length > 0 && (
								<div className={classes.usersContainer}>
									{rows.map((elem) => (
										<div>
											<Checkbox checked={elem.active} onChange={() => changeChecked(elem.id)} /> {elem.name}
										</div>	
									))}
								</div>
							)}
						</div>
						<div className={classes.menuContainerRightSide}>
							<div className={classes.menuContainerRightSideTitle}>SELECCIONADOS ({rows.length})</div>
							{rows.length > 0 && (
								<div className={classes.menuItemsContainerRightSide}>
									{rows.filter((elem) => (elem.active == true)).map((elem) => (
										<div className={classes.menuItemsRightSide}>
											<div>{elem.name}</div>
											<div className={classes.menuItemsRight} onClick={() => changeChecked(elem.id)}>x</div>
										</div>	
									))}
								</div>
							)}
						</div>
					</DialogContent>
					<DialogActions>
						<Button onClick={handleClose} color="secondary">
							{t("menu.trivia-panel-dialog-add-test-button-close", "Close")}
						</Button>
					</DialogActions>
				</Dialog>
			  </>
            )}

			{/*<Tooltip title={t("add-all.label", "Add all")}>
              <Button
                className="button-table ml-1"
                variant="contained"
                color="primary"
                onClick={() => handleClickSelectAll("teams")}
				style={{marginTop: '5px'}}
              >
                <AddIcon />
              </Button>
            </Tooltip>
			<Tooltip title={t("menu.trivia-panel-dialog-test-analytic-download", "Descargar Todo")}>
              <Button
                className="button-table ml-1"
                variant="contained"
                color="primary"
                onClick={() => handleDownload()}
				style={{marginTop: '5px'}}
              >
                <GetAppIcon />
              </Button>
			</Tooltip>*/}
        </Grid>
			
		</>
	);
}
