import React from "react";
import { withStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import Container from "@material-ui/core/Container";
import Pagination from "@material-ui/lab/Pagination";
import toastr from "toastr";
import { SpinnerCircular } from "spinners-react";
import { Link } from "react-router-dom";
import Button from "@material-ui/core/Button";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import InputLabel from "@material-ui/core/InputLabel";
import { withTranslation } from "react-i18next";

import "toastr/build/toastr.min.css";

/********Dialogs** */
import { NotRegistersFound } from "../../common/NotRegistersFound";

/********Services***** */
import TestService from "../../Services/TestService";
import TeamService from "../../Services/TeamService";

/******Icons***** */
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import environment from "../../../../../libs/environment";

import CloudDownloadIcon from "@material-ui/icons/CloudDownload";

const styles = (theme) => ({
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
  },
  fixedHeight: {
    height: 240,
  },
  root: {
    "& > *": {
      marginTop: theme.spacing(2),
    },
  },
  margin: {
    margin: theme.spacing(1),
  },
  textField: {
    width: "50ch",
    marginBottom: "5px",
  },
});

class TestsConsolidated extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loadingPage: false,
      loading: false,
      loadingGroup: false,
      rowsTests: [],
      rowsGroupsSelect: [],
      rows: [],
      numberPages: 1,
      page: 1,
      rowsGroup: [],
      numberPagesGroup: 1,
      pageGroup: 1,
      test: "",
      group: "",
    };
  }

  componentDidMount() {
    this.getAllTests();
    this.getTestsQuestions();
    //this.getTestsQuestionsGroup();
  }

  /*********PAGINATION***** */

  handleChange(event, value) {
    this.setState({ page: value });
    this.getTestsQuestions(value, this.state.test, this.state.group);
  }

  handleChangeGroupPagination(event, value) {
    this.setState({ pageGroup: value });
    this.getTestsQuestionsGroup(value, this.state.test, this.state.group);
  }

  /***********GENERAL FUNCTIONS AND SELECT************* */

  handleChangeTest(value) {
    this.setState({ test: value });
    this.getTestsQuestions(1, value, this.state.group);
  }

  handleChangeGroup(value) {
    this.setState({ group: value });
    this.getTestsQuestions(1, this.state.test, value);
  }

  getAllTests() {
    this.setState({ loadingPage: true });
    TestService.getAllTests(1, 999)
      .then((response) => {
        this.setState({ rowsTests: response.data.content });
        this.getTeams();
      })
      .catch(() => {
        toastr.error(
          this.props.t(
            "menu.trivia-panel-dialog-test-consolidated-message-error-get-tests",
            "An error occurred while trying to get the trivia"
          )
        );
      })
      .finally(()=>this.setState({ loadingPage: false }));
  }

  getTeams() {
    this.setState({ loadingPage: true });
    TeamService.getTeams()
      .then((response) => {
        this.setState({ rowsGroups: response.data.content });
        this.setState({ loadingPage: false });
      })
      .catch(() => {
        toastr.error(
          this.props.t(
            "menu.trivia-panel-dialog-test-consolidated-message-error-get-teams",
            "An error occurred while trying to get the computers"
          )
        );
        this.setState({ loadingPage: false });
      });
  }

  getTestsQuestions(number = 1, value, team) {
    
    this.setState({ loading: true });
    TestService.getTestsConsolidated(number, 10, value, team)
      .then((response) => {
        this.setState({ rows: [] });
        let rowTests = [];
        response.data.content.forEach((test) =>
          rowTests.push(test
          )
        );
        this.setState({ rows: rowTests });
        this.setState({ numberPages: response.data.totalPages });
        this.setState({ loading: false });
      })
      .catch(() => {
        toastr.error(
          this.props.t(
            "menu.trivia-panel-dialog-test-consolidated-message-error-get-questions",
            "An error occurred while trying to get the questions"
          )
        );
        this.setState({ loading: false });
      });
  }

  downloadReport() {
      this.setState({ loading: true });
  
      let params = { triviaId: this.state.test,teamId:this.state.group,  _format : "XLSX" };
  
      const sesion = JSON.parse(localStorage.getItem("sesion"));
      if (sesion && sesion.token) {
        //add token
        params._token = sesion.token;
      }
      let query = Object.keys(params)
        .filter((k) => params[k])
        .map(function (key) {
          return encodeURIComponent(key) + "=" + encodeURIComponent(params[key]);
        })
        .join("&");
      window.open(
        `${environment.motivarnosBackend}/report/execute/MotivarnosTriviaConsolidadoRespuestas?${query}`
      );
  
      this.setState({ loading: false });
  
  }

  getTestsQuestionsGroup(number = 1, test = null, value = null) {
    function createData(
      companyName,
      correctCount,
      incorrectCount,
      notAnsweredCount,
      triviaId,
      triviaName,
      triviaQtyQuestion,
      userId,
      userImage,
      username
    ) {
      return {
        companyName,
        correctCount,
        incorrectCount,
        notAnsweredCount,
        triviaId,
        triviaName,
        triviaQtyQuestion,
        userId,
        userImage,
        username,
      };
    }
    this.setState({ loadingGroup: true });
    TestService.getTestsConsolidatedTeam(number, 5, test, value)
      .then((response) => {
        this.setState({ rowsGroup: [] });
        let rowTests = [];
        response.data.content.forEach((test) =>
          rowTests.push(
            createData(
              test.companyName,
              test.correctCount,
              test.incorrectCount,
              test.notAnsweredCount,
              test.triviaId,
              test.triviaName,
              test.triviaQtyQuestion,
              test.userId,
              test.userImage,
              test.username
            )
          )
        );
        this.setState({ rowsGroup: rowTests });
        this.setState({ numberPagesGroup: response.data.totalPages });
        this.setState({ loadingGroup: false });
      })
      .catch(() => {
        toastr.error(
          this.props.t(
            "menu.trivia-panel-dialog-test-analytic-message-error-get-users",
            "An error occurred while trying to get the users"
          )
        );
        this.setState({ loadingGroup: false });
      });
  }
  /***********CRUD FUNCTIONS************* */

  render() {
    const {
      loadingPage,
      loading,
      loadingGroup,
      rowsTests,
      rowsGroups,
      test,
      group,
      rows,
      numberPages,
      page,
      rowsGroup,
      numberPagesGroup,
      pageGroup,
    } = this.state;
    const { classes, t } = this.props;
    return (
      <main className={classes.content}>
        <div className={classes.appBarSpacer} />
        <Container maxWidth="lg" className={classes.container}>
          {loadingPage && (
            <div className="div-spinner">
              <Grid container spacing={1}>
                <SpinnerCircular size={90} />
              </Grid>
            </div>
          )}

          {!loadingPage && (
            <>
              <Grid container spacing={1}>
                <Link to="/test/panel">
                  <Button
                    className="button-table mr-1"
                    variant="contained"
                    color="secondary"
                  >
                    <ArrowBackIosIcon className="mr-1" />
                    {t(
                      "menu.trivia-panel-dialog-test-question-button-back",
                      "Back"
                    )}
                  </Button>
                </Link>
                <Button
                  className="button-table mr-1"
                  variant="contained"
                  color="secondary"
                >
                  {t(
                    "menu.trivia-panel-dialog-test-consolidated-title",
                    "Consolidated responses"
                  )}
                </Button>

                <Button
                  className="button-table mr-1"
                  variant="contained"
                  color="secondary"
                  onClick={() => this.downloadReport()}
                >
                  <CloudDownloadIcon className="mr-1" />
                  {t(
                    "menu.trivia-panel-dialog-test-analytic-download",
                    "Download"
                  )}
                </Button>

                {rowsTests && rowsTests.length > 0 && (
                  <FormControl
                    variant="outlined"
                    className="form-control-custom"
                  >
                    <InputLabel id="teams-select-outlined-label">
                      {t(
                        "menu.trivia-panel-dialog-test-analytic-select-trivia",
                        "Select a trivia"
                      )}
                    </InputLabel>
                    <Select
                      labelId="test-mutiple-name-label"
                      id="test"
                      name="test"
                      value={test}
                      onChange={(e) => this.handleChangeTest(e.target.value)}
                      label={t(
                        "menu.trivia-panel-dialog-test-analytic-select-trivia",
                        "Select a trivia"
                      )}
                    >
                      <MenuItem value={""}>
                        {t(
                          "admin.user-panel-user-dialog-input-select-without-test",
                          "Without test"
                        )}
                      </MenuItem>

                      {rowsTests.map((test) => (
                        <MenuItem key={test.id} value={test.id}>
                          {test.name
                            ? test.name
                            : t(
                                "admin.user-panel-user-dialog-input-select-without-name",
                                "Without name"
                              )}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                )}

                {rowsGroups && rowsGroups.length > 0 && (
                  <FormControl
                    variant="outlined"
                    className="padding-custom form-control-custom"
                  >
                    <InputLabel id="group-select-outlined-label">
                      {t(
                        "menu.trivia-panel-dialog-test-consolidated-select-group",
                        "Select a group"
                      )}
                    </InputLabel>
                    <Select
                      labelId="group-mutiple-name-label"
                      id="group"
                      name="group"
                      value={group}
                      onChange={(e) => this.handleChangeGroup(e.target.value)}
                      label={t(
                        "menu.trivia-panel-dialog-test-consolidated-select-group",
                        "Select a group"
                      )}
                    >
                      <MenuItem value={""}>
                        {t(
                          "admin.user-panel-user-dialog-input-select-without-group",
                          "Without group"
                        )}
                      </MenuItem>

                      {rowsGroups.map((group) => (
                        <MenuItem key={group.id} value={group.id}>
                          {group.name
                            ? group.name
                            : t(
                                "admin.user-panel-user-dialog-input-select-without-name",
                                "Without name"
                              )}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                )}
              </Grid>

              {/***************************TABLE ONE***************************** */}

              {!loading ? (
                rows && rows.length > 0 ? (
                  <Grid container spacing={1} className="mt-2">
                    <TableContainer component={Paper}>
                      <Table
                        className={classes.table}
                        aria-label="simple table"
                        size="small"
                      >
                        <TableHead>
                          <TableRow>
                            <TableCell align="left">
                              {t("menu.rigth-list-item-trivia", "Trivia")}
                            </TableCell>
                            <TableCell align="left">
                              Equipo
                            </TableCell>

                            <TableCell align="left">
                              Outsourcing
                            </TableCell>

                            <TableCell align="left">
                              {t(
                                "menu.trivia-panel-dialog-test-consolidated-table-correct-answers",
                                "Correct answers"
                              )}
                            </TableCell>

                            <TableCell align="left">
                              {t(
                                "menu.trivia-panel-dialog-test-consolidated-table-incorrect-responses",
                                "Wrong answers"
                              )}
                            </TableCell>

                            <TableCell align="left">
                              {t(
                                "menu.trivia-panel-dialog-test-consolidated-table-unanswered",
                                "Unanswered"
                              )}
                            </TableCell>
                            
                            <TableCell align="left">
                              {t(
                                "menu.trivia-panel-dialog-test-consolidated-table-invited-user",
                                "Invited user"
                              )}
                              </TableCell>
                              
                            <TableCell align="left">
                              {t(
                                "menu.trivia-panel-dialog-test-consolidated-table-answer-user",
                                "User"
                              )}
                              </TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {rows.map((row, index) => (
                            <TableRow key={index}>
                              <TableCell align="left">
                                {row.triviaName}
                              </TableCell>

                              <TableCell align="left">
                                {row.teamName}
                              </TableCell>

                              <TableCell align="left">
                                {row.outsource}
                              </TableCell>

                              <TableCell align="center">
                                {row.correctAnswerQty}
                              </TableCell>

                              <TableCell align="center">
                                {row.incorrectAnswerQty}
                              </TableCell>
                              
                              <TableCell align="center">
                                {row.noRespondidasQty}
                              </TableCell>
                              
                              <TableCell align="center">
                                {row.invitadosQty}
                              </TableCell>
                              <TableCell align="center">
                                {row.participantesQty}
                              </TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                    <div className={classes.root}>
                      <Pagination
                        className="pagination-custom-margin"
                        count={numberPages}
                        page={page}
                        color="primary"
                        onChange={(event, value) =>
                          this.handleChange(event, value)
                        }
                      />
                      {/**  onChange={(event, value) => this.handleChange(event, value)} */}
                    </div>
                  </Grid>
                ) : (
                  <>
                    <>
                      <Grid item xs={12} md={12} lg={12}>
                        <NotRegistersFound
                          title={t(
                            "menu.trivia-panel-dialog-test-consolidated-table-not-registers",
                            "No records for trivia"
                          )}
                        />
                      </Grid>
                    </>
                  </>
                )
              ) : (
                <div className="div-spinner-consolidated">
                  <Grid container spacing={1}>
                    <SpinnerCircular size={90} />
                  </Grid>
                </div>
              )}

            </>
          )}
        </Container>
      </main>
    );
  }
}

export default withTranslation()(
  withStyles(styles, { withTheme: true })(TestsConsolidated)
);
