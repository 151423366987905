import {
  Button,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  FormControlLabel,
  Switch,
} from "@material-ui/core";
import React, { useState } from "react";
import { FormattedMessage } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { Redirect } from "react-router-dom";
import DialogTitle from "../../common/controls/DialogTitle";
import { buttonStyles, textStyles } from "../../styles/styles";
import { sendLink } from "./store/RecoverPasswordReducer";
import { useTranslation } from "react-i18next";
import CloseIcon from "@material-ui/icons/Close";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import { withStyles } from "@material-ui/core/styles";

const useReduxSelector = () =>
  useSelector((state) => ({
    company: state.recoverPasswordReducer?.company,
    userFrom: state.recoverPasswordReducer?.userFrom,
    team: state.recoverPasswordReducer?.team,
    users: state.recoverPasswordReducer?.users,
    hashes: state.recoverPasswordReducer?.hashes,
  }));

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogTitleCustom = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const EmailListRecoverPassword = (props) => {
  const [redirect, setRedirect] = useState(undefined);
  const { users, hashes } = useReduxSelector();
  const classesText = textStyles();
  const classesButton = buttonStyles();
  const [userList, setUserList] = useState([]);
  const [messageOpen] = useState(true);
  const dispatch = useDispatch();
  const { t } = useTranslation();
  let usersToAdd = [];
  if (users) {
    if (users.others) {
      users.others.forEach((value) => {
        usersToAdd.push({
          user: value,
          selected: false,
        });
        if (userList.length === 0) {
          setUserList(usersToAdd);
        }
      });
    }
  }

  return (
    <>
      {redirect ? <Redirect to={redirect}></Redirect> : null}
      {hashes && (
        <Dialog
          fullWidth={true}
          open={messageOpen}
          maxWidth={"sm"}
          onClose={() => {
            setRedirect("/");
          }}
        >
          <DialogTitleCustom
            onClose={() => {
              setRedirect("/");
            }}
          >
            {t("alert", "Alert")}
          </DialogTitleCustom>
          <DialogContent className="d-flex">
            <h2>{t("email-sent", "Email sent")}</h2>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => {
                setRedirect("/");
              }}
              color="secondary"
            >
              {t("menu.trivia-panel-dialog-add-test-button-close", "Close")}
            </Button>
          </DialogActions>
        </Dialog>
      )}
      {users && (
        <Dialog
          open={true}
          fullScreen
          onClose={() => {
            setRedirect("/");
          }}
        >
          <DialogTitle
            onClose={() => {
              setRedirect("/");
            }}
          ></DialogTitle>
          <DialogContent>
            <Container
              style={{
                display: "flex",
                flexDirection: "column",
              }}
            >
              {usersToAdd.map((value, index) => {
                return (
                  <FormControlLabel
                    key={index}
                    control={
                      <Switch
                        size="medium"
                        onChange={() => {
                          let userListToMod = userList.slice();
                          userListToMod[index].selected =
                            !userListToMod[index].selected;
                          setUserList(userListToMod);
                        }}
                      />
                    }
                    label={`${value.user.username}:${value.user.email}`}
                  />
                );
              })}
            </Container>
          </DialogContent>
          <DialogActions>
            <Button
              disabled={!userList.find((value) => value.selected)}
              style={{ alignSelf: "center" }}
              onClick={() => {
                const usersToSend = [];
                userList.forEach((value) => {
                  if (value.selected) {
                    usersToSend.push(value);
                  }
                });
                dispatch(
                  sendLink({
                    users: usersToSend,
                    token: users.token,
                  })
                );
              }}
              className={classesButton.buttonPurple}
            >
              <FormattedMessage
                id="restorePassword.sendEmail"
                defaultMessage={t("confirm.label", "Confirm")}
                className={classesText.textButton}
              />
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </>
  );
};

export default EmailListRecoverPassword;
