import axios from "axios";
import environment from "../../../libs/environment";

export default {
	getNotifications: () =>
		axios({
			method: "GET",
			url: `${environment.motivarnosBackend}/notificationuser`,
		}),

	getNotificationsNotReaded: () =>
		axios({
			method: "GET",
			url: `${environment.motivarnosBackend}/notificationuser?readed=false`,
		}),

	setNotificationsReaded: () =>
		axios({
			method: "POST",
			url: `${environment.motivarnosBackend}/notificationuser/markasread`,
		}),
	getNotificationsUser: (number = 1, size = 4) =>
		axios({
			method: "GET",
			url: `${environment.motivarnosBackend}/notificationuser/public?_number=${number - 1
				}&_size=${size}`,
		}),

	getNotificationsBadges: () =>
		axios({
			method: "GET",
			url: `${environment.motivarnosBackend}/insigniauser?_size=3`,
		}),

	getNews: () =>
		axios({
			method: "GET",
			url: `${environment.motivarnosBackend}/news`,
		}),

	voteNews: (data) =>
		axios({
			method: "POST",
			url: `${environment.motivarnosBackend}/notificationuser/vote`,
			data: data,
		}),

	markAsReaded: (data) =>
		axios({
			method: "POST",
			url: `${environment.motivarnosBackend}/news/markasreaded`,
			data: data,
		}),
	
	postComment: (data) =>
		axios({
			method: "POST",
			url: `${environment.motivarnosBackend}/notificationuser/comment`,
			data: data,
		}),

	voteComment: (data) =>
		axios({
			method: "POST",
			url: `${environment.motivarnosBackend}/notificationuser/comment/vote`,
			data: data,
		}),

};
