import React, { useState, useContext } from "react";
import DialogTitle from "./DialogTitle";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import { FormattedMessage } from "react-intl";
import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  FormGroup,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import pxToRem from "../../utils/pxToRem";
import { useTranslation } from "react-i18next";

const TermAndConditions = (props) => {
  const classes = useStyles();
  const onClose = () => {
    setTermsAccepted(false);
    props.onClose();
  };
  const actionPostTermsAndConditions = () => {
    if (props.actionPostTermsAndConditions) {
      props.actionPostTermsAndConditions();
    }
    onClose();
  };
  const [termsAccepted, setTermsAccepted] = useState(false);
  const { t, i18n } = useTranslation();
  //console.log(termsAccepted);
  return (
    <>
      <Dialog open={props.open} onClose={onClose} fullWidth={true}>
        <DialogTitle onClose={onClose}>
          <h2 style={{ textAlign: "center" }}>
            <FormattedMessage
              id="termsandconditions.title"
              defaultMessage={t(
                "terms-and-conditions.label",
                "Terms and conditions"
              )}
            ></FormattedMessage>
          </h2>
        </DialogTitle>
        <DialogContent>
          <Box flex={true} flexDirection="column" width="100%" height="50vh">
            <Box component="div" overflow="visible" height="100%">
              <iframe
                src={props.termsAndConditions}
                width="100%"
                height="100%"
              ></iframe>
            </Box>
          </Box>
        </DialogContent>
        <DialogActions
          style={{ display: "flex", flexGrow: 1, justifyContent: "center" }}
        >
          <FormGroup row={true}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={termsAccepted}
                  onClick={() => setTermsAccepted(!termsAccepted)}
                ></Checkbox>
              }
              label={
                <FormattedMessage
                  id="termsandconditions.acceptedTermsAndConditions"
                  defaultMessage={t("i-accept.label", "I accept")}
                />
              }
            ></FormControlLabel>
          </FormGroup>

          <Button
            variant="contained"
            onClick={() => actionPostTermsAndConditions()}
            className={classes.demoButton}
            disabled={!termsAccepted}
          >
            <FormattedMessage
              id="landing.confirmBtn"
              defaultMessage={t("confirm.label", "Confirm")}
            />
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
const useStyles = makeStyles((theme) => ({
  demoButton: {
    minHeight: pxToRem(48),
    paddingTop: 1,
    paddingBottom: 1,
    paddingRight: 25,
    paddingLeft: 25,
    marginBottom: pxToRem(24),
    backgroundColor: "#db0fb6",
    color: "white",
    borderRadius: 10,
    //borderWidth: pxToRem(2),
    lineHeight: 1.3,
    fontWeight: 400,
    fontSize: pxToRem(24),
    letterSpacing: 4,
    "&:hover": {
      backgroundColor: "hsla(311, 87%, 46%, 0.3)",
      color: "#db0fb6",
    },
  },
}));
export default TermAndConditions;
