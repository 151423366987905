import { handleActions } from "redux-actions";

import { createActions } from "redux-actions";
import obtainUserList from "../services/UserPanelService";

const { returnUserList, loadingUserList, addNewUserToUserList } = createActions(
  {
    returnUserList: (data) => data,
    loadingUserList: () => ({}),
    addNewUserToUserList: (data) => data,
  }
);

var defaultState = { userList: null, loading: false };
const getUserList = (data) => {
  return async (dispatch, getState) => {
    try {
      dispatch(loadingUserList());
      let userList = await obtainUserList(data.token);
      dispatch(
        returnUserList({
          userList: userList,
        })
      );
    } catch (error) {
      console.error(error);
    }
  };
};
const usersPanelReducer = handleActions(
  {
    [returnUserList]: (state, action) => {
      console.log(action.payload.userList);
      return { userList: action.payload.userList };
    },
    [loadingUserList]: (state, action) => {
      return { loading: true, userList: [] };
    },
    [addNewUserToUserList]: (state, action) => {
      let userList = state.userList.concat(action.payload.userList);
      return { userList: userList };
    },
  },
  defaultState
);

const exposedUserPanelReducer = {
  key: "usersPanelReducer",
  value: usersPanelReducer,
};

export { getUserList, addNewUserToUserList, exposedUserPanelReducer };
