import React from "react";
import { withStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import CloseIcon from "@material-ui/icons/Close";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import Button from "@material-ui/core/Button";
import { useTranslation } from "react-i18next";

/*******Services******* */
const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  textField: {
    width: "50ch",
    marginBottom: "5px",
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

export default function DeleteQuestion(props) {
  const [open, setOpen] = React.useState(false);
  const { t } = useTranslation();

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  /***********Data Binding Form******* */

  function onFormSubmit() {
    handleClose();
    props.deleteQuestion();
  }

  return (
    <>
      <>
        <Button
          className="button-table mr-1 w-100"
          variant="contained"
          color="secondary"
          onClick={handleClickOpen}
        >
          {t(
            "menu.trivia-panel-dialog-test-question-delete-dialog-button",
            "Delete answer"
          )}
        </Button>

        <Dialog
          maxWidth="sm"
          fullWidth={true}
          onClose={handleClose}
          aria-labelledby="customized-dialog-title"
          open={open}
        >
          <DialogTitle id="customized-dialog-title" onClose={handleClose}>
            {t(
              "menu.trivia-panel-dialog-test-question-delete-dialog-title",
              "Response management"
            )}
          </DialogTitle>

          <>
            <DialogContent dividers>
              <h4 className="text-center">
                {t(
                  "menu.trivia-panel-dialog-test-question-delete-dialog-text",
                  "Are you sure you delete this question?"
                )}
              </h4>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose} color="secondary">
                {t("admin.header-dropdown-dialog-actions-cancel", "Cancel")}
              </Button>

              <Button onClick={onFormSubmit} color="primary">
                {t("admin.actions-delete", "Delete")}
              </Button>
            </DialogActions>
          </>
        </Dialog>
      </>
    </>
  );
}
