import React, { useRef, useState, useEffect, useLayoutEffect } from "react";
import Button from "@material-ui/core/Button";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";
import TextField from "@material-ui/core/TextField";

import { useTranslation } from "react-i18next";
import { connect } from "react-redux";

/*********Images********* */
import peyastarsForm from "../../assets/img/peyastars-form.jpeg";

/*********Icons********* */
import InfoIcon from '@material-ui/icons/Info';
import CloseIcon from "@material-ui/icons/Close";
import SendIcon from '@material-ui/icons/Send';
/*******Services******* */
import SuggestionInboxService from "../app/admin/Services/SuggestionInboxService";
import ThemeService from "../app/admin/Services/ThemeService";

import toastr from "toastr";

import "./styles.scss";

function BotForm(props) {
  const [open, setOpen] = useState(false);
  const [logo, setLogo] = useState(false);
  const [{
    firstAnswer,
    secondAnswer,
    thirdAnswer
  }, setAnswers] = useState({
    firstAnswer: 'SUGGESTION',
    secondAnswer: '',
    thirdAnswer: '',
  })
  const [steps, setSteps] = useState(0);
  const [messageSeended, setMessageSeended] = useState(null);
  const [rows, setRows] = useState(1);
  const miDivRef = useRef(null);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const nextStep = () => {
    if (steps === 1 && secondAnswer === '') {
      return
    }

    if ((steps+1 === 2 && props.from != 'landing') 
      || (steps+1 === 3 && props.from == 'landing')) {
      onFormSubmit()
    }

    setSteps(steps+1);
  };

  const reset = () => {
    setMessageSeended(null);
    setRows(1);
    setSteps(0);
    setAnswers({
      firstAnswer: 'SUGGESTION',
      secondAnswer: '',
      thirdAnswer: ''
    })
  };

  function selectFirstAnswer({ target: { name, value } }){
    setAnswers((prevState) => ({ ...prevState, firstAnswer: value }));
  }

  function handleChangeSecondAnswer({ target: { name, value, scrollHeight } }) {
    setAnswers((prevState) => ({ ...prevState, secondAnswer: value }));
    const textareaRows = Math.min(4, Math.ceil(scrollHeight / 20));

    setRows(textareaRows);
  }

  function handleChangeThirdAnswer({ target: { name, value } }) {
    setAnswers((prevState) => ({ ...prevState, thirdAnswer: value }));
  }

  useEffect(() => {
    if(props.from == 'landing') {
      return;
    }
    
    if (!window.localStorage.getItem("theme")) {
      ThemeService.getThemePublic()
        .then((response) => {
          if (JSON.parse(window.localStorage.getItem("sesion")).company.company_Id != 5) {
            setLogo(response.data.logo);
          }
        })
        .catch(() => {
          toastr.error("Ha ocurrido un error al intentar obtener el diseño.");
        });
    } else {
      if (JSON.parse(window.localStorage.getItem("sesion")).company.company_Id != 5) {
        setLogo(JSON.parse(window.localStorage.getItem("theme")).logo);
      }
    }
  }, [open]);

  useLayoutEffect(() => {
    miDivRef.current.scrollTop = miDivRef.current.scrollHeight;
  }, [steps]);

  async function onFormSubmit() {
    const DATA = {
      "message": secondAnswer,
      "type": firstAnswer,
      "email": thirdAnswer,
    }

    if(props.type == 'custom' && props.from == 'landing') {
      DATA['company_Id'] = 5;
    }

    if(props.type == 'no-custom' && props.from == 'landing') {
      DATA['company_Id'] = 8;
    }

    setTimeout(() => {
      setOpen(false);
      reset();
    }, 5000);


    await SuggestionInboxService.setSuggestionInbox(DATA)
      .then((response) => {
        setMessageSeended(true);
      })
      .catch(() => {
        setMessageSeended(false);
      });
  }

  return (
    <>
      <>
        {open == false ? (
          <Button
            variant="contained"
            color="secondary"
            onClick={handleClickOpen}
            className={`button-form`}
          >
            <InfoIcon />
          </Button>
        ) : (
          <Button
            variant="contained"
            color="secondary"
            onClick={handleClose}
            className={`button-form`}
          >
            <CloseIcon />
          </Button>
        )}
        
        <div className={`chat-bot${open === false ? "-hidden" : ""}`}>
          <div className="chatbot-body" style={{height: steps === 0 ? 'auto' : ''}} ref={miDivRef} >
            <div className="chatbot-question">
              Mediante este formulario queremos que nos cuentes tus sugerencias u oportunidades de mejora, así como también nos puedas reportar los bugs existentes. ¡Ayúdanos a mejorar!🤩
            </div>

            {steps >= 1 && (
              <>
                {firstAnswer === 'SUGGESTION' ? (
                  <>
                    <div className="chatbot-answer">
                      ¡Tengo una sugerencia!🙋‍♂️
                    </div>
                    <div className="chatbot-question">
                      Aquí podrás describirnos tus sugerencias... 🤔
                    </div>
                  </>
                ) : (
                  <>
                    <div className="chatbot-answer">
                      Tengo un reporte de error🐛
                    </div>
                    <div className="chatbot-question">
                      Reportanos el error, en la brevedad estaremos trabajando para repararlo.🐛
                    </div>
                  </>
                )}
              </>
            )}

            {steps >= 2 && (
              <div className="chatbot-answer">
                {secondAnswer}
              </div>
            )}

            {(steps >= 2 && props.from == 'landing') && (
               <div className="chatbot-question">
                  ¿Cual es tu email?
                </div>
            )}

            {((steps >= 2 && props.from != 'landing') || (steps >= 3 && props.from == 'landing')) &&(
              <div className="chatbot-question">
                {messageSeended ? (
                  <>Su {firstAnswer === 'SUGGESTION' ? 'sugerencia sera analizada' : 'reporte de error sera analizado'}. Gracias por su tiempo.</>
                ) : (
                  <>Ha ocurrido un error mientras se guardaban las respuestas. Vuelva a intentarlo mas tarde.</>
                )}
              </div>
            )}
      
          </div>
          <div className="chatbot-footer">
            {steps === 0 && (
              <div className="chatbot-message-input">
                  <RadioGroup
                    aria-labelledby="demo-radio-buttons-group-label"
                    defaultValue="SUGGESTION"
                    name="radio-buttons-group"
                    onChange={(e) => selectFirstAnswer(e)}
                  >
                    <FormControlLabel value="SUGGESTION" control={<Radio />} label="¡Tengo una sugerencia!🙋‍♂️" />
                    <FormControlLabel value="BUG" control={<Radio />} label="Tengo un reporte de error🐛" />
                  </RadioGroup>
              </div>
            )}
            
            {steps === 1 && (
              <TextField
                id="outlined-multiline-static"
                multiline
                rows={rows}
                disabled={steps>=2}
                //value={comment}
                onChange={(e) => handleChangeSecondAnswer(e)}
                className="mb-2"
                inputProps={{ maxLength: 256 }}
                style={{padding: '10px'}}
                fullWidth
              />
            )}

            {(steps === 2 && props.from == 'landing') && (
              <TextField
                id="outlined-multiline-static"
                multiline
                rows={1}
                disabled={steps>=3 && props.from == 'landing'}
                //value={comment}
                onChange={(e) => handleChangeThirdAnswer(e)}
                className="mb-2"
                inputProps={{ maxLength: 256 }}
                style={{padding: '10px'}}
                fullWidth
              />
            )}
            
            <div style={{width: '20%'}}>
              <Button
                variant="contained"
                color="transparent"
                className="send-button"
                onClick={() => nextStep()}
              >
                <SendIcon />
              </Button>
              
              {steps > 0 &&
              <Button
                variant="contained"
                color="transparent"
                className="send-button"
                onClick={() => reset()}
              >
                <CloseIcon />
              </Button>
              }
            </div>
          </div>  
        </div>
        <div className={`${open === false ? "" : "chat-outside"}`} onClick={handleClose}></div>
      </>
    </>
  );
}

const mapDispatchToProps = {};

export default connect(null, mapDispatchToProps)(BotForm);
