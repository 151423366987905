import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import './TextInput.css';

class TextInput extends PureComponent {
  static propTypes = {
    onEnterPressed: PropTypes.func,
    className: PropTypes.string,
  };

  static defaultProps = {
    onEnterPressed: () => { },
    className: '',
  };

  constructor(props) {
    super(props);
    this.state = {
      value: '',
    };
  }

  handleChange = (e) => {
    this.setState({ value: e.target.value });
  };

  handleKeyDown = (e) => {
    if (e.which === 13) {
      this.props.onEnterPressed(e);
    }
  };

  render() {
    const { className, onEnterPressed, ...props } = this.props;
    return (
      <div className="input-group">
        <input
          type="text"          
          pattern='/^[a-z]+$/'
          className={`form-control TextInput ${className}`}
          value={this.state.value}
          onKeyDown={this.handleKeyDown}
          onChange={this.handleChange}
          {...props}
        />
        <span className="input-group-btn">
          <button className="btn btn-info Button-add"  onClick={onEnterPressed}  type="button">
            <span className="glyphicon glyphicon-plus" />
            +
          </button>
        </span >
      </div >
    );
  }
}

export default TextInput;
