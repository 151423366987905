import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Grid from "@material-ui/core/Grid";

/********Social components***** */
import InfiniteScroll from "react-infinite-scroll-component";
import review from "../../assets/img/review.svg";
import reviewFill from "../../assets/img/review-fill.svg";
import NewCart from "../../components/NewCart";

/********Services***** */
import NotificationsService from "../../Services/Notifications/Player";

import useUserSesion from "../../hooks/useUserSesion";

/********assets***** */
import badge from "../../assets/img/badge.svg";
import mission from "../../assets/img/mission.svg";
import battle from "../../assets/img/battle.svg";
import trivia from "../../assets/img/quiz.svg";
import motivarnos from "../../assets/img/megaphone.svg";
import SkeletonSocial from "./components/SkeletonSocial";

import "./styles.scss";

function replaceAll(string, search, replace) {
    return string.split(search).join(replace);
}

const INITIAL_NUMBER_PAGE = 1;

const PAGINATION_SIZE = 7;

const SKELETON_RENDERED = PAGINATION_SIZE - 2;

const Socials = (props) => {

    const [notificationsUser, setNotificationsUser] = useState([]);

    const [page, setPage] = useState(1);

    const [notificationsContent, setNotificationsContent] = useState([]);

    const [sesionUser] = useUserSesion();

    const { t } = useTranslation();

    const regexFormatDate = /\d{4}\-\d{1,2}\-\d{1,2}/;

    const iconTypes = {
        "TRIVIA": trivia,
        "MISSION": mission,
        "INSIGNIA": badge,
        "TOURNAMENT": battle
    }

    const mapToIcon = (type) => {
        return iconTypes[type] ?? motivarnos
    }

    const getUserNotifications = async (page = INITIAL_NUMBER_PAGE, size = PAGINATION_SIZE) => {

        try {
            const response = await NotificationsService.getNotificationsUser(page, size);

            setNotificationsUser(notificationsUser.concat(response.data.content));
            setNotificationsContent(response.data.totalElements);
            setPage(page);

        } catch (error) {
            console.log(error)
        }

    };

    useEffect(() => {

        getUserNotifications(INITIAL_NUMBER_PAGE, PAGINATION_SIZE);

    }, []);

    if (!sesionUser) return "";

    return (
        <div className="social-wrapper">

            <Grid container justifyContent="center">

                <InfiniteScroll
                    dataLength={notificationsUser.length}
                    next={() => getUserNotifications(page + 1)}
                    hasMore={true}
                    style={{ padding: '10px', overflow: 'initial' }}
                    loader={
                        notificationsContent !== notificationsUser.length && (
                            <Grid container className="grid-comments-container skeleton-social" spacing={1}>
                                <SkeletonSocial numItems={SKELETON_RENDERED}/>
                            </Grid>
                        )
                    }
                >
                    {
                        <Grid container className="grid-comments-container" spacing={1}>
                            

                            {notificationsUser.map((data, index) => {

                                return (
                                    <Grid item xs={12} md={8} lg={8}
                                        key={data.id}
                                    >
                                        <NewCart
                                            badge={mapToIcon(data.notification.type)}
                                            getNotifications={() => props.getNotifications()}
                                            avatar={data.user.image}
                                            imageNotification={
                                                data.notification.originData === null ?  null : data.notification.originData.image
                                            }
                                            commentsList={
                                                data.commentsList ? data.commentsList : []
                                            }
                                            name={data.notification.name}
                                            votationIcon={review}
                                            votationIconHover={reviewFill}
                                            title={data.notification.type}
                                            user={data.user}
                                            createdBy={data.notification.createdBy}
                                            description={""}
                                            date={data.created}
                                            key={index}
                                            isVoted={data.isVoted}
                                            id={data.notification.id}
                                            idNotification={data.id}
                                        />
                                    </Grid>
                                );
                            })}
                        </Grid>
                    }
                </InfiniteScroll>

            </Grid>


        </div>
    );
};

export default Socials;
