import React from "react";
import { withStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import Container from "@material-ui/core/Container";
import Pagination from "@material-ui/lab/Pagination";
import toastr from "toastr";
import { SpinnerCircular } from "spinners-react";
import { Link } from "react-router-dom";
import Button from "@material-ui/core/Button";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import InputLabel from "@material-ui/core/InputLabel";
import { withTranslation } from "react-i18next";

import "toastr/build/toastr.min.css";

/********Dialogs** */
import { NotRegistersFound } from "../../common/NotRegistersFound";

/********Services***** */
import TestService from "../../Services/TestService";
import UserServices from "../../Services/UserService";

/******Icons***** */
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import CloudDownloadIcon from "@material-ui/icons/CloudDownload";

const styles = (theme) => ({
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
  },
  fixedHeight: {
    height: 240,
  },
  root: {
    "& > *": {
      marginTop: theme.spacing(2),
    },
  },
  margin: {
    margin: theme.spacing(1),
  },
  textField: {
    width: "50ch",
    marginBottom: "5px",
  },
});

class TestSkills extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loadingPage: false,
      loading: false,
      rowsUsers: [],
      rows: [],
      numberPages: 1,
      page: 1,
      user: "",
    };
  }

  componentDidMount() {
    this.getUsers();
  }

  /*********PAGINATION***** */

  handleChange(event, value) {
    this.setState({ page: value });
    this.getUserSkills(value, this.state.user);
  }

  /***********GENERAL FUNCTIONS AND SELECT************* */

  handleChangeUser(value) {
    this.setState({ user: value });
    this.getUserSkills(1, value);
  }

  getUsers() {
    this.setState({ loadingPage: true });
    UserServices.getUsers(1, 999)
      .then((response) => {
        this.setState({ rowsUsers: response.data.content });
        this.setState({ loadingPage: false });
      })
      .catch(() => {
        toastr.error(
          this.props.t(
            "menu.trivia-panel-message-error-get-users",
            "An error occurred while trying to get the users"
          )
        );
        this.setState({ loadingPage: false });
      });
  }

  getUserSkills(number = 1, value = null) {
    function createData(id, points, skillName) {
      return {
        id,
        points,
        skillName,
      };
    }
    this.setState({ loading: true });
    TestService.getTestSkills(number, 5, value)
      .then((response) => {
        this.setState({ rows: [] });
        let rowSkills = [];
        response.data.content.forEach((user) =>
          rowSkills.push(createData(user.id, user.points, user.skillName))
        );
        this.setState({ rows: rowSkills });
        this.setState({ numberPages: response.data.totalPages });
        this.setState({ loading: false });
      })
      .catch(() => {
        toastr.error(
          this.props.t(
            "menu.trivia-panel-message-error-get-all-skills",
            "An error occurred while trying to get the skills"
          )
        );
        this.setState({ loading: false });
      });
  }

  /***********CRUD FUNCTIONS************* */

  downloadSkills(user) {
    this.setState({ loading: true });
    TestService.downloadSkills(user)
      .then((response) => {
        var blob = response.data;
        var link = document.createElement("a");
        link.href = window.URL.createObjectURL(new Blob([blob]));
        link.download = "Skills.csv";
        link.click();

        this.setState({ loading: false });
      })
      .catch(() => {
        toastr.error(
          this.props.t(
            "admin.user-panel-message-download-skills",
            "An error occurred while trying to download skills"
          )
        );
        this.setState({ loading: false });
      });
  }

  render() {
    const { loadingPage, loading, rowsUsers, user, rows, numberPages, page } =
      this.state;
    const { classes, t } = this.props;

    return (
      <main className={classes.content}>
        <div className={classes.appBarSpacer} />
        <Container maxWidth="lg" className={classes.container}>
          {loadingPage && (
            <div className="div-spinner">
              <Grid container spacing={1}>
                <SpinnerCircular size={90} />
              </Grid>
            </div>
          )}

          {!loadingPage && (
            <>
              <Grid container spacing={1}>
                <Link to="/test/panel">
                  <Button
                    className="button-table mr-1"
                    variant="contained"
                    color="secondary"
                  >
                    <ArrowBackIosIcon className="mr-1" />
                    {t(
                      "menu.trivia-panel-dialog-test-question-button-back",
                      "Back"
                    )}
                  </Button>
                </Link>
                <Button
                  className="button-table mr-1"
                  variant="contained"
                  color="secondary"
                >
                  {t("skills-report.label", "Skills Report")}
                </Button>

                {rows && user && rows.length > 0 && (
                  <Button
                    className="button-table mr-1"
                    variant="contained"
                    color="primary"
                    onClick={() => this.downloadSkills(user)}
                  >
                    <CloudDownloadIcon className="mr-1" />
                    {t("menu.user-panel-button-download-csv", "Download CSV")}
                  </Button>
                )}

                {rowsUsers && rowsUsers.length > 0 && (
                  <FormControl
                    variant="outlined"
                    className="form-control-custom"
                  >
                    <InputLabel id="teams-select-outlined-label">
                      {t(
                        "menu.trivia-panel-dialog-add-test-select-players",
                        "Players"
                      )}
                    </InputLabel>
                    <Select
                      labelId="users-mutiple-name-label"
                      id="users"
                      name="users"
                      value={user}
                      onChange={(e) => this.handleChangeUser(e.target.value)}
                      label={t(
                        "menu.trivia-panel-dialog-add-test-select-players",
                        "Players"
                      )}
                    >
                      {rowsUsers.map((rowUser) => (
                        <MenuItem key={rowUser.id} value={rowUser.id}>
                          {rowUser.name
                            ? rowUser.name + " " + rowUser.lastName
                            : t(
                                "admin.user-panel-user-dialog-input-select-without-name",
                                "Without name"
                              )}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                )}
              </Grid>

              {/***************************TABLE ONE***************************** */}

              {!loading ? (
                rows && user && rows.length > 0 ? (
                  <Grid container spacing={1} className="mt-2">
                    <TableContainer component={Paper}>
                      <Table
                        className={classes.table}
                        aria-label="simple table"
                      >
                        <TableHead>
                          <TableRow>
                            <TableCell align="left">
                              {t(
                                "menu.trivia-panel-dialog-test-question-add-dialog-input-text-skill",
                                "Skill"
                              )}
                            </TableCell>

                            <TableCell align="left">
                              {t("points.label", "Points")}
                            </TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {rows.map((row, index) => (
                            <TableRow key={index}>
                              <TableCell align="left">
                                {row.skillName}
                              </TableCell>

                              <TableCell align="left">
                                {row.points + " " + t("points.label", "Points")}
                              </TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                    <div className={classes.root}>
                      <Pagination
                        className="pagination-custom-margin"
                        count={numberPages}
                        page={page}
                        color="primary"
                        onChange={(e) => this.handleChange(e.target.value)}
                      />
                      {/**  onChange={(event, value) => this.handleChange(event, value)} */}
                    </div>
                  </Grid>
                ) : (
                  <>
                    <>
                      <Grid item xs={12} md={12} lg={12}>
                        <NotRegistersFound
                          title={t(
                            "menu.trivia-panel-dialog-test-consolidated-table-not-registers",
                            "No records for trivia"
                          )}
                        />
                      </Grid>
                    </>
                  </>
                )
              ) : (
                <div className="div-spinner-consolidated">
                  <Grid container spacing={1}>
                    <SpinnerCircular size={90} />
                  </Grid>
                </div>
              )}
            </>
          )}
        </Container>
      </main>
    );
  }
}

export default withTranslation()(
  withStyles(styles, { withTheme: true })(TestSkills)
);
