import React, { useState, useEffect } from "react";
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import toastr from "toastr";
import { SpinnerCircular } from "spinners-react";
import Grid from "@material-ui/core/Grid";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import { makeStyles } from "@material-ui/core/styles";
import Tooltip from "@material-ui/core/Tooltip";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";

import {
  saveTeamRequest,
  //saveSecondaryTeam,
} from "../../../../../actions/generalActions";

import TeamService from "../../Services/TeamService";

/*********Icons********* */
import AddIcon from "@material-ui/icons/Add";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import EditIcon from "@material-ui/icons/Edit";

import "../../Styles/UserDialog.scss";

const useStyles = makeStyles((theme) => ({
  formControl: {
    width: "100%",
    marginTop: "1rem",
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  textField: {
    width: "50ch",
    marginBottom: "5px",
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

function GroupManagement(props) {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [loading, setLoading] = useState(false);

  const { t } = useTranslation();

  const [
    {
      name,
      isActive,
    },
    setState,
  ] = useState({
    name: props.name ? props.name : "",
    isActive: props.isActive,
  });

  useEffect(() => {
    setState((prevState) => ({
      ...prevState,
      teams: props.teams,
      secondaryTeams: props.secondaryTeams,
    }));
  }, [props.teams, props.secondaryTeams]);

  useEffect(() => {
    const secondaryTeamFromProps =
      props.roles && props.roles.length > 0 && props.roles[0].secondaryTeam;
    if (secondaryTeamFromProps) {
      setState((prevState) => ({
        ...prevState,
        secondaryTeam: secondaryTeamFromProps,
      }));
    }
  }, [props.roles]);

  const handleClickOpen = () => {
    setOriginalState();

    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  function setOriginalState() {
    setState((prevState) => ({
      ...prevState,
      name: props.name ? props.name : "",
      isActive: props.isActive,
    }));
  }

  /***********Data Binding Form******* */

  function handleTextFieldChange({ target: { name, value } }) {
    setState((prevState) => ({ ...prevState, [name]: value }));
  }

  async function onFormSubmit() {
    /*********Remove Styles**** */

    setLoading(true);

    if (name === '' || isActive === undefined) {
      toastr.error(
        t(
          "added-new-team-message-error.label",
          "An error occurred while saving the team"
        )
      );
      setLoading(false);
      handleClose();
      return;
    }

    let status;

    if (props.typeModal === "modalEdit") {
      if (props.typeTeam === 'team'){
        await TeamService
          .updateTeam({
            id: props.id,
            name,
            isActive,
          })
          .then((r) => (status = r));
      } else {
        await TeamService
          .updateSecondaryTeam({
            id: props.id,
            name,
            isActive,
          })
          .then((r) => (status = r));
      }
    } else {
      if (props.typeTeam === 'team'){
        await TeamService
          .setTeam({
            name,
            isActive,
          })
          .then((r) => (status = r))
          .catch((error) => {
            if (error.response.data.status === '422') {
              toastr.error(error.response.data.message);
            } else {
              toastr.error(
                t(
                  "added-new-team-message-error.label",
                  "An error occurred while saving the team"
                )
              );
            }
          });
      } else {
        await TeamService
          .setSecondaryTeam({
            name,
            isActive,
          })
          .then((r) => (status = r))
          .catch((error) => {
            if (error.response.data.status === '422') {
              toastr.error(error.response.data.message);
            } else {
              toastr.error(
                t(
                  "added-new-team-message-error.label",
                  "An error occurred while saving the team"
                )
              );
            }
          });
      }
    }

    if (status !== undefined) {
      if (status.status && status.status === "ERROR") {
        toastr.error(
          t(
            "added-new-team-message-error.label",
            "An error occurred while saving the team"
          )
        );
      } else {
        toastr.success(
          t(
            "added-new-team-message-success.label",
            "Team saved successfully"
          )
        );

        if(props.typeTeam === 'team') {
          props.generalReducer.teamsRequest();
        } else {
          props.generalReducer.secondaryTeamsRequest();
        }
      }
    }

    setLoading(false);
    handleClose();
  }

  return (
    <>
      <>
        {props.typeModal === "modalEdit" ? (
          <Tooltip title={t("admin.actions-edit", "Edit")}>
            <IconButton aria-label="edit-user" onClick={handleClickOpen}>
              <EditIcon fontSize="small" />
            </IconButton>
          </Tooltip>
        ) : (
          <Button
            className="button-table"
            variant="contained"
            color="secondary"
            onClick={handleClickOpen}
          >
            <AddIcon />
            {t("menu.user-panel-button-groups", "Group")}
          </Button>
        )}

        <Dialog
          maxWidth="sm"
          fullWidth={true}
          onClose={handleClose}
          aria-labelledby="customized-dialog-title"
          open={open}
        >
          <DialogTitle id="customized-dialog-title" onClose={handleClose}>
            {t("admin.user-panel-group-dialog-title", "Group Management")}
          </DialogTitle>

          {loading && (
            <div className="div-spinner-modal">
              <DialogContent dividers>
                <Grid container spacing={1}>
                  <SpinnerCircular size={90} />
                </Grid>
              </DialogContent>
            </div>
          )}
          {!loading && (
            <>
              <DialogContent dividers>
                <TextField
                  value={name}
                  name="name"
                  onChange={handleTextFieldChange}
                  id="name"
                  label={t("admin.user-panel-user-dialog-input-name", "Name")}
                  variant="outlined"
                  className="mt-2"
                  fullWidth
                />

                <FormControl variant="outlined" className={classes.formControl}>
                  <InputLabel id="isActive-select-outlined-label">
                    {t("enabled-locked", "Habilitado/bloqueado")}
                  </InputLabel>
                  <Select
                    labelId="isActive"
                    id="isActive"
                    value={isActive}
                    name="isActive"
                    label={t("enabled-locked", "Habilitado/bloqueado")}
                    onChange={handleTextFieldChange}
                  >
                    <MenuItem value={true}>
                      {t(
                        "admin.header-dropdown-dialog-notifications-input-item-yes",
                        "Yes"
                      )}
                    </MenuItem>
                    <MenuItem value={false}>
                      {t(
                        "admin.header-dropdown-dialog-notifications-input-item-no",
                        "No"
                      )}
                    </MenuItem>
                  </Select>
                </FormControl>
              </DialogContent>
              <DialogActions>
                <Button onClick={handleClose} color="secondary">
                  {t("admin.header-dropdown-dialog-actions-cancel", "Cancel")}
                </Button>

                <Button onClick={onFormSubmit} color="primary">
                  {t("admin.header-dropdown-dialog-actions-save", "Save")}
                </Button>
              </DialogActions>
            </>
          )}
        </Dialog>
      </>
    </>
  );
}

const mapDispatchToProps = {
  saveTeamRequest,
  //saveSecondaryTeamRequest,
};

export default connect(null, mapDispatchToProps)(GroupManagement);
