import React from "react";
import BotForm from "../../components/BotForm";
import "./styles.scss";

const Footer = () => {
  return (
    <div className={"footer-wrapper"}>
      <BotForm/>
    </div>
  );
};

export default Footer;
