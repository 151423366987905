import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import { NotRegistersFound } from "../../common/NotRegistersFound";
import ChallengeService from "../../Services/ChallengeService";
import JSConfetti from "js-confetti";
import { SpinnerCircular } from "spinners-react";
import Slider from "@material-ui/core/Slider";
import trash from "../../../../../assets/img/close.svg";
import { useTranslation } from "react-i18next";
import Grid from "@material-ui/core/Grid";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import { es } from "date-fns/locale";
import { format, isValid } from "date-fns";
import TextField from "@material-ui/core/TextField";
import GeneralFunctions from "../../libs/GeneralFunctions";
import MenuItem from "@material-ui/core/MenuItem";
import InputLabel from "@material-ui/core/InputLabel";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FindRegistersDialog from "../../common/Dialogs/FindRegistersDialog";
import { connect } from "react-redux";
import TablePagination from "@material-ui/core/TablePagination";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import DetailsChallenge from "../../common/Dialogs/DetailsChallenge";
import {
  usersRequest,
  metricsRequest,
  teamsRequest,
} from "../../../../../actions/generalActions";

import {
  getChallengesRequest,
  saveChallengeRequest,
  updateChallengeRequest,
  deleteChallengeRequest,
} from "../../../../../actions/challengesActions";

import Avatar from "../../../../Avatar";
import tunder from "../../../../../assets/img/tunder.svg";
import Button from "../../../../Button";

import "./styles.scss";
import toastr from "toastr";

const useStyles = makeStyles((theme) => ({
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    overflow: "auto",
  },
  formControl: {
    width: "250px",
    marginTop: "1rem",
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
  },
  fixedHeight: {
    height: 240,
  },
  root: {
    "& > *": {
      marginTop: theme.spacing(2),
    },
  },
}));

function Challenges(props) {
  const jsConfetti = new JSConfetti();
  const [challenges, setChallenges] = useState([]);
  const [totalElements, setTotalElements] = useState([]);
  const [challengesCharging, setChallengesCharging] = useState(false);
  const [isChallengeTypeSelected, setIsChallengeTypeSelected] = useState(false);
  const [showNextButton, setShowNextButton] = useState(false);
  const [sesion, setSesion] = useState(null);
  const [active, setActive] = useState(0);
  const [isForMetric, setIsForMetric] = useState(false);
  const [isForTeams, setIsForTeams] = useState(false);
  const [isForDirect, setIsForDirect] = useState(false);
  const [metricsToBet, setMetricsToBet] = useState([]);
  const [fetchingMetrics, setFetchingMetric] = useState(false);
  const [points, setPoints] = useState(0);
  const [userActive, setUserActive] = useState(null);
  const [dateFrom, setDateFrom] = useState(format(new Date(), "yyyy-MM-dd"));
  const [dateTo, setDateTo] = useState(
    format(new Date(new Date().getTime() + 24 * 60 * 60 * 1000), "dd-MM-yyyy")
  );
  const [userMetricActive, setUserMetricActive] = useState(null);
  const [conditionActive, setConditionActive] = useState(null);
  const [creating, setCreating] = useState(false);
  const [name, setName] = useState("");
  const [metricName, setMetricName] = useState("");
  const [teamName, setTeamName] = useState("");
  const [deleting, setDeleting] = useState(false);
  const [success, setSuccess] = useState(false);
  const [page, setPage] = useState(0);
  const [rowsPerPage] = useState(15);
  const [user, setUser] = useState("");
  const [metric, setMetric] = useState("");
  const [team, setTeam] = useState("");
  const [usersHasMetric, setUsersHasMetric] = useState([]);
  const [teamsHasMetric, setTeamsHasMetric] = useState([]);
  const [secondaryTeamsHasMetric, setSecondaryTeamsHasMetric] = useState([]);

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    getContentAnchorEl: () => null,
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };

  const [{ teams, secondaryTeams, users }, setState] = useState({
    teams: formatPropsEdit(props.teams ? props.teams : []),
    secondaryTeams: formatPropsEdit(
      props.secondaryTeams ? props.secondaryTeams : []
    ),
    users: formatPropsEdit(props.users ? props.users : []),
  });

  const { t, i18n } = useTranslation();

  function formatPropsEdit(array) {
    let arrayFormatted = [];
    array.forEach((element) => {
      arrayFormatted.push(element.id);
    });
    return arrayFormatted;
  }

  const handleTypeOfChallenge = (e) => {
    setIsForMetric(e.target.value === "isformetric");
    setIsForTeams(e.target.value === "isforteams");
    setIsForDirect(e.target.value === "isfordirect");
    restartValues();
    setIsChallengeTypeSelected(true);

    // Mostrar el botón "Next" solo cuando se selecciona isfordirect o isforteams
    setShowNextButton(
      e.target.value === "isfordirect" || e.target.value === "isforteams"
    );

    console.log(e.target.value);
  };

  const handleNext = () => {
    if (active < 2) setActive(active + 1);
  };

  const handleBack = () => {
    if (active > 0) setActive(active - 1);
  };

  const handlePoints = (event, newValue) => {
    setPoints(newValue);
  };

  function handleChange({ target: { name, value } }) {
    setState((prevState) => ({ ...prevState, [name]: value }));
  }

  const handleMetric = (event) => {
    let value = event.target.value;
    if (value === "null") {
      setUserMetricActive(null);
      return;
    }
    setUserMetricActive(value);
  };

  const handleSelectedteams = (event) => {
    let value = event.target.value;
    if (value === "null") {
      setUserMetricActive(null);
      return;
    }
    setUserMetricActive(value);
  };

  const handleCondition = (event) => {
    let value = event.target.value;
    if (value === "null") {
      setConditionActive(null);
      return;
    }
    setConditionActive(value);
  };

  const handleDateFrom = ({ target: { name, value } }) => {
    setDateFrom(value);
    //console.log(year+'-'+month+'-'+date+'T:24:00:00')
  };

  const handleDateTo = ({ target: { name, value } }) => {
    setDateTo(value);
    //console.log(year+'-'+month+'-'+date+'T:24:00:00')
  };

  const getBetName = (betArray, id) => {
    //console.log(betArray, id);
    for (let i = 0; i < betArray.length; i++) {
      //console.log(betArray[i].metricConf.id, id);
      if (betArray[i].metricConf.id + "" === id + "")
        return betArray[i].metricConf.name;
    }
    return "";
  };

  /***********Format variables****** */
  let teamsFormatted = [];
  teams.forEach((element) => {
    teamsFormatted.push({
      id: element,
    });
  });

  let usersFormatted = [];
  users.forEach((element) => {
    usersFormatted.push({
      id: element,
    });
  });

  let secondaryTeamsFormatted = [];
  secondaryTeams.forEach((element) => {
    secondaryTeamsFormatted.push({
      id: element,
    });
  });
  const formatNewDate = (dateString) => {
    if (dateString) {
      const date = new Date(dateString);
      if (isValid(date)) {
        date.setDate(date.getDate() + 1);
        return format(date, "dd/MMM/yyyy", { locale: es });
      }
    }
    return "N/A";
  };

  const handleUserMetric = (event) => {
    let value = event.target ? event.target.value : event;
    if (value === "null") return;

    setUser(value);
    setFetchingMetric(true);
    setMetricsToBet([]);
    setUserActive(value);

    if (value !== "null")
      ChallengeService.GetMetric(sesion.user.id, value)
        .then((response) => {
          setFetchingMetric(false);

          setMetricsToBet(response.data);
          setTeamName(value.name);
        })
        .catch(() => {
          toastr.error(
            t(
              "menu.metric-panel-dialog-metric-error-get-metric",
              "An error occurred while trying to get the metrics"
            )
          );
          setFetchingMetric(false);
          setMetricsToBet([]);
        });
  };
  const handleTeamMetric = (event) => {
    let value = event.target ? event.target.value : event;
    if (!value || value === "null") return;

    setTeam(value);
    setFetchingMetric(true);
    setMetricsToBet([]);
    /* setUserActive(value); */
    console.log(value);
    if (value !== "null")
      ChallengeService.GetMetricTeams(value.id)
        .then((response) => {
          setFetchingMetric(false);

          setMetricsToBet(
            response.data.content.map((m) => {
              return { metricConf: m };
            })
          );
        })
        .catch(() => {
          toastr.error(
            t(
              "menu.metric-panel-dialog-metric-error-get-metric",
              "An error occurred while trying to get the metrics"
            )
          );
          setFetchingMetric(false);
          setMetricsToBet([]);
        });
  };

  const handleUserHasItMetric = (event) => {
    let value = event.target ? event.target.value : event;
    if (value === "null") return;

    if (
      props.generalReducer.metrics &&
      props.generalReducer.metrics.content.length
    ) {
      props.generalReducer.metrics.content.forEach((element) => {
        if (element.id === value) {
          setUsersHasMetric(element.users);
          setTeamsHasMetric(element.teams.name);
          setSecondaryTeamsHasMetric(element.secondaryTeams);
          setTeamName(element.team.name);
          setMetricName(element.name);
        }
      });

      setMetric(value);
      setFetchingMetric(true);
      // Hay que incluir userstobet teamstobet secundaryteamstobet
      // setMetricsToBet([]);
      //setUserActive(value);
      /*console.log("response.data value");
          console.log(props.generalReducer.metrics);
    if (value !== "null")
      ChallengeService.GetAllMetricNotFilter()
        .then((response) => {
          console.log("response.data");
          //console.log(props.);
          setFetchingMetric(false);
          //setMetricsToBet(response.data);
        })
        .catch(() => {
          toastr.error(
            t(
              "menu.metric-panel-dialog-metric-error-get-metric",
              "An error occurred while trying to get the metrics"
            )
          );
          setFetchingMetric(false);
         // setMetricsToBet([]);
        }); */
    }
  };

  const restartValues = () => {
    setSuccess(false);
    setCreating(false);
    setActive(0);
    setUserActive(null);
    setUserMetricActive(null);
    setConditionActive(null);
    setMetricsToBet([]);
    setPoints(0);
    setDateFrom(format(new Date(), "yyyy-MM-dd"));
    setDateTo(
      format(new Date(new Date().getTime() + 24 * 60 * 60 * 1000), "yyyy-MM-dd")
    );
    setUserMetricActive(null); // Asegúrate de reiniciar userMetricActive aquí
    setTeam("");
    setUser("");
  };

  const handleSuccess = (e) => {
    setIsForMetric(false);
    restartValues();
    setIsForDirect(false);
    setIsForTeams(false);
    setShowNextButton(false);
  };

  const replaceAll = (string, search, replace) => {
    if (string === undefined || string === null) return "";
    return string.split(search).join(replace);
  };

  const createChallenge = async () => {
    const challengeData = {
      name,
      message: "",
      points,
      dateFrom: `${dateFrom}T00:00:00`,
      dateTo: `${dateTo}T00:00:00`,
      metricConf: {
        id: !isForMetric ? userMetricActive : metric,
      },
      isGenerate: false,
      teams: isForTeams ? [team] : [],
      secondaryTeams: secondaryTeamsFormatted,
      users: isForDirect
        ? [{ id: userActive }]
        : isForMetric
        ? usersFormatted
        : [],
      isActive: true,
      conditionType: conditionActive,
      numberPages: 5,
    };

    //Habilitar creación
    setCreating(true);

    ChallengeService.CreateChallenge(challengeData)
      .then((res) => {
        setSuccess(true);

        //confeti
        jsConfetti.addConfetti({
          emojis: ["😀", "👍"],
          emojiSize: 40,
          confettiNumber: 40,
        });
        
        setPage(0)
        consultarChallenge(0);
      })
      .catch((error) => {
        toastr.error(
          t(
            "admin.user-panel-message-error-save-challenges",
            "An error occurred creating the challenge"
          )
        );
      })
      .finally(() => setCreating(false));
  };

  const consultarChallenge = (pagina) => {
    setChallengesCharging(true);
    ChallengeService.GetChallenges(pagina + 1, rowsPerPage).then((res) => {
      setChallenges(res.data.content);

      setTotalElements(res.data.totalElements);

      

      const storedSesion = JSON.parse(localStorage.getItem("sesion"));
      if (storedSesion) {
        setSesion(storedSesion);
      }
    }).finally(() => setChallengesCharging(false));
  };

  const handleCancel = async (id) => {
    let data = { id: id };

    setDeleting(true);

    let status;
    await props.updateChallengeRequest(data).then((r) => (status = r));

    if (status === "ERROR") {
      toastr.error(
        t(
          "admin.user-panel-message-error-cancel-challenges",
          "An error occurred canceling the challenge"
        )
      );
      setDeleting(false);
    } else {
      setDeleting(false);
    }
  };

  const handleDelete = async (id) => {
    let data = { id: id };

    let status;
    await props.deleteChallengeRequest(data).then((r) => (status = r));

    if (status === "SUCCESS") {
      setDeleting(false);
    } else if (status.error.status === 422) {
      toastr.error(
        t(
          "error-delete-torunament-processed",
          "You cannot delete the tournament because it has already been processed."
        )
      );
    } else {
      toastr.error(t("admin.mission-message-error", "An error has occurred"));
    }
  };

  const handleName = (event) => {
    let value = event.target.value;
    setName(value);
  };

  useEffect(() => {
    if (!props.generalReducer.users) {
      props.usersRequest();
    }

    if (!props.generalReducer.metrics) {
      props.metricsRequest();
    }

    if (!props.generalReducer.teams) {
      props.teamsRequest();
    }

    const storedSesion = JSON.parse(localStorage.getItem("sesion"));
    if (storedSesion) {
      setSesion(storedSesion);
    }

    consultarChallenge(0);
  }, []);

  const handleChangePage = (event, value) => {
    setPage(value);
    console.log(value)
    consultarChallenge(value);
  };
  const classes = useStyles();
  if (sesion)
    return (
      <main className={classes.content}>
        <div className={classes.appBarSpacer} />
        <Container maxWidth="lg" className={classes.container}>
          <h3>{t("menu.challenges-title", "Challenges")}</h3>

          <div className="challenge-admin-wrapper">
            <div className="new-bet">
              {props.generalReducer.users_charging ||
              props.generalReducer.metrics_charging ? (
                <span className="spinner-container">
                  <SpinnerCircular />
                </span>
              ) : !success ? (
                <React.Fragment>
                  <div className="steeper">
                    <span className="steep-container">
                      <span
                        className={
                          "steep" + (active >= 0 ? " steep-active" : "")
                        }
                      >
                        1
                      </span>
                      <span className="steep-title">
                        {t("menu.challenges-challenged", "Challenged")}
                      </span>
                    </span>
                    <span
                      className={"line" + (active > 0 ? " line-active" : "")}
                    />
                    <span className="steep-container">
                      <span
                        className={
                          "steep" + (active > 0 ? " steep-active" : "")
                        }
                      >
                        2
                      </span>
                      <span className="steep-title">
                        {t("menu.challenges-challenge", "Challenge")}
                      </span>
                    </span>
                    <span
                      className={"line" + (active > 1 ? " line-active" : "")}
                    />
                    <span className="steep-container">
                      <span
                        className={
                          "steep" + (active > 1 ? " steep-active" : "")
                        }
                      >
                        3
                      </span>
                      <span className="steep-title">
                        {t("summary.label", "Summary")}
                      </span>
                    </span>
                  </div>

                  <div className="steeps-wrapper">
                    <div
                      className={
                        "steep-content steep1-content " +
                        (active === 0 ? "animation-show" : "animation-hide")
                      }
                    >
                      <Grid item xs={12} md={6} lg={6} className={isForTeams || isForDirect ? 'd-flex' : 'steep1-content-5'}>
                      <Grid item xs={12} md={6} lg={6} className={`${isForTeams || isForDirect ? 'd-flex mt-1' : 'steep1-content-4'}`}>
                          <h4>
                            {" "}
                            {t(
                              "menu.challenges-choose-challenge-type",
                              "Choose challenge type"
                            )}
                          </h4>
                        </Grid>
                        <Grid item xs={6} md={6} lg={6} className="d-flex">
                          <FormControl
                            variant="outlined"
                            className={classes.formControl}
                          >
                            <RadioGroup
                              aria-labelledby="demo-radio-buttons-group-label"
                              name="radio-buttons-group"
                              onChange={handleTypeOfChallenge}
                            >
                              <FormControlLabel
                                value="isfordirect"
                                control={<Radio />}
                                label={t(
                                  "menu.challenges-type-user",
                                  "For user"
                                )}
                              />
                              {/* <FormControlLabel value="isformetric" control={<Radio />} label={t("menu.challenges-type-metric","By metric" )} />*/}
                              <FormControlLabel
                                value="isforteams"
                                control={<Radio />}
                                label={t(
                                  "menu.challenges-type-teams",
                                  "By teams"
                                )}
                              />
                            </RadioGroup>
                          </FormControl>
                        </Grid>
                      </Grid>

                      {/*<h4>
                        {" "}
                        {t(
                          "menu.challenges-choose-challenge",
                          "Choose who to challenge"
                        )}
                      </h4>*/}
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          marginTop: "1em",
                          justifyContent: "center",
                        }}
                      >
                        {isForDirect && (
                          <FormControl
                            variant="outlined"
                            className={classes.formControl}
                          >
                            <InputLabel id="users-select-outlined-label">
                              {t(
                                "menu.trivia-panel-dialog-add-test-select-players",
                                "Players"
                              )}
                            </InputLabel>
                            <Select
                              labelId="users-mutiple-name-label"
                              id="users"
                              name="users"
                              label={t(
                                "menu.trivia-panel-dialog-add-test-select-players",
                                "Players"
                              )}
                              value={user}
                              onChange={handleUserMetric}
                              MenuProps={MenuProps}
                            >
                              {props.generalReducer.users.content.map(
                                (rowUser) => (
                                  <MenuItem key={rowUser.id} value={rowUser.id}>
                                    {rowUser.name
                                      ? rowUser.name + " " + rowUser.lastName
                                      : t(
                                          "admin.user-panel-user-dialog-input-select-without-name",
                                          "Without name"
                                        )}
                                  </MenuItem>
                                )
                              )}
                            </Select>
                          </FormControl>
                        )}
                        {isForTeams && (
                          <FormControl
                            variant="outlined"
                            className={classes.formControl}
                          >
                            <InputLabel id="users-select-outlined-label">
                              {t(
                                "menu.trivia-panel-dialog-add-test-select-teams",
                                "Teams"
                              )}
                            </InputLabel>
                            <Select
                              labelId="users-mutiple-name-label"
                              id="users"
                              name="users"
                              label={t(
                                "menu.trivia-panel-dialog-add-test-select-teams",
                                "Teams"
                              )}
                              value={team}
                              onChange={handleTeamMetric}
                              MenuProps={MenuProps}
                            >
                              {props.generalReducer.teams.content.map((t) => (
                                <MenuItem key={t.id} value={t}>
                                  {t.name
                                    ? t.name
                                    : t(
                                        "admin.user-panel-user-dialog-input-select-without-name",
                                        "Without name"
                                      )}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        )}

                        {/*isForTeams &&
                          props.generalReducer.teams &&
                          props.generalReducer.teams.content.length > 0 && (
                            <FindRegistersDialog
                              button={true}
                              setValue={(t, v) => handleTeamMetric(v)}
                              //type="metrics"
                              isMultiple={false}
                              rows={props.generalReducer.teams.content}
                          />
                          )*/}

                        {isForMetric && (
                          <FormControl
                            variant="outlined"
                            className={classes.formControl}
                          >
                            <InputLabel id="metrics-select-outlined-label">
                              {t("menu.challenges-label-metrics", "Metrics")}
                            </InputLabel>
                            <Select
                              labelId="metrics-mutiple-name-label"
                              id="metrics"
                              name="metrics"
                              label={t(
                                "menu.challenges-label-metrics",
                                "Metrics"
                              )}
                              value={metric}
                              onChange={handleUserHasItMetric}
                              MenuProps={MenuProps}
                            >
                              {props.generalReducer.metrics.content.map(
                                (rowMetric) => (
                                  <MenuItem
                                    key={rowMetric.id}
                                    value={rowMetric.id}
                                  >
                                    {rowMetric.name
                                      ? rowMetric.name
                                      : t(
                                          "admin.user-panel-user-dialog-input-select-without-name",
                                          "Without name"
                                        )}
                                  </MenuItem>
                                )
                              )}
                            </Select>
                          </FormControl>
                        )}

                        {/*isForDirect &&
                          props.generalReducer.users &&
                          props.generalReducer.users.content.length > 0 && (
                            <FindRegistersDialog
                              button={true}
                              setValue={handleUserMetric}
                              //type="users"
                              rows={props.generalReducer.users.content}
                          />
                          )*/}
                      </div>

                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          marginTop: "1em",
                          justifyContent: "center",
                        }}
                      >
                        {isForDirect && (
                          <div className="opponent-info">
                            <span className="avatar-container">
                              <Avatar
                                size="10em"
                                avatar={"/api" + sesion.user.image}
                              />
                            </span>
                            <div className="metrics-info">
                              {metricsToBet.map((data, index) => {
                                return (
                                  <div className="metric-info-item">
                                    <span className="metric-score">
                                      {data.me.value}
                                    </span>
                                    <span className="metric-name">
                                      {data.metricConf.name}
                                    </span>
                                    <span className="metric-score">
                                      {data.opponent.value}
                                    </span>
                                  </div>
                                );
                              })}
                            </div>
                            <span className="avatar-container">
                              <Avatar
                                size="10em"
                                avatar={
                                  userActive
                                    ? "/api" +
                                      props.generalReducer.users.content.filter(
                                        (item) =>
                                          item.id + "" === userActive + ""
                                      )[0].image
                                    : null
                                }
                              />
                            </span>
                          </div>
                        )}

                        {showNextButton && (
                          <div className="button-container">
                            <Button
                              onClick={handleNext}
                              disabled={
                                !isChallengeTypeSelected ||
                                (isForDirect &&
                                  (userActive === null || fetchingMetrics)) ||
                                (isForMetric && metric === "") ||
                                (isForTeams && (!team || fetchingMetrics))
                              }
                            >
                              {t("next.label", "Next")}
                            </Button>
                          </div>
                        )}
                      </div>
                    </div>

                    <div
                      className={
                        "steep-content steep2-content " +
                        (active === 1 ? "animation-show" : "animation-hide")
                      }
                    >
                      <div className="slider-container">
                        <Slider
                          value={points}
                          onChange={handlePoints}
                          aria-labelledby="continuous-slider"
                          style={{ color: "var(--warm-purple)" }}
                          max={1000}
                        />
                        <div className="lines">
                          <span></span>
                          <span></span>
                          <span></span>
                          <span></span>
                          <span></span>
                          <span></span>
                          <span></span>
                          <span></span>
                          <span></span>
                          <span></span>
                          <span></span>
                        </div>
                        <div className="lim">
                          <span>0</span>
                          <span>{1000}</span>
                        </div>
                        <div className="points">
                          <span>
                            {t("coins.label", "Coins")}: {points}
                          </span>
                        </div>
                      </div>

                      <div className="input-text-container">
                        <input
                          onChange={handleName}
                          placeholder={t(
                            "menu.challenge-name",
                            "Challenge name"
                          )}
                        />
                      </div>

                      {!isForMetric ? (
                        <>
                          <span>
                            <span>
                              {t("select-metric.label", "Select metric")}
                            </span>
                            <select
                              className="select-css"
                              onChange={handleMetric}
                            >
                              <option value={"null"}>----</option>
                              {metricsToBet.map((data, index) => {
                                return (
                                  <option
                                    key={index}
                                    value={data.metricConf.id}
                                  >
                                    {data.metricConf.name}
                                  </option>
                                );
                              })}
                            </select>
                          </span>

                          <span>
                            <span>
                              {t("place-in-ranking.label", "Place in ranking")}
                            </span>
                            <select
                              className="select-css"
                              onChange={handleCondition}
                            >
                              <option value={"null"}>----</option>
                              <option value={"GREAT_THAN"}>
                                {t("menu.challenges-higher", "Higher")}
                              </option>
                              <option value={"LESS_THAN"}>
                                {t("menu.challenges-less", "Less")}
                              </option>
                            </select>
                          </span>
                        </>
                      ) : (
                        <>
                          {usersHasMetric.length ? (
                            <FormControl
                              variant="outlined"
                              className={classes.formControl}
                            >
                              <InputLabel id="users">
                                {t("menu.rigth-list-item-users", "Users")}
                              </InputLabel>
                              <Select
                                labelId="group-mutiple-name-label"
                                label={t("menu.rigth-list-item-users", "Users")}
                                id="users"
                                name="users"
                                multiple
                                value={users}
                                onChange={handleChange}
                                MenuProps={MenuProps}
                              >
                                {usersHasMetric.map((user) => (
                                  <MenuItem key={user.id} value={user.id}>
                                    {user.name
                                      ? user.name
                                      : t(
                                          "admin.user-panel-user-dialog-input-select-without-name",
                                          "Without name"
                                        )}
                                  </MenuItem>
                                ))}
                              </Select>
                            </FormControl>
                          ) : (
                            ""
                          )}

                          {teamsHasMetric.length ? (
                            <FormControl
                              variant="outlined"
                              className={classes.formControl}
                            >
                              <InputLabel id="teams">
                                {t(
                                  "admin.header-dropdown-dialog-teams-input",
                                  "Teams"
                                )}
                              </InputLabel>
                              <Select
                                labelId="group-mutiple-name-label"
                                label={t(
                                  "admin.header-dropdown-dialog-teams-input",
                                  "Teams"
                                )}
                                id="teams"
                                name="teams"
                                multiple
                                value={teams}
                                onChange={handleChange}
                                MenuProps={MenuProps}
                              >
                                {teamsHasMetric.map((team) => (
                                  <MenuItem key={team.id} value={team.id}>
                                    {team.name
                                      ? team.name
                                      : t(
                                          "admin.user-panel-user-dialog-input-select-without-name",
                                          "Without name"
                                        )}
                                  </MenuItem>
                                ))}
                              </Select>
                            </FormControl>
                          ) : (
                            ""
                          )}

                          {secondaryTeamsHasMetric.length ? (
                            <FormControl
                              variant="outlined"
                              className={classes.formControl}
                            >
                              <InputLabel id="secondaryteams">
                                {t(
                                  "admin.header-dropdown-dialog-secondary-teams-input",
                                  "Secondary Teams"
                                )}
                              </InputLabel>
                              <Select
                                labelId="group-mutiple-name-label"
                                label={t(
                                  "admin.header-dropdown-dialog-secondary-teams-input",
                                  "Secondary Teams"
                                )}
                                id="secondaryTeams"
                                name="secondaryTeams"
                                multiple
                                value={secondaryTeams}
                                onChange={handleChange}
                                MenuProps={MenuProps}
                              >
                                {secondaryTeamsHasMetric.map(
                                  (secondaryteam) => (
                                    <MenuItem
                                      key={secondaryteam.id}
                                      value={secondaryteam.id}
                                    >
                                      {secondaryteam.name
                                        ? secondaryteam.name
                                        : t(
                                            "admin.user-panel-user-dialog-input-select-without-name",
                                            "Without name"
                                          )}
                                    </MenuItem>
                                  )
                                )}
                              </Select>
                            </FormControl>
                          ) : (
                            ""
                          )}

                          <span>
                            <span>
                              {t("place-in-ranking.label", "Place in ranking")}
                            </span>
                            <select
                              className="select-css"
                              onChange={handleCondition}
                            >
                              <option value={"null"}>----</option>
                              <option value={"GREAT_THAN"}>
                                {t("menu.challenges-higher", "Higher")}
                              </option>
                              <option value={"LESS_THAN"}>
                                {t("menu.challenges-less", "Less")}
                              </option>
                            </select>
                          </span>
                        </>
                      )}

                      <div className="date-container">
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            width: "100%",
                          }}
                        >
                          <TextField
                            InputProps={{
                              inputProps: {
                                min: format(new Date(), "yyyy-MM-dd"),
                              },
                            }}
                            onChange={handleDateFrom}
                            value={dateFrom}
                            type="date"
                            name="date"
                            id="date"
                            label={t(
                              "menu.trivia-panel-dialog-add-test-input-dateFrom",
                              "Start date"
                            )}
                            variant="outlined"
                          />
                        </div>

                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            width: "100%",
                          }}
                        >
                          <TextField
                            InputProps={{
                              inputProps: {
                                min: format(new Date(), "yyyy-MM-dd"),
                              },
                            }}
                            onChange={handleDateTo}
                            value={dateTo}
                            type="date"
                            name="date"
                            id="date"
                            label={t("menu.challenge-date", "Challenge date")}
                            variant="outlined"
                          />
                        </div>
                      </div>
                      <div className="button-container">
                        <Button
                          secondary
                          style={{ marginRight: "1em" }}
                          onClick={handleBack}
                        >
                          {t("back.label", "Back")}
                        </Button>
                        <Button
                          onClick={handleNext}
                          disabled={
                            (isForDirect &&
                              (userMetricActive === null ||
                                conditionActive === null ||
                                points === 0 ||
                                name === "")) ||
                            (isForMetric &&
                              (points === 0 ||
                                name === "" ||
                                conditionActive === null ||
                                (!users.length &&
                                  !teams.length &&
                                  !secondaryTeams.length))) ||
                            (isForTeams &&
                              (!team ||
                                userMetricActive === null ||
                                name === "" ||
                                points === 0 ||
                                conditionActive === null))
                          }
                        >
                          {t("next.label", "Next")}
                        </Button>
                      </div>
                    </div>

                    <div
                      className={
                        "steep-content steep3-content " +
                        (active === 2 ? "animation-show" : "animation-hide")
                      }
                    >
                      {isForDirect && (
                        <span className="steep3-info-content">
                          <div className="vs-container">
                            <Avatar
                              size="10em"
                              avatar={"/api" + sesion.user.image}
                            />

                            {/* ak */}

                            {/* {sesion.user.username} */}
                            <span className="tunder">
                              <img src={tunder} alt="" />
                            </span>
                            <Avatar
                              size="10em"
                              avatar={
                                userActive
                                  ? "/api" +
                                    props.generalReducer.users.content.filter(
                                      (item) => item.id + "" === userActive + ""
                                    )[0].image
                                  : null
                              }
                            />
                          </div>
                          {isForDirect && (
                            <div className="bet-info">
                              <span>
                                <div className="points-container border-bottom border-right">
                                  <span className="points">
                                    {!isForMetric
                                      ? getBetName(
                                          metricsToBet,
                                          userMetricActive
                                        )
                                      : metricName}
                                  </span>
                                  <span>
                                    {t(
                                      "menu.badge-panel-dialog-metrics",
                                      "Metrics"
                                    )}
                                  </span>
                                </div>
                                <div className="points-container border-right">
                                  <span className="points">
                                    {conditionActive === "LESS_THAN"
                                      ? t("me-below.label", "Me below")
                                      : t("me-above.label", "Me above")}
                                  </span>
                                  <span>
                                    {t(
                                      "place-in-ranking.label",
                                      "Place in ranking"
                                    )}
                                  </span>
                                </div>
                              </span>

                              <span>
                                <div className="points-container border-bottom border-left">
                                  <span className="points">{points}</span>
                                  <span>
                                    {t(
                                      "menu.challenge-points-play",
                                      "Points in Play"
                                    )}
                                  </span>
                                </div>
                                <div className="points-container border-left">
                                  <span className="points">
                                    {/* {GeneralFunctions.formatNewDate(dateTo, es)} */}
                                    {formatNewDate(dateTo)}
                                  </span>
                                  <span>
                                    {t(
                                      "expiration-date.label",
                                      "Expiration date"
                                    )}
                                  </span>
                                </div>
                              </span>
                            </div>
                          )}

                          {isForTeams && (
                            <div className="bet-info">
                              <span>
                                <div className="points-container border-bottom">
                                  <span className="points">
                                    {!isForMetric
                                      ? getBetName(
                                          metricsToBet,
                                          userMetricActive
                                        )
                                      : metricName}
                                  </span>
                                  <span>
                                    {t(
                                      "menu.badge-panel-dialog-metrics",
                                      "Metrics"
                                    )}
                                  </span>
                                </div>
                                <div className="points-container border-rigth">
                                  <span className="points">
                                    {conditionActive === "LESS_THAN"
                                      ? t("me-below.label", "Me below")
                                      : t("me-above.label", "Me above")}
                                  </span>
                                  <span>
                                    {t(
                                      "place-in-ranking.label",
                                      "Place in ranking"
                                    )}
                                  </span>
                                </div>
                              </span>
                              <div className="points-container border-left">
                                <span className="points">{team.name}</span>
                                <span>
                                  {t("menu.challenge-points-team", "Team")}
                                </span>
                              </div>
                              <span>
                                <div className="points-container border-bottom border-left">
                                  <span className="points">{points}</span>
                                  <span>
                                    {t(
                                      "menu.challenge-points-play",
                                      "Points in Play"
                                    )}
                                  </span>
                                </div>
                                <div className="points-container border-left">
                                  <span className="points">
                                    {formatNewDate(dateTo)}
                                  </span>
                                  <span>
                                    {t(
                                      "expiration-date.label",
                                      "Expiration date"
                                    )}
                                  </span>
                                </div>
                              </span>
                            </div>
                          )}
                        </span>
                      )}
                      {isForTeams && (
                        <span className="steep3-info-content">
                          <div className="vs-container">
                            <Avatar
                              size="10em"
                              avatar={"/api" + sesion.user.image}
                            />

                            {/* ak */}

                            {/* {sesion.user.username} */}
                            <span className="tunder">
                              <img src={tunder} alt="" />
                            </span>
                            <div className="team-name-bar">{team.name}</div>
                          </div>
                          {isForDirect && (
                            <div className="bet-info">
                              <span>
                                <div className="points-container border-bottom border-right">
                                  <span className="points">
                                    {!isForMetric
                                      ? getBetName(
                                          metricsToBet,
                                          userMetricActive
                                        )
                                      : metricName}
                                  </span>
                                  <span>
                                    {t(
                                      "menu.badge-panel-dialog-metrics",
                                      "Metrics"
                                    )}
                                  </span>
                                </div>
                                <div className="points-container border-right">
                                  <span className="points">
                                    {conditionActive === "LESS_THAN"
                                      ? t("me-below.label", "Me below")
                                      : t("me-above.label", "Me above")}
                                  </span>
                                  <span>
                                    {t(
                                      "place-in-ranking.label",
                                      "Place in ranking"
                                    )}
                                  </span>
                                </div>
                              </span>

                              <span>
                                <div className="points-container border-bottom border-left">
                                  <span className="points">{points}</span>
                                  <span>
                                    {t(
                                      "menu.challenge-points-play",
                                      "Points in Play"
                                    )}
                                  </span>
                                </div>
                                <div className="points-container border-left">
                                  <span className="points">
                                    {/* {GeneralFunctions.formatNewDate(dateTo, es)} */}
                                    {formatNewDate(dateTo)}
                                  </span>
                                  <span>
                                    {t(
                                      "expiration-date.label",
                                      "Expiration date"
                                    )}
                                  </span>
                                </div>
                              </span>
                            </div>
                          )}

                          {isForTeams && (
                            <div className="bet-info">
                              <span>
                                <div className="points-container border-bottom">
                                  <span className="points">
                                    {!isForMetric
                                      ? getBetName(
                                          metricsToBet,
                                          userMetricActive
                                        )
                                      : metricName}
                                  </span>
                                  <span>
                                    {t(
                                      "menu.badge-panel-dialog-metrics",
                                      "Metrics"
                                    )}
                                  </span>
                                </div>
                                <div className="points-container border-rigth">
                                  <span className="points">
                                    {conditionActive === "LESS_THAN"
                                      ? t("me-below.label", "Me below")
                                      : t("me-above.label", "Me above")}
                                  </span>
                                  <span>
                                    {t(
                                      "place-in-ranking.label",
                                      "Place in ranking"
                                    )}
                                  </span>
                                </div>
                              </span>
                              <div className="points-container border-left">
                                <span className="points">{team.name}</span>
                                <span>
                                  {t("menu.challenge-points-team", "Team")}
                                </span>
                              </div>
                              <span>
                                <div className="points-container border-bottom border-left">
                                  <span className="points">{points}</span>
                                  <span>
                                    {t(
                                      "menu.challenge-points-play",
                                      "Points in Play"
                                    )}
                                  </span>
                                </div>
                                <div className="points-container border-left">
                                  <span className="points">
                                    {formatNewDate(dateTo)}
                                  </span>
                                  <span>
                                    {t(
                                      "expiration-date.label",
                                      "Expiration date"
                                    )}
                                  </span>
                                </div>
                              </span>
                            </div>
                          )}
                        </span>
                      )}
                      <div className="button-container">
                        <Button
                          secondary
                          style={{ marginRight: "1em" }}
                          onClick={handleBack}
                          disabled={creating}
                        >
                          {t("back.label", "Back")}
                        </Button>
                        <Button onClick={createChallenge} disabled={creating}>
                          {t("menu.challenge-challenge", "Challenge!")}
                        </Button>
                      </div>
                    </div>
                  </div>
                </React.Fragment>
              ) : (
                <div className="bet-success">
                  <h3>
                    {t(
                      "menu.challenge-challenge-created",
                      "Challenge created!"
                    )}
                  </h3>
                  <div>
                    {isForDirect && (
                      <div className="vs-container">
                        <Avatar
                          size="10em"
                          avatar={"/api" + sesion.user.image}
                        />
                        <span className="tunder shake">
                          <img src={tunder} alt="" />
                        </span>
                        <Avatar
                          size="10em"
                          avatar={
                            userActive
                              ? "/api" +
                                props.generalReducer.users.content.filter(
                                  (item) => item.id + "" === userActive + ""
                                )[0].image
                              : null
                          }
                        />
                      </div>
                    )}
                    {isForTeams && (
                      <div className="vs-container">
                        <Avatar
                          size="10em"
                          avatar={"/api" + sesion.user.image}
                        />
                        <span className="tunder shake">
                          <img src={tunder} alt="" />
                        </span>
                        <div className="team-name-bar-2">
                          <span className="points">{team.name}</span>
                        </div>
                      </div>
                    )}
                  </div>
                  <div style={{ marginTop: "1em" }}>
                    <Button onClick={handleSuccess}>
                      {t(
                        "menu.challenge-create-another",
                        "Create another challenge"
                      )}
                    </Button>
                  </div>
                </div>
              )}
            </div>
          </div>

          {challengesCharging ? (
            <span className="spinner-container">
              <SpinnerCircular />
            </span>
          ) : (
            <>
              {challenges && challenges.length > 0 ? (
                <div className="pending-bets" style={{ marginTop: "1em" }}>
                  <Table className="pending-bets-table">
                    <TableHead>
                      <TableRow>
                        <th>
                          {t(
                            "menu.trivia-panel-dialog-add-test-select-challenge",
                            "Challenge name"
                          )}
                        </th>
                        <th>
                          {t(
                            "menu.trivia-panel-dialog-add-test-select-players",
                            "Players"
                          )}
                        </th>
                        <th>
                          {t(
                            "menu.trivia-panel-dialog-add-test-select-teams",
                            "Teams"
                          )}
                        </th>
                        <th>{t("menu.metric-panel-title", "Metric")}</th>
                        <th>
                          {t("place-in-ranking.label", "Place in ranking")}
                        </th>
                        <th>{t("coins.label", "Coins")}</th>
                        <th>
                          {t(
                            "menu.trivia-panel-dialog-add-test-input-dateFrom",
                            "Start date"
                          )}
                        </th>
                        <th>
                          {t(
                            "menu.trivia-panel-dialog-add-test-input-dateTo",
                            "Ending date"
                          )}
                        </th>
                        {/* <th>
                          {t(
                            "admin.header-dropdown-view-conditions-table-state",
                            "State"
                          )}
                        </th> */}
                        <th></th>
                      </TableRow>
                    </TableHead>
                    {challenges.map((data, index) => {
                      let regex = /T(.)*/;
                      let dateTo = replaceAll(data.dateTo, "-", "/").replace(
                        regex,
                        ""
                      );
                      let dateFrom = replaceAll(
                        data.dateFrom,
                        "-",
                        "/"
                      ).replace(regex, "");

                      return (
                        <tr
                          className="table-content"
                          key={index}
                          style={{
                            filter: deleting ? "saturate(0)" : "saturate(1)",
                          }}
                        >
                          <td>{data.name}</td>

                          <td>
                            <div>
                              <DetailsChallenge data={data} />
                            </div>
                          </td>

                          <td>
                            {data.teams.length > 0 ? (
                              data.teams.map((team) => team.name)
                            ) : (
                              <span>
                                {t(
                                  "menu.trivia-panel-dialog-add-test-select-solo-challenge",
                                  "individual challenge"
                                )}
                              </span>
                            )}
                          </td>

                          <td>{data.metricConf.name}</td>
                          <td>
                            {data.conditionType === "LESS_THAN"
                              ? t("me-below.label", "Me below")
                              : t("me-above.label", "Me above")}
                          </td>
                          <td>{data.points}</td>

                          <td>
                            {GeneralFunctions.formatNewDate(dateFrom, es)}
                          </td>

                          <td>{GeneralFunctions.formatNewDate(dateTo, es)}</td>

                          <td
                            onClick={() => {
                              handleDelete(data);
                            }}
                            className="pointer"
                          ></td>
                          <td>
                            {data.isCancelled ? (
                              <span></span>
                            ) : (
                              <span
                                style={{ width: "1em", display: "flex" }}
                                className="pointer"
                                onClick={() => {
                                  if (!deleting) handleCancel(data.id);
                                }}
                              >
                                <img
                                  src={trash}
                                  alt=""
                                  style={{ width: "100%" }}
                                />
                              </span>
                            )}
                          </td>
                        </tr>
                      );
                    })}
                  </Table>

                  <TablePagination
                    className="mt-1"
                    rowsPerPageOptions={[5]}
                    component="div"
                    count={totalElements} //
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={(event, value) =>
                      handleChangePage(event, value)
                    }
                  />
                </div>
              ) : (
                <NotRegistersFound />
              )}
            </>
          )}
        </Container>
      </main>
    );
  else return "";
}

const mapStateToProps = ({ generalReducer, challengesReducer }) => ({
  generalReducer,
  challengesReducer,
});
const mapDispatchToProps = {
  usersRequest,
  metricsRequest,
  teamsRequest,
  getChallengesRequest,

  saveChallengeRequest,
  updateChallengeRequest,
  deleteChallengeRequest,
};

export default connect(mapStateToProps, mapDispatchToProps)(Challenges);
