import React from "react";
import { withStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import Container from "@material-ui/core/Container";
import IconButton from "@material-ui/core/IconButton";
import toastr from "toastr";
import { SpinnerCircular } from "spinners-react";
import { NotRegistersFound } from "../../common/NotRegistersFound";
import Tooltip from "@material-ui/core/Tooltip";
import { withTranslation } from "react-i18next";
import TextField from "@material-ui/core/TextField";
import ArrowDownwardIcon from "@material-ui/icons/ArrowDownward";
import ArrowUpwardIcon from "@material-ui/icons/ArrowUpward";
import { connect } from "react-redux";
import TablePagination from "@material-ui/core/TablePagination";

import {
  teamsRequest,
  secondaryTeamsRequest,
} from "../../../../../actions/generalActions";

import {
  getUsersRequest,
  getUsersFilteredRequest,
  resetState,
  disableUserRequest,
} from "../../../../../actions/usersActions";

/********Services******** */
import UserServices from "../../Services/UserService";
import ImportUserService from "../../Services/ImportUserService";

/*************Icons******** */

import DeleteIcon from "@material-ui/icons/Delete";

import "toastr/build/toastr.min.css";
import SecondaryGroup from "../../common/Dialogs/SecondaryGroup";

const styles = (theme) => ({
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
  },
  fixedHeight: {
    height: 240,
  },
  root: {
    "& > *": {
      marginTop: theme.spacing(2),
    },
  },
  margin: {
    margin: theme.spacing(1),
  },
});

class SecondaryGroupPanel extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      viewImportUser: false,
      rowsPerPage: 5,
      order: "desc",
      filter: "",
      filtering: false,
      page: 0,
      secondaryTeamsList: props.generalReducer.secondary_teams.content,
    };
  }

  componentDidMount() {
    if (!this.props.generalReducer.secondary_teams) {
      this.props.secondaryTeamsRequest();
    }
    this.getUsers(0, "", this.state.order);
  }

  handleChange(event, value) {
    this.setState({ page: value });
    this.getUsers(value, this.state.filter, this.state.order);
  }

  handleChangeOrder() {
    if (this.state.order === "desc") {
      this.setState({ order: "asc" });
    } else {
      this.setState({ order: "desc" });
    }

    this.props.resetState();

    this.getUsers(
      this.state.page,
      this.state.filter,
      this.state.order === "desc" ? "asc" : "desc"
    );
  }

  /***********Pre-import CSV*************** */
  handleImport(e) {
    this.setState({ loading: true });
    const formData = new FormData();
    formData.append("file", e.target.files[0]);
    ImportUserService.setImportUser(formData)
      .then(() => {
        this.saveImports();
      })
      .catch((error) => {
        if (error.response && error.response.status === 422) {
          toastr.error(error.response.data.message);
        } else {
          toastr.error(
            this.props.t(
              "admin.user-panel-message-error-import",
              "An error occurred while trying to pre-import the CSV"
            )
          );
        }
        this.setState({ loading: false });
      });
  }

  saveImports() {
    ImportUserService.saveImportUser()
      .then(() => {
        toastr.success(
          this.props.t(
            "admin.user-panel-message-success-save-import",
            "Users are being imported, when it is ready we will notify you. Thanks"
          )
        );
        this.setState({ loading: false, viewImportUser: false });
      })
      .catch((error) => {
        if (error.response.status === 422) {
          toastr.error(error.response.data.message);
        } else {
          toastr.error(
            this.props.t(
              "admin.user-panel-message-error-save-import",
              "It has happened when integrating the information"
            )
          );
        }

        this.setState({ loading: false });
      });
  }

  /***********Pre-import CSV*************** */

  /***********GENERAL FUNCTIONS************* */

  downloadUsers() {
    this.setState({ loading: true });
    UserServices.downloadUsers()
      .then((response) => {
        var blob = response.data;
        var link = document.createElement("a");
        link.href = window.URL.createObjectURL(new Blob([blob]));
        link.download = "Users.csv";
        link.click();

        this.setState({ loading: false });
      })
      .catch(() => {
        toastr.error(
          this.props.t(
            "admin.user-panel-message-download-users",
            "An error occurred while trying to download users"
          )
        );
        this.setState({ loading: false });
      });
  }

  getUsers(number = 0, filter = "", order = "desc") {
    this.setState({ filter: filter });

    if (filter === "" || !filter) {
      if (this.state.filtering === true) {
        this.props.resetState();
        this.setState({ filtering: false });
        this.setState({ page: 0 });
      }
      this.props.getUsersRequest({
        number: number + 1,
        rowsPerPage: 5,
        order,
      });
    } else {
      if (filter.length > 3) {
        if (this.state.filtering !== true) {
          this.props.resetState();
          this.setState({ filtering: true });
          this.setState({ page: 0 });
        }

        this.props.getUsersFilteredRequest({
          number: number + 1,
          rowsPerPage: 5,
          filter,
          order,
        });
      }
    }
  }

  searchSG(filter) {
    this.setState({ filter: filter });
    const filteredData =
      this.props.generalReducer.secondary_teams.content.filter((el) => {
        if (filter === "") {
          return el.name;
        } else {
          return el.name && el.name.toLowerCase().includes(filter);
        }
      });

    return filteredData;
  }

  /***********CRUD FUNCTIONS************* */

  async deleteUser(id) {
    let status;
    await this.props.disableUserRequest(id).then((r) => (status = r));

    if (status === "SUCCESS") {
      toastr.success(
        this.props.t(
          "admin.user-panel-message-success-delete-users",
          "User deleted successfully"
        )
      );
      return;
    }

    if (status.error) {
      this.props.t(
        "admin.user-panel-message-error-delete-users",
        "An error occurred while deleting the user"
      );
    }
  }

  render() {
    const { loading, rowsPerPage, page, viewImportUser, filter, order } =
      this.state;
    const { classes, t } = this.props;

    return (
      <main className={classes.content}>
        <div className={classes.appBarSpacer} />
        <Container maxWidth="lg" className={classes.container}>
          <Grid container className="d-flex-between mb-1">
            <SecondaryGroup />
            <TextField
              id="outlined-basic"
              label={t("menu.metric-panel-search", "Search")}
              variant="outlined"
              value={filter}
              name="filter"
              className="custom-text-field-user"
              onChange={(e) => this.searchSG(e.target.value)}
            />
          </Grid>
          {(loading || this.props.generalReducer.secondary_teams_charging) &&
            !viewImportUser && (
              <div className="div-spinner">
                <Grid container spacing={1}>
                  <SpinnerCircular size={90} />
                </Grid>
              </div>
            )}

          {!loading && !this.props.generalReducer.secondary_teams_charging ? (
            <Grid container spacing={1}>
              <TableContainer component={Paper}>
                <Table className={classes.table} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell
                        align="left"
                        onClick={() => this.handleChangeOrder()}
                        className="cursor-pointer d-flex-custom"
                      >
                        {order === "desc" ? (
                          <ArrowUpwardIcon />
                        ) : (
                          <ArrowDownwardIcon />
                        )}
                        {t("admin.user-panel-table-name", "Name")}
                      </TableCell>
                      <TableCell align="left">
                        {t("admin.user-panel-table-state", "State")}
                      </TableCell>
                      <TableCell align="left">
                        {t("admin.user-panel-table-actions", "Actions")}
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {this.props.generalReducer.secondary_teams.content &&
                      this.props.generalReducer.secondary_teams.content
                        .slice(
                          page * rowsPerPage,
                          page * rowsPerPage + rowsPerPage
                        )
                        .map((row) => {
                          return (
                            <TableRow key={row.id}>
                              <TableCell align="left">
                                {row.name ? row.name : "Whitout name"}
                              </TableCell>
                              <TableCell align="left">
                                {row.isActive
                                  ? t(
                                      "admin.header-dropdown-view-conditions-table-active-state",
                                      "Active"
                                    )
                                  : t(
                                      "admin.header-dropdown-view-conditions-table-blocked-state",
                                      "Blocked"
                                    )}
                              </TableCell>

                              <TableCell align="left">
                                <div
                                  style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                  }}
                                >
                                  <SecondaryGroup
                                    typeModal="modalEdit"
                                    {...row}
                                  />
                                  {row.isActive && (
                                    <Tooltip
                                      title={t(
                                        "admin.actions-delete",
                                        "Delete"
                                      )}
                                    >
                                      <IconButton
                                        aria-label="delete-user"
                                        onClick={() =>
                                          console.log("DELETING...", row.id)
                                        }
                                      >
                                        <DeleteIcon fontSize="small" />
                                      </IconButton>
                                    </Tooltip>
                                  )}
                                </div>
                              </TableCell>
                            </TableRow>
                          );
                        })}
                  </TableBody>
                </Table>
                <TablePagination
                  className="mt-1"
                  rowsPerPageOptions={[5]}
                  component="div"
                  count={
                    this.props.generalReducer.secondary_teams.numberOfElements
                  }
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={(event, value) =>
                    this.handleChange(event, value)
                  }
                />
              </TableContainer>
            </Grid>
          ) : (
            <>
              <Grid container spacing={1}>
                <Grid item xs={12} md={12} lg={12}>
                  <NotRegistersFound />
                </Grid>
              </Grid>
            </>
          )}
        </Container>
      </main>
    );
  }
}

const mapStateToProps = ({ generalReducer, usersReducer }) => ({
  generalReducer,
  usersReducer,
});
const mapDispatchToProps = {
  teamsRequest,
  secondaryTeamsRequest,
  getUsersRequest,
  getUsersFilteredRequest,
  resetState,
  disableUserRequest,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  withTranslation()(
    withStyles(styles, { withTheme: true })(SecondaryGroupPanel)
  )
);
