import React from "react";
import { NavLink } from "react-router-dom";
import notFound from "../../assets/img/not-found.svg";
import Tooltip from "@material-ui/core/Tooltip";

import "./styles.scss";

const MenuItem = (props) => {
  return (
    <NavLink to={"/" + props.link} activeClassName="link-active">
      <Tooltip title={props.name}>
        <div className="menu-item-wrapper">
          <span className="square-fill">
            <p>{props.name}</p>
          </span>
          <span className="icon-container">
            <div className="icon">
              {props.src === undefined ? (
                <img src={notFound} alt="" style={{ opacity: ".5" }} />
              ) : (
                <img src={props.src} alt={props.name} />
              )}
            </div>
          </span>
        </div>
      </Tooltip>
    </NavLink>
  );
};

MenuItem.defaultProps = {
  name: "",
};

export default MenuItem;
