
import React, { useState } from "react";

import PropTypes from 'prop-types';
import MaskedInput from 'react-text-mask';

import { TextField } from "@material-ui/core";

function TextMaskCustom(props) {
    const { inputRef, ...other } = props;
  
    return (
      <MaskedInput
        {...other}
        ref={(ref) => {
          inputRef(ref ? ref.inputElement : null);
        }}
        mask={[ /0?[0-2]/ ,/[0-3]/,':', /[0-5]/,/[0-9]/, ':', /[0-5]/,/[0-9]/]}
        placeholderChar={'\u2000'}
        showMask
      />
    );
  }
  
  TextMaskCustom.propTypes = {
    inputRef: PropTypes.func.isRequired,
  };

const TimeInput = (props) => {

    const [time, setTime] = useState(props.time);

    const changeTime = (time) => {
      setTime(time);
      if(props.onChange) {
          props.onChange(time);
      }
    }

    return <TextField
                id="maskExample"
                label={props.title}
                className={props.className}
                placeholder={'hh:mm:ss'}
                style={props.style}
                variant="outlined"
                InputProps={{
                  inputComponent: TextMaskCustom,
                  value: time,
                  onChange: (e) => changeTime(e.target.value),
                }}
              />

}
export default TimeInput;