import { makeStyles } from "@material-ui/core/styles";

import BackgroundBlack from "../../../assets/background/black-texture.jpg";

const inputStyles = makeStyles((theme) => ({
  pinkInput: {
    "& .MuiInputUnderline:after": {
      border: `solid 2px #ff0ba3`,
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        border: `solid 2px #ff0ba3`,
        borderRadius: "15px",
      },
      "&:hover fieldset": {
        border: `solid 2px #ff0ba3`,
      },
      "&.Mui-focused fieldset": {
        border: `solid 2px #ff0ba3`,
      },
    },
  },

  lightPinkInput: {
    "& .MuiInputUnderline:after": {
      border: `solid 2px #ff0bff`,
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        border: `solid 2px #ff0bff`,
        borderRadius: "15px",
      },
      "&:hover fieldset": {
        border: `solid 2px #ff0bff`,
      },
      "&.Mui-focused fieldset": {
        border: `solid 2px #ff0bff`,
      },
    },
  },
  purpleInput: {
    "& .MuiInputUnderline:after": {
      border: `solid 2px #840aff`,
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        border: `solid 2px #840aff`,
        borderRadius: "15px",
      },
      "&:hover fieldset": {
        border: `solid 2px #840aff`,
      },
      "&.Mui-focused fieldset": {
        border: `solid 2px #840aff`,
      },
    },
  },

  textField: {
    marginBottom: 12, 
    backgroundColor: "#F6F8FA",
  },
}));

const headerStyles = makeStyles((theme) => ({
  headerModal: {
    borderRadius: "10px",
    border: "solid 2px #ffffff",
    width: "25vw",
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "baseline",
    height: "25vh",
    backgroundImage: `linear-gradient(rgba(0,0,0,1) 0%, rgba(0,0,0,0) 50%, rgba(0,0,0,0) 100%), url(${BackgroundBlack})`,
  },
}));

const labelStyles = makeStyles((theme) => ({
  label: {
    fontWeight: 'bold',
    marginBottom: '2px',
    marginTop: '14px'
  },
}));

const buttonStyles = makeStyles((theme) => ({
  buttonPurple: {
    minWidth: "224px",
    maxWidth: "290px",
    height: "51px",
    textAlign: "center",
    borderRadius: "15px",
    width: "100%",
    border: "solid 2px #3508e7",
    "&:hover": {
      backgroundColor: "#7408E7",
      border: "solid 2px #7408E7",
    },
    marginBottom: "10px",
  },

  buttonPink: {
    minWidth: "224px",
    maxWidth: "290px",
    textAlign: "center",
    height: "51px",
    borderRadius: "15px",
    border: "solid 2px #db0fb6",
    "&:hover": {
      backgroundColor: "#db0fb6",
      border: "solid 2px #db0fb6",
    },
    marginBottom: "10px",
  },
  buttonRed: {
    minWidth: "224px",
    maxWidth: "290px",
    height: "51px",
    textAlign: "center",
    borderRadius: "15px",
    border: "solid 2px #FA1010",
    "&:hover": {
      backgroundColor: "#FA1010",
    },
    marginBottom: "10px",
  },
  buttonBorderless: {
    minWidth: "224px",
    height: "51px",
    borderRadius: "15px",
    border: "solid 0.5px #f3a0f7",
    "&:hover": {
      backgroundColor: "#f0aaf4",
    },
    marginBottom: "10px",
  },
  buttonTalkdesk: {
    //minWidth: "224px",
    width: "48%",
    marginBottom: "10px",
    height: "51px",
    backgroundColor: "#425b76",
    borderColor: "#425b76",
    color: "#ffffff !important",
    "&:hover": {
      backgroundColor: "#5085bf",
      color: "#ffffff",
    },
  },
  buttonGenesys: {
    //minWidth: "224px",
    width: "48%",
    marginBottom: "10px",
    height: "51px",
    backgroundColor: "#FF5823",
    borderColor: "#425b76",
    color: "#ffffff !important",
    "&:hover": {
      backgroundColor: "#ffffff",
      color: "#FF5823 !important",
      borderStyle: "solid",
      borderColor: "#FF5823 !important",
      borderWidth: "1px !important",
    },
  },
}));

const textStyles = makeStyles((theme) => ({
  textButton: {
    fontSize: "16px",
    fontWeight: 300,
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "normal",
    letterSpacing: "1.6px",
    textAlign: "left",
    color: "#000000",
  },
  textButtonButtonTalkdesk: {
    fontSize: "16px",
    fontWeight: 300,
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "normal",
    letterSpacing: "1.6px",
    textAlign: "left",
    color: "#ffffff !important",
  },
}));

export { inputStyles, headerStyles, buttonStyles, textStyles, labelStyles };
