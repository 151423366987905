import React from "react";
import { withStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import Container from "@material-ui/core/Container";
import Pagination from "@material-ui/lab/Pagination";
import toastr from "toastr";
import { SpinnerCircular } from "spinners-react";
import { Link } from "react-router-dom";
import Button from "@material-ui/core/Button";
import { withTranslation } from "react-i18next";
import CloudDownloadIcon from "@material-ui/icons/CloudDownload";

import "toastr/build/toastr.min.css";

/********Dialogs** */
import AddQuestionsDialog from "../../common/Dialogs/AddQuestions";
import { NotRegistersFound } from "../../common/NotRegistersFound";

/********Services***** */
import TestService from "../../Services/TestService";
/*********Dialog********* */
import Delete from "../../common/Dialogs/Delete";
import DetailsQuestion from "../../common/Dialogs/DetailsQuestion";

/******Icons***** */
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";

const styles = (theme) => ({
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
  },
  fixedHeight: {
    height: 240,
  },
  root: {
    "& > *": {
      marginTop: theme.spacing(2),
    },
  },
  margin: {
    margin: theme.spacing(1),
  },
  textField: {
    width: "50ch",
    marginBottom: "5px",
  },
});

class TestsQuestions extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      rows: [],
      rowsSkills: [],
      numberPages: 1,
      page: 1,
    };
  }

  componentDidMount() {
    this.getTestsQuestions();
  }

  handleChange(event, value) {
    this.setState({ page: value });
    this.getTestsQuestions(value);
  }

  /***********GENERAL FUNCTIONS************* */

  updateInformationUpdated() {
    this.setState({ viewImportMetrics: false });
    this.getTestsQuestions();
  }

  getTestsQuestions(number = 1) {
    function createData(
      company_name,
      id,
      text,
      mediaLink,
      mediaType,
      skillName,
      isMandatory,
      points,
      sequence,
      hint,
      hintMediaLink,
      hintMediaType,
      showHint,
      showCorrectAndIncorrect,
      questionType,
      answers,
      trivia
    ) {
      return {
        company_name,
        id,
        text,
        mediaLink,
        mediaType,
        skillName,
        isMandatory,
        points,
        sequence,
        hint,
        hintMediaLink,
        hintMediaType,
        showHint,
        showCorrectAndIncorrect,
        questionType,
        answers,
        trivia,
      };
    }
    this.setState({ loading: true });
    TestService.getTestsQuestions(number)
      .then((response) => {
        this.setState({ rows: [] });
        let rowTests = [];
        response.data.content.forEach((question) =>
          rowTests.push(
            createData(
              question.company_name,
              question.id,
              question.text,
              question.mediaLink,
              question.mediaType,
              question.skillName,
              question.isMandatory,
              question.points,
              question.sequence,
              question.hint,
              question.hintMediaLink,
              question.hintMediaType,
              question.showHint,
              question.showCorrectAndIncorrect,
              question.questionType,
              question.answers,
              question.trivia
            )
          )
        );
        this.setState({ rows: rowTests });
        this.setState({ numberPages: response.data.totalPages });
        this.getAllSkills();
      })
      .catch((error) => {
        toastr.error("Ha ocurrido un error al intentar obtener las preguntas.");
        this.setState({ loading: false });
      });
  }

  getAllSkills() {
    this.setState({ loading: true });
    TestService.getSkills(1, 999)
      .then((response) => {
        this.setState({ rowsSkills: response.data.content });
        this.setState({ loading: false });
      })
      .catch((error) => {
        toastr.error(
          "Ha ocurrido un error al intentar obtener las habilidades."
        );
        this.setState({ loading: false });
      });
  }

  /***********CRUD FUNCTIONS************* */

  deleteTestQuestions(id) {
    this.setState({ loading: true });
    TestService.deleteTestQuestion(id)
      .then(() => {
        toastr.success("Pregunta eliminada con éxito");
        this.setState({ loading: false });
        if (this.state.rows.length - 1 === 0) {
          this.getTestsQuestions(
            this.state.page - 1 === 0 ? 1 : this.state.page - 1
          );
          this.setState({
            page: this.state.page - 1 === 0 ? 1 : this.state.page - 1,
          });
        } else {
          this.getTestsQuestions(this.state.page);
        }

        /******Verify Rows*** */
      })
      .catch((error) => {
        if (error.response.status === 400) {
          toastr.error("Esta pregunta ya fue usada, no puede se eliminada");
        } else {
          toastr.error("Ha ocurrido un error al eliminar la pregunta.");
        }
        this.setState({ loading: false });
      });
  }

  downloadQuestions() {
    this.setState({ loading: true });
    TestService.downloadQuestions()
      .then((response) => {
        var blob = response.data;
        var link = document.createElement("a");
        link.href = window.URL.createObjectURL(new Blob([blob]));
        link.download = "Questions.csv";
        link.click();

        this.setState({ loading: false });
      })
      .catch(() => {
        toastr.error(
          this.props.t(
            "admin.user-panel-message-download-questions",
            "An error occurred while trying to download questions"
          )
        );
        this.setState({ loading: false });
      });
  }

  render() {
    const { loading, rows, numberPages, page, rowsSkills } = this.state;
    const { classes, t } = this.props;

    return (
      <main className={classes.content}>
        <div className={classes.appBarSpacer} />
        <Container maxWidth="lg" className={classes.container}>
          {loading && (
            <div className="div-spinner">
              <Grid container spacing={1}>
                <SpinnerCircular size={90} />
              </Grid>
            </div>
          )}
          {!loading &&
            (rows && rows.length > 0 ? (
              <Grid container spacing={1}>
                <Link to="/test/panel">
                  <Button
                    className="button-table mr-1"
                    variant="contained"
                    color="secondary"
                  >
                    <ArrowBackIosIcon className="mr-1" />
                    {t(
                      "menu.trivia-panel-dialog-test-question-button-back",
                      "Back"
                    )}
                  </Button>
                </Link>

                <Button
                  className="button-table mr-1"
                  variant="contained"
                  color="secondary"
                >
                  {t(
                    "menu.trivia-panel-dialog-test-question-button-title",
                    "Question Editing"
                  )}
                </Button>

                <Button
                  className="button-table"
                  variant="contained"
                  color="primary"
                  onClick={() => this.downloadQuestions()}
                >
                  <CloudDownloadIcon className="mr-1" />
                  {t("menu.user-panel-button-download-csv", "Download CSV")}
                </Button>

                <TableContainer component={Paper}>
                  <Table className={classes.table} aria-label="simple table">
                    <TableHead>
                      <TableRow>
                        <TableCell align="left">
                          {t(
                            "menu.trivia-panel-dialog-test-question-table-test",
                            "Trivia"
                          )}
                        </TableCell>
                        <TableCell align="left">
                          {t(
                            "menu.trivia-panel-dialog-test-question-table-question",
                            "Question"
                          )}
                        </TableCell>
                        <TableCell align="left">
                          {t(
                            "menu.trivia-panel-dialog-test-question-table-required",
                            "Required"
                          )}
                        </TableCell>
                        <TableCell align="left">
                          {t(
                            "menu.trivia-panel-dialog-test-question-table-number-questions",
                            "#N questions"
                          )}
                        </TableCell>
                        <TableCell align="left">
                          {t(
                            "menu.trivia-panel-dialog-test-question-table-clue",
                            "Clue"
                          )}
                        </TableCell>
                        <TableCell align="left">
                          {t("admin.user-panel-table-actions", "Actions")}
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {rows.map((row) => (
                        <TableRow key={row.id}>
                          <TableCell align="left">{row.trivia.name}</TableCell>
                          <TableCell align="left">
                            {row.text && row.text.length > 30
                              ? row.text.substring(0, 30) + "..."
                              : row.text}
                          </TableCell>

                          <TableCell align="left">
                            {row.isMandatory &&
                              t(
                                "admin.header-dropdown-dialog-notifications-input-item-yes",
                                "Yes"
                              )}
                            {!row.isMandatory &&
                              t(
                                "admin.header-dropdown-dialog-notifications-input-item-no",
                                "No"
                              )}
                          </TableCell>
                          <TableCell align="left">
                            {row.answers && row.answers.length > 0
                              ? row.answers.length +
                                " " +
                                t(
                                  "menu.trivia-panel-dialog-test-question-table-responses",
                                  "Responses"
                                )
                              : t(
                                  "menu.trivia-panel-dialog-test-question-table-without-responses",
                                  "Without responses"
                                )}
                          </TableCell>
                          <TableCell align="left">
                            {row.hint &&
                              t(
                                "admin.header-dropdown-dialog-notifications-input-item-yes",
                                "Yes"
                              )}
                            {!row.hint &&
                              t(
                                "admin.header-dropdown-dialog-notifications-input-item-no",
                                "No"
                              )}
                          </TableCell>

                          <TableCell align="left">
                            {row.answers && row.answers.length > 0 && (
                              <DetailsQuestion
                                data={row.answers}
                                rowsSkills={rowsSkills}
                              />
                            )}
                            <AddQuestionsDialog
                              getTestsQuestions={() =>
                                this.getTestsQuestions(page)
                              }
                              typeModal="modalEdit"
                              typeView={"testQuestions"}
                              {...row}
                              rowsSkills={rowsSkills}
                            />

                            <Delete
                              title={t("admin.actions-delete", "Delete")}
                              deleteMetric={() =>
                                this.deleteTestQuestions(row.id)
                              }
                            />
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
                <div className={classes.root}>
                  <Pagination
                    count={numberPages}
                    page={page}
                    color="primary"
                    onChange={(event, value) => this.handleChange(event, value)}
                  />
                </div>
              </Grid>
            ) : (
              <>
                <Grid container spacing={1}>
                  <Link to="/test/panel">
                    <Button
                      className="button-table mr-1"
                      variant="contained"
                      color="secondary"
                    >
                      <ArrowBackIosIcon className="mr-1" />
                      {t(
                        "menu.trivia-panel-dialog-test-question-button-back",
                        "Back"
                      )}
                    </Button>
                  </Link>
                  <Button
                    className="button-table mr-1"
                    variant="contained"
                    color="secondary"
                  >
                    {t(
                      "menu.trivia-panel-dialog-test-question-table-test-questions",
                      "Trivia questions"
                    )}
                  </Button>
                  <Grid item xs={12} md={12} lg={12}>
                    <NotRegistersFound />
                  </Grid>
                </Grid>
              </>
            ))}
        </Container>
      </main>
    );
  }
}

export default withTranslation()(
  withStyles(styles, { withTheme: true })(TestsQuestions)
);
