import * as notificationsVoteTypes from "../types/notificationsVoteTypes";

const {
  RESET_STATE,
  RESET_STORE,
  NOTIFICATIONSVOTE_LIST_REQUEST,
  NOTIFICATIONSVOTE_LIST_SAVED,
  NOTIFICATIONSVOTE_LIST_CHARGING,
  NOTIFICATIONSVOTE_LIST_ERROR,
} = notificationsVoteTypes;

const INITIAL_STATE = {
  notificationsVote: [],
  error_notificationsVote: false,
  notificationsVote_charging: false,
  filter: "",
  totalElements: 0,
  totalPages: 1,
  pages: [],
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case NOTIFICATIONSVOTE_LIST_REQUEST:
      return {
        ...state,
        notificationsVote_charging: false,
        notificationsVote:
          state.filter !== action.payload.filter
            ? [...action.payload.content]
            : [...state.pages].includes(action.payload.number)
            ? [...state.notificationsVote]
            : [...state.notificationsVote, ...action.payload.content],
        totalElements: action.payload.totalElements,
        totalPages: action.payload.totalPages,
        filter: action.payload.filter,
        pages: [...state.pages].includes(action.payload.number)
          ? [...state.pages]
          : [...state.pages, action.payload.number],
        error_notificationsVote: false,
      };

    case NOTIFICATIONSVOTE_LIST_CHARGING:
      return {
        ...state,
        notificationsVote_charging: true,
        error_notificationsVote: false,
      };

    case NOTIFICATIONSVOTE_LIST_ERROR:
      return {
        ...state,
        error_notificationsVote: action.payload,
        notificationsVote_charging: false,
        notificationsVote_save_charging: false,
      };

    case NOTIFICATIONSVOTE_LIST_SAVED:
      return {
        ...state,
        notificationsVote_charging: false,
        error_notificationsVote: false,
      };

    case RESET_STATE:
      return {
        ...state,
        error_notificationsVote: false,
        notificationsVote_charging: false,
        notificationsVote_save_charging: false,
      };

    case RESET_STORE:
      return {
        ...state,
        notificationsVote: [],
        error_notificationsVote: false,
        notificationsVote_charging: false,
        notificationsVote_save_charging: false,
        totalElements: 0,
        totalPages: 1,
        pages: [],
      };

    default:
      return state;
  }
};
