import axios from "axios";
import environment from "../../../../libs/environment";

export default {
  setImportMetric: (data) =>
    axios({
      method: "POST",
      url: `${environment.motivarnosBackend}/importmetricdata/loadfile`,
      data: data,
      headers: {
        "content-type": "multipart/form-data",
      },
    }),

  getImportMetrics: (number = 1, size = 5) =>
    axios({
      method: "GET",
      url: `${environment.motivarnosBackend}/importmetricdata?_number=${
        number - 1
      }&_size=${size}`,
    }),

  savePreImportMetric: (data) =>
    axios({
      method: "POST",
      url: `${environment.motivarnosBackend}/importmetricdata/importfile`,
      data: data,
    }),

  saveImportMetric: (data) =>
    axios({
      method: "POST",
      url: `${environment.motivarnosBackend}/importmetricdata/importdata`,
      data: data,
    }),

  deleteImportMetric: (id) =>
    axios({
      method: "DELETE",
      url: `${environment.motivarnosBackend}/importmetricdata/${id}`,
    }),
};
