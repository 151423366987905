import React, { useState, useContext } from "react";

import TextField from "@material-ui/core/TextField/TextField";
import { FormattedMessage } from "react-intl";
import EmailValidator from "email-validator";
import { useTranslation } from 'react-i18next';

const ValidateEmail = (email) => email && (EmailValidator.validate(email) && email.length <= 50);

const Email = (props) =>{

    const [email, setEmail] = useState(props.email);
    
    const changeEmail = (email) => {
        setEmail(email);
        if(props.onChange) {
            props.onChange(email);
        }
    }
    const { t, i18n } = useTranslation();

    return <TextField
                required
                label={
                    <FormattedMessage
                        id="landing.emailLabel"
                        defaultMessage={t('email.label','Email')}
                    />
                }
                error = {email && !ValidateEmail(email)}  
                value={email}
                className={props.className}
                onChange={(e) => changeEmail(e.target.value)}
                style={{ marginBottom: 12 }}
                variant="outlined"
            />
}

export default Email;