import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import ButtonCustom from "../../../../Button";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import Slide from "@material-ui/core/Slide";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import { useTranslation } from "react-i18next";
import { AppWrapper } from "../../common/GlobalStyles";
import AddIcon from "@material-ui/icons/Add";
import { format } from "date-fns";

import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import FormControl from "@material-ui/core/FormControl";


import toastr from "toastr";

/**********Services******** */
import environment from "../../../../../libs/environment";
import FileService from "../../Services/FileService";

/*********Dialog********* */
import FindRegistersDialog from "../../common/Dialogs/FindRegistersDialog";
import ImageDialog from "../../common/Dialogs/ImageDialog";
import CropImageDialog from "../../common/Dialogs/CropImageDialog";

import "./styles.scss";

const useStyles = makeStyles((theme) => ({
    appBarSpacer: theme.mixins.toolbar,
    content: {
      flexGrow: 1,
      paddingTop: "55px",
    },
    container: {
      paddingTop: theme.spacing(4),
      paddingBottom: theme.spacing(4),
    },
    paper: {
      padding: theme.spacing(2),
      display: "flex",
      overflow: "auto",
      flexDirection: "column",
    },
    fixedHeight: {
      height: 240,
    },
    root: {
      "& > *": {
        marginTop: theme.spacing(2),
      },
    },
    appBar: {
      position: "relative",
    },
    formControl: {
      width: "100%",
      marginTop: "1rem",
    },
  
    title: {
      marginLeft: theme.spacing(2),
      color: "black",
      flex: 1,
    },
    input: {
      display: "none",
    },
  }));
const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
  });  

const NewMissionDialog = (props) => {
  
    const [descriptionError, setDescriptionError] = useState(false);
    const classes = useStyles();
    const [image, setImage] = useState(null);
    const [date, setDate] = useState(format(new Date(), "yyyy-MM-dd"));
    const [teams, setTeams] = useState([]);
    const [secondaryTeams, setSecondaryTeams] = useState([]);
    const [users, setUsers] = useState([]);
    const [status, setStatus] = useState(true);
    const [name, setName] = useState("");
    const [description, setDescription] = useState("");
    const [points, setPoints] = useState(0);
    const [type, setType] = useState("GROUP");
    const [openCropImage, setOpenCropImage] = useState(false);
    const [openImageCrop, setOpenImageCrop] = useState(null);
    const [isIndividualAssignation, setIsIndividualAssignation] = useState(true);
    const [fetching, setFetching] = useState(false);
    const [imageObjectFromDialog, setImageObjectFromDialog] = useState(true);
    const [imageObject, setImageObject] = useState(null);
    const [hideOnDate, setHideOnDate] = useState(format(new Date(), "yyyy-MM-dd"));
    const [startDate, setStartDate] = useState(format(new Date(), "yyyy-MM-dd"));
    const [imagesDownloables, setImagesDownloables] = useState([]);

    const { t } = useTranslation();

    useEffect(() => {
        if (props.data) {
          setName(props.data.name);
          setImagesDownloables(props.data.missionsUser);
          setPoints(props.data.points);
          setDescription(props.data.description);
          setHideOnDate(
            props.data.hideOnDate
              ? format(new Date(props.data.hideOnDate), "yyyy-MM-dd")
              : format(new Date(), "yyyy-MM-dd")
          );
          setStatus(props.data.isActive);
          setIsIndividualAssignation(props.data.isIndividualAssignation);
          setType(props.data.type);
          if (props.data.type === "GROUP") {
            setTeams(formatPropsEdit(props.data.teams ? props.data.teams : []));
            setSecondaryTeams(
              formatPropsEdit(
                props.data.secondaryTeams ? props.data.secondaryTeams : []
              )
            );
          } else {
            setUsers(formatPropsEdit(props.data.users ? props.data.users : []));
          }
    
          setImage(props.data.image ? props.data.image : props.firstImage);
          setDate(
            props.data.expirationDate
              ? format(new Date(props.data.expirationDate), "yyyy-MM-dd")
              : format(new Date(), "yyyy-MM-dd")
          );
          setStartDate(
            props.data.created
              ? format(new Date(props.data.created), "yyyy-MM-dd")
              : format(new Date(), "yyyy-MM-dd")
          );
        } else {
          setImage(props.firstImage);
        }
        // eslint-disable-next-line
      }, []);
  
    function formatPropsEdit(array) {
      let arrayFormatted = [];
      array.forEach((element) => {
        arrayFormatted.push(element.id);
      });
      return arrayFormatted;
    }
  
    const downloadImage = (url, username = 'default') => {
      const imageUrl = url;
      fetch(imageUrl)
        .then(response => {
          if (!response.ok) {
            throw new Error('Error al descargar la imagen');
          }
          return response.blob();
        })
        .then(blob => {
          const url = window.URL.createObjectURL(blob);
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', username);
          document.body.appendChild(link);
          link.click();
          link.parentNode.removeChild(link);
        })
        .catch(error => {
          console.error('Error al descargar la imagen:', error);
        });
    }
    
    const handleChange = ({ target: { name, value } }) => {
      switch (name) {
        case "teams":
          setTeams(value);
          return;
        case "secondaryTeams":
          setSecondaryTeams(value);
          return;
        case "users":
          setUsers(value);
          return;
        case "status":
          setStatus(value);
          return;
        case "name":
          setName(value);
          return;
        case "description":
          setDescription(value);
          return;
  
        case "startDate":
          setStartDate(value);
          return;
  
        case "hideOnDate":
          setHideOnDate(value);
          return;
  
        case "points":
          if (/^\d+$/.test(value) || value === "") setPoints(value);
          return;
        case "type":
          if (value === "GROUP") {
            setUsers([]);
          } else {
            setTeams([]);
          }
          setType(value);
          return;
        case "isIndividualAssignation":
          setIsIndividualAssignation(value);
          return;
        default:
          return;
      }
    };
  
    function setImageDialog(e) {
      setImageObjectFromDialog(e);
      setImage(e);
    }
  
    function handleChangeImage(e) {
      if (e.target.files && e.target.files[0]) {
        setOpenCropImage(true);
        setOpenImageCrop(e.target.files[0]);
      }
    }
  
    function getImageFromDialog(img) {
      setImage(img);
      setImageObject(img);
      setImageObjectFromDialog(false);
      setOpenCropImage(false);
      readURL(img);
    }
  
    function readURL(img) {
      if (img) {
        var reader = new FileReader();
  
        reader.onload = function (e) {
          document
            .getElementById("img-designed")
            .setAttribute("src", e.target.result);
        };
        reader.readAsDataURL(img);
      }
    }
  
    const handleDate = ({ target: { name, value } }) => {
      setDate(value);
    };
  
    const createMission = async () => {
  
      if (description.length > 650) {
        setDescriptionError(true);
        toastr.error(
          t(
          "commons.description-max-length",
          "La descipción no puede tener más de 650 caracteres."
          )
        );
        return;
      }
      let urlCreator = window.URL || window.webkitURL;
      var imageUrl;
      //console.log(typeof image)
      if (typeof image + "" === "string") imageUrl = image;
      else imageUrl = urlCreator.createObjectURL(image);
      setFetching(true);
  
      /***********Format variables****** */
      let urlImage =
        image && image !== "" && !imageObjectFromDialog
          ? !imageObjectFromDialog
            ? ""
            : imageObjectFromDialog
          : image;
  
      if (image && image !== "") {
        if (imageObject && !imageObjectFromDialog) {
          const formData = new FormData();
          formData.append("file", imageObject);
          await FileService.uploadFile(formData, "mission")
            .then((response) => {
              urlImage = response.data.url;
              toastr.success(
                t(
                  "menu.trivia-panel-dialog-add-test-message-success-add-image",
                  "Image added successfully"
                )
              );
            })
            .catch(() => {
              toastr.error(
                t(
                  "menu.trivia-panel-dialog-add-test-message-error-add-image",
                  "An error occurred while trying to add the image"
                )
              );
            });
        }
      }
  
      let data = {
        description,
        expirationDate: date + "T00:00:00",
        isHasHideOnDate: true,
        image: urlImage,
        isActive: status,
        hideOnDate: hideOnDate + "T00:00:00",
        created: format(new Date(), "yyyy-MM-dd") + "T00:00:00",
        dateFrom: startDate + "T00:00:00",
        name,
        isIndividualAssignation,
        points,
        teams: teams.map((data) => {
          return { id: data };
        }),
        secondaryTeams: secondaryTeams.map((data) => {
          return { id: data };
        }),
        type,
        users: users.map((data) => {
          return { id: data };
        }),
      };
  
      let statusSave;
      await props.saveMissionRequest(data).then((r) => (statusSave = r));
  
      if (statusSave === "ERROR") {
        if (
          statusSave.error &&
          statusSave.error.response.data.messageCode === "invalid.mission.expired"
        ) {
          toastr.error(
            t(
              "menu.mission-panel-dialog-add-test-message-error-save-mission-date",
              "The expiration date is today. Try another expiration date"
            )
          );
        } else {
          toastr.error(
            t(
              "menu.mission-panel-dialog-add-test-message-error-save-mission",
              "An error occurred while trying to save the mission"
            )
          );
        }
        setFetching(false);
      } else {
        setFetching(false);
        resetValues();
      }
    };
  
    const editMission = async () => {
      if (description != null && description.length > 650) {
        setDescriptionError(true);
        toastr.error(
          t(
          "commons.description-max-length",
          "La descipción no puede tener más de 650 caracteres."
          )
        );
        return;
      }
      setFetching(true);
  
      /***********Format variables****** */
      let urlImage =
        image && image !== "" && !imageObjectFromDialog
          ? !imageObjectFromDialog
            ? ""
            : imageObjectFromDialog
          : image;
  
      if (image && image !== "") {
        if (imageObject && !imageObjectFromDialog) {
          const formData = new FormData();
          formData.append("file", imageObject);
          await FileService.uploadFile(formData, "mission")
            .then((response) => {
              urlImage = response.data.url;
              toastr.success(
                t(
                  "menu.trivia-panel-dialog-add-test-message-success-add-image",
                  "Image added successfully"
                )
              );
            })
            .catch(() => {
              toastr.error(
                t(
                  "menu.trivia-panel-dialog-add-test-message-error-add-image",
                  "An error occurred while trying to add the image"
                )
              );
            });
        }
      }
  
      let data = {
        description,
        expirationDate: date + "T00:00:00",
        isHasHideOnDate: true,
        id: props.data.id,
        image: urlImage,
        hideOnDate: hideOnDate + "T00:00:00",
        dateFrom: startDate + "T00:00:00",
        isActive: status,
        name,
        isIndividualAssignation,
        points,
        teams: teams.map((data) => {
          return { id: data };
        }),
        secondaryTeams: secondaryTeams.map((data) => {
          return { id: data };
        }),
        type,
        users: users.map((data) => {
          return { id: data };
        }),
      };
  
      let statusEdit;
  
      await props.updateMissionRequest(data).then((r) => (statusEdit = r));
  
      if (statusEdit === "ERROR") {
        if (
          statusEdit.error &&
          statusEdit.error.response.data.messageCode === "invalid.mission.expired"
        ) {
          toastr.error(
            t(
              "menu.mission-panel-dialog-add-test-message-error-save-mission-date",
              "The expiration date is today. Try another expiration date"
            )
          );
        } else {
          toastr.error(
            t(
              "menu.mission-panel-dialog-add-test-message-error-save-mission",
              "An error occurred while trying to save the mission"
            )
          );
        }
        setFetching(false);
      } else {
        setFetching(false);
        resetValues();
      }
    };
  
    const verifyData = () => {
      if (
        name === "" ||
        description === "" ||
        points === "" ||
        image === null ||
        hideOnDate === null
      )
        return true;
      return false;
    };
  
    const resetValues = () => {
      setImage(null);
      setDate(format(new Date(), "yyyy-MM-dd"));
      setTeams([]);
      setSecondaryTeams([]);
  
      setUsers([]);
      setStatus(true);
      setName("");
      setDescription("");
      setHideOnDate(format(new Date(), "yyyy-MM-dd"));
      setPoints(0);
      setType("GROUP");
      setFetching(false);
      props.onClose();
    };
  
  
    return (
        <>
            <Button
            className="button-table mr-1"
            variant="contained"
            color="secondary"
            onClick={() => props.onClose()}
            >
            <AddIcon />
            {props.title}
            </Button>
    
            <Dialog
            open={props.open}
            fullScreen
            TransitionComponent={Transition}
            className="new-mission-wrapper"
            >
            <AppWrapper>
                <AppBar className={classes.appBar}>
                <Toolbar>
                    <IconButton
                    edge="start"
                    onClick={() => {
                        if (!fetching) props.onClose();
                    }}
                    aria-label="close"
                    >
                    <CloseIcon />
                    </IconButton>
                    <Typography variant="h6" className={classes.title}>
                    {props.title}
                    </Typography>
                </Toolbar>
                </AppBar>
    
                <Container maxWidth="lg" className={classes.container}>
                <Grid container spacing={1}>
                    <Grid item xs={12} md={6} lg={6}>
                    <TextField
                        onChange={handleChange}
                        error={false}
                        name="name"
                        id="name"
                        label={t("menu.points-coins-table-mission", "Mission")}
                        variant="outlined"
                        className="mt-2"
                        fullWidth
                        value={name}
                        disabled={fetching}
                    />
                    </Grid>
    
                    <Grid item xs={12} md={6} lg={6}>
                    <TextField
                        onChange={handleChange}
                        error={descriptionError}
                        name="description"
                        id="description"
                        label={t(
                        "menu.trivia-panel-table-description",
                        "Description"
                        ) + ` (${(description? description.length: '0')}/650)`}
                        variant="outlined"
                        className="mt-2"
                        fullWidth
                        value={description}
                        disabled={fetching}
                        inputProps={{ maxLength: 650 }}
                    />
                    </Grid>
    
                    <Grid item xs={12} md={6} lg={6}>
                    <TextField
                        onChange={handleChange}
                        error={false}
                        name="points"
                        id="points"
                        label={t("points.label", "Points")}
                        variant="outlined"
                        className="mt-2"
                        fullWidth
                        value={points}
                        disabled={fetching}
                    />
                    </Grid>
    
                    <Grid item xs={12} md={6} lg={6}>
                    <FormControl variant="outlined" className={classes.formControl}>
                        <InputLabel id="type-select-outlined-label">
                        {t(
                            "admin.header-dropdown-view-conditions-table-state",
                            "State"
                        )}
                        </InputLabel>
                        <Select
                        onChange={handleChange}
                        error={false}
                        value={status}
                        labelId="status"
                        id="status"
                        name="status"
                        label={t(
                            "admin.header-dropdown-view-conditions-table-state",
                            "State"
                        )}
                        defaultValue={status}
                        disabled={fetching}
                        >
                        <MenuItem value={true}>
                            {t(
                            "admin.header-dropdown-view-conditions-table-active-state",
                            "Active"
                            )}
                        </MenuItem>
                        <MenuItem value={false}>
                            {t(
                            "admin.header-dropdown-view-conditions-table-blocked-state",
                            "Blocked"
                            )}
                        </MenuItem>
                        </Select>
                    </FormControl>
                    </Grid>
    
                    <Grid item xs={12} md={6} lg={6}>
                    <FormControl variant="outlined" className={classes.formControl}>
                        <InputLabel id="type-select-outlined-label">
                        {t("type.label", "Type")}
                        </InputLabel>
                        <Select
                        onChange={handleChange}
                        error={false}
                        value={type}
                        labelId="type"
                        id="type"
                        name="type"
                        label={t("type.label", "Type")}
                        defaultValue={type}
                        disabled={fetching}
                        >
                        <MenuItem value={"INDIVIDUAL"}>
                            {t("admin.mission-user-single", "Single")}
                        </MenuItem>
                        <MenuItem value={"GROUP"}>
                            {t("admin.mission-user-group", "Group")}
                        </MenuItem>
                        </Select>
                    </FormControl>
                    </Grid>
                    {/*isIndividualAssignation */}
                    <Grid item xs={12} md={6} lg={6}>
                    <FormControl variant="outlined" className={classes.formControl}>
                        <InputLabel id="type-select-outlined-label">
                        Individual
                        </InputLabel>
                        <Select
                        onChange={handleChange}
                        error={false}
                        value={isIndividualAssignation}
                        labelId="isIndividualAssignation"
                        id="isIndividualAssignation"
                        name="isIndividualAssignation"
                        label={"Individual"}
                        defaultValue={isIndividualAssignation}
                        disabled={fetching}
                        >
                        <MenuItem value={true}>Si</MenuItem>
                        <MenuItem value={false}>No</MenuItem>
                        </Select>
                    </FormControl>
                    </Grid>
    
                    {type === "GROUP" ? (
                    <>
                        {props.teams && props.teams.length > 0 && (
                        <FindRegistersDialog
                            label={
                            t(
                                "admin.header-dropdown-dialog-teams-input",
                                "Teams"
                            )
                            }
                            setValue={setTeams}
                            rows={props.teams}
                            rowsSelected={teams}
                        />
                        )}
                            
                        {props.secondaryTeams && props.secondaryTeams.length > 0 && (
                        <FindRegistersDialog
                            label={
                            t(
                                "admin.header-dropdown-dialog-secondary-teams-input",
                                "Secondary Teams"
                            )
                            }
                            setValue={setSecondaryTeams}
                            rowsSelected={secondaryTeams}
                            rows={props.secondaryTeams}
                        />
                        )}
    
                    </>
                    ): (
                    <>
                        {props.rowsUsers && props.rowsUsers.length > 0 && (
                        <FindRegistersDialog
                            label={
                            t(
                                "menu.trivia-panel-dialog-add-test-select-players",
                                "Players"
                            )
                            }
                            setValue={setUsers}
                            rows={props.rowsUsers}
                            rowsSelected={users}
                        />
                        )}
                    </>
                    )}
            
                    <Grid
                    item
                    xs={12}
                    md={6}
                    lg={6}
                    className="padding-custom-button"
                    >
                    <Grid container spacing={1}>
                        <Grid item xs={5} md={5} lg={5}>
                        <div className="h-100">
                            <input
                            accept="image/x-png,image/gif,image/jpeg"
                            className={classes.input}
                            id="contained-button-file"
                            type="file"
                            onChange={(e) => handleChangeImage(e)}
                            disabled={fetching ? "disabled" : ""}
                            onClick={(e) => {
                                e.target.value = null;
                            }}
                            />
                            <label htmlFor="contained-button-file">
                            <ButtonCustom
                                variant="contained"
                                color="primary"
                                component="span"
                                className="button-table h-100 w-100 button-table-custom"
                                disabled={fetching}
                            >
                                {image === ""
                                ? " " +
                                    t(
                                    "menu.trivia-panel-dialog-add-test-input-image-upload",
                                    "Upload image"
                                    )
                                : t(
                                    "menu.trivia-panel-dialog-add-test-input-image-change",
                                    "Change image"
                                    )}
                            </ButtonCustom>
                            </label>
                        </div>
                        </Grid>
    
                        <Grid item xs={5} md={5} lg={5}>
                        <ImageDialog setImageDialog={(e) => setImageDialog(e)} />
                        </Grid>
                        <Grid item xs={2} md={2} lg={2} className="div-img-designed">
                        {image && image !== "" && !imageObjectFromDialog && (
                            <img
                            id="img-designed"
                            className="img-predesigned"
                            src=""
                            alt=""
                            />
                        )}
                        {image && image !== "" && imageObjectFromDialog && (
                            <img
                            id="img-designed"
                            className="img-predesigned"
                            src={`${environment.motivarnosBackend}${image}`}
                            alt=""
                            />
                        )}
                        </Grid>
                    </Grid>
                    </Grid>
                </Grid>
                </Container>
    
                <Container maxWidth="md" className={classes.container}>
                <Grid container direction="row" justifyContent="space-evenly" alignItems="center">
                    <Grid item xs={4} md={4} lg={4}>
                    <>
                        <h4 style={{ marginBottom: ".5em" }} className="text-center">
                        {t("menu.new-panel-start-date", "Start date")}
                        </h4>
    
                        <div className="d-flex">
                        <span className="date-wrapper">
                            <TextField
                            InputProps={{
                                inputProps: { min: format(new Date(), "yyyy-MM-dd") },
                            }}
                            onChange={handleChange}
                            value={startDate}
                            type="date"
                            name="startDate"
                            id="startDate"
                            label={t("menu.new-panel-start-date", "Start date")}
                            variant="outlined"
                            disabled={fetching}
                            />
                        </span>
                        </div>
                    </>
                    </Grid>
    
                    <Grid item xs={4} md={4} lg={4}>
                    <>
                        <h4 style={{ marginBottom: ".5em" }} className="text-center">
                        {t("admin.mission-mission-deadline", "Mission deadline")}
                        </h4>
    
                        <div className="d-flex">
                        <span className="date-wrapper">
                            <TextField
                            InputProps={{
                                inputProps: { min: format(new Date(), "yyyy-MM-dd") },
                            }}
                            onChange={handleDate}
                            value={date}
                            type="date"
                            name="date"
                            id="date"
                            label={t(
                                "admin.mission-mission-deadline",
                                "Mission deadline"
                            )}
                            variant="outlined"
                            disabled={fetching}
                            />
                        </span>
                        </div>
                    </>
                    </Grid>
    
                    <Grid item xs={4} md={4} lg={4}>
                    <>
                        <h4 style={{ marginBottom: ".5em" }} className="text-center">
                        {t("hide-date.label", "Hide date")}
                        </h4>
    
                        <div className="d-flex">
                        <span className="date-wrapper">
                            <TextField
                            onChange={handleChange}
                            disabled={fetching}
                            value={hideOnDate}
                            type="date"
                            name="hideOnDate"
                            id="hideOnDate"
                            label={t("hide-date.label", "Hide date")}
                            variant="outlined"
                            fullWidth
                            />
                        </span>
                        </div>
                    </>
                    </Grid>
                </Grid>
                </Container>
                <span
                style={{
                    marginTop: "1em",
                    display: "flex",
                    justifyContent: "center",
                    width: "100%",
                    paddingBottom: "2em",
                }}
                >
                <ButtonCustom
                    color="secondary"
                    onClick={props.onClose}
                    disabled={fetching}
                    className={"danger-custom"}
                >
                    {t("admin.header-dropdown-dialog-actions-cancel", "Cancel")}
                </ButtonCustom>
                {props.data ? (
                  <>
                    <ButtonCustom
                    color="secondary"
                    style={{ marginLeft: "1em" }}
                    disabled={fetching || verifyData()}
                    onClick={editMission}
                    >
                    {t("admin.mission-edit-mission", "Edit mission")}
                    </ButtonCustom>
                  </>
                ) : (
                    <ButtonCustom
                    color="primary"
                    style={{ marginLeft: "1em" }}
                    disabled={fetching || verifyData()}
                    onClick={createMission}
                    >
                    {t("admin.mission-add-mission", "Add mission")}
                    </ButtonCustom>
                )}
                </span>
    
                {
                <CropImageDialog
                    open={openCropImage}
                    openImageCrop={openImageCrop}
                    getImageFromDialog={(img) => getImageFromDialog(img)}
                />
                }
            </AppWrapper>
            </Dialog>
        </>
    );
};


export default NewMissionDialog;
