import { useEffect, useState } from "react";

const useUserSesion = () =>  {

  const [sesionUser, setSesionUser] = useState(null);
  
    useEffect(function () {
		setSesionUser(JSON.parse(localStorage.getItem("sesion")));
    }, []);

  return [sesionUser];
}

export default useUserSesion; 