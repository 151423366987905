import React, { useState } from 'react';  /// antes PureComponent
//import PropTypes from 'prop-types';
import './WordList.css';
import Word from './Word';
import TextInput from './forms/TextInput';

function WordList({ title, list_words, emptyMessage, onWordAdded, onWordRemoved, editable,placeholder }) {
  const [propTypes, setPropTypes] = useState(
    {
      title: title,
      words: list_words,
      editable: editable,
    });

  const [newWord, setNewWord] = useState('')


  const handleNewWordChange = (e) => {
    //setNewWord(e.target.value);
    var WORD_RE = /^[a-z]+$/;  
    var w =  e.target.value;
    if(w==='') {
      setNewWord(w); 
    }else if(WORD_RE.test(w))  setNewWord(w);      
    return;
    
  };

  

  const handleSubmit = () => {
    onWordAdded(newWord);
    setNewWord('');
  };

  const words = list_words.map((w, idx) => (
    <ul key={w} className={'WordList-list'}>
      <Word
        word={w}
        controls={propTypes.editable}
        onRemove={() => onWordRemoved(w)}
        key={w + "" + idx}
      />
    </ul>
  ));


  return (
    <div className="WordList">
      {
       // propTypes.title && <div className="WordList-heading">{propTypes.title}</div>
      }
      {
        !emptyMessage && propTypes.words.length === 0 && <div className="WordList-emptyMessage">{emptyMessage}</div>
      }

        {words}
      
      {propTypes.editable && list_words.length<10 &&
        <TextInput
          value={newWord}
          placeholder={placeholder}
          className="WordList-addWord"
          onChange={handleNewWordChange}
          onEnterPressed={handleSubmit}
        />}
    </div>
  );
}
export default WordList;