import { createTheme } from "@material-ui/core/styles";
import pxToRem from "../components/app/utils/pxToRem";

const fontFamily = "Source Sans Pro, sans-serif";
const theme = createTheme({
  palette: {
    primary: {
      light: "#575756",
      main: "#030202",
      dark: "#575756",
      //contrastText: purple4,
    },
    secondary: {
      light: "#575756",
      main: "#575756",
      dark: "#575756",
    },
    // text: {
    //   primary: "#fff",
    //   secondary: purple2,
    //   hint: "#757575"
    // }
  },
  typography: {
    fontFamily: fontFamily,

    h1: {
      fontSize: pxToRem(44),
      lineHeight: 1.3,
      fontWeight: 800,
      marginBottom: pxToRem(12.8),
    },
    h3: {
      fontSize: pxToRem(28),
      fontWeight: 600,
      lineHeight: 1.5,
    },
  },
  breakpoints: {
    mobile: "@media (max-width: 1023px)",
    desktop: "@media (min-width: 1024px)",
  },
});

const mediaBreakpoints = {
  mobile: "@media (max-width: 1023px)",
  desktop: "@media (min-width: 1024px)",
};

export default theme;
export { mediaBreakpoints };
