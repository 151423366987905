import React, { useState } from "react";
import Dialog from "@material-ui/core/Dialog";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import { SpinnerCircular } from "spinners-react";
import Grid from "@material-ui/core/Grid";
import { useTranslation } from "react-i18next";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { ArrowForward } from "@material-ui/icons";
import {
    Box,
    Button,
    FormControl,
    Step,
    StepLabel,
    Stepper,
    TextField,
    Avatar,
    CardContent,
    Tooltip,
    Paper,
} from "@material-ui/core";
import userAvatarIcon from "../../../../../../assets/img/user.svg";
import environment from "../../../../../../libs/environment";
import { DialogContent } from "../DialogStyled/DialogStyled";
import useUserSesion from "../../../../../../hooks/useUserSesion";
import ganaste from "../../../../../../assets/img/ganaste.svg";
import "./DialogStepper.scss"

import toastr from "toastr";

import ColorPrimaryConnector, { ColorlibStyledStep } from "./StyledConnector";

const useStyles = makeStyles((theme) => ({
    formControl: {
        width: "100%",
        marginTop: "0.5rem",
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
    title: {
        display: 'flex',
        justifyContent: 'space-between',
        margin: '20px 15px',
        fontSize: '1.5rem',
    },
    ContainerBadgesItem: {
        display: 'flex'
    },
    badgesContainerImage: {
        display: 'flex',
        justifyContent: 'center',
        padding: '20px 20px 10px 20px'

    },
    badgesImage: {
        width: '50px'
    },
    close: {
        backgroundColor: 'black',
        color: 'white',
        cursor: 'pointer',
        borderRadius: '20px',
        height: '30px',
        width: '30px',
        display: 'flex',
        justifyContent: 'center',
        alignContent: 'center',
        flexWrap: 'wrap',
    },
    badgesBodyItem: {
        fontSize: '1rem',
        textAlign: 'center'
    },
    badgesActiveItem: {
        backgroundColor: '#e6e6e6'
    },
    badgesList: {
        display: 'flex'
    },
    badgesItem: {
        cursor: 'pointer',
        boxShadow: '2px 2px 2px 0px rgb(243 243 243 / 85%)',
        borderRadius: '5px',
        borderStyle: 'solid',
        borderColor: '#EDEDEE',
        borderWidth: '1px'
    },
    diagloContent: {
        borderTop: '0px',
        borderBottom: '0px',
        //padding: '16px 0px'
    },
    cancelBtn: {
        backgroundColor: "red",
        color: "white"
    },
    bodyFinallTitle:  {
        fontSize: "2rem",
        fontWeight: "700",
        textAlign: "center",
    },
    bodyFinallMessage: {
        textAlign: "Center"
    },
}));

const DialogStepper = ({
    isLoading,
    usersList,
    assignBadgesList,
    isOpen,
    handleClose,
    onShareBadge,
}) => {

    const [userSelected, setUserSelected] = useState(null);
    const [badgeActive, setBadgeActive] = useState('');
    const [comment, setComment] = useState("");
    const [searchUserInput, setSearchUserInput] = useState('');
    const [openSearch, setOpenSearch] = useState(false);
    const [activeStep, setActiveStep] = useState(0);
    const [sesion] = useUserSesion();

    const classes = useStyles();
    const { t } = useTranslation();

    function handleChangeComment({ target: { name, value } }) {
        setComment(value);
    }

    function handleBadgeChange(badge) {
        setBadgeActive(badge);
        setComment(badge.description)
    }

    const steps = [
        t('menu.badge-panel-dialog-title'),
        t('menu.trivia-panel-dialog-add-test-select-players'),
        t('add-comment.label')
    ];

    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const isHandleNext = () => {

        if (activeStep === 0 && !!badgeActive) return !badgeActive;

        if (activeStep === 1 && userSelected) return !userSelected;

        if (activeStep === (steps.length - 1) && comment) return !comment;

        return true;
    }

    const handleReset = () => {
        setActiveStep(0);
        setUserSelected(null)
        setBadgeActive(null)
        setComment("");
        handleClose();
    };

    const handleStepper = () => {
        if (activeStep !== steps.length - 1)
            handleNext();

        if (activeStep === 2) {
            onShareBadge(userSelected, badgeActive, comment);
            handleNext();
        }
    }

    function handleChangeUsers(event, value) {
        if(value.id == sesion.user.id) {
            toastr.error("No puedes entregarte una insignia.");
            return;
        }
        setUserSelected(value);
    }

    const displayUsernameOnAutocomplete = ({ name, lastName, username }) => {

        const fullName = `${name} ${lastName}`;
        const usernameOption = (fullName === ' ') ?
            username
            : (username) ? `${fullName} - ${username}` : fullName;

        return usernameOption;
    }

    if (sesion === null) return "";

    return (
        <Dialog
            maxWidth="sm"
            fullWidth
            onClose={handleClose}
            aria-labelledby="customized-dialog-title"
            open={isOpen}
        >
            <div className={classes.title}>
                <div>{t("menu.badge-panel-dialog-button-assign-badge", "Share badge")}</div>
                <div className={classes.close} onClick={handleClose}>x</div>
            </div>
            <DialogContent dividers className={classes.diagloContent + " dialog-content-asign-badge"}>
                <Grid container spacing={1}>
                    <Grid item xs={12} md={12} lg={12}>
                        {isLoading ?
                            <div className="div-spinner">
                                <Grid container spacing={1}>
                                    <SpinnerCircular />
                                </Grid>
                            </div> :
                            // stepper
                            <Box sx={{ width: '100%', marginTop: "40px"}}>
                                <Stepper
                                    alternativeLabel
                                    activeStep={activeStep}
                                    connector={<ColorPrimaryConnector />}
                                    className={"stepper-asign-badge"}
                                >
                                    {steps.map((label, index) => {
                                        const stepProps = {};
                                        const labelProps = {};
                                        return (
                                            <Step key={label} {...stepProps} >
                                                <StepLabel
                                                    StepIconComponent={ColorlibStyledStep}
                                                    {...labelProps}
                                                >
                                                    {label}
                                                </StepLabel>
                                            </Step>
                                        );
                                    })}
                                </Stepper>
                                {activeStep === steps.length ? (
                                    <>
                                        <div>
                                            <div className="body-image"><img src={ganaste} alt="" /></div>
                                            <div className={classes.bodyFinallTitle}>¡Felicidades!</div>
                                            <div className={classes.bodyFinallMessage}>Haz compartido una insignia a un Jugador</div>
                                        </div>
                                        <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                                            <Box sx={{ flex: '1 1 auto' }} />
                                            <Button onClick={handleReset}>Reset</Button>
                                        </Box>
                                    </>
                                ) : (
                                    <Grid container>
                                        <Grid item xs={12} md={12} lg={12}>
                                            {/* step 1 - select badge */}
                                            {activeStep === 0 &&
                                                <FormControl variant="outlined" className={classes.formControl} >
                                                    <div className={classes.badgesList}>
                                                        <Grid container spacing={2} className={`${classes.ContainerBadgesItem}`}>
                                                            {assignBadgesList.map((badge, index) => (
                                                                <Grid item xs={6} md={3} key={index} value={badge} onClick={() => handleBadgeChange(badge)}>
                                                                    <div className={`${classes.badgesItem} ${badgeActive != null && badgeActive.id == badge.id ? classes.badgesActiveItem : ''}`}>
                                                                        <div className={`${classes.badgesContainerImage}`}>
                                                                            <img className={`${classes.badgesImage}`} src={environment.motivarnosBackend + badge.image}/>
                                                                        </div>
                                                                        <div className={`${classes.badgesBodyItem}`}>
                                                                            {badge.name
                                                                                ? badge.name
                                                                                : t(
                                                                                    "admin.user-panel-user-dialog-input-select-without-name",
                                                                                    "Without name"
                                                                                )
                                                                            }
                                                                        </div>
                                                                    </div>
                                                                </Grid>
                                                            ))}
                                                        </Grid>
                                                    </div>
                                                </FormControl>
                                            }
                                            {/* step 2 - select users */}
                                            {
                                                activeStep === 1 &&
                                                <FormControl variant="outlined"
                                                    className={classes.formControl}
                                                >
                                                    <Autocomplete
                                                        filterSelectedOptions
                                                        id="users"
                                                        name="users"
                                                        onChange={
                                                            (event, value) =>
                                                                handleChangeUsers(event, value)
                                                        }
                                                        value={userSelected}
                                                        options={usersList}
                                                        getOptionLabel={(option) =>
                                                            displayUsernameOnAutocomplete(option) ?
                                                                displayUsernameOnAutocomplete(option)
                                                                : t(
                                                                    "admin.user-panel-user-dialog-input-select-without-name",
                                                                    "Without name"
                                                                )
                                                        }
                                                        open={openSearch}
                                                        onOpen={() => {
                                                            // only open when in focus and searchUserInput great than 3
                                                            if (searchUserInput.length > 2) setOpenSearch(true);
                                                        }}
                                                        onClose={() => {
                                                            if (searchUserInput.length < 3) setOpenSearch(false);
                                                            if (userSelected) setOpenSearch(false);
                                                        }}
                                                        inputValue={searchUserInput}
                                                        onInputChange={(e, value, reason) => {
                                                            setSearchUserInput((prev) => value);
                                                            // only open when searchUserInput is not empty 
                                                            // after the user typed something
                                                            if (value.length < 3 && openSearch) setOpenSearch(false);
                                                            if (value.length > 2) setOpenSearch(true);
                                                        }}
                                                        renderInput={(params) => (
                                                            <TextField {...params}
                                                                label={
                                                                    t(
                                                                        "menu.trivia-panel-dialog-add-test-select-players",
                                                                        "Players"
                                                                    )
                                                                }
                                                                placeholder={t("write-search.label", "Write your search")}
                                                                variant="outlined"
                                                            />
                                                        )}
                                                    />
                                                    <CardContent>
                                                        <div className="avatars-delivered">
                                                            <Paper elevation={3} className="user-avatar-container">
                                                                <Tooltip title={`${sesion.user.name} ${sesion.user.lastName}`}>
                                                                    <Avatar
                                                                        className="user-avatar-img"
                                                                        alt={`${sesion.user.name} ${sesion.user.lastName}`}
                                                                        src={`${environment.motivarnosBackend}${sesion.user.image}`}
                                                                        aria-label="user-avatar"
                                                                    />
                                                                </Tooltip>
                                                                <Typography variant="body1" color="secondary">
                                                                    {sesion.user.username}
                                                                </Typography>
                                                            </Paper>
                                                            <Tooltip title="Entregado a">
                                                                <ArrowForward fontSize="large" />
                                                            </Tooltip>
                                                            <Paper elevation={3} className="user-avatar-container">
                                                                <Tooltip title={`${userSelected != null ? userSelected.name + " " + userSelected.lastName : "Sin Nombre"}`}>
                                                                    <Avatar
                                                                        className="user-avatar-img"
                                                                        alt={userSelected != null ? userSelected.username : "Sin Nombre"}
                                                                        src={(userSelected != null && userSelected.image != null) ? `${environment.motivarnosBackend}${userSelected.image}` : userAvatarIcon}
                                                                        aria-label="user-avatar"
                                                                    />
                                                                </Tooltip>
                                                                <Typography variant="body1" color="secondary">
                                                                    {userSelected != null ? userSelected.username : "Sin Nombre"}
                                                                </Typography>
                                                            </Paper>
                                                        </div>
                                                    </CardContent>
                                                </FormControl>
                                            }
                                            {
                                                activeStep === 2 &&
                                                <>
                                                    <Grid item xs={12} md={12} lg={12}>
                                                        <TextField
                                                            onChange={handleChangeComment}
                                                            value={comment}
                                                            rows={3}
                                                            multiline
                                                            inputProps={{ maxLength: 650 }}
                                                            name="comment"
                                                            id="comment"
                                                            label={t(
                                                                "menu.badge-panel-dialog-delivery-comment",
                                                                "Comment"
                                                            )}
                                                            variant="outlined"
                                                            className="mt-2"
                                                            fullWidth
                                                        />
                                                        <p className="commentLength">{comment.length}/650</p>
                                                    </Grid>
                                                </>
                                            }
                                            {/*actions box*/}
                                            <Box
                                                className="actions-buttons-dialogstepper"
                                                sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}
                                            >
                                                <Button
                                                    color="inherit"
                                                    disabled={activeStep === 0}
                                                    onClick={handleBack}
                                                    className={activeStep === 0 ? "button-back" : "button-next"}
                                                    sx={{ mr: 1 }}
                                                >
                                                    {t("back.label")}
                                                </Button>
                                                <Button 
                                                    onClick={handleStepper}
                                                    disabled={isHandleNext()}
                                                    className={isHandleNext() ? "button-back" : "button-next"}
                                                >
                                                    {
                                                        activeStep === steps.length - 1 ?
                                                            t(
                                                                "menu.badge-panel-dialog-button-assign-badge",
                                                                "Share badge"
                                                            )
                                                            : t("next.label")
                                                    }
                                                </Button>
                                            </Box>
                                        </Grid>
                                    </Grid>
                                )}
                            </Box>
                            // stepper
                        }
                    </Grid>
                </Grid>
            </DialogContent>
        </Dialog>

    );
}

export default DialogStepper;