import React from "react";
import { NavLink } from "react-router-dom";
import Grid from "@material-ui/core/Grid";
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';

import correcto from "../../assets/img/correcto.svg";

import "./styles.scss";

const PerformanceCart = (props) => {
  return (
    <div className="card-model card-metrics">
      <Grid xs={3} className="card-metrics-data-container">
        <div className="card-metrics-data">
          <div className="card-metrics-data-title">
            {props.data.title !== null ? props.data.title : "Sin Titulo"}
          </div>
          <div className="card-metrics-data-body">
            <div className="card-metrics-data-body-container">
              <div className="card-metrics-data-sub-title">
                <div>Metrica</div>
                <div>Objetivo</div>
              </div>
              <div
                className={
                  "card-metrics-data-numbers " + ((props.data.milestone.length != undefined &&  props.data.milestone.length > 6)
                  ? "card-metrics-data-title-font-size-small" 
                  : "card-metrics-data-title-font-size-big") 
                }
              >
                <strong>{props.data.value}</strong> / {props.data.milestone}
              </div>
            </div>
          </div>
        </div>
      </Grid>
      <Grid xs={6} className="card-metrics-bar-container">
        <div className="card-metrics-bar-container-group">
          <div className="card-metrics-bar-grid-pointer">
            <div className="card-metrics-bar-grid-pointer-view" style={{marginLeft: `${props.data.percentage}%`}}>
              {props.data.percentage != 100 ? (
              <>
                <div className="card-metrics-bar-grid-pointer-number-block">
                  <div className="card-metrics-bar-grid-pointer-number">
                    {props.data.value}
                  </div>
                </div>
                <div className="card-metrics-bar-grid-pointer-div">
                  <ArrowDropDownIcon/>
                </div>
              </> 
              ) : ( 
              <>
                <img className="card-metrics-bar-grid-pointer-ganaste" style={{width: '30px'}} src={correcto} alt="" />
              </>
              )}
            </div>
          </div>
          <div className="card-metrics-bar">
            <div className="card-metrics-bar-start"></div>
            <div className="card-metrics-bar-end"></div>
          </div>
          <div className="card-metrics-bar-objetive"> Objetivo: <strong>{props.data.value}</strong></div>
        </div>
      </Grid>
      <Grid xs={3} className="card-metrics-button-container">
        <div className="card-metrics-button-container-completado">
          {props.data.value == props.data.milestone && (
            <div className="card-metrics-button-container-completado-tag">
              COMPLETADO
            </div>
          )}
        </div>
        <div className="card-metrics-button-container-ver-detalle">
          <NavLink to={"/metrics/metric?id=" + props.data.id}>
            <div className="button-metric-transparent" >VER DETALLES <div className="button-metric-signo">{">"}</div></div>
          </NavLink>
        </div>
      </Grid>
    </div>
  );
};

export default PerformanceCart;
