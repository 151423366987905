import * as newsTypes from "../types/newsTypes";

import NewService from "../components/app/admin/Services/NewService";

const {
  RESET_STATE,
  NEWS_LIST_REQUEST,
  NEWS_LIST_SAVED,
  NEWS_LIST_SAVE,
  NEWS_LIST_UPDATE,
  NEWS_LIST_DELETE,
  NEWS_LIST_CHARGING,
  NEWS_SAVE_CHARGING,
  NEWS_LIST_ERROR,
} = newsTypes;

export const getNewsRequest = (payload) => async (dispatch, getState) => {
  try {
    const { pages } = getState().newsReducer;
    if (!pages.includes(payload)) {
      dispatch({
        type: NEWS_LIST_CHARGING,
      });
      const responseLogin = await NewService.getNews(payload);
      dispatch({
        type: NEWS_LIST_REQUEST,
        payload: { ...responseLogin.data, number: payload },
      });
    } else {
      dispatch({
        type: NEWS_LIST_SAVED,
      });
    }
  } catch (error) {
    dispatch({
      type: NEWS_LIST_ERROR,
      payload: error.response ? error.response.data : error,
    });
  }
};

export const saveNewRequest = (payload) => async (dispatch, getState) => {
  dispatch({
    type: NEWS_SAVE_CHARGING,
  });

  try {
    const responseLogin = await NewService.saveNew(payload);
    const { news } = getState().newsReducer;
    const newsUpdated = [responseLogin.data, ...news];

    dispatch({
      type: NEWS_LIST_SAVE,
      payload: newsUpdated,
    });
    return { status: "SUCCESS" };
  } catch (error) {
    dispatch({
      type: NEWS_LIST_ERROR,
      payload: error.response ? error.response.data : error,
    });
    return { status: "ERROR", error: error.response };
  }
};

export const updateNewRequest = (payload) => async (dispatch, getState) => {
  dispatch({
    type: NEWS_SAVE_CHARGING,
  });

  try {
    await NewService.updateNew(payload);
    const { news } = getState().newsReducer;

    const newsUpdated = [...news];
    const findById = (newR) => newR.id === payload.id;
    const index = newsUpdated.findIndex(findById);
    newsUpdated[index] = {
      ...newsUpdated[index],
      ...payload,
    };

    dispatch({
      type: NEWS_LIST_UPDATE,
      payload: newsUpdated,
    });
    return { status: "SUCCESS" };
  } catch (error) {
    dispatch({
      type: NEWS_LIST_ERROR,
      payload: error.response ? error.response.data : error,
    });
    return { status: "ERROR", error: error.response };
  }
};

export const deleteNewRequest = (payload) => async (dispatch) => {
  dispatch({
    type: NEWS_LIST_CHARGING,
  });

  try {
    await NewService.deleteNew(payload);

    dispatch({
      type: NEWS_LIST_DELETE,
      payload: payload,
    });
    return "SUCCESS";
  } catch (error) {
    dispatch({
      type: NEWS_LIST_ERROR,
      payload: error.response ? error.response.data : error,
    });
    return { error: error.response };
  }
};

export const resetState = () => async (dispatch) => {
  dispatch({
    type: RESET_STATE,
  });
};
