import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Slide from "@material-ui/core/Slide";
import Dialog from "@material-ui/core/Dialog";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import Button from "@material-ui/core/Button";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import CloseIcon from "@material-ui/icons/Close";
import Typography from "@material-ui/core/Typography";

import { useTranslation } from "react-i18next";
import { AppWrapper } from "../../common/GlobalStyles";

import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import Container from "@material-ui/core/Container";

import "./suggestionInboxDetail.scss";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const useStyles = makeStyles((theme) => ({
  appBarSpacer: theme.mixins.toolbar,
  appBar: {
    position: "relative",
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
  container: {
    marginTop: "2rem",
  },
}));

const SuggestionInboxDetail = (props) => {
  const classes = useStyles();
  const [suggestion, setSuggestion] = useState(null);

  const { t } = useTranslation();

  useEffect(() => {
    if (props.suggestions_inbox) {
      const INDEX_SUGGESTION = props.suggestions_inbox.findIndex((elem) => elem.id == props.testID);
      setSuggestion(props.suggestions_inbox[INDEX_SUGGESTION]);
    }
  }, [props.testID]);

  return (
    <Dialog
      fullScreen 
      TransitionComponent={Transition}
      className="new-mission-wrapper"
      open={props.open}
    >
      <AppWrapper>
        <AppBar className={classes.appBar}>
          <Toolbar>
            <IconButton
              edge="start"
              onClick={() => { props.handleClose(); }}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography variant="h6" className={classes.title}>
              {props.title}
            </Typography>
            <Button
                className="button-table mt-2"
                color="primary"
               
              >
                {t("admin.header-dropdown-dialog-actions-save", "Save")}
              </Button>
          </Toolbar>
        </AppBar>

        <div>
          <Container maxWidth="lg" className={classes.container}>
            <Grid container spacing={3}>
  
                <TableContainer component={Paper} className="mt-2">
                  <Table aria-label="simple table">
                    <TableHead>
                      <TableRow>
                        <TableCell align="left">
                          {t("user.label", "User")}
                        </TableCell>
                        <TableCell align="left">
                          {t(
                            "menu.badge-report-message-table",
                            "Message"
                          )}
                        </TableCell>
                        {/* <TableCell align="left">
                         Estado
                        </TableCell> */}
                      </TableRow>
                    </TableHead>

                    <TableBody>
                      <TableRow>
                        <TableCell align="left">{ suggestion ? suggestion.email : '' }</TableCell>

                        <TableCell align="left">{ suggestion ? suggestion.message : '' }</TableCell>
                       {/*  <TableCell align="left">
  <Select
  align="left"
    value={suggestion ? (suggestion.isActive ? "Pendiente" : "Inactivo") : ""}
    onChange={(e) => {
      const value = e.target.value;
      // Aquí puedes manejar el cambio de estado como desees
    }}
  >
    <MenuItem  align="left" value="Pendiente">Pendiente</MenuItem>
    <MenuItem  align="left" value="Inactivo">En progreso</MenuItem>
    <MenuItem  align="left" value="Inactivo">Hecho</MenuItem>
    <MenuItem  align="left" value="Inactivo">No aplica/Deprecado</MenuItem>
  </Select>
</TableCell> */}

                      </TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
            </Grid>
          </Container>
        </div>
      </AppWrapper>
    </Dialog>
  )
}

export default SuggestionInboxDetail;
