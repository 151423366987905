import * as generalTypes from "../types/generalTypes";

const {
  TEAMS_REQUEST,
  TEAMS_CHARGING,
  TEAMS_ERROR,
  AWARDS_REQUEST,
  AWARDS_CHARGING,
  AWARDS_ERROR,
  IMAGES_REQUEST,
  IMAGES_CHARGING,
  IMAGES_ERROR,
  SKILLS_REQUEST,
  SKILLS_CHARGING,
  SKILLS_ERROR,
  SECONDARY_TEAMS_REQUEST,
  SECONDARY_TEAMS_CHARGING,
  SECONDARY_TEAMS_ERROR,
  USERS_REQUEST,
  USERS_CHARGING,
  USERS_ERROR,
  METRICS_CHARGING,
  METRICS_ERROR,
  METRICS_REQUEST,
  TESTS_CHARGING,
  TESTS_ERROR,
  TESTS_REQUEST,
  LETTERSOUP_CHARGING,
  LETTERSOUP_ERROR,
  LETTERSOUP_REQUEST,
  BADGES_CHARGING,
  BADGES_ERROR,
  BADGES_REQUEST,
  ASSIGN_BADGES_CHARGING,
  ASSIGN_BADGES_ERROR,
  ASSIGN_BADGES_REQUEST,
  RESET_STATE,
  RESET_STORE,
} = generalTypes;

const INITIAL_STATE = {
  teams: false,
  error_teams: false,
  teams_charging: false,
  awards: false,
  error_awards: false,
  awards_charging: false,
  images: false,
  error_images: false,
  images_charging: false,
  skills: false,
  error_skills: false,
  skills_charging: false,
  secondary_teams: false,
  error_secondary_teams: false,
  secondary_teams_charging: false,
  users: false,
  error_users: false,
  users_charging: false,
  metrics: false,
  error_metrics: false,
  metrics_charging: false,
  tests: false,
  error_tests: false,
  tests_charging: false,
  lettersoup: false,
  error_letterSoup: false,
  letterSoup_charging: false,

  badges: false,
  error_badges: false,
  badges_charging: false,

  assign_badges: false,
  error_assign_badges: false,
  assign_badges_charging: false,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case AWARDS_REQUEST:
      return {
        ...state,
        awards_charging: false,
        awards: action.payload,
        error_awards: false,
      };

    case AWARDS_CHARGING:
      return { ...state, awards_charging: true, error_awards: false };

    case AWARDS_ERROR:
      return {
        ...state,
        error_awards: action.payload,
        awards_charging: false,
        awards: [],
      };

    case TEAMS_REQUEST:
      return {
        ...state,
        teams_charging: false,
        teams: action.payload,
        error_teams: false,
      };

    case TEAMS_CHARGING:
      return { ...state, teams_charging: true, error_teams: false };

    case TEAMS_ERROR:
      return {
        ...state,
        error_teams: action.payload,
        teams_charging: false,
        teams: [],
      };

    case IMAGES_REQUEST:
      return {
        ...state,
        images_charging: false,
        images: action.payload,
        error_images: false,
      };

    case IMAGES_CHARGING:
      return { ...state, images_charging: true, error_images: false };

    case IMAGES_ERROR:
      return {
        ...state,
        error_images: action.payload,
        images_charging: false,
        images: [],
      };

    case SKILLS_REQUEST:
      return {
        ...state,
        skills_charging: false,
        skills: action.payload,
        error_skills: false,
      };

    case SKILLS_CHARGING:
      return { ...state, skills_charging: true, error_skills: false };

    case SKILLS_ERROR:
      return {
        ...state,
        error_skills: action.payload,
        skills_charging: false,
        skills: [],
      };

    case SECONDARY_TEAMS_REQUEST:
      return {
        ...state,
        secondary_teams_charging: false,
        secondary_teams: action.payload,
        error_secondary_teams: false,
      };

    case SECONDARY_TEAMS_CHARGING:
      return {
        ...state,
        secondary_teams_charging: true,
        error_secondary_teams: false,
      };

    case SECONDARY_TEAMS_ERROR:
      return {
        ...state,
        error_secondary_teams: action.payload,
        secondary_teams_charging: false,
        secondary_teams: [],
      };

    case USERS_REQUEST:
      return {
        ...state,
        users_charging: false,
        users: action.payload,
        error: false,
      };

    case USERS_CHARGING:
      return { ...state, users_charging: true, error: false };

    case USERS_ERROR:
      return {
        ...state,
        error: action.payload,
        users_charging: false,
        users: [],
      };

    case METRICS_REQUEST:
      return {
        ...state,
        metrics_charging: false,
        metrics: action.payload,
        error: false,
      };

    case METRICS_CHARGING:
      return { ...state, metrics_charging: true, error: false };

    case METRICS_ERROR:
      return {
        ...state,
        error: action.payload,
        metrics_charging: false,
        metrics: [],
      };

    case TESTS_REQUEST:
      return {
        ...state,
        tests_charging: false,
        tests: action.payload,
        error_tests: false,
      };

    case TESTS_CHARGING:
      return { ...state, tests_charging: true, error_tests: false };

    case TESTS_ERROR:
      return {
        ...state,
        error_tests: action.payload,
        tests_charging: false,
        tests: [],
      };

    case LETTERSOUP_REQUEST:
      return {
        ...state,
        letterSoup_charging: false,
        lettersoup: action.payload,
        error_letterSoup: false,
      };

    case LETTERSOUP_CHARGING:
      return { ...state, letterSoup_charging: true, error_letterSoup: false };

    case LETTERSOUP_ERROR:
      return { ...state, error_letterSoup: action.payload, letterSoup_charging: false };

    case BADGES_REQUEST:
      return {
        ...state,
        badges_charging: false,
        badges: action.payload,
        error_badges: false,
      };

    case BADGES_CHARGING:
      return { ...state, badges_charging: true, error_badges: false };

    case BADGES_ERROR:
      return {
        ...state,
        error_badges: action.payload,
        badges_charging: false,
        badges: [],
      };

    case ASSIGN_BADGES_REQUEST:
      return {
        ...state,
        assign_badges_charging: false,
        assign_badges: action.payload,
        error_assign_badges: false,
      };

    case ASSIGN_BADGES_CHARGING:
      return {
        ...state,
        assign_badges_charging: true,
        error_assign_badges: false,
      };

    case ASSIGN_BADGES_ERROR:
      return {
        ...state,
        error_assign_badges: action.payload,
        assign_badges_charging: false,
        assign_badges: [],
      };

    case RESET_STATE:
      return {
        ...state,
        teams: false,
        error_teams: false,
        teams_charging: false,
        secondary_teams: false,
        error_secondary_teams: false,
        secondary_teams_charging: false,
        users: false,
        error_users: false,
        users_charging: false,
        metrics: false,
        error_metrics: false,
        metrics_charging: false,
        tests: false,
        error_tests: false,
        tests_charging: false,
        lettersoup: false,
        error_letterSoup: false,
        letterSoup_charging: false,

        badges: false,
        error_badges: false,
        badges_charging: false,

        assign_badges: false,
        error_assign_badges: false,
        assign_badges_charging: false,
      };

    case RESET_STORE:
      return {
        ...state,
        teams: false,
        error_teams: false,
        teams_charging: false,
        secondary_teams: false,
        error_secondary_teams: false,
        secondary_teams_charging: false,
        users: false,
        error_users: false,
        users_charging: false,
        metrics: false,
        error_metrics: false,
        metrics_charging: false,
        tests: false,
        error_tests: false,
        tests_charging: false,
        lettersoup: false,
        error_letterSoup: false,
        letterSoup_charging: false,

        badges: false,
        error_badges: false,
        badges_charging: false,

        assign_badges: false,
        error_assign_badges: false,
        assign_badges_charging: false,
      };

    default:
      return state;
  }
};
