import jsonFetch from "../../../utils/jsonFetch";
import environment from "../../../../../libs/environment";
const auth = (token) => {
  return {
    postSignup: (data) => {
      return jsonFetch({
        endpoint: `${environment.motivarnosBackend}/company/signup`,
        method: "POST",
        jsonBody: data,
      });
    },
    postLogin: (data) => {
      return jsonFetch({
        endpoint: `${environment.motivarnosBackend}/login`,
        method: "POST",
        jsonBody: data,
      });
    },

    verifyUserTerms: (data) => {
      return jsonFetch({
        endpoint: `${environment.motivarnosBackend}/user/acceptconditions`,
        method: "POST",
        token: data,
        jsonBody: {},
      });
    },

    getLoginExternal: (data) => {
      return jsonFetch({
        endpoint: `${environment.motivarnosBackend}/v1/integration/authurl/${data.type}?companyName=${data.company}`,
        method: "GET",
      });
    },
    postRefreshToken: () => {
      return jsonFetch({
        endpoint: `${environment.motivarnosBackend}/sesion/renovar`,
        method: "POST",
        token: token,
      });
    },
  };
};

export { auth };
