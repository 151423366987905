import React from "react";
import { withStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import Container from "@material-ui/core/Container";
import toastr from "toastr";
import { SpinnerCircular } from "spinners-react";
import Button from "@material-ui/core/Button";
import Delete from "../../common/Dialogs/Delete";
import { Link } from "react-router-dom";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import TablePagination from "@material-ui/core/TablePagination";

import {
  teamsRequest,
  usersRequest,
  metricsRequest,
  testsRequest,
  secondaryTeamsRequest,
  badgesRequest,
  assignBadgesRequest,
} from "../../../../../actions/generalActions";

import {
  getBadgesRequest,
  deleteBadgeRequest,
} from "../../../../../actions/badgesActions";
import { NotRegistersFound } from "../../common/NotRegistersFound";

import environment from "../../../../../libs/environment";

import "toastr/build/toastr.min.css";

/*********Dialog********* */
import BadgeDialog from "../../common/Dialogs/Badge";
import AssignBadge from "../../common/Dialogs/AssignBadge";

/*********Icons********* */
import ViewListIcon from "@material-ui/icons/ViewList";

const styles = (theme) => ({
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
  },
  fixedHeight: {
    height: 240,
  },
  root: {
    "& > *": {
      marginTop: theme.spacing(2),
    },
  },
  margin: {
    margin: theme.spacing(1),
  },
  textField: {
    width: "50ch",
    marginBottom: "5px",
  },
});

class Badges extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      page: 0,
      rowsPerPage: 5,
    };
  }

  componentDidMount() {
    this.props.getBadgesRequest(1);

    if (!this.props.generalReducer.teams) {
      this.props.teamsRequest();
    }

    if (!this.props.generalReducer.tests) {
      this.props.testsRequest();
    }

    if (!this.props.generalReducer.secondary_teams) {
      this.props.secondaryTeamsRequest();
    }
    if (!this.props.generalReducer.users) {
      this.props.usersRequest();
    }

    if (!this.props.generalReducer.metrics) {
      this.props.metricsRequest();
    }

    if (!this.props.generalReducer.badges) {
      this.props.badgesRequest();
    }
    if (!this.props.generalReducer.assign_badges) {
      this.props.assignBadgesRequest();
    }
  }

  handleChange(event, value) {
    this.setState({ page: value });
    this.props.getBadgesRequest(value + 1);
  }

  /***********GENERAL FUNCTIONS************* */

  /***********CRUD FUNCTIONS************* */

  async deleteBadge(id) {
    let status;
    await this.props.deleteBadgeRequest(id).then((r) => (status = r));

    if (status === "SUCCESS") {
      toastr.success(
        this.props.t(
          "menu.badge-panel-message-success-delete-award",
          "Badge removed successfully"
        )
      );
      return;
    }

    if (status.error && status.error.status === 400) {
      toastr.error(
        this.props.t(
          "menu.badge-panel-message-error-delete-award-one",
          "This badge is being worn and cannot be removed"
        )
      );
    } else {
      toastr.error(
        this.props.t(
          "menu.badge-panel-message-error-delete-award-two",
          "An error occurred while removing the badge"
        )
      );
    }
  }

  render() {
    const { rowsPerPage, page } = this.state;
    const { classes, t } = this.props;

    return (
      <main className={classes.content}>
        <div className={classes.appBarSpacer} />
        <Container maxWidth="lg" className={classes.container}>
          {(this.props.badgesReducer.badges_charging ||
            this.props.generalReducer.teams_charging ||
            this.props.generalReducer.secondary_teams_charging ||
            this.props.generalReducer.users_charging ||
            this.props.generalReducer.metrics_charging ||
            this.props.generalReducer.tests_charging ||
            this.props.generalReducer.badges_charging ||
            this.props.generalReducer.assign_badges_charging) && (
            <div className="div-spinner">
              <Grid container spacing={1}>
                <SpinnerCircular size={90} />
              </Grid>
            </div>
          )}
          {!this.props.badgesReducer.badges_charging &&
            !this.props.generalReducer.teams_charging &&
            !this.props.generalReducer.secondary_teams_charging &&
            !this.props.generalReducer.users_charging &&
            !this.props.generalReducer.metrics_charging &&
            !this.props.generalReducer.tests_charging &&
            !this.props.generalReducer.badges_charging &&
            !this.props.generalReducer.assign_badges_charging &&
            (this.props.badgesReducer.badges &&
            this.props.badgesReducer.badges.length > 0 ? (
              <Grid container spacing={1}>
                {this.props.generalReducer.teams &&
                  this.props.generalReducer.secondary_teams &&
                  this.props.generalReducer.users &&
                  this.props.generalReducer.metrics &&
                  this.props.generalReducer.tests && (
                    <BadgeDialog
                      rowsTeams={this.props.generalReducer.teams.content}
                      rowSecondaryTeams={
                        this.props.generalReducer.secondary_teams.content
                      }
                      rowsUsers={this.props.generalReducer.users.content}
                      rowsMetrics={this.props.generalReducer.metrics.content}
                      rowsTests={this.props.generalReducer.tests.content}
                    />
                  )}

                {this.props.generalReducer.assign_badges &&
                  this.props.generalReducer.assign_badges.content.length > 0 &&
                  this.props.generalReducer.users && (
                    <AssignBadge
                      rowsAssignBadges={
                        this.props.generalReducer.assign_badges.content
                      }
                      isAdmin={true}
                      rowsUsers={this.props.generalReducer.users.content}
                    />
                  )}

                <Link to="/badges/report">
                  <Button
                    className="button-table mr-1"
                    variant="contained"
                    color="secondary"
                  >
                    <ViewListIcon className="mr-1" />
                    {t("report.label", "Report")}
                  </Button>
                </Link>

                <TableContainer component={Paper}>
                  <Table className={classes.table} aria-label="simple table">
                    <TableHead>
                      <TableRow>
                        <TableCell align="left">
                          {t("name.label", "Name")}
                        </TableCell>
                        <TableCell align="left">
                          {t(
                            "menu.trivia-panel-table-description",
                            "Description"
                          )}
                        </TableCell>
                        <TableCell align="left">
                          {t("points.label", "Points")}
                        </TableCell>
                        <TableCell align="left">
                          {t(
                            "menu.badge-panel-table-delivery-method",
                            "Delivery method"
                          )}
                        </TableCell>
                        <TableCell align="left">
                          {t(
                            "admin.header-dropdown-view-conditions-table-state",
                            "State"
                          )}
                        </TableCell>
                        <TableCell align="left">
                          {t("image.label", "Image")}
                        </TableCell>
                        <TableCell align="left">
                          {t("admin.user-panel-table-actions", "Actions")}
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {this.props.badgesReducer.badges
                        .slice(
                          page * rowsPerPage,
                          page * rowsPerPage + rowsPerPage
                        )
                        .map((row) => (
                          <TableRow key={row.id}>
                            <TableCell align="left">
                              {row.name.length > 30
                                ? row.name.substring(0, 30) + "..."
                                : row.name}
                            </TableCell>

                            <TableCell align="left">
                              {row.description.length > 30
                                ? row.description.substring(0, 30) + "..."
                                : row.description}
                            </TableCell>

                            <TableCell align="left">{row.points}</TableCell>
                            <TableCell align="left">
                              {row.type === "AUTOMATIC"
                                ? t(
                                    "menu.badge-panel-table-automatic",
                                    "Automatic"
                                  )
                                : t(
                                    "menu.badge-panel-table-handbook",
                                    "Handbook"
                                  )}
                            </TableCell>

                            <TableCell align="left">
                              {row.isActive === true &&
                                t(
                                  "admin.header-dropdown-view-conditions-table-active-state",
                                  "Active"
                                )}
                              {row.isActive !== true &&
                                t(
                                  "menu.trivia-panel-table-inactive",
                                  "Inactive"
                                )}
                            </TableCell>

                            <TableCell align="left">
                              {row.image && row.image !== "" ? (
                                <img
                                  alt="Badge"
                                  className="img-rounded"
                                  src={`${environment.motivarnosBackend}${row.image}`}
                                />
                              ) : (
                                "N/A"
                              )}
                            </TableCell>
                            <TableCell align="left">
                              <BadgeDialog
                                rowsTeams={
                                  this.props.generalReducer.teams.content
                                }
                                rowSecondaryTeams={
                                  this.props.generalReducer.secondary_teams
                                    .content
                                }
                                rowsUsers={
                                  this.props.generalReducer.users.content
                                }
                                rowsMetrics={
                                  this.props.generalReducer.metrics.content
                                }
                                rowsTests={
                                  this.props.generalReducer.tests.content
                                }
                                {...row}
                                isPointsGranted={row.isPointsGranted}
                                getBadges={() => this.getBadges(page, true)}
                                typeModal="modalEdit"
                              />

                              <Delete
                                title={t(
                                  "menu.badge-panel-table-badge-management",
                                  "Badge management"
                                )}
                                deleteMetric={() => this.deleteBadge(row.id)}
                              />
                            </TableCell>
                          </TableRow>
                        ))}
                    </TableBody>
                  </Table>

                  <TablePagination
                    className="mt-1"
                    rowsPerPageOptions={[5]}
                    component="div"
                    count={this.props.badgesReducer.totalElements}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={(event, value) =>
                      this.handleChange(event, value)
                    }
                  />
                </TableContainer>
              </Grid>
            ) : (
              <>
                <Grid container spacing={1}>
                  <Grid item xs={12} md={12} lg={12}>
                    {this.props.generalReducer.teams &&
                      this.props.generalReducer.secondary_teams &&
                      this.props.generalReducer.users &&
                      this.props.generalReducer.metrics &&
                      this.props.generalReducer.tests && (
                        <BadgeDialog
                          rowsTeams={this.props.generalReducer.teams.content}
                          rowSecondaryTeams={
                            this.props.generalReducer.secondary_teams.content
                          }
                          rowsUsers={this.props.generalReducer.users.content}
                          rowsMetrics={
                            this.props.generalReducer.metrics.content
                          }
                          rowsTests={this.props.generalReducer.tests.content}
                          getBadges={() => this.getBadges(page, true)}
                        />
                      )}

                    <NotRegistersFound />
                  </Grid>
                </Grid>
              </>
            ))}
        </Container>
      </main>
    );
  }
}

const mapStateToProps = ({ generalReducer, badgesReducer }) => ({
  generalReducer,
  badgesReducer,
});
const mapDispatchToProps = {
  teamsRequest,
  usersRequest,
  metricsRequest,
  testsRequest,
  secondaryTeamsRequest,
  badgesRequest,
  assignBadgesRequest,
  getBadgesRequest,
  deleteBadgeRequest,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(withStyles(styles, { withTheme: true })(Badges)));
