import styled from "styled-components";
import environment from "../../../../libs/environment";

export const AppWrapper = styled.div(
  () => `  
    background: ${
      JSON.parse(window.localStorage.getItem("theme"))
        ? "("
        : "linear-gradient(45deg," +
          (JSON.parse(window.localStorage.getItem("theme")) &&
          JSON.parse(window.localStorage.getItem("theme")).backgroundColor1
            ? JSON.parse(window.localStorage.getItem("theme")).backgroundColor1
            : " #fa0050") +
          "," +
          (JSON.parse(window.localStorage.getItem("theme")) &&
          JSON.parse(window.localStorage.getItem("theme")).backgroundColor2
            ? JSON.parse(window.localStorage.getItem("theme")).backgroundColor2
            : " #fa0050")
    });
  
  
    ${
      JSON.parse(window.localStorage.getItem("theme"))
        ? `background-image: url(${
            JSON.parse(window.localStorage.getItem("theme")).backgroundImage
          });`
        : ""
    }       
  
      background-repeat: no-repeat;
      background-size: cover;
    
      span ,a ,p ,h1 ,h2 ,h3 ,h4 ,h5, h6 ,label ,li, div  { color: ${
        JSON.parse(window.localStorage.getItem("theme")) &&
        JSON.parse(window.localStorage.getItem("theme")).textColor
          ? JSON.parse(window.localStorage.getItem("theme")).textColor
          : "#000000"
      } !important };
      
      path  { color: ${
        JSON.parse(window.localStorage.getItem("theme")) &&
        JSON.parse(window.localStorage.getItem("theme")).iconColor
          ? JSON.parse(window.localStorage.getItem("theme")).iconColor
          : "black"
      } !important };
      `
);

export const AppTableRanking = styled.div(
  () => `  

  .ranking-list-content-custom-style{
    .ranking-list-header {
      	padding: 0 10px;

		.ranking-list-title {

			background: ${
			JSON.parse(window.localStorage.getItem("theme"))
				? JSON.parse(window.localStorage.getItem("theme")).secondaryColor
				: "#0d489e"
			};  
			padding: 8px 1rem 8px 1rem !important;
			margin-bottom: 5px;
			border-radius: 99px;
			color: ${
			JSON.parse(window.localStorage.getItem("theme"))
				? JSON.parse(window.localStorage.getItem("theme")).textColor
				: "#000000"
			};  
			span {
			color: ${
				JSON.parse(window.localStorage.getItem("theme"))
				? JSON.parse(window.localStorage.getItem("theme")).textColor
				: "black"
			};    
			}
		}
	}

  .user-main-ranking-wrapper {
    background: ${
      JSON.parse(window.localStorage.getItem("theme"))
        ? JSON.parse(window.localStorage.getItem("theme")).secondaryColor
        : "rgb(0, 36, 22)"
    };      
    margin-top: 5px;
    span {
      color: ${
        JSON.parse(window.localStorage.getItem("theme"))
          ? JSON.parse(window.localStorage.getItem("theme")).textColor
          : "#000000"
      };    
      font-weight: bold;
    }
  }

  .user-ranking-wrapper {
    span {

      color: ${
        JSON.parse(window.localStorage.getItem("theme"))
          ? JSON.parse(window.localStorage.getItem("theme")).textColor
          : "#000000"
      };    
      font-weight: bold;
      span {
        color: ${
          JSON.parse(window.localStorage.getItem("theme"))
            ? JSON.parse(window.localStorage.getItem("theme")).textColor
            : "#000000"
        };    
        font-weight: bold;
      }
    }
  }

  .user-main-ranking-wrapper-active {
    background: ${
      JSON.parse(window.localStorage.getItem("theme"))
        ? JSON.parse(window.localStorage.getItem("theme")).secondaryColor
        : "rgb(32, 219, 41)"
    }!important;
    box-shadow: 0 0 0 3px #fff, 
                0 0px 0px 6px ${JSON.parse(window.localStorage.getItem("theme"))
              ? JSON.parse(window.localStorage.getItem("theme")).primaryColor: "rgb(32, 219, 41)"};
  }
}

      `
);
