import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { mediaBreakpoints } from "../../../../assets/publicTheme";
import Container from "@material-ui/core/Container/Container";
import Typography from "@material-ui/core/Typography";
import pxToRem from "../../utils/pxToRem";
import MotivationalPhrases from "../../common/controls/MotivationalPhrases";
import Slide from "@material-ui/core/Slide";
import { useTranslation } from "react-i18next";

const SECONDARY_FONTFAMILY = "Raleway, sans-serif";

const Section1 = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  return (
    <Container maxWidth="xl" disableGutters className={classes.container}>
      <div className={classes.columnLeft}>
        <Slide direction="right" appear in={true} timeout={3000}>
          <Typography className={classes.title}>
            <Typography className={classes}>
              <MotivationalPhrases></MotivationalPhrases>
            </Typography>
          </Typography>
        </Slide>
      </div>
      {/*<Slide direction="left" in={true} appear timeout={2000}>
        <div style={{ color: "#fff" }} className={classes.columnRight}>
          <p style={{ margin: "0" }}>{t("create-message.label")}</p>
        </div>
  </Slide>*/}
    </Container>
  );
};

export default Section1;

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",

    alignItems: "start",
    flexWrap: "wrap",
    flexGrow: 1,
    paddingLeft: pxToRem(24),
    paddingRight: pxToRem(24),
    backgroundColor: "transparent",
    //[mediaBreakpoints.mobile]: { minHeight: pxToRem(260) },
    //[mediaBreakpoints.desktop]: {
    //  minHeight: pxToRem(272),
    //  paddingTop: pxToRem(40),
    //},
  },
  columnLeft: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    [mediaBreakpoints.mobile]: { width: "100%" },
    [mediaBreakpoints.desktop]: { width: "70%", paddingLeft: pxToRem(47) },
  },
  columnRight: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-end",
    flexGrow: 1,
    paddingBottom: pxToRem(24),
    [mediaBreakpoints.mobile]: { width: "100%" },
    [mediaBreakpoints.desktop]: {
      width: "30%",
      paddingRight: pxToRem(36),
      justifyContent: "center",
      minHeight: pxToRem(272),
      paddingLeft: "2em",
    },
  },
  motivational: {
    color: "white",
    letterSpacing: 6,
    fontSize: pxToRem(50),
    fontWeight: "light",
    fontStyle: "bold",
  },
  title: {
    color: "black",
    letterSpacing: 6,
    lineHeight: 1.1,
    [mediaBreakpoints.mobile]: {
      fontSize: pxToRem(40),
      marginBottom: pxToRem(20),
      fontWeight: "bold",
    },
    [mediaBreakpoints.desktop]: {
      fontSize: pxToRem(120),
      marginBottom: pxToRem(20),
      fontWeight: "bold",
    },
  },
  text: {
    color: "white",
    textAlign: "right",
    fontFamily: SECONDARY_FONTFAMILY,
  },
  demoButton: {
    paddingTop: 1,
    paddingBottom: 1,
    paddingRight: 25,
    paddingLeft: 25,
    marginBottom: pxToRem(24),
    borderColor: "#db0fb6",
    color: "#db0fb6",
    borderRadius: 10,
    borderWidth: pxToRem(2),
    lineHeight: 1.3,
    fontWeight: 400,
    fontSize: pxToRem(24),
    letterSpacing: 8,
    "&:hover": {
      backgroundColor: "hsla(311, 87%, 46%, 0.3)",
    },
  },
}));
