import * as pointsCoinsTypes from "../types/pointsCoinsTypes";

import ReportsService from "../components/app/admin/Services/ReportsService";

const {
  RESET_STATE,
  POINTSCOINS_LIST_REQUEST,
  POINTSCOINS_LIST_SAVED,
  POINTSCOINS_LIST_CHARGING,
  POINTSCOINS_LIST_ERROR,
} = pointsCoinsTypes;

export const getPointsCoinsRequest =
  (payload) => async (dispatch, getState) => {
    try {
      const { pages } = getState().pointsCoinsReducer;
      if (!pages.includes(payload.number) || payload.reload) {
        dispatch({
          type: POINTSCOINS_LIST_CHARGING,
        });
        const responseLogin = await ReportsService.getAccountTransaction(
          payload.number,
          5
        );
        dispatch({
          type: POINTSCOINS_LIST_REQUEST,
          payload: {
            ...responseLogin.data,
            number: payload.number,
            filter: "",
          },
        });
      } else {
        dispatch({
          type: POINTSCOINS_LIST_SAVED,
        });
      }
    } catch (error) {
      dispatch({
        type: POINTSCOINS_LIST_ERROR,
        payload: error.response ? error.response.data : error,
      });
    }
  };

export const getPointsCoinsFilteredRequest =
  (payload) => async (dispatch, getState) => {
    try {
      const { pages, filter } = getState().pointsCoinsReducer;
      if (!pages.includes(payload) || filter !== payload.filter) {
        dispatch({
          type: POINTSCOINS_LIST_CHARGING,
        });
        const responseLogin = await ReportsService.filterAccountTransaction(
          payload.number,
          15,
          payload.filter
        );
        dispatch({
          type: POINTSCOINS_LIST_REQUEST,
          payload: {
            ...responseLogin.data,
            number: payload,
            filter: payload.filter,
          },
        });
      } else {
        dispatch({
          type: POINTSCOINS_LIST_SAVED,
        });
      }
    } catch (error) {
      dispatch({
        type: POINTSCOINS_LIST_ERROR,
        payload: error.response ? error.response.data : error,
      });
    }
  };

export const resetState = () => async (dispatch) => {
  dispatch({
    type: RESET_STATE,
  });
};
