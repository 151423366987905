import axios from "axios";
import environment from "../../../../libs/environment";

export default {
  getTests: (number = 1, size = 5) =>
    axios({
      method: "GET",
      url: `${environment.motivarnosBackend}/trivia/compact?_number=${
        number - 1
      }&_size=${size}`,
    }),

  getTest: (id) =>
    axios({
      method: "GET",
      url: `${environment.motivarnosBackend}/trivia/${id}`,
    }),

  getTestsForReset: (number = 1, size = 5, trivia_id = false, user_id = false) =>
    axios({
      method: "GET",
      url: `${
        environment.motivarnosBackend
      }/triviauserscore?${trivia_id !== false ? `trivia.id=${trivia_id}&` : `` }${user_id !== false ? `user.id=${user_id}&` : `` }isCancelled=false&isExpiredBySystem=false&isLast=true&_number=${number - 1}&_size=${size}`,
    }),

  deleteTestForReset: (id) =>
    axios({
      method: "DELETE",
      url: `${environment.motivarnosBackend}/triviauserscore/${id}`,
    }),

  getAllTests: (number = 1, size = 5) =>
    axios({
      method: "GET",
      url: `${environment.motivarnosBackend}/trivia/compact?_number=${
        number - 1
      }&_size=${size}`,
    }),

  saveSkill: (data) =>
    axios({
      method: "POST",
      url: `${environment.motivarnosBackend}/skill`,
      data: data,
    }),

  getSkills: (number = 1, size = 5) =>
    axios({
      method: "GET",
      url: `${environment.motivarnosBackend}/skill?_number=${
        number - 1
      }&_size=${size}`,
    }),

  saveTest: (data) =>
    axios({
      method: "POST",
      url: `${environment.motivarnosBackend}/trivia`,
      data: data,
    }),

  updateTest: (data) =>
    axios({
      method: "PUT",
      url: `${environment.motivarnosBackend}/trivia`,
      data: data,
    }),

  deleteTest: (id) =>
    axios({
      method: "DELETE",
      url: `${environment.motivarnosBackend}/trivia/${id}`,
    }),

  /***********QUESTIONS******** */
  getTestsQuestions: (number = 1, size = 5) =>
    axios({
      method: "GET",
      url: `${environment.motivarnosBackend}/triviaquestion?_number=${
        number - 1
      }&_size=${size}`,
    }),

  downloadQuestions: () =>
    axios({
      method: "GET",
      url: `${environment.motivarnosBackend}/triviaquestion?csv=true`,
    }),

  getTestsAnalitycs: (
    number = 1,
    size = 5,
    triviaId = null,
    userId = null,
    csv = null
  ) =>
    axios({
      method: "GET",
      url: `${environment.motivarnosBackend}/trivia/analyticsanswer?_number=${
        number - 1
      }&_size=${size}${triviaId ? "&triviaId=" + triviaId : ""}${
        userId ? "&userId=" + userId : ""
      }${csv ? "&csv=" + csv : ""}`,
    }),

  getTestsConsolidated: (number = 1, size = 10, triviaId, teamId) =>
    axios.get('/trivia/consolidateanswersreport', 
              {params: {
                          triviaId: triviaId? triviaId: undefined, 
                          teamId:teamId?teamId: undefined, 
                          _number: number - 1, 
                          _size: size}})
    ,

  getTestSkills: (number = 1, size = 5, userId) =>
    axios({
      method: "GET",
      url: `${environment.motivarnosBackend}/skill/${
        "skillbyuserreport?userId=" + userId
      }&_number=${number - 1}&_size=${size}`,
    }),

  downloadSkills: (userId) =>
    axios({
      method: "GET",
      url: `${environment.motivarnosBackend}/skill/${
        "skillbyuserreport?userId=" + userId
      }&csv=true`,
    }),

  getTestsConsolidatedTeam: (number = 1, size = 5, triviaId, teamId) =>
    axios({
      method: "GET",
      url: `${
        environment.motivarnosBackend
      }/trivia/consolidateuseranswersreport?${
        triviaId ? "triviaId=" + triviaId + "&" : ""
      }${teamId ? "teamId=" + teamId + "&" : ""}_number=${
        number - 1
      }&_size=${size}`,
    }),

  saveTestQuestion: (data) =>
    axios({
      method: "POST",
      url: `${environment.motivarnosBackend}/triviaquestion`,
      data: data,
    }),

  updateTestQuestion: (data) =>
    axios({
      method: "PUT",
      url: `${environment.motivarnosBackend}/triviaquestion`,
      data: data,
    }),

  deleteTestQuestion: (id) =>
    axios({
      method: "DELETE",
      url: `${environment.motivarnosBackend}/triviaquestion/${id}`,
    }),
};
