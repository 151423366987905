import React from "react";
import {
  Avatar,
  CardContent,
  Typography,
  Tooltip,
  Paper,
  Grid,
} from "@material-ui/core";
import { ArrowForward } from "@material-ui/icons";
import userAvatarIcon from "../../../../assets/img/user.svg";
import environment from "../../../../libs/environment";
import "./styles.scss";

const BadgeItemCard = ({ badge = {}, senderPlayer, handleOpenDialogComments, lengthBadgeTitle, lengthBadgeDescription }) => {
  const { image, user, name: badgeName } = badge;

  const {
    name,
    lastName,
    image: senderPlayerImage,
    username: senderPlayerUsername,
  } = senderPlayer;

  return (
    <Grid item xs={12} sm={6} md={6} lg={4}>
      <div className="badges-card card-model">
        <div className="badge-container">
          <div className="badges-container-div-icon">
            <img src={image ? `${environment.motivarnosBackend}${image}` : userAvatarIcon} />
          </div>
          <div className="badges-container-div-description">
            <div className="badges-container-div-info">
              <span className="span-quiz div-title-info"> <div></div><p>X{badge.count}</p></span>
            </div>
            <div className="badges-container-div-title">
              {badge.name && badge.name.length > lengthBadgeTitle
                ? badge.name.substring(0, lengthBadgeTitle) + "..."
                : badge.name}
            </div>
            <div className="badges-container-div-subtitle">
              {badge.description ? (
                <>
                  {badge.description && badge.description.length > lengthBadgeDescription
                    ? badge.description.substring(0, lengthBadgeDescription) + "..."
                    : badge.description}
                </>
              ) : (
                ""
              )}
            </div>
            {badge.isDelivered && badge.type === "MANUAL" && (
              <div className="badges-container-div-see-more-coments">
                <button
                  onClick={handleOpenDialogComments}
                  className="button-see-more-comments"
                >
                  VER COMENTARIOS
                </button>
              </div>
            )}
          </div>
        </div>
        <div className="divisor-footer"><div></div></div>
        <CardContent>
          <div className="avatars-delivered">
            <Paper elevation={3} className="user-avatar-container">
              <Tooltip title={`${name} ${lastName}`}>
                <Avatar
                  className="user-avatar-img"
                  alt={`${name} ${lastName}`}
                  src={`${environment.motivarnosBackend}${senderPlayerImage}`}
                  aria-label="user-avatar"
                />
              </Tooltip>

              <Typography variant="body1" color="secondary">
                {senderPlayerUsername}
              </Typography>
            </Paper>

            <Tooltip title="Entregado a">
              <ArrowForward fontSize="large" />
            </Tooltip>

            <Paper elevation={3} className="user-avatar-container">
              <Tooltip title={`${user.name} ${user.lastName}`}>
                <Avatar
                  className="user-avatar-img"
                  alt={user.username}
                  src={
                    user
                      ? `${environment.motivarnosBackend}${user.image}`
                      : userAvatarIcon
                  }
                  aria-label="user-avatar"
                />
              </Tooltip>

              <Typography variant="body1" color="secondary">
                {user.username}
              </Typography>
            </Paper>
          </div>
        </CardContent>
      </div>
    </Grid>
  );
};

export default BadgeItemCard;
