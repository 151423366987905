import {
  Button,
  Container,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Slider,
  TextField,
} from "@material-ui/core";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { buttonStyles } from "../../../../../styles/styles";
import {
  changedLowPointRange,
  postObjective,
  postMetricType,
  changedMediumPointRange,
  changedHighPointRange,
} from "../store/MetricsReducer";

import TimeInput from "../../../../../common/fields/TimeInput";
import { useTranslation } from "react-i18next";

const calculateSeconds = (time = "") => {
  if (time === "") return 0;
  const splited = time.split(":");
  const hours = splited[0];
  const minutes = splited[1];
  const seconds = splited[2];
  const totalSeconds =
    Number.parseInt(hours) * 3600 +
    Number.parseInt(minutes) * 60 +
    Number.parseInt(seconds);
  return totalSeconds;
};

const TimeObjective = (props) => {
  const dispatch = useDispatch();
  const buttonClasses = buttonStyles();

  const [lowLow, setLowLow] = useState(0);
  const [highLow, setHighLow] = useState();
  const [lowLowTime, setLowLowTime] = useState("");
  const [highLowTime, setHighLowTime] = useState("");

  const [lowMedium, setLowMedium] = useState();
  const [highMedium, setHighMedium] = useState();
  const [lowMediumTime, setLowMediumTime] = useState("");
  const [highMediumTime, setHighMediumTime] = useState("");

  const [lowHigh, setLowHigh] = useState();
  const [highHigh, setHighHigh] = useState();

  const [objective, setObjective] = useState(0);

  const [pointsLow, setPointsLow] = useState(0);
  const [pointsMedium, setPointsMedium] = useState(0);
  const [pointsHigh, setPointsHigh] = useState(0);

  const [colorLow, setColorLow] = useState("#e75a4e");
  const [colorMedium, setColorMedium] = useState("#e6e74e");
  const [colorHigh, setColorHigh] = useState("#5ee74e");
  const { t } = useTranslation();

  const dispatchRanges = () => {
    if (
      objective &&
      lowLow &&
      highLow &&
      colorLow &&
      lowMedium &&
      highMedium &&
      colorMedium &&
      lowHigh &&
      highHigh &&
      colorHigh
    ) {
      dispatch(
        postObjective({
          objective: objective,
        })
      );
      dispatch(
        postMetricType({
          metricType: "tiempo",
        })
      );
      dispatch(
        changedLowPointRange({
          from: lowLow,
          to: highLow,
          pointsRange: pointsLow,
          color: colorLow,
        })
      );
      dispatch(
        changedMediumPointRange({
          from: lowMedium,
          to: highMedium,
          pointsRange: pointsMedium,
          color: colorMedium,
        })
      );
      dispatch(
        changedHighPointRange({
          from: lowHigh,
          to: highHigh,
          pointsRange: pointsHigh,
          color: colorHigh,
        })
      );
    }
  };

  return (
    <>
      <Container style={{ display: "flex", flexDirection: "column" }}>
        <Container style={{ display: "flex", flexDirection: "row" }}>
          <h4>{`Objective`}</h4>

          <TimeInput
            time={objective}
            style={{ width: "8%" }}
            onChange={(t) => {
              if (t === "") return;
              setObjective(calculateSeconds(t));
            }}
          ></TimeInput>
        </Container>

        <br></br>
        <Container
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
          }}
        >
          <Container
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            <FormControl style={{ width: "12%", marginRight: "2%" }}>
              <InputLabel id="demo-simple-select-label">Color</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={colorLow}
                onChange={(e) => {
                  setColorLow(e.target.value);
                }}
              >
                <MenuItem value={"#e75a4e"}>Red</MenuItem>
                <MenuItem value={"#e6e74e"}>Yellow</MenuItem>
                <MenuItem value={"#5ee74e"}>Green</MenuItem>
              </Select>
            </FormControl>
            <TimeInput
              style={{ width: "12%" }}
              time={lowLowTime}
              onChange={(t) => {
                if (t === "") return;
                setLowLow(calculateSeconds(t));
                setHighLow(calculateSeconds(t));
                setLowMedium(calculateSeconds(t));

                setLowLowTime(t);
                setHighLowTime(t);
                setLowMediumTime(t);
              }}
            ></TimeInput>
            <Slider
              value={100}
              unselectable={true}
              style={{ width: "20%", color: colorLow, marginRight: "1%" }}
            ></Slider>
            <TimeInput
              style={{ width: "12%" }}
              time={highLowTime}
              onChange={(t) => {
                if (t === "") return;
                setHighLow(calculateSeconds(t));
                setLowMedium(calculateSeconds(t));

                setHighLowTime(t);
                setLowMediumTime(t);
              }}
            ></TimeInput>
            <Container style={{ display: "flex", flexDirection: "row" }}>
              <h4>{`Points`}</h4>
              <TextField
                style={{ width: "8%", marginLeft: "1%" }}
                value={pointsLow}
                onChange={(e) => setPointsLow(e.target.valueAsNumber)}
                type="number"
              ></TextField>
            </Container>
          </Container>
          <Container
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            <FormControl style={{ width: "12%", marginRight: "2%" }}>
              <InputLabel id="demo-simple-select-label">Color</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={colorMedium}
                onChange={(e) => {
                  setColorMedium(e.target.value);
                }}
              >
                <MenuItem value={"#e75a4e"}>Red</MenuItem>
                <MenuItem value={"#e6e74e"}>Yellow</MenuItem>
                <MenuItem value={"#5ee74e"}>Green</MenuItem>
              </Select>
            </FormControl>
            <TimeInput
              style={{ width: "12%" }}
              time={lowMediumTime}
              onChange={(t) => {
                if (t === "") return;
                setLowMedium(calculateSeconds(t));
                setHighMedium(calculateSeconds(t));
                setLowHigh(calculateSeconds(t));

                setLowMediumTime(t);
                setHighMediumTime(t);
              }}
            ></TimeInput>
            <Slider
              value={100}
              unselectable={true}
              style={{ width: "20%", color: colorMedium, marginRight: "1%" }}
            ></Slider>

            <TimeInput
              style={{ width: "12%" }}
              time={highMediumTime}
              onChange={(t) => {
                if (t === "") return;
                setHighMedium(calculateSeconds(t));
                setLowHigh(calculateSeconds(t));
                setHighMediumTime(t);
              }}
            ></TimeInput>
            <Container style={{ display: "flex", flexDirection: "row" }}>
              <h4>{`Points`}</h4>
              <TextField
                style={{ width: "8%", marginLeft: "1%" }}
                value={pointsMedium}
                onChange={(e) => setPointsMedium(e.target.valueAsNumber)}
                type="number"
              ></TextField>
            </Container>
          </Container>
          <Container
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            <FormControl style={{ width: "12%", marginRight: "2%" }}>
              <InputLabel id="demo-simple-select-label">Color</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={colorHigh}
                onChange={(e) => {
                  setColorHigh(e.target.value);
                }}
              >
                <MenuItem value={"#e75a4e"}>Red</MenuItem>
                <MenuItem value={"#e6e74e"}>Yellow</MenuItem>
                <MenuItem value={"#5ee74e"}>Green</MenuItem>
              </Select>
            </FormControl>
            <TimeInput
              style={{ width: "12%" }}
              time={highMediumTime}
              onChange={(t) => {
                if (t === "") return;
                setLowHigh(calculateSeconds(t));
                setHighHigh(calculateSeconds(t));
              }}
            ></TimeInput>
            <Slider
              value={100}
              unselectable={true}
              style={{ width: "20%", color: colorHigh, marginRight: "1%" }}
            ></Slider>

            <TimeInput
              style={{ width: "12%" }}
              time={highMediumTime}
              onChange={(t) => {
                if (t === "") return;
                setHighHigh(calculateSeconds(t));
              }}
            ></TimeInput>
            <Container style={{ display: "flex", flexDirection: "row" }}>
              <h4>{`Points`}</h4>
              <TextField
                style={{ width: "8%", marginLeft: "1%" }}
                value={pointsHigh}
                onChange={(e) => setPointsHigh(e.target.valueAsNumber)}
                type="number"
              ></TextField>
            </Container>
          </Container>
          <Button onClick={dispatchRanges} className={buttonClasses.buttonPink}>
            {t("confirm.label", "Confirm")}
          </Button>
        </Container>
      </Container>
    </>
  );
};

export default TimeObjective;
