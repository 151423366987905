import axios from "axios";
import environment from "../../../../libs/environment";

const CHUNK_SIZE = 1 * 1024 * 100; // 100KB

function getTempName() {
  let session = JSON.parse(localStorage.getItem("sesion"));

  const now = new Date();
  const timestamp =
    now.getFullYear().toString() +
    padZero(now.getMonth() + 1) +
    padZero(now.getDate()) +
    padZero(now.getHours()) +
    padZero(now.getMinutes()) +
    padZero(now.getSeconds());

  return `file_${session.id}_${timestamp}`;
}

function padZero(number) {
  return (number < 10 ? "0" : "") + number;
}

export default {
  /**
   *
   * @param {*} data File data
   * @param {*} folder Folder in storage
   * @param {*} name Optional name of file in storage, if file exists, will be overwritten.
   * @param {*} description description
   * @param {*} id is en filedata, permite actualizar un registro ya creado.
   * @param {*} expirationDate Fecha en la que se borrara el archivo.
   * @param {*} callback: funcion para obtener el porcentaje de avance.
   * @returns
   */
  uploadFile: (data, folder, name, description, expirationDate, id, callback) => {
    let paramstr = "";
    let param = {};
    if (folder) {
      param.folder = folder;
    }

    if (name) {
      param.name = name + new Date().toLocaleDateString();
    }

    if (folder || name) {
      paramstr = Object.entries(param)
        .map(([key, val]) => `${key}=${val}`)
        .join("&");
      if (paramstr) {
        paramstr = `?${paramstr}`;
      }
    }

    const selectedFile = data.get("file");
    const totalChunks = Math.ceil(selectedFile.size / CHUNK_SIZE);

    let chunkID = getTempName();

    return new Promise((resolve, reject) => {
      const sendChunk = (i) => {
        const start = i * CHUNK_SIZE;
        const end = Math.min(start + CHUNK_SIZE, selectedFile.size);
        const chunk = selectedFile.slice(start, end);

        const formData = new FormData();

        formData.append("file", chunk, selectedFile.name);
        formData.append("chunkIndex", i);
        formData.append("totalChunks", totalChunks);
        formData.append("chunkID", chunkID);

        if (i == totalChunks - 1) {
          if (expirationDate) {
            formData.append("expirationDate", expirationDate);
          }
          if (description) {
            formData.append("description", description);
          }
          if (id) {
            formData.append("id", id);
          }
        }
        

        axios({
          method: "POST",
          url: `${environment.motivarnosBackend}/file/uploadchunk${paramstr}`,
          data: formData,
          headers: {
            "content-type": "multipart/form-data",
          },
        })
          .then((response) => {
            if (callback) {
              try {
                let progress = (100.0 * (i + 1)) / totalChunks;
                callback(Number(progress.toFixed(2)));
              } catch (error) {}
            }

            if (i == totalChunks - 1) {
              // resolve al enviar la ultima parte
              resolve(response);
            } else {
              sendChunk(i + 1);
            }
          })
          .catch((error) => {
            reject(error);
          });
      };

      sendChunk(0);
    });
  },

  getTableContent(page, size, folder) {
    return axios.get(
      `/file?isActive=true&_number=${
        page - 1
      }&_size=${size}&doUrl_like=%25${folder}%25`
    );
  },

  delete(id) {
    return axios.delete(`/file/${id}`);
  },

  update(data) {
    return axios.put("/file/", data);
  },
};
