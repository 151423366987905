import axios from "axios";
import environment from "../../../libs/environment";

export default {
  getPrizes: () =>
    axios({
      method: "GET",
      url: `${environment.motivarnosBackend}/giftitem`,
    }),
  getPrizesHistory: () =>
    axios({
      method: "GET",
      url: `${environment.motivarnosBackend}/giftitemuser`,
    }),
  requestPrize: (data) =>
    axios({
      method: "POST",
      url: `${environment.motivarnosBackend}/giftitemuser/request`,
      data: data,
    }),
};
