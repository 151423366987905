import React from "react";
import { withStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import Container from "@material-ui/core/Container";
import Pagination from "@material-ui/lab/Pagination";
import Button from "@material-ui/core/Button";
import toastr from "toastr";
import GeneralFunctions from "../../libs/GeneralFunctions";
import { SpinnerCircular } from "spinners-react";
import TextField from "@material-ui/core/TextField";
import { withTranslation } from "react-i18next";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import InputLabel from "@material-ui/core/InputLabel";
import { format } from "date-fns";
import CloudDownloadIcon from "@material-ui/icons/CloudDownload";
import "toastr/build/toastr.min.css";
import { es } from "date-fns/locale";
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
/********Dialogs** */
import { NotRegistersFound } from "../../common/NotRegistersFound";
import { Link } from "react-router-dom";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
/********Services***** */
import UserServices from "../../Services/UserService";
import TeamService from "../../Services/TeamService";
import MetricService from "../../Services/MetricService";
/* import MetricsReportCreated from "./MetricsReportCreated";*/

const styles = (theme) => ({
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
    
  },
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
  },
  fixedHeight: {
    height: 240,
  },
  root: {
    "& > *": {
      marginTop: theme.spacing(2),
    },
  },
  margin: {
    margin: theme.spacing(1),
  },
  textField: {
    width: "30ch",
    marginTop: "90px",
    marginBottom: "5px",
    marginRight: "1rem",
    marginLeft: "1rem",
  },
/*   Button: {
    width: "33ch",
    height: "5px",
    marginTop: "5.1px",
    marginBottom: "10px",
    marginRight: "1rem",
  }, */
});

class MetricsReportCreated extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      loadingPage: false,
      rows: [],
      rowsCreated: [],
      numberPages: 1,
      page: 1,
      filterDateOne: "",
      filterDateTwo: "",
      created: "",
    };
  }

  componentDidMount() {
    this.getAllDates();
    
  }

  /*********PAGINATION***** */

  handleChange(event, value) {
    this.setState({ page: value });
    var created = false;
    if (this.state.created.length > 0) {
      created = [...this.state.created].join(",");
    } else {
      created = false;
    }
    this.getMetrics2(
      value,
      this.state.filterDateOne,
      this.state.filterDateTwo,
      created
    );

  }
/*   handleChange(event, value) {
    this.setState({ page: value });
    var created = false;
    if (this.state.created.length > 0) {
      created = [...this.state.created].join(",");
    } else {
      created = false;
    }
    this.getMetrics2(
      value,
      this.state.filterDateOne,
      this.state.filterDateTwo,
      created
    );
  } */

  /***********GENERAL FUNCTIONS AND SELECT************* */

/*   downloadMetricReport() {
    this.setState({ loading: true });
    MetricService.downloadMetricReport(this.state.filterDateOne,this.state.filterDateTwo)
      .then((response) => {
        var blob = response.data;
        var link = document.createElement("a");
        link.href = window.URL.createObjectURL(new Blob([blob]));
        link.download = "Reporte metricas impacto.csv";
        link.click();

        this.setState({ loading: false });
      })
  } */

  downloadMetricReport2() {
    this.setState({ loading: true });
    MetricService.downloadMetricReportCreated(this.state.filterDateOne,this.state.filterDateTwo)
      .then((response) => {
        var blob = response.data;
        var link = document.createElement("a");
        link.href = window.URL.createObjectURL(new Blob([blob]));
        link.download = "Reporte metricas proceso.csv";
        link.click();

        this.setState({ loading: false });
      })
  }

  getAllDates() {
    this.setState({ loadingPage: true });
    MetricService.getMetrics()
      .then((response) => {
        this.setState({ rowsCreated: response.data.content });
        this.setState({ loadingPage: false });
      })
      .catch(() => {
       /*  toastr.error(
          this.props.t(
            "menu.trivia-panel-message-error-get-teams",
            "An error occurred while trying to get the dates"
          )
        ); */
        this.setState({ loadingPage: false });
      });
  }

  updateInformationUpdated() {
    this.setState({ viewImportMetrics: false });
    this.getMetrics();
  }

  getMetrics(number = 1, filterDateOne, filterDateTwo, created = false) {
    function createData(
      company_name,
      id,
      created,
      date1,
      metricConf,
      team,
      user,
      value,
      points) {
      return {
        company_name,
      id,
      created,
      date1,
      metricConf,
      team,
      user,
      value,
      points
      };
    }
    this.setState({ loading: true });
    MetricService.getMetricsReport(number, 10, filterDateOne, filterDateTwo, created)
      .then((response) => {
        this.setState({ rows: [] });
        let rowLogins = [];
        response.data.content.forEach((metricReport) =>
          rowLogins.push(createData( metricReport.company_name,
            metricReport.id,
            metricReport.created,
            metricReport.date1,
            metricReport.metricConf,
            metricReport.team,
            metricReport.user,
            metricReport.value,
            metricReport.points))
        );
        this.setState({ rows: rowLogins });
        this.setState({ numberPages: response.data.totalPages });
        this.setState({ loading: false });
      })
      .catch(() => {
       /*  toastr.error(
          this.props.t(
            "admin.ranking-message-error-get-login-by-day",
            "An error occurred obtaining the logins by day"
          )
        ); */
        this.setState({ loading: false });
      });
  }

  getMetrics2(number = 1, filterDateOne, filterDateTwo, created = false) {
    function createData(
      company_name,
      id,
      created,
      date1,
      metricConf,
      team,
      user,
      value,
      points) {
      return {
        company_name,
      id,
      created,
      date1,
      metricConf,
      team,
      user,
      value,
      points
      };
    }
    this.setState({ loading: true });
    MetricService.getMetricsReportCreated(number, 10, filterDateOne, filterDateTwo, created)
      .then((response) => {
        this.setState({ rows: [] });
        let rowLogins = [];
        response.data.content.forEach((metricReport) =>
          rowLogins.push(createData( metricReport.company_name,
            metricReport.id,
            metricReport.created,
            metricReport.date1,
            metricReport.metricConf,
            metricReport.team,
            metricReport.user,
            metricReport.value,
            metricReport.points))
        );
        this.setState({ rows: rowLogins });
        this.setState({ numberPages: response.data.totalPages });
        this.setState({ loading: false });
       
      })
      .catch(() => {
       /*  toastr.error(
          this.props.t(
            "admin.ranking-message-error-get-login-by-day",
            "An error occurred obtaining the logins by day"
          )
        ); */
        this.setState({ loading: false });
      });
  }

  async getReportMetrics(type, filter = "") {
    if (type === "filterDateOne") {
      await this.setState({ filterDateOne: filter });
    } else if (type === "filterDateTwo") {
      await this.setState({ filterDateTwo: filter });
    } else if (type === "created") {
      await this.setState({ created: filter });
    } else {
      await this.setState((prevState) => ({ ...prevState, created: filter }));
    }
    var created = false;
    if (this.state.created.length > 0) {
      created = [...this.state.created].join(",");
    } else {
      created = false;
    }

    this.setState({ page: 1 });

    if (this.state.filterDateOne && this.state.filterDateTwo) {
      this.getMetrics(
        1,
        this.state.filterDateOne,
        this.state.filterDateTwo,
        created
      );
    }
  }
  
  async getReportMetrics2(type, filter = "") {
    if (type === "filterDateOne") {
      await this.setState({ filterDateOne: filter });
    } else if (type === "filterDateTwo") {
      await this.setState({ filterDateTwo: filter });
    } else if (type === "created") {
      await this.setState({ created: filter });
    } else {
      await this.setState((prevState) => ({ ...prevState, created: filter }));
    }
    var created = false;
    if (this.state.created.length > 0) {
      created = [...this.state.created].join(",");
    } else {
      created = false;
    }

    this.setState({ page: 1 });

    if (this.state.filterDateOne && this.state.filterDateTwo) {
      this.getMetrics2(
        1,
        this.state.filterDateOne,
        this.state.filterDateTwo,
        created
      );
    }
  }
  /***********CRUD FUNCTIONS************* */

  render() {
    const {
      loading,
      loadingPage,
      created,
      rows,
      rowsCreated,
      numberPages,
      page,
      filterDateOne,
      filterDateTwo,
    } = this.state;
    const { classes, t, i18n } = this.props;

    return (
      
      <main className={classes.content}>
      
        <Container maxWidth="lg">
        <br></br>
        <br></br>
              
          {loadingPage && (
            <div className="div-spinner">
              <Grid container spacing={1}>
                <SpinnerCircular size={90} />
              </Grid>
            </div>
          )}

          {!loadingPage && (
            <>
            <br></br>

              <Grid container spacing={1}>
                <>
                <Link to="/metrics/panel">
                    <Button
                      className="button-table mr-1 mt-2"
                      variant="contained"
                      color="secondary"
                    >
                      <ArrowBackIosIcon className="mr-1" />
                      {t("back.label", "Back")}
                    </Button>
                  </Link>


                  <TextField
                    id="outlined-basic"
                    variant="outlined"
                    value={filterDateOne}
                    type="date"
                    name="filter"
                    className={classes.textField}
                    onChange={(e) =>
                      this.getReportMetrics("filterDateOne", e.target.value)
                    }
                  />

                  <TextField
                    id="outlined-basic"
                    variant="outlined"
                    value={filterDateTwo}
                    type="date"
                    name="filter"
                    className={classes.textField}
                    onChange={(e) =>
                      this.getReportMetrics("filterDateTwo", e.target.value)
                    }
                  />

                  

                  {rowsCreated && rowsCreated.length > 0 && (
                    <FormControl
                      variant="outlined"
                      className={classes.textField}
                    >
                    
                     {  <InputLabel >
                     {t("select-procces-date.label", "Date of procces")}
                      </InputLabel> }

                       <Select
                        labelId="fechas"
                        id="date"
                        value={created}
                        onChange={(e) =>
                          this.getReportMetrics("date", e.target.value)
                        }
                        label={t("select-impact-date.label", "Date of impact")}
                      >
                      
                          <Link to="/metrics/report"><MenuItem value={"impacto"}>
                          {t("select-impact-date.label", "Date of impact")}
                          </MenuItem></Link>

                          <MenuItem value={"proceso"}>
                          {t("select-procces-date.label", "Date of procces")}
                          </MenuItem>
                    
                      </Select> 
                    </FormControl>
                  )}
                </>                
              </Grid>

              {/***************************TABLE ONE***************************** */}

              {!loading ? (
                rows && rows.length > 0 ? (
                  <Grid container spacing={1} className="mt-2">
                    <TableContainer id="impacto" component={Paper}>
                    <Table className={classes.table} aria-label="simple table">
                    <TableHead>
                      <TableRow>
                        <TableCell align="left">
                          {t("user.label", "User")}
                        </TableCell>
                        <TableCell align="left">
                          {t("team.label", "Team")}
                        </TableCell>
                        <TableCell align="left">
                          {t("menu.metric-panel-title", "Metric")}
                        </TableCell>
                        <TableCell align="left">
                          {t(
                            "menu.metric-panel-dialog-metric-imported-value",
                            "Imported value"
                          )}
                        </TableCell>
                        <TableCell align="left">
                          {t(
                            "menu.metric-panel-dialog-metric-points-earned",
                            "Points earned"
                          )}
                        </TableCell>
                        <TableCell align="left">
                          {t(
                            "menu.metric-panel-dialog-metric-process-date",
                            "Process. date"
                          )}
                        </TableCell>
                        <TableCell align="left">
                          {t(
                            "menu.metric-panel-dialog-metric-impact-date",
                            "Impact date"
                          )}
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {rows.map((row, index) => (
                        <TableRow key={index}>
                          <TableCell align="left">
                            {row.user.username}
                          </TableCell>
                          <TableCell align="left">{row.team.name}</TableCell>
                          <TableCell align="left">
                            {row.metricConf.name}
                          </TableCell>
                          <TableCell align="left">{row.value}</TableCell>
                          <TableCell align="left">{row.points}</TableCell>
                          <TableCell align="left">
                            {row.created
                              ? GeneralFunctions.formatNewDate(
                                  row.created,
                                  i18n.language === "es" ? es : ""
                                )
                              : "N/A"}
                          </TableCell>
                          <TableCell align="left">
                            {row.date1
                              ? GeneralFunctions.formatNewDate(
                                  row.date1,
                                  i18n.language === "es" ? es : ""
                                )
                              : "N/A"}
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                    <div className={classes.root}>
                    
                  <Pagination
                    count={numberPages}
                    page={page}
                    color="primary"
                    onChange={(event, value) => this.handleChange(event, value)}
                  />
                  
                 
                  <Button 
                  className="button-table mr-1"
                  variant="contained"
                  color="primary"
                  onClick={() => this.downloadMetricReport2()}
                >
                <CloudDownloadIcon className="mr-1" />
                  {t("Descargar")} 
                  </Button>


              
                </div>
                  </Table>
                    </TableContainer>

              {/*  <div className={classes.root}>
                      <Pagination
                        className="pagination-custom-margin"
                        count={numberPages}
                        page={page}
                        color="primary"
                        onChange={(event, value) =>
                          this.handleChange(event, value)
                        }
                      />
                      *  onChange={(event, value) => this.handleChange(event, value)}
                    </div> */}
                  </Grid>
                ) : (
                  <>
                    <>
                      <Grid item xs={12} md={12} lg={12}>
                        <NotRegistersFound
                          title={t(
                            "menu.trivia-panel-dialog-test-consolidated-table-not-registers",
                            "No records for trivia"
                          )}
                        />
                      </Grid>
                    </>
                  </>
                )
              ) : (
                <div className="div-spinner-consolidated">
                  <Grid container spacing={1}>
                    <SpinnerCircular size={90} />
                  </Grid>
                </div>
              )}
            </>
          )}
        </Container>
      </main>
    );
  }
}

export default withTranslation()(
  withStyles(styles, { withTheme: true })(MetricsReportCreated)
);
