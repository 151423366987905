import React from 'react';
import { Button } from '@material-ui/core';
import { FormattedMessage } from "react-intl";
import {buttonStyles} from "../../../styles/styles"

const DriveTemplateButton = (props) => {
    const classes = buttonStyles();
    return <Button
                variant="contained"
                className={classes.buttonPink}
                style={props.style}
                onClick={() => {window.open('https://docs.google.com/spreadsheets/d/1m5m4EDeY12MUUc5v5sJVsDzBHrRNoyLzBZj1s8mMgjQ/edit?usp=sharing')}}
              >
                <FormattedMessage
                  id="importCSV.copyFromDrive"
                  defaultMessage="Copy Template From Drive"
                />
            </Button>
  }
export default DriveTemplateButton;
