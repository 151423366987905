import * as newsTypes from "../types/newsTypes";

const {
  RESET_STATE,
  RESET_STORE,
  NEWS_LIST_REQUEST,
  NEWS_LIST_SAVED,
  NEWS_LIST_SAVE,
  NEWS_LIST_UPDATE,
  NEWS_LIST_DELETE,
  NEWS_LIST_CHARGING,
  NEWS_SAVE_CHARGING,
  NEWS_LIST_ERROR,
} = newsTypes;

const INITIAL_STATE = {
  news: [],
  error_news: false,
  news_charging: false,
  news_save_charging: false,
  totalElements: 0,
  totalPages: 1,
  pages: [],
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case NEWS_LIST_REQUEST:
      return {
        ...state,
        news_charging: false,
        news: [...state.pages].includes(action.payload.number)
          ? [...state.news]
          : [...state.news, ...action.payload.content],
        totalElements: action.payload.totalElements,
        totalPages: action.payload.totalPages,
        pages: [...state.pages].includes(action.payload.number)
          ? [...state.pages]
          : [...state.pages, action.payload.number],
        error_news: false,
      };

    case NEWS_LIST_SAVE:
      return {
        ...state,
        news: action.payload,
        error_news: false,
        news_save_charging: false,
      };
    case NEWS_LIST_UPDATE:
      return {
        ...state,
        news: action.payload,
        error_news: false,
        news_save_charging: false,
      };
    case NEWS_LIST_DELETE:
      return {
        ...state,
        news: [...state.news].filter((newR) => newR.id !== action.payload),
        news_charging: false,
        totalElements: state.totalElements - 1,
        error_news: false,
      };

    case NEWS_LIST_CHARGING:
      return { ...state, news_charging: true, error_news: false };

    case NEWS_SAVE_CHARGING:
      return { ...state, news_save_charging: true, error_news: false };

    case NEWS_LIST_ERROR:
      return {
        ...state,
        error_news: action.payload,
        news_charging: false,
        news_save_charging: false,
      };

    case NEWS_LIST_SAVED:
      return { ...state, news_charging: false, error_news: false };

    case RESET_STATE:
      return {
        ...state,
        error_news: false,
        news_charging: false,
        news_save_charging: false,
      };

    case RESET_STORE:
      return {
        ...state,
        news: [],
        error_news: false,
        news_charging: false,
        news_save_charging: false,
        totalElements: 0,
        totalPages: 1,
        pages: [],
      };

    default:
      return state;
  }
};
