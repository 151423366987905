import axios from "axios";
import environment from "../../../../libs/environment";

export default {
  completedTriviaByTeamReport: (number = 1, size = 5) =>
    axios({
      method: "GET",
      url: `${
        environment.motivarnosBackend
      }/trivia/completedtriviabyteamreport?_number=${number - 1}&_size=${size}`,
    }),

  skillByTeamReport: (number = 1, size = 5) =>
    axios({
      method: "GET",
      url: `${environment.motivarnosBackend}/skill/skillbyteamreport?_number=${
        number - 1
      }&_size=${size}`,
    }),

  badgeCountByTeam: (number = 1, size = 5) =>
    axios({
      method: "GET",
      url: `${
        environment.motivarnosBackend
      }/insignia/insigniacountbyteam?_number=${number - 1}&_size=${size}`,
    }),

  completedMissionByTeam: (number = 1, size = 5) =>
    axios({
      method: "GET",
      url: `${
        environment.motivarnosBackend
      }/mission/completedmissionbyteam?_number=${number - 1}&_size=${size}`,
    }),

  accountRankingByTeam: (number = 1, size = 5) =>
    axios({
      method: "GET",
      url: `${
        environment.motivarnosBackend
      }/account/accountrankingbyteam?_number=${number - 1}&_size=${size}`,
    }),

  tournamentCompact: (number = 1, size = 5) =>
    axios({
      method: "GET",
      url: `${environment.motivarnosBackend}/tournament/compact?_number=${
        number - 1
      }&_size=${size}`,
    }),

  challengeByTeam: (number = 1, size = 5) =>
    axios({
      method: "GET",
      url: `${
        environment.motivarnosBackend
      }/challenge/challengebyteam?_number=${number - 1}&_size=${size}`,
    }),

  giftItemByTeam: (number = 1, size = 5) =>
    axios({
      method: "GET",
      url: `${environment.motivarnosBackend}/giftitem/giftitembyteam?_number=${
        number - 1
      }&_size=${size}`,
    }),
};
