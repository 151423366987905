import React from "react";
import HeaderLanding from "./HeaderLanding";
import { ThemeProvider, makeStyles } from "@material-ui/core/styles";
import PublicTheme from "../../../../assets/publicTheme";
import Container from "@material-ui/core/Container/Container";
import CssBaseline from "@material-ui/core/CssBaseline";
import Section1 from "./Section1";
import DevelopedBy from "./DevelopedBy";
import { useDispatch, useSelector } from "react-redux";
import { exposedLandingReducer, closeSignUp } from "./store/LandingReducer";
import useAsyncReducer from "../../useAsyncReducer";
import SignupLanding from "../auth/SignupLanding";
import SignInSignUp from "../auth/SignInSignUp";
import MotivationalPhrases from "../../common/controls/MotivationalPhrases";
import BotForm from "../../../BotForm";

const useReduxSelector = () =>
  useSelector((state) => ({
    openSignUp: state.landingReducer?.openSignUp,
    openLogin: state.landingReducer?.openLogin,
  }));

const Landing = () => {
  useAsyncReducer([exposedLandingReducer]);
  const { openSignUp } = useReduxSelector();
  const classes = useStyles();
  const dispatch = useDispatch();
  return (
    <ThemeProvider theme={PublicTheme}>
      <CssBaseline />
      <SignupLanding
        open={openSignUp}
        onClose={() => dispatch(closeSignUp())}
      ></SignupLanding>
      <HeaderLanding />
      <MotivationalPhrases/>
      <Container maxWidth="xl" disableGutters className={classes.rootContainer}>
        <SignInSignUp padding={20} width={'50%'} />
        {/*<Section1 />*/}
        <DevelopedBy />
      </Container>
        <div 
          style={{
            zIndex: "30",
            bottom: "30px",
            right: "30px",
            position: "fixed",
          }}
        >
        <BotForm from="landing" type="no-custom"/>
      </div>
    </ThemeProvider>
  );
};

export default Landing;

const useStyles = makeStyles((theme) => ({
  rootContainer: {
    flexGrow: 1,
    backgroundPosition: "center center",
    backgroundSize: "cover",
    overflowX: "hidden",
  },
}));
