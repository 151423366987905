import axios from "axios";
import environment from "../../../../libs/environment";

export default {
  getBadges: (number = 1, size = 5) =>
    axios({
      method: "GET",
      url: `${environment.motivarnosBackend}/insignia?_number=${
        number - 1
      }&_size=${size}&isActive=true&`,
    }),

  getBadgesOrdered: (number = 1, size = 5, isAdmin = false) =>
    axios({
      method: "GET",
      url: `${environment.motivarnosBackend}/insignia?${isAdmin ? 'isActive=true' : `_number=${ number - 1 }&isAllowPlayerAssignation=true`}`
    }),
    
  getMyDeliveredBadges: () =>
    axios({
      method: "GET",
      url: `${environment.motivarnosBackend}/insignia/user?isDelivered=true&createdBy=own`,
    }),
  
  getDeliveredBadges: () =>
    axios({
      method: "GET",
      url: `${environment.motivarnosBackend}/insignia/user?isDelivered=true`,
    }),

  downloadDeliveredBadges: () =>
    axios({
      method: "GET",
      url: `${environment.motivarnosBackend}/insignia/user?isDelivered=true&csv=true`,
    }),

  getReportBadges: (number = 1, size = 5) =>
    axios({
      method: "GET",
      url: `${environment.motivarnosBackend}/insignia/user?_number=${
        number - 1
      }&_size=${size}`,
    }),
  
  getMyAssignedBadges: (number = 1, size = 5) =>
    axios({
      method: "GET",
      url: `${environment.motivarnosBackend}/insignia/own?isActive=true&isDelivered=true&_number=${
        number - 1
      }&_size=${size}`,
    }),
  
    getAssignedBadges: (number = 1, size = 5) =>
      axios({
        method: "GET",
        url: `${environment.motivarnosBackend}/insignia/own?_number=${
          number - 1
        }&_size=${size}`,
      }),

  getBadgeComments: (badgeId, userId) =>
    axios({
      method: "GET",
      url: `${environment.motivarnosBackend}/insigniauser?insignia.id=${badgeId}&user.id=${userId}&type=MANUAL`,
    }),

  getAssignedBadgesAdmin: (number = 1, size = 5) =>
    axios({
      method: "GET",
      url: `${environment.motivarnosBackend}/insignia?_number=${
        number - 1
      }&isActive=true&_size=${size}`,
    }),

  saveBadge: (data) =>
    axios({
      method: "POST",
      url: `${environment.motivarnosBackend}/insignia`,
      data: data,
    }),

  deliverBadge: (data) =>
    axios({
      method: "POST",
      url: `${environment.motivarnosBackend}/insignia/deliver`,
      data: data,
    }),

  updateBadge: (data) =>
    axios({
      method: "PUT",
      url: `${environment.motivarnosBackend}/insignia`,
      data: data,
    }),

  saveImageBadge: (data) =>
    axios({
      method: "POST",
      url: `${environment.motivarnosBackend}/file/upload`,
      data: data,
      headers: {
        "content-type": "multipart/form-data",
      },
    }),

  deleteBadge: (id) =>
    axios({
      method: "DELETE",
      url: `${environment.motivarnosBackend}/insignia/${id}`,
    }),
};
