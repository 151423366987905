import React, { useState, createContext } from "react";

export const LocaleContext = createContext();

export const LocaleContextProvider = (props) => {
  const [locale, setLocale] = useState("en");
  return (
    <LocaleContext.Provider value={[locale, setLocale]}>
      {props.children}
    </LocaleContext.Provider>
  );
};
