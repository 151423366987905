import React from 'react';
import { Button } from '@material-ui/core';
import { FormattedMessage } from "react-intl";
import {buttonStyles} from "../../../styles/styles";

const DownloadTemplate = (props) => {
    const classes = buttonStyles();
    return <Button
                variant="contained"
                className={classes.buttonPink}
                style={props.style}
                href="/assets/UserCSV-EN.csv"
              >
                <FormattedMessage
                id="importCSV.downloadTemplate"
                defaultMessage="Download Template"
                />
            </Button>
  }
export default DownloadTemplate;
