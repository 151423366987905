import React, { useState } from "react";

import TextField from "@material-ui/core/TextField/TextField";
import { FormattedMessage } from "react-intl";
import { useTranslation } from "react-i18next";

const ValidateUserName = (userName) => userName && /[\w.\-_]+$/.test(userName);
const UserName = (props) => {
  const [userName, setUserName] = useState(props.username);
  const { t } = useTranslation();

  const changeUserName = (username) => {
    setUserName(username);
    if (props.onChange) {
      props.onChange(username);
    }
  };

  return (
    <TextField
      required
      label={
        <FormattedMessage
          id="landing.userLabel"
          defaultMessage={t("user.label", "User")}
        />
      }
      helperText={
        props.specificationsUserName ? (
          <FormattedMessage
            id={props.specificationsUserName}
            defaultMessage=""
          ></FormattedMessage>
        ) : (
          ""
        )
      }
      error={userName && !ValidateUserName(userName)}
      value={userName}
      variant="outlined"
      className={props.className}
      onChange={(e) => changeUserName(e.target.value)}
      style={{ marginBottom: 12 }}
    />
  );
};

export default UserName;
