import axios from "axios";
import environment from "../../../../libs/environment";

export default {
  getNews: (number = 1, size = 5) =>
    axios({
      method: "GET",
      url: `${environment.motivarnosBackend}/news?_number=${
        number - 1
      }&_size=${size}`,
    }),

  saveNew: (data) =>
    axios({
      method: "POST",
      url: `${environment.motivarnosBackend}/news`,
      data: data,
    }),

  updateNew: (data) =>
    axios({
      method: "PUT",
      url: `${environment.motivarnosBackend}/news`,
      data: data,
    }),

  deleteNew: (id) =>
    axios({
      method: "DELETE",
      url: `${environment.motivarnosBackend}/news/${id}`,
    }),

  getNewsReaded: (number = 1, size = 5) =>
    axios({
      method: "GET",
      url: `${environment.motivarnosBackend}/newsuser?_number=${
        number - 1
      }&_size=${size}`,
    }),

  downloadNewsRead: () =>
    axios({
      method: "GET",
      url: `${environment.motivarnosBackend}/newsuser?csv=true`,
    }),
};
