export const RESET_STATE = "RESET_STATE";
export const RESET_STORE = "RESET_STORE";

export const MISSIONS_LIST_REQUEST = "MISSIONS_LIST_REQUEST";
export const MISSIONS_LIST_SAVED = "MISSIONS_LIST_SAVED";

export const MISSIONS_LIST_SAVE = "MISSIONS_LIST_SAVE";
export const MISSIONS_LIST_UPDATE = "MISSIONS_LIST_UPDATE";
export const MISSIONS_LIST_DELETE = "MISSIONS_LIST_DELETE";

export const MISSIONS_LIST_CHARGING = "MISSIONS_LIST_CHARGING";
export const MISSIONS_SAVE_CHARGING = "MISSIONS_SAVE_CHARGING";

export const MISSIONS_LIST_ERROR = "MISSIONS_LIST_ERROR";
