import axios from "axios";
import environment from "../../../../libs/environment";

export default {
  getTheme: (number = 1, size = 5) =>
    axios({
      method: "GET",
      url: `${environment.motivarnosBackend}/company?_number=${
        number - 1
      }&_size=${size}`,
    }),

  getThemePublic: () =>
    axios({
      method: "GET",
      url: `${environment.motivarnosBackend}/theme`,
    }),

  updateTheme: (data) =>
    axios({
      method: "PUT",
      url: `${environment.motivarnosBackend}/company`,
      data: data,
    }),

  getMenuLinks: () =>
    axios({
      method: "GET",
      url: `${environment.motivarnosBackend}/menu/edit`,
    }),

  updateLinks: (data) =>
    axios({
      method: "PUT",
      url: `${environment.motivarnosBackend}/menu/edit`,
      data: data,
    }),

  saveImageLogo: (data) =>
    axios({
      method: "POST",
      url: `${environment.motivarnosBackend}/file/upload`,
      data: data,
      headers: {
        "content-type": "multipart/form-data",
      },
    }),
};
