import * as betsTypes from "../types/betsTypes";

const {
  RESET_STATE,
  RESET_STORE,
  BETS_LIST_REQUEST,
  BETS_LIST_SAVED,
  BETS_LIST_CHARGING,
  BETS_LIST_ERROR,
} = betsTypes;

const INITIAL_STATE = {
  bets: [],
  error_bets: false,
  bets_charging: false,
  bets_save_charging: false,
  totalElements: 0,
  totalPages: 1,
  pages: [],
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case BETS_LIST_REQUEST:
      return {
        ...state,
        bets_charging: false,
        bets: [...state.pages].includes(action.payload.number)
          ? [...state.bets]
          : [...state.bets, ...action.payload.content],
        totalElements: action.payload.totalElements,
        totalPages: action.payload.totalPages,
        pages: [...state.pages].includes(action.payload.number)
          ? [...state.pages]
          : [...state.pages, action.payload.number],
        error_bets: false,
      };

    case BETS_LIST_CHARGING:
      return { ...state, bets_charging: true, error_bets: false };

    case BETS_LIST_ERROR:
      return {
        ...state,
        error_bets: action.payload,
        bets_charging: false,
        bets_save_charging: false,
      };

    case BETS_LIST_SAVED:
      return { ...state, bets_charging: false, error_bets: false };

    case RESET_STATE:
      return {
        ...state,
        error_bets: false,
        bets_charging: false,
        bets_save_charging: false,
      };

    case RESET_STORE:
      return {
        ...state,
        bets: [],
        error_bets: false,
        bets_charging: false,
        bets_save_charging: false,
        totalElements: 0,
        totalPages: 1,
        pages: [],
      };

    default:
      return state;
  }
};
