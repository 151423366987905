import axios from "axios";
export default {
  getAwards: (number = 1, size = 5) =>
    axios({
      method: "GET",
      url: `/giftitem?_number=${number - 1}&_size=${size}`,
    }),

  getAllPendingAwards: (number = 1, size = 5) =>
    axios({
      method: "GET",
      url: `/giftitemuser?_number=${
        number - 1
      }&_size=${size}&status=IN_PROGRESS`,
    }),

  getAwardsAssigned: (number = 1, size = 5) =>
    axios({
      method: "GET",
      url: `/giftitemuser?_number=${number - 1}&_size=${size}`,
    }),

  updateGiftAward: (data) =>
    axios({
      method: "PUT",
      url: `/giftitemuser`,
      data: data,
    }),

  saveAward: (data) =>
    axios({
      method: "POST",
      url: `/giftitem`,
      data: data,
    }),

  acceptAward: (data) =>
    axios({
      method: "POST",
      url: `/giftitemuser/accept`,
      data: data,
    }),

  rejectAward: (data) =>
    axios({
      method: "POST",
      url: `/giftitemuser/reject`,
      data: data,
    }),

  updateAward: (data) =>
    axios({
      method: "PUT",
      url: `/giftitem`,
      data: data,
    }),

  deleteAward: (id) =>
    axios({
      method: "DELETE",
      url: `/giftitem/${id}`,
    }),

  assignAward: (data) =>
    axios({
      method: "POST",
      url: `/giftitemuser/giveaway`,
      data: data,
    }),
};
