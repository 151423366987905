import React from "react";
import { connect } from "react-redux";
import Dasboard from "../../../../Pages/Dashboard";

const Inicio = (props) => {
  const name = props.sesionData.session.user.name;
  const lastName = props.sesionData.session.user.lastName;
  /* dev log */

  return (
    <React.Fragment>
      <Dasboard name={name} lastName={lastName} />
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  /* dev log */
  return {
    sesionData: state.authReducer,
  };
};

export default connect(mapStateToProps)(Inicio);
