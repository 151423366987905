import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import Pagination from "@material-ui/lab/Pagination";
import { SpinnerCircular } from "spinners-react";
import Grid from "@material-ui/core/Grid";
import toastr from "toastr";
import { useTranslation } from "react-i18next";

import UserService from "../../Services/UserService";

import "../../scss/TableDashboard.scss";

const useStyles = makeStyles((theme) => ({
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    height: "100vh",
    overflow: "auto",
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
  },
  root: {
    "& > *": {
      marginTop: theme.spacing(2),
    },
  },
}));

export default function TableRanking() {
  const classes = useStyles();

  const [loading, setLoading] = useState(true);
  const [rows, setRows] = useState([]);
  const [numberPages, setNumberPages] = useState(1);
  const [page, setPage] = useState(1);
  const { t } = useTranslation();

  useEffect(() => {
    getServices();
  }, []);

  const handleChange = (event, value) => {
    setPage(value);
    getServices(value);
  };

  const getServices = (page = 1) => {
    function createData(
      company_name,
      company_Id,
      id,
      coinBalance,
      lockedCoinBalance,
      pointBalance,
      lockedPointBalance,
      user,
      createdBy,
      updatedBy,
      updated,
      created,
      isActive,
      position
    ) {
      return {
        company_name,
        company_Id,
        id,
        coinBalance,
        lockedCoinBalance,
        pointBalance,
        lockedPointBalance,
        user,
        createdBy,
        updatedBy,
        updated,
        created,
        isActive,
        position,
      };
    }

    setLoading(true);
    UserService.getRanking(page, 5)
      .then((response) => {
        setRows([]);
        let rowRanking = [];
        if (response.data.content && response.data.content.length > 0) {
          response.data.content.forEach((ranking) =>
            rowRanking.push(
              createData(
                ranking.company_name,
                ranking.company_Id,
                ranking.id,
                ranking.coinBalance,
                ranking.lockedCoinBalance,
                ranking.pointBalance,
                ranking.lockedPointBalance,
                ranking.user,
                ranking.createdBy,
                ranking.updatedBy,
                ranking.updated,
                ranking.created,
                ranking.isActive,
                ranking.position
              )
            )
          );
        }
        setRows(rowRanking);
        setNumberPages(response.data.totalPages);
        setLoading(false);
      })
      .catch(() => {
        toastr.error(
          t(
            "admin.ranking-message-error-get-ranking",
            "An error occurred obtaining the ranking"
          )
        );
        setLoading(false);
      });
  };

  return (
    <>
      {loading && (
        <div className="div-spinner">
          <Grid container spacing={1}>
            <SpinnerCircular size={90} />
          </Grid>
        </div>
      )}
      {!loading && (
        <>
          <h4 className="titleTable">
            {t("total-points-month.label", "Points of the month")}
          </h4>
          <TableContainer component={Paper}>
            <Table className={classes.table} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell align="left">{t("name.label", "Name")}</TableCell>
                  <TableCell align="left">{t("user.label", "User")}</TableCell>
                  <TableCell align="left">
                    {t("points.label", "Points")}
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {rows.map((row, index) => (
                  <TableRow key={index}>
                    <TableCell align="left">
                      {row.position && (
                        <span className="span-custom-position">
                          {row.position}
                        </span>
                      )}{" "}
                      {row.user.name + " " + row.user.lastName}
                    </TableCell>
                    <TableCell align="left">{row.user.username}</TableCell>
                    <TableCell align="left">
                      {row.pointBalance + " " + t("points.label", "Points")}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>

          <Pagination
            onChange={(event, value) => handleChange(event, value)}
            count={numberPages}
            page={page}
            color="primary"
          />
        </>
      )}
    </>
  );
}
