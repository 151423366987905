import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Redirect } from "react-router-dom";
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { Container, Dialog, DialogActions, DialogContent, Slide } from '@material-ui/core';
import DialogTitle from '../../../../common/controls/DialogTitle';
import { buttonStyles } from "../../../../styles/styles"
import DownloadTemplate from './DownloadTemplate';
import ImportFile from './ImportFile';
import {getUserList} from '../userPanel/store/UserPanelReducer'
import FinishFlow from './FinishFlow';
import { useDispatch, useSelector } from 'react-redux';

const useReduxSelector = () =>
  useSelector((state) => ({
    session: state.authReducer.session
  }));

const useStyles = makeStyles((theme) => ({
  stepper: {
    display:"flex",
    flexDirection:"row",
    justifyContent:"center",
    width:"100%"
  },
  button: {
    marginRight: theme.spacing(1),
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
}));

function getSteps() {
  return ['Download user template', 'Import user template', 'Finish and continue'];
}

function getStep(step) {
  switch (step) {
    case 0:
      return <DownloadTemplate></DownloadTemplate>;
    case 1:
      return <ImportFile></ImportFile>;
    case 2:
      return <FinishFlow></FinishFlow>;
    default:
      return 'Unknown step';
  }
}

function getStepContent(step, classes) {
  return  <Typography className={classes.instructions}>{
            getStep(step)
        }</Typography>

}
const UserStepper = (props) => {
    const dispatch =useDispatch();
    const {session} = useReduxSelector();
    const classes = useStyles();
    const buttonClasses = buttonStyles();
    const [activeStep, setActiveStep] = useState(0);
    const steps = getSteps();
    const [open, setOpen] = useState(props.open);
    const [redirect, setRedirect] = useState("");

    const handleNext = () => {
      setActiveStep((prevActiveStep) => prevActiveStep<steps.length? prevActiveStep + 1:prevActiveStep);
    };
    const handlePrevious = () => {
      setActiveStep((prevActiveStep) => prevActiveStep > 0 ? prevActiveStep - 1:prevActiveStep);
    };
    return (
      <>
        {redirect!=="" ? <Redirect to={redirect}></Redirect>:null}
        <Dialog open={open} fullScreen={true}>
            <DialogTitle onClose={() => setRedirect("/admin")}>
                <Container>
                    <Stepper activeStep={activeStep}>
                        {steps.map((label, index) => {
                        const stepProps = {};
                        const labelProps = {};
                        return (
                            <Step key={label} {...stepProps}>
                            <StepLabel {...labelProps}>{label}</StepLabel>
                            </Step>
                        );
                        })}
                    </Stepper>
                </Container>    
            </DialogTitle>
          <DialogContent>{
            getStepContent(activeStep, classes)
          }
          </DialogContent>  
          <DialogActions>
            <Container style={{display:"flex", flexDirection:"row", justifyContent:"space-between"}}>
                <Button 
                        onClick={()=>{setRedirect("/userPanel")}}
                        className={buttonClasses.buttonRed}>
                    Skip
                </Button>
                <Button 
                        onClick={handlePrevious}
                        className={buttonClasses.buttonPink}>
                    Previous
                </Button>
                <Button
                    onClick={activeStep<steps.length-1? handleNext: () => {dispatch(getUserList({
                      token:session.token
                    }));setRedirect("/userPanel");}}
                    className={buttonClasses.buttonPurple}
                >
                    {activeStep >= 2  ? 'Finish' : 'Next'}
                </Button>
            </Container>
          </DialogActions>    
        </Dialog>
      </>
    );
  }
export default UserStepper;