import * as awardsTypes from "../types/awardsTypes";

const {
  RESET_STATE,
  RESET_STORE,
  AWARDS_LIST_REQUEST,
  AWARDS_LIST_SAVED,
  AWARDS_LIST_SAVE,
  AWARDS_LIST_UPDATE,
  AWARDS_LIST_DELETE,
  AWARDS_LIST_CHARGING,
  AWARDS_SAVE_CHARGING,
  AWARDS_LIST_ERROR,
} = awardsTypes;

const INITIAL_STATE = {
  awards: [],
  error_awards: false,
  awards_charging: false,
  awards_save_charging: false,
  totalElements: 0,
  totalPages: 1,
  pages: [],
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case AWARDS_LIST_REQUEST:
      return {
        ...state,
        awards_charging: false,
        awards: [...state.pages].includes(action.payload.number)
          ? [...state.awards]
          : [...state.awards, ...action.payload.content],
        totalElements: action.payload.totalElements,
        totalPages: action.payload.totalPages,
        pages: [...state.pages].includes(action.payload.number)
          ? [...state.pages]
          : [...state.pages, action.payload.number],
        error_awards: false,
      };

    case AWARDS_LIST_SAVE:
      return {
        ...state,
        awards: action.payload,
        error_awards: false,
        awards_save_charging: false,
      };
    case AWARDS_LIST_UPDATE:
      return {
        ...state,
        awards: action.payload,
        error_awards: false,
        awards_save_charging: false,
      };
    case AWARDS_LIST_DELETE:
      return {
        ...state,
        awards: [...state.awards].filter(
          (award) => award.id !== action.payload
        ),
        awards_charging: false,
        totalElements: state.totalElements - 1,
        error_awards: false,
      };

    case AWARDS_LIST_CHARGING:
      return { ...state, awards_charging: true, error_awards: false };

    case AWARDS_SAVE_CHARGING:
      return { ...state, awards_save_charging: true, error_awards: false };

    case AWARDS_LIST_ERROR:
      return {
        ...state,
        error_awards: action.payload,
        awards_charging: false,
        awards_save_charging: false,
      };

    case AWARDS_LIST_SAVED:
      return { ...state, awards_charging: false, error_awards: false };

    case RESET_STATE:
      return {
        ...state,
        error_awards: false,
        awards_charging: false,
        awards_save_charging: false,
      };

    case RESET_STORE:
      return {
        ...state,
        awards: [],
        error_awards: false,
        awards_charging: false,
        awards_save_charging: false,
        totalElements: 0,
        totalPages: 1,
        pages: [],
      };

    default:
      return state;
  }
};
