import axios from "axios";
import environment from "../../../libs/environment";

export default {
  GetMetricRanking: () =>
    axios({
      method: "GET",
      url: `${environment.motivarnosBackend}/metricconf/user`,
    }),
  GetMainRanking: () =>
    axios({
      method: "GET",
      url: `${environment.motivarnosBackend}/account/ranking`,
    }),

  GetHistoricalRanking: (fromDate = null, toDate = null) =>
    axios({
      method: "GET",
      url: `${environment.motivarnosBackend}/account/ranking?${fromDate != null ? 'fromDate=' + fromDate + 'T00:00:00' + '&' : ''}${toDate != null ? 'toDate=' + toDate + 'T00:00:00' + '&' : ''}historical=true`,
    }),

  GetBadgeRanking: () =>
    axios({
      method: "GET",
      url: `${environment.motivarnosBackend}/insignia/ranking?historical=true&_size=999`,
    }),
  GetMainRankingAwarCategory: () => axios.get("/account/rankingPorCategoria"),

  GetHistoricalRankingAwarCategory: (fromDate = null, toDate = null) => axios.get("/account/rankingPorCategoria",{
    params : {
      fromDate: fromDate,
      toDate: toDate,
      historical: true
    }
  }),
};
