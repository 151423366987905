import axios from "axios";
import environment from "../../../../libs/environment";

export default {
  getMetrics: (number = 1, size = 5) =>
    axios({
      method: "GET",
      url: `${environment.motivarnosBackend}/metricconf?_number=${
        number - 1
      }&_size=${size}`,
    }),

  getMetricsNotFilter: (number = 1, size = 5) =>
    axios({
      method: "GET",
      url: `${environment.motivarnosBackend}/metricconf/nofilter?_number=${
        number - 1
      }&_size=${size}`,
    }),

  getImports: (number = 1, size = 4) =>
    axios({
      method: "GET",
      url: `${environment.motivarnosBackend}/file?_number=${number}&_size=${size}&doUrl_like=%25import_metric%25&createdBy=own`,
    }),

  getMetricsReport: (number = 1, size = 5, date, date1) =>
    axios({
      method: "GET",
      url: `${environment.motivarnosBackend}/metricdata?_sort=date1_desc&date1_ge=${date}&date1_le=${date1}T23:59:59&_number=${number - 1}&_size=${size}`,
    }),
    /* /metricdata?_sort=date1_desc&date1_ge=2021-01-05T00:00:00&date1_le=2022-06-05T23:59:59&_number=0&_size=5 */

  getMetricsReportCreated: (number = 1, size = 5, created, created1) =>
    axios({
      method: "GET",
      url: `${environment.motivarnosBackend}/metricdata?_sort=created_desc&created_ge=${created}&created_le=${created1}T23:59:59&_number=${number - 1}&_size=${size}`,
    }),

  downloadMetricReport: (date, date1) =>
    axios({
      method: "GET",
      url: `${environment.motivarnosBackend}/metricdata?_sort=date1_desc&date1_ge=${date}&date1_le=${date1}&csv=true`,
    }),

  downloadMetricReportCreated: (created, created1) =>
    axios({
      method: "GET",
      url: `${environment.motivarnosBackend}/metricdata?_sort=created_desc&created_ge=${created}&created_le=${created1}T23:59:59&csv=true`,
    }),

  getMetricsRejectedReport: (number = 1, size = 5) =>
    axios({
      method: "GET",
      url: `${environment.motivarnosBackend}/importmetricdata?_number=${
        number - 1
      }&_size=${size}`,
    }),

  filterMetrics: (number = 1, size = 5, filter) =>
    axios({
      method: "GET",
      url: `${environment.motivarnosBackend}/metricconf?_number=${
        number - 1
      }&_size=${size}&name_like=${filter}`,
    }),

  filterMetricsReport: (number = 1, size = 5, filter) =>
    axios({
      method: "GET",
      url: `${environment.motivarnosBackend}/metricconf/userreport?_number=${
        number - 1
      }&_size=${size}&user.username_like=${filter}`,
    }),

  saveMetric: (data) =>
    axios({
      method: "POST",
      url: `${environment.motivarnosBackend}/metricconf`,
      data: data,
    }),

  updateMetric: (data) =>
    axios({
      method: "PUT",
      url: `${environment.motivarnosBackend}/metricconf`,
      data: data,
    }),

  deleteMetric: (id) =>
    axios({
      method: "DELETE",
      url: `${environment.motivarnosBackend}/metricconf/${id}`,
    }),
};
