import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { getWeek, format, subDays, startOfWeek } from 'date-fns';

import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableRow from "@material-ui/core/TableRow";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import Paper from "@material-ui/core/Paper";

import PieChartIcon from '@material-ui/icons/PieChart';
import PeopleIcon from '@material-ui/icons/People';
import SettingsIcon from '@material-ui/icons/Settings';
import AssignmentIndIcon from '@material-ui/icons/AssignmentInd';

import { SpinnerCircular } from "spinners-react";

import CoachingServices from "../../Services/Coaching/Player";

import "./styles.scss";

const Metric = (props) => {
  const [metrics, setMetrics] = useState(null);
  const [page, setPage] = useState(0);
  const [totalElements, setTotalElements] = useState(null);
  const [loading, setLoading] = useState(false);
  const rowPerPage = 3;

  const { t } = useTranslation();

  useEffect(() => {
    getMetrics(page);
  }, []);

  function changePage(event, value) {
    setPage(value);
    getMetrics(value);
  }

  function getMetrics(number = 0) {
    let id = props.user_id;

    setLoading(true);
    
    if(props.title == 'ONE ON ONE'){
      CoachingServices.GetDashboardMetricsOneOnOne({
        id,
        secondaryTeams: false,
        number: number,
        size: rowPerPage
      })
      .then((resp) => {
        setMetrics(resp.data.content)
        setTotalElements(resp.data.totalElements)
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
    } else if(props.title == 'PDS') {
      CoachingServices.GetDashboardMetricsPDS({
        id,
        secondaryTeams: false,
        number: number,
        size: rowPerPage
      })
      .then((resp) => {
        setMetrics(resp.data.content)
        setTotalElements(resp.data.totalElements)
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
    } else if(props.title == 'PIP') {
      CoachingServices.GetDashboardMetricsPIP({
        id,
        secondaryTeams: false,
        number: number,
        size: rowPerPage
      })
      .then((resp) => {
        setMetrics(resp.data.content)
        setTotalElements(resp.data.totalElements)
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
    } else if(props.title == 'RESUME') {
      CoachingServices.getDashboardResumeMetrics(number, rowPerPage)
      .then((resp) => {
        setMetrics(resp.data.content)
        setTotalElements(resp.data.totalElements)
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
    }
  }

  function getWeekCountBack(index) {
    const count = getWeek(subDays(startOfWeek(new Date(), { weekStartsOn: 1 }), 7 * index)) - 1;

    if (count === 1) {
      return `(W${1} - ${format(
        subDays(startOfWeek(new Date(), { weekStartsOn: 1 }), 7 * index),
        'dd/MM/yyyy'
      )})`;
    }

    if (count === 51) {
      return `(W${52} - ${format(
        subDays(startOfWeek(new Date(), { weekStartsOn: 1 }), 7 * index),
        'dd/MM/yyyy'
      )})`;
    }

    if (count < 1) {
      return `(W${1} - ${format(
        subDays(startOfWeek(new Date(), { weekStartsOn: 1 }), 7 * index),
        'dd/MM/yyyy'
      )})`;
    }

    return `(W${count} - ${format(
      subDays(startOfWeek(new Date(), { weekStartsOn: 1 }), 7 * index),
      'dd/MM/yyyy'
    )})`;
  }

  return (
    <div className="card-model card-coaching-metric">
      {(metrics == null || loading) ? (
        <div className="spiner-container">
         <SpinnerCircular/>
        </div>
      ) : (
        <>
          <div className="table-detail-coaching-metric-title">
            <div className="table-detail-coaching-metric-title-icon">
              {props.title == 'RESUME' && (
                <PieChartIcon />
              )}
              {props.title == 'ONE ON ONE' && (
                <PeopleIcon />
              )}
              {props.title == 'PDS' && (
                <SettingsIcon />
              )}
              {props.title == 'PIP' && (
                <AssignmentIndIcon />
              )}
            </div>
            <div className="table-detail-coaching-metric-title-text">
              {props.title != 'RESUME' ? props.title : t("resume.label", "RESUMEN")}
            </div>
          </div>
          <TableContainer component={Paper} className="mt-2 table-detail-coaching-metric-container">
            <Table className="table-detail-coaching-metric-container-table" aria-label="custom pagination table">
              <TableHead>
                <TableRow>
                  <TableCell align="left">
                    NOMBRE
                  </TableCell>
                  <TableCell align="left" className="align-content-center">
                    DATO INCOG.
                  </TableCell>
                  <TableCell align="left" className="align-content-center">
                    FEEDBACK
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
              {metrics.map((row, index) => (
                <TableRow>
                  <TableCell align="left">
                    {getWeekCountBack(index)}
                  </TableCell>
                  <TableCell align="left" className="align-content-center">
                    {row.sended}
                  </TableCell>
                  <TableCell align="left" className="align-content-center">
                    {row.acknowleged}
                  </TableCell>
                </TableRow>
              ))}
              </TableBody>
            </Table>
            <TablePagination
              className="mt-1"
              rowsPerPageOptions={[rowPerPage]}
              component="div"
              count={totalElements}
              rowsPerPage={rowPerPage}
              page={page}
              onPageChange={(event, value) =>
                changePage(event, value)
              }
            />
          </TableContainer>
        </>
      )}
    </div>
  );
};

const Coaching = () => {
  const [sesion, setSesion] = useState(null);

  useEffect(() => {
    let sesion = JSON.parse(localStorage.getItem("sesion"));
    
    setSesion(sesion);
  }, []);

  if (!sesion) return "";

  return (
    <div className="coaching-wrapper-metric">
      <div className="coaching-wrapper-metric-title">COACHING</div>
      <div className="coaching-wrapper-metric-content">
        <Metric
          user_id={sesion.user.id}
          title={"RESUME"}
        />
        <Metric
          user_id={sesion.user.id}
          title={"ONE ON ONE"}
        />
        <Metric
          user_id={sesion.user.id}
          title={"PDS"}
        />
        <Metric
          user_id={sesion.user.id}
          title={"PIP"}
        />
      </div>
    </div>
  );
};

export default Coaching;
