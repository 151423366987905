import React from "react";
import { styled } from "@material-ui/core/styles";
import { Box, Button, IconButton } from '@material-ui/core';
import PhotoCamera from "@material-ui/icons/PhotoCamera";

const Input = styled('input')({
  display: 'none',
});

export default function UploadButton({label, children, uploadFile, onUpload}) {
  return (
    <Box sx={{width:'100%', display:'flex', justifyContent:'center'}}>
      <label htmlFor="contained-button-file">
        <Input accept="image/*" id="contained-button-file" type="file" 
            onChange={onUpload}
        />
        <Button size="large" fullWidth color="primary" aria-label="upload picture" 
          variant="contained" component="span" endIcon={<PhotoCamera />}
        >
          {
            label ? label:
              children
          }
        </Button>
      </label>
    </Box>
  );
}