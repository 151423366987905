import axios from "axios";
import environment from "../../../libs/environment";

export default {
  getChallenges: () =>
    axios({
      method: "GET",
      url: `${environment.motivarnosBackend}/challengeuser`,
    }),
  AcceptChallenges: (data) =>
    axios({
      method: "POST",
      url: `${environment.motivarnosBackend}/challengeuser/accept`,
      data: data,
    }),
  RejectChallenges: (data) =>
    axios({
      method: "POST",
      url: `${environment.motivarnosBackend}/challengeuser/reject`,
      data: data,
    }),
};
