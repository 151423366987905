import * as letterSoupTypes from "../types/letterSoupTypes";

const {
  RESET_STATE,
  RESET_STORE,
  LETTERSOUP_LIST_REQUEST,
  LETTERSOUP_LIST_SAVED,
  LETTERSOUP_LIST_SAVE,
  LETTERSOUP_LIST_UPDATE,
  LETTERSOUP_LIST_DELETE,
  LETTERSOUP_LIST_CHARGING,
  LETTERSOUP_SAVE_CHARGING,
  LETTERSOUP_LIST_ERROR,
} = letterSoupTypes;

const INITIAL_STATE = {
  lettersoup: [],
  error_lettersoup: false,
  lettersoup_charging: false,
  lettersoup_save_charging: false,
  totalElements: 0,
  totalPages: 1,
  pages: [],
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case LETTERSOUP_LIST_REQUEST:
      return {
        ...state,
        lettersoup_charging: false,
        lettersoup: [...state.pages].includes(action.payload.number)
          ? [...state.lettersoup]
          : [...state.lettersoup, ...action.payload.content],
        totalElements: action.payload.totalElements,
        totalPages: action.payload.totalPages,
        pages: [...state.pages].includes(action.payload.number)
          ? [...state.pages]
          : [...state.pages, action.payload.number],
        error_lettersoup: false,
      };

    case LETTERSOUP_LIST_SAVE:
      return {
        ...state,
        lettersoup: action.payload,
        error_lettersoup: false,
        lettersoup_save_charging: false,
      };
    case LETTERSOUP_LIST_UPDATE:
      return {
        ...state,
        lettersoup: action.payload,
        error_lettersoup: false,
        lettersoup_save_charging: false,
      };
    case LETTERSOUP_LIST_DELETE:
      return {
        ...state,
        lettersoup: [...state.lettersoup].filter((test) => test.id !== action.payload),
        lettersoup_charging: false,
        totalElements: state.totalElements - 1,
        error_lettersoup: false,
      };

    case LETTERSOUP_LIST_CHARGING:
      return { ...state, lettersoup_charging: true, error_lettersoup: false };

    case LETTERSOUP_SAVE_CHARGING:
      return { ...state, lettersoup_save_charging: true, error_lettersoup: false };

    case LETTERSOUP_LIST_ERROR:
      return {
        ...state,
        error_lettersoup: action.payload,
        lettersoup_charging: false,
        lettersoup_save_charging: false,
      };

    case LETTERSOUP_LIST_SAVED:
      return { ...state, lettersoup_charging: false, error_lettersoup: false };

    case RESET_STATE:
      return {
        ...state,
        error_lettersoup: false,
        lettersoup_charging: false,
        lettersoup_save_charging: false,
      };

    case RESET_STORE:
      return {
        ...state,
        lettersoup: [],
        error_lettersoup: false,
        lettersoup_charging: false,
        lettersoup_save_charging: false,
        totalElements: 0,
        totalPages: 1,
        pages: [],
      };

    default:
      return state;
  }
};
