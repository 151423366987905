import React, { useState } from "react";

import TextField from "@material-ui/core/TextField/TextField";
import { FormattedMessage } from "react-intl";
import { useTranslation } from "react-i18next";

const ValidatePassword = (password) => password && password.length >= 8;

const Password = (props) => {
  const [password, setPassword] = useState(props.password);

  const changePassword = (password) => {
    setPassword(password);
    if (props.onChange) {
      props.onChange(password);
    }
  };
  const { t } = useTranslation();

  return (
    <TextField
      required
      helperText={
        props.specificationsPassword ? (
          <FormattedMessage
            id={props.specificationsPassword}
            defaultMessage=""
          ></FormattedMessage>
        ) : (
          ""
        )
      }
      label={
        <FormattedMessage
          id="landing.passwordLabel"
          defaultMessage={t("password.label", "Password")}
        />
      }
      type="password"
      error={password && !ValidatePassword(password)}
      value={password}
      className={props.className}
      variant="outlined"
      onChange={(e) => changePassword(e.target.value)}
      style={{ marginBottom: 12 }}
    />
  );
};

export default Password;
