import React, { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import { SpinnerCircular } from "spinners-react";
import { useTranslation } from "react-i18next";
import { format } from "date-fns";

import Button from "../../components/Button";
import Grid from "@material-ui/core/Grid";
import trivia_logo from "../../assets/img/trivia-logo.png";
import novedad_logo from "../../assets/img/novedad-logo.png";
import SportsEsportsIcon from '@material-ui/icons/SportsEsports';
import ImageIcon from '@material-ui/icons/Image';
import AssignmentIcon from '@material-ui/icons/Assignment';
import ShowImageDialog from "../../components/app/admin/common/Dialogs/ShowImage";
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import PlayArrowIcon from '@material-ui/icons/PlayArrow';
import PlayCircleOutlineIcon from '@material-ui/icons/PlayCircleOutline';
import AccessAlarmsIcon from '@material-ui/icons/AccessAlarms';
import SummaryDialog from "../common/SummaryDialog";
import ilustracion_gato from "../../assets/img/ilustracion-gato.png";
import AnnouncementIcon from '@material-ui/icons/Announcement';
import FolderSpecialIcon from '@material-ui/icons/FolderSpecial';
import NotificationImportantIcon from "@material-ui/icons/NotificationImportant";
import { getTime } from "../../libs/utils";

import QuizzesServices from "../../Services/Quizzes/Global";

import EmptyMessage from "../../components/EmptyMessage";
import environment from "../../libs/environment";

import "./styles.scss";
import "toastr/build/toastr.min.css";

const QuizCart = (props) => {
  const [openModal, setOpenModal] = useState(false);
  const [imageModal, setImageModal] = useState(null);
  const [videoModal, setVideoModal] = useState(null);
  


  const openModalFunction = (open, image, isVideo = false) => {
    setOpenModal(open);
    setVideoModal(isVideo);
    if (open) {
      setImageModal(image);
    } else {
      setImageModal(null);
    }
  };

  return (
    <div className="card-model card-activity-trivia">
      <Grid xs={2} xl={2} className="card-activity-image-container">  
        {props.data.image ? (
          <>
          {/*<img
            src={`${environment.motivarnosBackend}${props.data.image}`}
            alt="Imagen de la trivia"
            onClick={() => openModalFunction(true, props.data.image) }
          />*/}
          <div 
            className="card-activity-trivia-image-div"
            style={{backgroundImage:  `url("${environment.motivarnosBackend}${props.data.image}")`}}
            onClick={() => openModalFunction(true, props.data.image)}
          ></div>
          </>
        ) : (
          <>
            {props.data.mediaLink != '' && (
              <div className="frame-image" style={{background: "url('"+ novedad_logo+ "')"}}  onClick={() => openModalFunction(true, props.data.mediaLink, true)}>
                <PlayCircleOutlineIcon/>
              </div>
            )}
            {props.data.mediaLink == '' && (
              <img
                src={trivia_logo}
                style={{width: '120px'}}
                alt="Trivia"
                onClick={() => openModalFunction(true, 'trivia_logo') }
              />
            )}
          </>
        )}
      </Grid>
      <Grid xs={12} md={7} lg={7} className="card-activity-info-container">
        <div className="info-container-type">
          {/*(props.data.image == '' || props.data.image == null) && (props.data.mediaLink == '' || props.data.mediaLink == null) && <span className="span-mission">GENERAL</span>*/}
          {/*(props.data.image != '' && props.data.image != null) && (props.data.mediaLink == '' || props.data.mediaLink == null) && <span className="span-new">IMAGEN</span>*/}
          {/*(props.data.image == '' || props.data.image == null) && (props.data.mediaLink != '' && props.data.mediaLink != null) && <span className="span-quiz">VIDEO</span>*/}
        </div>
        <div className="info-container-title">
          {props.title.charAt(0).toUpperCase() + props.title.slice(1)}
        </div>
        <div className="info-container-subtitle">
            {(props.description != null)  && <>{props.description.charAt(0).toUpperCase() + props.description.slice(1)}</>}
        </div>
        <div className="info-container-state">
          <ErrorOutlineIcon style={{fill: 'rgb(158, 158, 158)', width: '20px', height: '20px'}}/>{`${(props.finalizo? 'Finalizó ': 'Finaliza ')} el ${format(new Date(props.date), "dd-MM-yyyy")}`}
          {/*props.secondary && props.data.timeInSec != 0 && <><AccessAlarmsIcon style={{fill: 'rgb(158, 158, 158)', width: '20px', height: '20px', marginLeft: '10px'}}/>Tiempo: {props.data.timeInSec} segundos</>*/}
          {props.secondary == false && props.data.triviaAnswerTimeStr != null && <><AccessAlarmsIcon style={{fill: 'rgb(158, 158, 158)', width: '20px', height: '20px', marginLeft: '10px'}}/>{getTime(props.data.triviaAnswerTimeStr)}</>}
          {props.secondary ? 
            <>
            <NotificationImportantIcon style={{fill: 'rgb(158, 158, 158)', width: '20px', height: '20px', marginLeft: '10px'}}/>
            Pendiente
            </> : <>
            <FolderSpecialIcon style={{fill: 'rgb(158, 158, 158)', width: '20px', height: '20px', marginLeft: '10px'}}/>
            Realizado
            </>
            }
        </div>
      </Grid>
      <Grid  xs={12} md={3} lg={3} className="card-activity-button-container">
        {props.secondary && !props.finalizo ? (
          <NavLink to={"/quizzes/quiz?id=" + props.id}>
            <Button className="button-activity">JUGAR TRIVIA <SportsEsportsIcon style={{fill: 'white', marginLeft: '8px'}}/></Button>
          </NavLink>
        ) : (
          <>
          {/*<NavLink to={"/quizzes/quiz?id=" + props.id}>
            <Button className="button-activity">JUGAR TRIVIA <SportsEsportsIcon style={{fill: 'white', marginLeft: '8px'}}/></Button>
          </NavLink>*/}
          <SummaryDialog data={props.data}/>
          </>
        )}
      </Grid>
      {openModal && (
        <ShowImageDialog
          openModal={openModal}
          imageModal={imageModal}
          videoModal={videoModal}
          openModalFunction={(open) => openModalFunction(open)}
        />
      )}
    </div>
  );
};

QuizCart.defaultProps = {
  title: "",
  name: "",
  badge: null,
  btnText: "",
};

const Quizzes = () => {
  const { t } = useTranslation();
  const [quizzes, setQuizzes] = useState(null);
  const [lastQuizzes, setLastQuizzes] = useState(null);
  const [sesion, setSesion] = useState(null);

  useEffect(() => {
    let sesion = JSON.parse(localStorage.getItem("sesion"));

    QuizzesServices.findAll()
      .then((resp) => {
        const lastQuizzes = [];
        resp.data.content.forEach((elem, index) => {
          if (elem.isEnableForPlay && index < 3) { 
            lastQuizzes.push(elem);
          }
        })

        setLastQuizzes(lastQuizzes);
        setQuizzes(resp.data.content);
      })
      .catch(() => { });
    
      QuizzesServices.getUser(sesion.user.id)
      .then((resp) => {
        sesion = { ...sesion, user: resp.data };
        setSesion(sesion);
      })
      .catch(() => { });

    setSesion(sesion);

  }, []);

  function replaceAll(string, search, replace) {
    if (string === null) return "";
    return string.split(search).join(replace).split(" ")[0];
  }
  
  if (!sesion) return "";

  return (
    <div className="trivia-wrapper-trivias">
      {sesion.company.company_Id != 5 && lastQuizzes && lastQuizzes.length > 0 && <>
        <div className="last-trivias">
            {lastQuizzes.map((elem, index) => {
              let backgroundImage = '';
              let className = '';
              if(index == 0) {
                backgroundImage = 'linear-gradient(135deg, #0A4AC7, #4BA8CA)';
                className = 'button-activity-first';
              } else if(index == 1) {
                backgroundImage = 'linear-gradient(135deg, #CA4E84, #CE4443)';
                className = 'button-activity-second';
              } else {
                backgroundImage = 'linear-gradient(135deg, #9A08CB, #BC8ACE)';
                className = 'button-activity-third';
              }
              const styles = {
                marginRight: `${index < 2 ? '5%' : ''}`,
                backgroundImage: backgroundImage
              }
              return (<div className="last-trivias-item" style={styles}>
                {elem.image == '' && elem.mediaLink == '' && <AssignmentIcon className="slideshow"/>}
                {elem.image != '' && elem.mediaLink == '' && <ImageIcon className="slideshow"/>}
                {elem.image == '' && elem.mediaLink != '' && <PlayArrowIcon className="slideshow"/>}

                <p className="last-trivias-item-title">{elem.triviaName}</p>
                <p className="last-trivias-item-subtitle">Responde la trivia y gana puntos al instante</p>
                <div className="last-trivias-item-button">
                  <NavLink to={"/quizzes/quiz?id=" + elem.id}>
                    <Button className={"button-activity" + " " + className}>JUGAR <SportsEsportsIcon style={{fill: '#0A5DFF', marginLeft: '8px'}}/></Button>
                  </NavLink>
                </div>
              </div>);
            }
            )}
        </div>
      </>}
      <div className="trivia-title" >TRIVIAS</div>
      <div className="quizzes-content">
      {quizzes === null ? (
        <span className="spinner-container">
          <SpinnerCircular />
        </span>
      ) : (

        quizzes.map((data, index) => {
          let regex = /T(.)*/;
          let date = replaceAll(data.dateTo, "-", "/").replace(regex, "");
          return (
            <QuizCart
              key={index}
              badge={data.badge}
              title={data.triviaName}
              description={data.triviaDescription}
              btnText={
                !data.isEnableForPlay
                  ? t("play.label", "Play")
                  : t("summary.label", "Summary")
              }
              secondary={data.isEnableForPlay}
              id={data.id}
              time={parseInt(data.timeInSec)}
              points={data.minApprovalPoints}
              date={date}
              data={data}
              score={data.userScoreId}
              finalizo={new Date(new Date().toDateString()) >= new Date(date)}
            />
          );
        })
      )}
      </div>
      {quizzes !== null ? quizzes.length === 0 && (sesion.company.company_Id == 5 ? 
        <div className="card-model empty-message-ilustracion">

        <div>
          <img src={ilustracion_gato} alt="" />
        </div>
        <h4>Actualmente, no hay trivias para responder</h4>
        </div>

      : <EmptyMessage />) : ""}
    </div>
  );
};

export default Quizzes;
