import React from "react";
import { es } from "date-fns/locale";
import { withStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import Container from "@material-ui/core/Container";
import Button from "@material-ui/core/Button";
import Pagination from "@material-ui/lab/Pagination";
import toastr from "toastr";
import { SpinnerCircular } from "spinners-react";
import CloudDownloadIcon from "@material-ui/icons/CloudDownload";
import { withTranslation } from "react-i18next";
import GeneralFunctions from "../../libs/GeneralFunctions";
import { Link } from "react-router-dom";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";

import "toastr/build/toastr.min.css";
/********Dialogs***** */

import { NotRegistersFound } from "../../common/NotRegistersFound";

/*****Componentes */

/********Services***** */
import NewService from "../../Services/NewService";
/*********Icons********* */
import ViewListIcon from "@material-ui/icons/ViewList";

const styles = (theme) => ({
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
  },
  fixedHeight: {
    height: 240,
  },
  root: {
    "& > *": {
      marginTop: theme.spacing(2),
    },
  },
  margin: {
    margin: theme.spacing(1),
  },
  textField: {
    width: "50ch",
    marginBottom: "5px",
  },
});

class Readed extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      rows: [],
      numberPages: 1,
      page: 1,
    };
  }

  componentDidMount() {
    this.getNewsReaded();
  }

  handleChange(event, value) {
    this.setState({ page: value });
    this.getNewsReaded(value);
  }

  /***********GENERAL FUNCTIONS************* */

  getNewsReaded(number = 1) {
    function createData(
      company_name,
      company_Id,
      updated_session,
      created_session,
      externalId,
      externalInfo,
      id,
      user,
      news,
      createdBy,
      updatedBy,
      updated,
      created,
      isActive
    ) {
      return {
        company_name,
        company_Id,
        updated_session,
        created_session,
        externalId,
        externalInfo,
        id,
        user,
        news,
        createdBy,
        updatedBy,
        updated,
        created,
        isActive,
      };
    }
    this.setState({ loading: true });
    NewService.getNewsReaded(number)
      .then((response) => {
        this.setState({ rows: [] });
        let rows = [];
        response.data.content.forEach((news) =>
          rows.push(
            createData(
              news.company_name,
              news.company_Id,
              news.updated_session,
              news.created_session,
              news.externalId,
              news.externalInfo,
              news.id,
              news.user,
              news.news,
              news.createdBy,
              news.updatedBy,
              news.updated,
              news.created,
              news.isActive
            )
          )
        );
        this.setState({ rows: rows });
        this.setState({ numberPages: response.data.totalPages });
        this.setState({ loading: false });
      })
      .catch(() => {
        toastr.error(
          this.props.t(
            "menu.new-panel-message-error-notifications",
            "An error occurred while trying to get the News"
          )
        );
        this.setState({ loading: false });
      });
  }

  downloadNewsRead() {
    this.setState({ loading: true });
    NewService.downloadNewsRead()
      .then((response) => {
        var blob = response.data;
        var link = document.createElement("a");
        link.href = window.URL.createObjectURL(new Blob([blob]));
        link.download = "NewsRead.csv";
        link.click();

        this.setState({ loading: false });
      })
      .catch(() => {
        toastr.error(
          this.props.t(
            "admin.user-panel-message-download-notifications",
            "An error occurred while trying to download notifications"
          )
        );
        this.setState({ loading: false });
      });
  }

  render() {
    const { loading, rows, numberPages, page } = this.state;
    const { classes, t, i18n } = this.props;

    return (
      <main className={classes.content}>
        <div className={classes.appBarSpacer} />
        <Container maxWidth="lg" className={classes.container}>
          <Grid container spacing={1}>
            <Link to="/news">
              <Button
                className="button-table mr-1"
                variant="contained"
                color="secondary"
              >
                <ArrowBackIosIcon className="mr-1" />
                {t(
                  "menu.trivia-panel-dialog-test-question-button-back",
                  "Back"
                )}
              </Button>
            </Link>

            <Button
              className="button-table mr-1"
              variant="contained"
              color="secondary"
            >
              <ViewListIcon className="mr-1" />
              {t("news-readed.label", "News read")}
            </Button>

            <Button
              className="button-table ml-1 mr-1"
              variant="contained"
              color="primary"
              onClick={() => this.downloadNewsRead()}
            >
              <CloudDownloadIcon className="mr-1" />
              {t("menu.feelings-download-csv", "Download CSV")}
            </Button>
          </Grid>
          {loading && (
            <div className="div-spinner">
              <Grid container spacing={1}>
                <SpinnerCircular size={90} />
              </Grid>
            </div>
          )}

          {!loading &&
            (rows && rows.length > 0 ? (
              <Grid container spacing={1}>
                <TableContainer component={Paper}>
                  <Table className={classes.table} aria-label="simple table">
                    <TableHead>
                      <TableRow>
                        <TableCell align="left">
                          {t("novelty-read.label", "Novelty read")}
                        </TableCell>
                        <TableCell align="left">
                          {t("user.label", "User")}
                        </TableCell>
                        <TableCell align="left">
                          {t("readed.label", "Readed")}
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {rows.map((row) => (
                        <TableRow key={row.id}>
                          <TableCell align="left">{row.news.title}</TableCell>
                          <TableCell align="left">
                            {row.user.name + " " + row.user.lastName}
                          </TableCell>

                          <TableCell align="left">
                            {GeneralFunctions.formatNewDate(
                              row.created,
                              es
                            )}
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
                <div className={classes.root}>
                  <Pagination
                    count={numberPages}
                    page={page}
                    color="primary"
                    onChange={(event, value) => this.handleChange(event, value)}
                  />
                </div>
              </Grid>
            ) : (
              <>
                <Grid container spacing={1}>
                  <Grid item xs={12} md={12} lg={12}>
                    <NotRegistersFound />
                  </Grid>
                </Grid>
              </>
            ))}
        </Container>
      </main>
    );
  }
}

export default withTranslation()(
  withStyles(styles, { withTheme: true })(Readed)
);
