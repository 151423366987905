import {
  Button,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
} from "@material-ui/core";
import React, { useState } from "react";
import { FormattedMessage } from "react-intl";
import { Redirect, useParams } from "react-router-dom";
import DialogTitle from "../../common/controls/DialogTitle";
import PasswordCreation from "../../common/controls/PasswordCreation";
import { buttonStyles, inputStyles, textStyles } from "../../styles/styles";
import MessageBox from "../../utils/MessageBox";
import { SpinnerCircular } from "spinners-react";
import {
  postChangePassword,
  validarHash,
} from "./service/recoverPasswordService";

import UserService from "../../admin/Services/UserService";
import { useTranslation } from "react-i18next";

const ChangePassword = (props) => {
  const { username, company, hash } = useParams();
  const [redirect, setRedirect] = useState();
  const [password, setPassword] = useState();
  const [message, setMessage] = useState();
  const [confirm, setConfirm] = useState();
  var inputClasses = inputStyles();
  const classesButton = buttonStyles();
  const classesText = textStyles();
  const usercode = username;
  const [warningMessage, setWarningMessage] = useState('');
  const [loading, setLoading] = useState(false);

  const { t, i18n } = useTranslation();
  console.log(redirect);
  if (redirect === undefined) {
    validarHash(username, hash).then((value) => {
      if (Number.parseInt(value) === 0 && !redirect) {
        setRedirect("/");
      } else {
        setRedirect("");
      }
    });
  }

  return (
    <>
      {redirect !== undefined && redirect !== "" ? (
        <Redirect to={redirect}></Redirect>
      ) : null}
      {message && (
        <MessageBox
          message={
            <FormattedMessage
              id={message.id}
              defaultMessage={message.messageDefault}
            />
          }
          open={true}
          onClose={() => {
            setRedirect("/");
          }}
          title="Change Password"
        ></MessageBox>
      )}
      <Dialog open={true} fullScreen onClose={() => setRedirect("/")}>
        <DialogTitle onClose = {()=> setRedirect("/")}>
          <h2 style={{ textAlign: "center" }}>
            <FormattedMessage
              id="changePassword.title"
              defaultMessage={t(
                "forgotten-password",
                "Change your forgotten password"
              )}
            />
          </h2>
        </DialogTitle>
        {loading? (

        <div style={{ textAlign: "center" }}>
          <SpinnerCircular  size={90} height={200} />
        </div>
        ): (
          <>
          <DialogContent>
          <Container style={{ display: "flex", flexDirection: "column" }}>
            
          {warningMessage? (
          <h2 style={{ textAlign: "center", color: "red" }}>
            <FormattedMessage
              id={warningMessage}
              defaultMessage={warningMessage}
            />
          </h2>
          
          ): (<></>)}
            <PasswordCreation
              password={password}
              confirmPassword={confirm}
              className={[
                inputClasses.lightPinkInput,
                inputClasses.purpleInput,
              ]}
              onChange={(passwordCreate) => {
                setPassword(passwordCreate.password);
                setConfirm(passwordCreate.confirmPassword);
              }}
            ></PasswordCreation>
          </Container>
        </DialogContent>
        <DialogActions>
          <Button
            disabled={password !== confirm}
            onClick={() => {
              setLoading(true);
              UserService.resetPassword(usercode, password, hash)
              .then (r => {
                setMessage({
                  id: "recoverPassword.changeCorrect",
                  messageDefault: "Password changed correctly",
                });
              }).catch((e) => {
                if (e.response?.status == 422) {
                  setWarningMessage(e.response.data.message);
                } else {
                  setWarningMessage('Error al intentar cambiar la contraseña.');
                }
              }).finally(() => {
                setLoading(false);
              });
            }}
            style={{ alignSelf: "center" }}
            className={classesButton.buttonPurple}
          >
            <FormattedMessage
              id="login.confirmBtn"
              defaultMessage={t("confirm.label", "Confirm")}
              className={classesText.textButton}
            />
          </Button>
        </DialogActions>
        
        </>
        )}
      </Dialog>
    </>
  );
};

export default ChangePassword;
