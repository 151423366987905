import React, { useState } from "react";
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Typography from "@material-ui/core/Typography";
import { SpinnerCircular } from "spinners-react";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@material-ui/core/styles";
import Tooltip from "@material-ui/core/Tooltip";
import VisibilityIcon from "@material-ui/icons/Visibility";

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
  },
  margin: {
    margin: theme.spacing(1),
  },
  withoutLabel: {
    marginTop: theme.spacing(3),
  },
  formControl: {
    width: "100%",
    marginTop: "1rem",
  },
}));

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

export default function DetailsQuestion(props) {
  const classes = useStyles();

  const [open, setOpen] = useState(false);
  const { t } = useTranslation();
  const [loading] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Tooltip
        title={t("menu.metric-panel-dialog-show-detail", "Show details")}
      >
        <VisibilityIcon
          fontSize="small"
          className="cursor-pointer"
          onClick={handleClickOpen}
        />
      </Tooltip>

      <Dialog
        maxWidth="sm"
        fullWidth={true}
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <DialogTitle id="customized-dialog-title" onClose={handleClose}>
          {t("menu.metric-panel-dialog-show-detail", "Show details")}
        </DialogTitle>
        <DialogContent dividers>
          {loading && (
            <div className="div-spinner-modal">
              <Container maxWidth="lg" className={classes.container}>
                <Grid container spacing={1}>
                  <SpinnerCircular size={90} />
                </Grid>
              </Container>
            </div>
          )}

          {!loading && (
            <>
              {props.data.map((answer, index) => (
                <div key={index}>
                  <h3 className="mb-0">
                    {t("response.label", "Response")} {index + 1} (
                    {answer.isCorrectAnswer
                      ? t(
                          "menu.trivia-panel-dialog-test-question-add-dialog-input-text-correct",
                          "Correct"
                        )
                      : t(
                          "menu.trivia-panel-dialog-test-question-add-dialog-input-text-incorrect",
                          "Incorrect"
                        )}
                    )
                  </h3>
                  <h4 className="mb-0 mt-0">{answer.text}</h4>

                  {answer.isCorrectAnswer && (
                    <>
                      {" "}
                      <p className="mb-0 mt-0">
                        {answer.points
                          ? t("points.label", "Points") + ": " + answer.points
                          : t(
                              "menu.metric-panel-dialog-show-detail-without-points",
                              "Without points"
                            )}
                      </p>
                      <p className="mb-0 mt-0">
                        {answer.skill
                          ? t(
                              "menu.trivia-panel-dialog-test-question-add-dialog-input-text-skill",
                              "Skill"
                            ) +
                            ": " +
                            answer.skill.name
                          : t(
                              "menu.trivia-panel-dialog-test-analytic-without-skill",
                              "Without skill"
                            )}
                      </p>
                    </>
                  )}

                  {!answer.isCorrectAnswer && (
                    <>
                      {" "}
                      <p className="mb-0 mt-0">
                        {t(
                          "menu.metric-panel-dialog-show-detail-without-points",
                          "Without points"
                        )}
                      </p>
                      <p className="mb-0 mt-0">
                        {t(
                          "menu.trivia-panel-dialog-test-analytic-without-skill",
                          "Without skill"
                        )}
                      </p>
                    </>
                  )}

                  <hr className="hr-styles" />
                </div>
              ))}
            </>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="secondary">
            {t("menu.trivia-panel-dialog-add-test-button-close", "Close")}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
