import * as generalTypes from "../types/generalTypes";

import TeamService from "../components/app/admin/Services/TeamService";
import GeneralService from "../components/app/admin/Services/GeneralService";

import UserServices from "../components/app/admin/Services/UserService";
import MetricService from "../components/app/admin/Services/MetricService";

import TestService from "../components/app/admin/Services/TestService";
import BadgeService from "../components/app/admin/Services/BadgeService";
import AwardService from "../components/app/admin/Services/AwardService";

import LetterSoupService from "../components/app/admin/Services/LetterSoupService";
import TournamentService from "../components/app/admin/Services/TournamentService";

const {
  TEAMS_REQUEST,
  TEAMS_CHARGING,
  TEAMS_ERROR,
  AWARDS_REQUEST,
  AWARDS_CHARGING,
  AWARDS_ERROR,
  IMAGES_REQUEST,
  IMAGES_CHARGING,
  IMAGES_ERROR,
  SKILLS_REQUEST,
  SKILLS_CHARGING,
  SKILLS_ERROR,
  SECONDARY_TEAMS_REQUEST,
  SECONDARY_TEAMS_CHARGING,
  SECONDARY_TEAMS_ERROR,
  USERS_REQUEST,
  USERS_CHARGING,
  USERS_ERROR,
  METRICS_CHARGING,
  METRICS_ERROR,
  METRICS_REQUEST,
  TESTS_CHARGING,
  TESTS_ERROR,
  TESTS_REQUEST,
  LETTERSOUP_CHARGING,
  LETTERSOUP_ERROR,
  LETTERSOUP_REQUEST,
  BADGES_CHARGING,
  BADGES_ERROR,
  BADGES_REQUEST,
  ASSIGN_BADGES_CHARGING,
  ASSIGN_BADGES_ERROR,
  ASSIGN_BADGES_REQUEST,
} = generalTypes;

export const awardsRequest = () => async (dispatch) => {
  dispatch({
    type: AWARDS_CHARGING,
  });

  try {
    const responseLogin = await AwardService.getAwards(1, 999);

    dispatch({
      type: AWARDS_REQUEST,
      payload: responseLogin.data,
    });
  } catch (error) {
    dispatch({
      type: AWARDS_ERROR,
      payload: error.response ? error.response.data : error,
    });
  }
};

export const teamsRequest = () => async (dispatch) => {
  dispatch({
    type: TEAMS_CHARGING,
  });

  try {
    const responseLogin = await TeamService.getTeams();

    dispatch({
      type: TEAMS_REQUEST,
      payload: responseLogin.data,
    });
  } catch (error) {
    dispatch({
      type: TEAMS_ERROR,
      payload: error.response ? error.response.data : error,
    });
  }
};

export const saveTeamRequest = (payload) => async (dispatch, getState) => {
  const { teams } = getState().generalReducer;

  const teamsUpdated = [payload, ...teams];

  dispatch({
    type: TEAMS_REQUEST,
    payload: teamsUpdated,
  });
};

export const skillsRequest = () => async (dispatch) => {
  dispatch({
    type: SKILLS_CHARGING,
  });

  try {
    const responseLogin = await TestService.getSkills(1, 999);

    dispatch({
      type: SKILLS_REQUEST,
      payload: responseLogin.data,
    });
  } catch (error) {
    dispatch({
      type: SKILLS_ERROR,
      payload: error.response ? error.response.data : error,
    });
  }
};

export const imagesRequest = () => async (dispatch) => {
  dispatch({
    type: IMAGES_CHARGING,
  });

  try {
    const responseLogin = await GeneralService.getBadgesImages(1);

    dispatch({
      type: IMAGES_REQUEST,
      payload: responseLogin.data,
    });
  } catch (error) {
    dispatch({
      type: IMAGES_ERROR,
      payload: error.response ? error.response.data : error,
    });
  }
};

export const secondaryTeamsRequest = () => async (dispatch) => {
  dispatch({
    type: SECONDARY_TEAMS_CHARGING,
  });

  try {
    const responseLogin = await TeamService.getSecondaryTeams();

    dispatch({
      type: SECONDARY_TEAMS_REQUEST,
      payload: responseLogin.data,
    });
  } catch (error) {
    dispatch({
      type: SECONDARY_TEAMS_ERROR,
      payload: error.response ? error.response.data : error,
    });
  }
};

export const saveSecondaryTeamRequest =
  (payload) => async (dispatch, getState) => {
    const { secondary_teams } = getState().generalReducer;

    const secondaryTeamsUpdated = [payload, ...secondary_teams];

    dispatch({
      type: TEAMS_REQUEST,
      payload: secondaryTeamsUpdated,
    });
  };

export const usersRequest = () => async (dispatch) => {
  dispatch({
    type: USERS_CHARGING,
  });

  try {
    const responseLogin = await UserServices.getUsersNotOwnCompact();

    dispatch({
      type: USERS_REQUEST,
      payload: responseLogin.data,
    });
  } catch (error) {
    dispatch({
      type: USERS_ERROR,
      payload: error.response ? error.response.data : error,
    });
  }
};

export const metricsRequest = () => async (dispatch) => {
  dispatch({
    type: METRICS_CHARGING,
  });

  try {
    const responseLogin = await MetricService.getMetrics(1, 999);

    dispatch({
      type: METRICS_REQUEST,
      payload: responseLogin.data,
    });
  } catch (error) {
    dispatch({
      type: METRICS_ERROR,
      payload: error.response ? error.response.data : error,
    });
  }
};
export const tournamentRequest = () => async (dispatch) => {
  dispatch({
    type: METRICS_CHARGING,
  });

  try {
    const responseLogin = await TournamentService.getMetrics(1, 999);

    dispatch({
      type: METRICS_REQUEST,
      payload: responseLogin.data,
    });
  } catch (error) {
    dispatch({
      type: METRICS_ERROR,
      payload: error.response ? error.response.data : error,
    });
  }
};

export const testsRequest = (payload) => async (dispatch) => {
  dispatch({
    type: TESTS_CHARGING,
  });

  try {

    const NUMBER = payload && payload.number ? payload.number : 1;
    const responseLogin = await TestService.getAllTests(NUMBER, 5);

    dispatch({
      type: TESTS_REQUEST,
      payload: responseLogin.data,
    });
  } catch (error) {
    dispatch({
      type: TESTS_ERROR,
      payload: error.response ? error.response.data : error,
    });
  }
};
export const letterSoupRequest = () => async (dispatch) => {
  dispatch({
    type: LETTERSOUP_CHARGING,
  });
  
  try {
    const responseLogin = await LetterSoupService.getAllLetterSoup(1, 999);
    dispatch({
      type: LETTERSOUP_REQUEST,
      payload: responseLogin.data,
    });
  } catch (error) {
    dispatch({
      type: LETTERSOUP_ERROR,
      payload: error.response ? error.response.data : error,
    });
  }
};


export const badgesRequest = () => async (dispatch) => {
  dispatch({
    type: BADGES_CHARGING,
  });

  try {
    const responseLogin = await BadgeService.getBadges(1, 999);

    dispatch({
      type: BADGES_REQUEST,
      payload: responseLogin.data,
    });
  } catch (error) {
    dispatch({
      type: BADGES_ERROR,
      payload: error.response ? error.response.data : error,
    });
  }
};

export const assignBadgesRequest = () => async (dispatch) => {
  dispatch({
    type: ASSIGN_BADGES_CHARGING,
  });

  try {
    const responseLogin = await BadgeService.getAssignedBadgesAdmin(1, 999);

    dispatch({
      type: ASSIGN_BADGES_REQUEST,
      payload: responseLogin.data,
    });
  } catch (error) {
    dispatch({
      type: ASSIGN_BADGES_ERROR,
      payload: error.response ? error.response.data : error,
    });
  }
};
