import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import Slide from "@material-ui/core/Slide";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import InputLabel from "@material-ui/core/InputLabel";
import { SpinnerCircular } from "spinners-react";
import toastr from "toastr";
import Tooltip from "@material-ui/core/Tooltip";
import { useTranslation } from "react-i18next";
import { AppWrapper } from "../GlobalStyles";
import FindRegistersDialog from "../../common/Dialogs/FindRegistersDialog";
import GeneralFunctions from "../../libs/GeneralFunctions";
import { connect } from "react-redux";

import {
  saveMetricRequest,
  updateMetricRequest,
} from "../../../../../actions/metricsActions";

/*********Icons********* */
import AddIcon from "@material-ui/icons/Add";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import EditIcon from "@material-ui/icons/Edit";

/**********Form part********** */

/********Components********* */
import YesNoOption from "../Metrics/YesNoOption";
import NumberOption from "../Metrics/NumberOption";
import TimeOption from "../Metrics/TimeOption";
import Percentage from "../Metrics/Percentage";
import Multiplier from "../Metrics/Multiplier";

/**********Hooks********** */
import { useFormatProps } from "../../hooks/useFormatProps";

/*********Libs********* */
import DateFnsUtils from "@date-io/date-fns";
import { TimePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";

const useStyles = makeStyles((theme) => ({
  appBar: {
    position: "relative",
  },
  formControl: {
    width: "100%",
    marginTop: "1rem",
  },
  container: {
    paddingTop: theme.spacing(4),
  },
  title: {
    marginLeft: theme.spacing(2),
    color: "black",
    flex: 1,
  },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function MetricDialog(props) {
  /***************Styles************ */
  const classes = useStyles();

  /***********General functions and constants******** */

  const [open, setOpen] = React.useState(false);
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();
  const [nameError, setNameError] = useState(false);
  const [descriptionError, setDescriptionError] = useState(false);
  const [frequencyError, setFrequencyErrorError] = useState(false);
  const [typeError, setTypeError] = useState(false);
  const [isPointsGrantedError, setIsPointsGrantedError] = useState(false);
  const [teamsError, setTeamsError] = useState(false);
  const [secondaryTeamsError, setSecondaryTeamsError] = useState(false);
  const [usersError, setUsersError] = useState(false);
  const [isApplyToSupervisorError, setIsApplyToSupervisorError] =
    useState(false);

  const [supervisorCalculationTypeError, setSupervisorCalculationTypeError] =
    useState(false);
  const [isActiveError, setIsActiveError] = useState(false);

  const [targetValueError, setTargetValueError] = useState(false);

  const [teamsHooks, rowsHooks, secondaryTeamsHooks] = useFormatProps(
    props.rowTeams ? props.rowTeams : [],
    props.rowsUsers ? props.rowsUsers : [],
    props.rowSecondaryTeams ? props.rowSecondaryTeams : []
  );

  function formatPropsEdit(array) {
    let arrayFormatted = [];
    array.forEach((element) => {
      arrayFormatted.push(element.id);
    });
    return arrayFormatted;
  }

  function parseValue(val, type) {
    return (
      val? 
        type !== "TIME"? 
          type === "BOOLEAN"? 
            val
          : parseFloat(val)
        : val
    : ""
    )
  }

  const [
    {
      name,
      description,
      frequency,
      type,
      isPointsGranted,
      teams,
      users,
      isApplyToSupervisor,
      supervisorCalculationType,
      targetValue,
      isActive,
      teamsProps,
      rowsUsers,
      color1,
      color2,
      color3,
      points1,
      points2,
      points3,
      rangeFrom1,
      rangeFrom2,
      rangeFrom3,
      rangeto1,
      rangeto2,
      rangeto3,
      secondaryTeams,
      rowSecondaryTeams,
    },
    setState,
  ] = useState({
    color1: props.color1 ? props.color1 : "#FF0000",
    color2: props.color2 ? props.color2 : "#FFFF00",
    color3: props.color3 ? props.color3 : "#00D800",
    points1: props.points1 ? props.points1 : 0,
    points2: props.points2 ? props.points2 : 0,
    points3: props.points3 ? props.points3 : 0,
    rangeFrom1: props.rangeFrom1 ? props.rangeFrom1 : 0,
    rangeFrom2: props.rangeFrom2 ? props.rangeFrom2 : 0,
    rangeFrom3: parseValue(props.rangeFrom3,props.type ),
    rangeto1: props.rangeTo1 ? props.rangeTo1 : 0,
    rangeto2: props.rangeTo2 ? props.rangeTo2 : 0,
    rangeto3: props.rangeTo3 ? props.rangeTo3 : 0,
    name: props.name ? props.name : "",
    type: props.type ? props.type : "",
    description: props.description ? props.description : "",
    frequency: props.frequency ? props.frequency : "",
    isActive: props.isActive ? props.isActive : true,
    isApplyToSupervisor: props.isApplyToSupervisor
      ? String(props.isApplyToSupervisor)
      : "false",
    isPointsGranted: props.isPointsGranted ? props.isPointsGranted : false,
    supervisorCalculationType: props.supervisorCalculationType
      ? props.supervisorCalculationType
      : "ADITION",

    targetValue: props.targetValue
      ? props.type !== "TIME"
        ? props.type === "BOOLEAN"
          ? props.targetValue
          : parseFloat(props.targetValue)
        : new Date(
            "0000-01-01T" + (props.targetValue ? props.targetValue : "00:00:00")
          )
      : "",

    teams: formatPropsEdit(props.teams ? props.teams : []),
    users: formatPropsEdit(props.users ? props.users : []),

    teamsProps: teamsHooks && teamsHooks.length > 0 ? teamsHooks : [],
    rowsUsers: rowsHooks && rowsHooks.length > 0 ? rowsHooks : [],
    secondaryTeams: formatPropsEdit(
      props.secondaryTeams ? props.secondaryTeams : []
    ),
    rowSecondaryTeams:
      secondaryTeamsHooks && secondaryTeamsHooks.length > 0
        ? secondaryTeamsHooks
        : [],
  });

  const handleClickOpen = () => {
    setOriginalState();

    setOpen(true);
  };

  function handleChange({ target: { name, value } }) {
    setState((prevState) => ({ ...prevState, [name]: value }));
    if (name === "type") {
      setState((prevState) => ({
        ...prevState,
        color1: props.color1 ? props.color1 : "#FF0000",
        color2: props.color2 ? props.color2 : "#FFFF00",
        color3: props.color3 ? props.color3 : "#00D800",
        points1: props.points1 ? props.points1 : 0,
        points2: props.points2 ? props.points2 : 0,
        points3: props.points3 ? props.points3 : 0,
        rangeFrom1: props.rangeFrom1 ? props.rangeFrom1 : 0,
        rangeFrom2: props.rangeFrom2 ? props.rangeFrom2 : 0,
        rangeFrom3: props.rangeFrom3 ? props.rangeFrom3 : 0,
        rangeto1: props.rangeTo1 ? props.rangeTo1 : 0,
        rangeto2: props.rangeTo2 ? props.rangeTo2 : 0,
        rangeto3: props.rangeTo3 ? props.rangeTo3 : 0,
      }));

      if (name === "type" && value === "TIME") {
        setState((prevState) => ({
          ...prevState,
          targetValue: new Date("0000-01-01T00:00:00"),
        }));
      } else if (name === "type" && value === "BOOLEAN") {
        setState((prevState) => ({
          ...prevState,
          targetValue: true,
        }));
      } else {
        setState((prevState) => ({
          ...prevState,
          targetValue: "",
        }));
      }
    }
  }

  function handleChangeTime(e) {
    setState((prevState) => ({
      ...prevState,
      targetValue: e,
    }));
  }

  const handleClose = () => {
    setOriginalState();
    setOpen(false);
  };

  function handleData(data) {
    setState((prevState) => ({ ...prevState, [data.name]: data.value }));
  }

  function setOriginalState() {
    setState((prevState) => ({
      ...prevState,
      color1: props.color1 ? props.color1 : "#fff",
      color2: props.color2 ? props.color2 : "yellow",
      color3: props.color3 ? props.color3 : "green",
      points1: props.points1 ? parseFloat(props.points1) : 0,
      points2: props.points2 ? parseFloat(props.points2) : 0,
      points3: props.points3 ? parseFloat(props.points3) : 0,
      rangeFrom1: parseValue(props.rangeFrom1, props.type) === '' ? 0 : parseValue(props.rangeFrom1, props.type),
      rangeFrom2: parseValue(props.rangeFrom2, props.type) === '' ? 0 : parseValue(props.rangeFrom2, props.type),
      rangeFrom3: parseValue(props.rangeFrom3, props.type) === '' ? 0 : parseValue(props.rangeFrom3, props.type),
      rangeto1: parseValue(props.rangeTo1, props.type),
      rangeto2: parseValue(props.rangeTo2, props.type),
      rangeto3: parseValue(props.rangeTo3, props.type),
      secondaryTeams: formatPropsEdit(
        props.secondaryTeams ? props.secondaryTeams : []
      ),
      teams: formatPropsEdit(props.teams ? props.teams : []),
      users: formatPropsEdit(props.users ? props.users : []),
      type: props.type ? props.type : "",
      isActive: props.isActive ? props.isActive : true,
      description: props.description ? props.description : "",
      frequency: props.frequency ? props.frequency : "",
      isApplyToSupervisor: props.isApplyToSupervisor
        ? String(props.isApplyToSupervisor)
        : "false",
      isPointsGranted: props.isPointsGranted ? props.isPointsGranted : false,
      supervisorCalculationType: props.supervisorCalculationType
        ? props.supervisorCalculationType
        : "ADITION",
      targetValue: props.targetValue
        ? props.type !== "TIME"
          ? props.type === "BOOLEAN"
            ? props.targetValue
            : parseFloat(props.targetValue)
          : new Date(
              "0000-01-01T" +
                (props.targetValue ? props.targetValue : "00:00:00")
            )
        : "",
    }));
  }

  function setSecondaryTeams(secondaryTeams) {
    setState((prevState) => ({ ...prevState, 'secondaryTeams': secondaryTeams }));
  }

  function setTeams(teams) {
    setState((prevState) => ({ ...prevState, 'teams': teams }));
  }

  function setUsers(users) {
    console.log(users)
    setState((prevState) => ({ ...prevState, 'users': users }));
  }

  function setName(option) {
    return (option.name == '' || option.name  == null) ? t(
      "admin.user-panel-user-dialog-input-select-without-name",
      "Without name") : option.name;
  }

  /***************************CRUD functions*************** */
  async function onFormSubmit() {
    setNameError(false);
    setDescriptionError(false);
    setFrequencyErrorError(false);
    setTypeError(false);
    setIsPointsGrantedError(false);
    setTeamsError(false);
    setUsersError(false);
    setIsApplyToSupervisorError(false);
    setSupervisorCalculationTypeError(false);
    setIsActiveError(false);
    setSecondaryTeamsError(false);

    if (name === "") {
      setNameError(true);
      toastr.error(
        t(
          "menu.trivia-panel-dialog-add-test-message-error-name",
          "The name is required"
        )
      );
      return;
    }

    if (description === "") {
      setDescriptionError(true);
      toastr.error(
        t(
          "menu.trivia-panel-dialog-add-test-message-error-description",
          "Description is required"
        )
      );
      return;
    }  else if (description.length > 650) {
      setDescriptionError(true);
      toastr.error(
        t(
        "commons.description-max-length",
        "La descipción no puede tener más de 650 caracteres."
        )
      );
      return;
    }

    if (frequency === "") {
      setFrequencyErrorError(true);
      toastr.error(
        t(
          "menu.metric-panel-dialog-message-error-frequency",
          "Frequency is required"
        )
      );
      return;
    }

    if (type === "") {
      setTypeError(true);
      toastr.error(
        t("menu.metric-panel-dialog-message-error-type", "The type is required")
      );
      return;
    }

    if (isPointsGranted === "") {
      setIsPointsGrantedError(true);
      toastr.error(
        t(
          "menu.trivia-panel-dialog-add-test-message-error-score",
          "The score is required"
        )
      );
      return;
    }

    if (secondaryTeams === "" || secondaryTeams.length === 0) {
      if (
        (teams === "" || teams.length === 0 || !teams) &&
        (users === "" || users.length === 0 || !users)
      ) {
        setTeamsError(true);
        setUsersError(true);
        setSecondaryTeamsError(true);

        toastr.error(
          t(
            "menu.trivia-panel-dialog-add-test-message-error-add-data",
            "You must add group, secondary group or players"
          )
        );
        return;
      }
    }

    if (isApplyToSupervisor === "") {
      setIsApplyToSupervisorError(true);
      toastr.error(
        t(
          "menu.metric-panel-dialog-message-error-supervisor",
          "You must choose whether or not to impact the supervisor"
        )
      );
      return;
    }

    if (isApplyToSupervisor === true) {
      if (supervisorCalculationType === "") {
        setSupervisorCalculationTypeError(true);
        toastr.error(
          t(
            "menu.metric-panel-dialog-message-error-calculation",
            "You must choose the type of calculation"
          )
        );
        return;
      }
    }

    if (targetValue === "") {
      setTargetValueError(true);
      toastr.error(
        t(
          "menu.metric-panel-dialog-message-error-goal",
          "You must enter a goal"
        )
      );
      return;
    }

    if (isActive === "") {
      setIsActiveError(true);
      toastr.error(
        t(
          "menu.trivia-panel-dialog-add-test-message-error-active",
          "You must choose if it is active or not"
        )
      );
      return;
    }

    if (isPointsGranted) {
      if (type === "BOOLEAN") {
        if (points1 === "" || rangeFrom1 === "") {
          toastr.error(
            t(
              "menu.metric-panel-dialog-message-error-rank-information",
              "Rank and point information is missing, please check and try again"
            )
          );
          return;
        }
      } else {
        if (type === "TIME") {
          if (
            points1 === "" ||
            points2 === "" ||
            points3 === "" ||
            color1 === "" ||
            color2 === "" ||
            color3 === ""
          ) {
            toastr.error(
              t(
                "menu.metric-panel-dialog-message-error-rank-information",
                "Rank and point information is missing, please check and try again"
              )
            );
            return;
          }
        } else {
          if (
            points1 === "" ||
            points2 === "" ||
            points3 === "" ||
            rangeFrom1 === "" ||
            rangeFrom2 === "" ||
            rangeFrom3 === "" ||
            rangeto1 === "" ||
            rangeto2 === "" ||
            rangeto3 === "" ||
            color1 === "" ||
            color2 === "" ||
            color3 === ""
          ) {
            toastr.error(
              t(
                "menu.metric-panel-dialog-message-error-rank-information",
                "Rank and point information is missing, please check and try again"
              )
            );
            return;
          }
        }
      }
    }

    /***********Format variables****** */
    let teamsFormatted = [];
    teams.forEach((element) => {
      teamsFormatted.push({
        id: element,
      });
    });

    let usersFormatted = [];
    users.forEach((element) => {
      usersFormatted.push({
        id: element,
      });
    });

    let secondaryTeamsFormatted = [];
    secondaryTeams.forEach((element) => {
      secondaryTeamsFormatted.push({
        id: element,
      });
    });
    /***********Format variables****** */
    console.log('usersFormatted', usersFormatted)
    let json = {
      color1: color1 ? color1 : "red",
      color2: color2 ? color2 : "yellow",
      color3: color3 ? color3 : "green",
      points1: points1 && points1 !== "" ? parseFloat(points1) : "",
      points2: points2 && points2 !== "" ? parseFloat(points2) : "",
      points3: points3 && points3 !== "" ? parseFloat(points3) : "",
      rangeFrom1:
        type === "TIME"
          ? !rangeFrom1
            ? "00:00:00"
            : rangeFrom1
          : rangeFrom1,
      rangeFrom2:
        type === "TIME"
          ? !rangeFrom2
            ? "00:00:00"
            : rangeFrom2
          : parseFloat(rangeFrom2),
      rangeFrom3:
        type === "TIME"
          ? !rangeFrom3
            ? "00:00:00"
            : rangeFrom3
          : parseFloat(rangeFrom3),
      rangeTo1:
        type === "TIME"
          ? !rangeto1
            ? "00:00:00"
            : rangeto1
          : parseFloat(rangeto1),

      rangeTo2:
        type === "TIME"
          ? !rangeto2
            ? "00:00:00"
            : rangeto2
          : parseFloat(rangeto2),
      rangeTo3:
        type === "TIME"
          ? !rangeto3
            ? "00:00:00"
            : rangeto3
          : parseFloat(rangeto3),

      name: name,
      teams: teamsFormatted,
      users: usersFormatted,
      type: type,
      isActive: isActive,
      secondaryTeams: secondaryTeamsFormatted,
      description: description,
      frequency: frequency,
      isApplyToSupervisor: isApplyToSupervisor,
      isPointsGranted: isPointsGranted,
      supervisorCalculationType: supervisorCalculationType,
      targetValue:
        type !== "TIME"
          ? type === "BOOLEAN"
            ? targetValue
            : parseFloat(targetValue)
          : GeneralFunctions.formatDate(targetValue),
    };

    setLoading(true);

    let status;
    if (props.typeModal === "modalEdit") {
      await props
        .updateMetricRequest({ ...json, id: props.id })
        .then((r) => (status = r));
    } else {
      await props.saveMetricRequest(json).then((r) => (status = r));
    }

    if (status.status === "ERROR") {
      if (status.error && status.error.status === 422) {
        toastr.error(
          t(
            "menu.metric-panel-dialog-message-error-save-metric-one",
            "A metric with this name already exists"
          )
        );
      } else {
        if (status.error.data.message === "invalid.duplicatedname") {
          toastr.error(
            t(
              "menu.metric-panel-dialog-message-error-save-metric-one",
              "A metric with this name already exists"
            )
          );
        } else {
          toastr.error(
            t(
              "menu.metric-panel-dialog-message-error-save-metric-two",
              "An error occurred while trying to save the metric"
            )
          );
        }
      }

      setLoading(false);
    } else {
      toastr.success(
        t(
          "menu.metric-panel-dialog-message-success-save-metric",
          "Metric saved successfully"
        )
      );
      setLoading(false);
      handleClose();
    }
  }
  return (
    <>
      {props.typeModal === "modalEdit" ? (
        <Tooltip title={t("admin.actions-edit", "Edit")}>
          <EditIcon
            className="cursor-pointer"
            fontSize="small"
            onClick={handleClickOpen}
          />
        </Tooltip>
      ) : (
        <Button
          className="button-table mr-1"
          variant="contained"
          color="secondary"
          onClick={handleClickOpen}
        >
          <AddIcon />
          {t("menu.metric-panel-title", "Metric")}
        </Button>
      )}

      <Dialog
        fullScreen
        open={open}
        onClose={handleClose}
        TransitionComponent={Transition}
      >
        <AppWrapper>
          <AppBar className={classes.appBar}>
            <Toolbar>
              <IconButton edge="start" onClick={handleClose} aria-label="close">
                <CloseIcon />
              </IconButton>
              <Typography variant="h6" className={classes.title}>
                {t("menu.metric-panel-dialog-title", "Metric management")}
              </Typography>
            </Toolbar>
          </AppBar>

          {loading && (
            <div className="div-spinner-modal">
              <Container maxWidth="lg" className={classes.container}>
                {" "}
                <Grid container spacing={1}>
                  <SpinnerCircular size={90} />
                </Grid>
              </Container>
            </div>
          )}

          {!loading && (
            <Container maxWidth="lg" className={classes.container}>
              <Grid container spacing={1}>
                <Grid item xs={12} md={6} lg={6}>
                  <TextField
                    onChange={handleChange}
                    error={nameError}
                    value={name}
                    name="name"
                    id="name"
                    label={t(
                      "menu.metric-panel-dialog-metric-name",
                      "Metric name"
                    )}
                    variant="outlined"
                    className="mt-2"
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} md={6} lg={6}>
                  <TextField
                    onChange={handleChange}
                    error={descriptionError}
                    value={description}
                    name="description"
                    id="description"
                    label={t(
                      "menu.metric-panel-dialog-metric-description",
                      "Metric description"
                    )+ ` (${(description? description.length: '0')}/650)`}
                    variant="outlined"
                    className="mt-2"
                    fullWidth
                    inputProps={{ maxLength: 650 }}
                  />
                </Grid>
              </Grid>

              <Grid container spacing={1}>
                <Grid item xs={12} md={6} lg={6}>
                  <FormControl
                    variant="outlined"
                    className={classes.formControl}
                  >
                    <InputLabel id="frequency-select-outlined-label">
                      {t("menu.metric-panel-table-frequency", "Frequency")}
                    </InputLabel>
                    <Select
                      onChange={handleChange}
                      error={frequencyError}
                      value={frequency}
                      labelId="frequency"
                      id="frequency"
                      name="frequency"
                      label={t(
                        "menu.metric-panel-table-frequency",
                        "Frequency"
                      )}
                    >
                      <MenuItem value={"DAILY"}>
                        {t("menu.metric-panel-table-daily", "Daily")}{" "}
                      </MenuItem>
                      <MenuItem value={"WEEKLY"}>
                        {t("menu.metric-panel-table-weekly", "Weekly")}
                      </MenuItem>
                      <MenuItem value={"MONTHLY"}>
                        {t("menu.metric-panel-table-mothly", "Mothly")}
                      </MenuItem>
                      <MenuItem value={"EVENTUAL"}>
                        {t("menu.metric-panel-table-eventual", "Eventual")}
                      </MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} md={6} lg={6}>
                  <FormControl
                    variant="outlined"
                    className={classes.formControl}
                  >
                    <InputLabel id="type-select-outlined-label">
                      {t("type.label", "Type")}
                    </InputLabel>
                    <Select
                      onChange={handleChange}
                      error={typeError}
                      value={type}
                      labelId="type"
                      id="type"
                      name="type"
                      label={t("type.label", "Type")}
                    >
                      <MenuItem value={"BOOLEAN"}>
                        {t(
                          "admin.header-dropdown-dialog-notifications-input-item-yes",
                          "Yes"
                        )}
                        /
                        {t(
                          "admin.header-dropdown-dialog-notifications-input-item-no",
                          "No"
                        )}{" "}
                      </MenuItem>
                      <MenuItem value={"NUMBER"}>
                        {t(
                          "menu.trivia-panel-dialog-add-test-select-questions-number",
                          "Number"
                        )}{" "}
                      </MenuItem>
                      <MenuItem value={"TIME"}>
                        {t(
                          "menu.trivia-panel-dialog-test-analytic-time",
                          "Time"
                        )}
                      </MenuItem>

                      {/* <MenuItem value={"PERCENT"}>
                        {t("menu.metric-panel-table-percentage", "Percentage")}
                      </MenuItem> */}

                      <MenuItem value={"MULTIPLIER"}>
                        {t("menu.metric-panel-dialog-Multiplier", "Multiplier")}
                      </MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>

              <Grid container spacing={1}>
                <Grid item xs={12} md={6} lg={6}>
                  <FormControl
                    variant="outlined"
                    className={classes.formControl}
                  >
                    <InputLabel id="isPointsGranted-select-outlined-label">
                      {t("points.label", "Points")}
                    </InputLabel>
                    <Select
                      onChange={handleChange}
                      error={isPointsGrantedError}
                      value={isPointsGranted}
                      labelId="isPointsGranted"
                      id="isPointsGranted"
                      name="isPointsGranted"
                      label={t("points.label", "Points")}
                    >
                      <MenuItem value={true}>
                        {t("menu.metric-panel-table-add-points", "Add points")}{" "}
                      </MenuItem>
                      <MenuItem value={false}>
                        {t(
                          "menu.metric-panel-table-not-add-points",
                          "Does not score points"
                        )}
                      </MenuItem>
                    </Select>
                  </FormControl>
                </Grid>

                {rowSecondaryTeams && rowSecondaryTeams.length > 0 && (
                  <FindRegistersDialog
                    setValue={setSecondaryTeams}
                    setName={setName}
                    label={t(
                      "admin.header-dropdown-dialog-secondary-teams-input",
                      "Secondary Teams"
                    )}
                    rowsSelected={secondaryTeams}
                    rows={rowSecondaryTeams}
                  />
                )}

                {teamsProps && teamsProps.length > 0 && (
                  <FindRegistersDialog
                    setValue={setTeams}
                    setName={setName}
                    label={t(
                      "admin.user-panel-user-dialog-input-groups",
                      "Groups"
                    )}
                    rowsSelected={teams}
                    rows={teamsProps}
                  />
                )}

                {rowsUsers && rowsUsers.length > 0 && (
                  <FindRegistersDialog
                    setValue={setUsers}
                    label={t(
                      "menu.trivia-panel-dialog-add-test-select-players",
                      "Players"
                    )}
                    rowsSelected={users}
                    rows={rowsUsers}
                  />
                )}

                <Grid item xs={12} md={6} lg={6}>
                  <FormControl
                    variant="outlined"
                    className={classes.formControl}
                  >
                    <InputLabel id="isApplyToSupervisor-select-outlined-label">
                      {t(
                        "menu.metric-panel-table-impact-supervisor",
                        "Impacta/Supervisor"
                      )}
                    </InputLabel>
                    <Select
                      onChange={handleChange}
                      error={isApplyToSupervisorError}
                      value={isApplyToSupervisor}
                      labelId="isApplyToSupervisor"
                      id="isApplyToSupervisor"
                      name="isApplyToSupervisor"
                      label={t(
                        "menu.metric-panel-table-impact-supervisor",
                        "Impacta/Supervisor"
                      )}
                    >
                      <MenuItem value={"true"}>
                        {t(
                          "admin.header-dropdown-dialog-notifications-input-item-yes",
                          "Yes"
                        )}{" "}
                      </MenuItem>
                      <MenuItem value={"false"}>
                        {t(
                          "admin.header-dropdown-dialog-notifications-input-item-no",
                          "No"
                        )}
                      </MenuItem>
                    </Select>
                  </FormControl>
                </Grid>

                {isApplyToSupervisor === "true" && (
                  <Grid item xs={12} md={6} lg={6}>
                    <FormControl
                      variant="outlined"
                      className={classes.formControl}
                    >
                      <InputLabel id="supervisorCalculationType-select-outlined-label">
                        {t(
                          "menu.metric-panel-dialog-calculation-type",
                          "Calculation type"
                        )}
                      </InputLabel>
                      <Select
                        onChange={handleChange}
                        value={supervisorCalculationType}
                        error={supervisorCalculationTypeError}
                        labelId="supervisorCalculationType"
                        id="supervisorCalculationType"
                        name="supervisorCalculationType"
                        label={t(
                          "menu.metric-panel-dialog-calculation-type",
                          "Calculation type"
                        )}
                      >
                        <MenuItem value={"ADITION"}>
                          {t("menu.metric-panel-dialog-add", "Addition")}{" "}
                        </MenuItem>
                        <MenuItem value={"AVERAGE"}>
                          {t("menu.metric-panel-dialog-average", "Average")}
                        </MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                )}
                {/*******Objective******** */}

                {type === "BOOLEAN" && (
                  <Grid item xs={12} md={6} lg={6}>
                    <FormControl
                      variant="outlined"
                      className={classes.formControl}
                    >
                      <InputLabel id="targetValue-select-outlined-label">
                        {t("menu.metric-panel-dialog-objective", "Objective")}
                      </InputLabel>
                      <Select
                        onChange={handleChange}
                        error={targetValueError}
                        value={targetValue}
                        labelId="targetValue"
                        id="targetValue"
                        name="targetValue"
                        label="targetValue"
                      >
                        <MenuItem value={true}>
                          {t(
                            "admin.header-dropdown-dialog-notifications-input-item-yes",
                            "Yes"
                          )}{" "}
                        </MenuItem>
                        <MenuItem value={false}>
                          {t(
                            "admin.header-dropdown-dialog-notifications-input-item-no",
                            "No"
                          )}
                        </MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                )}
                {(type === "NUMBER" ||
                  type === "PERCENT" ||
                  type === "MULTIPLIER") && (
                  <Grid item xs={12} md={6} lg={6}>
                    <TextField
                      onChange={handleChange}
                      error={targetValueError}
                      value={targetValue}
                      name="targetValue"
                      id="targetValue"
                      type="number"
                      label={t(
                        "menu.metric-panel-dialog-objective",
                        "Objective"
                      )}
                      variant="outlined"
                      className="mt-2"
                      fullWidth
                    />
                  </Grid>
                )}

                {type === "TIME" && (
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <Grid item xs={12} md={6} lg={6}>
                      <TimePicker
                        className="mt-2"
                        ampm={false}
                        okLabel={t("accept.label", "Accept")}
                        cancelLabel={t(
                          "admin.header-dropdown-dialog-actions-cancel",
                          "Cancel"
                        )}
                        clearLabel={t(
                          "menu.badge-panel-dialog-minimum-points-clean-up",
                          "Clean up"
                        )}
                        openTo="hours"
                        inputVariant="outlined"
                        views={["hours", "minutes", "seconds"]}
                        format="HH:mm:ss"
                        label={t(
                          "menu.metric-panel-dialog-objective",
                          "Objective"
                        )}
                        value={targetValue}
                        error={targetValueError}
                        fullWidth
                        onChange={handleChangeTime}
                        name="targetValue"
                        id="targetValue"
                      />
                    </Grid>
                  </MuiPickersUtilsProvider>
                )}

                {/*******Objective******** */}
                <Grid item xs={12} md={6} lg={6}>
                  <FormControl
                    variant="outlined"
                    className={classes.formControl}
                  >
                    <InputLabel id="isActive-select-outlined-label">
                      {t(
                        "admin.header-dropdown-view-conditions-table-state",
                        "State"
                      )}
                    </InputLabel>
                    <Select
                      onChange={handleChange}
                      error={isActiveError}
                      value={isActive}
                      labelId="isActive"
                      id="isActive"
                      name="isActive"
                      label={t(
                        "admin.header-dropdown-view-conditions-table-state",
                        "State"
                      )}
                    >
                      <MenuItem value={true}>
                        {t(
                          "admin.header-dropdown-view-conditions-table-active-state",
                          "Active"
                        )}{" "}
                      </MenuItem>
                      <MenuItem value={false}>
                        {t(
                          "admin.header-dropdown-view-conditions-table-blocked-state",
                          "Blocked"
                        )}
                      </MenuItem>
                    </Select>
                  </FormControl>
                </Grid>

                {/***********Yes/No********* */}

                {type === "BOOLEAN" && isPointsGranted === true && (
                  <>
                    <hr className="w-100 mt-2" />{" "}
                    <YesNoOption
                      points1={points1}
                      rangeFrom1={rangeFrom1}
                      setData={(data) => handleData(data)}
                    />
                  </>
                )}

                {/***********Yes/No********* */}

                {/***********Number********* */}
                {type === "NUMBER" && isPointsGranted === true && (
                  <>
                    <hr className="w-100 mt-2" />{" "}
                    <NumberOption
                      setData={(data) => handleData(data)}
                      color1={color1}
                      color2={color2}
                      color3={color3}
                      points1={points1}
                      points2={points2}
                      points3={points3}
                      rangeFrom1={rangeFrom1}
                      rangeFrom2={rangeFrom2}
                      rangeFrom3={rangeFrom3}
                      rangeto1={rangeto1}
                      rangeto2={rangeto2}
                      rangeto3={rangeto3}
                    />
                  </>
                )}

                {/***********Number********* */}

                {/***********Time********* */}

                {type === "TIME" && isPointsGranted === true && (
                  <>
                    <hr className="w-100 mt-2" />{" "}
                    <TimeOption
                      setData={(data) => handleData(data)}
                      color1={color1}
                      color2={color2}
                      color3={color3}
                      points1={points1}
                      points2={points2}
                      points3={points3}
                      rangeFrom1={rangeFrom1}
                      rangeFrom2={rangeFrom2}
                      rangeFrom3={rangeFrom3}
                      rangeto1={rangeto1}
                      rangeto2={rangeto2}
                      rangeto3={rangeto3}
                    />
                  </>
                )}

                {/***********Time********* */}

                {/***********Percentage********* */}

                {type === "PERCENT" && isPointsGranted === true && (
                  <>
                    <hr className="w-100 mt-2" />{" "}
                    <Percentage
                      setData={(data) => handleData(data)}
                      color1={color1}
                      color2={color2}
                      color3={color3}
                      points1={points1}
                      points2={points2}
                      points3={points3}
                      rangeFrom1={rangeFrom1}
                      rangeFrom2={rangeFrom2}
                      rangeFrom3={rangeFrom3}
                      rangeto1={rangeto1}
                      rangeto2={rangeto2}
                      rangeto3={rangeto3}
                    />
                  </>
                )}
                {/***********Percentage********* */}
                {/***********Multiplier********* */}

                {type === "MULTIPLIER" && isPointsGranted === true && (
                  <>
                    <hr className="w-100 mt-2" />{" "}
                    <Multiplier
                      setData={(data) => handleData(data)}
                      color1={color1}
                      color2={color2}
                      color3={color3}
                      points1={points1}
                      points2={points2}
                      points3={points3}
                      rangeFrom1={rangeFrom1}
                      rangeFrom2={rangeFrom2}
                      rangeFrom3={rangeFrom3}
                      rangeto1={rangeto1}
                      rangeto2={rangeto2}
                      rangeto3={rangeto3}
                    />
                  </>
                )}
                {/***********Multiplier********* */}
              </Grid>

              <Button
                className="button-table mt-2 mr-1"
                onClick={handleClose}
                variant="contained"
                color="secondary"
              >
                {t("menu.trivia-panel-dialog-add-test-button-close", "Close")}
              </Button>

              <Button
                className="button-table mt-2"
                variant="contained"
                color="primary"
                onClick={() => onFormSubmit()}
              >
                {t(
                  "menu.metric-panel-dialog-save-information",
                  "Save information"
                )}
              </Button>
            </Container>
          )}
        </AppWrapper>
      </Dialog>
    </>
  );
}

const mapDispatchToProps = {
  saveMetricRequest,
  updateMetricRequest,
};

export default connect(null, mapDispatchToProps)(MetricDialog);
