import axios from "axios";
import environment from "../../../libs/environment";

export default {
  GetTournaments: () =>
    axios({
      method: "GET",
      url: `${environment.motivarnosBackend}/tournament`,
    }),
};
