import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import Container from "@material-ui/core/Container";
import Button from "@material-ui/core/Button";
import Pagination from "@material-ui/lab/Pagination";
import TextField from "@material-ui/core/TextField";
import { useHistory } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    height: "100vh",
    overflow: "auto",
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
  },
  fixedHeight: {
    height: 240,
  },
  root: {
    "& > *": {
      marginTop: theme.spacing(2),
    },
  },
  textField: {
    width: "50ch",
    marginBottom: "5px",
  },
}));

function createData(user, name, lastName, points) {
  return { user, name, lastName, points };
}

const rows = [
  createData("bomj321", "José", "Ortega", 5000),
  createData("raul321", "Raúl", "Ortega", 4000),
  createData("bomj321", "José", "Ortega", 2000),
  createData("bomj321", "José", "Ortega", 1000),
  createData("bomj321", "José", "Ortega", 500),
  createData("bomj321", "José", "Ortega", 800),
];

export default function Coins() {
  const classes = useStyles();
  const history = useHistory();

  function redirectFunction() {
    history.push("/admin");
  }

  return (
    <main className={classes.content}>
      <div className={classes.appBarSpacer} />
      <Container maxWidth="lg" className={classes.container}>
        <Grid container spacing={1}>
          <Button
            className="button-table"
            variant="contained"
            color="secondary"
            onClick={redirectFunction}
          >
            Atras
          </Button>
          <Button
            className="button-table-report ml-1 mr-1"
            variant="contained"
            color="primary"
          >
            Exportar CSV
          </Button>

          <TextField
            id="outlined-basic"
            label="Busqueda"
            variant="outlined"
            className={classes.textField}
          />

          <TableContainer component={Paper}>
            <Table className={classes.table} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell align="left">Usuario</TableCell>
                  <TableCell align="left">Nombre</TableCell>
                  <TableCell align="left">Apellido</TableCell>
                  <TableCell align="left">Monedas</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {rows.map((row) => (
                  <TableRow key={row.user}>
                    <TableCell align="left">{row.user}</TableCell>
                    <TableCell align="left">{row.name}</TableCell>
                    <TableCell align="left">{row.lastName}</TableCell>
                    <TableCell align="left">{row.points + " puntos"}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>

          <div className={classes.root}>
            <Pagination count={10} color="primary" />
          </div>
        </Grid>
      </Container>
    </main>
  );
}
