import axios from "axios";
import environment from "../../../../libs/environment";

export default {

  getLetterSoup: () =>
  axios({
    method: "GET",
    url: `${environment.motivarnosBackend}/game`,
  }),

  getLetterSoup: (number = 1, size = 5) =>
    axios({
      method: "GET",
      url: `${environment.motivarnosBackend}/game?_number=${
        number - 1
      }&_size=${size}`,
    }),

  getAllLetterSoup: (number = 1, size = 5) =>
    axios({
            method: "GET",
      url: `${environment.motivarnosBackend}/game?_number=${
        number - 1
      }&_size=${size}`,
    }),

  saveLetterSoup: (data) =>
    axios({
      method: "POST",
      url: `${environment.motivarnosBackend}/lettersoup`,
      data: data,
    }),

  updateLetterSoup: (data) =>
    axios({
      method: "PUT",
      url: `${environment.motivarnosBackend}/lettersoup`,
      data: data,
    }),

  deleteLetterSoup: (id) =>
    axios({
      method: "DELETE",
      url: `${environment.motivarnosBackend}/game/${id}`,
    }),
};
