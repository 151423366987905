import React, { useState, useEffect } from "react";
import money from "../../assets/img/money.svg";

export default function SumPoints({ type }) {
  const [sesion, setSesion] = useState(null);

  useEffect(() => {
    let sesion = JSON.parse(localStorage.getItem("sesion"));
    setSesion(sesion);
  }, []);

  return (
    <>
      {sesion && (
        <div className={type ? type : "trivia-wrapper"}>
          <div className="sum-points">
            <img src={money} alt="" /> {sesion.user.coinBalance}
          </div>
        </div>
      )}
    </>
  );
}
