import React from "react";
import { es } from "date-fns/locale";
import { withStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import Container from "@material-ui/core/Container";
import Pagination from "@material-ui/lab/Pagination";
import toastr from "toastr";
import { SpinnerCircular } from "spinners-react";
import { NotRegistersFound } from "../../common/NotRegistersFound";
import Button from "@material-ui/core/Button";
import { Link } from "react-router-dom";
import { withTranslation } from "react-i18next";
import GeneralFunctions from "../../libs/GeneralFunctions";

/********Services***** */
import AwardService from "../../Services/AwardService";

/*******Icons */
import AddIcon from "@material-ui/icons/Add";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";

import "toastr/build/toastr.min.css";

const styles = (theme) => ({
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
  },
  fixedHeight: {
    height: 240,
  },
  root: {
    "& > *": {
      marginTop: theme.spacing(2),
    },
  },
  margin: {
    margin: theme.spacing(1),
  },
  textField: {
    width: "50ch",
    marginBottom: "5px",
  },
});

class AwardsReport extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      rows: [],
      numberPages: 1,
      page: 1,
    };
  }

  componentDidMount() {
    this.getAwards();
  }

  handleChange(event, value) {
    this.setState({ page: value });
    this.getAwards(value);
  }

  /***********GENERAL FUNCTIONS************* */

  getAwards(number = 1) {
    function createData(
      company_name,
      id,
      giftItem,
      user,
      qty,
      comment,
      status,
      processed,
      trxReference,
      updated,
      created,
      isActive
    ) {
      return {
        company_name,
        id,
        giftItem,
        user,
        qty,
        comment,
        status,
        processed,
        trxReference,
        updated,
        created,
        isActive,
      };
    }

    this.setState({ loading: true });
    AwardService.getAwardsAssigned(number)
      .then((response) => {
        this.setState({ rows: [] });
        let rowAwards = [];
        response.data.content.forEach((award) =>
          rowAwards.push(
            createData(
              award.company_name,
              award.id,
              award.giftItem,
              award.user,
              award.qty,
              award.comment,
              award.status,
              award.processed,
              award.trxReference,
              award.updated,
              award.created,
              award.isActive
            )
          )
        );
        this.setState({ rows: rowAwards });
        this.setState({ numberPages: response.data.totalPages });
        this.setState({ loading: false });
      })
      .catch(() => {
        toastr.error(
          this.props.t(
            "admin.user-panel-message-error-get-users",
            "An error occurred while trying to get the users"
          )
        );
        this.setState({ loading: false });
      });
  }

  /***********CRUD FUNCTIONS************* */

  render() {
    const { loading, rows, numberPages, page } = this.state;
    const { classes, t, i18n } = this.props;

    return (
      <main className={classes.content}>
        <div className={classes.appBarSpacer} />
        <Container maxWidth="lg" className={classes.container}>
          {loading && (
            <div className="div-spinner">
              <Grid container spacing={1}>
                <SpinnerCircular size={90} />
              </Grid>
            </div>
          )}
          {!loading &&
            (rows && rows.length > 0 ? (
              <Grid container spacing={1}>
                <Link to="/awards/panel">
                  <Button
                    className="button-table mr-1"
                    variant="contained"
                    color="secondary"
                  >
                    <ArrowBackIosIcon className="mr-1" />
                    {t("back.label", "Back")}
                  </Button>
                </Link>

                <Button
                  className="button-table mr-1"
                  variant="contained"
                  color="secondary"
                >
                  <AddIcon />
                  {t("report.label", "Report")}
                </Button>

                <TableContainer component={Paper}>
                  <Table className={classes.table} aria-label="simple table">
                    <TableHead>
                      <TableRow>
                        <TableCell align="left">
                          {t("user.label", "User")}
                        </TableCell>
                        <TableCell align="left">
                          {t("email.label", "Email")}
                        </TableCell>
                        <TableCell align="left">
                          {" "}
                          {t("menu.award-panel-dialog-title", "Award")}
                        </TableCell>

                        <TableCell align="left">
                          {t("points.label", "Points")}
                        </TableCell>
                        <TableCell align="left">
                          {t("date.label", "Date")}
                        </TableCell>
                        <TableCell align="left">
                          {t("menu.badge-report-message-table", "Message")}
                        </TableCell>
                        <TableCell align="left">
                          {t(
                            "admin.header-dropdown-view-conditions-table-state",
                            "State"
                          )}
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {rows.map((row) => (
                        <TableRow key={row.id}>
                          <TableCell align="left">
                            {row.user.username}
                          </TableCell>

                          <TableCell align="left">
                            {row.user.email && row.user.email !== ""
                              ? row.user.email
                              : "N/A"}
                          </TableCell>
                          <TableCell align="left">
                            {row.giftItem.name}
                          </TableCell>

                          <TableCell align="left">
                            {" "}
                            {row.giftItem.price}
                          </TableCell>

                          <TableCell align="left">
                            {row.created
                              ? GeneralFunctions.formatNewDate(
                                  row.created,
                                  es
                                )
                              : "N/A"}
                          </TableCell>

                          <TableCell align="left">
                            {row.comment
                              ? row.comment
                              : t(
                                  "menu.badge-report-notComment-table",
                                  "Without comment"
                                )}
                          </TableCell>

                          <TableCell align="left">
                            {row.status === "COMPLETED" &&
                              t(
                                "menu.award-report-table-completed",
                                "Completed"
                              )}
                            {row.status === "VOIDED" &&
                              t("menu.award-report-table-rejected", "Rejected")}
                            {row.status === "IN_PROGRESS" &&
                              t(
                                "menu.award-report-table-in-progress",
                                "In progress"
                              )}
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
                <div className={classes.root}>
                  <Pagination
                    count={numberPages}
                    page={page}
                    color="primary"
                    onChange={(event, value) => this.handleChange(event, value)}
                  />
                </div>
              </Grid>
            ) : (
              <>
                <Grid container spacing={1}>
                  <Link to="/awards/panel">
                    <Button
                      className="button-table mr-1"
                      variant="contained"
                      color="secondary"
                    >
                      <ArrowBackIosIcon className="mr-1" />
                      {t("back.label", "Back")}
                    </Button>
                  </Link>

                  <Button
                    className="button-table mr-1"
                    variant="contained"
                    color="secondary"
                  >
                    <AddIcon />
                    {t("report.label", "Report")}
                  </Button>
                  <Grid item xs={12} md={12} lg={12}>
                    <NotRegistersFound />
                  </Grid>
                </Grid>
              </>
            ))}
        </Container>
      </main>
    );
  }
}

export default withTranslation()(
  withStyles(styles, { withTheme: true })(AwardsReport)
);
