import axios from "axios";
import environment from "../../../../libs/environment";

export default {
  getBets: (number = 1, size = 5) =>
    axios({
      method: "GET",
      url: `${environment.motivarnosBackend}/bet?_number=${
        number - 1
      }&_size=${size}`,
    }),
  GetMainRanking: () =>
    axios({
      method: "GET",
      url: `${environment.motivarnosBackend}/account`,
    }),
  UpdateRanking: ({ id, sumary, amountCoin, amountPoint, userId }) =>
    axios({
      method: "POST",
      url:
        `${environment.motivarnosBackend}/accounttransaction?createdbyb.id=` +
        id,
      data: {
        sumary: sumary + "",
        amountCoin: amountCoin + "",
        amountPoint: amountPoint + "",
        status: "COMPLETED",
        user: {
          id: userId + "",
        },
      },
    }),
};
