import React from "react";
import { withStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import Container from "@material-ui/core/Container";
import Button from "@material-ui/core/Button";
import TablePagination from "@material-ui/core/TablePagination";

import toastr from "toastr";
import { SpinnerCircular } from "spinners-react";
import TextField from "@material-ui/core/TextField";
import Delete from "../../common/Dialogs/Delete";
import Imports from "../../common/Dialogs/Imports";
import { Link } from "react-router-dom";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";

import {
  teamsRequest,
  usersRequest,
  secondaryTeamsRequest,
} from "../../../../../actions/generalActions";

import {
  getMetricsRequest,
  getMetricsFilteredRequest,
  deleteMetricRequest,
} from "../../../../../actions/metricsActions";

/********Dialogs***** */

import { NotRegistersFound } from "../../common/NotRegistersFound";
import MetricImport from "../../common/Dialogs/MetricImport";

/*****Componentes */

/********Services***** */
import ImportMetricService from "../../Services/ImportMetricService";
/*********Icons********* */
import MetricDialog from "../../common/Dialogs/Metric";
import ShowDetailsDialog from "../../common/Dialogs/ShowDetails";
import BackupIcon from "@material-ui/icons/Backup";
import ViewListIcon from "@material-ui/icons/ViewList";

import "toastr/build/toastr.min.css";

const styles = (theme) => ({
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
  },
  fixedHeight: {
    height: 240,
  },
  root: {
    "& > *": {
      marginTop: theme.spacing(2),
    },
  },
  margin: {
    margin: theme.spacing(1),
  },
  textField: {
    width: "50ch",
    marginBottom: "5px",
  },
});

class Metrics extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      metricImports: "",
      viewImportMetrics: false,
      filter: "",
      page: 0,
      rowsPerPage: 5,
    };
  }

  componentDidMount() {
    this.props.getMetricsRequest({ number: 1, reload: false });

    if (!this.props.generalReducer.users) {
      this.props.usersRequest();
    }
    if (!this.props.generalReducer.teams) {
      this.props.teamsRequest();
    }
    if (!this.props.generalReducer.secondary_teams) {
      this.props.secondaryTeamsRequest();
    }
  }

  handleChange(event, value) {
    this.setState({ page: value });

    if (this.filter !== "" && this.filter) {
      this.props.getMetricsFilteredRequest({
        number: value + 1,
        filter: this.filter,
      });
    } else {
      this.props.getMetricsRequest({ number: value + 1, reload: false });
    }
  }

  /***********Pre-import CSV*************** */
  handleImport(e) {
    this.setState({ loading: true });
    const formData = new FormData();
    formData.append("file", e.target.files[0]);
    ImportMetricService.setImportMetric(formData)
      .then((response) => {
        this.setState({ metricImports: response });
        this.setState({ loading: false });
        this.setState({ viewImportMetrics: true });
      })
      .catch((error) => {
        if (error.response.status === 422) {
          toastr.error(error.response.data.message);
        } else {
          toastr.error(
            this.props.t(
              "menu.metric-panel-message-error-import",
              "An error occurred while trying to pre-import the CSV"
            )
          );
        }
        this.setState({ loading: false });
      });
  }

  /***********GENERAL FUNCTIONS************* */

  updateInformationUpdated() {
    this.setState({ viewImportMetrics: false });
    this.props.getMetricsRequest({ number: 1, reload: true });
  }

  /***********CRUD FUNCTIONS************* */

  async deleteMetric(id) {
    let status;
    await this.props.deleteMetricRequest(id).then((r) => (status = r));

    if (status === "SUCCESS") {
      toastr.success(
        this.props.t(
          "menu.metric-panel-message-success-delete",
          "Metric removed successfully"
        )
      );
      return;
    }

    if (status.error && status.error.status === 400) {
      toastr.error(
        this.props.t(
          "menu.metric-panel-message-error-delete-one",
          "This metric is being used and cannot be removed"
        )
      );
    } else {
      toastr.error(
        this.props.t(
          "menu.metric-panel-message-error-delete-two",
          "An error occurred while removing the metric"
        )
      );
    }
  }

  filterValue(number, value) {
    this.setState({ filter: value });

    if (value && value.length > 2) {
      this.props.getMetricsFilteredRequest({
        number: number,
        filter: value,
      });
    }

    if (value === "") {
      this.props.getMetricsRequest({ number: 1, reload: true });
    }
  }

  render() {
    const {
      loading,
      page,
      rowsPerPage,
      viewImportMetrics,
      metricImports,
      filter,
    } = this.state;
    const { classes, t } = this.props;

    return (
      <main className={classes.content}>
        <div className={classes.appBarSpacer} />
        <Container maxWidth="lg" className={classes.container}>
          {(loading ||
            this.props.metricsReducer.metrics_charging ||
            this.props.generalReducer.teams_charging ||
            this.props.generalReducer.secondary_teams_charging ||
            this.props.generalReducer.users_charging) && (
            <div className="div-spinner">
              <Grid container spacing={1}>
                <SpinnerCircular size={90} />
              </Grid>
            </div>
          )}
          {!viewImportMetrics ? (
            !loading &&
            !this.props.metricsReducer.metrics_charging &&
            !this.props.generalReducer.teams_charging &&
            !this.props.generalReducer.secondary_teams_charging &&
            !this.props.generalReducer.users_charging &&
            (this.props.metricsReducer.metrics &&
            this.props.metricsReducer.metrics.length > 0 ? (
              <Grid container spacing={1}>
                {this.props.generalReducer.teams &&
                  this.props.generalReducer.secondary_teams &&
                  this.props.generalReducer.users && (
                    <MetricDialog
                      rowSecondaryTeams={
                        this.props.generalReducer.secondary_teams.content
                      }
                      rowTeams={this.props.generalReducer.teams.content}
                      rowsUsers={this.props.generalReducer.users.content}
                    />
                  )}

                <Button
                  className="button-table mr-1"
                  variant="contained"
                  color="primary"
                >
                  <label htmlFor="avatar" className="d-flex">
                    <BackupIcon className="mr-1" />
                    {t("menu.metric-panel-import", "Import")}
                  </label>
                  <input
                    type="file"
                    className="fileInputUser"
                    id="avatar"
                    name="avatar"
                    onChange={(e) => this.handleImport(e)}
                  />
                </Button>

                <Link to="/metrics/report" className="link-custom">
                  <Button
                    className="button-table mr-1"
                    variant="contained"
                    color="secondary"
                  >
                    <ViewListIcon className="mr-1" />
                    {t("report.label", "Report")}
                  </Button>
                </Link>

                <Imports />

                <TextField
                  id="outlined-basic"
                  label={t("menu.metric-panel-search", "Search")}
                  variant="outlined"
                  value={filter}
                  name="filter"
                  className={classes.textField}
                  onChange={(e) => this.filterValue(1, e.target.value)}
                />

                <TableContainer component={Paper}>
                  <Table className={classes.table} aria-label="simple table">
                    <TableHead>
                      <TableRow>
                        <TableCell align="left">
                          {t("menu.metric-panel-title", "Metric")}
                        </TableCell>
                        <TableCell align="left">
                          {t(
                            "menu.trivia-panel-table-description",
                            "Description"
                          )}
                        </TableCell>
                        <TableCell align="left">
                          {t(
                            "menu.metric-panel-table-variable-type",
                            "Type of variable"
                          )}
                        </TableCell>

                        <TableCell align="left">
                          {t("menu.metric-panel-table-frequency", "Frequency")}
                        </TableCell>
                        <TableCell align="left">
                          {t("points.label", "Points")}
                        </TableCell>
                        <TableCell align="left">
                          {t(
                            "menu.metric-panel-table-impact-supervisor",
                            "Impact/Supervisor"
                          )}
                        </TableCell>

                        <TableCell align="left">
                          {t(
                            "admin.header-dropdown-view-conditions-table-state",
                            "State"
                          )}
                        </TableCell>
                        <TableCell align="left">
                          {t("admin.user-panel-table-actions", "Actions")}
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {this.props.metricsReducer.metrics
                        .slice(
                          page * rowsPerPage,
                          page * rowsPerPage + rowsPerPage
                        )
                        .map((row) => (
                          <TableRow key={row.id}>
                            <TableCell align="left">
                              {row.name.length > 30
                                ? row.name.substring(0, 30) + "..."
                                : row.name}
                            </TableCell>
                            <TableCell align="left">
                              {row.description.length > 30
                                ? row.description.substring(0, 30) + "..."
                                : row.description}
                            </TableCell>
                            <TableCell align="left">
                              {row.type === "BOOLEAN" &&
                                t("menu.metric-panel-table-boolean", "Boolean")}
                              {row.type === "NUMBER" &&
                                t(
                                  "menu.trivia-panel-dialog-add-test-select-questions-number",
                                  "Number"
                                )}
                              {row.type === "TIME" &&
                                t(
                                  "menu.trivia-panel-dialog-test-analytic-time",
                                  "Time"
                                )}
                              {row.type === "PERCENT" &&
                                t(
                                  "menu.metric-panel-table-percentage",
                                  "Percentage"
                                )}

                              {row.type === "MULTIPLIER" &&
                                t(
                                  "menu.metric-panel-dialog-Multiplier",
                                  "Multiplier"
                                )}
                            </TableCell>

                            <TableCell align="left">
                              {row.frequency === "HOURLY" &&
                                t("menu.metric-panel-table-hour", "Hour")}
                              {row.frequency === "DAILY" &&
                                t("menu.metric-panel-table-daily", "Daily")}
                              {row.frequency === "WEEKLY" &&
                                t("menu.metric-panel-table-weekly", "Weekly")}
                              {row.frequency === "MONTHLY" &&
                                t("menu.metric-panel-table-mothly", "Mothly")}
                              {row.frequency === "EVENTUAL" &&
                                t(
                                  "menu.metric-panel-table-eventual",
                                  "Eventual"
                                )}
                            </TableCell>

                            <TableCell align="left">
                              {row.isPointsGranted &&
                                t(
                                  "menu.metric-panel-table-add-points",
                                  "Add points"
                                )}
                              {!row.isPointsGranted &&
                                t(
                                  "menu.metric-panel-table-not-add-points",
                                  "Does not score points"
                                )}
                            </TableCell>

                            <TableCell align="left">
                              {row.isApplyToSupervisor &&
                                t(
                                  "admin.header-dropdown-dialog-notifications-input-item-yes",
                                  "Yes"
                                )}
                              {!row.isApplyToSupervisor &&
                                t(
                                  "admin.header-dropdown-dialog-notifications-input-item-no",
                                  "No"
                                )}
                            </TableCell>

                            <TableCell align="left">
                              {row.isActive === true &&
                                t(
                                  "admin.header-dropdown-view-conditions-table-active-state",
                                  "Active"
                                )}
                              {row.isActive !== true &&
                                t(
                                  "menu.trivia-panel-table-inactive",
                                  "Inactive"
                                )}
                            </TableCell>

                            <TableCell align="left">
                              <div className="d-flex-between">
                              <ShowDetailsDialog {...row} />

                              {this.props.generalReducer.teams &&
                                this.props.generalReducer.users &&
                                this.props.generalReducer.secondary_teams && (
                                  <MetricDialog
                                    rowSecondaryTeams={
                                      this.props.generalReducer.secondary_teams
                                        .content
                                    }
                                    rowTeams={
                                      this.props.generalReducer.teams.content
                                    }
                                    rowsUsers={
                                      this.props.generalReducer.users.content
                                    }
                                    {...row}
                                    typeModal="modalEdit"
                                  />
                                )}

                              <Delete
                                deleteMetric={() => this.deleteMetric(row.id)}
                              />
                              </div>
                            </TableCell>
                          </TableRow>
                        ))}
                    </TableBody>
                  </Table>
                  <TablePagination
                    className="mt-1"
                    rowsPerPageOptions={[5]}
                    component="div"
                    count={this.props.metricsReducer.totalElements}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={(event, value) =>
                      this.handleChange(event, value)
                    }
                  />
                </TableContainer>
              </Grid>
            ) : (
              <>
                <Grid container spacing={1}>
                  {this.props.generalReducer.teams &&
                    this.props.generalReducer.users &&
                    this.props.generalReducer.secondary_teams && (
                      <MetricDialog
                        rowSecondaryTeams={
                          this.props.generalReducer.secondary_teams.content
                        }
                        rowTeams={this.props.generalReducer.teams.content}
                        rowsUsers={this.props.generalReducer.users.content}
                      />
                    )}

                  <Button
                    className="button-table mr-1"
                    variant="contained"
                    color="primary"
                  >
                    <label htmlFor="avatar">
                      {" "}
                      {t("menu.metric-panel-import", "Import")}
                    </label>
                    <input
                      type="file"
                      className="fileInputUser"
                      id="avatar"
                      name="avatar"
                      onChange={(e) => this.handleImport(e)}
                    />
                  </Button>

                  <TextField
                    id="outlined-basic"
                    label={t("menu.metric-panel-search", "Search")}
                    variant="outlined"
                    value={filter}
                    name="filter"
                    className={classes.textField}
                    onChange={(e) => this.filterValue(1, e.target.value)}
                  />
                  <Grid item xs={12} md={12} lg={12}>
                    <NotRegistersFound />
                  </Grid>
                </Grid>
              </>
            ))
          ) : (
            <>
              {this.props.generalReducer.teams &&
                this.props.generalReducer.users && (
                  <MetricImport
                    metricImports={metricImports}
                    backViewAndReload={() =>
                      this.setState({ viewImportMetrics: false })
                    }
                  />
                )}
            </>
          )}
        </Container>
      </main>
    );
  }
}

const mapStateToProps = ({ generalReducer, metricsReducer }) => ({
  generalReducer,
  metricsReducer,
});
const mapDispatchToProps = {
  teamsRequest,
  usersRequest,
  secondaryTeamsRequest,
  getMetricsRequest,
  getMetricsFilteredRequest,
  deleteMetricRequest,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(withStyles(styles, { withTheme: true })(Metrics)));
