
import { handleActions } from 'redux-actions';

import { createActions } from "redux-actions";

const {openSignUp,closeSignUp,openLogin} = createActions({
    openSignUp: () => ({}),
    closeSignUp: () => ({}),
    openLogin: () => ({})
});


var defaultState = {};

const landingReducer = handleActions({
    [openSignUp] : (state, action) => {
            return {openSignUp:true} 
    },
    [closeSignUp] : (state, action) => {
        return {openSignUp:false} 
    },
    [openLogin] : (state, action) => {
      return {openLogin:true} 
    }
},
defaultState);

const exposedLandingReducer = {
    key: "landingReducer",
    value: landingReducer,
  };

  export {exposedLandingReducer, openSignUp, openLogin,closeSignUp}