import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import Slide from "@material-ui/core/Slide";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import InputLabel from "@material-ui/core/InputLabel";
import { SpinnerCircular } from "spinners-react";
import toastr from "toastr";
import Button from "@material-ui/core/Button";
import Tooltip from "@material-ui/core/Tooltip";
import { useTranslation } from "react-i18next";
import { AppWrapper } from "../GlobalStyles";
import { connect } from "react-redux";

import FindRegistersDialog from "../../common/Dialogs/FindRegistersDialog";

/*********Icons********* */
import AddIcon from "@material-ui/icons/Add";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import EditIcon from "@material-ui/icons/Edit";

/********Services********* */
import BadgeService from "../../Services/BadgeService";
import environment from "../../../../../libs/environment";

/**********Hooks********** */
import { useFormatProps } from "../../hooks/useFormatProps";

/*********Dialog********* */
import ImageDialog from "../../common/Dialogs/ImageDialog";

/********Libs********* */

import {
  saveBadgeRequest,
  updateBadgeRequest,
} from "../../../../../actions/badgesActions";

// pick a date util library
import DateFnsUtils from "@date-io/date-fns";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
const useStyles = makeStyles((theme) => ({
  appBar: {
    position: "relative",
  },
  formControl: {
    width: "100%",
    marginTop: "1rem",
  },
  container: {
    paddingTop: theme.spacing(4),
  },
  title: {
    marginLeft: theme.spacing(2),
    color: "black",
    flex: 1,
  },
  root: {
    "& > *": {
      margin: theme.spacing(1),
    },
  },
  input: {
    display: "none",
  },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function BadgeDialog(props) {
  /***************Styles************ */
  const classes = useStyles();
  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    getContentAnchorEl: () => null,
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };
  /***************Styles************ */

  /***********General functions and constants******** */

  const [open, setOpen] = React.useState(false);
  const [loading, setLoading] = useState(false);
  const [nameError, setNameError] = useState(false);
  const [descriptionError, setDescriptionError] = useState(false);
  const [imageObject, setImageObject] = useState(null);
  const [imageObjectFromDialog, setImageObjectFromDialog] = useState(true);
  const [pointsError, setPointsError] = useState(false);
  const [isPointsGrantedError, setIsPointsGrantedError] = useState(false);
  const [automaticTypeError, setAutomaticTypeError] = useState(false);
  const [metricConfError, setMetricConfError] = useState(false);
  const [testError, setTestErrorError] = useState(false);
  const [minPointsError, setminPointsError] = useState(false);
  const [typeError, setTypeError] = useState(false);
  const [teamsError, setTeamsError] = useState(false);
  const [usersError, setUsersError] = useState(false);
  const { t } = useTranslation();

  const [isActiveError, setIsActiveError] = useState(false);
  const [isAllowPlayerAssignationError, setIsAllowPlayerAssignationError] =
    useState(false);

  const [teamsHooks, rowsHooks, secondaryTeamsHooks] = useFormatProps(
    props.rowsTeams ? props.rowsTeams : [],
    props.rowsUsers ? props.rowsUsers : [],
    props.rowSecondaryTeams ? props.rowSecondaryTeams : []
  );

  function formatPropsEdit(array) {
    let arrayFormatted = [];
    array.forEach((element) => {
      arrayFormatted.push(element.id);
    });
    return arrayFormatted;
  }

  const [
    {
      name,
      points,
      description,
      image,
      type,
      automaticType,
      teams,
      secondaryTeams,
      rowSecondaryTeams,
      users,
      metricConf,
      test,
      minPoints,
      metricOption,
      isActive,
      teamsProps,
      usersProps,
      metricsProps,
      testsProps,
      isAllowPlayerAssignation,
      typeInputPoints,
      isPointsGranted,
    },
    setState,
  ] = useState({
    name: props.name ? props.name : "",
    points: props.points ? props.points : "",
    image: props.image ? props.image : "",
    description: props.description ? props.description : "",
    type: props.type ? props.type : "AUTOMATIC",
    automaticType: props.automaticType ? props.automaticType : "",
    teams: formatPropsEdit(props.teams ? props.teams : []),
    users: formatPropsEdit(props.users ? props.users : []),
    metricConf: props.metricConf ? props.metricConf.id : "",
    test: props.trivia ? props.trivia.id : "",
    metricOption: props.metricOption ? props.metricOption : false,
    minPoints: props.minPoints ? parseFloat(props.minPoints) : "",

    isActive: props.isActive ? props.isActive : false,
    teamsProps: teamsHooks && teamsHooks.length > 0 ? teamsHooks : [],
    usersProps: rowsHooks && rowsHooks.length > 0 ? rowsHooks : [],
    metricsProps:
      props.rowsMetrics && props.rowsMetrics.length > 0
        ? props.rowsMetrics
        : [],
    testsProps:
      props.rowsTests && props.rowsTests.length > 0 ? props.rowsTests : [],
    isAllowPlayerAssignation: props.isAllowPlayerAssignation
      ? props.isAllowPlayerAssignation
      : false,
    typeInputPoints: props.metricConf ? props.metricConf.type : "NUMBER",
    isPointsGranted: props.isPointsGranted ? props.isPointsGranted : false,
    secondaryTeams: formatPropsEdit(
      props.secondaryTeams ? props.secondaryTeams : []
    ),
    rowSecondaryTeams:
      secondaryTeamsHooks && secondaryTeamsHooks.length > 0
        ? secondaryTeamsHooks
        : [],
  });

  const handleClickOpen = () => {
    setOriginalState(true);

    setOpen(true);
  };

  function handleChange({ target: { name, value } }) {
    setState((prevState) => ({ ...prevState, [name]: value }));

    if (name === "type") {
      setState((prevState) => ({
        ...prevState,
        automaticType: props.automaticType ? props.automaticType : "BY_POINTS",
        typeInputPoints: "NUMBER",
        minPoints: props.minPoints ? props.minPoints : "",
      }));
    }

    if (name === "automaticType") {
      setState((prevState) => ({
        ...prevState,
        metricConf: props.metricConf ? props.metricConf : "",
        test: props.test ? props.test : "",
        minPoints: props.minPoints ? props.minPoints : "",
        typeInputPoints: "NUMBER",
      }));
    }

    let arrayMetric = props.rowsMetrics.filter((metric) => metric.id === value);
    if (name === "metricConf") {
      if (arrayMetric && arrayMetric.length > 0) {
        setState((prevState) => ({
          ...prevState,
          typeInputPoints:
            arrayMetric && arrayMetric.length > 0
              ? props.rowsMetrics && props.rowsMetrics.length > 0
                ? arrayMetric[0].type
                : "NUMBER"
              : "NUMBER",
          minPoints: "",
        }));
      } else {
        setState((prevState) => ({
          ...prevState,
          typeInputPoints: "NUMBER",
          minPoints: "",
        }));
      }
    }
  }

  function setSecondaryTeams(secondaryTeams) {
    setState((prevState) => ({ ...prevState, 'secondaryTeams': secondaryTeams }));
  }

  function setTeams(teams) {
    setState((prevState) => ({ ...prevState, 'teams': teams }));
  }

  function setUsers(users) {
    setState((prevState) => ({ ...prevState, 'users': users }));
  }

  function setName(option) {
    return (option.name == '' || option.name  == null) ? t(
      "admin.user-panel-user-dialog-input-select-without-name",
      "Without name") : option.name;
  }

  function handleChangeImage(e) {
    if (e.target.files && e.target.files[0]) {
      setState((prevState) => ({ ...prevState, image: e.target.files[0] }));
      setImageObject(e.target.files[0]);
      setImageObjectFromDialog(false);
      readURL(e);
    }
  }

  function readURL(e) {
    if (e.target.files && e.target.files[0]) {
      var reader = new FileReader();

      reader.onload = function (e) {
        document
          .getElementById("img-designed")
          .setAttribute("src", e.target.result);
      };
      reader.readAsDataURL(e.target.files[0]);
    }
  }

  function setImageDialog(e) {
    setImageObjectFromDialog(e);
    setState((prevState) => ({ ...prevState, image: e }));
  }

  const handleClose = () => {
    setOriginalState();
  };

  function setOriginalState(notAll = false) {
    setState((prevState) => ({
      ...prevState,
      name: props.name ? props.name : "",
      image: props.image ? props.image : "",
      points: props.points ? props.points : "",
      description: props.description ? props.description : "",
      type: props.type ? props.type : "AUTOMATIC",
      automaticType: props.automaticType ? props.automaticType : "",
      metricConf: props.metricConf ? props.metricConf.id : "",
      metricOption: props.metricOption ? props.metricOption : false,
      test: props.trivia ? props.trivia.id : "",
      isActive: props.isActive ? props.isActive : false,
      minPoints: props.minPoints ? props.minPoints : "",
      isAllowPlayerAssignation: props.isAllowPlayerAssignation
        ? props.isAllowPlayerAssignation
        : false,
      typeInputPoints: props.metricConf ? props.metricConf.type : "NUMBER",
      isPointsGranted: props.isPointsGranted ? props.isPointsGranted : false,
    }));

    if (!notAll) {
      setImageObject(null);
      setOpen(false);
    }
  }

  useEffect(() => {
    setOriginalState(true);
    // eslint-disable-next-line
  }, [props]);
  /***********General functions and constants******** */

  /***************************CRUD functions*************** */
  async function onFormSubmit() {
    setNameError(false);
    setIsPointsGrantedError(false);
    setDescriptionError(false);
    setPointsError(false);
    setAutomaticTypeError(false);
    setminPointsError(false);
    setTypeError(false);
    setTeamsError(false);
    setUsersError(false);
    setIsActiveError(false);
    setMetricConfError(false);
    setTestErrorError(false);

    if (name === "") {
      setNameError(true);
      toastr.error(
        t(
          "menu.trivia-panel-dialog-add-test-message-error-name",
          "The name is required"
        )
      );
      return;
    }

    if (isPointsGranted === "") {
      setIsPointsGrantedError(true);
      toastr.error(
        t(
          "menu.trivia-panel-dialog-add-test-message-error-score",
          "The score is required"
        )
      );
      return;
    }

    if (isPointsGranted) {
      if (points === "") {
        setPointsError(true);
        toastr.error(
          t(
            "menu.badge-panel-dialog-message-error-points-required",
            "Points are required"
          )
        );
        return;
      }
    }

    if (description === "") {
      setDescriptionError(true);
      toastr.error(
        t(
          "menu.trivia-panel-dialog-add-test-message-error-description",
          "Description is required"
        )
      );
      return;
    } else if (description.length > 650) {
      setDescriptionError(true);
      toastr.error(
        t(
        "commons.description-max-length",
        "La descipción no puede tener más de 650 caracteres."
        )
      );
      return;
    }
    if (props.typeModal !== "modalEdit") {
      if (image === "") {
        toastr.error(
          t(
            "menu.badge-panel-dialog-message-error-image-required",
            "Image is required"
          )
        );
        return;
      }
    }

    if (type === "") {
      setTypeError(true);
      toastr.error(
        t(
          "menu.badge-panel-dialog-message-error-stock-required",
          "Stock is required"
        )
      );
      return;
    }

    if (type === "AUTOMATIC") {
      if (automaticType === "") {
        setAutomaticTypeError(true);
        toastr.error(
          t(
            "menu.badge-panel-dialog-message-error-subtype-required",
            "The subtype is required"
          )
        );
        return;
      }

      if (automaticType === "BY_METRIC") {
        if (metricConf === "") {
          setMetricConfError(true);
          toastr.error(
            t(
              "menu.badge-panel-dialog-message-error-metric-required",
              "The metric is required"
            )
          );
          return;
        }
      } else if (automaticType === "BY_TRIVIA") {
        if (test === "") {
          setTestErrorError(true);
          toastr.error(
            t(
              "menu.badge-panel-dialog-message-error-trivia-required",
              "Trivia is required"
            )
          );
          return;
        }
      }

      if (minPoints === "") {
        setminPointsError(true);
        toastr.error(
          t(
            "menu.badge-panel-dialog-message-error-minimum-points-required",
            "Minimum points are required"
          )
        );
        return;
      }
    }

    if (
      (secondaryTeams === "" ||
        secondaryTeams.length === 0 ||
        !secondaryTeams) &&
      (teams === "" || teams.length === 0 || !teams) &&
      (users === "" || users.length === 0 || !users)
    ) {
      setTeamsError(true);
      setUsersError(true);

      toastr.error(
        t(
          "menu.badge-panel-dialog-message-error-groups-required",
          "You must add groups, players or secondary teams"
        )
      );
      return;
    }

    if (isActive === "") {
      setIsActiveError(true);
      toastr.error(
        t(
          "menu.trivia-panel-dialog-add-test-message-error-active",
          "You must choose if it is active or not"
        )
      );
      return;
    }

    if (isAllowPlayerAssignation === "") {
      setIsAllowPlayerAssignationError(true);
      toastr.error(
        t(
          "menu.badge-panel-dialog-message-error-assignment-required",
          "You must choose an assignment type"
        )
      );
      return;
    }

    /***********Format variables****** */
    let teamsFormatted = [];
    teams.forEach((element) => {
      teamsFormatted.push({
        id: element,
      });
    });

    let usersFormatted = [];
    users.forEach((element) => {
      usersFormatted.push({
        id: element,
      });
    });

    let secondaryTeamsFormatted = [];
    secondaryTeams.forEach((element) => {
      secondaryTeamsFormatted.push({
        id: element,
      });
    });

    setLoading(true);

    let urlImage =
      image && image !== "" && !imageObjectFromDialog
        ? !imageObjectFromDialog
          ? ""
          : imageObjectFromDialog
        : image;

    if (image && image !== "") {
      if (imageObject && !imageObjectFromDialog) {
        const formData = new FormData();
        formData.append("file", imageObject);
        await BadgeService.saveImageBadge(formData)
          .then((response) => {
            urlImage = response.data.url;
            toastr.success(
              t(
                "menu.trivia-panel-dialog-add-test-message-success-add-image",
                "Image added successfully"
              )
            );
          })
          .catch(() => {
            toastr.error(
              t(
                "menu.trivia-panel-dialog-add-test-message-error-add-image",
                "An error occurred while trying to add the image"
              )
            );
          });
      }
    }

    var json;
    if (
      type === "AUTOMATIC" &&
      typeInputPoints !== "NUMBER" &&
      automaticType !== "BY_TRIVIA"
    ) {
      json = {
        name: name,
        points: isPointsGranted ? parseFloat(points) : 0,
        isPointsGranted: isPointsGranted,
        description: description,
        image: urlImage,
        metricConf: (metricConf && automaticType === "BY_METRIC") ? { id: metricConf } : null,
        trivia: null,
        type: type,
        automaticType: automaticType === "" ? null : automaticType,
        metricValue: minPoints === "" ? null : minPoints,
        teams: teamsFormatted,
        users: usersFormatted,
        secondaryTeams: secondaryTeamsFormatted,

        isActive: isActive,
        isAllowPlayerAssignation: isAllowPlayerAssignation,
      };
    } else {
      json = {
        name: name,
        points: isPointsGranted ? parseFloat(points) : 0,
        isPointsGranted: isPointsGranted,
        description: description,
        image: urlImage,
        metricConf: (metricConf && automaticType === "BY_METRIC") ? { id: metricConf } : null,
        trivia: (test && automaticType === "BY_TRIVIA")? { id: test } : null,
        type: type,
        automaticType: automaticType === "" ? null : automaticType,
        minPoints: minPoints ? minPoints : null,
        teams: teamsFormatted,
        users: usersFormatted,
        secondaryTeams: secondaryTeamsFormatted,

        isActive: isActive,
        isAllowPlayerAssignation: isAllowPlayerAssignation,
      };
    }

    let status;
    if (props.typeModal === "modalEdit") {
      await props
        .updateBadgeRequest({ ...json, id: props.id })
        .then((r) => (status = r));
    } else {
      await props.saveBadgeRequest(json).then((r) => (status = r));
    }
    if (status.status === "ERROR") {
      if (status.error && status.error.status === 422) {
        toastr.error(status.error.data.message);
      } else {
        toastr.error(
          t(
            "menu.badge-panel-dialog-message-error-save",
            "An error occurred while trying to save the badge"
          )
        );
      }
      setLoading(false);
    } else {
      toastr.success(
        t(
          "menu.badge-panel-dialog-message-success-save",
          "Badge saved successfully"
        )
      );
      setLoading(false);
      handleClose();
    }
  }
  return (
    <>
      {props.typeModal === "modalEdit" ? (
        <Tooltip title={t("admin.actions-edit", "Edit")}>
          <EditIcon
            fontSize="small"
            onClick={handleClickOpen}
            className="cursor-pointer"
          />
        </Tooltip>
      ) : (
        <Button
          className="button-table mr-1"
          variant="contained"
          color="secondary"
          onClick={handleClickOpen}
        >
          <AddIcon />
          {t("menu.badge-panel-dialog-title", "Badge")}
        </Button>
      )}

      <Dialog
        fullScreen
        open={open}
        onClose={handleClose}
        TransitionComponent={Transition}
      >
        <AppWrapper>
          <AppBar className={classes.appBar}>
            <Toolbar>
              <IconButton edge="start" onClick={handleClose} aria-label="close">
                <CloseIcon />
              </IconButton>
              <Typography variant="h6" className={classes.title}>
                Nueva insignia
              </Typography>
            </Toolbar>
          </AppBar>

          {loading && (
            <div className="div-spinner-modal">
              <Container maxWidth="lg" className={classes.container}>
                {" "}
                <Grid container spacing={1}>
                  <SpinnerCircular size={90} />
                </Grid>
              </Container>
            </div>
          )}

          {!loading && (
            <Container maxWidth="lg" className={classes.container}>
              <Grid container spacing={1}>
                <Grid item xs={12} md={6} lg={6}>
                  <TextField
                    onChange={handleChange}
                    error={nameError}
                    value={name}
                    name="name"
                    id="name"
                    label={t(
                      "menu.badge-panel-dialog-badge-name-input",
                      "Badge Name"
                    )}
                    variant="outlined"
                    className="mt-2"
                    fullWidth
                  />
                </Grid>

                <Grid item xs={12} md={6} lg={6}>
                  <FormControl
                    variant="outlined"
                    className={classes.formControl}
                  >
                    <InputLabel id="isPointsGranted-select-outlined-label">
                      {t("points.label", "Points")}
                    </InputLabel>
                    <Select
                      onChange={handleChange}
                      error={isPointsGrantedError}
                      value={isPointsGranted}
                      labelId="isPointsGranted"
                      id="isPointsGranted"
                      name="isPointsGranted"
                      label={t("points.label", "Points")}
                    >
                      <MenuItem value={true}>
                        {t("menu.metric-panel-table-add-points", "Add points")}{" "}
                      </MenuItem>
                      <MenuItem value={false}>
                        {t(
                          "menu.metric-panel-table-not-add-points",
                          "Does not score points"
                        )}
                      </MenuItem>
                    </Select>
                  </FormControl>
                </Grid>

                {isPointsGranted && (
                  <Grid item xs={12} md={6} lg={6}>
                    <TextField
                      onChange={handleChange}
                      error={pointsError}
                      value={points}
                      name="points"
                      id="points"
                      label={t(
                        "menu.badge-panel-dialog-badge-points-input",
                        "Badge Points"
                      )}
                      variant="outlined"
                      className="mt-2"
                      type="number"
                      fullWidth
                    />
                  </Grid>
                )}

                <Grid item xs={12} md={6} lg={6}>
                  <TextField
                    onChange={handleChange}
                    error={descriptionError}
                    value={description}
                    name="description"
                    id="description"
                    label={t(
                      "menu.badge-panel-dialog-badge-description-input",
                      "Badge Description"
                    ) + ` (${(description? description.length: '0')}/650)`}
                    variant="outlined"
                    className="mt-2"
                    fullWidth
                    inputProps={{ maxLength: 650 }}
                  />
                </Grid>

                <Grid
                  item
                  xs={12}
                  md={6}
                  lg={6}
                  className="padding-custom-button"
                >
                  <Grid container spacing={1}>
                    <Grid item xs={5} md={5} lg={5}>
                      <div className="h-100">
                        <input
                          accept="image/*"
                          className={classes.input}
                          id="contained-button-file"
                          type="file"
                          onChange={(e) => handleChangeImage(e)}
                        />
                        <label htmlFor="contained-button-file">
                          <Button
                            variant="contained"
                            color="primary"
                            component="span"
                            className="button-table h-100 w-100"
                          >
                            {image === ""
                              ? t(
                                  "menu.trivia-panel-dialog-add-test-input-image-upload",
                                  "Upload image"
                                )
                              : t(
                                  "menu.trivia-panel-dialog-add-test-input-image-change",
                                  "Change image"
                                )}
                          </Button>
                        </label>
                      </div>
                    </Grid>

                    <Grid item xs={5} md={5} lg={5}>
                      <ImageDialog setImageDialog={(e) => setImageDialog(e)} />
                    </Grid>
                    <Grid
                      item
                      xs={2}
                      md={2}
                      lg={2}
                      className="div-img-designed"
                    >
                      {image && image !== "" && !imageObjectFromDialog && (
                        <img
                          id="img-designed"
                          className="img-predesigned"
                          src=""
                          alt="Logo"
                        />
                      )}
                      {image && image !== "" && imageObjectFromDialog && (
                        <img
                          id="img-designed"
                          className="img-predesigned"
                          src={`${environment.motivarnosBackend}${image}`}
                          alt="Logo"
                        />
                      )}
                    </Grid>
                  </Grid>
                </Grid>

                <Grid item xs={12} md={6} lg={6}>
                  <FormControl
                    variant="outlined"
                    className={classes.formControl}
                  >
                    <InputLabel id="type-select-outlined-label">
                      {t(
                        "menu.badge-panel-dialog-delivey-method-input",
                        "Delivery method"
                      )}
                    </InputLabel>
                    <Select
                      onChange={handleChange}
                      error={typeError}
                      value={type}
                      labelId="type"
                      id="type"
                      name="type"
                      label={t(
                        "menu.badge-panel-dialog-delivey-method-input",
                        "Delivery method"
                      )}
                    >
                      <MenuItem value={"MANUAL"}>
                        {t(
                          "menu.badge-panel-dialog-delivey-method-handbook",
                          "Handbook"
                        )}{" "}
                      </MenuItem>
                      <MenuItem value={"AUTOMATIC"}>
                        {t(
                          "menu.badge-panel-dialog-delivey-method-automatic",
                          "Automatic"
                        )}{" "}
                      </MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                {/*************STOCK************* */}
                {type === "AUTOMATIC" && (
                  <Grid item xs={12} md={6} lg={6}>
                    <FormControl
                      variant="outlined"
                      className={classes.formControl}
                    >
                      <InputLabel id="automaticType-select-outlined-label">
                        {t("menu.badge-panel-dialog-subtype", "Subtype")}
                      </InputLabel>
                      <Select
                        onChange={handleChange}
                        error={automaticTypeError}
                        value={automaticType}
                        labelId="automaticType"
                        id="automaticType"
                        name="automaticType"
                        label={t("menu.badge-panel-dialog-subtype", "Subtype")}
                      >
                        <MenuItem value={"BY_POINTS"}>
                          {t(
                            "menu.badge-panel-dialog-subtype-badge-points",
                            "Badge for points"
                          )}
                        </MenuItem>
                        <MenuItem value={"BY_METRIC"}>
                          {t(
                            "menu.badge-panel-dialog-subtype-metric-badge",
                            "Metric Badge"
                          )}
                        </MenuItem>
                        <MenuItem value={"BY_TRIVIA"}>
                          {t(
                            "menu.badge-panel-dialog-subtype-badge-trivia",
                            "Badge for trivia"
                          )}
                        </MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                )}

                {type === "AUTOMATIC" && automaticType === "BY_METRIC" && (
                  <Grid item xs={12} md={6} lg={6}>
                    <FormControl
                      variant="outlined"
                      className={classes.formControl}
                    >
                      <InputLabel id="metricConf-select-outlined-label">
                        {t("menu.badge-panel-dialog-metrics", "Metrics")}
                      </InputLabel>
                      <Select
                        labelId="metricConf-mutiple-name-label"
                        id="metricConf"
                        name="metricConf"
                        label={t("menu.badge-panel-dialog-metrics", "Metrics")}
                        value={metricConf}
                        error={metricConfError}
                        onChange={handleChange}
                        MenuProps={MenuProps}
                      >
                        {metricsProps.map((rowMetric) => (
                          <MenuItem key={rowMetric.id} value={rowMetric.id}>
                            {rowMetric.name
                              ? rowMetric.name
                              : t(
                                  "admin.user-panel-user-dialog-input-select-without-name",
                                  "Without name"
                                )}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                )}

                {type === "AUTOMATIC" && automaticType === "BY_TRIVIA" && (
                  <Grid item xs={12} md={6} lg={6}>
                    <FormControl
                      variant="outlined"
                      className={classes.formControl}
                    >
                      <InputLabel id="test-select-outlined-label">
                        {t("menu.rigth-list-item-trivia", "Trivia")}
                      </InputLabel>
                      <Select
                        labelId="test-mutiple-name-label"
                        id="test"
                        name="test"
                        label={t("menu.rigth-list-item-trivia", "Trivia")}
                        value={test}
                        error={testError}
                        onChange={handleChange}
                        MenuProps={MenuProps}
                      >
                        {testsProps.map((rowTest) => (
                          <MenuItem key={rowTest.id} value={rowTest.id}>
                            {rowTest.name
                              ? rowTest.name
                              : t(
                                  "admin.user-panel-user-dialog-input-select-without-name",
                                  "Without name"
                                )}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                )}

                {(type === "AUTOMATIC" || automaticType === "BY_TRIVIA") && (
                  <Grid item xs={12} md={6} lg={6}>
                    <TextField
                      onChange={handleChange}
                      error={minPointsError}
                      value={minPoints}
                      name="minPoints"
                      id="minPoints"
                      type="number"
                      label={t(
                        "menu.badge-panel-dialog-minimum-points",
                        "Minimum points"
                      )}
                      variant="outlined"
                      className="mt-2"
                      fullWidth
                    />
                  </Grid>
                )}

                {type === "AUTOMATIC" &&
                  typeInputPoints === "BOOLEAN" &&
                  automaticType !== "BY_TRIVIA" && (
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      <Grid item xs={12} md={6} lg={6}>
                        <FormControl
                          variant="outlined"
                          className={classes.formControl}
                        >
                          <InputLabel id="metricOption-select-outlined-label">
                            {t(
                              "menu.badge-panel-dialog-metric-option",
                              "Metric option"
                            )}
                          </InputLabel>
                          <Select
                            onChange={handleChange}
                            value={metricOption}
                            labelId="metricOption"
                            id="metricOption"
                            name="metricOption"
                            label={t(
                              "menu.badge-panel-dialog-metric-option",
                              "Metric option"
                            )}
                          >
                            <MenuItem value={true}>
                              {t(
                                "admin.header-dropdown-dialog-notifications-input-item-yes",
                                "Yes"
                              )}{" "}
                            </MenuItem>
                            <MenuItem value={false}>
                              {t(
                                "admin.header-dropdown-dialog-notifications-input-item-no",
                                "No"
                              )}
                            </MenuItem>
                          </Select>
                        </FormControl>
                      </Grid>
                    </MuiPickersUtilsProvider>
                  )}

                {/*************STOCK************* */}

                {rowSecondaryTeams && rowSecondaryTeams.length > 0 && (
                  <FindRegistersDialog
                    setValue={setSecondaryTeams}
                    setName={setName}
                    label={t(
                      "admin.header-dropdown-dialog-secondary-teams-input",
                      "Secondary Teams"
                    )}
                    rowsSelected={secondaryTeams}
                    rows={rowSecondaryTeams}
                  />
                )}

                {teamsProps && teamsProps.length > 0 && (
                  <FindRegistersDialog
                    setValue={setTeams}
                    setName={setName}
                    label={t(
                      "admin.user-panel-user-dialog-input-groups",
                      "Groups"
                    )}
                    rowsSelected={teams}
                    rows={teamsProps}
                  />
                )}

                {usersProps && usersProps.length > 0 && (
                  <FindRegistersDialog
                    setValue={setUsers}
                    label={t(
                      "menu.trivia-panel-dialog-add-test-select-players",
                      "Players"
                    )}
                    rowsSelected={users}
                    rows={usersProps}
                  />
                )}
                
                <Grid item xs={12} md={6} lg={6}>
                  <FormControl
                    variant="outlined"
                    className={classes.formControl}
                  >
                    <InputLabel id="isActive-select-outlined-label">
                      {t(
                        "admin.header-dropdown-view-conditions-table-state",
                        "State"
                      )}
                    </InputLabel>
                    <Select
                      onChange={handleChange}
                      error={isActiveError}
                      value={isActive}
                      labelId="isActive"
                      id="isActive"
                      name="isActive"
                      label={t(
                        "admin.header-dropdown-view-conditions-table-state",
                        "State"
                      )}
                    >
                      <MenuItem value={true}>
                        {t(
                          "admin.header-dropdown-view-conditions-table-active-state",
                          "Active"
                        )}{" "}
                      </MenuItem>
                      <MenuItem value={false}>
                        {t(
                          "admin.header-dropdown-view-conditions-table-blocked-state",
                          "Blocked"
                        )}
                      </MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} md={6} lg={6}>
                  <FormControl
                    variant="outlined"
                    className={classes.formControl}
                  >
                    <InputLabel id="isActive-select-outlined-label">
                      {t(
                        "menu.badge-panel-dialog-player-recognition",
                        "Player recognition"
                      )}
                    </InputLabel>
                    <Select
                      onChange={handleChange}
                      error={isAllowPlayerAssignationError}
                      value={isAllowPlayerAssignation}
                      labelId="isAllowPlayerAssignation"
                      id="isAllowPlayerAssignation"
                      name="isAllowPlayerAssignation"
                      label={t(
                        "menu.badge-panel-dialog-player-recognition",
                        "Player recognition"
                      )}
                    >
                      <MenuItem value={true}>
                        {t(
                          "admin.header-dropdown-dialog-notifications-input-item-yes",
                          "Yes"
                        )}{" "}
                      </MenuItem>
                      <MenuItem value={false}>
                        {t(
                          "admin.header-dropdown-dialog-notifications-input-item-no",
                          "No"
                        )}
                      </MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>

              <Button
                className="button-table mt-2 mr-1"
                onClick={handleClose}
                variant="contained"
                color="secondary"
              >
                {t("menu.trivia-panel-dialog-add-test-button-close", "Close")}
              </Button>

              <Button
                className="button-table mt-2"
                variant="contained"
                color="primary"
                onClick={() => onFormSubmit()}
              >
                {t("menu.badge-panel-dialog-generate-badge", "Generate Badge")}
              </Button>
            </Container>
          )}
        </AppWrapper>
      </Dialog>
    </>
  );
}

const mapDispatchToProps = {
  saveBadgeRequest,
  updateBadgeRequest,
};

export default connect(null, mapDispatchToProps)(BadgeDialog);
