import * as testsTypes from "../types/testsTypes";

const {
  RESET_STATE,
  RESET_STORE,
  TESTS_LIST_REQUEST,
  TESTS_LIST_SAVED,
  TESTS_LIST_SAVE,
  TESTS_LIST_UPDATE,
  TESTS_LIST_DELETE,
  TESTS_LIST_CHARGING,
  TESTS_SAVE_CHARGING,
  TESTS_LIST_ERROR,
} = testsTypes;

const INITIAL_STATE = {
  tests: [],
  error_tests: false,
  tests_charging: false,
  tests_save_charging: false,
  totalElements: 0,
  totalPages: 1,
  pages: [],
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case TESTS_LIST_REQUEST:
      return {
        ...state,
        tests_charging: false,
        tests: [...state.pages].includes(action.payload.number)
          ? [...state.tests]
          : [...state.tests, ...action.payload.content],
        totalElements: action.payload.totalElements,
        totalPages: action.payload.totalPages,
        pages: [...state.pages].includes(action.payload.number)
          ? [...state.pages]
          : [...state.pages, action.payload.number],
        error_tests: false,
      };

    case TESTS_LIST_SAVE:
      return {
        ...state,
        tests: action.payload,
        error_tests: false,
        tests_save_charging: false,
      };
    case TESTS_LIST_UPDATE:
      return {
        ...state,
        tests: action.payload,
        error_tests: false,
        tests_save_charging: false,
      };
    case TESTS_LIST_DELETE:
      return {
        ...state,
        tests: [...state.tests].filter((test) => test.id !== action.payload),
        tests_charging: false,
        totalElements: state.totalElements - 1,
        error_tests: false,
      };

    case TESTS_LIST_CHARGING:
      return { ...state, tests_charging: true, error_tests: false };

    case TESTS_SAVE_CHARGING:
      return { ...state, tests_save_charging: true, error_tests: false };

    case TESTS_LIST_ERROR:
      return {
        ...state,
        error_tests: action.payload,
        tests_charging: false,
        tests_save_charging: false,
      };

    case TESTS_LIST_SAVED:
      return { ...state, tests_charging: false, error_tests: false };

    case RESET_STATE:
      return {
        ...state,
        error_tests: false,
        tests_charging: false,
        tests_save_charging: false,
      };

    case RESET_STORE:
      return {
        ...state,
        tests: [],
        error_tests: false,
        tests_charging: false,
        tests_save_charging: false,
        totalElements: 0,
        totalPages: 1,
        pages: [],
      };

    default:
      return state;
  }
};
