import jsonFetch from "../../../../../utils/jsonFetch";
import environment from "../../../../../../../libs/environment";

const User = (token) => {
  return {
    createUser: (data) => {
      console.log(data);
      return jsonFetch({
        endpoint: `${environment.motivarnosBackend}/admin/csv/crear`,
        method: "POST",
        token: token,
        jsonBody: data,
      });
    },
    modifyUser: (data) => {
      console.log(data);
      return jsonFetch({
        endpoint: `${environment.motivarnosBackend}/admin/csv/cambiar`,
        method: "POST",
        token: token,
        jsonBody: data,
      });
    },
    deleteUser: (data) => {
      console.log(data);
      return jsonFetch({
        endpoint: `${environment.motivarnosBackend}/admin/csv/borrar`,
        method: "POST",
        token: token,
        jsonBody: data,
      });
    },
  };
};

export default User;
