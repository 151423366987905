import React, { useState, useEffect } from "react";
import { Document, Page, pdfjs } from "react-pdf";

import { useLocation, NavLink, useHistory } from "react-router-dom";
import { SpinnerCircular } from "spinners-react";
import CircularProgress from "@material-ui/core/CircularProgress";
import { useTranslation } from "react-i18next";
import Grid from "@material-ui/core/Grid";

import toastr from "toastr";
import Button from "../../components/Button";

import LetterSoupServices from "../../Services/LetterSoup/Global";
import Loader from "../../components/Loader";
import Wordsearch from './Wordsearch';
import wordsearch from './WordSearchFuntions';
import WordsearchSolver from './word-search-solver';
import WordListShow from './WordListShow';

import "./styles.scss";
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const LetterSoupPlay = (props) => {
  let location = useLocation();
  let history = useHistory();  
  
  const id = parseInt(props.match.params.id);
  const userId = parseInt(props.match.params.userid);
 
  const { t } = useTranslation();

  const [data, setData] = useState(null);
  const [statewords, setStatewords] = useState([]);
  const [stateclues, setStateclues] = useState([]);
  const [qtyFoundWords, setQtyFoundWords] = useState(0);
  const [originalWordsList, setOriginalWordsList] = useState([]);
  const [statewordsFinded, setStatewordsFinded] = useState([]);
  const [excludedWordsList, setExcludedWordsList] = useState([]);
  const [showSolution, setShowSolution] = useState(false);
  const [d, setD] = useState({
    width: 10,
    height: 10,
  });
  const [solucion, setSolucion] = useState([]);
  const [puzzle, setPuzzle] = useState(
    Array(10).fill(Array(10).fill()),
  );
  const [loading, setLoading] = useState(true);
  const [saving, setSaving] = useState(false);
  const [time, setTime] = useState(null);
  const [timeLimit, setTimeLimit] = useState(null);
  const [pointForFoundWords, setPointForFoundWords] = useState(null);
  const [lettersoupRulesType, setLettersoupRulesType] = useState('');


  const handleFinish = (ttime) => {
    if (ttime === 0) {
      //handleClickFinish();
      history.push("/soup");
    }else if((!loading && statewords.length===0)){
      // handleClickFinish();
      history.push("/soup");
    }
  };

  var count;

  const startTimer = () => {
    count = setInterval(() => {
      setTime((time) => {
        handleFinish(time - 1);
        return time - 1;
      });
    }, 1000);
  };

  const filterUnplaceWords = (list1, list2) => {
    let list3 = list1.filter(function (el) {
      if ((list2.indexOf(el) < 0) ||  list2.indexOf(el.split('').reverse().join(''))) return el;
    });

    return list3;
  }

  useEffect(() => {
    LetterSoupServices.findById(id)
      .then((resp) => {
         let type = (resp.data && resp.data.rules && resp.data.rules !== '')? resp.data.rules.type : '';

         var auxWordsList = '';

        switch (type) {

          case 'sugerente':

            
            setLettersoupRulesType('sugerente');

            let aux = extractWordOfRulesJson(resp.data.rules.clues);
            let auxClues = extractCluesOfRulesJson(resp.data.rules.clues).split(", ");

            auxWordsList = (aux!=='')? aux.split(", "):null;
            setStateclues(auxClues);
            
            if (auxWordsList && auxWordsList.length > 0) {
              setPointForFoundWords((resp.data.pointsForFoundWords) ? resp.data.pointsForFoundWords : 20);
              setData(resp.data);

              let ws = (wordsearch(auxWordsList, d.width, d.height, { backwards: 0.25 }));
              setExcludedWordsList(ws.unplaced);
              let palabrasUbicadas = filterUnplaceWords(auxWordsList, ws.unplaced);
             
              setD({width: ws.grid.length, height: ws.grid.length,});
              setSolucion(WordsearchSolver(ws.grid, auxWordsList));
              setPuzzle(ws.grid.slice());
              setStatewords(palabrasUbicadas);
              setOriginalWordsList(auxWordsList);
              setLoading(false);
              setTime(resp.data.timeInSec);
              setTimeLimit(resp.data.timeInSec);
              if (resp.data.timeInSec > 0 )
                startTimer();
    
            } else {
              toastr.error(
                t(
                  "lettersoup.play.not-words",
                  "Sorry, this letter soup have not words"
                )
              );
              history.push("/soup");
            }
            
            break;

          case '':

            auxWordsList = (resp.data.words)? resp.data.words.split(", "):null;
            if (auxWordsList && auxWordsList.length > 0) {
              setPointForFoundWords((resp.data.pointsForFoundWords) 
                  ? resp.data.pointsForFoundWords : 20);
              setData(resp.data);
              let ws = (wordsearch(auxWordsList, d.width, d.height, { backwards: 0.25 }));
              setExcludedWordsList(ws.unplaced);
              let palabrasUbicadas = filterUnplaceWords(auxWordsList, ws.unplaced);
             /* console.log("ws.unplaced->")
              console.log(ws.unplaced)*/
              setD({width: ws.grid.length, height: ws.grid.length,});
              setSolucion(WordsearchSolver(ws.grid, auxWordsList));
              setPuzzle(ws.grid.slice());
              setStatewords(palabrasUbicadas);
              setOriginalWordsList(auxWordsList);
              setLoading(false);
              setTime(resp.data.timeInSec);
              setTimeLimit(resp.data.timeInSec);
              if (resp.data.timeInSec > 0 )
                startTimer();
    
            } else {
              toastr.error(
                t(
                  "lettersoup.play.not-words",
                  "Sorry, this letter soup have not words"
                )
              );
              history.push("/soup");
            }

          break;
        
          default:
            toastr.error(
              t(
                "lettersoup.label.type-error",
                "There was an error, please try again later"
              )
            );
            break;
        }
        

      })
      .catch((error) => {
        if (error.response && error.response.status === 422) {
          toastr.error(
            t("lettersoup-solved", "This lettersoup has already been solved")
          );
        } else {
          toastr.error(
            t(
              "response-login-500.label",
              "There was an error, please try again later"
            )
          );
        }
        history.push("/soup");
      });

    return () => {
      window.clearInterval(count);
    };
    // eslint-disable-next-line
  }, []);

  function createEmptyPuzzle(width, height) {
    return Array(height).fill(Array(width).fill('*'));
  }

  const updateListAencontrar = (word) => {
    const words = (statewords.length) ? statewords.slice() : [];
    
    let indix = words.indexOf(word);
    indix = indix !== -1 ? indix : words.indexOf(word.split('').reverse().join(''));

    switch (lettersoupRulesType) {

      case 'sugerente':
        const clues = (stateclues.length) ? stateclues.slice() : [];
        //la pista debe tener el mismo indice que la palabra en []words
        
        if (indix !== -1) {      
          words.splice(indix, 1);
          clues.splice(indix, 1);
          setStatewords(words);
          setStateclues(clues);
          setQtyFoundWords(prev=>prev+1);
        }       
        
        break;
    
      default:

        if (indix !== -1) {      
          words.splice(indix, 1);
          setStatewords(words);
          setQtyFoundWords(prev=>prev+1);
        }

        break;
    }
    
  };

  const updatePuzzle = async () => {
    if (statewords.length) {
      const ws = await wordsearch(statewords, d.width, d.height, { backwards: 0.25 });
      const solution = await WordsearchSolver(ws.grid, statewords);
      setExcludedWordsList(ws.unplaced);
      setSolucion(solution);
      setPuzzle(ws.grid);
    } else {
      setExcludedWordsList([]);
      setPuzzle(createEmptyPuzzle(d.width, d.height));
    }
  }

  const extractWordOfRulesJson = (clues) => {
    if(clues.length){
       let pal = [];
       let auxwords=clues;
       auxwords.forEach(element => {
         pal.push(element.word);
      });
      return pal.join(', ');       
    }
    return;
  }

  const extractCluesOfRulesJson = (clues) => {
    if(clues.length){
       let pal = [];
       let auxwords=clues;
       auxwords.forEach(element => {
         pal.push(element.clue);
      });
      return pal.join(', ');       
    }
    return;
 }

  const handleClickFinish = async () => {
    setSaving(true);
    let win = ((statewords.length) < (originalWordsList.length / 3) ) ? true : false;
    let dataScore = {
      "id": id,
      "points": (qtyFoundWords * pointForFoundWords)
    };

    if(win){
      await LetterSoupServices.setWin(dataScore)
      .then((resp) => {
        toastr.success(
          t(
            "player.lettersoup-play-dialog-add-message-success-saved-score",
            "The user's scored was saved successfully"
          )
        );
      })
      .catch((error) => {
        toastr.error(
          t(
            "lettersoup.play.not-words",
            "Sorry, some it wrong"
          )
        );
      });
    }else{

      await LetterSoupServices.setLose(dataScore)
      .then((resp) => {
        toastr.success(
          t(
            "player.lettersoup-play-dialog-add-message-success-saved-score",
            "The user's scored was saved successfully"
          )
        );
      })
      .catch((error) => {
        toastr.error(
          t(
            "lettersoup.play.not-words",
            "Sorry, some it wrong"
          )
        );
      });
    }
    
    history.push("/soup");
  }

  if (!loading && !saving) {

    if(statewords.length === 0){
      handleClickFinish();

    }else if(time-2 === 0){
      
       handleClickFinish();
    }
    
    return (
      <React.Fragment>
       <Grid container spacing={1}>
       
        <div className="lettersoup-wrapper">
        <Grid item xs={12} md={8} lg={8}>
          <div className="lettersoup-controls">
          <Grid item xs={8} md={8} lg={8}>
            <div className="time-progress-bar">
              <div
                style={{
                  width: `${statewords.length * 100 / (originalWordsList.length - excludedWordsList.length)}%`,
                  height: "1em",
                }}
                className="progress"
              />
            </div>
            {data.timeInSec > 0 ? (
              <span className="time-counter-wrapper" style={{ display: "flex" }}>
                <span>{t("time-limit.label", "Time limit")}</span>
                {"" +
                  (Math.trunc(time / 60) > 9
                    ? Math.trunc(time / 60)
                    : "0" + Math.trunc(time / 60)) +
                  ":" +
                  (Math.trunc(time % 60) > 9
                    ? Math.trunc(time % 60)
                    : "0" + Math.trunc(time % 60))}
                <span className="time-circular-progress-container">
                  <CircularProgress
                    variant="determinate"
                    size={"6rem"}
                    primary={"var(--warm-purple)"}
                    value={Math.trunc((time / timeLimit) * 100)}
                  />
                </span>
              </span>
              
            ) : (
              ""
            )} 
            </Grid>
            <Grid item xs={4} md={4} lg={4}>
            <span className="exit-button-wrapper">
              <NavLink to="/soup">
                <Button key="btnfinish" onClick={() => handleClickFinish()} style={{ marginRight: "1em" }} secondary >
                  {t("admin.header-dropdown-user-leave", "Leave")}
                </Button>
              </NavLink>
            </span>
            </Grid>
          </div>
          </Grid>

          {loading ? (
            <Loader />
          ) : statewords.length > 0 ? (
            <>
              <Grid item xs={12} md={6} lg={6}>
                <div key="worklist" className="lettersoup-cart-wrapper Wordsearch-row">
                  
                  { 
                    <WordListShow
                      title={(lettersoupRulesType==='sugerente')? 
                          t("lettersoup.label.clues", "Clues") 
                        : 
                          t("menu.lettersoup-panel-table-words", "Words")}
                      list_words={(lettersoupRulesType==='sugerente')? stateclues : statewords}
                      list_wordsStile={statewordsFinded}
                    />

                  }
                </div>
              </Grid>
              <Grid item xs={12} md={6} lg={6}>
                <div key="searchdescrip" className="lettersoup-cart-wrapper">
                  {t("lettersoup-play-description", "To indicate a word, click on the first letter and another on the last. It can be in reverse order.")}
                </div>
                <div key="worksearch" className="lettersoup-cart-wrapper">
                  
                             
                  <Wordsearch
                    sopa={puzzle}
                    solution={solucion}
                    showSolution={showSolution}
                    isFinded={statewordsFinded}
                    handleWordFinded={updateListAencontrar}
                  />

                </div>
              </Grid>
              </>
          ) : (
            
              <Grid item xs={12} md={6} lg={6}>
                <div key="winner" className="lettersoup-cart-wrapper">
                  {
                    t("lettersoup.play.winner", "Congratulations! You found all the words.")
                  }
                </div>
              </Grid>
            
          )}
        </div> 
        
        </Grid>
      </React.Fragment>
    );
  } else
    return (
      <span className="spinner-container">
        <SpinnerCircular />
      </span>
    );
};

export default LetterSoupPlay;

/***
 * Game model
	private String rules;/**
	 * Para pasarle a Game las reglas o caracteristicas especificas del juego que no se ajusten a las que estan definidas en el modelo Game.
	 * *{ Dimension of squared board DxD	 *   
	 **  "dimension": 10;
	 *    "tematic": "IT"  //se puede especificar o no la tematica de la sopa
	 *   "type":"sugerente"  // tipica //sugerente-> se da una referencia, concepto , ... se deben buscar las palabras que lo definan o esten relacionadas
	 **  List of words separate for ",". example> "word1,word2,...,wordn"
	 *   "words":["palabra1","palabra2"],
     *  Points for each found word 
	 *  "pointsForFoundWords:"20",
	 *  "referencia":[{"text":"referencia", "palabras":["palabra1","palabra2"]}], //depende del tipo de sopa
	 *  
	 *  }
	 * }*/
 
   /** GameUserScore
    * private String detailsByRules;
	 * Para pasarle los detalles del score especifica de cada juego que sea diferente a lo comun.
	 *  { "numberFoundWords":10,
	 *    "numberNotFoundWords":0,}
	 *  *  
	 * */
