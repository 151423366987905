import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import Container from "@material-ui/core/Container";
import Button from "@material-ui/core/Button";
import Pagination from "@material-ui/lab/Pagination";
import FixtureDialog from "../../common/Dialogs/Fixture";

const useStyles = makeStyles((theme) => ({
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    height: "100vh",
    overflow: "auto",
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
  },
  fixedHeight: {
    height: 240,
  },
  root: {
    "& > *": {
      marginTop: theme.spacing(2),
    },
  },
}));

function createData(tournament, award, condition, players, actions) {
  return { tournament, award, condition, players, actions };
}

const rows = [
  createData("Torneo 1", "3000 de oro", "Mejor de tres", 50),
  createData("Torneo 2", "3000 de oro", "Mejor de tres", 50),
  createData("Torneo 3", "3000 de oro", "Mejor de tres", 50),
  createData("Torneo 4", "3000 de oro", "Mejor de tres", 50),
];

export default function Fixture() {
  const classes = useStyles();

  return (
    <main className={classes.content}>
      <div className={classes.appBarSpacer} />
      <Container maxWidth="lg" className={classes.container}>
        <Grid container spacing={1}>
          <Button
            className="button-table"
            variant="contained"
            color="secondary"
          >
            Fixtures
          </Button>

          <TableContainer component={Paper}>
            <Table className={classes.table} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell align="left">Torneo</TableCell>
                  <TableCell align="left">Premio</TableCell>
                  <TableCell align="left">Condiciones</TableCell>
                  <TableCell align="left">#N jugadores</TableCell>
                  <TableCell align="left">Acciones</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {rows.map((row) => (
                  <TableRow key={row.tournament}>
                    <TableCell align="left">{row.tournament}</TableCell>
                    <TableCell align="left">{row.award}</TableCell>
                    <TableCell align="left">{row.condition}</TableCell>
                    <TableCell align="left">{row.players}</TableCell>
                    <TableCell align="left">
                      <FixtureDialog />
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>

          <div className={classes.root}>
            <Pagination count={10} color="primary" />
          </div>
        </Grid>
      </Container>
    </main>
  );
}
