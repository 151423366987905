import { handleActions, createActions } from "redux-actions";
import {
  obtainListOfEmailAvailable,
  postSendLink,
  validarHash,
} from "../service/recoverPasswordService";
import toastr from "toastr";

const { returnUserList, returnError, returnSendLink, returnValidateHash } =
  createActions({
    returnUserList: (data) => data,
    returnSendLink: (data) => data,
    returnError: (data) => data,
    returnValidateHash: (data) => data,
  });

function retrieveListToSendLink(data) {
  return function (dispatch, getState) {
    return obtainListOfEmailAvailable(data.company, data.user, data.team)
      .then((value) => {
        dispatch(
          returnUserList({
            company: data.company,
            userFrom: data.user,
            team: data.team,
            users: value,
          })
        );
        return "OK";
      })
      .catch((error) => {
        if (error.status == 422) {
          toastr.error(`${error.error}`);
        } else {
          toastr.error(
            "Ha ocurrido un error al intentar intentar recuperar la contraseña."
          );
        }

        return "ERROR";
      });
  };
}

const sendLink = (data) => {
  return async (dispatch, getState) => {
    try {
      let hashes = await postSendLink(data.users, data.token);
      dispatch(returnSendLink(hashes));
    } catch (err) {
      dispatch(returnError(err));
    }
  };
};

const validateHash = (data) => {
  return async (dispatch, getState) => {
    try {
      validarHash(data.user, data.hash).then((value) => {
        dispatch(returnValidateHash({ validHash: value }));
      });
    } catch (err) {
      dispatch(returnError(err));
    }
  };
};

const defaultState = {};
const recoverPasswordReducer = handleActions(
  {
    [returnUserList]: (state, action) => {
      return action.payload;
    },
    [returnSendLink]: (state, action) => {
      console.log(action.payload);
      return { hashes: action.payload };
    },
    [returnValidateHash]: (state, action) => {
      return { validHash: action.payload.validHash };
    },
    [returnError]: (state, action) => {
      return { error: action.payload };
    },
  },
  defaultState
);

const exposedRecoverPasswordReducer = {
  key: "recoverPasswordReducer",
  value: recoverPasswordReducer,
};

export {
  retrieveListToSendLink,
  sendLink,
  validateHash,
  exposedRecoverPasswordReducer,
};
