import React, { useState, useEffect } from "react";
import { merge } from "lodash";
import ReactApexChart from "react-apexcharts";
import { useTranslation } from "react-i18next";
import ServiceDashboard from "../Services/ServiceDashboard";
import { SpinnerCircular } from "spinners-react";
import { NotRegistersFound } from "../common/NotRegistersFound";
import Grid from "@material-ui/core/Grid";
import Container from "@material-ui/core/Container";
import Pagination from "@material-ui/lab/Pagination";

// material
import { Card, CardHeader, Box } from "@material-ui/core";
//
import { BaseOptionChart } from "./libs";

// ----------------------------------------------------------------------

export default function TriviasResponses() {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(1);
  const [numberPages, setNumberPages] = useState(1);

  const [chartData, setChartData] = useState(null);
  const [chartOptions, setChartOptions] = useState(
    merge(BaseOptionChart(), {
      stroke: { width: [2, 2, 2, 2] },
      plotOptions: { bar: { columnWidth: "11%", borderRadius: 4 } },
      fill: { type: ["solid", "solid", "solid", "solid"] },
      labels: null,
      legend: {
        show: false,
      },
      yaxis: {
        labels: {
          formatter: (value) => {
            return parseInt(value);
          },
        },
      },
      xaxis: { type: "text" },
      tooltip: {
        shared: true,
        intersect: false,
        y: {
          formatter: (y) => {
            if (typeof y !== "undefined") {
              return `${y.toFixed(0)}`;
            }
            return y;
          },
          title: {
            formatter: () => "",
          },
        },
      },
    })
  );

  const getCompletedTriviaByTeamReport = (number = 1) => {
    ServiceDashboard.completedTriviaByTeamReport(number)
      .then((response) => {
        if (response.data && response.data.content.length > 0) {
          setDataChart(response.data.content);
          setNumberPages(response.data.totalPages);
        }

        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    getCompletedTriviaByTeamReport();
    // eslint-disable-next-line
  }, []);

  const setDataChart = (data) => {
    let dataArray = [...data];

    let chartData = [];
    let nameData = [];
    for (let i = 0; i < dataArray.length; i++) {
      chartData.push(dataArray[i].value);
      nameData.push(dataArray[i].name);
    }

    let chartOptionsOriginal = { ...chartOptions };
    chartOptionsOriginal.labels = nameData;

    setChartOptions(chartOptionsOriginal);

    setChartData([
      {
        name: "Trivias",
        type: "line",
        data: chartData,
      },
    ]);
  };

  const handleChange = (event, value) => {
    setPage(value);
    setLoading(true);

    getCompletedTriviaByTeamReport(value);
  };

  return (
    <>
      {loading ? (
        <Container>
          <Grid container spacing={1} className="justify-content-center">
            <SpinnerCircular size={90} height={570} />
          </Grid>
        </Container>
      ) : (
        <>
          {chartData && chartOptions ? (
            <Card>
              <CardHeader
                title={t("trivia-answered.label", "Trivia answered")}
              />
              <Box sx={{ p: 3, pb: 1 }} dir="ltr">
                <ReactApexChart
                  type="line"
                  series={chartData}
                  options={chartOptions}
                  height={388}
                />

                <Pagination
                  className="mt-2 mb-2"
                  count={numberPages}
                  page={page}
                  color="primary"
                  onChange={(event, value) => handleChange(event, value)}
                />
              </Box>
            </Card>
          ) : (
            <Grid container spacing={1}>
              <Grid item xs={12} md={12} lg={12}>
                <NotRegistersFound />
              </Grid>
            </Grid>
          )}
        </>
      )}
    </>
  );
}
