import React, { useState, useContext } from "react";

import TextField from "@material-ui/core/TextField/TextField";
import { FormattedMessage } from "react-intl";
import { useTranslation } from 'react-i18next';

const ValidateCompany = (empresa) => empresa && empresa.length <= 30;

const Company = (props) => {
    const [empresa, setEmpresa] = useState(props.company);
    const changeCompany = (company, onChange) =>{
      setEmpresa(company);
      if(props.onChange){
        props.onChange(company);
      }
    }
    const { t, i18n } = useTranslation();

    return <TextField
              required
              label={
                <FormattedMessage
                  id="landing.companyLabel"
                  defaultMessage={t('company.label','Company')}
                />
              }
              className={props.className}
              value={empresa}
              onChange={(e) => changeCompany(e.target.value)}
              error={empresa && !ValidateCompany(empresa)}
              style={{ marginBottom: 12 }}
              variant="outlined"
            />
}

export default Company;