import { useEffect, useState } from "react";

export function useFormatProps(
  teamsHooksArray = [],
  rowsHooksArray = [],
  secondaryTeamsHooksArray = []
) {
  const [teamsHooks, setTeamsHooks] = useState(teamsHooksArray);
  const [rowsHooks, setRowsHooks] = useState(rowsHooksArray);
  const [secondaryTeamsHooks, setSecondaryTeamsHooks] = useState(
    secondaryTeamsHooksArray
  );

  useEffect(() => {
    let teamsHooksFormatted = [];
    let rowsHooksFormatted = [];
    let secondaryTeamsHooksFormatted = [];

    secondaryTeamsHooks.forEach((element) => {
      secondaryTeamsHooksFormatted.push({
        id: element.id,
      });
    });

    setSecondaryTeamsHooks(secondaryTeamsHooksFormatted);

    teamsHooks.forEach((element) => {
      teamsHooksFormatted.push({
        id: element.id,
      });
    });

    setTeamsHooks(teamsHooksFormatted);

    rowsHooks.forEach((element) => {
      rowsHooksFormatted.push({
        id: element.id,
      });
    });
    setRowsHooks(rowsHooksFormatted);

    // eslint-disable-next-line
  }, []);

  return [teamsHooks, rowsHooks, secondaryTeamsHooks];
}
