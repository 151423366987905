import React from "react";
import { withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Container from "@material-ui/core/Container";
import Button from "@material-ui/core/Button";
import toastr from "toastr";
import { SpinnerCircular } from "spinners-react";
import TextField from "@material-ui/core/TextField";
import { withTranslation } from "react-i18next";

import "toastr/build/toastr.min.css";
/********Services***** */
import ThemeService from "../../Services/ThemeService";

/*********Icons********* */
import ViewListIcon from "@material-ui/icons/ViewList";
import FileService from "../../Services/FileService";

const styles = (theme) => ({
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
  },
  fixedHeight: {
    height: 240,
  },
  root: {
    "& > *": {
      marginTop: theme.spacing(2),
    },
  },
  margin: {
    margin: theme.spacing(1),
  },
  textField: {
    width: "50ch",
    marginBottom: "5px",
  },
  input: {
    display: "none",
  },
});

class Styles extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      logo: "",
      backgroundImage: "",
      logoNew: false,
      backgroundImageNew: false,
      textColor: "#000000",
      primaryColor: "#ffffff",
      secondaryColor: "#ffffff",
      backgroundColor1: "#ffffff",
      backgroundColor2: "#ffffff",
      feelingsPopupLabel: "",
      id: "",
    };
  }

  componentDidMount() {
    this.getTheme();
  }

  handleChange({ target: { name, value } }) {
    if (name === "backgroundColor1" || name === "backgroundColor2") {
      this.setState((prevState) => ({
        ...prevState,
        [name]: value,
        backgroundImage: "",
      }));
    } else {
      this.setState((prevState) => ({ ...prevState, [name]: value }));
    }
  }

  async onFormSubmit() {
    this.setState({ loading: true });

    let urlLogo =
      this.state.logo && this.state.logo.size ? "" : this.state.logo;
    if (this.state.logo && this.state.logo.size) {
      const formData = new FormData();
      formData.append("file", this.state.logo);
      await FileService.uploadFile(formData, "company_files", "Logo")
        .then((response) => {
          urlLogo = response.data.doUrl;
          toastr.success(
            this.props.t(
              "admin.header-dropdown-view-styles-logo-message-success",
              "Logo added successfully"
            )
          );
        })
        .catch(() => {
          toastr.error(
            this.props.t(
              "admin.header-dropdown-view-styles-logo-message-error",
              "An error occurred while trying to add the logo"
            )
          );
        });
    }

    let urlBackgroundImage =
      this.state.backgroundImage && this.state.backgroundImage.size
        ? ""
        : this.state.backgroundImage;
    if (this.state.backgroundImage && this.state.backgroundImage.size) {
      const formData = new FormData();
      formData.append("file", this.state.backgroundImage);
      await FileService.uploadFile(formData, "company_files", "BackGroundImage")
        .then((response) => {
          urlBackgroundImage = response.data.doUrl;
          toastr.success(
            this.props.t(
              "admin.header-dropdown-view-styles-backgroundImage-message-success",
              "Background added successfully"
            )
          );
        })
        .catch(() => {
          toastr.error(
            this.props.t(
              "admin.header-dropdown-view-styles-backgroundImage-message-error",
              "An error occurred while trying to add the background"
            )
          );
        });
    }

    let json = {
      id: this.state.id,
      logo: urlLogo,
      backgroundImage: urlBackgroundImage,
      textColor: this.state.textColor,
      primaryColor: this.state.primaryColor,
      feelingsPopupLabel: this.state.feelingsPopupLabel,
      secondaryColor: this.state.secondaryColor,
      backgroundColor1: this.state.backgroundColor1,
      backgroundColor2: this.state.backgroundColor2,
    };

    ThemeService.updateTheme({ ...json })
      .then(() => {
        toastr.success(
          this.props.t(
            "admin.header-dropdown-view-styles-update-message-success",
            "Options saved successfully"
          )
        );
        this.getTheme();
      })
      .catch(() => {
        toastr.error(
          this.props.t(
            "admin.header-dropdown-view-styles-update-message-error",
            "An error occurred while trying to save options"
          )
        );
        this.setState({ loading: false });
      });
  }

  handleChangeLogo({ target: { files, result } }) {
    this.setState({ logoNew: true });

    if (files && files[0]) {
      this.setState((prevState) => ({
        ...prevState,
        textColor: this.state.textColor,
        primaryColor: this.state.primaryColor,
        feelingsPopupLabel: this.state.feelingsPopupLabel,
        secondaryColor: this.state.secondaryColor,
        backgroundColor1: this.state.backgroundColor1,
        backgroundColor2: this.state.backgroundColor2,
        logo: files[0],
      }));

      this.readURL(files);
    }
  }

  handleChangeBackgroundImage({ target: { files, result } }) {
    this.setState({ backgroundImageNew: true });

    if (files && files[0]) {
      this.setState((prevState) => ({
        ...prevState,
        textColor: this.state.textColor,
        primaryColor: this.state.primaryColor,
        feelingsPopupLabel: this.state.feelingsPopupLabel,
        secondaryColor: this.state.secondaryColor,
        backgroundColor1: "",
        backgroundColor2: "",
        logo: this.state.logo,
        backgroundImage: files[0],
      }));

      this.readURLBackground(files);
    }
  }

  readURLBackgroundTop(files) {
    if (files && files[0]) {
      var reader = new FileReader();
      reader.onload = function (e) {
        document
          .getElementById("img-designed-background-top")
          .setAttribute("src", e.target.result);
      };
      reader.readAsDataURL(files[0]);
    }
  }

  readURLBackground(files) {
    if (files && files[0]) {
      var reader = new FileReader();
      reader.onload = function (e) {
        document
          .getElementById("img-designed-background")
          .setAttribute("src", e.target.result);
      };
      reader.readAsDataURL(files[0]);
    }
  }

  readURL(files) {
    if (files && files[0]) {
      var reader = new FileReader();
      reader.onload = function (e) {
        document
          .getElementById("img-designed")
          .setAttribute("src", e.target.result);
      };
      reader.readAsDataURL(files[0]);
    }
  }

  /***********GENERAL FUNCTIONS************* */

  getTheme() {
    this.setState({ loading: true });
    ThemeService.getTheme()
      .then((response) => {
        if (response.data && response.data.content[0]) {
          this.setState({ ...response.data.content[0] });
        }
        this.setState({ logoNew: true });
        this.setState({ backgroundImageNew: true });
        this.setState({ backgroundImageNewTop: true });

        this.setState({ loading: false });
      })
      .catch(() => {
        toastr.error("Ha ocurrido un error al intentar obtener las opciones.");
        this.setState({ loading: false });
      });
  }

  render() {
    const {
      loading,
      logo,
      backgroundImage,
      textColor,
      primaryColor,
      feelingsPopupLabel,
      secondaryColor,
      backgroundColor1,
      backgroundColor2,
      logoNew,
      backgroundImageNew,
    } = this.state;
    const { classes, t } = this.props;
    return (
      <main className={classes.content}>
        <div className={classes.appBarSpacer} />
        <Container maxWidth="lg" className={classes.container}>
          <Grid container spacing={1}>
            <Button
              className="button-table mr-1"
              variant="contained"
              color="secondary"
            >
              <ViewListIcon className="mr-1" />
              {t(
                "admin.header-dropdown-view-styles-title",
                "Aesthetic settings"
              )}
            </Button>
          </Grid>

          {loading && (
            <div className="div-spinner">
              <Grid container spacing={1}>
                <SpinnerCircular size={90} />
              </Grid>
            </div>
          )}

          {!loading && (
            <Grid container spacing={1}>
              <Grid item xs={12} md={6} lg={6}>
                <Grid container>
                  <Grid item xs={12} className="padding-custom-button">
                    <Grid container spacing={1}>
                      <Grid item xs={10} md={10} lg={10}>
                        <div className="h-100">
                          <input
                            accept="image/*"
                            className={classes.input}
                            id="contained-button-file"
                            type="file"
                            onChange={(e) => this.handleChangeLogo(e)}
                          />
                          <label htmlFor="contained-button-file">
                            <Button
                              variant="contained"
                              color="primary"
                              component="span"
                              className="button-table h-100 w-100"
                            >
                              {logo === ""
                                ? t(
                                    "admin.header-dropdown-view-styles-image-input-one",
                                    "Upload logo"
                                  )
                                : t(
                                    "admin.header-dropdown-view-styles-image-input-two",
                                    "Change logo"
                                  )}
                            </Button>
                          </label>
                        </div>
                      </Grid>

                      <Grid
                        item
                        xs={2}
                        md={2}
                        lg={2}
                        className="div-img-designed"
                      >
                        {logo && logo !== "" && !logoNew && (
                          <img
                            id="img-designed"
                            className="img-predesigned"
                            src=""
                            alt="Logo"
                          />
                        )}
                        {logo && logo !== "" && logoNew && (
                          <img
                            id="img-designed"
                            className="img-predesigned"
                            src={`${logo}`}
                            alt="Logo"
                          />
                        )}
                      </Grid>
                    </Grid>
                  </Grid>

                  <Grid item xs={12} className="padding-custom-button">
                    <Grid container spacing={1}>
                      <Grid item xs={10} md={10} lg={10}>
                        <div className="h-100">
                          <input
                            accept="image/*"
                            className={classes.input}
                            id="contained-button-file-background"
                            type="file"
                            onChange={(e) =>
                              this.handleChangeBackgroundImage(e)
                            }
                          />
                          <label htmlFor="contained-button-file-background">
                            <Button
                              variant="contained"
                              color="primary"
                              component="span"
                              className="button-table h-100 w-100"
                            >
                              {backgroundImage === ""
                                ? t(
                                    "admin.header-dropdown-view-styles-backgroundImage1-input",
                                    "Upload image"
                                  )
                                : t(
                                    "admin.header-dropdown-view-styles-backgroundImage2-input",
                                    "Change background image"
                                  )}
                            </Button>
                          </label>
                        </div>
                      </Grid>

                      <Grid
                        item
                        xs={2}
                        md={2}
                        lg={2}
                        className="div-img-designed"
                      >
                        {backgroundImage &&
                          backgroundImage !== "" &&
                          !backgroundImageNew && (
                            <img
                              id="img-designed-background"
                              className="img-predesigned"
                              src=""
                              alt="backgroundImage"
                            />
                          )}
                        {backgroundImage &&
                          backgroundImage !== "" &&
                          backgroundImageNew && (
                            <img
                              id="img-designed-background"
                              className="img-predesigned"
                              src={`${backgroundImage}`}
                              alt="backgroundImage"
                            />
                          )}
                      </Grid>
                    </Grid>
                  </Grid>

                  <Grid item xs={12}>
                    <TextField
                      value={textColor ? textColor : "#000000"}
                      name="textColor"
                      id="textColor"
                      label={t(
                        "admin.header-dropdown-view-styles-textColor-input",
                        "Text color"
                      )}
                      type="color"
                      variant="outlined"
                      className="mt-2"
                      fullWidth
                      onChange={(e) => this.handleChange(e)}
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <TextField
                      value={primaryColor ? primaryColor : "#ffffff"}
                      name="primaryColor"
                      id="primaryColor"
                      label={t(
                        "admin.header-dropdown-view-styles-primaryColor-input",
                        "Primary color"
                      )}
                      type="color"
                      variant="outlined"
                      className="mt-2"
                      fullWidth
                      onChange={(e) => this.handleChange(e)}
                    />
                  </Grid>
                </Grid>
              </Grid>
              {/******************** */}
              <Grid item xs={12} md={6} lg={6}>
                <Grid container>
                  <Grid item xs={12}>
                    <TextField
                      value={backgroundColor1 ? backgroundColor1 : "#ffffff"}
                      name="backgroundColor1"
                      id="backgroundColor1"
                      label={t(
                        "admin.header-dropdown-view-styles-backgroundColor1-input",
                        "Background color one"
                      )}
                      type="color"
                      variant="outlined"
                      className="mt-2"
                      fullWidth
                      onChange={(e) => this.handleChange(e)}
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <TextField
                      value={backgroundColor2 ? backgroundColor2 : "#ffffff"}
                      name="backgroundColor2"
                      id="backgroundColor2"
                      label={t(
                        "admin.header-dropdown-view-styles-backgroundColor2-input",
                        "Background color two"
                      )}
                      type="color"
                      variant="outlined"
                      className="mt-2"
                      fullWidth
                      onChange={(e) => this.handleChange(e)}
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <TextField
                      value={secondaryColor ? secondaryColor : "#ffffff"}
                      name="secondaryColor"
                      id="secondaryColor"
                      label={t(
                        "admin.header-dropdown-view-styles-secondaryColor-input",
                        "Secondary color"
                      )}
                      type="color"
                      variant="outlined"
                      className="mt-2"
                      fullWidth
                      onChange={(e) => this.handleChange(e)}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      value={feelingsPopupLabel}
                      name="feelingsPopupLabel"
                      id="feelingsPopupLabel"
                      label={t("how-feel", "How you feel?")}
                      type="text"
                      variant="outlined"
                      className="mt-2"
                      fullWidth
                      onChange={(e) => this.handleChange(e)}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Button
                  onClick={() => this.onFormSubmit()}
                  className="button-table mt-2"
                  color="primary"
                >
                  {t("admin.header-dropdown-dialog-actions-save", "Save")}
                </Button>
              </Grid>
            </Grid>
          )}
        </Container>
      </main>
    );
  }
}

export default withTranslation()(
  withStyles(styles, { withTheme: true })(Styles)
);
