import React from "react";
import { motivational } from "../../../../locales/motivational.json";
import { useTranslation } from "react-i18next";

const MotivationalPhrases = (props) => {
  const { t } = useTranslation();
  var min = 0;
  var max = 9;
  var rand = Math.trunc(min + Math.random() * (max - min));

  return (
    <div style={{position: 'absolute', bottom: "1px", margin: '10px', width: '50vh'}}>
      <h4 style={{ fontSize: "1.3em", margin: '0px'}}>{t(motivational[rand].label)}</h4>
      <h5 style={{ fontSize: "0.7em", margin: '0px'}}>{motivational[rand].author}</h5>
    </div>
  );
};

export default MotivationalPhrases;
