import * as challengesTypes from "../types/challengesTypes";

const {
  RESET_STATE,
  RESET_STORE,
  CHALLENGES_LIST_REQUEST,
  CHALLENGES_LIST_SAVED,
  CHALLENGES_LIST_SAVE,
  CHALLENGES_LIST_UPDATE,
  CHALLENGES_LIST_DELETE,
  CHALLENGES_LIST_CHARGING,
  CHALLENGES_SAVE_CHARGING,
  CHALLENGES_LIST_ERROR,
} = challengesTypes;

const INITIAL_STATE = {
  challenges: [],
  error_challenges: false,
  challenges_charging: false,
  challenges_save_charging: false,
  totalElements: 0,
  totalPages: 1,
  pages: [],
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case CHALLENGES_LIST_REQUEST:
      return {
        ...state,
        challenges_charging: false,
        challenges: [...state.pages].includes(action.payload.number)
          ? [...state.challenges]
          : [...state.challenges, ...action.payload.content],
        totalElements: action.payload.totalElements,
        totalPages: action.payload.totalPages,
        pages: [...state.pages].includes(action.payload.number)
          ? [...state.pages]
          : [...state.pages, action.payload.number],
        error_challenges: false,
      };

    case CHALLENGES_LIST_SAVE:
      return {
        ...state,
        challenges: action.payload,
        error_challenges: false,
        challenges_save_charging: false,
      };
    case CHALLENGES_LIST_UPDATE:
      return {
        ...state,
        challenges: action.payload,
        error_challenges: false,
        challenges_save_charging: false,
      };
    case CHALLENGES_LIST_DELETE:
      return {
        ...state,
        challenges: [...state.challenges].filter(
          (test) => test.id !== action.payload
        ),
        challenges_charging: false,
        totalElements: state.totalElements - 1,
        error_challenges: false,
      };

    case CHALLENGES_LIST_CHARGING:
      return { ...state, challenges_charging: true, error_challenges: false };

    case CHALLENGES_SAVE_CHARGING:
      return {
        ...state,
        challenges_save_charging: true,
        error_challenges: false,
      };

    case CHALLENGES_LIST_ERROR:
      return {
        ...state,
        error_challenges: action.payload,
        challenges_charging: false,
        challenges_save_charging: false,
      };

    case CHALLENGES_LIST_SAVED:
      return { ...state, challenges_charging: false, error_challenges: false };

    case RESET_STATE:
      return {
        ...state,
        error_challenges: false,
        challenges_charging: false,
        challenges_save_charging: false,
      };

    case RESET_STORE:
      return {
        ...state,
        challenges: [],
        error_challenges: false,
        challenges_charging: false,
        challenges_save_charging: false,
        totalElements: 0,
        totalPages: 1,
        pages: [],
      };

    default:
      return state;
  }
};
