import React from "react";
import { withStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import Container from "@material-ui/core/Container";
import Button from "@material-ui/core/Button";
import { useTranslation } from "react-i18next";

import { Link } from "react-router-dom";
import { withTranslation } from "react-i18next";

import TableRanking from "../../common/Tables/TableRanking";
import TableHistoricalRanking from "../../common/Tables/TableHistoricalRanking";

import UserLoginByHour from "./UserLoginByHour";
import UserLoginByDate from "./UserLoginByDate";

import "toastr/build/toastr.min.css";

import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";

const styles = (theme) => ({
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
  },

  containerDefault: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
  },
});

function UserPanelReport(props) {
  const { classes } = props;
  const { t } = useTranslation();

  return (
    <main className={classes.content}>
      <div className={classes.appBarSpacer} />
      <Container maxWidth="lg" className={classes.container}>
        <Grid container spacing={1}>
          <Link to="/userPanel">
            <Button
              className="button-table mr-1"
              variant="contained"
              color="secondary"
            >
              <ArrowBackIosIcon className="mr-1" />
              {t("back.label", "Back")}
            </Button>
          </Link>

          {/*
          <Grid item xs={12} md={12} lg={12}>
            <Paper className={classes.containerDefault}>
              <TableRanking />
            </Paper>
          </Grid>

          <Grid item xs={12} md={12} lg={12}>
            <Paper className={classes.containerDefault}>
              <TableHistoricalRanking />
            </Paper>
          </Grid>
          */}

          <Grid item xs={12} md={12} lg={12}>
            <Paper className={classes.containerDefault}>
              <UserLoginByHour />
            </Paper>
          </Grid>

          {/* <Grid item xs={12} md={12} lg={12}>
            <Paper className={classes.containerDefault}>
              <UserLoginByDate />
            </Paper>
          </Grid> */}
        </Grid>
      </Container>
    </main>
  );
}

export default withTranslation()(
  withStyles(styles, { withTheme: true })(UserPanelReport)
);
