import React, { useState, useEffect } from "react";
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Typography from "@material-ui/core/Typography";
import toastr from "toastr";
import { SpinnerCircular } from "spinners-react";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import InputLabel from "@material-ui/core/InputLabel";
import { useTranslation } from "react-i18next";

import { makeStyles } from "@material-ui/core/styles";
import FormControl from "@material-ui/core/FormControl";

/********Services********* */
import GeneralService from "../../Services/GeneralService";
import TeamService from "../../Services/TeamService";

/*********Icons********* */
import AddIcon from "@material-ui/icons/Add";

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
  },
  margin: {
    margin: theme.spacing(1),
  },
  withoutLabel: {
    marginTop: theme.spacing(3),
  },
  formControl: {
    width: "100%",
    marginTop: "1rem",
  },
}));

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

export default function MetricSupervisors(props) {
  const [open, setOpen] = useState(false);
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [supervisorError, setSupervisorError] = useState(false);
  const [teamsError, setTeamsError] = useState(false);
  const { t } = useTranslation();

  /************General functions********** */
  const [values, setValues] = useState({
    supervisor: "",
    supervisorArray: [],
    teams: [],
    teamsArray: [],
  });

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
    props.sendUser();
  };

  /*
  const handleCloseCustom = () => {   
    setOpen(false);
  };
  
  */

  useEffect(() => {
    setLoading(true);
    getSupervisors();
    // eslint-disable-next-line
  }, []);

  const getSupervisors = () => {
    GeneralService.getSupervisors(1, 999)
      .then((response) => {
        getTeams(response);
      })
      .catch(() => {
        toastr.error(
          t(
            "admin.header-dropdown-dialog-get-supervisors-error",
            "There was an error getting the supervisors"
          )
        );
        setLoading(false);
      });
  };

  const getTeams = (responseSupervisors) => {
    TeamService.getTeams()
      .then((response) => {
        setValues({
          ...values,
          supervisorArray: responseSupervisors.data.content,
          teamsArray: response.data.content,
        });
        setLoading(false);
      })
      .catch(() => {
        toastr.error(
          t(
            "admin.header-dropdown-dialog-get-teams-error",
            "An error occurred while trying to get the computers"
          )
        );
        setLoading(false);
      });
  };

  const handleChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
  };

  const onFormSubmit = () => {
    setSupervisorError(false);
    setTeamsError(false);

    if (values.supervisor === "") {
      setSupervisorError(true);
      toastr.error(
        t(
          "admin.header-dropdown-dialog-supervisors-message",
          "There was an error getting the supervisors"
        )
      );
      return;
    }

    if (values.teams === "" || values.teams.length === 0) {
      setTeamsError(true);
      toastr.error(
        t(
          "admin.header-dropdown-dialog-teams-message",
          "An error occurred while trying to get the computers"
        )
      );
      return;
    }

    setLoading(true);

    let json = {
      id: values.supervisor.id,
      supervisedTeams: values.teams,
      role: 2,
    };

    GeneralService.updateUser({ ...json })
      .then(() => {
        toastr.success(
          t(
            "admin.header-dropdown-dialog-supervisors-success",
            "Supervisor saved successfully"
          )
        );
        setLoading(false);
        setValues({
          ...values,
          supervisor: "",
          teams: [],
        });
      })
      .catch(() => {
        toastr.error(
          t(
            "admin.header-dropdown-dialog-supervisors-error",
            "Supervisor saved successfully"
          )
        );
        setLoading(false);
      });
  };

  return (
    <>
      <Button
        className="button-table"
        variant="contained"
        color="secondary"
        onClick={handleClickOpen}
      >
        <AddIcon />
        {t("admin.header-dropdown-dialog-supervisors-title", "Supervisors")}
      </Button>

      <Dialog
        maxWidth="sm"
        fullWidth={true}
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
        disableBackdropClick={true}
        disableEscapeKeyDown={true}
      >
        <DialogTitle id="customized-dialog-title" onClose={handleClose}>
          {t("admin.header-dropdown-dialog-supervisors-title", "Supervisors")}
        </DialogTitle>
        <DialogContent dividers>
          {loading && (
            <div className="div-spinner-modal">
              <Container maxWidth="lg" className={classes.container}>
                <Grid container spacing={1}>
                  <SpinnerCircular size={90} />
                </Grid>
              </Container>
            </div>
          )}

          {!loading && (
            <>
              <FormControl variant="outlined" className={classes.formControl}>
                <InputLabel id="supervisor-select-outlined-label">
                  {t(
                    "admin.header-dropdown-dialog-supervisors-title",
                    "Supervisors"
                  )}
                </InputLabel>
                <Select
                  labelId="group-mutiple-name-label"
                  error={supervisorError}
                  id="supervisor"
                  name="supervisor"
                  label={t(
                    "admin.header-dropdown-dialog-supervisors-title",
                    "Supervisors"
                  )}
                  value={values.supervisor}
                  onChange={handleChange("supervisor")}
                >
                  {values.supervisorArray.map((supervisor, index) => (
                    <MenuItem key={index} value={supervisor}>
                      {supervisor.username ? supervisor.username : "Sin nombre"}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>

              <FormControl variant="outlined" className={classes.formControl}>
                <InputLabel id="teams-select-outlined-label">
                  {t("admin.header-dropdown-dialog-teams-input", "Teams")}
                </InputLabel>
                <Select
                  labelId="group-mutiple-name-label"
                  error={teamsError}
                  id="teams"
                  name="teams"
                  label={t("admin.header-dropdown-dialog-teams-input", "Teams")}
                  value={values.teams || ""}
                  multiple
                  onChange={handleChange("teams")}
                >
                  {values.teamsArray.map((team, index) => (
                    <MenuItem key={index} value={team}>
                      {team.name ? team.name : "Sin nombre"}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </>
          )}
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleClose} color="secondary">
            {t("admin.header-dropdown-dialog-actions-cancel", "Cancel")}
          </Button>
          <Button autoFocus color="primary" onClick={onFormSubmit}>
            {t("admin.header-dropdown-dialog-actions-save", "Save")}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
