import { recoverPassword } from "../api/recover-password";

const obtainListOfEmailAvailable = async (company, user, team) => {
  const listOfEmails = await recoverPassword().getListSupervisorsAvailable({
    company: company,
    user: user,
    team: team,
  });

  return listOfEmails;
};

const postSendLink = async (users, token) => {
  let hashes = [];
  let userIdArray = [];

  users.forEach((element) => {
    userIdArray.push({ id: element.user.id });
  });

  const hash = await recoverPassword().changeNewPassword({
    token: token,
    resetUserList: userIdArray,
  });
  hashes.push(hash);
  return hashes;
};

const validarHash = (user, hash) => {
  return recoverPassword().validarHash({ user: user, hash: hash });
};

const postChangePassword = async (usercode, newPassword, hash) => {
  try {
    await recoverPassword().changePassword({
      valor1: usercode,
      valor2: newPassword,
      valor3: hash,
    });
    return 1;
  } catch (ex) {
    console.log(ex);
    return 0;
  }
};
export {
  obtainListOfEmailAvailable,
  postSendLink,
  postChangePassword,
  validarHash,
};
