import React from "react";
import { Container, Slide } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import pxToRem from "../../../../utils/pxToRem";
import { FormattedMessage } from "react-intl";
import { CSVLink } from "react-csv";
import { useSelector } from 'react-redux';
import { generateBodyForCSV } from './services/UserService'
import moment from "moment";

const useReduxSelector = () =>
  useSelector((state) => ({
    dataToExport:state.usersReducer?.dataToExport? state.usersReducer.dataToExport : [{}]
  }));

const FinishFlow = (props) => {
    const className = useStyles();
    const {dataToExport} = useReduxSelector();
    var exportList = [];
    
    exportList = dataToExport.errors? exportList.concat(generateBodyForCSV(dataToExport.errors)) : exportList;
    exportList = dataToExport.usersImported? exportList.concat(generateBodyForCSV(dataToExport.usersImported)) : exportList;
    
    return( 
        <>
            
            <Container style={{display:"flex", flexDirection:"column"}}>
                { exportList.length === 0 ? 
                    <h2 className={className.title} style={{textAlign:"center"}}>
                        <FormattedMessage
                            id="finishUserStepper.Title"
                            defaultMessage="You didn't imported anything"
                        />
                    </h2>
                :
                <>
                    <h2 className={className.title} style={{textAlign:"center"}}>
                        <FormattedMessage
                            id="finishUserStepper.Title"
                            defaultMessage="You finished with the groups, congrats!"
                        />
                    </h2>
                    <h4 className={className.body} style={{textAlign:"center"}}>
                        <FormattedMessage
                            id="finishUserStepper.body1"
                            defaultMessage="Download a csv from here where you can find all the details on how your importation went."
                        />
                        <CSVLink filename={moment().format("YYYYMMDDHHmmss")+"-users.csv"} data={exportList}> Download CSV</CSVLink>
                    </h4>
                </>
                }
            </Container>
        </>     
    )
}

const useStyles = makeStyles((theme) => ({
    motivational:{
        color:"black",
        letterSpacing: 6,
        fontSize: pxToRem(20),
        fontWeight:"light",
        fontStyle:"bold"
    },
    body: {
        color: "black",
        letterSpacing: 6,
        lineHeight: 1.1,
        fontSize: pxToRem(30),
        marginBottom: pxToRem(20),
        fontWeight: "bold"
    },
    title: {
        color: "black",
        letterSpacing: 6,
        lineHeight: 1.1,
        fontSize: pxToRem(50),
        marginBottom: pxToRem(20),
        fontWeight: "bold"
    }
}));
export default FinishFlow;