import React from "react";
import { Box, IconButton, Tooltip } from '@material-ui/core';
import PlayArrow from "@material-ui/icons/PlayArrow";
import Pause from "@material-ui/icons/Pause";

export default function PlayerButton({ isPlaying, handlePlay }) {
    return (
        <Box pl={1} onClick={handlePlay}>
            <IconButton className="play-btn" color="primary" aria-label="play music" component="span">
                {
                    !isPlaying ?
                        <Tooltip title={'Play'} id="play-btn-selector">
                            <PlayArrow />
                        </Tooltip>
                        :
                        <Tooltip title={'Pause'}>
                            <Pause />
                        </Tooltip>
                }
            </IconButton>
        </Box>
    );
}