import React, { useEffect, useState } from "react";
import toastr from "toastr";
import Button from "@material-ui/core/Button";
import { useTranslation } from "react-i18next";

/*********Icons********* */
import AddIcon from "@material-ui/icons/Add";
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';

/********Services********* */
import BadgeService from "../../Services/BadgeService";
import UserServices from "../../Services/UserService";
import DialogStepper from "./DialogStepper/DialogStepper";

export default function AssignDialog(props) {
	const [open, setOpen] = React.useState(false);
	const [loading, setLoading] = useState(false);
	const [{ rowsAssignBadges, comment, badge, rowsUsers }, setState] =
		useState({
			rowsAssignBadges: [],
			comment: "",
			badge: "",
			rowsUsers: [],
		});

	const { t } = useTranslation();

	const handleClickOpen = () => {
		setOpen(true);
	};

	const handleClose = () => {
		setOriginalState();
	};

	function setOriginalState() {
		setState((prevState) => ({
			...prevState,
			badge: "",
			comment: "",
		}));
		setOpen(false);
	}

	function onFormSubmit(user, selectedBadge, badgeComment) {
		if (user === null) {
			toastr.error(
				t(
					"menu.badge-panel-dialog-delivery-message-error-user",
					"You must select a user"
				)
			);
			return;
		}

		if (selectedBadge === "") {
			toastr.error(
				t(
					"menu.badge-panel-dialog-delivery-message-error-badge",
					"You must select a badge"
				)
			);
			return;
		}

		if (badgeComment === "") {
			toastr.error(
				t(
					"menu.trivia-panel-dialog-add-test-message-error-description",
					"Description is required"
				)
			);
			return;
		}  else if (badgeComment.length > 650) {
			toastr.error(
			  t(
			  "commons.comment-max-length",
			  "El comentario no puede tener más de 650 caracteres."
			  )
			);
			return;
		  }

		let json = {
			userIdList: [user.id],
			insigniaId: selectedBadge.id,
			comment: badgeComment,
		};

		setLoading(true);
		BadgeService.deliverBadge(json)
			.then(() => {
				toastr.success(
					t(
						"menu.badge-panel-dialog-delivery-message-success-save",
						"The badge has been delivered"
					)
				);
				
				setLoading(false);
				props.callbackDeliveredBadge(true)
			})
			.catch((error) => {
				if (error.response.status === 422) {
					toastr.error(error.response.data.message);
				} else if (
					error.response.data.message.indexOf("Duplicate entry") !== -1
				) {
					toastr.error(
						t(
							"menu.badge-panel-dialog-delivery-message-error-save-one",
							"You have already assigned the badge to the player"
						)
					);
				} else {
					toastr.error(
						t(
							"menu.badge-panel-dialog-delivery-message-error-save-two",
							"An error occurred while trying to deliver the badge"
						)
					);
				}

				setLoading(false);
			});
	}

	const getUsers = async () => {
		try {
			setLoading(true)
			const response = await UserServices.getUserAllCompany();

			setState((prevState) => (
				{
					...prevState,
					rowsUsers: response.data.content,
				}
			));
			setLoading(false)

		} catch (error) {
			toastr.error("Ha ocurrido un error al intentar obtener los usuarios.");
			setLoading(false)
		}
	};

	const getBadges = async () => {
		try {
			setLoading(true)
			const response = await BadgeService.getBadgesOrdered(1, 999, props.isAdmin);

			setState((prevState) => (
				{
					...prevState,
					rowsAssignBadges: response.data.content,
				}
			));

			setLoading(false)

		} catch (error) {
			setLoading(false)
		}

	}

	useEffect(() => {
		if (open) {
			getBadges();
			getUsers();
		}

	}, [open]);

	return (
		<>
			{props.playerSection ? (
				<div className="insignias-asignadas-title-asignar" onClick={handleClickOpen}>
					<div><AddCircleOutlineIcon/></div> {t("menu.badge-panel-dialog-button-assign-badge", "Share badge").toUpperCase()}
				</div>
			) : (
				<Button
					className="button-table mr-1"
					variant="contained"
					color="secondary"
					onClick={handleClickOpen}
				>
					<AddIcon />
					{t("menu.badge-panel-dialog-button-assign-badge", "Share badge")}
				</Button>
			)}

			<DialogStepper
				usersList={rowsUsers}
				assignBadgesList={rowsAssignBadges}
				isLoading={loading}
				isOpen={open}
				handleClose={handleClose}
				onShareBadge={(user, selectBadge, userComment) => onFormSubmit(user, selectBadge, userComment)}
			/>
		</>
	);
}
