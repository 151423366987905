import jsonFetch from "../../../../../utils/jsonFetch";
import environment from "../../../../../../../libs/environment";

const UserPanelApi = (token) => {
    return {
        retreiveUsers: () => {
            return jsonFetch({
                endpoint: `${environment.motivarnosBackend}/admin/listar/usuarios`,
                method: "GET",
                token: token
            });
        }
    }
}

export default UserPanelApi;