import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import './Word.css';

class Clue extends PureComponent {
  static propTypes = {
    word: PropTypes.string.isRequired,
    clue: PropTypes.string.isRequired,
    controls: PropTypes.bool,
    onRemove: PropTypes.func,
  };

  static defaultProps = {
    controls: false,
    onRemove: () => {},
  };

  render() {
    return (
      <li className="Word">
        <span className="Word-label">{this.props.clue + " =  "+ this.props.word}</span>    
         {this.props.controls &&
          <button key={this.props.word+""+this.props.clue} className="Word-removeBtn" onClick={this.props.onRemove}>
            <span className="u-srOnly"></span>
          </button>
         }          
        </li>   
    );
  }
}

export default Clue;
