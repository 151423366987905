import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import './Wordsearch.css';
import Letter from './Letter';
import { create2dArray } from './utils';

class Cursor {
  constructor(firstLetter, lastLetter) {
    this.firstLetter = firstLetter;
    this.adjRow = Math.min(Math.max(lastLetter[0] - firstLetter[0], -1), 1);
    this.adjCol = Math.min(Math.max(lastLetter[1] - firstLetter[1], -1), 1);
    this.forwards = this.adjRow >= 0 && this.adjCol >= 0;

    this.dir = '';
    if (this.adjRow < 0) {
      this.dir += 'N';
    } else if (this.adjRow > 0) {
      this.dir += 'S';
    }
    if (this.adjCol < 0) {
      this.dir += 'E';
    } else if (this.adjCol > 0) {
      this.dir += 'W';
    }
  }

  getPosition(index) {
    return {
      row: this.firstLetter[0] + (index * this.adjRow),
      col: this.firstLetter[1] + (index * this.adjCol),
    };
  }
}


export default Cursor;
