export const RESET_STATE = "RESET_STATE";
export const RESET_STORE = "RESET_STORE";

export const NEWS_LIST_REQUEST = "NEWS_LIST_REQUEST";
export const NEWS_LIST_SAVED = "NEWS_LIST_SAVED";

export const NEWS_LIST_SAVE = "NEWS_LIST_SAVE";
export const NEWS_LIST_UPDATE = "NEWS_LIST_UPDATE";
export const NEWS_LIST_DELETE = "NEWS_LIST_DELETE";

export const NEWS_LIST_CHARGING = "NEWS_LIST_CHARGING";
export const NEWS_SAVE_CHARGING = "NEWS_SAVE_CHARGING";

export const NEWS_LIST_ERROR = "NEWS_LIST_ERROR";
