import * as conditionsTypes from "../types/conditionsTypes";

import UserServices from "../components/app/admin/Services/UserService";

const {
  RESET_STATE,
  CONDITIONS_LIST_REQUEST,
  CONDITIONS_LIST_SAVED,
  CONDITIONS_LIST_CHARGING,
  CONDITIONS_LIST_ERROR,
} = conditionsTypes;

export const getConditionsRequest = (payload) => async (dispatch, getState) => {
  try {
    const { pages } = getState().conditionsReducer;

    if (!pages.includes(payload.number) || payload.reload) {
      dispatch({
        type: CONDITIONS_LIST_CHARGING,
      });
      const responseLogin = await UserServices.getUsersAccepted(
        payload.number,
        5
      );
      dispatch({
        type: CONDITIONS_LIST_REQUEST,
        payload: {
          ...responseLogin.data,
          number: payload.number,
          filter: "",
        },
      });
    } else {
      dispatch({
        type: CONDITIONS_LIST_SAVED,
      });
    }
  } catch (error) {
    dispatch({
      type: CONDITIONS_LIST_ERROR,
      payload: error.response ? error.response.data : error,
    });
  }
};

export const resetState = () => async (dispatch) => {
  dispatch({
    type: RESET_STATE,
  });
};
