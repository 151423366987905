import React from "react";
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import CloseIcon from "@material-ui/icons/Close";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import VisibilityIcon from "@material-ui/icons/Visibility";
import TextField from "@material-ui/core/TextField";

/*******Services******* */

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  textField: {
    width: "50ch",
    marginBottom: "5px",
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

export default function ShowDetailsBadgeDialog(props) {
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  /***********Data Binding Form******* */

  return (
    <>
      <Tooltip title="Ver detalles">
        <VisibilityIcon
          fontSize="small"
          className="cursor-pointer"
          onClick={handleClickOpen}
        />
      </Tooltip>

      <Dialog
        maxWidth="sm"
        fullWidth={true}
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <DialogTitle id="customized-dialog-title" onClose={handleClose}>
          Detalles de la insignia
        </DialogTitle>

        <DialogContent dividers>
          <TextField
            value={props.name}
            disabled={true}
            label="Insignia"
            variant="outlined"
            className="mt-2"
            fullWidth
          />

          <TextField
            value={props.description}
            multiline
            rows={3}
            disabled={true}
            label="Descripción"
            variant="outlined"
            className="mt-2"
            fullWidth
          />

          <TextField
            value={props.user.email}
            disabled={true}
            label="Correo del usuario"
            variant="outlined"
            className="mt-2"
            fullWidth
          />

          <TextField
            value={props.points}
            disabled={true}
            label="Puntos"
            variant="outlined"
            className="mt-2"
            fullWidth
          />

          <TextField
            value={props.isActive ? "Si" : "No"}
            disabled={true}
            label="Activo"
            variant="outlined"
            className="mt-2"
            fullWidth
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="secondary">
            Cerrar
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
