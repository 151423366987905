import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import Slide from "@material-ui/core/Slide";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import InputLabel from "@material-ui/core/InputLabel";
import { SpinnerCircular } from "spinners-react";
import toastr from "toastr";
import Button from "@material-ui/core/Button";
import Tooltip from "@material-ui/core/Tooltip";
import { useTranslation } from "react-i18next";
import { AppWrapper } from "../GlobalStyles";
import FindRegistersDialog from "../../common/Dialogs/FindRegistersDialog";
import { connect } from "react-redux";

import {
  saveAwardRequest,
  updateAwardRequest,
} from "../../../../../actions/awardsActions";

/*********Icons********* */
import AddIcon from "@material-ui/icons/Add";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import EditIcon from "@material-ui/icons/Edit";

/********Services********* */
import environment from "../../../../../libs/environment";
import FileService from "../../Services/FileService";

/**********Hooks********** */
import { useFormatProps } from "../../hooks/useFormatProps";

/*********Dialog********* */
import ImageDialog from "../../common/Dialogs/ImageDialog";

const useStyles = makeStyles((theme) => ({
  appBar: {
    position: "relative",
  },
  formControl: {
    width: "100%",
    marginTop: "1rem",
  },
  container: {
    paddingTop: theme.spacing(4),
  },
  title: {
    marginLeft: theme.spacing(2),
    color: "black",
    flex: 1,
  },
  root: {
    "& > *": {
      margin: theme.spacing(1),
    },
  },
  input: {
    display: "none",
  },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function AwardDialog(props) {
  /***************Styles************ */
  const classes = useStyles();
  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    getContentAnchorEl: () => null,
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };
  /***************Styles************ */

  /***********General functions and constants******** */

  const [open, setOpen] = React.useState(false);
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();
  const [nameError, setNameError] = useState(false);
  const [descriptionError, setDescriptionError] = useState(false);
  const [pointsError, setPointsError] = useState(false);
  const [actualStockError, setActualStockError] = useState(false);
  const [isStockError, setIsStockError] = useState(false);
  const [teamsError, setTeamsError] = useState(false);
  const [usersError, setUsersError] = useState(false);

  const [isActiveError, setIsActiveError] = useState(false);
  const [imageObject, setImageObject] = useState(null);
  const [imageObjectFromDialog, setImageObjectFromDialog] = useState(true);

  const [teamsHooks, rowsHooks, secondaryTeamsHooks] = useFormatProps(
    props.rowTeams ? props.rowTeams : [],
    props.rowsUsers ? props.rowsUsers : [],
    props.rowSecondaryTeams ? props.rowSecondaryTeams : []
  );

  function formatPropsEdit(array) {
    let arrayFormatted = [];
    array.forEach((element) => {
      arrayFormatted.push(element.id);
    });
    return arrayFormatted;
  }
  const [
    {
      name,
      points,
      description,
      image,
      isStock,
      actualStock,
      teams,
      users,
      isActive,
      isApprovalNeeded,
      teamsProps,
      usersProps,
      secondaryTeams,
      rowSecondaryTeams,
    },
    setState,
  ] = useState({
    name: props.name ? props.name : "",
    points: props.price ? props.price : "",
    description: props.description ? props.description : "",
    isStock: props.isStock ? props.isStock : false,
    actualStock: props.actualStock ? props.actualStock : "",
    image: props.image ? props.image : "",
    teams: formatPropsEdit(props.teams ? props.teams : []),
    users: formatPropsEdit(props.users ? props.users : []),
    isActive: props.isActive ? props.isActive : false,
    isApprovalNeeded: props.isApprovalNeeded ? props.isApprovalNeeded : false,
    teamsProps: teamsHooks && teamsHooks.length > 0 ? teamsHooks : [],
    usersProps: rowsHooks && rowsHooks.length > 0 ? rowsHooks : [],
    secondaryTeams: formatPropsEdit(
      props.secondaryTeams ? props.secondaryTeams : []
    ),
    rowSecondaryTeams:
      secondaryTeamsHooks && secondaryTeamsHooks.length > 0
        ? secondaryTeamsHooks
        : [],
  });

  const handleClickOpen = () => {
    setOriginalState(true);
  };

  function handleChange({ target: { name, value } }) {
    setState((prevState) => ({ ...prevState, [name]: value }));
    if (name === "isStock") {
      setState((prevState) => ({
        ...prevState,
        actualStock: props.actualStock ? props.actualStock : "",
      }));
    }
  }

  function setSecondaryTeams(secondaryTeams) {
    setState((prevState) => ({ ...prevState, 'secondaryTeams': secondaryTeams }));
  }

  function setTeams(teams) {
    setState((prevState) => ({ ...prevState, 'teams': teams }));
  }

  function setUsers(users) {
    console.log(users)
    setState((prevState) => ({ ...prevState, 'users': users }));
  }

  function setName(option) {
    return (option.name == '' || option.name  == null) ? t(
      "admin.user-panel-user-dialog-input-select-without-name",
      "Without name") : option.name;
  }

  function handleChangeImage(e) {
    if (e.target.files && e.target.files[0]) {
      setState((prevState) => ({ ...prevState, image: e.target.files[0] }));
      setImageObject(e.target.files[0]);
      setImageObjectFromDialog(false);
      readURL(e);
    }
  }

  function readURL(e) {
    if (e.target.files && e.target.files[0]) {
      var reader = new FileReader();

      reader.onload = function (e) {
        document
          .getElementById("img-designed")
          .setAttribute("src", e.target.result);
      };
      reader.readAsDataURL(e.target.files[0]);
    }
  }

  function setImageDialog(e) {
    setImageObjectFromDialog(e);
    setState((prevState) => ({ ...prevState, image: e }));
  }

  const handleClose = () => {
    setOriginalState();
  };

  function setOriginalState(open = false) {
    setState((prevState) => ({
      ...prevState,
      name: props.name ? props.name : "",
      points: props.price ? props.price : "",
      description: props.description ? props.description : "",
      isStock: props.isStock ? props.isStock : false,
      actualStock: props.actualStock ? props.actualStock : "",
      teams: formatPropsEdit(props.teams ? props.teams : []),
      users: formatPropsEdit(props.users ? props.users : []),
      isApprovalNeeded: props.isApprovalNeeded ? props.isApprovalNeeded : false,
      secondaryTeams: formatPropsEdit(
        props.secondaryTeams ? props.secondaryTeams : []
      ),
      image: props.image ? props.image : "",
      isActive: props.isActive ? props.isActive : false,
    }));
    setOpen(open);
  }
  /***********General functions and constants******** */

  /***************************CRUD functions*************** */
  async function onFormSubmit() {
    setNameError(false);
    setDescriptionError(false);
    setPointsError(false);
    setIsStockError(false);
    setActualStockError(false);
    setTeamsError(false);
    setUsersError(false);

    setIsActiveError(false);

    if (name === "") {
      setNameError(true);
      toastr.error(
        t(
          "menu.trivia-panel-dialog-add-test-message-error-name",
          "The name is required"
        )
      );
      return;
    }

    if (description === "") {
      setDescriptionError(true);
      toastr.error(
        t(
          "menu.trivia-panel-dialog-add-test-message-error-description",
          "Description is required"
        )
      );
      return;
    }

    if (props.typeModal !== "modalEdit") {
      if (image === "") {
        toastr.error(
          t(
            "menu.badge-panel-dialog-message-error-image-required",
            "Image is required"
          )
        );
        return;
      }
    }

    if (points === "") {
      setPointsError(true);
      toastr.error(
        t(
          "menu.badge-panel-dialog-message-error-points-required",
          "Points are required"
        )
      );
      return;
    }

    if (isStock === "") {
      setIsStockError(true);
      toastr.error(
        t(
          "menu.badge-panel-dialog-message-error-stock-required",
          "Stock is required"
        )
      );
      return;
    }

    if (isStock === true) {
      if (actualStock === "") {
        setActualStockError(true);
        toastr.error(
          t(
            "menu.award-panel-dialog-message-error-number-stock",
            "Stock number is required"
          )
        );
        return;
      }
    }

    if (
      (teams === "" || teams.length === 0 || !teams) &&
      (users === "" || users.length === 0 || !users)
    ) {
      setTeamsError(true);
      setUsersError(true);

      toastr.error(
        t(
          "admin.header-dropdown-dialog-get-teams-error",
          "An error occurred while trying to get the computers"
        )
      );
      return;
    }

    if (isActive === "") {
      setIsActiveError(true);
      toastr.error(
        t(
          "menu.trivia-panel-dialog-add-test-message-error-active",
          "You must choose if it is active or not"
        )
      );
      return;
    }

    /***********Format variables****** */
    let teamsFormatted = [];
    teams.forEach((element) => {
      teamsFormatted.push({
        id: element,
      });
    });

    let usersFormatted = [];
    users.forEach((element) => {
      usersFormatted.push({
        id: element,
      });
    });

    let secondaryTeamsFormatted = [];
    secondaryTeams.forEach((element) => {
      secondaryTeamsFormatted.push({
        id: element,
      });
    });

    /***********Format variables****** */

    let urlImage =
      image && image !== "" && !imageObjectFromDialog
        ? !imageObjectFromDialog
          ? ""
          : imageObjectFromDialog
        : image;

    if (image && image !== "") {
      if (imageObject && !imageObjectFromDialog) {
        setLoading(true);
        const formData = new FormData();
        formData.append("file", imageObject);
        await FileService.uploadFile(formData, "gift", null, (p) => console.info("Avance " + p + "%"))
          .then((response) => {
            urlImage = response.data.url;
            toastr.success(
              t(
                "menu.trivia-panel-dialog-test-question-add-dialog-message-success-add-image",
                "Image added successfully"
              )
            );
          })
          .catch(() => {
            toastr.error(
              t(
                "menu.trivia-panel-dialog-test-question-add-dialog-message-error-add-image",
                "An error occurred while trying to add the image"
              )
            );
          });
      }
    }

    let json = {
      name: name,
      price: points,
      image: urlImage,
      description: description,
      isStock: isStock,
      actualStock: actualStock,
      teams: teamsFormatted,
      users: usersFormatted,
      secondaryTeams: secondaryTeamsFormatted,
      isApprovalNeeded: isApprovalNeeded,
      isActive: isActive,
    };

    setLoading(true);

    let status;

    if (props.typeModal === "modalEdit") {
      await props
        .updateAwardRequest({ ...json, id: props.id })
        .then((r) => (status = r));
    } else {
      await props.saveAwardRequest(json).then((r) => (status = r));
    }

    if (status.status === "ERROR") {
      if (
        status.error &&
        status.error.data.message === "invalid.duplicatedname"
      ) {
        toastr.error(
          t(
            "menu.award-panel-dialog-message-error-save-award-one",
            "There is already an award with this name"
          )
        );
      } else {
        toastr.error(
          t(
            "menu.award-panel-dialog-message-error-save-award-two",
            "An error occurred while trying to save the prize"
          )
        );
      }
      setLoading(false);
    } else {
      toastr.success(
        t(
          "menu.award-panel-dialog-message-success-save-award",
          "Prize saved successfully"
        )
      );
      setLoading(false);
      handleClose();
    }
  }
  return (
    <>
      {props.typeModal === "modalEdit" ? (
        <Tooltip title={t("admin.actions-edit", "Edit")}>
          <EditIcon
            fontSize="small"
            onClick={handleClickOpen}
            className="cursor-pointer"
          />
        </Tooltip>
      ) : (
        <Button
          className="button-table mr-1"
          variant="contained"
          color="secondary"
          onClick={handleClickOpen}
        >
          <AddIcon />
          {t("menu.award-panel-dialog-title", "Award")}
        </Button>
      )}

      <Dialog
        fullScreen
        open={open}
        onClose={handleClose}
        TransitionComponent={Transition}
      >
        <AppWrapper>
          <AppBar className={classes.appBar}>
            <Toolbar>
              <IconButton edge="start" onClick={handleClose} aria-label="close">
                <CloseIcon />
              </IconButton>
              <Typography variant="h6" className={classes.title}>
                {t("menu.award-panel-award-management", "Award management")}
              </Typography>
            </Toolbar>
          </AppBar>

          {loading && (
            <div className="div-spinner-modal">
              <Container maxWidth="lg" className={classes.container}>
                {" "}
                <Grid container spacing={1}>
                  <SpinnerCircular size={90} />
                </Grid>
              </Container>
            </div>
          )}

          {!loading && (
            <Container maxWidth="lg" className={classes.container}>
              <Grid container spacing={1}>
                <Grid item xs={12} md={6} lg={6}>
                  <TextField
                    onChange={handleChange}
                    error={nameError}
                    value={name}
                    name="name"
                    id="name"
                    label={t(
                      "menu.award-panel-dialog-award-name",
                      "Award name"
                    )}
                    variant="outlined"
                    className="mt-2"
                    fullWidth
                  />
                </Grid>

                <Grid item xs={12} md={6} lg={6}>
                  <TextField
                    onChange={handleChange}
                    error={pointsError}
                    value={points}
                    type="number"
                    name="points"
                    id="points"
                    label={t(
                      "menu.award-panel-dialog-award-points",
                      "Award points"
                    )}
                    variant="outlined"
                    className="mt-2"
                    fullWidth
                  />
                </Grid>
              </Grid>

              <Grid container spacing={1}>
                <Grid item xs={12} md={6} lg={6}>
                  <TextField
                    onChange={handleChange}
                    error={descriptionError}
                    value={description}
                    name="description"
                    id="description"
                    label={t(
                      "menu.award-panel-dialog-award-description",
                      "Award description"
                    ) + ` (${(description? description.length: '0')}/650)`}
                    variant="outlined"
                    className="mt-2"
                    fullWidth
                    inputProps={{ maxLength: 650 }}
                  />
                </Grid>

                <Grid
                  item
                  xs={12}
                  md={6}
                  lg={6}
                  className="padding-custom-button"
                >
                  <Grid container spacing={1}>
                    <Grid item xs={5} md={5} lg={5}>
                      <div className="h-100">
                        <input
                          accept="image/*"
                          className={classes.input}
                          id="contained-button-file"
                          type="file"
                          onChange={(e) => handleChangeImage(e)}
                        />
                        <label htmlFor="contained-button-file">
                          <Button
                            variant="contained"
                            color="primary"
                            component="span"
                            className="button-table h-100 w-100"
                          >
                            {image === ""
                              ? " " +
                                t(
                                  "menu.trivia-panel-dialog-add-test-input-image-upload",
                                  "Upload image"
                                )
                              : " " +
                                t(
                                  "menu.trivia-panel-dialog-add-test-input-image-change",
                                  "Change image"
                                )}
                          </Button>
                        </label>
                      </div>
                    </Grid>

                    <Grid item xs={5} md={5} lg={5}>
                      <ImageDialog setImageDialog={(e) => setImageDialog(e)} />
                    </Grid>
                    <Grid
                      item
                      xs={2}
                      md={2}
                      lg={2}
                      className="div-img-designed"
                    >
                      {image && image !== "" && !imageObjectFromDialog && (
                        <img
                          id="img-designed"
                          className="img-predesigned"
                          src=""
                          alt=""
                        />
                      )}
                      {image && image !== "" && imageObjectFromDialog && (
                        <img
                          id="img-designed"
                          className="img-predesigned"
                          src={`${environment.motivarnosBackend}${image}`}
                          alt=""
                        />
                      )}
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>

              <Grid container spacing={1}>
                <Grid item xs={12} md={6} lg={6}>
                  <FormControl
                    variant="outlined"
                    className={classes.formControl}
                  >
                    <InputLabel id="isStock-select-outlined-label">
                      {t("menu.award-panel-table-stock", "Stock")}
                    </InputLabel>
                    <Select
                      onChange={handleChange}
                      error={isStockError}
                      value={isStock}
                      labelId="isStock"
                      id="isStock"
                      name="isStock"
                      label={t("menu.award-panel-table-stock", "Stock")}
                    >
                      <MenuItem value={false}>
                        {t("menu.award-panel-table-unlimited", "Unlimited")}{" "}
                      </MenuItem>
                      <MenuItem value={true}>
                        {t("menu.award-panel-table-limited", "Limited")}{" "}
                      </MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                {/*************STOCK************* */}
                {isStock && (
                  <Grid item xs={12} md={6} lg={6}>
                    <TextField
                      onChange={handleChange}
                      error={actualStockError}
                      value={actualStock}
                      type="number"
                      name="actualStock"
                      id="actualStock"
                      label={t(
                        "menu.award-panel-dialog-stock-quatity",
                        "Stock quantity"
                      )}
                      variant="outlined"
                      className="mt-2"
                      fullWidth
                    />
                  </Grid>
                )}

                {rowSecondaryTeams && rowSecondaryTeams.length > 0 && (
                  <FindRegistersDialog
                    setValue={setSecondaryTeams}
                    setName={setName}
                    label={t(
                      "admin.header-dropdown-dialog-secondary-teams-input",
                      "Secondary Teams"
                    )}
                    rowsSelected={secondaryTeams}
                    rows={rowSecondaryTeams}
                  />
                )}

                {teamsProps && teamsProps.length > 0 && (
                  <FindRegistersDialog
                    setValue={setTeams}
                    setName={setName}
                    label={t(
                      "admin.user-panel-user-dialog-input-groups",
                      "Groups"
                    )}
                    rowsSelected={teams}
                    rows={teamsProps}
                  />
                )}

                {props.rowsUsers && props.rowsUsers.length > 0 && (
                  <FindRegistersDialog
                    setValue={setUsers}
                    label={t(
                      "menu.trivia-panel-dialog-add-test-select-players",
                      "Players"
                    )}
                    rowsSelected={users}
                    rows={props.rowsUsers}
                  />
                )}

                <Grid item xs={12} md={6} lg={6}>
                  <FormControl
                    variant="outlined"
                    className={classes.formControl}
                  >
                    <InputLabel id="isActive-select-outlined-label">
                      {t(
                        "admin.header-dropdown-view-conditions-table-state",
                        "State"
                      )}
                    </InputLabel>
                    <Select
                      onChange={handleChange}
                      error={isActiveError}
                      value={isActive}
                      labelId="isActive"
                      id="isActive"
                      name="isActive"
                      label="Estado"
                    >
                      <MenuItem value={true}>
                        {t(
                          "admin.header-dropdown-view-conditions-table-active-state",
                          "Active"
                        )}{" "}
                      </MenuItem>
                      <MenuItem value={false}>
                        {t(
                          "admin.header-dropdown-view-conditions-table-blocked-state",
                          "Blocked"
                        )}
                      </MenuItem>
                    </Select>
                  </FormControl>
                </Grid>

                <Grid item xs={12} md={6} lg={6}>
                  <FormControl
                    variant="outlined"
                    className={classes.formControl}
                  >
                    <InputLabel id="isApprovalNeeded-select-outlined-label">
                      {t(
                        "is-approval-needed",
                        "Is Approval Needed"
                      )}
                    </InputLabel>
                    <Select
                      onChange={handleChange}
                      error={isActiveError}
                      value={isApprovalNeeded}
                      labelId="isApprovalNeeded"
                      id="isApprovalNeeded"
                      name="isApprovalNeeded"
                      label="Se necesita aprovacion"
                    >
                      <MenuItem value={true}>
                        {t(
                          "admin.header-dropdown-dialog-notifications-input-item-yes",
                          "Yes"
                        )}{" "}
                      </MenuItem>
                      <MenuItem value={false}>
                        {t(
                          "admin.header-dropdown-dialog-notifications-input-item-no",
                          "No"
                        )}
                      </MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>

              <Button
                className="button-table mt-2 mr-1"
                onClick={handleClose}
                variant="contained"
                color="secondary"
              >
                {t("menu.trivia-panel-dialog-add-test-button-close", "Close")}
              </Button>

              <Button
                className="button-table mt-2"
                variant="contained"
                color="primary"
                onClick={() => onFormSubmit()}
              >
                {t("menu.award-panel-dialog-generate-award", "Generate award")}
              </Button>
            </Container>
          )}
        </AppWrapper>
      </Dialog>
    </>
  );
}

const mapDispatchToProps = {
  saveAwardRequest,
  updateAwardRequest,
};

export default connect(null, mapDispatchToProps)(AwardDialog);
