import { combineReducers } from "redux";
import authReducer from "../components/app/public/auth/AuthenticationActions";
import generalReducer from "../reducers/generalReducer";
import badgesReducer from "../reducers/badgesReducer";
import metricsReducer from "../reducers/metricsReducer";
import challengesReducer from "../reducers/challengesReducer";
import betsReducer from "../reducers/betsReducer";
import awardsReducer from "../reducers/awardsReducer";
import newsReducer from "../reducers/newsReducer";

import pointsCoinsReducer from "../reducers/pointsCoinsReducer";
import feelingsReducer from "../reducers/feelingsReducer";
import notificationsVoteReducer from "../reducers/notificationsVoteReducer";
import conditionsReducer from "../reducers/conditionsReducer";

import tournamentsReducer from "../reducers/tournamentsReducer";

import testsReducer from "../reducers/testsReducer";
import missionsReducer from "../reducers/missionsReducer";
import usersReducer from "../reducers/usersReducer";
import letterSoupReducer from "../reducers/letterSoupReducer";

import suggestionInboxReducer from "../reducers/suggestionInboxReducer";

export const createReducer = (asyncReducers) => {
  return combineReducers({
    authReducer,
    generalReducer,
    badgesReducer,
    metricsReducer,
    challengesReducer,
    betsReducer,
    awardsReducer,
    newsReducer,
    pointsCoinsReducer,
    tournamentsReducer,
    feelingsReducer,
    notificationsVoteReducer,
    conditionsReducer,
    testsReducer,
    letterSoupReducer,
    missionsReducer,
    usersReducer,
    suggestionInboxReducer,
    //ASYNC REDUCERS
    ...asyncReducers,
  });
};
