import React, { useState, useEffect } from "react";
import SignUpModel from "../../../app/models/UserModel"
import { useSelector } from "react-redux";
import Error from "../../common/errorHandler/ErrorHandler"
import CircularProgress from "@material-ui/core/CircularProgress/CircularProgress";
import { FormattedMessage } from "react-intl";
import Phone from "../../common/fields/Phone";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import Container from "@material-ui/core/Container/Container";
import DialogTitle from "../../common/controls/DialogTitle";
import Company from "../../common/fields/Company";
import Email from "../../common/fields/Email";
import Name from "../../common/fields/Name";
import LastName from "../../common/fields/LastName";
import UserName from "../../common/fields/UserName";
import PasswordCreation from "../../common/controls/PasswordCreation";
import TermsAndConditionsButton from "../../common/actions/TermsAndConditionsButton";
import { signup } from "../../public/auth/AuthenticationActions";
import { useDispatch } from "react-redux";
import { inputStyles, buttonStyles } from "../../styles/styles";
import { useTranslation } from 'react-i18next';

const useReduxSelector = () =>
  useSelector((state) => ({
    isLoading: state.authReducer.isLoading,
    signupCompleted: state.authReducer.signupCompleted,
    error: state.authReducer.error,
  }));
  
const SignupLanding = ({ open, onClose }) => {
  const { isLoading, signupCompleted, error } = useReduxSelector();
  const [signUpModel, setSignUpModel] = useState(new SignUpModel());
  var signUpModelVariable = signUpModel;
  var inputClasses = inputStyles();
  var buttonClasses = buttonStyles();
  const dispatch = useDispatch();
  const signupHandler = () => {
      dispatch(
        signup({
          username: signUpModel.getUsername(),
          password: signUpModel.getPassword(),
          email: signUpModel.getEmail(),
          name: signUpModel.getNombre(),
          lastName: signUpModel.getApellido(),
          phone: signUpModel.getTelefono(),
          companyName: signUpModel.getEmpresaNombre(),
          groupName: signUpModel.getGrupoNombre(),
        })
      );
  };
  const { t, i18n } = useTranslation();


  var isValid =
          signUpModel.ValidateCompany() &&
          signUpModel.ValidateUserName() &&
          signUpModel.ValidatePassword() &&
          signUpModel.ValidateConfirmPassword() &&
          signUpModel.ValidateEmail();

  const setValueInModel = (value,nameValue) =>{
    signUpModelVariable.model[nameValue] = value;
    setSignUpModel(new SignUpModel(signUpModelVariable.model));
  }  

  return (
    <>
      <Dialog open={open} onClose={onClose}>
        <DialogTitle onClose={onClose}>
          <h2 style={{ textAlign: "center" }}>
            <FormattedMessage id="signup.title" defaultMessage={t('create-your-free-accoun.label', 'Create your free account')} />
          </h2>
        </DialogTitle>
        <DialogContent>
          <Container
            style={{display: "flex", flexDirection: "column" }}
          >
            {isLoading && (
              <CircularProgress
                style={{ alignSelf: "center", justifySelf: "center" }}
                color="primary"
              />
            )}
            {!isLoading && error && (
              <Error message={error.error?.message}></Error>
            )}
            {!isLoading && !signupCompleted && (
              <span style={{display:'flex', flexWrap:'wrap', justifyContent:'space-around'}}>
                          <Company style={{width:'50%'}} company = {signUpModelVariable.getEmpresaNombre()}
                                  //className = {inputClasses.lightPinkInput}
                                  onChange = {(company) => setValueInModel(company, "empresanombre")}
                            ></Company>
                          <Email style={{width:'50%'}}  email = {signUpModelVariable.getEmail()}
                                  //className = {inputClasses.pinkInput}
                                  onChange = {(email) => setValueInModel(email, "email")}
                            ></Email>
                          <Name style={{width:'50%'}}  name = {signUpModelVariable.getNombre()}
                                  //className = {inputClasses.purpleInput}
                                onChange = {(name) => setValueInModel(name, "nombre")}
                            ></Name>
                          <LastName style={{width:'50%'}}  lastname = {signUpModelVariable.getApellido()}
                                  //className = {inputClasses.lightPinkInput}
                                    onChange = {(lastName) => setValueInModel(lastName, "apellido")} 
                            ></LastName>
                          <Phone style={{width:'50%'}} 
                                  phone={signUpModelVariable.getTelefono()}
                                  //className = {inputClasses.pinkInput}
                                  onChange={(phone) => setValueInModel(phone, "telefono")}
                            ></Phone>
                          <UserName  style={{width:'50%'}} 
                                  username = {signUpModelVariable.getUsername()}
                                  specificationsUserName = ""
                                  //className = {inputClasses.pinkInput}
                                  onChange = {(username) => setValueInModel(username,"username")}
                            ></UserName>
                          <PasswordCreation style={{width:'50%'}} 
                            password = {signUpModelVariable.getPassword()} 
                            confirmPassword = {signUpModelVariable.getConfirmPassword()}
                            className = {["",""]}
                            onChange = {(passwordCreate) => {
                              setValueInModel(passwordCreate.password,"password");
                              setValueInModel(passwordCreate.confirmPassword,"confirmPassword")
                          }}
                          ></PasswordCreation>
              </span>
            )}
          </Container>
        </DialogContent>
        <TermsAndConditionsButton
          //className={buttonClasses.buttonPurple}
          termsAndConditions="../assets/tyc.html"
          actionPostTermsAndConditions={signupHandler}
          goToTermsAndConditions = {isValid}
        />
      </Dialog>
    </>
  );
};

export default SignupLanding;
