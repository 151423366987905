import React from "react";
import { Redirect } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import { login } from "components/app/public/auth/AuthenticationActions";

/**
 * Allow login from a code given in backend
 */
const AuthComponent = () => {
  let query = new URLSearchParams(useLocation().search);
  const dispatch = useDispatch();
  const code = query.get("code");

  if (code) {
    dispatch(login({ token: code })).then((e) => {
      if (e && e.error) {
      } else {
        window.location.reload();
      }
    });
  }
  if (!code) {
    return (
      <Redirect
        to={{
          pathname: "/panel",
        }}
      />
    );
  } else {
    return (
      <Redirect
        to={{
          pathname: "/",
        }}
      />
    );
  }
};

export default AuthComponent;
