import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import Avatar from "../../../../Avatar";
import { useTranslation } from "react-i18next";
import GeneralFunctions from "../../libs/GeneralFunctions";
import { es } from "date-fns/locale";
import { connect } from "react-redux";

import { getBetsRequest } from "../../../../../actions/betsActions";

import "./styles.scss";

const useStyles = makeStyles((theme) => ({
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    overflow: "auto",
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
  },
  fixedHeight: {
    height: 240,
  },
  root: {
    "& > *": {
      marginTop: theme.spacing(2),
    },
  },
}));

function Bets(props) {
  const { t, i18n } = useTranslation();

  useEffect(() => {
    props.getBetsRequest(1);

    // eslint-disable-next-line
  }, []);

  const classes = useStyles();

  return (
    <main className={classes.content}>
      <div className={classes.appBarSpacer} />
      <Container maxWidth="lg" className={classes.container}>
        <h3>
          {t("admin.bets-table-between-players", "Apuestas entre jugadores")}
        </h3>

        <div className="pending-bets">
          <table className="pending-bets-table">
            <thead>
              <tr>
                <th>{t("gambler.label", "Gambler")}</th>
                <th>{t("opponent.label", "Opponent")}</th>
                <th>{t("menu.rigth-list-item-metrics", "Metrics")}</th>
                <th>{t("place-in-ranking.label", "Place in ranking")}</th>
                <th>{t("points.label", "Points")}</th>
                <th>{t("date.label", "Date")}</th>
                <th>
                  {t(
                    "admin.header-dropdown-view-conditions-table-state",
                    "State"
                  )}
                </th>
                <th>{t("admin.bets-table-winner", "Winner")}</th>
              </tr>
            </thead>

            {props.betsReducer.bets.map((data, index) => {
              return (
                <tr key={index} className="table-content">
                  <td>
                    <span className="gambler">
                      <span className="icon">
                        <Avatar size="1.5em" style={{ borderWidth: "1px" }} />
                      </span>
                      {data.challenger !== null ? data.challenger.username : ""}
                    </span>
                  </td>
                  <td>
                    <span className="opponent">
                      <span className="icon">
                        <Avatar size="1.5em" style={{ borderWidth: "1px" }} />
                      </span>
                      {data.opponent !== null ? data.opponent.username : ""}
                    </span>
                  </td>
                  <td>{data.metricConf ? data.metricConf.name : "N/A"}</td>
                  <td>
                    {data.condition === "LESS_THAN"
                      ? t("me-below.label", "Me below")
                      : t("me-above.label", "Me above")}
                  </td>
                  <td>{data.points}</td>
                  <td>
                    {GeneralFunctions.formatNewDate(
                      data.date1,
                      es
                    )}
                  </td>
                  <td>
                    <span className="status pending">{data.status}</span>
                  </td>
                  <td>{data.winner !== null ? data.winner : ""}</td>
                </tr>
              );
            })}
          </table>
        </div>
      </Container>
    </main>
  );
}

const mapStateToProps = (betsReducer) => betsReducer;

const mapDispatchToProps = {
  getBetsRequest,
};

export default connect(mapStateToProps, mapDispatchToProps)(Bets);
