import React from "react";
import ReactPlayer from "react-player";
import environment from "../../../../../../libs/environment";

const imageDisplayer = ({ urlFile }) => {
    return (
        <span style={{ width: "100%" }}>
            <img
                src={environment.motivarnosBackend + urlFile}
                alt=""
                style={{ width: "100%" }}
            />
        </span>
    )

}

const audioDisplayer = ({ urlFile }) => {

    return (
        <span style={{ width: "100%" }}>
            <audio
                controls
                src={environment.motivarnosBackend + urlFile}
            />
        </span>
    )
}

const videoDisplayer = ({ urlFile }) => {

    return (
        <ReactPlayer
            url={environment.motivarnosBackend + urlFile}
            style={{ height: "auto", width: "100%" }}
        />
    )
}

const YoutubePlayer = ({videoSrc}) => {
  const srcToEmbed = src => {
    const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/;
    const match = src.match(regExp);

    return (match && match[2].length === 11)
      ? match[2]
      : null;
  }

  return (
      <iframe
        id="player"
        //height={height ? (height <= 360 ? "360" : `${height}`) : "360"}
        //width={width ? (width <= 640 ? "640" : `${width}`) : "640"}
        height="360"
        width="569"
        src={`https://www.youtube.com/embed/${srcToEmbed(videoSrc)}`}
        title="YouTube video player"
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
      ></iframe>
  )
}

const MissionFileItem = ({ fileURL, fileContentType }) => {

    const fileObjetList = {
        image: imageDisplayer,
        audio: audioDisplayer,
        video: videoDisplayer
    }

    const DisplayerComponent = fileObjetList[fileContentType]

    return (
        <>
            {
                fileContentType === 'image' &&
                <div className="mb-1 user-mission-preview">
                    <img
                        src={ environment.motivarnosBackend + fileURL}
                        alt="mission-user-answer"
                    />
                </div>
            }
            {
                fileContentType === 'audio' &&
                <div className="mb-1 user-mission-preview">
                    <audio
                        controls
                        src={ environment.motivarnosBackend + fileURL}
                    />
                </div>
            }
            {
                fileContentType === 'video' &&
                <ReactPlayer
                    src={ environment.motivarnosBackend + fileURL}
                    style={{ height: "auto", width: "100%" }}
                />
            }
            {
                !fileContentType && <YoutubePlayer videoSrc={fileURL} />
            }
        </>
    )

}

export default MissionFileItem;