import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { mediaBreakpoints } from "../../../../assets/publicTheme";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTranslation } from "react-i18next";
import pxToRem from "../../utils/pxToRem";
import Button from "@material-ui/core/Button/Button";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import Drawer from "@material-ui/core/Drawer";
import List from "@material-ui/core/List";
import Divider from "@material-ui/core/Divider";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import LoginIcon from "@material-ui/icons/ExitToApp";
import LanguageIcon from "@material-ui/icons/Language";
import MenuIcon from "@material-ui/icons/Menu";
import LanguageSelector from "../../../business/languageSelector/LanguageSelector";
import { FormattedMessage } from "react-intl";
import { openSignUp } from "./store/LandingReducer";
import { useDispatch } from "react-redux";
import HubspotMeetings from "./HubspotMeetings";

const HeaderLanding = (prop) => {
  const classes = useStyles();
  const isMobile = useMediaQuery(mediaBreakpoints.mobile);
  return (
    <div className={classes.root}>
      <AppBar
        position="static"
        elevation={0}
        style={{ backgroundColor: "transparent" }}
      >
        <Toolbar className={classes.toolbar}>
          <div className={classes.toolbarActions}>
            {isMobile ? (
              <MobileMenu />
            ) : (
              <>
                <DesktopMenu />
              </>
            )}
          </div>
        </Toolbar>
      </AppBar>
    </div>
  );
};
export default HeaderLanding;

const DesktopMenu = () => {
  const [openPrice, setOpenPrice] = useState(false);
  const [languageSelectorOpen, setLanguageSelectorOpen] = useState(false);
  const toggleLanguageSelector = () => {
    setLanguageSelectorOpen(!languageSelectorOpen);
  };
  const { t } = useTranslation();
  return (
    <>
      <LanguageSelector
        handleClose={toggleLanguageSelector}
        open={languageSelectorOpen}
      />

      <HubspotMeetings open={openPrice} onClose={() => setOpenPrice(false)} />

      <Button
        color="primary"
        variant="contained"
        style={{ marginRight: pxToRem(12) }}
        onClick={() => setOpenPrice(true)}
      >
        {t("contact-us.label", "Contact us")}
      </Button>
      <Button
        color="primary"
        variant="contained"
        onClick={toggleLanguageSelector}
      >
        <LanguageIcon />
      </Button>
    </>
  );
};

const MobileMenu = () => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const [opened, setOpened] = useState(false);
  const [languageSelectorOpen, setLanguageSelectorOpen] = useState(false);
  const { t } = useTranslation();

  const toggleLanguageSelector = () => {
    setLanguageSelectorOpen(!languageSelectorOpen);
  };

  const toggleDrawer = () => {
    setOpened(!opened);
  };

  const list = () => (
    <div
      className={classes.fullList}
      role="presentation"
      onClick={toggleDrawer}
      onKeyDown={toggleDrawer}
    >
      <List>
        <ListItem onClick={() => dispatch(openSignUp())} button>
          <ListItemIcon>
            <LoginIcon />
          </ListItemIcon>
          <Button
            color="primary"
            variant="contained"
            style={{ marginRight: pxToRem(12) }}
            onClick={()=> dispatch(openSignUp())}
          >
            <FormattedMessage
              id="header.createAccountBtn"
              defaultMessage={t(
                "create-your-free-accoun.label",
                "Create your free account"
              )}
            />
          </Button>
        </ListItem>
        <ListItem
          button
          key={"drawer-btn-language"}
          onClick={toggleLanguageSelector}
        >
          <ListItemIcon>
            <LanguageIcon />
          </ListItemIcon>
          <ListItemText
            primary={
              <FormattedMessage
                id="header.languageMiniBtn"
                defaultMessage="Language"
              />
            }
          />
        </ListItem>
      </List>
      <Divider />
    </div>
  );

  return (
    <>
      <LanguageSelector
        handleClose={toggleLanguageSelector}
        open={languageSelectorOpen}
      />
      <IconButton
        className={classes.menuButton}
        edge="end"
        color="inherit"
        onClick={toggleDrawer}
      >
        <MenuIcon />
      </IconButton>
      <Drawer anchor="top" open={opened} onClose={toggleDrawer}>
        {list()}
      </Drawer>
    </>
  );
};

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    position: 'absolute',
    right: '1px'
  },
  menuButton: {
    [mediaBreakpoints.desktop]: {
      marginRight: theme.spacing(2),
    },
  },
  toolbar: {
    minHeight: 160,
    alignItems: "flex-start",
    justifyContent: "space-between",
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(2),
  },
  logo: {
    alignSelf: "flex-end",
    marginLeft: theme.spacing(6),
    [mediaBreakpoints.mobile]: {
      width: pxToRem(140),
      alignSelf: "center",
      marginLeft: theme.spacing(2),
    },
  },
  toolbarActions: {
    marginTop: theme.spacing(6),
    [mediaBreakpoints.mobile]: {
      alignSelf: "start",
      marginTop: theme.spacing(2),
    },
  },
  fullList: {
    width: "auto",
  },
}));
