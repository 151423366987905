import React, { useEffect } from "react";
import Dialog from "@material-ui/core/Dialog";

import DialogContent from "@material-ui/core/DialogContent";

import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import { makeStyles } from "@material-ui/core/styles";
import Slide from "@material-ui/core/Slide";
import pxToRem from "../../utils/pxToRem";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const HubspotMeetings = ({ open, onClose }) => {
  const classes = useStyles();
  useEffect(() => {
    const script = document.createElement("script");

    script.src =
      "https://static.hsappstatic.net/MeetingsEmbed/ex/MeetingsEmbedCode.js";
    script.type = "text/javascript";
    script.async = true;

    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  });

  return (
    <>
      <Dialog
        open={open}
        onClose={onClose}
        TransitionComponent={Transition}
        fullScreen
        keepMounted
      >
        <IconButton className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>

        <DialogContent
          style={{
            paddingTop: pxToRem(64),
            backgroundColor: "#2A292A",
          }}
        >
          <div
            className="meetings-iframe-container"
            data-src="https://app.hubspot.com/meetings/rodrigo12?embed=true"
          ></div>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default HubspotMeetings;

const useStyles = makeStyles((theme) => ({
  closeButton: {
    position: "absolute",
    right: theme.spacing(2),
    top: theme.spacing(1),
    color: "white",
  },
}));
