import React from "react";
import { withStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import Container from "@material-ui/core/Container";
import CloudDownloadIcon from "@material-ui/icons/CloudDownload";
import TablePagination from "@material-ui/core/TablePagination";
import SuggestionInboxDetail from "./SuggestionInboxDetail.js";
import Button from "@material-ui/core/Button";
import toastr from "toastr";
import ButtonStatus from "./ButtonStatus";
import CommentDialog from "./CommentDialog";
import OwnerDialog from "./OwnerDialog";
import SuggestionInboxService from "../../Services/SuggestionInboxService";
import { suggestionInboxRequest, suggestionInboxUpdate } from "../../../../../actions/suggestionInboxActions";
import { NotRegistersFound } from "../../common/NotRegistersFound";
import { SpinnerCircular } from "spinners-react";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";

import "toastr/build/toastr.min.css";
import "./suggestionInbox.scss";

const styles = (theme) => ({
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
  },
  fixedHeight: {
    height: 240,
  },

  margin: {
    margin: theme.spacing(1),
  },
  textField: {
    width: "50ch",
    marginBottom: "5px",
  },
});

class SuggestionInbox extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      openTestDeatails: false,
      page: 0,
      rowsPerPage: 5,
      testID: null,
      loading: false
    };

    this.handleCloseTestDetails = this.handleCloseTestDetails.bind(this);
  }

  componentDidMount() {
    if (!this.props.suggestionInboxReducer.suggestions_inbox) {
      this.props.suggestionInboxRequest(); 
    }
  }

  handleChange(event, value) {
    this.setState({ page: value });
  }

  handleOpenTestDetails(id){
    this.setState({
      openTestDeatails: true,
      testID: id,
    })
  }

  handleCloseTestDetails(){
    this.setState({
      openTestDeatails: false,
      testID: null,
    })
  }

  downloadSuggestions() {
    this.setState({ loading: true });
    SuggestionInboxService.downloadSuggestionInbox()
      .then((response) => {
        var blob = response.data;
        var link = document.createElement("a");
        link.href = window.URL.createObjectURL(new Blob([blob]));
        link.download = "Sugerencias.csv";
        link.click();
        this.setState({ loading: false });
      })
      .catch(() => {
        toastr.error(
          this.props.t(
            "admin.user-panel-message-download-questions",
            "An error occurred while trying to download questions"
          )
        );
        this.setState({ loading: false });
      });
  }

  render() {
    const { rowsPerPage, page, openTestDeatails, testID, loading} = this.state;
    const { classes, t } = this.props;

    return (
      <main className={classes.content}>
        <div className={classes.appBarSpacer} />
        <Container maxWidth="lg" className={classes.container}>
          {(this.props.suggestionInboxReducer.suggestions_inbox_charging || loading) &&(
            <div className="div-spinner">
              <Grid container spacing={1}>
                <SpinnerCircular size={90} />
              </Grid>
            </div>
          )}
          {!this.props.suggestionInboxReducer.suggestions_inbox_charging &&
            (this.props.suggestionInboxReducer.suggestions_inbox && loading == false &&
              this.props.suggestionInboxReducer.suggestions_inbox.content.length > 0 ? (
              <Grid container spacing={1}>
                <Button
                  className="button-table"
                  variant="contained"
                  color="primary"
                  onClick={() => this.downloadSuggestions()}
                >
                  <CloudDownloadIcon className="mr-1" />
                  {t("menu.user-panel-button-download-csv", "Download CSV")}
                </Button>
                
                <TableContainer component={Paper}>
                  <Table className={classes.table} aria-label="simple table">
                    <TableHead>
                      <TableRow>

                      <TableCell align="left">
                          {t("menu.trivia-panel-table-type", "Type")}
                        </TableCell>

                        <TableCell align="left">
                          {t("admin.user-panel-user-dialog-input-name", "Name")} / {t("email.label", "Correo")} 
                        </TableCell>

                        <TableCell align="left">
                          {t("menu.badge-report-message-table", "Message")}
                        </TableCell>
                        
                        <TableCell align="left">
                          {t("menu.badge-panel-dialog-delivery-comment", "Comment")}
                        </TableCell>

                        <TableCell align="left">
                          {t("responsible", "Responsible")}
                        </TableCell>

                        <TableCell align="left">
                          Fecha de 
                          <br></br>
                          creación
                        </TableCell>

                        <TableCell align="left">
                          Fecha de
                          <br></br>
                          actualización
                        </TableCell>

                        <TableCell align="left">
                          {t("admin.user-panel-table-state", "State")}
                        </TableCell>

                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {this.props.suggestionInboxReducer.suggestions_inbox.content
                        .slice(
                          page * rowsPerPage,
                          page * rowsPerPage + rowsPerPage
                        )
                        .map((row) => (
                          <TableRow key={row.id}>

                          <TableCell align="left">
                              {row.type === "SUGGESTION" &&
                                t("suggestion", "Suggestion")}
                              {row.type === "BUG" &&
                                t("report.label", "Report")}
                            </TableCell>
                            
                            <TableCell align="left">
                              {row.createdByData == undefined ? `${row.email == '' ? '---' : `${row.email}`}` : `${row.createdByData.name} ${row.createdByData.lastName}`}
                            </TableCell>

                            <TableCell
                              align="left"
                              className="pointer testTableCell"
                              onClick={() => this.handleOpenTestDetails(row.id)}
                            >
                              {row.message.length > 30
                                ? row.message.substring(0, 30) + "..."
                                : row.message}
                            </TableCell>

                            <CommentDialog suggestionInbox={row} suggestionInboxUpdate={this.props.suggestionInboxUpdate} />

                            <OwnerDialog suggestionInbox={row} suggestionInboxUpdate={this.props.suggestionInboxUpdate} suggestionInboxOwner={this.props.suggestionInboxReducer.suggestions_inbox.UsersSuggestionInboxOwner} />

                            <TableCell align="left">
                              {new Date(row.created).toLocaleDateString('es-ES', {
                                month: 'long',
                                year: 'numeric'
                              }).split(' ')[0].charAt(0).toUpperCase() + new Date(row.created).toLocaleDateString('es-ES', {
                                month: 'long'
                              }).slice(1)}
                              {' '}
                              {'\'' + new Date(row.created).toLocaleDateString('es-ES', {
                                year: '2-digit'
                              })}
                            </TableCell>
                            
                            <TableCell align="left">
                              {new Date(row.updated).toLocaleDateString('es-ES')}
                            </TableCell>
                            
                            <TableCell align="left">
                              <ButtonStatus status={row.status} id={row.id}/>
                            </TableCell>
                          </TableRow>
                        ))}
                    </TableBody>
                  </Table>
                  <TablePagination
                    className="mt-1"
                    rowsPerPageOptions={[5]}
                    component="div"
                    count={this.props.suggestionInboxReducer.suggestions_inbox.totalElements}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={(event, value) =>
                      this.handleChange(event, value)
                    }
                  />
                </TableContainer>
              </Grid>
            ) : (
              <>
                <Grid container spacing={1}>
                  <Grid item xs={12} md={12} lg={12}>
                    <NotRegistersFound />
                  </Grid>
                </Grid>
              </>
            ))}
          <SuggestionInboxDetail
            title={t("menu.metric-panel-dialog-show-detail", "Show details")}
            open={openTestDeatails}
            handleClose={this.handleCloseTestDetails}
            testID={testID}
            suggestions_inbox={this.props.suggestionInboxReducer.suggestions_inbox.content}
          />
        </Container>
      </main>
    );
  }
}

const mapStateToProps = ({ suggestionInboxReducer }) => ({ suggestionInboxReducer });
const mapDispatchToProps = { suggestionInboxRequest, suggestionInboxUpdate };

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(withStyles(styles, { withTheme: true })(SuggestionInbox)));
