import React, { useState, useCallback, useRef, useEffect } from "react";
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import CloseIcon from "@material-ui/icons/Close";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import ReactCrop from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";
import toastr from "toastr";
import { useTranslation } from "react-i18next";

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  textField: {
    width: "50ch",
    marginBottom: "5px",
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

export default function CropImageDialog(props) {
  const { t } = useTranslation();
  const [open, setOpen] = useState(props.open);
  const [completedCrop, setCompletedCrop] = useState(null);
  const [upImg, setUpImg] = useState();
  const imgRef = useRef(null);
  const previewCanvasRef = useRef(null);
  const [crop, setCrop] = useState({
    unit: '%',
    x: 0,
    y: 0,
    width: 100,
    height: 100,
  });
  
  const handleClose = () => {

    setOpen(false);
  };
  
  


  

  const onLoad = useCallback((img) => {
    imgRef.current = img;
  }, []);

  useEffect(() => {
    if (!completedCrop || !previewCanvasRef.current || !imgRef.current) {
      return;
    }
  
    const image = imgRef.current;
    const canvas = previewCanvasRef.current;
    const crop = completedCrop;
  
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    const ctx = canvas.getContext("2d");
    const pixelRatio = window.devicePixelRatio;
  
    canvas.width = crop.width * pixelRatio;
    canvas.height = crop.height * pixelRatio;
  
    ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0);
    ctx.imageSmoothingQuality = "high";
  
    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width,
      crop.height
    );
  }, [completedCrop]);
  
  useEffect(() => {
    if (props.open && props.openImageCrop) {
      const reader = new FileReader();
      reader.addEventListener("load", () => {
        setUpImg(reader.result);
        setCrop({ // Reiniciar el recorte
          unit: '%',
          x: 0,
          y: 0,
          width: 100,
          height: 100,
        });
        setOpen(true); 
      });
      reader.readAsDataURL(props.openImageCrop);
    }
  }, [props.open, props.openImageCrop]);
  
  

  function getImage(canvas, crop) {
    if (!crop || !canvas) {
      return;
    }

    if (crop.width === 0 || crop.height === 0) {
      toastr.error(t("You-must-cut-the-image.label", "You must cut the image"));
      return;
    }

    canvas.toBlob(
      (blob) => {
        var file = new File([blob], "image-mission.png");
      
        props.getImageFromDialog(file, window.URL.createObjectURL(blob));

        handleClose();
        setCrop({
          unit: "px",
          aspect: 1 / 1,
        });
      },
      "image/png",
      1
    );
  }

  return (
    <>
      <Dialog
  maxWidth="md"
  fullWidth={false} 
  onClose={handleClose}
  aria-labelledby="customized-dialog-title"
  open={open}

>
        <DialogTitle id="customized-dialog-title" onClose={handleClose}>
          {t("image.label", "Image")}
        </DialogTitle>

        <DialogContent dividers>
    <div className="App">
    <ReactCrop
  src={upImg}
  onImageLoaded={onLoad}
  crop={crop}
  onChange={(c) => setCrop(c)}
  onComplete={(c) => setCompletedCrop(c)}
  style={{
    maxWidth: "500px", 
    maxHeight: "500px", 
 

  }}
/>
      <div className="d-none">
      <canvas
  ref={previewCanvasRef}
  style={{
    maxWidth: "5px", 
    maxHeight: "5px", 
    width: "20%", 
    height: "100%", 
  }}
/>

      </div>
    </div>
  </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="secondary">
            {t("admin.header-dropdown-dialog-actions-cancel", "Cancel")}
          </Button>
          <Button
            color="primary"
            onClick={() => getImage(previewCanvasRef.current, completedCrop)}
          >
            {t("admin.header-dropdown-dialog-actions-save", "Save")}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
