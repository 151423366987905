import axios from "axios";
import environment from "../../../../libs/environment";

export default {
  getBadgesImages: (number = 1, size = 6) =>
    axios({
      method: "GET",
      url: `${
        environment.motivarnosBackend
      }/file/list/insigniasimages?_number=${number - 1}&_size=${size}`,
    }),

  savePassword: (data) =>
    axios({
      method: "POST",
      url: `${environment.motivarnosBackend}/user/changepassword`,
      data: data,
    }),

  getPreferences: () =>
    axios({
      method: "GET",
      url: `${environment.motivarnosBackend}/user/preference`,
    }),

  savePreferences: (data) =>
    axios({
      method: "POST",
      url: `${environment.motivarnosBackend}/user/preference`,
      data: data,
    }),

  getSupervisors: (number = 1, size = 6) =>
    axios({
      method: "GET",
      url: `${environment.motivarnosBackend}/user/supervisor?_number=${
        number - 1
      }&_size=${size}`,
    }),

  updateUser: (data) =>
    axios({
      method: "PUT",
      url: `${environment.motivarnosBackend}/user`,
      data: data,
    }),

  getCredentials: () =>
    axios({
      method: "GET",
      url: `${environment.motivarnosBackend}/company/oauth/credentials`,
    }),

  setCredentials: () =>
    axios({
      method: "POST",
      url: `${environment.motivarnosBackend}/company/oauth/credentials`,
      data: {},
    }),
  setChangeCredentials: () =>
    axios({
      method: "POST",
      url: `${environment.motivarnosBackend}/company/oauth/refreshsecret`,
      data: {},
    }),

  setComment: (data) =>
    axios({
      method: "POST",
      url: `${environment.motivarnosBackend}/notificationuser/comment`,
      data: data,
    }),
};
