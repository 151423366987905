export const RESET_STATE = "RESET_STATE";
export const RESET_STATE_SPECIAL = "RESET_STATE_SPECIAL";

export const RESET_STORE = "RESET_STORE";

export const USERS_LIST_REQUEST = "USERS_LIST_REQUEST";
export const USERS_LIST_SAVED = "USERS_LIST_SAVED";

export const USERS_LIST_SAVE = "USERS_LIST_SAVE";
export const USERS_LIST_UPDATE = "USERS_LIST_UPDATE";
export const USERS_LIST_DELETE = "USERS_LIST_DELETE";

export const USERS_LIST_CHARGING = "USERS_LIST_CHARGING";
export const USERS_SAVE_CHARGING = "USERS_SAVE_CHARGING";

export const USERS_LIST_ERROR = "USERS_LIST_ERROR";

export const USERS_LIST_REQUESTED = "USERS_LIST_REQUESTED";