import React from "react";
import { es } from "date-fns/locale";
import { withStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import Container from "@material-ui/core/Container";
import Pagination from "@material-ui/lab/Pagination";
import toastr from "toastr";
import { SpinnerCircular } from "spinners-react";
import Tooltip from "@material-ui/core/Tooltip";
import { NotRegistersFound } from "../../common/NotRegistersFound";
import Button from "@material-ui/core/Button";
import { Link } from "react-router-dom";
import { withTranslation } from "react-i18next";

import GeneralFunctions from "../../libs/GeneralFunctions";

/********Services***** */
import AwardService from "../../Services/AwardService";

/*******Icons */
import AddIcon from "@material-ui/icons/Add";
import CheckIcon from "@material-ui/icons/Check";
import CloseIcon from "@material-ui/icons/Close";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";

import "toastr/build/toastr.min.css";

const styles = (theme) => ({
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
  },
  fixedHeight: {
    height: 240,
  },
  root: {
    "& > *": {
      marginTop: theme.spacing(2),
    },
  },
  margin: {
    margin: theme.spacing(1),
  },
  textField: {
    width: "50ch",
    marginBottom: "5px",
  },
});

class AwardsApproval extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      rows: [],
      numberPages: 1,
      page: 1,
    };
  }

  componentDidMount() {
    this.getAwards();
  }

  handleChange(event, value) {
    this.setState({ page: value });
    this.getAwards(value);
  }

  /***********GENERAL FUNCTIONS************* */

  getAwards(number = 1) {
    function createData(
      company_name,
      id,
      giftItem,
      user,
      qty,
      comment,
      status,
      processed,
      trxReference,
      updated,
      created,
      isActive
    ) {
      return {
        company_name,
        id,
        giftItem,
        user,
        qty,
        comment,
        status,
        processed,
        trxReference,
        updated,
        created,
        isActive,
      };
    }

    this.setState({ loading: true });
    AwardService.getAllPendingAwards(number)
      .then((response) => {
        this.setState({ rows: [] });
        let rowAwards = [];
        response.data.content.forEach((award) =>
          rowAwards.push(
            createData(
              award.company_name,
              award.id,
              award.giftItem,
              award.user,
              award.qty,
              award.comment,
              award.status,
              award.processed,
              award.trxReference,
              award.updated,
              award.created,
              award.isActive
            )
          )
        );
        this.setState({ rows: rowAwards });
        this.setState({ numberPages: response.data.totalPages });
        this.setState({ loading: false });
      })
      .catch(() => {
        toastr.error(
          this.props.t(
            "admin.user-panel-message-error-get-users",
            "An error occurred while trying to get the users"
          )
        );
        this.setState({ loading: false });
      });
  }

  /***********CRUD FUNCTIONS************* */

  updateAward(award, type = "IN_PROGRESS") {
    let json = {
      id: award.id,
    };

    this.setState({ loading: true });
    if (type === "COMPLETED") {
      AwardService.acceptAward(json)
        .then(() => {
          toastr.success(
            this.props.t(
              "menu.award-panel-dialog-award-approval-message-success",
              "Successfully approved award"
            )
          );

          if (this.state.rows.length - 1 === 0) {
            this.getAwards(
              this.state.page - 1 === 0 || this.state.page - 1 < 0
                ? 1
                : this.state.page - 1
            );
            this.setState({
              page:
                this.state.page - 1 === 0 || this.state.page - 1 < 0
                  ? 1
                  : this.state.page - 1,
            });
          } else {
            this.getAwards(this.state.page);
          }
        })
        .catch((error) => {
          if (error.response.status === 422) {
            toastr.error(error.response.data.message);
          } else {
            toastr.error(
              this.props.t(
                "menu.award-panel-dialog-message-error-approval-awards",
                "An error occurred while trying to approve the award"
              )
            );
          }
          this.setState({ loading: false });
        });
    } else {
      AwardService.rejectAward(json)
        .then(() => {
          toastr.success(
            this.props.t(
              "menu.award-panel-dialog-message-success-rejected-awards",
              "Successfully rejected award"
            )
          );

          if (this.state.rows.length - 1 === 0) {
            this.getAwards(
              this.state.page - 1 === 0 || this.state.page - 1 < 0
                ? 1
                : this.state.page - 1
            );
            this.setState({
              page:
                this.state.page - 1 === 0 || this.state.page - 1 < 0
                  ? 1
                  : this.state.page - 1,
            });
          } else {
            this.getAwards(this.state.page);
          }
        })
        .catch(() => {
          toastr.error(
            this.props.t(
              "menu.award-panel-dialog-message-error-rejected-awards",
              "An error occurred while trying to decline the prize"
            )
          );
          this.setState({ loading: false });
        });
    }
  }

  render() {
    const { loading, rows, numberPages, page } = this.state;
    const { classes, t, i18n } = this.props;

    return (
      <main className={classes.content}>
        <div className={classes.appBarSpacer} />
        <Container maxWidth="lg" className={classes.container}>
          {loading && (
            <div className="div-spinner">
              <Grid container spacing={1}>
                <SpinnerCircular size={90} />
              </Grid>
            </div>
          )}
          {!loading &&
            (rows && rows.length > 0 ? (
              <Grid container spacing={1}>
                <Link to="/awards/panel">
                  <Button
                    className="button-table mr-1"
                    variant="contained"
                    color="secondary"
                  >
                    <ArrowBackIosIcon className="mr-1" />
                    {t("back.label", "Back")}
                  </Button>
                </Link>

                <Button
                  className="button-table mr-1"
                  variant="contained"
                  color="secondary"
                >
                  <AddIcon />
                  {t(
                    "menu.award-panel-dialog-award-approval-title",
                    "Approval/Reject awards"
                  )}
                </Button>

                <TableContainer component={Paper}>
                  <Table className={classes.table} aria-label="simple table">
                    <TableHead>
                      <TableRow>
                        <TableCell align="left">
                          {t("user.label", "User")}
                        </TableCell>
                        <TableCell align="left">
                          {t("email.label", "Email")}
                        </TableCell>
                        <TableCell align="left">
                          {t("menu.award-panel-dialog-title", "Award")}
                        </TableCell>
                        <TableCell align="left">
                          {t("points.label", "Points")}
                        </TableCell>
                        <TableCell align="left">
                          {t("date.label", "Date")}
                        </TableCell>
                        <TableCell align="left">
                          {t("admin.user-panel-table-actions", "Actions")}
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {rows.map((row) => (
                        <TableRow key={row.id}>
                          <TableCell align="left">
                            {row.user.username}
                          </TableCell>

                          <TableCell align="left">
                            {row.user.email && row.user.email != ""
                              ? row.user.email
                              : "N/A"}
                          </TableCell>
                          <TableCell align="left">
                            {row.giftItem.name}
                          </TableCell>
                          <TableCell align="left">
                            {row.giftItem.price}
                          </TableCell>
                          <TableCell align="left">
                            {row.created
                              ? GeneralFunctions.formatNewDate(row.created, es)
                              : "N/A"}
                          </TableCell>
                          <TableCell align="left">
                            <Tooltip
                              title={t("approval.label", "Approval")}
                              onClick={() => this.updateAward(row, "COMPLETED")}
                            >
                              <CheckIcon
                                fontSize="small"
                                className="cursor-pointer"
                              />
                            </Tooltip>

                            <Tooltip
                              title={t("reject.label", "Reject")}
                              onClick={() => this.updateAward(row, "VOIDED")}
                              className="ml-1"
                            >
                              <CloseIcon
                                fontSize="small"
                                className="cursor-pointer"
                              />
                            </Tooltip>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
                <div className={classes.root}>
                  <Pagination
                    count={numberPages}
                    page={page}
                    color="primary"
                    onChange={(event, value) => this.handleChange(event, value)}
                  />
                </div>
              </Grid>
            ) : (
              <>
                <Grid container spacing={1}>
                  <Link to="/awards/panel">
                    <Button
                      className="button-table mr-1"
                      variant="contained"
                      color="secondary"
                    >
                      <ArrowBackIosIcon className="mr-1" />
                      {t("back.label", "Back")}
                    </Button>
                  </Link>

                  <Button
                    className="button-table mr-1"
                    variant="contained"
                    color="secondary"
                  >
                    <AddIcon />
                    {t(
                      "menu.award-panel-dialog-award-approval-title",
                      "Approval/Reject awards"
                    )}
                  </Button>
                  <Grid item xs={12} md={12} lg={12}>
                    <NotRegistersFound />
                  </Grid>
                </Grid>
              </>
            ))}
        </Container>
      </main>
    );
  }
}

export default withTranslation()(
  withStyles(styles, { withTheme: true })(AwardsApproval)
);
