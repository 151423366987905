import React, { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import { SpinnerCircular } from "spinners-react";
import { useTranslation } from "react-i18next";
import { format } from "date-fns";
import toastr from "toastr";

import Button from "../../components/Button";
import Dialog from "../../components/Dialog";
import CurveText from "../../components/CurveText";

import error from "../../assets/img/close.svg";
import success from "../../assets/img/checked-2.svg";

import star from "../../assets/img/star.svg";
import clock from "../../assets/img/clock.svg";
import checklist from "../../assets/img/checklist.svg";
import calendar from "../../assets/img/calendar.svg";
import money from "../../assets/img/money.svg";

import LetterSoupServices from "../../Services/LetterSoup/Global";

import EmptyMessage from "../../components/EmptyMessage";
import Sparks from "../../components/Sparks";
import environment from "../../libs/environment";

import "./styles.scss";
import "toastr/build/toastr.min.css";

const LetterSoupCart = (props) => {
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState(null);
  const { t } = useTranslation();

  const [showSparks, setShowSparks] = useState(true);

  useEffect(() => {
    if (showSparks) {
      let timer = setTimeout(() => setShowSparks(false), 5000);

      return () => { clearInterval(timer) };
    }
  }, [showSparks])

  const handleClick = () => {
    if (props.score) {
      setLoading(true);
      LetterSoupServices.getScore(props.score)
        .then((response) => {
          toastr.clear();
          if (
            response.data
          ) {
            setData(response.data);
            
        } else {
            setData({ words: [] });//TODO palabras
          }

          setOpen(true);
          props.onClick();
          setLoading(false);
        })
        .catch(() => {
          setLoading(false);
        });
    } else {
      toastr.error(t("lettersoup-not-points", "This letter soup has no points"));
    }
  };

  const handleClose = () => {
    setOpen(false);
  };

  const extractWordOfRulesJson = (cluesString) => {
    let clues = JSON.parse(cluesString); 
    if(clues.clues.length){
       let pal = [];
       let auxwords=clues.clues;
       auxwords.forEach(element => {
         pal.push(element.word);
      });
     /* console.log("extractWordOfRulesJson");
      console.log(pal);*/
      return pal.join(', ');       
    }
    return;
  }

  const extractCluesOfRulesJson = (cluesString) => {
    let clues = JSON.parse(cluesString); 
    if(clues.clues.length){
       let pal = [];
       let auxwords=clues.clues;
       auxwords.forEach(element => {
         pal.push(element.clue);
      });
     /* console.log("extractCluesOfRulesJson");
      console.log(pal);*/
      return pal.join(', ');       
    }
    return;
 }


 /* console.log("data->gameWords gameRules");
  console.log((props.data.gameWords!==null && props.data.gameWords!=='')?props.data.gameWords : props.data.gameRules);
  console.log("props.data.gameWords");
  console.log(props.data.gameWords);
  console.log("props.data.gameRules");
  console.log(props.data.gameRules);
  console.log("extractCluesOfRulesJson(props.data.gameRules)");
  console.log(extractCluesOfRulesJson(props.data.gameRules));
  console.log("extractWordOfRulesJson(props.data.gameRules)");
  console.log(extractWordOfRulesJson(props.data.gameRules));*/
 
 
   
  
  return (
    <div className="lettersoup-cart-wrapper">
      {loading && (
        <span className="spinner-container">
          <SpinnerCircular />
        </span>
      )}

      {!loading && (
        <>
          {" "}
          <div style={{position: "absolute", zIndex: "40"}}>
            <Sparks mission={true} show={props.data.isWin === 1 && showSparks}/>
          </div>
          <span
            className={
              "lettersoup-badge-container " +
              (props.secondary ? "" : "lettersoup-container-secondary")
            }
          >
            {props.data.image && (
              <img
                src={`${environment.motivarnosBackend}${props.data.image}`}
                alt="Imagen letter soup"
              />
            )}
          </span>
          <div className="lettersoup-info">
            <div className="lettersoup-text">
              <span
                className={
                  "lettersoup-title " +
                  (props.secondary ? "" : "lettersoup-container-secondary")
                }
              >
                {props.title}
              </span>
              <span
                className={
                  "lettersoup-description " +
                  (props.secondary ? "" : "lettersoup-container-secondary")
                }
              >
                {props.gameDescription}
              </span>
              {!props.secondary ? (
                <div className="resumen-min">
                  {(props.data.gameWords!==null && props.data.gameWords!=='') ?                  
                    <span className="resumen-min-item exam">
                      <p>Palabras</p>
                      <div key="worklistresum" className="lettersoup-cart-wrapper Wordsearch-row">
                       {props.data.gameWords}
                      </div>
                    </span>                  
                  :
                   <span className="lettersoup-wrapper exam">
                     <p>{t("menu.trivia-panel-dialog-test-question-table-clue", "Clue")+'s'}</p>
                     <div key="clueslistresum" className="lettersoup-cart-wrapper">
                      {props.data.gameRules !==null ? extractCluesOfRulesJson(props.data.gameRules) : ""}
                     </div>
                     <p>{t("admin.words", "Words")}</p>
                     <div key="wordlistresum" className="lettersoup-cart-wrapper">                     
                      {props.data.gameRules !==null ? extractWordOfRulesJson(props.data.gameRules): ""}
                     </div>
                    </span>
                  }
                  
                  {
                    !props.data.isWin ? (
                      <span className="resumen-min-item error">
                        <span style={{ filter: "brightness(100)" }}>
                           <img src={error} alt="" />
                        </span>
                         {props.data.points}
                     </span>)
                     : (
                       <span className="resumen-min-item success">
                          <span>
                            <img src={success} alt="" />
                          </span>
                          {props.data.points}
                       </span>)
                  }
                  
                </div>
              ) : (
                <div
                  style={{
                    display: "flex",
                    width: "100%",
                    justifyContent: "center",
                    fontSize: ".75em",
                    marginTop: ".5em",
                    marginBottom: ".5em",
                  }}
                >
                  {props.points > 0 ? (
                    <span style={{ display: "flex" }}>
                      <span
                        style={{
                          width: "1em",
                          marginRight: ".5em",
                          display: "flex",
                        }}
                      >
                        <img style={{ width: "100%" }} src={checklist} alt="" />
                      </span>
                      {props.points + " " + t("points.label", "Points")}
                    </span>
                  ) : (
                    ""
                  )}
                  {props.date === "" ? (
                    ""
                  ) : (
                    <span style={{ display: "flex" }}>
                      <span
                        style={{
                          width: "1em",
                          marginRight: ".5em",
                          display: "flex",
                        }}
                      >
                        <img style={{ width: "100%" }} src={calendar} alt="" />
                      </span>
                      {format(new Date(props.date), "dd/MMM/yyyy")}
                    </span>
                  )}
                  {props.time > 0 ? (
                    <span style={{ display: "flex" }}>
                      <span
                        style={{
                          width: "1em",
                          marginRight: ".5em",
                          marginLeft: ".5em",
                          display: "flex",
                        }}
                      >
                        <img style={{ width: "100%" }} src={clock} alt="" />
                      </span>
                      {`${
                        parseInt(props.time / 3600) > 9
                          ? parseInt(props.time / 3600)
                          : "0" + parseInt(props.time / 3600)
                      }:${
                        parseInt((props.time % 3600) / 60) > 9
                          ? parseInt((props.time % 3600) / 60)
                          : "0" + parseInt((props.time % 3600) / 60)
                      }:${
                        parseInt((props.time % 3600) % 60) > 9
                          ? parseInt((props.time % 3600) % 60)
                          : "0" + parseInt((props.time % 3600) % 60)
                      }`}
                    </span>
                  ) : (
                    ""
                  )}
                </div>
              )}
            </div>
          </div>
          <span className="lettersoup-btn">
            {props.secondary ? (
              
              <NavLink to={`/soup/play/${props.id}/${props.userId}`}>
                <Button style={{ width: "100%" }}>{props.btnText}</Button>
              </NavLink>
            ) : (<></>
              /*<Button
                secondary={props.secondary}
                onClick={handleClick}
                style={{ width: "100%", background: "gray" }}
              >
                {props.btnText}
              </Button>*/
            )}
          </span>
          {!props.secondary && (
            <Dialog
              onClose={handleClose}
              open={open}
              className="resumen-dialog-wrapper"
            >
              <div className="resumen-wrapper">
                <span className="resumen-title">
                  <CurveText
                    text={t("resume.label", "RESUME")}
                    arc={40}
                    radius={350}
                  />
                </span>

                <div className="stars-conteainer">
                  <span>
                    <img src={star} alt="" />
                  </span>
                  <span>
                    <img src={star} alt="" />
                  </span>
                  <span>
                    <img src={star} alt="" />
                  </span>
                </div>
                <div className="resumen-info">
                  <span>
                    {t("number-words.label", "Game type")}:{" "}
                    {props.data.gameType}
                  </span>
                  <span>
                    {t("found-words.label", "Found words")}:{" "}
                    {props.data.gameName}
                  </span>
                  <span>
                    {t("points-lettersoup.label", "Points for this letter soup")}:{" "}
                    {props.data.points}
                  </span>
                </div>

                <Button
                  secondary
                  onClick={handleClose}
                  style={{ borderRadius: "1em", zIndex: "1" }}
                >
                  {t("back-lettersoup.label", "Back to letter soup")}
                </Button>
              </div>
            </Dialog>
          )}
        </>
      )}
    </div>
  );
};

LetterSoupCart.defaultProps = {
  title: "",
  name: "",
  badge: null,
  btnText: "",
};

const LetterSoup = (props) => {
  const [lettersoup, setLetterSoup] = useState(null);
  const { t } = useTranslation();
  const [sesion, setSesion] = useState(null);
  //const [userId, setUserId] = useState(null);

  useEffect(() => {
    let sesion = JSON.parse(localStorage.getItem("sesion"));
    LetterSoupServices.findAll()
      .then((resp) => {
        setLetterSoup(resp.data.content);
        /*console.log("resp.data");
        console.log(resp.data);*/        
      })
      .catch(() => {});
    LetterSoupServices.getUser(sesion.user.id)
      .then((resp) => {
        sesion = { ...sesion, user: resp.data };
        setSesion(sesion);
      })
      .catch(() => {});

    setSesion(sesion);
  }, []);

  function replaceAll(string, search, replace) {
    if (string === null) return "";
    return string.split(search).join(replace).split(" ")[0];
  }
  if (sesion)
    return (
      <div className="lettersoup-wrapper">
        <div className="sum-points">
          <img src={money} alt="" /> {sesion.user.coinBalance}
        </div>
        {lettersoup === null ? (
          <span className="spinner-container">
            <SpinnerCircular />
          </span>
        ) : (
          lettersoup.map((data, index) => {
            let regex = /T(.)*/;
            let date = replaceAll(data.expirationDate, "-", "/").replace(regex, "");
           
            return (
              <LetterSoupCart
                key={index}
                badge={data.badge}
                title={data.gameName}
                description={data.gameDescription}
                btnText={
                  !data.userScoreId
                    ? t("play.label", "Play")
                    : t("summary.label", "Summary")
                }
                secondary={
                  !data.userScoreId /*|| (new Date(date)<=new Date())*/
                }
                id={parseInt(data.id)}
                userId={parseInt(data.user.id)}
                time={parseInt(data.timeInSec)}
                points={data.points}
                date={date}
                data={data}
                score={data.userScoreId}
              />
            );
          })
        )}
        {lettersoup !== null ? lettersoup.length === 0 && <EmptyMessage /> : ""}
      </div>
    );
  else return "";
};

export default LetterSoup;
