import jsonFetch from "../../../utils/jsonFetch";
import environment from "../../../../../libs/environment";

const recoverPassword = () => {
  return {
    getListSupervisorsAvailable: ({ company, user, team }) => {
      return jsonFetch({
        endpoint: `${environment.motivarnosBackend}/sesion/passwordreset?username=${user}&grupo=${team}&empresa=${company}`,
        method: "GET",
      });
    },
    postSendLink: (data) => {
      return jsonFetch({
        endpoint: `${environment.motivarnosBackend}/password/enviarlink`,
        method: "POST",
        jsonBody: data,
      });
    },
    validarHash: ({ user, hash }) => {
      return jsonFetch({
        endpoint: `${environment.motivarnosBackend}/password/validarhash?user=${user}&hash=${hash}`,
        method: "GET",
      });
    },
    changePassword: (data) => {
      return jsonFetch({
        endpoint: `${environment.motivarnosBackend}/admin/password/reset`,
        method: "PUT",
        jsonBody: data,
      });
    },

    changeNewPassword: (data) => {
      return jsonFetch({
        endpoint: `${environment.motivarnosBackend}/sesion/passwordresetoptions`,
        method: "POST",
        jsonBody: data,
      });
    },
  };
};
export { recoverPassword };
