import React from "react";
import { withStyles } from "@material-ui/core/styles";
import { NotRegistersFound } from "../../common/NotRegistersFound";

import HallFameTable from "./HallFameTable";

import environment from "../../../../../libs/environment";

/********Services***** */
import "./HallFame.css";

class HallFameQuarter extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
    };
  }

  /***********GENERAL FUNCTIONS************* */

  render() {
    const { hallFame } = this.props;

    return (
      <>
        {hallFame && hallFame && hallFame.length > 0 ? (
          <>
            <div id="podium-box" className="row">
              {hallFame[1] && (
                <div className="col-md-4 step-container m-0 p-0">
                  <div className="user-card">
                    {hallFame[1].user.image && (
                      <img
                        className="hall-fame-logo"
                        src={
                          environment.motivarnosBackend + hallFame[1].user.image
                        }
                        alt="hall-fame-user"
                      />
                    )}

                    <p>{`${hallFame[1].user.name} ${hallFame[1].user.lastName}`}</p>
                  </div>
                  <div
                    id="second-step"
                    className="bg-podium-number step centerBoth podium-number"
                  >
                    <p>2</p>
                    <p>Puntos: {`${hallFame[1].pointBalance}`}</p>
                  </div>
                </div>
              )}
              {hallFame[0] && (
                <div className="col-md-4 step-container m-0 p-0">
                  <div className="user-card">
                    {hallFame[0].user.image && (
                      <img
                        className="hall-fame-logo"
                        src={
                          environment.motivarnosBackend + hallFame[0].user.image
                        }
                        alt="hall-fame-user"
                      />
                    )}
                    <p>{`${hallFame[0].user.name} ${hallFame[0].user.lastName}`}</p>{" "}
                  </div>
                  <div
                    id="first-step"
                    className="bg-podium-number step centerBoth podium-number"
                  >
                    <p>1</p>
                    <p>Puntos: {`${hallFame[0].pointBalance}`}</p>
                  </div>
                </div>
              )}
              {hallFame[2] && (
                <div className="col-md-4 step-container m-0 p-0">
                  <div className="user-card">
                    {hallFame[2].user.image && (
                      <img
                        className="hall-fame-logo"
                        src={
                          environment.motivarnosBackend + hallFame[2].user.image
                        }
                        alt="hall-fame-user"
                      />
                    )}
                    <p>{`${hallFame[2].user.name} ${hallFame[2].user.lastName}`}</p>{" "}
                  </div>
                  <div
                    id="third-step"
                    className="bg-podium-number step centerBoth podium-number"
                  >
                    <p>3</p>
                    <p>Puntos: {`${hallFame[2].pointBalance}`}</p>
                  </div>
                </div>
              )}
            </div>
            <HallFameTable hallFame={hallFame} />
          </>
        ) : (
          <NotRegistersFound />
        )}
      </>
    );
  }
}

export default withStyles({ withTheme: true })(HallFameQuarter);
