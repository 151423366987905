import React from "react";
import { es } from "date-fns/locale";
import { connect } from "react-redux";
import TablePagination from "@material-ui/core/TablePagination";

import {
  teamsRequest,
  usersRequest,
} from "../../../../../actions/generalActions";

import {
  getNewsRequest,
  deleteNewRequest,
} from "../../../../../actions/newsActions";

import { withStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import Container from "@material-ui/core/Container";
import toastr from "toastr";
import { SpinnerCircular } from "spinners-react";
import { NotRegistersFound } from "../../common/NotRegistersFound";
import Delete from "../../common/Dialogs/Delete";
import { withTranslation } from "react-i18next";
import GeneralFunctions from "../../libs/GeneralFunctions";
import { Link } from "react-router-dom";

import Button from "@material-ui/core/Button";

import ViewListIcon from "@material-ui/icons/ViewList";

import "toastr/build/toastr.min.css";

/*********Dialog********* */
import NewDialog from "../../common/Dialogs/New";

const styles = (theme) => ({
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
  },
  fixedHeight: {
    height: 240,
  },
  root: {
    "& > *": {
      marginTop: theme.spacing(2),
    },
  },
  margin: {
    margin: theme.spacing(1),
  },
  textField: {
    width: "50ch",
    marginBottom: "5px",
  },
});

class News extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      page: 0,
      rowsPerPage: 5,
    };
  }

  componentDidMount() {
    if (!this.props.generalReducer.users) {
      this.props.usersRequest();
    }
    if (!this.props.generalReducer.teams) {
      this.props.teamsRequest();
    }

    this.props.getNewsRequest(1);
  }

  handleChange(event, value) {
    this.setState({ page: value });
    this.props.getNewsRequest(value + 1);
  }

  /***********GENERAL FUNCTIONS************* */

  /***********CRUD FUNCTIONS************* */

  async deleteNew(id) {
    let status;
    await this.props.deleteNewRequest(id).then((r) => (status = r));

    if (status === "SUCCESS") {
      toastr.success(
        this.props.t(
          "menu.new-panel-message-success-notification-removed",
          "Notification removed successfully"
        )
      );
      return;
    }

    if (status.error && status.error.status === 400) {
      toastr.error(
        this.props.t(
          "menu.new-panel-message-error-notification-removed-one",
          "This notification could not be removed"
        )
      );
    } else {
      toastr.error(
        this.props.t(
          "menu.new-panel-message-error-notification-removed-two",
          "An error occurred while removing the badge"
        )
      );
    }
  }

  render() {
    const { page, rowsPerPage } = this.state;
    const { classes, t, i18n } = this.props;

    return (
      <main className={classes.content}>
        <div className={classes.appBarSpacer} />
        <Container maxWidth="lg" className={classes.container}>
          {(this.props.newsReducer.news_charging ||
            this.props.generalReducer.teams_charging ||
            this.props.generalReducer.users_charging) && (
            <div className="div-spinner">
              <Grid container spacing={1}>
                <SpinnerCircular size={90} />
              </Grid>
            </div>
          )}
          {!this.props.newsReducer.news_charging &&
            !this.props.generalReducer.teams_charging &&
            !this.props.generalReducer.users_charging &&
            (this.props.newsReducer.news &&
            this.props.newsReducer.news.length > 0 ? (
              <Grid container spacing={1}>
                {this.props.generalReducer.teams &&
                  this.props.generalReducer.users && (
                    <NewDialog
                      rowsTeams={this.props.generalReducer.teams.content}
                      rowsUsers={this.props.generalReducer.users.content}
                    />
                  )}

                <Link className="link-custom" to="/news/readed">
                  <Button
                    className="button-table mr-1"
                    variant="contained"
                    color="secondary"
                  >
                    <ViewListIcon className="mr-1" />
                    {t("news-readed.label", "News read")}
                  </Button>
                </Link>

                <TableContainer component={Paper}>
                  <Table className={classes.table} aria-label="simple table">
                    <TableHead>
                      <TableRow>
                        <TableCell align="left">
                          {t("menu.new-panel-title", "Title")}
                        </TableCell>
                        <TableCell align="left">
                          {t("menu.badge-report-message-table", "Message")}
                        </TableCell>
                        <TableCell align="left">
                          {t("menu.new-panel-start-date", "Start date")}
                        </TableCell>
                        <TableCell align="left">
                          {t("menu.new-panel-due-date", "Due date")}
                        </TableCell>
                        <TableCell align="left">
                          {t(
                            "admin.header-dropdown-view-conditions-table-state",
                            "State"
                          )}
                        </TableCell>
                        <TableCell align="left">
                          {t("admin.user-panel-table-actions", "Actions")}
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {this.props.newsReducer.news
                        .slice(
                          page * rowsPerPage,
                          page * rowsPerPage + rowsPerPage
                        )
                        .map((row) => (
                          <TableRow key={row.id}>
                            <TableCell align="left">
                              {row.title.length > 30
                                ? row.title.substring(0, 30) + "..."
                                : row.title}
                            </TableCell>

                            <TableCell align="left">
                              {row.message.length > 30
                                ? row.message.substring(0, 30) + "..."
                                : row.message}
                            </TableCell>

                            <TableCell align="left">
                              {GeneralFunctions.formatNewDate(
                                row.dateFrom,
                                es
                              )}
                            </TableCell>
                            <TableCell align="left">
                              {GeneralFunctions.formatNewDate(
                                row.expirationDate,
                                es
                              )}
                            </TableCell>

                            <TableCell align="left">
                              {row.isActive === true &&
                                t(
                                  "admin.header-dropdown-view-conditions-table-active-state",
                                  "Active"
                                )}
                              {row.isActive !== true &&
                                t(
                                  "menu.trivia-panel-table-inactive",
                                  "Inactive"
                                )}
                            </TableCell>

                            <TableCell align="left">
                              {this.props.generalReducer.teams &&
                                this.props.generalReducer.users && (
                                  <NewDialog
                                    rowsTeams={
                                      this.props.generalReducer.teams.content
                                    }
                                    rowsUsers={
                                      this.props.generalReducer.users.content
                                    }
                                    {...row}
                                    typeModal="modalEdit"
                                  />
                                )}

                              <Delete
                                title={t(
                                  "menu.new-panel-notification-management",
                                  "Notification management"
                                )}
                                deleteMetric={() => this.deleteNew(row.id)}
                              />
                            </TableCell>
                          </TableRow>
                        ))}
                    </TableBody>
                  </Table>
                  <TablePagination
                    className="mt-1"
                    rowsPerPageOptions={[5]}
                    component="div"
                    count={this.props.newsReducer.totalElements}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={(event, value) =>
                      this.handleChange(event, value)
                    }
                  />
                </TableContainer>
              </Grid>
            ) : (
              <>
                <Grid container spacing={1}>
                  <Grid item xs={12} md={12} lg={12}>
                    {this.props.generalReducer.teams &&
                      this.props.generalReducer.users && (
                        <NewDialog
                          rowsTeams={this.props.generalReducer.teams.content}
                          rowsUsers={this.props.generalReducer.users.content}
                        />
                      )}

                    <Link className="link-custom" to="/test/analytic">
                      <Button
                        className="button-table mr-1"
                        variant="contained"
                        color="secondary"
                      >
                        <ViewListIcon className="mr-1" />
                        {t("readed.label", "Readed")}
                      </Button>
                    </Link>

                    <NotRegistersFound />
                  </Grid>
                </Grid>
              </>
            ))}
        </Container>
      </main>
    );
  }
}

const mapStateToProps = ({ generalReducer, newsReducer }) => ({
  generalReducer,
  newsReducer,
});
const mapDispatchToProps = {
  teamsRequest,
  usersRequest,
  getNewsRequest,
  deleteNewRequest,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(withStyles(styles, { withTheme: true })(News)));
