import React, { useEffect, useState } from "react";

import { makeStyles } from "@material-ui/core/styles";
import Slide from "@material-ui/core/Slide";
//import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
//import Grid from "@material-ui/core/Grid";
//import { makeStyles } from "@material-ui/core/styles";
import ViewListIcon from "@material-ui/icons/ViewList";
import Button from "@material-ui/core/Button";
import { useTranslation } from "react-i18next";
import Dialog from "@material-ui/core/Dialog";
//import Select from "@material-ui/core/Select";
//import MenuItem from "@material-ui/core/MenuItem";
//import { FormControl } from "@material-ui/core";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import MuiDialogTitle from "@material-ui/core/DialogTitle";

import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import CloseIcon from "@material-ui/icons/Close";
import IconButton from "@material-ui/core/IconButton";
import InputLabel from "@material-ui/core/InputLabel";
import { connect } from "react-redux";

import ReportButton from "../../../common/Dialogs/ReportButton";
import MissionsService from "../../../Services/MissionsService";

import toastr from "toastr";
import { SpinnerCircular } from "spinners-react";
import environment from "../../../../../../libs/environment";
import { withStyles } from "@material-ui/core/styles";
const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

//import "./styles.scss";

const useStyles = makeStyles((theme) => ({
  //appBarSpacer: theme.mixins.toolbar,
  formControl: {
    width: "100%",
    marginTop: "1rem",
  },
}));


const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

function ReportButtonDialog(props) {
  const classes = useStyles();
  const [reportParams, setReportParams] = useState({"mission.id": 0});
  const [missionsList, setMissionsList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = React.useState(false);
  const [format, setFormat] = React.useState();
  const { t } = useTranslation();

  const handleMisionUserReportOpen = () => {
    setOpen(true);
    setLoading(true);
    MissionsService.getMissionForSelect().
      then(response => {
        setMissionsList(response.data.content);
        setLoading(false);
      })
  };

  const handleClose = () => {
    setOpen(false);
  };

  const onChangeFormat = (value) => {
    console.info(value);
    setFormat(value.target.value);
  };

  const ejecutar = () => {
    //let params = { ...props.params };
    let params = reportParams;
    params._format = format ? format : "XLSX";

    const sesion = JSON.parse(localStorage.getItem("sesion"));
    if (sesion && sesion.token) {
      //add token
      params._token = sesion.token;
    }
    console.info(params);
    let query = Object.keys(params)
      .filter((k) => params[k])
      .map(function (key) {
        return encodeURIComponent(key) + "=" + encodeURIComponent(params[key]);
      })
      .join("&");
    window.open(
      `${environment.motivarnosBackend}/report/execute/MotivarnosMissionUserReport?${query}`
    );
    setOpen(false);

    console.log(`${environment.motivarnosBackend}/report/execute/MotivarnosMissionUserReport?${query}`)
  };

  const reportMisionOnChange = (value) => {
    setReportParams({'mission.id': value.target.value})
  }

  return (
    <>
      <Dialog open={open}>
        <DialogTitle id="customized-dialog-title" onClose={handleClose}>
            {t(
              "menu.trivia-panel-dialog-test-question-delete-dialog-title",
              "Response management"
            )}
          </DialogTitle>
        <AppBar className={classes.appBar}>
          <Toolbar>
            <IconButton
              edge="start"
              onClick={() => {
                setOpen(false);
              }}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography variant="h6" className={classes.title}>
              {props.title}
            </Typography>
          </Toolbar>
        </AppBar>

        <Container maxWidth="lg" className={classes.container}>
          <Grid container spacing={5}>
            <Grid item xs={12}>
              <FormControl
                variant="outlined"
                className={classes.formControl}
              >
                {loading ? (
                  <div className="div-spinner">
                    <Grid style={{display: 'flex', justifyContent: 'center'}} spacing={1}>
                      <SpinnerCircular size={90} />
                    </Grid>
                  </div>
                ) : (
                  <>
                    <InputLabel id="mission-report-param-label">
                      Misión
                    </InputLabel>
                    <Select
                      labelId="mission-report-param-label"
                      id="mission"
                      name="mission-report"
                      fullWidth
                      onChange={reportMisionOnChange}
                      defaultValue={0}
                    >
                      {missionsList
                        .sort((a, b) => {
                          return a.name.toLowerCase() < b.name.toLowerCase()
                            ? -1
                            : 1;
                        })
                        .map((row) => (
                          <MenuItem key={row.id} value={row.id}>
                            {row.name}
                          </MenuItem>
                        ))}
                    </Select>
                  </>)
                }
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <FormControl variant="outlined" className={classes.formControl}>
                <InputLabel id="report-format-label">Formato</InputLabel>
                <Select
                  error={false}
                  id="format"
                  name="format"
                  fullWidth
                  defaultValue={"XLSX"}
                  onChange={onChangeFormat}
                  labelId="report-format-label"
                >
                  <MenuItem value={"XLSX"}>
                    {t("admin-reports-format-xlsx", "Excel (*.xlsx)")}
                  </MenuItem>
                  <MenuItem value={"PDF"}>
                    {t("admin-reports-format-xlsx", "Archivo PDF (*.pdf)")}
                  </MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <Button
                className="button-table mr-1"
                variant="contained"
                color="secondary"
                onClick={ejecutar}
                fullWidth
              >
                <ViewListIcon className="mr-1" />
                Generar Reporte
              </Button>
            </Grid>
          </Grid>
        </Container>
      </Dialog>

      <Button
        className="button-table mr-1"
        variant="contained"
        color="secondary"
        onClick={handleMisionUserReportOpen}
      >
        <ViewListIcon className="mr-1" />
        Generar Reporte
      </Button>
    </>
  );
}


export default ReportButtonDialog;
