import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { refreshToken } from "./AuthenticationActions";
import store from "store2";

const isSessionExpired = (expiresAt) => {
  return new Date().getTime() < expiresAt;
};

const useReduxSelector = () =>
  useSelector((state) => {
    const authReducer = state.authReducer;

    return {
      session: authReducer.session,
      isLoading: authReducer.isLoading,
      loggedAs: authReducer.loggedAs,
    };
  });

export const useRefreshSession = () => {
  const { session } = useReduxSelector();
  const dispatch = useDispatch();

  useEffect(() => {
    let timerForWebRefresh = null;

    const isAuthenticated = session && isSessionExpired(session.expiresAt);
    const hasToken = store("token");
    if (isAuthenticated || hasToken) {
      const TWO_MINUTES = 2 * 60 * 1000;
      let timeToExpire = 0;
      if (session && session.expiresAt > 0) {
        timeToExpire = session.expiresAt - new Date().getTime();
        timeToExpire =
          timeToExpire > TWO_MINUTES ? timeToExpire - TWO_MINUTES : 0;
      }

      timerForWebRefresh = setTimeout(() => {
        console.log("REFRESHING");
        dispatch(refreshToken());
      }, timeToExpire);
    }

    return function cleanup() {
      if (timerForWebRefresh) clearTimeout(timerForWebRefresh);
    };
  }, [session, dispatch]);
};

export const useAuth = () => {
  const { session, isLoading, loggedAs, roles } = useReduxSelector();

  const hasToken = store("token");

  return {
    session,
    isLoading,
    hasToken,
    loggedAs,
    roles,
  };
};
