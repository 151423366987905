import jsonFetch from "../../../../../utils/jsonFetch";
import environment from "../../../../../../../libs/environment";

const Metric = (token) => {
  return {
    createMetric: (data) => {
      return jsonFetch({
        endpoint: `${environment.motivarnosBackend}/metricas/nueva/${data.metricType}`,
        method: "POST",
        token: token,
        jsonBody: data.body,
      });
    },
    listMetric: () => {
      return jsonFetch({
        endpoint: `${environment.motivarnosBackend}/metricas/listar`,
        method: "GET",
        token: token,
      });
    },
    searchMetric: (id) => {
      return jsonFetch({
        endpoint: `${environment.motivarnosBackend}/metricas/mostrar?idmetrica=${id}`,
        method: "GET",
        token: token,
      });
    },
    searchMetric: (id) => {
      return jsonFetch({
        endpoint: `${environment.motivarnosBackend}/metricas/mostrar?idmetrica=${id}`,
        method: "GET",
        token: token,
      });
    },
  };
};

export default Metric;
