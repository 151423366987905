import React, { useState, useEffect } from "react";
import Slider from "@material-ui/core/Slider";
import Grid from "@material-ui/core/Grid";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import { makeStyles } from "@material-ui/core/styles";
import { format } from "date-fns";
import { es } from "date-fns/locale";
import TextField from "@material-ui/core/TextField";
import { useTranslation } from "react-i18next";
import { SpinnerCircular } from "spinners-react";
import toastr from "toastr";
import GeneralFunctions from "components/app/admin/libs/GeneralFunctions";

import checked from "../../assets/img/checked.svg";
import trash from "../../assets/img/close.svg";
import tunder from "../../assets/img/tunder.svg";
import money from "../../assets/img/money.svg";

import Button from "../../components/Button";
import Avatar from "../../components/Avatar";
import EmptyMessage from "../../components/EmptyMessage";
import FindRegistersDialogBets from "../../components/app/admin/common/Dialogs/FindRegistersDialogBets";

import BetServices from "../../Services/Bets/Player";
import "./styles.scss";

const useStyles = makeStyles(() => ({
  formControl: {
    width: "100%",
    marginTop: "0.5em",
  },
}));

const Bets = (props) => {
  const classes = useStyles();

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    getContentAnchorEl: () => null,
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };

  const { t, i18n } = useTranslation();
  const [sesion, setSesion] = useState(null);
  const [active, setActive] = useState(0);
  const [points, setPoints] = useState(0);
  const [users, setUsers] = useState(null);
  const [fetchingMetrics, setFetchingMetric] = useState(false);
  const [userActive, setUserActive] = useState(null);
  const [metricActive, setMetricActive] = useState(null);
  const [conditionActive, setConditionActive] = useState(null);
  const [date, setDate] = useState(format(new Date(), "yyyy-MM-dd"));
  const [metricsToBet, setMetricsToBet] = useState([]);
  const [creating, setCreating] = useState(false);
  const [success, setSuccess] = useState(false);
  const [bets, setBets] = useState(null);
  const [betHistory, setBetHistory] = useState(null);
  const [fetching, setFetching] = useState(false);
  const [user, setUser] = useState("");
  const [maxCoinBalance, setMaxCoinBalance] = useState(0);

  const handleNext = () => {
    if (active < 2) setActive(active + 1);
  };

  const handleBack = () => {
    if (active > 0) setActive(active - 1);
  };

  const handleChange = (event, newValue) => {
    setPoints(newValue);
  };

  const handleUserMetric = (event) => {
    let value = JSON.parse(event.target ? event.target.value : event);
    setUser(event.target ? event.target.value : event);

    setMaxCoinBalance(value.coinBalance < sesion.user.coinBalance ? value.coinBalance : sesion.user.coinBalance);    

    if (value === "null") return;
    setFetchingMetric(true);
    setMetricsToBet([]);
    setUserActive(value);
    if (value !== "null")
      BetServices.getMetric(sesion.user.id, value.id)
        .then((response) => {
          setFetchingMetric(false);
          setMetricsToBet(response.data);
        })
        .catch((error) => {
          toastr.error("Ha ocurrido un error obteniendo las metricas.");
          setFetchingMetric(false);
          setMetricsToBet([]);
        });
  };

  const handleMetric = (event) => {
    let value = event.target.value;
    if (value === "null") {
      setMetricActive(null);
      return;
    }
    setMetricActive(value);
  };

  const handleCondition = (event) => {
    let value = event.target.value;
    if (value === "null") {
      setConditionActive(null);
      return;
    }
    setConditionActive(value);
  };

  const handleDate = (e) => {
    setDate(e.target.value);
  };

  const createBet = () => {
    let body = {
      date1: date + "T00:00:00",
      metricConf: {
        id: parseInt(metricActive),
      },
      opponent: {
        id: userActive.id,
      },
      points: points,
      condition: conditionActive,
    };

    setCreating(true);

    BetServices.CreateBet(body)
      .then((response) => {
        console.log(response);
        setSuccess(true);
        setCreating(false);
        getData();
      })
      .catch((error) => {
        toastr.error("Ha ocurrido un error creando la apuesta.");
        setSuccess(false);
        setCreating(false);
      });
  };

  const getBetName = (betArray, id) => {
    //console.log(betArray, id);
    for (let i = 0; i < betArray.length; i++) {
      //console.log(betArray[i].metricConf.id, id);
      if (betArray[i].metricConf.id + "" === id + "")
        return betArray[i] && betArray[i].metricConf
          ? betArray[i].metricConf.name
          : "N/A";
    }
    return "N/A";
  };

  const restartValues = (type) => {
    setSuccess(false);
    setCreating(false);
    setActive(0);
    setConditionActive(null);
    setPoints(0);
    setDate(format(new Date(), "yyyy-MM-dd"));
    if (type === 1) {
      setUserActive(null);
      setMetricActive(null);
      setMetricsToBet([]);
    }

    setBets(null);
    setBetHistory(null);

    getBets(false, (data) => {
      setBets(data);
    });

    getBets(true, (data) => {
      setBetHistory(data);
    });
  };

  const handleSuccess = () => {
    restartValues(1);
  };

  const handleAccept = (id) => {
    setFetching(true);
    BetServices.AcceptBet({ id: id + "" })
      .then((response) => {
        restartValues();
        setFetching(false);
      })
      .catch((error) => {
        toastr.error("Ha ocurrido un error.");
        setFetching(false);
      });
  };

  const handleReject = (id) => {
    setFetching(true);
    BetServices.RejectBet({ id: id + "" })
      .then((response) => {
        restartValues();
        setFetching(false);
      })
      .catch((error) => {
        toastr.error("Ha ocurrido un error.");
        setFetching(false);
      });
  };

  function replaceAll(string, search, replace) {
    return string.split(search).join(replace);
  }

  const getBets = (condition, callback) => {
    BetServices.GetHistory(condition)
      .then((response) => {
        callback(response.data.content);
      })
      .catch((error) => {
        toastr.error("Ha ocurrido un error obteniendo las apuestas.");
      });
  };

  const getData = (condition) => {
    BetServices.GetHistory(condition)
      .then((response) => {
        if (condition) {
          setBetHistory(response.data.content);
        } else {
          setBets(response.data.content);
        }
      })
      .catch((error) => {
        toastr.error("Ha ocurrido un error obteniendo las apuestas.");
      });
  };

  useEffect(() => {
    let userSesion = JSON.parse(localStorage.getItem("sesion"));
    setSesion(userSesion);

    BetServices.GetUsersNotOwn()
      .then((response) => {
        setUsers(response.data.content);
        setBets(null);
        setBetHistory(null);
        getData(false);
        getData(true);
      })
      .catch((error) => {
        toastr.error("Ha ocurrido un error obteniendo los usuarios.");
      });
  }, []);

  if (sesion !== null)
    return (
      <div className="bets-page-wrapper">
      {/*   <div className="sum-points">
          <img src={money} alt="" /> {sesion.user.coinBalance}
        </div> */}

        <div className="bets-title-first">{t("bets.label.title")}</div>

        <div className="new-bet">
          {users === null ? (
            <span className="spinner-container">
              <SpinnerCircular />
            </span>
          ) : !success ? (
            <React.Fragment>
              <div className="steeper">
                <span className="steep-container">
                  <span
                    className={"steep" + (active >= 0 ? " steep-active" : "")}
                  >
                    1
                  </span>
                  <span className="steep-title">{t("opponent.label")}</span>
                </span>
                <span className={"line" + (active > 0 ? " line-active" : "")} />
                <span className="steep-container">
                  <span
                    className={"steep" + (active > 0 ? " steep-active" : "")}
                  >
                    2
                  </span>
                  <span className="steep-title">{t("bets.label")}</span>
                </span>
                <span className={"line" + (active > 1 ? " line-active" : "")} />
                <span className="steep-container">
                  <span
                    className={"steep" + (active > 1 ? " steep-active" : "")}
                  >
                    3
                  </span>
                  <span className="steep-title">{t("summary.label")}</span>
                </span>
              </div>

              <div className="steeps-wrapper">
                <div
                  className={
                    "steep-content steep1-content " +
                    (active === 0 ? "animation-show" : "animation-hide")
                  }
                >
                  <span className="select-container">
                    <span>{t("choose-your-opponent.label")}</span>

                    <Grid container spacing={1}>
                      <Grid item xs={12} md={12} lg={12} className="d-flex">
                        <FormControl
                          variant="outlined"
                          className={classes.formControl}
                        >
                          <InputLabel id="users-select-outlined-label">
                            {t(
                              "menu.trivia-panel-dialog-add-test-select-players",
                              "Players"
                            )}
                          </InputLabel>
                          <Select
                            labelId="users-mutiple-name-label"
                            id="users-select"
                            name="users"
                            label={t(
                              "menu.trivia-panel-dialog-add-test-select-players",
                              "Players"
                            )}
                            value={user}
                            onChange={handleUserMetric}
                            MenuProps={MenuProps}
                            disabled={fetchingMetrics}
                          >
                            {users.map((rowUser) => (
                              <MenuItem
                                key={rowUser.id}
                                value={JSON.stringify(rowUser)}
                              >
                                {rowUser.name
                                  ? rowUser.name + " " + rowUser.lastName
                                  : t(
                                      "admin.user-panel-user-dialog-input-select-without-name",
                                      "Without name"
                                    )}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>

                        {users && users.length > 0 && (
                          <FindRegistersDialogBets
                            setValue={(v) => handleUserMetric(v)}
                            type="users"
                            rows={users}
                          />
                        )}
                      </Grid>
                    </Grid>
                  </span>

                  <div
                    style={{
                      display: "flex",
                      marginTop: "1em",
                      justifyContent: "center",
                    }}
                  >
                    <div className="opponent-info">
                      <span className="avatar-container">
                        <Avatar
                          size="10em"
                          avatar={"/api" + sesion.user.image}
                        />
                      </span>
                      <div className="metrics-info">
                        {metricsToBet.map((data, index) => {
                          return (
                            <div key={index} className="metric-info-item">
                              <span className="metric-score">
                                {data.me.value}
                              </span>
                              <span className="metric-name">
                                {data.metricConf ? data.metricConf.name : ""}
                              </span>
                              <span className="metric-score">
                                {data.opponent.value}
                              </span>
                            </div>
                          );
                        })}
                      </div>
                      <span className="avatar-container">
                        <Avatar
                          size="10em"
                          avatar={
                            userActive !== null
                              ? "/api" + userActive.image
                              : null
                          }
                        />
                      </span>
                    </div>

                    <div className="button-container">
                      <Button
                        onClick={handleNext}
                        disabled={userActive === null || fetchingMetrics}
                      >
                        {t("next.label")}
                      </Button>
                    </div>
                  </div>
                </div>

                <div
                  className={
                    "steep-content steep2-content " +
                    (active === 1 ? "animation-show" : "animation-hide")
                  }
                >
                  <div className="slider-container">
                    <Slider
                      value={points}
                      onChange={handleChange}
                      aria-labelledby="continuous-slider"
                      style={{ color: "var(--warm-purple)" }}
                      max={maxCoinBalance}
                    />
                    <div className="lines">
                      <span></span>
                      <span></span>
                      <span></span>
                      <span></span>
                      <span></span>
                      <span></span>
                      <span></span>
                      <span></span>
                      <span></span>
                      <span></span>
                      <span></span>
                    </div>
                    <div className="lim">
                      <span>0</span>
                      <span>{maxCoinBalance}</span>
                    </div>
                    <div className="points">
                      <span>
                        {t("coins.label")}: {points}
                      </span>
                    </div>
                  </div>

                  <div className="steep2-inputs-container">
                    <span>
                      <span>{t("select-metric.label")}</span>
                      <select className="select-css" onChange={handleMetric}>
                        <option value={"null"}>----</option>
                        {metricsToBet.map((data, index) => {
                          return (
                            <option key={index} value={data.metricConf.id}>
                              {data.metricConf ? data.metricConf.name : ""}
                            </option>
                          );
                        })}
                      </select>
                    </span>
                    <span>
                      <span>{t("place-in-ranking.label")}</span>
                      <select className="select-css" onChange={handleCondition}>
                        <option value={"null"}>----</option>
                        <option value={"GREAT_THAN"}>
                          {t("me-above.label")}
                        </option>
                        <option value={"LESS_THAN"}>
                          {t("me-below.label")}
                        </option>
                      </select>
                    </span>
                    {/*<span>
                        <input/>
                      </span>*/}
                  </div>

                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      width: "100%",
                    }}
                  >
                    <TextField
                      InputProps={{
                        inputProps: { min: format(new Date(), "yyyy-MM-dd") },
                      }}
                      onChange={handleDate}
                      value={date}
                      type="date"
                      name="date"
                      id="date"
                      label={t("expiration-date.label")}
                      variant="outlined"
                      disabled={fetching}
                    />
                  </div>

                  <div className="button-container">
                    <Button
                      secondary
                      style={{ marginRight: "1em" }}
                      onClick={handleBack}
                    >
                      {t("back.label")}
                    </Button>
                    <Button
                      onClick={handleNext}
                      disabled={
                        metricActive === null ||
                        conditionActive === null ||
                        points === 0
                      }
                    >
                      {t("next.label")}
                    </Button>
                  </div>
                </div>

                <div
                  className={
                    "steep-content steep3-content " +
                    (active === 2 ? "animation-show" : "animation-hide")
                  }
                >
                  <span className="steep3-info-content">
                    <div className="vs-container">
                      <Avatar size="10em" avatar={"/api" + sesion.user.image} />
                      <span className="tunder">
                        <img src={tunder} alt="" />
                      </span>
                      <Avatar
                        size="10em"
                        avatar={
                          userActive !== null ? "/api" + userActive.image : null
                        }
                      />
                    </div>
                    <div className="bet-info">
                      <span>
                        <div className="points-container border-bottom border-right">
                          <span className="points">
                            {getBetName(metricsToBet, metricActive)}
                          </span>
                          <span>{t("metrics.label")}</span>
                        </div>
                        <div className="points-container border-right">
                          <span className="points">
                            {conditionActive === "LESS_THAN"
                              ? t("me-below.label")
                              : t("me-above.label")}
                          </span>

                          <span>{t("place-in-ranking.label")}</span>
                        </div>
                      </span>
                      <span>
                        <div className="points-container border-bottom">
                          <span className="points">
                            {GeneralFunctions.formatNewDate(
                              date,
                              es
                            )}
                          </span>
                          <span>{t("expiration-date.label")}</span>
                        </div>
                        <div className="points-container">
                          <span className="points">{points}</span>
                          <span>{t("points.label")}</span>
                        </div>
                      </span>
                    </div>
                  </span>
                  <div className="button-container">
                    <Button
                      secondary
                      style={{ marginRight: "1em" }}
                      onClick={handleBack}
                      disabled={creating}
                    >
                      {t("back.label")}
                    </Button>
                    <Button onClick={createBet} disabled={creating}>
                      {t("bet.label")}
                    </Button>
                  </div>
                </div>
              </div>
            </React.Fragment>
          ) : (
            <div className="bet-success">
              <h3>{t("bet-created.label")}</h3>
              <div>
                <div className="vs-container">
                  <Avatar size="10em" avatar={"/api" + sesion.user.image} />
                  <span className="tunder shake">
                    <img src={tunder} alt="" />
                  </span>
                  <Avatar
                    size="10em"
                    avatar={
                      userActive !== null ? "/api" + userActive.image : null
                    }
                  />
                </div>
              </div>
              <div style={{ marginTop: "1em" }}>
                <Button onClick={handleSuccess}>{t("new.label")}</Button>
              </div>
            </div>
          )}
        </div>

        <div className="bets-title">{t("pending-bets.label")}</div>

        {bets === null ? (
          <span className="spinner-container">
            <SpinnerCircular />
          </span>
        ) : bets.length === 0 ? (
          <EmptyMessage />
        ) : (
          <div
            className="pending-bets"
            style={{ filter: !fetching ? "saturate(1)" : "saturate(0)" }}
          >
            <table className="pending-bets-table">
              <thead>
                <tr>
                  <th className="text-center">{t("gambler.label")}</th>
                  <th className="text-center">{t("opponent.label")}</th>
                  <th className="text-center">{t("metrics.label")}</th>
                  <th className="text-center">{t("place-in-ranking.label")}</th>
                  <th className="text-center">{t("coins.label")}</th>
                  <th className="text-center">{t("date.label")}</th>
                  <th className="text-center">{t("status.label")}</th>
                  <th className="text-center">
                    {t("admin.user-panel-table-actions", "Actions")}
                  </th>
                </tr>
              </thead>
              <tbody>
                {bets.map((data, index) => {
                  let regex = /T(.)*/;
                  let date = replaceAll(data.date1, "-", "/").replace(
                    regex,
                    ""
                  );
                  //console.log(sesion.user.id, data.challenger.id, data.opponent.id)

                  return (
                    <tr className="table-content" key={index}>
                      <td>
                        {data.challenger ? (
                          <span className="gambler">
                            <span className="icon">
                              <Avatar
                                avatar={"/api" + data.challenger.image}
                                size={"2.5em"}
                              />
                            </span>
                            {data.challenger.username}
                          </span>
                        ) : (
                          "N/A"
                        )}
                      </td>
                      <td>
                        {data.opponent ? (
                          <span className="opponent">
                            <span className="icon">
                              <Avatar
                                avatar={"/api" + data.opponent.image}
                                size={"2.5em"}
                              />
                            </span>
                            {data.opponent.username}
                          </span>
                        ) : (
                          "N/A"
                        )}
                      </td>
                      <td>{data.metricConf ? data.metricConf.name : ""}</td>
                      <td>
                        {data.condition === "LESS_THAN"
                          ? t("me-below.label")
                          : t("me-above.label")}
                      </td>
                      <td>{data.points}</td>
                      <td>
                        {GeneralFunctions.formatNewDate(
                          date,
                          es
                        )}
                      </td>
                      <td>
                        <span className="status pending">{data.status}</span>
                      </td>
                      <td>
                        <span className="bet-crud">
                          {data.challenger && (
                            <>
                              {data.challenger.id !== sesion.user.id &&
                              data.status === "PENDING" ? (
                                <span
                                  className={
                                    "crud-icon" + (fetching ? "" : " pointer")
                                  }
                                  onClick={() => {
                                    if (!fetching) handleAccept(data.id);
                                  }}
                                >
                                  <img src={checked} alt="" />
                                </span>
                              ) : (
                                ""
                              )}
                            </>
                          )}
                          <span
                            className={
                              "crud-icon crud-icon-delete" +
                              (fetching ? "" : " pointer")
                            }
                            onClick={() => {
                              if (!fetching) handleReject(data.id);
                            }}
                          >
                            {data.status === "PENDING" ? (
                              <img src={trash} alt="" />
                            ) : (
                              ""
                            )}
                          </span>
                        </span>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        )}

        <div className="bets-title">{t("bets.report.label", "Report")}</div>
        {betHistory === null ? (
          <span className="spinner-container">
            <SpinnerCircular />
          </span>
        ) : betHistory.length === 0 ? (
          <EmptyMessage />
        ) : (
          <div className="betting-history">
            <table className="pending-bets-table">
              <thead>
                <tr>
                  <th>{t("gambler.label")}</th>
                  <th>{t("opponent.label")}</th>
                  <th>{t("metrics.label")}</th>
                  <th>{t("place-in-ranking.label")}</th>
                  <th>{t("coins.label")}</th>
                  <th>{t("date.label")}</th>
                  <th>{t("status.label")}</th>
                </tr>
              </thead>

              {betHistory.map((data, index) => {
                let regex = /T(.)*/;
                let date = replaceAll(data.date1, "-", "/").replace(regex, "");

                return (
                  <tr key={index} className="table-content">
                    <td>
                      {data.challenger ? (
                        <span className="gambler">
                          <span className="icon">
                            <Avatar
                              avatar={"/api" + data.challenger.image}
                              size={"2.5em"}
                            />
                          </span>
                          {data.challenger.username}
                        </span>
                      ) : (
                        ""
                      )}
                    </td>
                    <td>
                      {data.opponent ? (
                        <span className="opponent">
                          <span className="icon">
                            <Avatar
                              avatar={"/api" + data.opponent.image}
                              size={"2.5em"}
                            />
                          </span>
                          {data.opponent.username}
                        </span>
                      ) : (
                        ""
                      )}
                    </td>
                    <td>{data.metricConf ? data.metricConf.name : ""}</td>
                    <td>
                      {data.condition === "LESS_THAN"
                        ? t("me-below.label")
                        : t("me-above.label")}
                    </td>
                    <td>{data.points}</td>
                    <td>
                      {GeneralFunctions.formatNewDate(
                        date,
                        es
                      )}
                    </td>
                    <td>
                      <span className="status rejected">{data.status}</span>
                    </td>
                  </tr>
                );
              })}
            </table>
          </div>
        )}
      </div>
    );
  else return "";
};

export default Bets;
