import * as metricsTypes from "../types/metricsTypes";

import MetricService from "../components/app/admin/Services/MetricService";

const {
  RESET_STATE,
  METRICS_LIST_REQUEST,
  METRICS_LIST_SAVED,
  METRICS_LIST_SAVE,
  METRICS_LIST_UPDATE,
  METRICS_LIST_DELETE,
  METRICS_LIST_CHARGING,
  METRICS_SAVE_CHARGING,
  METRICS_LIST_ERROR,
} = metricsTypes;

export const getMetricsRequest = (payload) => async (dispatch, getState) => {
  try {
    const { pages } = getState().metricsReducer;
    if (!pages.includes(payload.number) || payload.reload) {
      dispatch({
        type: METRICS_LIST_CHARGING,
      });
      const responseLogin = await MetricService.getMetrics(payload.number, 5);
      dispatch({
        type: METRICS_LIST_REQUEST,
        payload: {
          ...responseLogin.data,
          number: payload.number,
          filter: "",
        },
      });
    } else {
      dispatch({
        type: METRICS_LIST_SAVED,
      });
    }
  } catch (error) {
    dispatch({
      type: METRICS_LIST_ERROR,
      payload: error.response ? error.response.data : error,
    });
  }
};

export const getMetricsFilteredRequest =
  (payload) => async (dispatch, getState) => {
    try {
      const { pages, filter } = getState().metricsReducer;
      if (!pages.includes(payload) || filter !== payload.filter) {
        dispatch({
          type: METRICS_LIST_CHARGING,
        });
        const responseLogin = await MetricService.filterMetrics(
          payload.number,
          5,
          payload.filter
        );
        dispatch({
          type: METRICS_LIST_REQUEST,
          payload: {
            ...responseLogin.data,
            number: payload,
            filter: payload.filter,
          },
        });
      } else {
        dispatch({
          type: METRICS_LIST_SAVED,
        });
      }
    } catch (error) {
      dispatch({
        type: METRICS_LIST_ERROR,
        payload: error.response ? error.response.data : error,
      });
    }
  };

export const saveMetricRequest = (payload) => async (dispatch, getState) => {
  dispatch({
    type: METRICS_SAVE_CHARGING,
  });

  try {
    const responseLogin = await MetricService.saveMetric(payload);
    const { metrics } = getState().metricsReducer;
    const metricsUpdated = [responseLogin.data, ...metrics];

    dispatch({
      type: METRICS_LIST_SAVE,
      payload: metricsUpdated,
    });
    return { status: "SUCCESS" };
  } catch (error) {
    dispatch({
      type: METRICS_LIST_ERROR,
      payload: error.response ? error.response.data : error,
    });
    return { status: "ERROR", error: error.response };
  }
};

export const updateMetricRequest = (payload) => async (dispatch, getState) => {
  dispatch({
    type: METRICS_SAVE_CHARGING,
  });

  try {
    await MetricService.updateMetric(payload);
    const { metrics } = getState().metricsReducer;

    const metricsUpdated = [...metrics];
    const findById = (metric) => metric.id === payload.id;
    const index = metricsUpdated.findIndex(findById);
    metricsUpdated[index] = {
      ...metricsUpdated[index],
      ...payload,
    };

    dispatch({
      type: METRICS_LIST_UPDATE,
      payload: metricsUpdated,
    });
    return { status: "SUCCESS" };
  } catch (error) {
    dispatch({
      type: METRICS_LIST_ERROR,
      payload: error.response ? error.response.data : error,
    });
    return { status: "ERROR", error: error.response };
  }
};

export const deleteMetricRequest = (payload) => async (dispatch) => {
  dispatch({
    type: METRICS_LIST_CHARGING,
  });

  try {
    await MetricService.deleteMetric(payload);

    dispatch({
      type: METRICS_LIST_DELETE,
      payload: payload,
    });
    return "SUCCESS";
  } catch (error) {
    dispatch({
      type: METRICS_LIST_ERROR,
      payload: error.response ? error.response.data : error,
    });
    return { error: error.response };
  }
};

export const resetState = () => async (dispatch) => {
  dispatch({
    type: RESET_STATE,
  });
};
