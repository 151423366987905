import * as missionsTypes from "../types/missionsTypes";

const {
  RESET_STATE,
  RESET_STORE,
  MISSIONS_LIST_REQUEST,
  MISSIONS_LIST_SAVED,
  MISSIONS_LIST_SAVE,
  MISSIONS_LIST_UPDATE,
  MISSIONS_LIST_DELETE,
  MISSIONS_LIST_CHARGING,
  MISSIONS_SAVE_CHARGING,
  MISSIONS_LIST_ERROR,
} = missionsTypes;

const INITIAL_STATE = {
  missions: [],
  error_missions: false,
  missions_charging: false,
  missions_save_charging: false,
  totalElements: 0,
  totalPages: 1,
  pages: [],
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case MISSIONS_LIST_REQUEST:
      return {
        ...state,
        missions_charging: false,
        missions: [...state.pages].includes(action.payload.number)
          ? [...state.missions]
          : [...state.missions, ...action.payload.content],
        totalElements: action.payload.totalElements,
        totalPages: action.payload.totalPages,
        pages: [...state.pages].includes(action.payload.number)
          ? [...state.pages]
          : [...state.pages, action.payload.number],
        error_missions: false,
      };

    case MISSIONS_LIST_SAVE:
      return {
        ...state,
        missions: action.payload,
        error_missions: false,
        missions_save_charging: false,
      };
    case MISSIONS_LIST_UPDATE:
      return {
        ...state,
        missions: action.payload,
        error_missions: false,
        missions_save_charging: false,
      };
    case MISSIONS_LIST_DELETE:
      return {
        ...state,
        missions: [...state.missions].filter(
          (test) => test.id !== action.payload
        ),
        missions_charging: false,
        totalElements: state.totalElements - 1,
        error_missions: false,
      };

    case MISSIONS_LIST_CHARGING:
      return { ...state, missions_charging: true, error_missions: false };

    case MISSIONS_SAVE_CHARGING:
      return { ...state, missions_save_charging: true, error_missions: false };

    case MISSIONS_LIST_ERROR:
      return {
        ...state,
        error_missions: action.payload,
        missions_charging: false,
        missions_save_charging: false,
      };

    case MISSIONS_LIST_SAVED:
      return { ...state, missions_charging: false, error_missions: false };

    case RESET_STATE:
      return {
        ...state,
        error_missions: false,
        missions_charging: false,
        missions_save_charging: false,
      };

    case RESET_STORE:
      return {
        ...state,
        missions: [],
        error_missions: false,
        missions_charging: false,
        missions_save_charging: false,
        totalElements: 0,
        totalPages: 1,
        pages: [],
      };

    default:
      return state;
  }
};
