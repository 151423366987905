import React, { useMemo, useState } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import GeneralFunctions from "components/app/admin/libs/GeneralFunctions";
import { es } from "date-fns/locale";
import MessageIcon from "@material-ui/icons/Message";
import TelegramIcon from "@material-ui/icons/Telegram";
import toastr from "toastr";

import Avatar from "../Avatar";
import NotificationServices from "../../Services/Notifications/Player";
import environment from "../../libs/environment";

/********Dialogs***** */

import useUserSesion from "../../hooks/useUserSesion";

import "./styles.scss";
import { Button } from "@material-ui/core";

const NewCart = (props) => {

	const [notification, setNotification] = useState(props);

	const [comment, setComment] = useState('');

	const [isSendingComment, setIsSendingComment] = useState(false);

	const [toggle, setToggle] = useState(!!props.commentsList.length);

	const [chatComments, setChatComments] = useState(props.commentsList);

	const [displayMoreComment, setDisplayMoreComment] = useState(props.commentsList.length > 3);

	const avatarImage = props.avatar? `${environment.motivarnosBackend}${props.avatar}` : null;

	const [sesionUser] = useUserSesion();

	const { i18n, t } = useTranslation();

	const vote = () => {
		if (!props.isVoted)
			NotificationServices.voteNews({ id: props.idNotification })

				.then((resp) => {
					let notificationUpdated = { ...notification };
					notificationUpdated.isVoted = true;
					setNotification(notificationUpdated);
				})
				.catch((error) => { });
	};

	const voteComment = async (comment, index) => {				
		if (comment.isVoted) {
			toastr.error(t('added-like-comment-message-error.label'));
			return;
		}
		
		document.getElementById(`notification-${notification.id}-comment-${index}`).classList.remove("img-vote-icon-commented");

		NotificationServices.voteComment({ id: comment.id })
			.then((resp) => {
				toastr.success(t('added-like-comment-message-success.label'));
			})
			.catch((error) => {
				if (error.response.status === 400) {
					toastr.error(t('added-like-comment-message-error.label'));
				} else {
					toastr.error(t('response-login-500.label'));
					document.getElementById(`notification-${notification.id}-comment-${index}`).classList.add("img-vote-icon-commented");
				}
			});
	};

	const handlerToggle = () => {
		setToggle((prev) => !prev);
	}

	const getUrl = (type) => {
		switch (type) {
			case "TRIVIA":
				return "/quizzes";
			case "SOUP":
				return "/sopa";
			case "MISSION":
				return "/missions";
			case "TOURNAMENT":
				return "/tournament";
			case "INSIGNIA":
				return "/badges";
			case "CHALLENGE":
				return "/challenges";
			case "BET":
				return "/bets";
			case "GIFTITEM":
				return "/prizes";
			default:
				return "/panel";
		}
	};

	const handleChangeComment = (evt) => {
		setComment(evt.target.value);
	}

	const handleSendComment = async () => {
		if (!comment)
			return;

		try {
			setIsSendingComment(true);
			const commentData = { id: props.idNotification, comment };
			const { data: postedComment } = await NotificationServices.postComment(commentData);

			setChatComments((prevState) => [
				{
					id: postedComment.id,
					user: {
						image: postedComment.user.image,
						name: postedComment.user.name,
						lastName: postedComment.user.lastName,
					},
					comment: postedComment.comment
				},
				...prevState
			])
			setComment('');
			setIsSendingComment(false);
			toastr.success(t('added-comment.label'));

		} catch (error) {
			toastr.error(t('added-comment-message-error.label'));
			console.log(error)
		}


	};


	const handleKeySend = (evt) => {
		if (evt.key === 'Enter')
			handleSendComment()
	}

	const urlTypes = {
		"TRIVIA": "/quizzes",
		"SOUP": "/soup",
		"MISSION": "/missions",
		"TOURNAMENT": "/tournament",
		"INSIGNIA": "/badges",
		"CHALLENGE": "/challenges",
		"BET": "/bets",
		"GIFTITEM": "/prizes"
	}

	const mapToUrl = (type) => {
		return urlTypes[type] ?? '/panel'
	}

	const displayComments = useMemo(() => (
		displayMoreComment ? chatComments.slice(0, 3) : chatComments
	), [displayMoreComment, chatComments]);

	const handleDisplaMoreComments = () => {
		setDisplayMoreComment(false);
	}

	return (
		<div className="new-cart-wrapper mt-2">

			<div className="new-cart-content">

				<span className="user-profile text-center cart-item-img">

					<Avatar
						className="center-element"
						avatar={avatarImage}
						size={"3em"}
						style={{ borderWidth: "1px" }}
					/>

					<h6 className="user-profile-name mb-0 mt-1">
						{notification.user
							? notification.user.name + " " + notification.user.lastName
							: ""}
					</h6>

				</span>

				<div className="frame-img cart-main-img">

					<Link className="icon-custom" to={mapToUrl(notification.title)}>
						<span>
							<img
								src={
									notification.imageNotification ?
										`${environment.motivarnosBackend}${notification.imageNotification}` :
										notification.badge
								}
								alt=""
							/>
						</span>
					</Link>

				</div>

				<div className="cart-item-img">
					<div className="flex-badge">

						<Link className="icon" to={mapToUrl(notification.title)}>
							<span className="icon">
								<img src={notification.badge} alt={notification.title} />
								<h6 className="title mb-0 mt-0">{notification.title}</h6>
							</span>
						</Link>
					</div>

				</div>

			</div>

			<div className="new-cart-footer">

				<div className="vote">

					<div aria-label="escribe un comentario" onClick={handlerToggle} role="button">
						<label htmlFor={`commentBox-${props.id}`}>
							<span className="icon-vote-dialog pointer mr-05">
								<MessageIcon />
							</span>
						</label>
					</div>

					<span className="vote-icon">
						{notification.isVoted === true ? (
							<img
								className="img-vote-icon"
								src={notification.votationIconHover}
								alt=""
							/>
						) : (
							<img
								className="img-vote-icon"
								src={notification.votationIcon}
								alt=""
							/>
						)}
					</span>

					<span className="vote-icon vote-icon-active">
						{
							<img
								className="img-vote-icon"
								onClick={vote}
								src={notification.votationIconHover}
								alt=""
							/>
						}
					</span>

				</div>

				<Link to={mapToUrl(notification.title)}>
					<h4 className="text-center">
						{notification.name && notification.name.length > 30
							? notification.name.substring(0, 30) + "..."
							: notification.name}
					</h4>
				</Link>

				<span>
					{GeneralFunctions.formatNewDate(
						notification.date,
						es
					)}
				</span>

			</div>

			<div className={toggle ? 'container-comments displayed-comments' : 'container-comments'} >

				<div className="comments">
					{
						displayComments.map((comment, index) => (
							<div className="comment-box" key={index}>

								<div className="profile-img">
									<img src={`${environment.motivarnosBackend}${comment.user.image}`} />
								</div>

								<div className="comment-content">
									<h2>
										{comment.user.name} {comment.user.lastName}
									</h2>

									<p style={{marginBottom: '10px'}}>
										{comment.comment}
									</p>
									<span className="vote-icon vote-icon-active">
										<img
											className={`img-vote-icon-comment ${comment.isVoted ? "": "img-vote-icon-commented"}`}
											id={`notification-${notification.id}-comment-${index}`}
											src={notification.votationIconHover}
											alt=""
											onClick={() => voteComment(comment, index)}
										/>
									</span>
								</div>
							</div>
						))
					}
					{
						displayMoreComment &&
						<div className="more-comments-section">
							<Button 
								fullWidth
								onClick={handleDisplaMoreComments}
								className="btn--circle"
								variant="outlined"
							>
								Display More Comments
							</Button>
						</div>
					}

				</div>

				<div className="container-input-message">

					<div className={toggle ? "box-input-message" : "box-message-hide"}>

						<div className="sender-content" onKeyDown={handleKeySend}>

							<input className="comment-style-input pl-2 py-1"
								id={`commentBox-${props.id}`}
								placeholder="Comentar insignia"
								role="textbox"
								aria-label="write comment"
								type="text"
								onChange={handleChangeComment}
								value={comment}
								disabled={isSendingComment}
							/>

							<button className="btn-message-position send-btn-styles"
								aria-label="send"
								onClick={handleSendComment}
								disabled={isSendingComment}
							>
								<TelegramIcon className="icon-style" />
							</button>

						</div>

					</div>
				</div>


			</div>

		</div >
	);
};

NewCart.defaultProps = {
	title: "",
	description: "",
	date: "",
	vote: "",
};

export default NewCart;
