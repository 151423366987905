import axios from "axios";
import environment from "../../../../libs/environment";

export default {
  setImportUser: (data) =>
    axios({
      method: "POST",
      url: `${environment.motivarnosBackend}/importuser/loadfile`,
      data: data,
      headers: {
        "content-type": "multipart/form-data",
      },
    }),

  getImportUsers: (number = 1, size = 5) =>
    axios({
      method: "GET",
      url: `${environment.motivarnosBackend}/importuser?_number=${
        number - 1
      }&_size=${size}`,
    }),

  getUsersImported: (number = 0, size = 5) =>
    axios.get("/importuser/audit", {params: {_size: size, _number: number}}),

  saveImportUser: (data) =>
    axios({
      method: "POST",
      url: `${environment.motivarnosBackend}/importuser/importdata`,
      data: data,
    }),

  updateImportUser: (data) =>
    axios({
      method: "PUT",
      url: `${environment.motivarnosBackend}/importuser`,
      data: data,
    }),

  deleteImportUser: (id) =>
    axios({
      method: "DELETE",
      url: `${environment.motivarnosBackend}/importuser/${id}`,
    }),

  setImportUser: (data) =>
    axios({
      method: "POST",
      url: `${environment.motivarnosBackend}/importuser/loadfile`,
      data: data,
      headers: {
        "content-type": "multipart/form-data",
      },
    }),

  savePreImportUser: (data) =>
    axios({
      method: "POST",
      url: `${environment.motivarnosBackend}/importuser/importfile`,
      data: data,
    }),
};
