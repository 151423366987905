import React from "react";
import EmptyMessage from "../../../EmptyMessage";

export const NotRegistersFound = () => {
  return (
    <div className="not-register-div">
      <EmptyMessage />
    </div>
  );
};
