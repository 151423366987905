import React, { useState, useEffect } from "react";
import axios from "axios";
import ChallengeService from "../../Services/ChallengeService";
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Typography from "@material-ui/core/Typography";
import { SpinnerCircular } from "spinners-react";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@material-ui/core/styles";
import Tooltip from "@material-ui/core/Tooltip";
import VisibilityIcon from "@material-ui/icons/Visibility";
import { useParams } from "react-router-dom";

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
  },
  margin: {
    margin: theme.spacing(1),
  },
  withoutLabel: {
    marginTop: theme.spacing(3),
  },
  formControl: {
    width: "100%",
    marginTop: "1rem",
  },
}));

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

export default function DetailsChallenge(props) {
  const classes = useStyles();

  const [open, setOpen] = useState(false);
  const { t, i18n } = useTranslation();

  const handleClickOpen = () => {
    setOpen(true);
    setLoading(true);
    ChallengeService.DetailsChallenge(props.data.id)
      .then((response) => {
        const userNames = response.data.content;
        setChallengeUsers(userNames); // Actualizar el estado con los nombres de los usuarios
      })
      .catch((e) => {})
      .finally(() => {
        setLoading(false);
      });
  };
  const handleClose = () => {
    setOpen(false);
  };

  const { id } = useParams();

  const [challengeUsers, setChallengeUsers] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {}, [id]);

  return (
    <>
      <Tooltip
        title={t("menu.metric-panel-dialog-show-detail", "Show details")}
      >
        <VisibilityIcon
          fontSize="small"
          className="cursor-pointer"
          onClick={handleClickOpen}
        />
      </Tooltip>

      <Dialog
        maxWidth="sm"
        fullWidth={true}
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <DialogTitle id="customized-dialog-title" onClose={handleClose}>
          {/* {t("menu.metric-panel-dialog-show-detail", "Show details")} */}
          <th>{t("menu.trivia-panel-dialog-add-test-select-players", "Players")}</th>
        </DialogTitle>
        <DialogContent dividers>
          {loading && (
            <div className="div-spinner-modal">
              <Container maxWidth="lg" className={classes.container}>
                <Grid container spacing={1}>
                  <SpinnerCircular size={90} />
                </Grid>
              </Container>
            </div>
          )}
          {!loading && (
  <div>
    {challengeUsers
      .sort((a, b) => a.user.name.localeCompare(b.user.name))
      .map((cu, index) => (
        <div key={index}>
          <span>
            {cu.user.name} -{" "}
            <span
              className={`status ${cu.status}`}
              style={{
                backgroundColor:
                  cu.status === "REJECTED"
                    ? "#d10606"
                    : cu.status === "ACCEPTED"
                    ? "#00FF00" // Cambia el color para el estado "ACCEPTED"
                    : "#fdcf00",
                borderRadius: "1em",
                padding: ".25em 1em",
                fontSize: ".75em",
              }}
            >
              {cu.status === "REJECTED"
                ? t("rejected.label", "RECHAZADO").toUpperCase()
                : cu.status === "PENDING"
                ? t( "menu.trivia-panel-dialog-add-test-select-pending-challenge", "Pending").toUpperCase()
                :cu.status === "ACHIEVED"
                ? t( "menu.trivia-panel-dialog-add-test-select-achieved-challenge", "Logrado",).toUpperCase()
                :cu.status === "UNACHIEVED"
                ? t("menu.trivia-panel-dialog-add-test-select-unaachieved-challenge", "No logrado").toUpperCase()
                :cu.status === "IN_PROGRESS"
                ? t("menu.trivia-panel-dialog-add-test-select-in-progress-challenge", "En_progreso").toUpperCase()
                :cu.status === "CANCELLED"
                ? t( "menu.trivia-panel-dialog-add-test-select-cancel-challenge", "Cancelled").toUpperCase()
                : cu.status}
            </span>
          </span>
        </div>
      ))}
  <div></div>
</div>
)}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="secondary">
            {t("menu.trivia-panel-dialog-add-test-button-close", "Close")}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
