import axios from "axios";
import environment from "../../../libs/environment";

export default {
  answerQuestion: (data) =>
    axios({
      method: "POST",
      url: `${environment.motivarnosBackend}/triviauseranswer/answer`,
      data: data,
    }),
  getUser: (id) =>
    axios({
      method: "GET",
      url: `${environment.motivarnosBackend}/user/${id}`,
    }),
  cancel: (data) =>
    axios({
      method: "POST",
      url: `${environment.motivarnosBackend}/triviauserscore/cancel`,
      data: data,
    }),
};
