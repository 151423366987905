import React, { useState } from "react";

import TextField from "@material-ui/core/TextField/TextField";
import { FormattedMessage } from "react-intl";
import { useTranslation } from "react-i18next";

import Password from "../../common/fields/Password";

const ValidateConfirmPassword = (password, confirmPassword) =>
  confirmPassword && confirmPassword === password;

const PasswordCreation = (props) => {
  const [confirmPassword, setConfirmPassword] = useState(props.confirmPassword);
  const { t } = useTranslation();
  const [passwordCreation, setPasswordCreation] = useState({
    password: props.password,
    confirmPassword: confirmPassword,
  });
  var passwordCreationMutable = passwordCreation;

  const change = (value, name) => {
    passwordCreationMutable[name] = value;
    setPasswordCreation(passwordCreationMutable);
    if (props.onChange) {
      props.onChange(passwordCreationMutable);
    }
  };

  return (
    <>
      <Password
        password={passwordCreation.password}
        onChange={(password) => change(password, "password")}
        specificationsPassword=""
        className={props.className[0]}
      ></Password>
      <TextField
        required
        label={
          <FormattedMessage
            id="landing.confirmPasswordLabel"
            defaultMessage={t("confirm-password.label", "Confirm password")}
          />
        }
        className={props.className[1]}
        variant="outlined"
        type="password"
        error={
          passwordCreation.confirmPassword &&
          !ValidateConfirmPassword(
            passwordCreation.password,
            passwordCreation.confirmPassword
          )
        }
        value={passwordCreation.confirmPassword}
        onChange={(e) => {
          setConfirmPassword(e.target.value);
          change(e.target.value, "confirmPassword");
        }}
        style={{ marginBottom: 12 }}
      />
    </>
  );
};

export default PasswordCreation;
