import React from "react";
import SportsEsports from "@material-ui/icons/SportsEsports";
import Button from "@material-ui/core/Button";
import Popper from "@material-ui/core/Popper";
import MenuItem from "@material-ui/core/MenuItem";
import MenuList from "@material-ui/core/MenuList";
import Paper from "@material-ui/core/Paper";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import Grow from "@material-ui/core/Grow";
import { changeRole, logout } from "../../public/auth/AuthenticationActions";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import toastr from "toastr";
import { auth } from "../../public/auth/api/auth";
import Divider from "@material-ui/core/Divider";
import Avatar from "../../../../components/Avatar";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

export const DropDownUser = () => {
  /**********DropDown User logic************ */

  const [openDropDownUser, setOpenDropDownUser] = React.useState(false);
  const anchorRefUser = React.useRef(null);
  const history = useHistory();
  const { t } = useTranslation();

  const handleToggleUser = () => {
    setOpenDropDownUser((prevOpen) => !prevOpen);
  };

  const handleCloseUser = (event) => {
    if (anchorRefUser.current && anchorRefUser.current.contains(event.target)) {
      return;
    }

    setOpenDropDownUser(false);
  };

  function handleListKeyDownUser(event) {
    if (event.key === "Tab") {
      event.preventDefault();
      setOpenDropDownUser(false);
    }
  }

  // return focus to the button when we transitioned from !open -> open
  const prevOpenUser = React.useRef(openDropDownUser);
  React.useEffect(() => {
    if (prevOpenUser.current === true && openDropDownUser === false) {
      anchorRefUser.current.focus();
    }

    prevOpenUser.current = openDropDownUser;
  }, [openDropDownUser]);

  /**********DropDown User logic************ */

  /****Change role*** */
  const dispatch = useDispatch();
  /****Change role*** */

  function logoutFunction() {
    dispatch(logout());
    history.push("/");
  }

  function handleDispatch(e) {
    handleCloseUser(e);
    let data = {
      role: 1,
      token: JSON.parse(localStorage.getItem("token")),
    };
    auth()
      .postLogin(data)
      .then((response) => {
        dispatch(changeRole(response));
      })
      .catch((error) => {
        if (error.status == 401) {
          toastr.error(`${error.message}`);
        } else {
          toastr.error("Ha ocurrido un error al cambiar el rol.");
        }
      });

    /* */
  }

  return (
    <>
      <Button
        ref={anchorRefUser}
        aria-controls={openDropDownUser ? "menu-list-grow" : undefined}
        aria-haspopup="true"
        onClick={handleToggleUser}
        className="button-dropDown"
      >
        <SportsEsports style={{ color: "var(--warm-purple)" }} />
      </Button>

      <Popper
        open={openDropDownUser}
        anchorEl={anchorRefUser.current}
        role={undefined}
        transition
        disablePortal
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === "bottom" ? "center top" : "center bottom",
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleCloseUser}>
                <MenuList
                  autoFocusItem={openDropDownUser}
                  id="menu-list-grow"
                  onKeyDown={handleListKeyDownUser}
                >
                  <MenuItem>
                    <Link to="/avatar">
                      <Avatar
                        avatar={
                          "/api" +
                          JSON.parse(localStorage.getItem("sesion")).user.image
                        }
                        size={"3em"}
                        style={{ borderWidth: "2px" }}
                      />
                    </Link>
                  </MenuItem>
                  <Divider />

                  <MenuItem onClick={(e) => logoutFunction(e)}>
                    {t("admin.header-dropdown-user-leave", "Leave")}
                  </MenuItem>
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </>
  );
};
