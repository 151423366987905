import React from "react";
import "./styles.scss";
import MonetizationOnIcon from '@material-ui/icons/MonetizationOn';

const Badge = (props) => {
  return (
    <div className={`card-model badge-container ${props.count == 0 ? 'badge-unasigned' : ''}`}>
      <div className="badges-container-div-icon">
      {props.icon}
      </div>
      <div className="badges-container-div-description">
        <div className="badges-container-div-info">
          {props.points && props.points !== 0 ? (
            <span className="span-quiz div-title-info"> <div><MonetizationOnIcon /></div><p>{props.points} pts</p></span>
          ) : (
            ""
          )}
          <span className="span-quiz div-title-info">  <div><p>X{props.count}</p></div></span>
        </div>
        <div className="badges-container-div-title">
          {props.title && props.title.length > props.lengthBadgeTitle
            ? props.title.substring(0, props.lengthBadgeTitle) + "..."
            : props.title}
        </div>
        <div className="badges-container-div-subtitle">
          {props.description ? (
            <>
              {props.description && props.description.length > props.lengthBadgeDescription
                ? props.description.substring(0, props.lengthBadgeDescription) + "..."
                : props.description}
            </>
          ) : (
            ""
          )}
        </div>
        {props.isDelivered && props.type === "MANUAL" && (
          <div className="badges-container-div-see-more-coments">
            <button
              onClick={props.handleOpenDialogComments}
              className="button-see-more-comments"
            >
              VER COMENTARIOS
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

Badge.defaultProps = {
  icon: null,
  title: "",
  description: "",
  points: "",
  isDelivered: false,
  type: "",
  count: 0,
  owned: false,
  isPointsGranted: false,
};

export default Badge;
