import React, { useEffect, useState } from "react";
import { Bracket, Seed, SeedItem, SeedTeam } from "react-brackets";
import WinnerCup from '@material-ui/icons/EmojiEvents';
import { es } from "date-fns/locale";
import SumPoints from "../common/SumPoints";

import GeneralFunctions from "components/app/admin/libs/GeneralFunctions";

import avatar from "../../assets/img/user.svg";

import { useTranslation } from "react-i18next";
import TournamentServices from "../../Services/Touunaments/Player";
import { SpinnerCircular } from "spinners-react";
import EmptyMessage from "../../components/EmptyMessage";
import environment from "../../libs/environment";

import "./styles.scss";

const Tournament = (props) => {
  const { t, i18n } = useTranslation();
  const [tournaments, setTournaments] = useState(null);
  const [option, setOption] = useState(0);

  useEffect(() => {
    TournamentServices.GetTournaments()
      .then((resp) => {
        setTournaments(resp.data.content);
      })
      .catch((error) => {});
  }, []);

  /**************************************BRACKET */

  const getBracket = (data) => {
    let players = [];
    for (let i = 0; i < data.length; i++) {
      if (data[i].player1) {
        data[i].player1["date"] = data[i].date1 ? data[i].date1 : null;
        data[i].player1["metric"] = data[i].metricData1
          ? data[i].metricData1
          : null;
        data[i].player1["image"] = data[i].player1.image
          ? data[i].player1.image
          : null;
      } else {
        data[i].player1 = {};
        data[i]["player1"]["date"] = data[i].date1 ? data[i].date1 : null;
      }

      if (data[i].player2) {
        data[i].player2["date"] = data[i].date1 ? data[i].date1 : null;
        data[i].player2["metric"] = data[i].metricData2
          ? data[i].metricData2
          : null;

        data[i].player2["image"] = data[i].player2.image
          ? data[i].player2.image
          : null;
      } else {
        data[i].player2 = {};
        data[i]["player2"]["date"] = data[i].date1 ? data[i].date1 : null;
      }

      players.push(data[i].player1);
      players.push(data[i].player2);
    }

    let rounds;
    const CustomSeed = ({ seed, breakpoint }) => {
      return (
        <Seed mobileBreakpoint={breakpoint} style={{ fontSize: 12 }}>
          <span className="font-weight-bold w-100">{seed.date}</span>

          <SeedItem style={seed.id === 6 || seed.id === 5 ?
              {backgroundColor: "#f5f6f8", width: "250px"}
              :
              seed.id === 7 ? {backgroundColor: "#f5f6f8", width: "300px"}
              :
              {backgroundColor: "#f5f6f8"}
            }
          >
            <div>
              <SeedTeam style={{ color: "red" }}>
                {seed.teams[0].metric > seed.teams[1].metric ?
                  <div className="d-flex-between">
                    <span>
                      <img
                        className="img-custom-tournament"
                        src={
                          seed.teams[0]?.image
                            ? `${environment.motivarnosBackend}${seed.teams[0]?.image}`
                            : avatar
                        }
                        alt="winner-avatar"
                        style={seed.id === 6 || seed.id === 5 ?
                          {
                            height: "100px",
                            width: "100px"
                          }
                          : seed.id === 7 ?
                          {
                            height: "120px",
                            width: "120px"
                          }
                          :
                          null
                        }
                      />
                    </span>
                    <WinnerCup />
                  </div>
                :
                  <span>
                    <img
                      className="img-custom-tournament"
                      src={
                        seed.teams[0]?.image
                          ? `${environment.motivarnosBackend}${seed.teams[0]?.image}`
                          : avatar
                      }
                      alt=""
                    />
                  </span>
                }
                <span className="font-weight-bold">
                  {seed.teams[0].name && seed.teams[0].name !== "undefined"
                    ? seed.teams[0].name
                    : "--"}
                </span>
                <span className="custom-span-tournament">
                  {seed.teams[0].metric
                    ? "(" + seed.teams[0].metric + ")"
                    : "--"}
                </span>
              </SeedTeam>
              <SeedTeam>
                {seed.teams[1].metric > seed.teams[0].metric ?
                  <div className="d-flex-between">
                    <span>
                      <img
                        className="img-custom-tournament"
                        src={
                          seed.teams[1]?.image
                            ? `${environment.motivarnosBackend}${seed.teams[1]?.image}`
                            : avatar
                        }
                        alt="winner-avatar"
                        style={seed.id === 6 || seed.id === 5 ?
                          {
                            height: "100px",
                            width: "100px"
                          }
                          : seed.id === 7 ?
                          {
                            height: "120px",
                            width: "120px"
                          }
                          :
                          null
                        }
                      />
                    </span>
                    <WinnerCup />
                  </div>
                :
                  <span>
                    <img
                      className="img-custom-tournament"
                      src={
                        seed.teams[1]?.image
                          ? `${environment.motivarnosBackend}${seed.teams[1]?.image}`
                          : avatar
                      }
                      alt=""
                    />
                  </span>
                }


                <span className="font-weight-bold">
                  {seed.teams[1].name && seed.teams[1].name !== "undefined"
                    ? seed.teams[1].name
                    : "--"}
                </span>
                <span className="custom-span-tournament">
                  {seed.teams[1].metric
                    ? "(" + seed.teams[1].metric + ")"
                    : "--"}
                </span>
              </SeedTeam>
            </div>
          </SeedItem>
        </Seed>
      );
    };
    switch (players.length) {
      case 2:
        rounds = [
          {
            seeds: [
              {
                id: 1,
                date: players[0]
                  ? GeneralFunctions.formatNewDate(
                      players[0].date,
                      es
                    )
                  : "--",
                teams: [
                  {
                    name: `${players[0] ? players[0].username : ""}`,

                    metric: `${
                      players[0] && players[0].metric
                        ? players[0].metric.points
                        : ""
                    }`,

                    image: `${
                      players[0] && players[0].image ? players[0].image : ""
                    }`,
                  },
                  {
                    name: `${players[1] ? players[1].username : ""}`,

                    metric: `${
                      players[1] && players[1].metric
                        ? players[1].metric.points
                        : ""
                    }`,

                    image: `${
                      players[1] && players[1].image ? players[1].image : ""
                    }`,
                  },
                ],
              },
            ],
          },
        ];

        return (
          <React.Fragment>
            <Bracket rounds={rounds} renderSeedComponent={CustomSeed} />
          </React.Fragment>
        );

      case 6:
        rounds = [
          {
            seeds: [
              {
                id: 1,
                date: players[0]
                  ? GeneralFunctions.formatNewDate(
                      players[0].date,
                      es
                    )
                  : "--",
                teams: [
                  {
                    name: `${players[0] ? players[0].username : "--"}`,

                    metric: `${
                      players[0] && players[0].metric
                        ? players[0].metric.points
                        : ""
                    }`,

                    image: `${
                      players[0] && players[0].image ? players[0].image : ""
                    }`,
                  },
                  {
                    name: `${players[1] ? players[1].username : "--"}`,
                    metric: `${
                      players[1] && players[1].metric
                        ? players[1].metric.points
                        : ""
                    }`,

                    image: `${
                      players[1] && players[1].image ? players[1].image : ""
                    }`,
                  },
                ],
              },

              {
                id: 2,
                date: players[2]
                  ? GeneralFunctions.formatNewDate(
                      players[2].date,
                      es
                    )
                  : "--",
                teams: [
                  {
                    name: `${players[2] ? players[2].username : "--"}`,
                    metric: `${
                      players[2] && players[2].metric
                        ? players[2].metric.points
                        : ""
                    }`,

                    image: `${
                      players[2] && players[2].image ? players[2].image : ""
                    }`,
                  },
                  {
                    name: `${players[3] ? players[3].username : "--"}`,
                    metric: `${
                      players[3] && players[3].metric
                        ? players[3].metric.points
                        : ""
                    }`,

                    image: `${
                      players[3] && players[3].image ? players[3].image : ""
                    }`,
                  },
                ],
              },
            ],
          },

          {
            seeds: [
              {
                id: 3,
                date: players[4]
                  ? GeneralFunctions.formatNewDate(
                      players[4].date,
                      es
                    )
                  : "--",
                teams: [
                  {
                    name: `${players[4] ? players[4].username : "--"}`,
                    metric: `${
                      players[4] && players[4].metric
                        ? players[4].metric.points
                        : ""
                    }`,

                    image: `${
                      players[4] && players[4].image ? players[4].image : ""
                    }`,
                  },
                  {
                    name: `${players[5] ? players[5].username : "--"}`,
                    metric: `${
                      players[5] && players[5].metric
                        ? players[5].metric.points
                        : ""
                    }`,

                    image: `${
                      players[5] && players[5].image ? players[5].image : ""
                    }`,
                  },
                ],
              },
            ],
          },
        ];

        return (
          <React.Fragment>
            <Bracket rounds={rounds} renderSeedComponent={CustomSeed} />
          </React.Fragment>
        );
      case 14:
        rounds = [
          {
            seeds: [
              {
                id: 1,
                date: players[0]
                  ? GeneralFunctions.formatNewDate(
                      players[0].date,
                      es
                    )
                  : "--",
                teams: [
                  {
                    name: `${players[0] ? players[0].username : "--"}`,

                    metric: `${
                      players[0] && players[0].metric
                        ? players[0].metric.points
                        : ""
                    }`,

                    image: `${
                      players[0] && players[0].image ? players[0].image : ""
                    }`,
                  },
                  {
                    name: `${players[1] ? players[1].username : "--"}`,
                    metric: `${
                      players[1] && players[1].metric
                        ? players[1].metric.points
                        : ""
                    }`,

                    image: `${
                      players[1] && players[1].image ? players[1].image : ""
                    }`,
                  },
                ],
              },

              {
                id: 2,
                date: players[2]
                  ? GeneralFunctions.formatNewDate(
                      players[2].date,
                      es
                    )
                  : "--",
                teams: [
                  {
                    name: `${players[2] ? players[2].username : "--"}`,
                    metric: `${
                      players[2] && players[2].metric
                        ? players[2].metric.points
                        : ""
                    }`,

                    image: `${
                      players[2] && players[2].image ? players[2].image : ""
                    }`,
                  },
                  {
                    name: `${players[3] ? players[3].username : "--"}`,
                    metric: `${
                      players[3] && players[3].metric
                        ? players[3].metric.points
                        : ""
                    }`,

                    image: `${
                      players[3] && players[3].image ? players[3].image : ""
                    }`,
                  },
                ],
              },

              {
                id: 3,
                date: players[4]
                  ? GeneralFunctions.formatNewDate(
                      players[4].date,
                      es
                    )
                  : "--",
                teams: [
                  {
                    name: `${players[4] ? players[4].username : "--"}`,
                    metric: `${
                      players[4] && players[4].metric
                        ? players[4].metric.points
                        : ""
                    }`,

                    image: `${
                      players[4] && players[4].image ? players[4].image : ""
                    }`,
                  },
                  {
                    name: `${players[5] ? players[5].username : "--"}`,
                    metric: `${
                      players[5] && players[5].metric
                        ? players[5].metric.points
                        : ""
                    }`,

                    image: `${
                      players[5] && players[5].image ? players[5].image : ""
                    }`,
                  },
                ],
              },

              {
                id: 4,
                date: players[6]
                  ? GeneralFunctions.formatNewDate(
                      players[6].date,
                      es
                    )
                  : "--",
                teams: [
                  {
                    name: `${players[6] ? players[6].username : "--"}`,
                    metric: `${
                      players[6] && players[6].metric
                        ? players[6].metric.points
                        : ""
                    }`,

                    image: `${
                      players[6] && players[6].image ? players[6].image : ""
                    }`,
                  },
                  {
                    name: `${players[7] ? players[7].username : "--"}`,
                    metric: `${
                      players[7] && players[7].metric
                        ? players[7].metric.points
                        : ""
                    }`,

                    image: `${
                      players[7] && players[7].image ? players[7].image : ""
                    }`,
                  },
                ],
              },
            ],
          },

          {
            seeds: [
              {
                id: 5,
                date: players[8]
                  ? GeneralFunctions.formatNewDate(
                      players[8].date,
                      es
                    )
                  : "--",
                teams: [
                  {
                    name: `${players[8] ? players[8].username : "--"}`,
                    metric: `${
                      players[8] && players[8].metric
                        ? players[8].metric.points
                        : ""
                    }`,

                    image: `${
                      players[8] && players[8].image ? players[8].image : ""
                    }`,
                  },
                  {
                    name: `${players[9] ? players[9].username : "--"}`,
                    metric: `${
                      players[9] && players[9].metric
                        ? players[9].metric.points
                        : ""
                    }`,

                    image: `${
                      players[9] && players[9].image ? players[9].image : ""
                    }`,
                  },
                ],
              },

              {
                id: 6,
                date: players[10]
                  ? GeneralFunctions.formatNewDate(
                      players[10].date,
                      es
                    )
                  : "--",
                teams: [
                  {
                    name: `${players[10] ? players[10].username : "--"}`,
                    metric: `${
                      players[10] && players[10].metric
                        ? players[10].metric.points
                        : ""
                    }`,

                    image: `${
                      players[10] && players[10].image ? players[10].image : ""
                    }`,
                  },
                  {
                    name: `${players[11] ? players[11].username : "--"}`,
                    metric: `${
                      players[11] && players[11].metric
                        ? players[11].metric.points
                        : ""
                    }`,

                    image: `${
                      players[11] && players[11].image ? players[11].image : ""
                    }`,
                  },
                ],
              },
            ],
          },

          {
            seeds: [
              {
                id: 7,
                date: players[12]
                  ? GeneralFunctions.formatNewDate(
                      players[12].date,
                      es
                    )
                  : "--",
                teams: [
                  {
                    name: `${players[12] ? players[12].username : "--"}`,
                    metric: `${
                      players[12] && players[12].metric
                        ? players[12].metric.points
                        : ""
                    }`,

                    image: `${
                      players[12] && players[12].image ? players[12].image : ""
                    }`,
                  },
                  {
                    name: `${players[13] ? players[13].username : "--"}`,
                    metric: `${
                      players[13] && players[13].metric
                        ? players[13].metric.points
                        : ""
                    }`,

                    image: `${
                      players[13] && players[13].image ? players[13].image : ""
                    }`,
                  },
                ],
              },
            ],
          },
        ];

        return (
          <React.Fragment>
            <Bracket rounds={rounds} renderSeedComponent={CustomSeed} />
          </React.Fragment>
        );
      case 30:
        rounds = [
          {
            seeds: [
              {
                id: 1,
                date: players[0]
                  ? GeneralFunctions.formatNewDate(
                      players[0].date,
                      es
                    )
                  : "--",
                teams: [
                  {
                    name: `${players[0] ? players[0].username : "--"}`,

                    metric: `${
                      players[0] && players[0].metric
                        ? players[0].metric.points
                        : ""
                    }`,

                    image: `${
                      players[0] && players[0].image ? players[0].image : ""
                    }`,
                  },
                  {
                    name: `${players[1] ? players[1].username : "--"}`,
                    metric: `${
                      players[1] && players[1].metric
                        ? players[1].metric.points
                        : ""
                    }`,

                    image: `${
                      players[1] && players[1].image ? players[1].image : ""
                    }`,
                  },
                ],
              },

              {
                id: 2,
                date: players[2]
                  ? GeneralFunctions.formatNewDate(
                      players[2].date,
                      es
                    )
                  : "--",
                teams: [
                  {
                    name: `${players[2] ? players[2].username : "--"}`,
                    metric: `${
                      players[2] && players[2].metric
                        ? players[2].metric.points
                        : ""
                    }`,

                    image: `${
                      players[2] && players[2].image ? players[2].image : ""
                    }`,
                  },
                  {
                    name: `${players[3] ? players[3].username : "--"}`,
                    metric: `${
                      players[3] && players[3].metric
                        ? players[3].metric.points
                        : ""
                    }`,

                    image: `${
                      players[3] && players[3].image ? players[3].image : ""
                    }`,
                  },
                ],
              },

              {
                id: 3,
                date: players[4]
                  ? GeneralFunctions.formatNewDate(
                      players[4].date,
                      es
                    )
                  : "--",
                teams: [
                  {
                    name: `${players[4] ? players[4].username : "--"}`,
                    metric: `${
                      players[4] && players[4].metric
                        ? players[4].metric.points
                        : ""
                    }`,

                    image: `${
                      players[4] && players[4].image ? players[4].image : ""
                    }`,
                  },
                  {
                    name: `${players[5] ? players[5].username : "--"}`,
                    metric: `${
                      players[5] && players[5].metric
                        ? players[5].metric.points
                        : ""
                    }`,

                    image: `${
                      players[5] && players[5].image ? players[5].image : ""
                    }`,
                  },
                ],
              },

              {
                id: 4,
                date: players[6]
                  ? GeneralFunctions.formatNewDate(
                      players[6].date,
                      es
                    )
                  : "--",
                teams: [
                  {
                    name: `${players[6] ? players[6].username : "--"}`,
                    metric: `${
                      players[6] && players[6].metric
                        ? players[6].metric.points
                        : ""
                    }`,

                    image: `${
                      players[6] && players[6].image ? players[6].image : ""
                    }`,
                  },
                  {
                    name: `${players[7] ? players[7].username : "--"}`,
                    metric: `${
                      players[7] && players[7].metric
                        ? players[7].metric.points
                        : ""
                    }`,

                    image: `${
                      players[7] && players[7].image ? players[7].image : ""
                    }`,
                  },
                ],
              },

              {
                id: 5,
                date: players[8]
                  ? GeneralFunctions.formatNewDate(
                      players[8].date,
                      es
                    )
                  : "--",
                teams: [
                  {
                    name: `${players[8] ? players[8].username : "--"}`,
                    metric: `${
                      players[8] && players[8].metric
                        ? players[8].metric.points
                        : ""
                    }`,

                    image: `${
                      players[8] && players[8].image ? players[8].image : ""
                    }`,
                  },
                  {
                    name: `${players[9] ? players[9].username : "--"}`,
                    metric: `${
                      players[9] && players[9].metric
                        ? players[9].metric.points
                        : ""
                    }`,

                    image: `${
                      players[9] && players[9].image ? players[9].image : ""
                    }`,
                  },
                ],
              },

              {
                id: 6,
                date: players[10]
                  ? GeneralFunctions.formatNewDate(
                      players[10].date,
                      es
                    )
                  : "--",
                teams: [
                  {
                    name: `${players[10] ? players[10].username : "--"}`,
                    metric: `${
                      players[10] && players[10].metric
                        ? players[10].metric.points
                        : ""
                    }`,

                    image: `${
                      players[10] && players[10].image ? players[10].image : ""
                    }`,
                  },
                  {
                    name: `${players[11] ? players[11].username : "--"}`,
                    metric: `${
                      players[11] && players[11].metric
                        ? players[11].metric.points
                        : ""
                    }`,

                    image: `${
                      players[11] && players[11].image ? players[11].image : ""
                    }`,
                  },
                ],
              },

              {
                id: 7,
                date: players[12]
                  ? GeneralFunctions.formatNewDate(
                      players[12].date,
                      es
                    )
                  : "--",
                teams: [
                  {
                    name: `${players[12] ? players[12].username : "--"}`,
                    metric: `${
                      players[12] && players[12].metric
                        ? players[12].metric.points
                        : ""
                    }`,

                    image: `${
                      players[12] && players[12].image ? players[12].image : ""
                    }`,
                  },
                  {
                    name: `${players[13] ? players[13].username : "--"}`,
                    metric: `${
                      players[13] && players[13].metric
                        ? players[13].metric.points
                        : ""
                    }`,

                    image: `${
                      players[13] && players[13].image ? players[13].image : ""
                    }`,
                  },
                ],
              },

              {
                id: 8,
                date: players[14]
                  ? GeneralFunctions.formatNewDate(
                      players[14].date,
                      es
                    )
                  : "--",
                teams: [
                  {
                    name: `${players[14] ? players[14].username : "--"}`,
                    metric: `${
                      players[14] && players[14].metric
                        ? players[14].metric.points
                        : ""
                    }`,

                    image: `${
                      players[14] && players[14].image ? players[14].image : ""
                    }`,
                  },
                  {
                    name: `${players[15] ? players[15].username : "--"}`,
                    metric: `${
                      players[15] && players[15].metric
                        ? players[15].metric.points
                        : ""
                    }`,

                    image: `${
                      players[15] && players[15].image ? players[15].image : ""
                    }`,
                  },
                ],
              },
            ],
          },

          {
            seeds: [
              {
                id: 9,
                date: players[16]
                  ? GeneralFunctions.formatNewDate(
                      players[16].date,
                      es
                    )
                  : "--",
                teams: [
                  {
                    name: `${players[16] ? players[16].username : "--"}`,
                    metric: `${
                      players[16] && players[16].metric
                        ? players[16].metric.points
                        : ""
                    }`,

                    image: `${
                      players[16] && players[16].image ? players[16].image : ""
                    }`,
                  },
                  {
                    name: `${players[17] ? players[17].username : "--"}`,
                    metric: `${
                      players[17] && players[17].metric
                        ? players[17].metric.points
                        : ""
                    }`,

                    image: `${
                      players[17] && players[17].image ? players[17].image : ""
                    }`,
                  },
                ],
              },

              {
                id: 10,
                date: players[18]
                  ? GeneralFunctions.formatNewDate(
                      players[18].date,
                      es
                    )
                  : "--",
                teams: [
                  {
                    name: `${players[18] ? players[18].username : "--"}`,
                    metric: `${
                      players[18] && players[18].metric
                        ? players[18].metric.points
                        : ""
                    }`,

                    image: `${
                      players[18] && players[18].image ? players[18].image : ""
                    }`,
                  },
                  {
                    name: `${players[19] ? players[19].username : "--"}`,
                    metric: `${
                      players[19] && players[19].metric
                        ? players[19].metric.points
                        : ""
                    }`,

                    image: `${
                      players[19] && players[19].image ? players[19].image : ""
                    }`,
                  },
                ],
              },

              {
                id: 11,
                date: players[20]
                  ? GeneralFunctions.formatNewDate(
                      players[20].date,
                      es
                    )
                  : "--",
                teams: [
                  {
                    name: `${players[20] ? players[20].username : "--"}`,
                    metric: `${
                      players[20] && players[20].metric
                        ? players[20].metric.points
                        : ""
                    }`,

                    image: `${
                      players[20] && players[20].image ? players[20].image : ""
                    }`,
                  },
                  {
                    name: `${players[21] ? players[21].username : "--"}`,
                    metric: `${
                      players[21] && players[21].metric
                        ? players[21].metric.points
                        : ""
                    }`,

                    image: `${
                      players[21] && players[21].image ? players[21].image : ""
                    }`,
                  },
                ],
              },

              {
                id: 12,
                date: players[22]
                  ? GeneralFunctions.formatNewDate(
                      players[22].date,
                      es
                    )
                  : "--",
                teams: [
                  {
                    name: `${players[22] ? players[22].username : "--"}`,
                    metric: `${
                      players[22] && players[22].metric
                        ? players[22].metric.points
                        : ""
                    }`,

                    image: `${
                      players[22] && players[22].image ? players[22].image : ""
                    }`,
                  },
                  {
                    name: `${players[23] ? players[23].username : "--"}`,
                    metric: `${
                      players[23] && players[23].metric
                        ? players[23].metric.points
                        : ""
                    }`,

                    image: `${
                      players[23] && players[23].image ? players[23].image : ""
                    }`,
                  },
                ],
              },
            ],
          },

          {
            seeds: [
              {
                id: 13,
                date: players[24]
                  ? GeneralFunctions.formatNewDate(
                      players[24].date,
                      es
                    )
                  : "--",
                teams: [
                  {
                    name: `${players[24] ? players[24].username : "--"}`,
                    metric: `${
                      players[24] && players[24].metric
                        ? players[24].metric.points
                        : ""
                    }`,

                    image: `${
                      players[24] && players[24].image ? players[24].image : ""
                    }`,
                  },
                  {
                    name: `${players[25] ? players[25].username : "--"}`,
                    metric: `${
                      players[25] && players[25].metric
                        ? players[25].metric.points
                        : ""
                    }`,

                    image: `${
                      players[25] && players[25].image ? players[25].image : ""
                    }`,
                  },
                ],
              },

              {
                id: 14,
                date: players[26]
                  ? GeneralFunctions.formatNewDate(
                      players[26].date,
                      es
                    )
                  : "--",
                teams: [
                  {
                    name: `${players[26] ? players[26].username : "--"}`,
                    metric: `${
                      players[26] && players[26].metric
                        ? players[26].metric.points
                        : ""
                    }`,

                    image: `${
                      players[26] && players[26].image ? players[26].image : ""
                    }`,
                  },
                  {
                    name: `${players[27] ? players[27].username : "--"}`,
                    metric: `${
                      players[27] && players[27].metric
                        ? players[27].metric.points
                        : ""
                    }`,

                    image: `${
                      players[27] && players[27].image ? players[27].image : ""
                    }`,
                  },
                ],
              },
            ],
          },
          {
            seeds: [
              {
                id: 15,
                date: players[28]
                  ? GeneralFunctions.formatNewDate(
                      players[28].date,
                      es
                    )
                  : "--",
                teams: [
                  {
                    name: `${players[28] ? players[28].username : "--"}`,
                    metric: `${
                      players[28] && players[28].metric
                        ? players[28].metric.points
                        : ""
                    }`,

                    image: `${
                      players[28] && players[28].image ? players[28].image : ""
                    }`,
                  },
                  {
                    name: `${players[29] ? players[29].username : "--"}`,
                    metric: `${
                      players[29] && players[29].metric
                        ? players[29].metric.points
                        : ""
                    }`,

                    image: `${
                      players[29] && players[29].image ? players[29].image : ""
                    }`,
                  },
                ],
              },
            ],
          },
        ];

        return (
          <React.Fragment>
            <Bracket rounds={rounds} renderSeedComponent={CustomSeed} />
          </React.Fragment>
        );
      case 62:
        var firstSeed = [];
        var secondSeed = [];
        var thirdSeed = [];
        var fourSeed = [];
        var fiveSeed = [];

        for (let i = 0; i < 32; i += 2) {
          firstSeed.push({
            id: i,
            date: players[i]
              ? GeneralFunctions.formatNewDate(
                  players[i].date,
                  es
                )
              : "--",
            teams: [
              {
                name: `${players[i] ? players[i].username : "--"}`,

                metric: `${
                  players[i] && players[i].metric
                    ? players[i].metric.points
                    : ""
                }`,

                image: `${
                  players[i] && players[i].image ? players[i].image : ""
                }`,
              },
              {
                name: `${players[i + 1] ? players[i + 1].username : "--"}`,
                metric: `${
                  players[i + 1] && players[i + 1].metric
                    ? players[i + 1].metric.points
                    : ""
                }`,

                image: `${
                  players[i + 1] && players[i + 1].image
                    ? players[i + 1].image
                    : ""
                }`,
              },
            ],
          });
        }

        for (let i = 32; i < 48; i += 2) {
          secondSeed.push({
            id: i,
            date: players[i]
              ? GeneralFunctions.formatNewDate(
                  players[i].date,
                  es
                )
              : "--",
            teams: [
              {
                name: `${players[i] ? players[i].username : "--"}`,

                metric: `${
                  players[i] && players[i].metric
                    ? players[i].metric.points
                    : ""
                }`,

                image: `${
                  players[i] && players[i].image ? players[i].image : ""
                }`,
              },
              {
                name: `${players[i + 1] ? players[i + 1].username : "--"}`,
                metric: `${
                  players[i + 1] && players[i + 1].metric
                    ? players[i + 1].metric.points
                    : ""
                }`,

                image: `${
                  players[i + 1] && players[i + 1].image
                    ? players[i + 1].image
                    : ""
                }`,
              },
            ],
          });
        }

        for (let i = 48; i < 56; i += 2) {
          thirdSeed.push({
            id: i,
            date: players[i]
              ? GeneralFunctions.formatNewDate(
                  players[i].date,
                  es
                )
              : "--",
            teams: [
              {
                name: `${players[i] ? players[i].username : "--"}`,

                metric: `${
                  players[i] && players[i].metric
                    ? players[i].metric.points
                    : ""
                }`,

                image: `${
                  players[i] && players[i].image ? players[i].image : ""
                }`,
              },
              {
                name: `${players[i + 1] ? players[i + 1].username : "--"}`,
                metric: `${
                  players[i + 1] && players[i + 1].metric
                    ? players[i + 1].metric.points
                    : ""
                }`,

                image: `${
                  players[i + 1] && players[i + 1].image
                    ? players[i + 1].image
                    : ""
                }`,
              },
            ],
          });
        }

        for (let i = 56; i < 60; i += 2) {
          fourSeed.push({
            id: i,
            date: players[i]
              ? GeneralFunctions.formatNewDate(
                  players[i].date,
                  es
                )
              : "--",
            teams: [
              {
                name: `${players[i] ? players[i].username : "--"}`,

                metric: `${
                  players[i] && players[i].metric
                    ? players[i].metric.points
                    : ""
                }`,

                image: `${
                  players[i] && players[i].image ? players[i].image : ""
                }`,
              },
              {
                name: `${players[i + 1] ? players[i + 1].username : "--"}`,
                metric: `${
                  players[i + 1] && players[i + 1].metric
                    ? players[i + 1].metric.points
                    : ""
                }`,

                image: `${
                  players[i + 1] && players[i + 1].image
                    ? players[i + 1].image
                    : ""
                }`,
              },
            ],
          });
        }

        for (let i = 60; i < 62; i += 2) {
          fiveSeed.push({
            id: i,
            date: players[i]
              ? GeneralFunctions.formatNewDate(
                  players[i].date,
                  es
                )
              : "--",
            teams: [
              {
                name: `${players[i] ? players[i].username : "--"}`,

                metric: `${
                  players[i] && players[i].metric
                    ? players[i].metric.points
                    : ""
                }`,

                image: `${
                  players[i] && players[i].image ? players[i].image : ""
                }`,
              },
              {
                name: `${players[i + 1] ? players[i + 1].username : "--"}`,
                metric: `${
                  players[i + 1] && players[i + 1].metric
                    ? players[i + 1].metric.points
                    : ""
                }`,

                image: `${
                  players[i + 1] && players[i + 1].image
                    ? players[i + 1].image
                    : ""
                }`,
              },
            ],
          });
        }

        rounds = [
          {
            seeds: firstSeed,
          },

          {
            seeds: secondSeed,
          },

          {
            seeds: thirdSeed,
          },

          {
            seeds: fourSeed,
          },
          {
            seeds: fiveSeed,
          },
        ];

        return (
          <React.Fragment>
            <Bracket rounds={rounds} renderSeedComponent={CustomSeed} />
          </React.Fragment>
        );

      default:
        return <></>;
    }
  };

  /**************************************BRACKET */

  const handleChange = ({ target }) => {
    setOption(target.value);
  };

  return (
    <div style={{ zIndex: "1", position: "relative" }}>
      <h3
        style={{ color: "var(--warm-purple)", position: "relative" }}
        className="d-flex-between mt-0"
      >
        {t("tournaments.label", "Tournaments")}
        <SumPoints type="trivia-wrapper-tournament" />
      </h3>
      {
        <span className="tournament-input">
          <span>{t("select-tournament.label", "Select the tournament")}</span>
          {tournaments === null ? (
            <span className="spinner-container">
              <SpinnerCircular />
            </span>
          ) : (
            <select
              className="select-css"
              onChange={handleChange}
              value={option}
            >
              {/*<option value={"null"}>----</option>*/}
              {tournaments.map((data, i) => {
                return (
                  <option key={i} value={i}>
                    {data.name}
                  </option>
                );
              })}
            </select>
          )}
        </span>
      }
      {tournaments && tournaments.length > 0 ? (
        <div className="tournament-wrapper pb-custom" style={{backgroundColor: "#4d8d8d"}}>
          <h3>{tournaments[option].name}</h3>

          <div className="bet-info">
            <span>
              <div className="points-container border-bottom border-right">
                <span className="points">
                  {tournaments[option].metricConf.name}
                </span>
                <span>{t("metrics.label")}</span>
              </div>
              <div className="points-container border-right">
                <span className="points">
                  {tournaments[option].conditionType === "LESS_THAN"
                    ? t("me-below.label")
                    : t("me-above.label")}
                </span>
                <span>{t("place-in-ranking.label")}</span>
              </div>
            </span>
            <span>
              <div className="points-container">
                <span className="points">{tournaments[option].points}</span>
                <span>{("points-play.label", "Points in play")}</span>
              </div>
            </span>
          </div>
          {option !== "null" ? getBracket(tournaments[option].matches) : ""}
        </div>
      ) : (
        ""
      )}
      {!tournaments ? (
        <span className="spinner-container">
          <SpinnerCircular />
        </span>
      ) : (
        ""
      )}
      {tournaments && tournaments.length === 0 ? <EmptyMessage /> : ""}
    </div>
  );
};

export default Tournament;
