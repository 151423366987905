import * as badgesTypes from "../types/badgesTypes";

const {
  RESET_STATE,
  RESET_STORE,
  BADGES_LIST_REQUEST,
  BADGES_LIST_SAVED,
  BADGES_LIST_SAVE,
  BADGES_LIST_UPDATE,
  BADGES_LIST_DELETE,
  BADGES_LIST_CHARGING,
  BADGES_SAVE_CHARGING,
  BADGES_LIST_ERROR,
} = badgesTypes;

const INITIAL_STATE = {
  badges: [],
  error_badges: false,
  badges_charging: false,
  badges_save_charging: false,
  totalElements: 0,
  totalPages: 1,

  pages: [],
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case BADGES_LIST_REQUEST:
      return {
        ...state,
        badges_charging: false,
        badges: [...state.pages].includes(action.payload.number)
          ? [...state.badges]
          : [...state.badges, ...action.payload.content],
        totalElements: action.payload.totalElements,
        totalPages: action.payload.totalPages,
        pages: [...state.pages].includes(action.payload.number)
          ? [...state.pages]
          : [...state.pages, action.payload.number],
        error_badges: false,
      };

    case BADGES_LIST_SAVE:
      return {
        ...state,
        badges: action.payload,
        error_badges: false,
        badges_save_charging: false,
      };
    case BADGES_LIST_UPDATE:
      return {
        ...state,
        badges: action.payload,
        error_badges: false,
        badges_save_charging: false,
      };
    case BADGES_LIST_DELETE:
      return {
        ...state,
        badges: [...state.badges].filter(
          (badge) => badge.id !== action.payload
        ),
        badges_charging: false,
        totalElements: state.totalElements - 1,
        error_badges: false,
      };

    case BADGES_LIST_CHARGING:
      return { ...state, badges_charging: true, error_badges: false };

    case BADGES_SAVE_CHARGING:
      return { ...state, badges_save_charging: true, error_badges: false };

    case BADGES_LIST_ERROR:
      return {
        ...state,
        error_badges: action.payload,
        badges_charging: false,
        badges_save_charging: false,
      };

    case BADGES_LIST_SAVED:
      return { ...state, badges_charging: false, error_badges: false };

    case RESET_STATE:
      return {
        ...state,
        error_badges: false,
        badges_charging: false,
        badges_save_charging: false,
      };

    case RESET_STORE:
      return {
        ...state,
        badges: [],
        error_badges: false,
        badges_charging: false,
        badges_save_charging: false,
        totalElements: 0,
        pages: [],
      };

    default:
      return state;
  }
};
