import * as awardsTypes from "../types/awardsTypes";

import AwardService from "../components/app/admin/Services/AwardService";

const {
  RESET_STATE,
  AWARDS_LIST_REQUEST,
  AWARDS_LIST_SAVED,
  AWARDS_LIST_SAVE,
  AWARDS_LIST_UPDATE,
  AWARDS_LIST_DELETE,
  AWARDS_LIST_CHARGING,
  AWARDS_SAVE_CHARGING,
  AWARDS_LIST_ERROR,
} = awardsTypes;

export const getAwardsRequest = (payload) => async (dispatch, getState) => {
  try {
    const { pages } = getState().awardsReducer;
    if (!pages.includes(payload)) {
      dispatch({
        type: AWARDS_LIST_CHARGING,
      });
      const responseLogin = await AwardService.getAwards(payload);
      dispatch({
        type: AWARDS_LIST_REQUEST,
        payload: { ...responseLogin.data, number: payload },
      });
    } else {
      dispatch({
        type: AWARDS_LIST_SAVED,
      });
    }
  } catch (error) {
    dispatch({
      type: AWARDS_LIST_ERROR,
      payload: error.response ? error.response.data : error,
    });
  }
};

export const saveAwardRequest = (payload) => async (dispatch, getState) => {
  dispatch({
    type: AWARDS_SAVE_CHARGING,
  });

  try {
    const responseLogin = await AwardService.saveAward(payload);
    const { awards } = getState().awardsReducer;
    const awardsUpdated = [responseLogin.data, ...awards];

    dispatch({
      type: AWARDS_LIST_SAVE,
      payload: awardsUpdated,
    });
    return { status: "SUCCESS" };
  } catch (error) {
    dispatch({
      type: AWARDS_LIST_ERROR,
      payload: error.response ? error.response.data : error,
    });
    return { status: "ERROR", error: error.response };
  }
};

export const updateAwardRequest = (payload) => async (dispatch, getState) => {
  dispatch({
    type: AWARDS_SAVE_CHARGING,
  });

  try {
    await AwardService.updateAward(payload);
    const { awards } = getState().awardsReducer;

    const awardsUpdated = [...awards];
    const findById = (test) => test.id === payload.id;
    const index = awardsUpdated.findIndex(findById);
    awardsUpdated[index] = {
      ...awardsUpdated[index],
      ...payload,
    };

    dispatch({
      type: AWARDS_LIST_UPDATE,
      payload: awardsUpdated,
    });
    return { status: "SUCCESS" };
  } catch (error) {
    dispatch({
      type: AWARDS_LIST_ERROR,
      payload: error.response ? error.response.data : error,
    });
    return { status: "ERROR", error: error.response };
  }
};

export const deleteAwardRequest = (payload) => async (dispatch) => {
  dispatch({
    type: AWARDS_LIST_CHARGING,
  });

  try {
    await AwardService.deleteAward(payload);

    dispatch({
      type: AWARDS_LIST_DELETE,
      payload: payload,
    });
    return "SUCCESS";
  } catch (error) {
    dispatch({
      type: AWARDS_LIST_ERROR,
      payload: error.response ? error.response.data : error,
    });
    return { error: error.response };
  }
};

export const resetState = () => async (dispatch) => {
  dispatch({
    type: RESET_STATE,
  });
};
