import * as letterSoupTypes from "../types/letterSoupTypes";

import LetterSoupService from "../components/app/admin/Services/LetterSoupService";

const {
  RESET_STATE,
  LETTERSOUP_LIST_REQUEST,
  LETTERSOUP_LIST_SAVED,
  LETTERSOUP_LIST_SAVE,
  LETTERSOUP_LIST_UPDATE,
  LETTERSOUP_LIST_DELETE,
  LETTERSOUP_LIST_CHARGING,
  LETTERSOUP_SAVE_CHARGING,
  LETTERSOUP_LIST_ERROR,
} = letterSoupTypes;

export const getLetterSoupRequest = (payload) => async (dispatch, getState) => {
  try {
    const { pages } = getState().letterSoupReducer;
    if (!pages.includes(payload)) {
      dispatch({
        type: LETTERSOUP_LIST_CHARGING,
      });
      const responseLogin = await LetterSoupService.getLetterSoup(payload);
      dispatch({
        type: LETTERSOUP_LIST_REQUEST,
        payload: { ...responseLogin.data, number: payload },
      });
    } else {
      dispatch({
        type: LETTERSOUP_LIST_SAVED,
      });
    }
  } catch (error) {
    dispatch({
      type: LETTERSOUP_LIST_ERROR,
      payload: error.response ? error.response.data : error,
    });
  }
};

export const saveLetterSoupRequest = (payload) => async (dispatch, getState) => {
  dispatch({
    type: LETTERSOUP_SAVE_CHARGING,
  });

  try {
    const responseLogin = await LetterSoupService.saveLetterSoup(payload);
   const { lettersoup } = getState().letterSoupReducer;
    const letterSoupUpdated = [responseLogin.data, ...lettersoup];

    dispatch({
      type: LETTERSOUP_LIST_SAVE,
      payload: letterSoupUpdated,
    });
    return "SUCCESS";
  } catch (error) {
    dispatch({
      type: LETTERSOUP_LIST_ERROR,
      payload: error.response ? error.response.data : error,
    });
    return "ERROR";
  }
};

export const updateLetterSoupRequest = (payload) => async (dispatch, getState) => {
  dispatch({
    type: LETTERSOUP_SAVE_CHARGING,
  });

  try {
    await LetterSoupService.updateLetterSoup(payload);
    const { lettersoup } = getState().letterSoupReducer;

    const letterSoupUpdated = [...lettersoup];
    const findById = (lettersoup) => lettersoup.id === payload.id;
    const index = letterSoupUpdated.findIndex(findById);
    letterSoupUpdated[index] = {
     ...letterSoupUpdated[index],
     ...payload,
    };

    dispatch({
      type: LETTERSOUP_LIST_UPDATE,
      payload: letterSoupUpdated,
    });
    return "SUCCESS";
  } catch (error) {
    dispatch({
      type: LETTERSOUP_LIST_ERROR,
      payload: error.response ? error.response.data : error,
    });
    return "ERROR";
  }
};

export const deleteLetterSoupRequest = (payload) => async (dispatch) => {
  
  dispatch({
    type: LETTERSOUP_LIST_CHARGING,
  });

  try {
    await LetterSoupService.deleteLetterSoup(payload);

    dispatch({
      type: LETTERSOUP_LIST_DELETE,
      payload: payload,
    });
    return "SUCCESS";
  } catch (error) {
    dispatch({
      type: LETTERSOUP_LIST_ERROR,
      payload: error.response ? error.response.data : error,
    });
    return { error: error.response };
  }
};

export const resetState = () => async (dispatch) => {
  dispatch({
    type: RESET_STATE,
  });
};
