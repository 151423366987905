import React, { useState } from 'react';  /// antes PureComponent
//import PropTypes from 'prop-types';
//import './WordList.css';
import "./styles.scss";
//import Word from './Word';
//import TextInput from './forms/TextInput';

function WordListShow({ title, list_words, list_wordsStile }) {
  const [propTypes, setPropTypes] = useState(
    {
      title: title,
      words: list_words,
    });

  const words = list_words.map((w, idx) => (
    <div key={w + "-" + idx} className="col">
      <div className="ClueList-list">
        {w}
      </div>
    </div>
  ));

  return (
    <div className="WordList">
      {
        propTypes.title && <div className="lettersoup-cart-wrapper WordList-heading">{propTypes.title}</div>
      }
      <div className="row">
        {words}
      </div>
    </div>
  );
}
export default WordListShow;
