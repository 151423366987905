import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import InputLabel from "@material-ui/core/InputLabel";
import { SpinnerCircular } from "spinners-react";
import toastr from "toastr";
import Button from "@material-ui/core/Button";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import TextField from "@material-ui/core/TextField";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import { withStyles } from "@material-ui/core/styles";
import { useTranslation } from "react-i18next";
import FindRegistersDialog from "../../common/Dialogs/FindRegistersDialog";
import Tooltip from "@material-ui/core/Tooltip";

/*********Icons********* */
import AddIcon from "@material-ui/icons/Add";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";

/********Services********* */
import AwardService from "../../Services/AwardService";

const useStyles = makeStyles((theme) => ({
  formControl: {
    width: "100%",
    marginTop: "1rem",
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  textField: {
    width: "50ch",
    marginBottom: "5px",
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

export default function AssignAwardDialog(props) {
  /***************Styles************ */
  const classes = useStyles();
  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    getContentAnchorEl: () => null,
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };
  /***************Styles************ */

  /***********General functions and constants******** */

  const [open, setOpen] = React.useState(false);
  const [loading, setLoading] = useState(false);
  const [usersError, setUsersError] = useState(false);
  const [badgeError, setBadgeError] = useState(false);
  const [commentError, setcommentError] = useState(false);
  const { t } = useTranslation();

  /***********************New functions********************* */

  function formatPropsEdit(array) {
    let arrayFormatted = [];
    array.forEach((element) => {
      arrayFormatted.push(element.id);
    });
    return arrayFormatted;
  }

  function formatArray(array) {
    let arrayFormatted = [];
    array.forEach((element) => {
      arrayFormatted.push({ id: element });
    });
    return arrayFormatted;
  }

  function setValue(name, value) {
    var valueArray;

    valueArray = [...users];

    var n = valueArray.includes(value);

    if (!n) {
      valueArray.push(value);
      setState((prevState) => ({ ...prevState, [name]: valueArray }));
    }
  }

  function handleClickSelectAll(type) {
    setState((prevState) => ({
      ...prevState,
      users: formatPropsEdit(props.rowsUsers),
    }));
  }

  /***********************New functions********************* */

  const [{ award, comment, rowsUsers, users, teams, rowsAwards }, setState] =
    useState({
      award: "",
      comment: "",
      rowsUsers: props.rowsUsers ? props.rowsUsers : [],
      users: [],
      rowsAwards: props.rowsAwards ? props.rowsAwards : [],
    });

  const handleClickOpen = () => {
    setOpen(true);
  };

  function handleChange({ target: { name, value } }) {
    setState((prevState) => ({ ...prevState, [name]: value }));
    if (name === "award") {
      setState((prevState) => ({
        ...prevState,
        comment: value.description,
      }));
    }
  }

  const handleClose = () => {
    setOriginalState();
  };

  function setOriginalState() {
    setState((prevState) => ({
      ...prevState,
      award: "",
      teams: [],
      users: [],
      comment: "",
    }));
    setOpen(false);
  }
  /***********General functions and constants******** */

  /***************************CRUD functions*************** */
  function onFormSubmit() {
    setUsersError(false);
    setBadgeError(false);

    setcommentError(false);

    if (users === "" || users.length === 0 || !users) {
      setUsersError(true);
      toastr.error(
        t(
          "menu.badge-panel-dialog-message-error-players-required",
          "You must add players"
        )
      );
      return;
    }

    if (award === "") {
      setBadgeError(true);
      toastr.error(
        t(
          "menu.badge-panel-dialog-delivery-message-error-badge",
          "You must select a badge"
        )
      );
      return;
    }

    if (comment === "") {
      setcommentError(true);
      toastr.error(
        t(
          "menu.trivia-panel-dialog-add-test-message-error-description",
          "Description is required"
        )
      );
      return;
    }

    let json = {
      giftItem: { id: award.id },
      users: formatArray(users),
      teams: teams,
      qty: award.isStock ? award.actualStock : 1,
      comment: comment,
    };

    setLoading(true);
    AwardService.assignAward(json)
      .then(() => {
        toastr.success(
          t(
            "menu.award-panel-dialog-message-success-delivery-award",
            "The award has been awarded"
          )
        );
        setState((prevState) => ({
          ...prevState,
          award: "",
          teams: [],
          users: [],
          comment: "",
        }));
        setLoading(false);
      })
      .catch((error) => {
        if (error.response.status === 422) {
          toastr.error(error.response.data.message);
        } else {
          toastr.error(
            t(
              "menu.award-panel-dialog-message-error-delivery-award",
              "An error occurred while trying to deliver the prize"
            )
          );
        }

        setLoading(false);
      });
  }
  return (
    <>
      <Button
        className="button-table mr-1"
        variant="contained"
        color="secondary"
        onClick={handleClickOpen}
      >
        <AddIcon />
        {t("menu.award-panel-dialog-assign-award", "Deliver award")}
      </Button>

      <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth={true}>
        <DialogTitle id="customized-dialog-title" onClose={handleClose}>
          {t("menu.badge-panel-dialog-delivery-title", "Delivery management")}
        </DialogTitle>

        {loading && (
          <div className="div-spinner-modal">
            <DialogContent dividers>
              {" "}
              <Grid container spacing={1}>
                <SpinnerCircular size={90} />
              </Grid>
            </DialogContent>
          </div>
        )}

        {!loading && (
          <>
            <DialogContent dividers>
              <Grid item xs={12} md={12} lg={12} className="d-flex">
                <FormControl variant="outlined" className={classes.formControl}>
                  <InputLabel id="users-select-outlined-label">
                    {t(
                      "menu.trivia-panel-dialog-add-test-select-players",
                      "Players"
                    )}
                  </InputLabel>
                  <Select
                    labelId="users-mutiple-name-label"
                    id="users"
                    name="users"
                    multiple
                    label={t(
                      "menu.trivia-panel-dialog-add-test-select-players",
                      "Players"
                    )}
                    value={users}
                    error={usersError}
                    onChange={handleChange}
                    MenuProps={MenuProps}
                  >
                    {rowsUsers.map((rowUser) => (
                      <MenuItem key={rowUser.id} value={rowUser.id}>
                        {rowUser.name
                          ? rowUser.name + " " + rowUser.lastName
                          : t(
                              "admin.user-panel-user-dialog-input-select-without-name",
                              "Without name"
                            )}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>

                {rowsUsers && rowsUsers.length > 0 && (
                  <FindRegistersDialog
                    setValue={(t, v) => setValue(t, v)}
                    type="users"
                    rows={rowsUsers}
                  />
                )}

                <Tooltip title={t("add-all.label", "Add all")}>
                  <Button
                    className="button-table ml-1 mt-2"
                    variant="contained"
                    color="primary"
                    onClick={() => handleClickSelectAll("users")}
                  >
                    <AddIcon />
                  </Button>
                </Tooltip>
              </Grid>

              <Grid item xs={12} md={12} lg={12}>
                <FormControl variant="outlined" className={classes.formControl}>
                  <InputLabel id="award-select-outlined-label">
                    {t("awards.label", "Awards")}
                  </InputLabel>
                  <Select
                    labelId="group-mutiple-name-label"
                    id="award"
                    name="award"
                    label={t("awards.label", "Awards")}
                    value={award}
                    error={badgeError}
                    onChange={handleChange}
                    MenuProps={MenuProps}
                  >
                    {rowsAwards.map((award) => (
                      <MenuItem key={award.id} value={award}>
                        {award.name
                          ? award.name
                          : t(
                              "admin.user-panel-user-dialog-input-select-without-name",
                              "Without name"
                            )}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>

              {award !== "" && (
                <Grid item xs={12} md={12} lg={12}>
                  <TextField
                    onChange={handleChange}
                    error={commentError}
                    rows={3}
                    multiline
                    value={comment}
                    name="comment"
                    id="comment"
                    label={t(
                      "menu.badge-panel-dialog-delivery-comment",
                      "Comment"
                    )}
                    variant="outlined"
                    className="mt-2"
                    fullWidth
                  />
                </Grid>
              )}
            </DialogContent>

            <DialogActions>
              <Button
                className="button-table mt-2 mr-1"
                onClick={handleClose}
                color="secondary"
              >
                {t("menu.trivia-panel-dialog-add-test-button-close", "Close")}
              </Button>
              <Button
                onClick={() => onFormSubmit()}
                className="button-table mt-2"
              >
                {t("menu.badge-panel-dialog-delivery-button", "Delivery")}
              </Button>
            </DialogActions>
          </>
        )}
      </Dialog>
    </>
  );
}
