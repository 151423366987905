import React from 'react';
import empty from '../../assets/img/empty.svg';
import './styles.scss';

const EmptyMessage = () => {
    return (
        <div className='empty-message-wrapper'>
            <span className='empty-img-container'>
                <img src={empty} alt=''/>
            </span>
        </div>
    )
}
export default EmptyMessage;