import React from "react";
import { withStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import Container from "@material-ui/core/Container";
import Pagination from "@material-ui/lab/Pagination";
import toastr from "toastr";
import { SpinnerCircular } from "spinners-react";
import { Link } from "react-router-dom";
import Button from "@material-ui/core/Button";
import { withTranslation } from "react-i18next";
import { es } from "date-fns/locale";
import Select from 'react-select'
import GeneralFunctions from "../../libs/GeneralFunctions";
import environment from "../../../../../libs/environment";
import DownloadTriviasAnalitic from "../../common/Dialogs/DownloadTriviasAnalitic";

/********Dialogs** */
import { NotRegistersFound } from "../../common/NotRegistersFound";

/********Services***** */
import TestService from "../../Services/TestService";
/*********Dialog********* */

/******Icons***** */
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import CloudDownloadIcon from "@material-ui/icons/CloudDownload";

import "toastr/build/toastr.min.css";

const styles = (theme) => ({
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
  },
  fixedHeight: {
    height: 240,
  },
  root: {
    "& > *": {
      marginTop: theme.spacing(2),
    },
  },
  margin: {
    margin: theme.spacing(1),
  },
  textField: {
    width: "50ch",
    marginBottom: "5px",
  },
});

class TestsAnalytic extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      rows: [],
      rowsTests: [],
      rowsUsers: [],
      test: "",
      user: "",
      numberPages: 1,
      page: 1,
      trivias: [],
      id_trivia: false,
      label_trivia:  this.props.t("search-trivia", "Search Trivia")
    };
  }

  componentDidMount() {
    this.getAnalitycsQuestions(1, null, null);
    this.getTests();
  }

  handleChange(event, value) {
    this.setState({ page: value });
    this.getAnalitycsQuestions(
      value,
      this.state.test !== "" ? this.state.test : null,
      this.state.user !== "" || this.state.user !== "NOT USER"
        ? this.state.user
        : null
    );
  }

  /***********GENERAL FUNCTIONS************* */

  getTests() {
    this.setState({ loading: true });
    TestService.getAllTests(1, 99999)
    .then((response) => {
      const trivias = [];
      response.data.content.forEach((elem) => {
        trivias.push({
          value: elem.id,
          label: elem.name
        })
      })
      this.setState({ trivias: trivias });
      this.setState({ loading: false });
    })
    .catch(($error) => {
      console.log('Ha ocurrido un error', $error)
      this.setState({ loading: false });
    });
  }

  selectTrivia({ value, label }) {
    this.setState({ id_trivia: value });
    this.setState({ label_trivia: label });
    this.setState({ page: 1 });
    this.setState({ test: value });
    this.getAnalitycsQuestions(1, value, null);
    //this.getTest(this.state.page, 5, value);
  }

  getAnalitycsQuestions(number = 1, triviaId = null, userId = null) {
    function createData(
      answerText,
      companyName,
      created,
      isComplete,

      isExpired,
      questionSequence,
      questionText,
      scoreId,
      skillName,
      correctAnswer,
      state,
      triviaId,
      triviaName,
      userId,

      userImage,
      userPoints,
      username,
      triviaAnswerTimeStr
    ) {
      return {
        answerText,
        companyName,
        created,
        isComplete,

        isExpired,
        questionSequence,
        questionText,
        scoreId,
        skillName,
        correctAnswer,
        state,
        triviaId,
        triviaName,
        userId,

        userImage,
        userPoints,
        username,
        triviaAnswerTimeStr,
      };
    }
    this.setState({ loading: true });
    TestService.getTestsAnalitycs(
      number,
      5,
      triviaId,
      userId ? userId : this.state.user
    )
      .then((response) => {
        this.setState({ rows: [] });
        let rowTests = [];
        response.data.content.forEach((analityc) =>
          rowTests.push(
            createData(
              analityc.answerText,
              analityc.companyName,
              analityc.created,
              analityc.isComplete,

              analityc.isExpired,
              analityc.questionSequence,
              analityc.questionText,
              analityc.scoreId,
              analityc.skillName,
              analityc.correctAnswer,
              analityc.state,
              analityc.triviaId,
              analityc.triviaName,
              analityc.userId,

              analityc.userImage,
              analityc.userPoints,
              analityc.username,
              analityc.triviaAnswerTimeStr
            )
          )
        );
        this.setState({ rows: rowTests });
        this.setState({ numberPages: response.data.totalPages });
        this.setState({ loading: false });
      })
      .catch((error) => {
        console.log(error);
        toastr.error(
          this.props.t(
            "menu.trivia-panel-dialog-test-analytic-message-error-questions",
            "An error occurred while trying to get the questions"
          )
        );
        this.setState({ loading: false });
      });
  }

  /***********CRUD FUNCTIONS************* */

  /***********GENERAL FUNCTIONS AND SELECT************* */

  downloadAnalytics() {
    this.setState({ loading: true });

    let params = { triviaId: this.state.test, _format : "XLSX" };

    const sesion = JSON.parse(localStorage.getItem("sesion"));
    if (sesion && sesion.token) {
      //add token
      params._token = sesion.token;
    }
    let query = Object.keys(params)
      .filter((k) => params[k])
      .map(function (key) {
        return encodeURIComponent(key) + "=" + encodeURIComponent(params[key]);
      })
      .join("&");
    window.open(
      `${environment.motivarnosBackend}/report/execute/MotivarnosTriviaAnaliticoRespuestas?${query}`
    );

    this.setState({ loading: false });

  }

  handleChangeTest(value) {
    this.setState({ test: value });
    this.getAnalitycsQuestions(1, value, null);
  }

  handleChangeUser(value) {
    this.setState({ user: value });
    this.getAnalitycsQuestions(1, this.state.test, value);
  }

  render() {
    const { loading, rows, numberPages, page } = this.state;
    const { classes, t, i18n } = this.props;

    return (
      <main className={classes.content}>
        <div className={classes.appBarSpacer} />
        <Container maxWidth="lg" className={classes.container}>
          {loading && (
            <div className="div-spinner">
              <Grid container spacing={1}>
                <SpinnerCircular size={90} />
              </Grid>
            </div>
          )}
          {!loading &&
            (rows && rows.length > 0 ? (
              <Grid container spacing={1}>
                <Link to="/test/panel">
                  <Button
                    className="button-table mr-1"
                    variant="contained"
                    color="secondary"
                  >
                    <ArrowBackIosIcon className="mr-1" />
                    {t(
                      "menu.trivia-panel-dialog-test-question-button-back",
                      "Back"
                    )}
                  </Button>
                </Link>
                <Button
                  className="button-table mr-1"
                  variant="contained"
                  color="secondary"
                >
                  {t(
                    "menu.trivia-panel-dialog-test-analytic-title",
                    "Response analytics"
                  )}
                </Button>
                {/*<Button
                  className="button-table mr-1"
                  variant="contained"
                  color="primary"
                  onClick={() => this.downloadAnalytics()}
                >
                  <CloudDownloadIcon className="mr-1" />
                  {t(
                    "menu.trivia-panel-dialog-test-analytic-download",
                    "Download"
                  )}
                  </Button>*/}
                <Select 
                  options={this.state.trivias}
                  placeholder={<div>{this.state.label_trivia.length > 14 ? this.state.label_trivia.slice(0,14) + '...' : this.state.label_trivia }</div>}
                  value={this.state.id_trivia}
                  styles={{
                    control: (provided, state) => ({
                      ...provided,
                      width: '200px',
                      height: '55px',
                      marginBottom: '8px',
                    }),
                    option: (provided, state) => ({
                      ...provided,
                      fontSize: '0.7rem'
                    })
                  }}
                  onChange={(option) => this.selectTrivia(option)}
                />

                {this.state.trivias.length == 0 ? ( 
                  <div 
                    className="div-spinner" 
                    style={{
                      display: 'flex',
                      width: '100px',
                      justifyContent: 'center'
                    }}
                  >
                    <SpinnerCircular size={40} />
                 </div>
                ) : (
                  <DownloadTriviasAnalitic
                    rows={this.state.trivias}
                  />
                )}

                <TableContainer component={Paper}>
                  <Table className={classes.table} aria-label="simple table">
                    <TableHead>
                      <TableRow>
                        <TableCell align="left" className="row-display-none">
                          {t(
                            "admin.header-dropdown-view-conditions-table-user",
                            "User"
                          )}
                        </TableCell>

                        <TableCell align="left">
                          {t("menu.trivia-panel-button-trivia-name", "Trivia")}
                        </TableCell>

                        <TableCell align="left">
                          {t(
                            "menu.trivia-panel-dialog-test-question-table-question",
                            "Question"
                          )}
                        </TableCell>

                        <TableCell align="left" className="row-display-none">
                          {t(
                            "menu.trivia-panel-dialog-test-question-add-dialog-input-text-answer",
                            "Answer"
                          )}
                        </TableCell>

                        <TableCell align="left">
                          {t("status.label", "State")}
                        </TableCell>

                        <TableCell align="left" className="row-display-none">
                          {t("correct-response.label", "Correct answer")}
                        </TableCell>

                        <TableCell align="left" className="row-display-none">
                          {t(
                            "menu.metric-panel-dialog-metric-points-earned",
                            "Points earned"
                          )}
                        </TableCell>

                        <TableCell align="left" className="row-display-none">
                          {t(
                            "menu.trivia-panel-dialog-test-analytic-skill-earned",
                            "Skill earned"
                          )}
                        </TableCell>

                        <TableCell align="left" className="row-display-none">
                          {t("date.label", "Date")}
                        </TableCell>
                        <TableCell align="left" className="row-display-none">
                          Tiempo total Respuesta
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {rows.map((row, index) => (
                        <TableRow key={index}>
                          <TableCell align="left" className="row-display-none">
                            {row.username}
                          </TableCell>

                          <TableCell align="left">{row.triviaName}</TableCell>

                          <TableCell align="left">
                            {row.questionText
                              ? row.questionText
                              : t(
                                  "menu.trivia-panel-dialog-test-analytic-without-question",
                                  "Without question"
                                )}
                          </TableCell>

                          <TableCell align="left">
                            {row.answerText
                              ? row.answerText
                              : t(
                                  "menu.trivia-panel-dialog-test-analytic-without-answer",
                                  "Without answer"
                                )}
                          </TableCell>

                          <TableCell align="left" className="row-display-none">
                            {row.state === "CORRECT"
                              ? t(
                                  "menu.trivia-panel-dialog-test-question-add-dialog-input-text-correct",
                                  "Correct"
                                )
                              : row.state === "NOT_ANSWERED"
                              ? t(
                                  "menu.trivia-panel-dialog-test-analytic-without-answer",
                                  "Without answer"
                                )
                              : t(
                                  "menu.trivia-panel-dialog-test-question-add-dialog-input-text-incorrect",
                                  "Incorrect"
                                )}
                          </TableCell>

                          <TableCell align="left" className="row-display-none">
                            {row.state === "CORRECT"
                              ? row.correctAnswer
                              : t(
                                  "menu.trivia-panel-dialog-test-analytic-without-answer",
                                  "Without answer"
                                )}
                          </TableCell>

                          <TableCell align="left" className="row-display-none">
                            {row.userPoints}{" "}
                          </TableCell>

                          <TableCell align="left" className="row-display-none">
                            {row.skillName
                              ? row.skillName
                              : t(
                                  "menu.trivia-panel-dialog-test-analytic-without-skill",
                                  "Without skill"
                                )}
                          </TableCell>

                          <TableCell align="left" className="row-display-none">
                            {GeneralFunctions.formatNewDate(
                              row.created,
                              es
                            )}
                          </TableCell>
                          <TableCell align="left" className="row-display-none">
                            {row.triviaAnswerTimeStr}
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
                <div className={classes.root}>
                  <Pagination
                    count={numberPages}
                    page={page}
                    color="primary"
                    onChange={(event, value) => this.handleChange(event, value)}
                  />
                </div>
              </Grid>
            ) : (
              <>
                <Grid container spacing={1}>
                  <Link to="/test/panel">
                    <Button
                      className="button-table mr-1"
                      variant="contained"
                      color="secondary"
                    >
                      <ArrowBackIosIcon className="mr-1" />
                      {t(
                        "menu.trivia-panel-dialog-test-question-button-back",
                        "Back"
                      )}
                    </Button>
                  </Link>
                  <Button
                    className="button-table mr-1"
                    variant="contained"
                    color="secondary"
                  >
                    {t(
                      "menu.trivia-panel-dialog-test-analytic-title",
                      "Response analytics"
                    )}
                  </Button>

                  <Grid item xs={12} md={12} lg={12}>
                    <NotRegistersFound />
                  </Grid>
                </Grid>
              </>
            ))}
        </Container>
      </main>
    );
  }
}

export default withTranslation()(
  withStyles(styles, { withTheme: true })(TestsAnalytic)
);
