
import EmailValidator from "email-validator";
class UserModel {

    constructor(model = {}){
        this.setModel(model);
    }

    setModelFromCSV(usercsvData) {

        this.model.username = usercsvData.User;
        
        this.model.empresanombre = usercsvData.company;
        
        this.model.password = usercsvData.company + usercsvData.User;
        
        this.model.email = usercsvData.email;
        
        this.model.nombre = usercsvData.Name;
        
        this.model.apellido = usercsvData["Last Name"];

        this.model.rol = usercsvData.Role;

        this.model.gruponombre = usercsvData.Team
    }

    setModel(model){
        this.model = {
            username: model.username,
            password: model.password,
            confirmPassword: model.confirmPassword,
            email: model.email,
            nombre: model.nombre,
            apellido: model.apellido,
            telefono: model.telefono,
            empresanombre: model.empresanombre,
            gruponombre: model.gruponombre
        }
    }
    
    getUsername(){
        return this.model.username;
    }
    
    setUsername(username){
        this.model.username = username;
    }
    
    getPassword(){
        return this.model.password;
    }

    setPassword(password){
        this.model.password = password;
    }

    getConfirmPassword(){
        return this.model.confirmPassword;
    }

    setConfirmPassword(confirmPassword){
        this.model.confirmPassword = confirmPassword;
    }

    getEmail(){
        return this.model.email;
    }

    setEmail(email){
        this.model.email = email;
    }

    setNombre(nombre){
        this.model.nombre = nombre;
    }

    getNombre(){
        return this.model.nombre;
    }

    getApellido(){
        return this.model.apellido;
    }

    setApellido(apellido){
        this.model.apellido = apellido;
    }

    getTelefono(){
        return this.model.telefono;
    }
    
    setTelefono(telefono){
        this.model.telefono = telefono;
    }

    getEmpresaNombre(){
        return this.model.empresanombre;
    }

    setEmpresaNombre(empresaNombre){
        this.model.empresanombre = empresaNombre;
    }

    getGrupoNombre(){
        return this.model.gruponombre;
    }

    setGrupoNombre(gruponombre){
        this.model.gruponombre = gruponombre;
    }

    ValidateCSV = () => {
        var error = {
            ...this.model,
            importation:""
        };
        if(this.getEmail() && !this.ValidateEmail()){
            error.importation = error.importation.concat("Bad Email");
        }
        if(!this.ValidateUserName()){
            error.importation = error.importation.concat(",Bad Username");
        }
        if(!this.ValidatePassword()){
            error.importation = error.importation.concat(",Bad Password");
        }
        return error;
    }

    ValidateCompany = () => this.getEmpresaNombre() && this.getEmpresaNombre().length <= 30;
    ValidateEmail = () => this.getEmail() && (EmailValidator.validate(this.getEmail()) && this.getEmail().length <= 50);
    ValidateName = () => this.getNombre() && this.getNombre().length <= 30;
    ValidateLastName = () => this.getApellido() && this.getApellido().length <= 30;
    ValidatePassword = () => this.getPassword() && this.getPassword().length >= 8;
    ValidateUserName = () => this.getUsername() && /[\w.\-_]+$/.test(this.getUsername());
    ValidateConfirmPassword = () => this.getConfirmPassword() && this.getConfirmPassword() === this.getPassword();
}

export default UserModel;