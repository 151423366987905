import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import Container from "@material-ui/core/Container";
import Pagination from "@material-ui/lab/Pagination";
import ServiceDashboard from "../../Services/ServiceDashboard";
import { SpinnerCircular } from "spinners-react";
import { NotRegistersFound } from "../../common/NotRegistersFound";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
});

export default function TableAwards() {
  const classes = useStyles();
  const { t } = useTranslation();
  const [loading, setLoading] = useState(true);
  const [awards, setAwards] = useState([]);
  const [page, setPage] = useState(1);
  const [numberPages, setNumberPages] = useState(1);

  useEffect(() => {
    getGiftItemByTeam();
  }, []);

  const getGiftItemByTeam = (number = 1) => {
    ServiceDashboard.giftItemByTeam(number)
      .then((response) => {
        setAwards(response.data.content);
        setNumberPages(response.data.totalPages);
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  };

  const handleChange = (event, value) => {
    setPage(value);
    setLoading(true);
    getGiftItemByTeam(value);
  };

  return (
    <Grid container spacing={1}>
      {loading ? (
        <Container>
          <Grid container spacing={1} className="justify-content-center">
            <SpinnerCircular size={90} height={475} />
          </Grid>
        </Container>
      ) : (
        <>
          {awards && awards.length > 0 ? (
            <TableContainer component={Paper}>
              <h4 className="ml-1">{t("awards.label", "Awards")}</h4>

              <Table className={classes.table} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell>{t("team.label", "Team")}</TableCell>
                    <TableCell align="right">
                      {t(
                        "menu.trivia-panel-dialog-add-test-select-questions-ordered",
                        "Ordered"
                      )}
                    </TableCell>
                    <TableCell align="right">
                      {t("approved.label", "Approved")}
                    </TableCell>
                    <TableCell align="right">
                      {t("menu.award-report-table-rejected", "Rejected")}
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {awards.map((row, index) => (
                    <TableRow key={index}>
                      <TableCell component="th" scope="row">
                        {row.name}
                      </TableCell>
                      <TableCell align="right">{row.pendingCount}</TableCell>
                      <TableCell align="right">{row.acceptedCount}</TableCell>
                      <TableCell align="right">{row.rejectedCount}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>

              <Pagination
                className="mt-2"
                count={numberPages}
                page={page}
                color="primary"
                onChange={(event, value) => handleChange(event, value)}
              />
            </TableContainer>
          ) : (
            <Container>
              <Grid container spacing={1}>
                <Grid item xs={12} md={12} lg={12}>
                  <NotRegistersFound />
                </Grid>
              </Grid>
            </Container>
          )}
        </>
      )}
    </Grid>
  );
}
