import React, { useEffect, useState } from "react";
import { useLocation, NavLink } from "react-router-dom";
import { Line } from "react-chartjs-2";
import { SpinnerCircular } from "spinners-react";
import { useTranslation } from "react-i18next";

import InsertChartIcon from '@material-ui/icons/InsertChart';
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';

import TableDetailMetric from "./TableDetailMetric";

import MetricServices from "../../Services/Metrics/Player";

import "./styles.scss";

const Metric = () => {
  let location = useLocation();
  const URLinterface = new URLSearchParams(location.search);
  const id = URLinterface.get("id");

  const [data, setData] = useState(null);
  const [metric, setMetric] = useState(null);
  const [month, setMonth] = useState("");
  const [metricData, setMetricData] = useState(null);

  const metricsTypes = [
    {text: 'Últimos 30 días', value: 30},
    {text: 'Últimos 60 días', value: 60 },
    {text: 'Últimos 90 días', value: 90 }
  ]
  
  const { t } = useTranslation();

  const returnDate = (dateTarget) => {
    let [month, day, year] = dateTarget.toLocaleDateString("en-US").split("/");
    let date =
      year +
      "-" +
      (month > 9 ? month : "0" + month) +
      "-" +
      (day > 9 ? day : "0" + day) +
      "T00:00:00";
    return date;
  }

  const getMetricDataService = (dateFrom, dateTo, cantDays) => {
    const labels = [];
    setData(null);
    setMetricData(null)
    MetricServices.getMetricData(id, dateFrom, dateTo)
      .then((resp) => {
        let aux = new Array(cantDays).fill(0);

        let startDate = new Date(dateFrom);
        let endDate = new Date(dateTo);

        while (startDate <= endDate) {
          let date = new Date(startDate);
          date.setHours(0, 0, 0, 0);
          let formattedDate = date.toLocaleDateString('es-ES', {day: '2-digit', month: '2-digit', year: 'numeric'});
          labels.push(formattedDate);
          startDate.setDate(startDate.getDate() + 1);
        }

        let bottomLimit = 0;
        let topLimit = 0;
        resp.data.content.forEach((element) => {
          let position = labels.indexOf(new Date(element.date1).toLocaleDateString('es-ES', {day: '2-digit', month: '2-digit', year: 'numeric'}));
          if (element.metricConf.type !== "TIME") {
            aux[position] = element.value;
            if (bottomLimit === 0)
              bottomLimit = element.metricConf.rangeFrom1
                ? element.metricConf.rangeFrom1
                : 0;

            if (topLimit === 0) topLimit = element.metricConf.targetValue;
          } else {
            if (topLimit === 0) {
              let milestoneValues = parseInt(element.metricConf.targetValue);
              try {
                milestoneValues = element.metricConf.targetValue.split(":");
                topLimit =
                  parseInt(milestoneValues[0]) * 3600 +
                  parseInt(milestoneValues[1]) * 60 +
                  parseInt(milestoneValues[2]);
              } catch (error) {
                topLimit = 0;
              }
            }

            if (bottomLimit === 0) {
              let milestoneValues = parseInt(
                element.metricConf.rangeFrom1
                  ? element.metricConf.rangeFrom1
                  : 0
              );
              try {
                milestoneValues = element.metricConf.rangeFrom1.split(":");
                bottomLimit =
                  parseInt(milestoneValues[0]) * 3600 +
                  parseInt(milestoneValues[1]) * 60 +
                  parseInt(milestoneValues[2]);
              } catch (error) {
                bottomLimit = 0;
              }
            }

            var values = 0;
            try {
              values = element.value.split(":");
              var secondValue =
                parseInt(values[0]) * 3600 +
                parseInt(values[1]) * 60 +
                parseInt(values[2]);
            } catch (error) {
              values = 0;
            }

            aux[position] = secondValue;
          }
        });

        setData({
          datasets: [
            {
              label:
                resp.data.content[0] &&
                resp.data.content[0].metricConf.type === "TIME"
                  ? t("menu.metric-panel-title", "Metric") +
                    " " +
                    "(" +
                    t("seconds.label", "Seconds") +
                    ")"
                  : t("menu.metric-panel-title", "Metric"),
              data: aux,
              borderColor: "#969696",
              backgroundColor: "#969696",
              fill: false,
              borderWidth: 2,
              order: 1,
            },

            {
              label: t("goal.label", "Goal"),
              data: Array(cantDays).fill(topLimit),
              type: "line",
              borderColor: "#8CC543",
              backgroundColor: "#8CC543",
              fill: false,
              borderWidth: 2,
              order: 2,
            },
          ],
          labels: labels,
        });
        setMetricData(resp.data.content);
      })
      .catch((error) => {});
  }

  useEffect(() => {
    const dateObj = new Date();
    const monthName = dateObj.toLocaleString("default", { month: "long" }).toUpperCase();
    setMonth(monthName);
    var date = new Date();
    var firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
    var lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);

    let dateFrom = returnDate(firstDay)
    let dateTo = returnDate(lastDay)

    const cantDays = 31;

    getMetricDataService(dateFrom, dateTo, cantDays);

    MetricServices.getMetric(id)
      .then((resp) => {
        setMetric(resp.data);
      })
      .catch((error) => {});
    // eslint-disable-next-line
  }, []);


  const getMetric = (value) => {
    let month = ''

    switch(value){
      case 30:
        month = 'Últimos 30 días';
        break
      case 60:
        month = 'Últimos 60 días';
        break
      default:
        month = 'Últimos 90 días';
        break
    }

    setMonth(month);

    const dateNow = new Date();
    const dateOld = new Date();
    dateOld.setDate(dateNow.getDate() - value);

    let dateFrom = returnDate(dateOld)
    let dateTo = returnDate(dateNow)

    getMetricDataService(dateFrom, dateTo, value);
  }  

  if (metric)
    return (
      <div className="metric-wrapper">
        <div className="metric-wrapper-container-name">
          <div className="metric-wrapper-container-name-icon">
            <NavLink to={"/metrics"}>
            <div><NavigateBeforeIcon /></div>
            </NavLink>
          </div>
          <div className="metric-wrapper-container-name-title">{metric.name}</div>
        </div>
        <div className="metric-wrapper-container-types">
          {metricsTypes.map((elem, index) => (
            <div key={index} onClick={() => getMetric(elem.value)} className="metric-wrapper-container-types-item card-model">
              <div className="metric-wrapper-container-types-item-icon"><InsertChartIcon /></div>
              <div className="metric-wrapper-container-types-item-name">
                <div className="metric-wrapper-container-types-item-name-title">{elem.text}</div>
                <div className="metric-wrapper-container-types-item-name-subtitle">MOSTRAR</div>
              </div>
              <div className="metric-wrapper-container-types-item-bottom-left"></div>
              <div className="metric-wrapper-container-types-item-bottom-right">
                <div className="metric-wrapper-container-types-item-bottom-right-bar"></div>
              </div>
            </div>
          ))}
        </div>
        <div className="card-model metric-content">
          <div className="metric-content-labels-container">
            <div className="metric-content-labels-container-left">
              <span>{month}</span>
            </div>
            <div className="metric-content-labels-container-right">
              <div className="metric-label-description-content"> 
                <div className="metric-label-description">
                  <div className="metric-label-description-color">
                    <div className="metric-label-description-color-box" style={{backgroundColor: '#969696'}}></div>
                  </div>
                  <div className="metric-label-description-name"><div>METRICA</div></div>
                </div>
                <div className="metric-label-description">
                  <div className="metric-label-description-color">
                    <div className="metric-label-description-color-box" style={{backgroundColor: '#8CC543'}}></div>
                  </div>
                  <div className="metric-label-description-name">OBJETIVO</div>
                </div>
              </div>
            </div>
          </div>
          <span className="metric-chart-container">
            {data === null ? (
              <span className="spinner-container">
                <SpinnerCircular />
              </span>
            ) : (
              <Line
                data={data}
                height={100}
                options={{
                  legend: {
                    display: false
                  },
                  elements: {
                    line: {
                      tension: 0,
                    },
                  },
                }}
              />
            )}
          </span>
        </div>

        {metricData === null ? (
          <span className="spinner-container">
            <SpinnerCircular />
          </span>
        ) : (
          <>
            {metricData.length > 0 && (
              <TableDetailMetric data={metricData} />
            )}
          </>
        )}
      </div>
    );
  else return "";
};

export default Metric;
