import React, { useEffect, useState } from 'react'
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import LoginLanding from './LoginLanding';

const SignInSignUp = ({ padding, width }) => {
  const [value, setValue] = useState(0);
  const [reloadGoogleLogin, setReloadGoogleLogin] = useState(false)

  const handleChange = (event, newValue) => {
      setValue(newValue);
  };

  useEffect(() => {
    value == 0 ? setReloadGoogleLogin(true) : setReloadGoogleLogin(false);
  }, [value])

  function TabPanel(props) {
    const { children, value, index, ...other } = props;
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }
  
  return (
    <div>
        <TabPanel value={value} index={0}>
          <LoginLanding
            padding={padding}
            reloadGoogleTabs={reloadGoogleLogin}
            width={width}
          />
        </TabPanel>
    </div>
  )
}

export default SignInSignUp;
