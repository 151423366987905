import React from 'react';
import { Button } from '@material-ui/core';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import { downloadFiles } from '../../../../utils/downloadFiles';
import environment from '../../../../../../libs/environment';
import toastr from 'toastr';

const DownloadAllImagesButton = ({ images }) => {
  const handleDownloadAll = () => {
    const validImages = images.filter(image => image.file); // Filtra imágenes válidas
    if (validImages.length === 0) {
      toastr.error("No hay imágenes para descargar.");
      return;
    }

    validImages.forEach((image, index) => {
      const username = image.user ? image.user.username : `user-${index + 1}`;
      downloadFiles(environment.motivarnosBackend + image.file, `${username}`);
    });
  };

  return (
    <Button
      onClick={handleDownloadAll}
      variant="contained"
      color="#fff"
      style={{ marginTop: '4px', marginLeft: '10px', height: '55px' }}
      startIcon={<CloudDownloadIcon />}
    >
      Descargar imágenes
    </Button>
  );
};

export default DownloadAllImagesButton;
