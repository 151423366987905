import React from 'react';
import {Dialog as DialogComponent} from '@material-ui/core';
import './styles.scss';

const Dialog = (props) => {

    const handleClose = () => {
        if(props.onClose)
            props.onClose();
    }

    return(
        <React.Fragment>
            <DialogComponent
                open={props.open}
                onClose={handleClose}
                className='dialog-custom'
                fullScreen={props.fullScreen}
                fullWidth={props.fullWidth} // ESTO NO FUNCA :()
                maxWidth={props.maxWidth}
                TransitionComponent={props.TransitionComponent}
            >
                <div className='dialog-component-container'>
                    {props.children}
                </div>
            </DialogComponent>
        </React.Fragment>
    )
}

Dialog.defaulrProps = {
    open: false,

}

export default Dialog;