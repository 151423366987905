import React from 'react';
import { Button } from '@material-ui/core';
import { FormattedMessage } from "react-intl";
import { CSVReader } from 'react-papaparse';
import { importCSVAction,exposedCSVReducer } from './store/ImportCSVReducer';
import { useDispatch, useSelector } from "react-redux";
import { buttonStyles } from "../../../styles/styles";
import useAsyncReducer from "../../../useAsyncReducer";



const ImportCSV = (props) => {
    const buttonClasses = buttonStyles();
    useAsyncReducer([exposedCSVReducer]);
    const buttonRef = React.createRef();
    const dispatch = useDispatch();
    const handleOpenDialog = (e) => {
      if (buttonRef.current) { 
        buttonRef.current.open(e)
      }
    }  

    const handleOnFileLoad = async (data) => {
        await dispatch(importCSVAction(data));
    }

    return <>
    <span style={props.style}>
    <CSVReader
              ref={buttonRef}
              onFileLoad={handleOnFileLoad}
              noClick
              noDrag
              >
              { ({file}) => (
                <Button
                variant="contained"
                style={props.style}
                className={buttonClasses.buttonPurple}
                onClick={handleOpenDialog}
                >
                  <FormattedMessage
                  id="importCSV.import"
                  defaultMessage="Import CSV"
                  />
                </Button>
              )}
            </CSVReader>
    </span>
            
          </>
}
export default ImportCSV;