import * as challengesTypes from "../types/challengesTypes";

import ChallengeService from "../components/app/admin/Services/ChallengeService";

const {
  RESET_STATE,
  CHALLENGES_LIST_REQUEST,
  CHALLENGES_LIST_SAVED,
  CHALLENGES_LIST_UPDATE,
  CHALLENGES_LIST_CHARGING,
  CHALLENGES_LIST_DELETE,
  CHALLENGES_SAVE_CHARGING,
  CHALLENGES_LIST_ERROR,
} = challengesTypes;

export const getChallengesRequest = (payload) => async (dispatch, getState) => {
  try {
    const { pages } = getState().challengesReducer;
    if (!pages.includes(payload.number) || payload.reload) {
      dispatch({
        type: CHALLENGES_LIST_CHARGING,
      });
      const responseLogin = await ChallengeService.GetChallenges(
        payload.number
      );

      dispatch({
        type: CHALLENGES_LIST_REQUEST,
        payload: { ...responseLogin.data, number: payload.number },
      });
    } else {
      dispatch({
        type: CHALLENGES_LIST_SAVED,
      });
    }
  } catch (error) {
    dispatch({
      type: CHALLENGES_LIST_ERROR,
      payload: error.response ? error.response.data : error,
    });
  }
};
export const getChallengesRequest2 = (payload) => async (dispatch, getState) => {
  try {
    const { pages } = getState().challengesReducer;
    if (!pages.includes(payload.number) || payload.reload) {
      dispatch({
        type: CHALLENGES_LIST_CHARGING,
      });
      const responseLogin = await ChallengeService.GetChallenges2(
        payload.number
      );
      dispatch({
        type: CHALLENGES_LIST_REQUEST,
        payload: { ...responseLogin.data, number: payload.number },
      });
    } else {
      dispatch({
        type: CHALLENGES_LIST_SAVED,
      });
    }
  } catch (error) {
    dispatch({
      type: CHALLENGES_LIST_ERROR,
      payload: error.response ? error.response.data : error,
    });
  }
};
export const getChallengesUserRequest = (payload) => async (dispatch, getState) => {
  try {
    const { pages } = getState().challengesReducer;
    if (!pages.includes(payload.number) || payload.reload) {
      dispatch({
        type: CHALLENGES_LIST_CHARGING,
      });
      const responseLogin = await ChallengeService.GetChallenges2(
        payload.number
      );
      dispatch({
        type: CHALLENGES_LIST_REQUEST,
        payload: { ...responseLogin.data, number: payload.number },
      });
    } else {
      dispatch({
        type: CHALLENGES_LIST_SAVED,
      });
    }
  } catch (error) {
    dispatch({
      type: CHALLENGES_LIST_ERROR,
      payload: error.response ? error.response.data : error,
    });
  }
};
export const getDetailsRequest = (payload) => async (dispatch) => {

    dispatch({
        type: CHALLENGES_LIST_CHARGING,
      });

      try {
      const responseLogin = await ChallengeService.GetChallenges2();

     
      dispatch({
        type: CHALLENGES_LIST_SAVED,
        payload: responseLogin.data,
      });
    
  } catch (error) {
    dispatch({
      type: CHALLENGES_LIST_ERROR,
      payload: error.response ? error.response.data : error,
    });
  }
};


export const saveChallengeRequest = (payload, callback) => async (dispatch, getState) => {
  dispatch({
    type: CHALLENGES_SAVE_CHARGING,
  });

  try {
    await ChallengeService.CreateChallenge(payload);

    // Llamamos a la función de callback después de crear el desafío
    if (callback) {
      callback();
    }
  } catch (error) {
    dispatch({
      type: CHALLENGES_LIST_ERROR,
      payload: error.response ? error.response.data : error,
    });
  }
};
export const updateChallengeRequest =
  (payload) => async (dispatch, getState) => {
    dispatch({
      type: CHALLENGES_SAVE_CHARGING,
    });

    try {
      const response = await ChallengeService.CancelChallenge(payload);
      const { challenges } = getState().challengesReducer;

      const challengesUpdated = [...challenges];
      const findById = (challenge) => challenge.id === payload.id;
      const index = challengesUpdated.findIndex(findById);
      challengesUpdated[index] = {
        ...challengesUpdated[index],
        ...response.data,
      };

      dispatch({
        type: CHALLENGES_LIST_UPDATE,
        payload: challengesUpdated,
      });
      return "SUCCESS";
    } catch (error) {
      dispatch({
        type: CHALLENGES_LIST_ERROR,
        payload: error.response ? error.response.data : error,
      });
      return "ERROR";
    }
  };

  export const deleteChallengeRequest = (payload) => async (dispatch) => {
    dispatch({
      type: CHALLENGES_LIST_CHARGING,
    });
  
    try {
      await ChallengeService.DeleteChallenge(payload);
  
      dispatch({
        type: CHALLENGES_LIST_DELETE,
        payload: payload,
      });
      return "SUCCESS";
    } catch (error) {
      dispatch({
        type: CHALLENGES_LIST_ERROR,
        payload: error.response ? error.response.data : error,
      });
      return { error: error.response };
    }
  };

export const resetState = () => async (dispatch) => {
  dispatch({
    type: RESET_STATE,
  });
};
