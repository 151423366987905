
import React from "react";
import { styled } from "@material-ui/core/styles";
import { withStyles } from '@material-ui/core/styles';
import { StepConnector } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import LoyaltyIcon from '@material-ui/icons/Loyalty';
import PeopleIcon from '@material-ui/icons/People';
import MessageIcon from '@material-ui/icons/Message';

const useStyles = makeStyles((theme) => ({
    item: {
		fontSize: '2rem'
    },
	itemActive: {
		backgroundColor: '#000123 !important'
	},
	itemNoActive: {
        backgroundColor: '#E1E1E1',
    },
	icon: {
		fontSize: "3rem"
	},
	iconActive: {
		fill: "#000123",
	},
	iconNoActive: {
		fill: "#E1E1E1",
	}
}));

const styles = theme => ({

	line: {
		borderColor: '#E1E1E1',
	},

	lineHorizontal: {
		borderTopWidth: '3px',
	},

	completed: {
		'& $line': {
			borderColor: '#fa0050',
			borderTopStyle: 'solid',
		},
	},
	
	active: {
		'& $line': {
			borderColor: '#000123',
			borderTopStyle: 'solid',
		},
	},


});

const ColorPrimaryConnector = withStyles(styles)(StepConnector);

const StyledStepRoot = styled('div')(({ theme }) => ({
		zIndex: 1,
		color: '#fff',
		width: 50,
		height: 50,
		display: 'flex',
		borderRadius: '50%',
		justifyContent: 'center',
		alignItems: 'center',
		position: 'relative',
		bottom: '1rem'
	})
);


function ColorlibStyledStep(props) {
	const { active, completed, className, icon } = props;
	const classes = useStyles();

	const mainClass = className ?? '';
	const activeClass = (active || completed) ? classes.itemActive : classes.itemNoActive;
	const iconActiveClass = (active || completed) ? classes.iconActive : classes.iconNoActive;

	return (
		<StyledStepRoot className={`${classes.item} ${activeClass} ${mainClass}`}>

			<div style={{position: 'absolute', top: '-58px'}}>
				{icon == 1 && (<LoyaltyIcon className={`${classes.icon} ${iconActiveClass}`}/>)}
				{icon == 2 && (<PeopleIcon className={`${classes.icon} ${iconActiveClass}`}/>)}
				{icon == 3 && (<MessageIcon className={`${classes.icon} ${iconActiveClass}`}/>)}
			</div>
			{icon}
		</StyledStepRoot>
	);
}

export default ColorPrimaryConnector;

export {
	ColorlibStyledStep
}