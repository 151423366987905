import { Container, FormControl, InputLabel, makeStyles, MenuItem, Select, TextField } from '@material-ui/core';
import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { inputStyles } from '../../../../styles/styles';
import useAsyncReducer from '../../../../useAsyncReducer';
import { postedBasicData, exposedMetricsReducer } from './store/MetricsReducer';


const useStyles = makeStyles((theme) => ({
    formControl: {
      margin: theme.spacing(1),
      minWidth: 120,
      '& .MuiInputUnderline:after': {
        border: `solid 2px #ff0ba3`,      
      },
      '& .MuiOutlinedInput-root': {
          '& fieldset': {
              border: `solid 2px #ff0ba3`,
              borderRadius: "15px",
          },
          '&:hover fieldset': {
              border: `solid 2px #ff0ba3`
          },
          '&.Mui-focused fieldset': {
              border: `solid 2px #ff0ba3`
          },
      },
    },
    selectEmpty: {
      marginTop: theme.spacing(2),
    },
  }));

  const useReduxSelector = () =>
  useSelector((state) => ({
    nameOr: state.metricsReducer?.name, 
    frequencyOr: state.metricsReducer?.frequency,
    descriptionOr: state.metricsReducer?.description,
    typeOr: state.metricsReducer?.type, 
    metricTypeOr: state.metricsReducer?.metricType, 
    pointsOr: state.metricsReducer?.points
  }));


const BasicData = (props) => {
    const {nameOr,frequencyOr,descriptionOr,typeOr,metricTypeOr,pointsOr} = useReduxSelector();
    const dispatch = useDispatch();
    const classes = useStyles();
    const inputClasses = inputStyles();
    const [name, setName] = useState(nameOr);
    const [description, setDescription] = useState(descriptionOr);
    const [frequency, setFrequency] = useState(frequencyOr);
    const [type, setType] = useState(typeOr);
    const [points, setPoints] = useState(pointsOr);
    const [active, setActive] = useState(1);
    
    return (
        <>
            <Container style={{display:"flex", flexDirection:"column", flexWrap:"wrap"}}>
                <TextField className={inputClasses.lightPinkInput}
                            required
                            label={<FormattedMessage
                                id="metrics.metricName"
                                defaultMessage="Name"
                              />}
                            onChange={(e) => {
                                dispatch(postedBasicData({
                                    name: e.target.value,
                                    description:description,
                                    frequency:frequency,
                                    type:type,
                                    points:points,
                                    active:active
                                }));
                                setName(e.target.value);
                            }}
                            style={{ marginBottom: 12 }}
                            variant="outlined"
                ></TextField>
                <TextField className={inputClasses.pinkInput}
                            label={<FormattedMessage
                                id="metrics.metricDescription"
                                defaultMessage="Description"
                              />}
                            onChange={(e) => {
                                dispatch(postedBasicData({
                                    name: name,
                                    description:e.target.value,
                                    frequency:frequency,
                                    type:type,
                                    points:points,
                                    active:active
                                }));
                                setDescription(e.target.value);
                            }}
                            style={{ marginBottom: 12 }}
                            variant="outlined"
                ></TextField>
                
                <FormControl required variant="outlined" className={classes.formControl}>
                    <InputLabel id="demo-simple-select-outlined-label">Frequency</InputLabel>
                    <Select
                        labelId="demo-simple-select-outlined-label"
                        id="demo-simple-select-outlined"
                        value={frequency}
                        onChange={(e)=>{
                            dispatch(postedBasicData({
                                name: name,
                                description:description,
                                frequency:e.target.value,
                                type:type,
                                points:points,
                                active:active
                            }));
                            setFrequency(e.target.value);
                        }}
                        label="Frequency"
                    >
                        <MenuItem value={"HORARIA"}>Hourly</MenuItem>
                        <MenuItem value={"DIARIA"}>Daily</MenuItem>
                        <MenuItem value={"SEMANAL"}>Weekly</MenuItem>
                        <MenuItem value={"MENSUAL"}>Monthly</MenuItem>
                        <MenuItem value={"EVENTUAL"}>Eventual</MenuItem>
                    </Select>
                </FormControl>
                <FormControl required variant="outlined" className={classes.formControl}>
                    <InputLabel id="demo-simple-select-outlined-label">Type</InputLabel>
                    <Select
                        labelId="demo-simple-select-outlined-label"
                        id="demo-simple-select-outlined"
                        value={type}
                        onChange={(e)=>{
                            dispatch(postedBasicData({
                                name: name,
                                description:description,
                                frequency:frequency,
                                type:e.target.value,
                                points:points,
                                active:active
                            }));
                            setType(e.target.value);}}
                        label="type"
                    >
                        <MenuItem value={"SINO"}>Yes/No</MenuItem>
                        <MenuItem value={"ENTERO"}>Number</MenuItem>
                        <MenuItem value={"TIEMPO"}>Time</MenuItem>
                        <MenuItem value={"PORCENTAJE"}>Percentage</MenuItem>
                        <MenuItem value={"MULTIPLICADOR"}>Multiplier</MenuItem>
                        <MenuItem value={"CALCULADA"}>Calculated</MenuItem>
                    </Select>
                </FormControl>
                <FormControl required variant="outlined" className={classes.formControl}>
                    <InputLabel id="demo-simple-select-outlined-label">Points</InputLabel>
                    <Select
                        labelId="demo-simple-select-outlined-label"
                        id="demo-simple-select-outlined"
                        value={points}
                        onChange={(e)=>{
                            dispatch(postedBasicData({
                                name: name,
                                description:description,
                                frequency:frequency,
                                type:type,
                                points:e.target.value,
                                active:active
                            }));
                            setPoints(e.target.value);
                        }}
                        label="type"
                    >
                        <MenuItem value={1}>Gives points</MenuItem>
                        <MenuItem value={0}>Doesn't gives points</MenuItem>
                    </Select>
                </FormControl>
                <FormControl required variant="outlined" className={classes.formControl}>
                    <InputLabel id="demo-simple-select-outlined-label">Active</InputLabel>
                    <Select
                        labelId="demo-simple-select-outlined-label"
                        id="demo-simple-select-outlined"
                        value={active}
                        onChange={(e)=>{
                            dispatch(postedBasicData({
                                name: name,
                                description:description,
                                frequency:frequency,
                                type:type,
                                points:points,
                                active:e.target.value
                            }));
                            setActive(e.target.value);
                        }}
                        label="type"
                    >
                        <MenuItem value={1}>Active</MenuItem>
                        <MenuItem value={0}>Locked</MenuItem>
                    </Select>
                </FormControl>
            </Container>
        </>
    )
}

export default BasicData;