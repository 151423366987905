import axios from "axios";
import environment from "../../../../libs/environment";

export default {
  GetUsers: () =>
    axios({
      method: "GET",
      url: `${environment.motivarnosBackend}/user`,
    }),
    GetAllMetric: () =>
    axios({
      method: "GET",
      url: `${environment.motivarnosBackend}/metricconf`,
    }), 
    GetAllMetricNotFilter: () =>
    axios({
      method: "GET",
      url: `${environment.motivarnosBackend}/metricconf/nofilter`,
    }),
  GetMetric: (id1, id2) =>
    axios({
      method: "GET",
      url: `${environment.motivarnosBackend}/metricconf/userformatch?user.isActive=true&user.id_in=${id1},${id2}`,
    }),
    GetUsersByMetric: (id) =>
    axios({
      method: "GET",
      url: `${environment.motivarnosBackend}/metricconf/userreport?metricConf.id=${id}`,
    }),
    GetMetricTeams: (id) =>
    axios({
      method: "GET",
      url: `${environment.motivarnosBackend}/metricconf?teams.id=${id}&isActive=true&_sort=name`,
    }),

  CreateChallenge: (data) =>
    axios({
      method: "POST",
      url: `${environment.motivarnosBackend}/challenge`,
      data: data,
    }),

  GetChallenges: (number = 1, size) =>
    axios.get('/challenge',{params: {_number: number - 1, _size: size}}),

  CancelChallenge: (data) =>
    axios({
      method: "POST",
      url: `${environment.motivarnosBackend}/challenge/cancel`,
      data: data,
    }),
  DeleteChallenge: (id) =>
    axios({
      method: "DELETE",
      url: `${environment.motivarnosBackend}/challenge/${id}`,
    }),

   DetailsChallenge: (id) =>
    axios({
      method: "GET",
      url: `${environment.motivarnosBackend}/challengeuser?challenge.id=${id}`,
    }), 
};
