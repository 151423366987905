import React, { useState, useEffect } from "react";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import Drawer from "@material-ui/core/Drawer";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import List from "@material-ui/core/List";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import IconButton from "@material-ui/core/IconButton";
import Badge from "@material-ui/core/Badge";
import MenuIcon from "@material-ui/icons/Menu";
import MainListItems from "../common/listItems";

/********Icons and Images******** */
import systemImage from "../../../../assets/img/logo.svg";
import notification from "../../../../assets/img/bell.svg";
import badge from "../../../../assets/img/badge.svg";
import battle from "../../../../assets/img/battle.svg";
import mission from "../../../../assets/img/mission.svg";
import trivia from "../../../../assets/img/quiz.svg";
import megaphone from "../../../../assets/img/megaphone.svg";
/****************DropDown********** */
/********Icons******* */
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import { DropDownUser } from "../common/DropDownUser";
import { DropDownFunctions } from "../common/DropDownFunctions";
import Menu from "@material-ui/core/Menu";
import { NavLink } from "react-router-dom";
//import NotificationsService from "../../../../Services/Notifications/Player";
import { useTranslation } from "react-i18next";

const drawerWidth = 300;

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    color: "var(--warm-purple)",
    position: "relative",
    zIndex: "1",
    marginTop:
      localStorage.getItem("sesion") &&
      JSON.parse(localStorage.getItem("sesion")).souceType === "TALKDESK"
        ? "2rem"
        : "",
  },
  toolbar: {
    paddingRight: 24, // keep right padding when drawer closed
  },
  toolbarIcon: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: "0 8px",
    ...theme.mixins.toolbar,
  },
  appBar: {
    //color: 'var(--warm-purple)',
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    backgroundColor: "#fff",
    boxShadow: "none",
    borderBottom: "solid 1px #e1e1e1",
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
  },
  menuButtonHidden: {
    display: "none",
  },
  title: {
    flexGrow: 1,
  },
  drawerPaper: {
    position: "relative",
    whiteSpace: "nowrap",
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerPaperClose: {
    overflowX: "hidden",
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: theme.spacing(7),
    [theme.breakpoints.up("sm")]: {
      width: theme.spacing(9),
    },
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    height: "100vh",
    overflow: "auto",
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
  },
  fixedHeight: {
    height: 240,
  },
}));

export default function Dashboard({ children }) {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  //const [openNotifications, setOpenNotifications] = useState(false);
  //const [notifications, setNotifications] = useState([]);
  //const [notificationsLength, setNotificationsLength] = useState(0);

  const { t } = useTranslation();

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  /*const handleOpenNotifications = () => {
    setOpenNotifications(!openNotifications);
    if (notificationsLength > 0) {
      setNotificationsReaded();
    }
  };

  useEffect(() => {
    NotificationsService.getNotifications()
      .then((response) => {
        setNotifications(response.data.content);
      })
      .catch((error) => {});

    NotificationsService.getNotificationsNotReaded()
      .then((response) => {
        setNotificationsLength(response.data.content.length);
      })
      .catch((error) => {});
  }, []);

  const setNotificationsReaded = () => {
    NotificationsService.setNotificationsReaded()
      .then(() => {
        setNotificationsLength(0);
      })
      .catch((error) => {});
  };*/

  const getIcon = (type) => {
    switch (type) {
      case "TRIVIA":
        return trivia;
      case "MISSION":
        return mission;
      case "INSIGNIA,":
        return badge;
      case "TOURNAMENT":
        return battle;
      case "":
        return trivia;
      default:
        return megaphone;
    }
  };

  const getUrl = (type) => {
    switch (type) {
      case "TRIVIA":
        return "/test/panel";
      case "MISSION":
        return "/missions/panel";
      case "TOURNAMENT":
        return "/tournament/panel";
      case "INSIGNIA":
        return "/badges/panel";
      case "CHALLENGE":
        return "/challenges/panel";
      case "BET":
        return "/bets/panel";
      case "GIFTITEM":
        return "/awards/panel";
      case "LETTERSOUP":
          return "/letter/soup";
      default:
        return "/";
    }
  };

  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar
        position="absolute"
        className={clsx(classes.appBar, open && classes.appBarShift)}
      >
        <Toolbar className={classes.toolbar}>
          <IconButton
            edge="start"
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            className={clsx(
              classes.menuButton,
              open && classes.menuButtonHidden
            )}
          >
            <MenuIcon />
          </IconButton>
          <Typography
            component="h1"
            variant="h6"
            color="inherit"
            noWrap
            className={classes.title}
          >
            {JSON.parse(localStorage.getItem("theme")) &&
            JSON.parse(localStorage.getItem("theme")).logo ? (
              <img
                className="img-dashboard"
                src={`${JSON.parse(localStorage.getItem("theme")).logo}`}
                alt="Logo"
              />
            ) : (
              t("admin.header-dashboard", "Dashboard")
            )}
          </Typography>
          {JSON.parse(localStorage.getItem("sesion"))
            ? t("admin.header-hello", "Hello") +
              ", " +
              JSON.parse(localStorage.getItem("sesion")).user.username
            : ""}
          <IconButton
            color="inherit"
            //onClick={handleOpenNotifications}
            //className={
              //notifications && notificationsLength > 0
             //   ? "has-notifications"
             //   : "has-not-notifications"
            //}
            className="has-not-notifications"
          >
            {/*<Badge badgeContent={notificationsLength}>
                <span className="icon">
                  <img src={notification} alt="" />
                </span>
              </Badge>*/}
              <span className="icon">
                <img src={notification} alt="" />
              </span>
          </IconButton>
          {/*****************BUTTON Dropdown ********************/}
          <DropDownFunctions />
          {/*****************BUTTON Dropdown ********************/}
          {/*****************BUTTON Dropdown ********************/}
          <DropDownUser />
          {/*****************BUTTON Dropdown ********************/}
        </Toolbar>
      </AppBar>
      <Drawer
        variant="permanent"
        classes={{
          paper: clsx(
            classes.drawerPaper,
            !open && classes.drawerPaperClose,
            classes.newWidth
          ),
        }}
        open={open}
      >
        <div className={classes.toolbarIcon}>
          <span className="img-system-container">
            <img
              className="img-system"
              src={systemImage}
              alt="Imagen del sistema"
            />
          </span>
          <IconButton onClick={handleDrawerClose}>
            <ChevronLeftIcon />
          </IconButton>
        </div>
        <Divider />
        <List>{<MainListItems open={open} />}</List>
      </Drawer>

      {/*<Menu
          open={openNotifications}
          onClose={handleOpenNotifications}
          keepMounted
          className="notifications-menu-wrapper"
          anchorEl={null}
        >
          {notifications.map((data) => {
            return (
              <NavLink
                onClick={handleOpenNotifications}
                key={data.notification.id}
                to={getUrl(data.notification.type)}
              >
                <div className="menu-item">
                  <span className="notification-icon">
                    <img src={getIcon(data.notification.type)} alt="" />
                  </span>
                  <div className="notification-info">
                    <p className="mb-0 mt-0 bold">
                      {data.user.name} {data.user.lastName}
                    </p>
                    <span className="notification-title">
                      {data.notification.message}
                    </span>
                  </div>
                </div>
              </NavLink>
            );
          })}
        </Menu>*/
      }

      {children}
    </div>
  );
}
