import React from "react";
import { withStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import Container from "@material-ui/core/Container";
import IconButton from "@material-ui/core/IconButton";
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import toastr from "toastr";
import { SpinnerCircular } from "spinners-react";
import { NotRegistersFound } from "../../common/NotRegistersFound";
import Tooltip from "@material-ui/core/Tooltip";
import { withTranslation } from "react-i18next";
import ArrowDownwardIcon from "@material-ui/icons/ArrowDownward";
import ArrowUpwardIcon from "@material-ui/icons/ArrowUpward";
import { connect } from "react-redux";
import TablePagination from "@material-ui/core/TablePagination";

import {
  teamsRequest,
  secondaryTeamsRequest,
} from "../../../../../actions/generalActions";

/********Services******** */
import TeamService from "../../Services/TeamService";

/*************Icons******** */

import DeleteIcon from "@material-ui/icons/Delete";

import "toastr/build/toastr.min.css";
import Group from "../../common/Dialogs/Group";

const styles = (theme) => ({
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
  },
  fixedHeight: {
    height: 240,
  },
  root: {
    "& > *": {
      marginTop: theme.spacing(2),
    },
  },
  margin: {
    margin: theme.spacing(1),
  },
});

class GroupPanel extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      typeTeam: 'team',
      loading: false,
      viewImportUser: false,
      rowsPerPage: 5,
      order: "desc",
      filter: "",
      filtering: false,
      page: 0,
      teamsList: props.generalReducer.teams.content,
    };
  }

  componentDidMount() {
    if (!this.props.generalReducer.teams) {
      this.props.teamsRequest();
    }
  }

  handleChange(event, value) {
    this.setState({ page: value });
  }

  handleChangeTeam({ target: { value } }) {
    this.setState({ loading: true})
    if (value === 'secondaryTeam') {
      this.props.secondaryTeamsRequest();
      this.setState({ typeTeam: 'secondaryTeam', loading: false});
      return;
    }
    this.props.teamsRequest();
    this.setState({ typeTeam: 'team', loading: false});
  }

  async handleDeleteTeam(id, isSecondaryTeam) {
    this.setState({ loading: true });
    const status = await TeamService.deleteTeam({id, isSecondaryTeam});
    
    if (status.status === "ERROR") {
      toastr.error(
        this.props.t(
          "deleted-new-team-message-error.label",
          "Ha ocurrido un error mientras se eliminaba el equipo"
        )
      );
    } else {
      toastr.success(
        this.props.t(
          "deleted-new-team-message-success.label",
          "Se ha eliminado el equipo correctamente"
        )
      );
    }

    if (isSecondaryTeam) { 
      this.props.secondaryTeamsRequest();
      this.setState({ loading: false });
      return;
    }
    this.props.teamsRequest();
    this.setState({ loading: false });
  }

  handleChangeOrder() {
    if (this.state.order === "desc") {
      this.setState({ order: "asc" });
    } else {
      this.setState({ order: "desc" });
    }
  }

  searchSG(filter) {
    this.setState({ filter: filter });
    const filteredData =
      this.props.generalReducer.teams.content.filter((el) => {
        if (filter === "") {
          return el.name;
        } else {
          return el.name && el.name.toLowerCase().includes(filter);
        }
      });

    return filteredData;
  }

  render() {
    const { loading, rowsPerPage, page, viewImportUser, filter, order, typeTeam } =
      this.state;
    const { classes, t } = this.props;

    return (
      <main className={classes.content}>
        <div className={classes.appBarSpacer} />
        <Container maxWidth="lg" className={classes.container}>
          <Grid container className="d-flex-between mb-1">
            <Group typeTeam={typeTeam} generalReducer={this.props}/>

            <FormControl>
              <RadioGroup
                defaultValue="team"
                name="radio-buttons-group"
                onChange={(event) => this.handleChangeTeam(event)}
              >
                <FormControlLabel value="team" control={<Radio />} label={t("admin.header-dropdown-dialog-teams-input", "Equipos")} />
                <FormControlLabel value="secondaryTeam" control={<Radio />} label={t("admin.header-dropdown-dialog-secondary-teams-input", "Equipos Secundarios")} />
              </RadioGroup>
            </FormControl>
          </Grid>
          {(loading || this.props.generalReducer.teams_charging) &&
            !viewImportUser && (
              <div className="div-spinner">
                <Grid container spacing={1}>
                  <SpinnerCircular size={90} />
                </Grid>
              </div>
            )}

          {!loading && !this.props.generalReducer.teams_charging ? (
            <Grid container spacing={1}>
              <TableContainer component={Paper}>
                <Table className={classes.table} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell
                        align="left"
                        onClick={() => this.handleChangeOrder()}
                        className="cursor-pointer d-flex-custom"
                      >
                        {order === "desc" ? (
                          <ArrowUpwardIcon />
                        ) : (
                          <ArrowDownwardIcon />
                        )}
                        {t("admin.user-panel-table-name", "Name")}
                      </TableCell>
                      <TableCell align="left">
                        {t("admin.user-panel-table-state", "State")}
                      </TableCell>
                      <TableCell align="left" style={{textAlign: "right", paddingRight: "30px"}}>
                        {t("admin.user-panel-table-actions", "Actions")}
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {this.props.generalReducer.teams.content && 
                    typeTeam === 'team' && 
                      this.props.generalReducer.teams.content
                        .slice(
                          page * rowsPerPage,
                          page * rowsPerPage + rowsPerPage
                        )
                        .map((row) => {
                          return (
                            <TableRow key={row.id}>
                              <TableCell align="left">
                                {row.name ? row.name : "Whitout name"}
                              </TableCell>
                              <TableCell align="left">
                                {row.isActive
                                  ? t(
                                      "admin.header-dropdown-view-conditions-table-active-state",
                                      "Active"
                                    )
                                  : t(
                                      "admin.header-dropdown-view-conditions-table-blocked-state",
                                      "Blocked"
                                    )}
                              </TableCell>

                              <TableCell align="right">
                                <div
                                  style={{
                                    display: "flex",
                                    justifyContent: "right",
                                  }}
                                >
                                  <Group
                                    typeModal="modalEdit"
                                    typeTeam="team"
                                    generalReducer={this.props}
                                    {...row}
                                  />
                                  {row.isActive && (
                                    <Tooltip
                                      title={t(
                                        "admin.actions-delete",
                                        "Delete"
                                      )}
                                    >
                                      <IconButton
                                        aria-label="delete-user"
                                        onClick={() => {this.handleDeleteTeam(row.id, false)}}
                                      >
                                        <DeleteIcon fontSize="small" />
                                      </IconButton>
                                    </Tooltip>
                                  )}
                                </div>
                              </TableCell>
                            </TableRow>
                          );
                        })}

                    {this.props.generalReducer.secondary_teams.content && 
                    typeTeam === 'secondaryTeam' && 
                      this.props.generalReducer.secondary_teams.content
                        .slice(
                          page * rowsPerPage,
                          page * rowsPerPage + rowsPerPage
                        )
                        .map((row) => {
                          return (
                            <TableRow key={row.id}>
                              <TableCell align="left">
                                {row.name ? row.name : "Whitout name"}
                              </TableCell>
                              <TableCell align="left">
                                {row.isActive
                                  ? t(
                                      "admin.header-dropdown-view-conditions-table-active-state",
                                      "Active"
                                    )
                                  : t(
                                      "admin.header-dropdown-view-conditions-table-blocked-state",
                                      "Blocked"
                                    )}
                              </TableCell>

                              <TableCell align="left">
                                <div
                                  style={{
                                    display: "flex",
                                    justifyContent: "right",
                                  }}
                                >
                                  <Group
                                    typeModal="modalEdit"
                                    typeTeam="secondaryTeam"
                                    generalReducer={this.props}
                                    {...row}
                                  />
                                  {row.isActive && (
                                    <Tooltip
                                      title={t(
                                        "admin.actions-delete",
                                        "Delete"
                                      )}
                                    >
                                      <IconButton
                                        aria-label="delete-user"
                                        onClick={() => {this.handleDeleteTeam(row.id, true)}}
                                      >
                                        <DeleteIcon fontSize="small" />
                                      </IconButton>
                                    </Tooltip>
                                  )}
                                </div>
                              </TableCell>
                            </TableRow>
                          );
                        })}
                  </TableBody>
                </Table>
                <TablePagination
                  className="mt-1"
                  rowsPerPageOptions={[5]}
                  component="div"
                  count={
                    typeTeam === 'team' ? this.props.generalReducer.teams.numberOfElements : this.props.generalReducer.secondary_teams.numberOfElements
                  }
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={(event, value) =>
                    this.handleChange(event, value)
                  }
                />
              </TableContainer>
            </Grid>
          ) : (
            <>
              <Grid container spacing={1}>
                <Grid item xs={12} md={12} lg={12}>
                  <NotRegistersFound />
                </Grid>
              </Grid>
            </>
          )}
        </Container>
      </main>
    );
  }
}

const mapStateToProps = ({ generalReducer, usersReducer }) => ({
  generalReducer,
  usersReducer,
});
const mapDispatchToProps = {
  teamsRequest,
  secondaryTeamsRequest,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  withTranslation()(
    withStyles(styles, { withTheme: true })(GroupPanel)
  )
);
