export const RESET_STATE = "RESET_STATE";
export const RESET_STORE = "RESET_STORE";

export const CHALLENGES_LIST_REQUEST = "CHALLENGES_LIST_REQUEST";
export const CHALLENGES_LIST_SAVED = "CHALLENGES_LIST_SAVED";

export const CHALLENGES_LIST_SAVE = "CHALLENGES_LIST_SAVE";
export const CHALLENGES_LIST_UPDATE = "CHALLENGES_LIST_UPDATE";
export const CHALLENGES_LIST_DELETE = "CHALLENGES_LIST_DELETE";

export const CHALLENGES_LIST_CHARGING = "CHALLENGES_LIST_CHARGING";
export const CHALLENGES_SAVE_CHARGING = "CHALLENGES_SAVE_CHARGING";

export const CHALLENGES_LIST_ERROR = "CHALLENGES_LIST_ERROR";
