import React from "react";
import { withStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import Container from "@material-ui/core/Container";
import Button from "@material-ui/core/Button";
import { SpinnerCircular } from "spinners-react";
import { NotRegistersFound } from "../../common/NotRegistersFound";
import { es } from "date-fns/locale";
import GeneralFunctions from "../../libs/GeneralFunctions";
import { withTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import TablePagination from "@material-ui/core/TablePagination";

import { connect } from "react-redux";

import { getConditionsRequest } from "../../../../../actions/conditionsActions";

/********Services******** */

/*************Icons******** */
import ViewListIcon from "@material-ui/icons/ViewList";
import TermConditions from "../../common/Dialogs/TermConditions";
import "toastr/build/toastr.min.css";

const styles = (theme) => ({
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
  },
  fixedHeight: {
    height: 240,
  },
  root: {
    "& > *": {
      marginTop: theme.spacing(2),
    },
  },
  margin: {
    margin: theme.spacing(1),
  },
});

class UserPanelConditions extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      viewImportUser: false,
      page: 0,
      rowsPerPage: 5,
    };
  }

  componentDidMount() {
    let session = JSON.parse(localStorage.getItem("sesion"));
    if (session && session.company && session.company.conditionsFile) {
      if (!session.company.conditionsFile.startsWith("http")) {
        session.company.conditionsFile = `${session.company.conditionsFile}`;
      }
    }
    this.props.getConditionsRequest({ number: 1, reload: false });
  }

  handleChange(event, value) {
    this.setState({ page: value });
    this.props.getConditionsRequest({ number: value + 1, reload: false });
  }

  /***********GENERAL FUNCTIONS************* */

  /***********CRUD FUNCTIONS************* */

  render() {
    const { page, rowsPerPage } = this.state;
    const { classes, t, i18n } = this.props;

    return (
      <main className={classes.content}>
        <div className={classes.appBarSpacer} />
        <Container maxWidth="lg" className={classes.container}>
          {this.props.conditionsReducer.conditions_charging && (
            <div className="div-spinner">
              <Grid container spacing={1}>
                <SpinnerCircular size={90} />
              </Grid>
            </div>
          )}
          {!this.props.conditionsReducer.conditions_charging &&
            (this.props.conditionsReducer.conditions &&
            this.props.conditionsReducer.conditions.length > 0 ? (
              <Grid container spacing={1}>
                <TermConditions />

                

                <TableContainer component={Paper}>
                  <Table className={classes.table} aria-label="simple table">
                    <TableHead>
                      <TableRow>
                        <TableCell align="left">
                          {t(
                            "admin.header-dropdown-view-conditions-table-user",
                            "User"
                          )}
                        </TableCell>
                        <TableCell align="left">
                          {t(
                            "admin.header-dropdown-view-conditions-table-name",
                            "Name"
                          )}
                        </TableCell>
                        <TableCell align="left">
                          {t(
                            "admin.header-dropdown-view-conditions-table-email",
                            "Email"
                          )}
                        </TableCell>
                        <TableCell align="left">
                          {t(
                            "admin.header-dropdown-view-conditions-table-role",
                            "Role"
                          )}
                        </TableCell>
                        <TableCell align="left">
                          {t(
                            "admin.header-dropdown-view-conditions-table-team",
                            "Team"
                          )}
                        </TableCell>
                        <TableCell align="left">
                          {t(
                            "admin.header-dropdown-view-conditions-table-date",
                            "Date"
                          )}
                        </TableCell>
                        <TableCell align="left">
                          {t(
                            "admin.header-dropdown-view-conditions-table-state",
                            "State"
                          )}
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {this.props.conditionsReducer.conditions
                        .slice(
                          page * rowsPerPage,
                          page * rowsPerPage + rowsPerPage
                        )
                        .map((row) => (
                          <TableRow key={row.id}>
                            <TableCell align="left">{row.username}</TableCell>
                            <TableCell align="left">
                              {row.name + " " + row.lastName}
                            </TableCell>
                            <TableCell align="left">
                              {row.email
                                ? row.email
                                : t(
                                    "admin.header-dropdown-view-conditions-table-notEmail",
                                    "Without email"
                                  )}
                            </TableCell>
                            <TableCell align="left">
                              {row.role === 0
                                ? "Staff"
                                : row.role === 1
                                ? "Super Admin"
                                : row.role === 2
                                ? "Supervisor"
                                : row.role === 3
                                ? "Jugador"
                                : "N/A"}
                            </TableCell>
                            <TableCell align="left">
                              {row.team ? row.team.name : "N/A"}
                            </TableCell>
                            <TableCell align="left">
                              {GeneralFunctions.formatNewDate(
                                row.acceptedConditionsDate,
                                es
                              )}
                            </TableCell>
                            <TableCell align="left">
                              {row.isActive
                                ? t(
                                    "admin.header-dropdown-view-conditions-table-active-state",
                                    "Active"
                                  )
                                : t(
                                    "admin.header-dropdown-view-conditions-table-blocked-state",
                                    "Blocked"
                                  )}
                            </TableCell>
                          </TableRow>
                        ))}
                    </TableBody>
                  </Table>
                  <TablePagination
                    className="mt-1"
                    rowsPerPageOptions={[5]}
                    component="div"
                    count={this.props.conditionsReducer.totalElements}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={(event, value) =>
                      this.handleChange(event, value)
                    }
                  />
                </TableContainer>
              </Grid>
            ) : (
              <>
                <Grid container spacing={1}>
                  <TermConditions />

                  <Link className="link-custom" to="/user/conditions">
                    <Button
                      className="button-table ml-1"
                      variant="contained"
                      color="secondary"
                    >
                      <ViewListIcon className="mr-1" />
                      {t("report-last-login.label", "Last login report")}
                    </Button>
                  </Link>

                  <Grid item xs={12} md={12} lg={12}>
                    <NotRegistersFound />
                  </Grid>
                </Grid>
              </>
            ))}
        </Container>
      </main>
    );
  }
}

const mapStateToProps = (conditionsReducer) => conditionsReducer;
const mapDispatchToProps = {
  getConditionsRequest,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  withTranslation()(
    withStyles(styles, { withTheme: true })(UserPanelConditions)
  )
);
