import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Container, Slide } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';
import pxToRem from '../../../../utils/pxToRem';
import ImportCSVButton from "../../../common/CSVImporter/ImportCSV"
import {deleteCSVBody} from  "../../../common/CSVImporter/store/ImportCSVReducer"
import { useDispatch, useSelector } from 'react-redux';
import {exposedUserReducer, createUserFromCSV} from "./store/UserReducer";
import useAsyncReducer from "../../../../useAsyncReducer";

const useReduxSelector = () =>
  useSelector((state) => ({
    finishedProcess: state.usersReducer?.finishedProcess,
    mappedBody: state.importCSVReducer?.mappedBody,
    session: state.authReducer.session,
    dataToExport:state.usersReducer?.dataToExport
  }));
  
const ImportFile = (props) => {
    const loading = useAsyncReducer([exposedUserReducer]);
    const {finishedProcess, mappedBody,session,dataToExport} = useReduxSelector();
    const dispatch = useDispatch();
    const [activate,setActivate] = useState(true);
    const className = useStyles();

    if(mappedBody && activate){
        setActivate(false);
        dispatch(createUserFromCSV({
          entries:mappedBody,
          session:session
        }));
    }
    if(dataToExport && !activate){
        setActivate(true);
        dispatch(deleteCSVBody());
    }
    return( <>
        {loading && <>CARGANDO FALTA LOADING VIEW</>}
        {!loading && <>
            <Container style={{display:"flex", flexDirection:"column"}}>
                <h4 className={className.body} style={{textAlign:"center"}}>
                    <FormattedMessage
                        id="thankyou.body1"
                        defaultMessage="You almost got it! Once you finished with the template, load it using the button bellow."
                    />
                </h4>
                <ImportCSVButton style={{alignSelf:"center"}}></ImportCSVButton>
            </Container>    
        </>
        }
      </>
    )
}

const useStyles = makeStyles((theme) => ({
    motivational:{
        color:"black",
        letterSpacing: 6,
        fontSize: pxToRem(20),
        fontWeight:"light",
        fontStyle:"bold"
    },
    body: {
        color: "black",
        letterSpacing: 6,
        lineHeight: 1.1,
        fontSize: pxToRem(30),
        marginBottom: pxToRem(20),
        fontWeight: "bold"
    }
}));
export default ImportFile;