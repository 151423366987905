import axios from "axios";
import environment from "../../../libs/environment";

export default {
  GetDashboardMetricsFiltered: ({
    id,
    isOneOnOne = false,
    isPDS = false,
    isPIP = false,
    number = 0,
    size = 7,
    secondaryTeams = false
  }) =>
    axios({
      method: 'GET',
      url: `${
        environment.motivarnosBackend
      }/plan/dashboard?user.id=${id}&isOneOnOne=${isOneOnOne}&isPDS=${isPDS}&isPIP=${isPIP}&_page=${number}&_size=${size}${
        !secondaryTeams ? '' : `&user.roles.secondaryTeam.id_in=${secondaryTeams}`
      }`
    }),

  GetDashboardMetricsOneOnOne: ({
    id,
    number = 0,
    size = 7,
    secondaryTeams = false
  }) =>
    axios({
      method: 'GET',
      url: `${
        environment.motivarnosBackend
      }/plan/dashboard?user.id=${id}&isOneOnOne=true&_page=${number}&_size=${size}${
        !secondaryTeams ? '' : `&user.roles.secondaryTeam.id_in=${secondaryTeams}`
      }`
    }),

  GetDashboardMetricsPDS: ({
    id,
    number = 0,
    size = 7,
    secondaryTeams = false
  }) =>
    axios({
      method: 'GET',
      url: `${
        environment.motivarnosBackend
      }/plan/dashboard?user.id=${id}&isPDS=true&_page=${number}&_size=${size}${
        !secondaryTeams ? '' : `&user.roles.secondaryTeam.id_in=${secondaryTeams}`
      }`
    }),

  GetDashboardMetricsPIP: ({
    id,
    number = 0,
    size = 7,
    secondaryTeams = false
  }) =>
    axios({
      method: 'GET',
      url: `${
        environment.motivarnosBackend
      }/plan/dashboard?user.id=${id}&isPIP=true&_page=${number}&_size=${size}${
        !secondaryTeams ? '' : `&user.roles.secondaryTeam.id_in=${secondaryTeams}`
      }`
    }),

  getDashboardResumeMetrics: (number = 0, size = 7) =>
    axios({
      method: 'GET',
      url: `${environment.motivarnosBackend}/plan/dashboard?_page=${number}&_size=${size}`
    }),
};
