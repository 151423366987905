import React, { useState, useContext } from "react";

import TextField from "@material-ui/core/TextField/TextField";
import { FormattedMessage } from "react-intl";
import { useTranslation } from 'react-i18next';


const ValidateLastName = (lastName) => lastName && lastName.length <= 30;
const LastName = (props) =>{

    const [lastName, setLastName] = useState(props.lastName);
    const { t, i18n } = useTranslation();
    
    const changeLastName = (lastName) => {
        if(lastName && !ValidateLastName(lastName))
            return;
        setLastName(lastName);
        if(props.onChange) {
            props.onChange(lastName);
        }
    }

    return <TextField
                label={
                    <FormattedMessage
                        id="landing.lastnameLabel"
                        defaultMessage={t('last-name.label','Last name')}
                    />
                }
                //error={lastName && !ValidateLastName(lastName)}
                value={lastName}
                className = {props.className}
                variant="outlined"
                onChange={(e) => changeLastName(e.target.value)}
                style={{ marginBottom: 12 }}
            />
}

export default LastName;