import React from "react";
import { withStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import Container from "@material-ui/core/Container";
import Pagination from "@material-ui/lab/Pagination";
import toastr from "toastr";
import { SpinnerCircular } from "spinners-react";
import MetricSupervisors from "../../common/Dialogs/MetricSupervisors";
import { NotRegistersFound } from "../../common/NotRegistersFound";
import { withTranslation } from "react-i18next";

/********Services******** */
import UserServices from "../../Services/UserService";

/*********Dialog********* */
import DetailsSupervisors from "../../common/Dialogs/DetailsSupervisors";

import "toastr/build/toastr.min.css";

const styles = (theme) => ({
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
  },
  fixedHeight: {
    height: 240,
  },
  root: {
    "& > *": {
      marginTop: theme.spacing(2),
    },
  },
  margin: {
    margin: theme.spacing(1),
  },
});

class Supervisors extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      viewImportUser: false,
      rows: [],
      teams: [],
      numberPages: 1,
      page: 1,
    };
  }

  componentDidMount() {
    this.getUsers();
  }

  handleChange(event, value) {
    this.setState({ page: value });
    this.getUsers(value);
  }

  /***********GENERAL FUNCTIONS************* */

  getUsers(number = 1) {
    function createData(
      id,
      username,
      name,
      lastName,
      email,
      role,
      team,
      lastLogin,
      numberLogin,
      supervisedTeams,
      isActive
    ) {
      return {
        id,
        username,
        name,
        lastName,
        email,
        role,
        team,
        lastLogin,
        numberLogin,
        supervisedTeams,
        isActive,
      };
    }

    this.setState({ loading: true });
    UserServices.getUsersSupervisors(number)
      .then((response) => {
        this.setState({ rows: [] });
        let rowUsers = [];
        response.data.content.forEach((user) =>
          rowUsers.push(
            createData(
              user.id,
              user.username,
              user.name,
              user.lastName,
              user.email,
              user.role,
              user.team,
              "N/A",
              "N/A",
              user.supervisedTeams,
              user.isActive
            )
          )
        );
        this.setState({ rows: rowUsers });
        this.setState({ numberPages: response.data.totalPages });
        this.setState({ loading: false });
      })
      .catch(() => {
        toastr.error(
          this.props.t(
            "admin.user-panel-message-error-get-users",
            "An error occurred while trying to get the users"
          )
        );
        this.setState({ loading: false });
      });
  }

  /***********CRUD FUNCTIONS************* */

  render() {
    const { loading, rows, numberPages, page, viewImportUser } = this.state;
    const { classes, t } = this.props;

    return (
      <main className={classes.content}>
        <div className={classes.appBarSpacer} />
        <Container maxWidth="lg" className={classes.container}>
          {loading && !viewImportUser && (
            <div className="div-spinner">
              <Grid container spacing={1}>
                <SpinnerCircular size={90} />
              </Grid>
            </div>
          )}
          {!loading &&
            (rows && rows.length > 0 ? (
              <Grid container spacing={1}>
                <MetricSupervisors sendUser={() => this.getUsers(page)} />

                <TableContainer component={Paper}>
                  <Table className={classes.table} aria-label="simple table">
                    <TableHead>
                      <TableRow>
                        <TableCell align="left">
                          {t("admin.user-panel-table-user", "User")}
                        </TableCell>
                        <TableCell align="left">
                          {t("admin.user-panel-table-name", "Name")}
                        </TableCell>
                        <TableCell align="left">
                          {t("admin.user-panel-table-email", "Email")}
                        </TableCell>
                        <TableCell align="left">
                          {t("admin.user-panel-table-role", "Role")}
                        </TableCell>
                        <TableCell align="left">
                          {t("admin.user-panel-table-team", "Team")}
                        </TableCell>
                        <TableCell align="left">
                          {t("admin.user-panel-table-actions", "Actions")}
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {rows.map((row) => (
                        <TableRow key={row.id}>
                          <TableCell align="left">{row.username}</TableCell>
                          <TableCell align="left">
                            {row.name + " " + row.lastName}
                          </TableCell>
                          <TableCell align="left">{row.email}</TableCell>
                          <TableCell align="left">
                            {row.role === 0
                              ? t("admin.user-panel-table-staff", "Staff")
                              : row.role === 1
                              ? t(
                                  "admin.user-panel-table-superAdmin",
                                  "Super administrator"
                                )
                              : row.role === 2
                              ? t(
                                  "admin.user-panel-table-supervisor",
                                  "Supervisor"
                                )
                              : row.role === 3
                              ? t("admin.user-panel-table-player", "Player")
                              : "N/A"}
                          </TableCell>
                          <TableCell align="left">{row.team.name}</TableCell>

                          <TableCell align="left">
                            <DetailsSupervisors
                              supervisedTeams={row.supervisedTeams}
                            />
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>

                <div className={classes.root}>
                  <Pagination
                    count={numberPages}
                    page={page}
                    color="primary"
                    onChange={(event, value) => this.handleChange(event, value)}
                  />
                </div>
              </Grid>
            ) : (
              <>
                <Grid container spacing={1}>
                  <MetricSupervisors sendUser={() => this.getUsers(page)} />

                  <Grid item xs={12} md={12} lg={12}>
                    <NotRegistersFound />
                  </Grid>
                </Grid>
              </>
            ))}
        </Container>
      </main>
    );
  }
}

export default withTranslation()(
  withStyles(styles, { withTheme: true })(Supervisors)
);
