import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import { SpinnerCircular } from "spinners-react";
import TextField from "@material-ui/core/TextField";
import EditTorunament from "./Edit";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import Grid from "@material-ui/core/Grid";
import { format } from "date-fns";
import toastr from "toastr";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import { useTranslation } from "react-i18next";
import Button from "../../../../Button";
import TournamentServices from "../../Services/TournamentService";
import { connect } from "react-redux";

import { tournamentRequest } from "../../../../../actions/generalActions";

import {
  getTournamentsRequest,
  saveTournamentRequest,
  deleteTournamentRequest,
} from "../../../../../actions/tournamentsActions";

import "./styles.scss";

/*********Dialog********* */
import DetailsTournament from "../../common/Dialogs/DetailsTournament";

const useStyles = makeStyles((theme) => ({
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    overflow: "auto",
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
  },
  fixedHeight: {
    height: 240,
  },
  root: {
    "& > *": {
      marginTop: theme.spacing(2),
    },
  },
  appBar: {
    position: "relative",
  },
  formControl: {
    width: "100%",
    marginTop: "1rem",
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: "30%",
  },
}));

const options = [
  {
    id: 2,
    value: 2,
  },
  {
    id: 4,
    value: 4,
  },
  {
    id: 8,
    value: 8,
  },
  {
    id: 16,
    value: 16,
  },
  {
    id: 32,
    value: 32,
  },
];

function Tournament(props) {
  const [sesion, setSesion] = useState(null);
  const [steep, setSteep] = useState(1);
  const classes = useStyles();
  const steps = [1, 2, 3, 4];
  const [maxPlayer, setMaxPlayer] = useState(null);
  const [metricActive, setMetricActive] = useState(null);
  const [users, setUsers] = useState(null);
  const [players, setPlayers] = useState("null");
  const [name, setName] = useState("");
  const [points, setPoints] = useState("");
  const [fillType] = useState(false);
  const [playersGames, setPlayerGames] = useState([]);
  const [fetchingUsers, setFetchingUsers] = useState(false);
  const [fetching, setFetching] = useState(false);
  const [condition, setCondition] = useState("null");
  const [forceUpdate, setForceUpdate] = useState("");
  const [nextDates, setNextDates] = useState([]);
  const [openEdit, setOpenEdit] = useState(false);
  const [editID, setEditID] = useState(null);
  const [editToutnament, setEditToutnament] = useState(null);
  const [deleting, setDeleting] = useState(false);
  const { t } = useTranslation();

  const handleOpenEdit = (id, data) => {
    setOpenEdit(!openEdit);
    setEditID(id);
    setEditToutnament(data);
  };

  const handleMetric = (event) => {
    let value = event.target.value;
    //console.log(value);
    setMetricActive(value);
    setMaxPlayer(null);
    setPlayers("null");
    setFetchingUsers(true);
    TournamentServices.getUsers(value)
      .then((resp) => {
        setMaxPlayer(resp.data.content.length);
        setUsers(resp.data.content);
        setFetchingUsers(false);
      })
      .catch((error) => {
        setFetchingUsers(false);
      });
  };

  useEffect(() => {
    setSesion(JSON.parse(localStorage.getItem("sesion")));

    if (!props.generalReducer.metrics) {
      props.tournamentRequest();
    }

    props.getTournamentsRequest(1);
  }, []);

  const handleNumber = (event) => {
    let value = event.target.value;
    setPlayers(value);
    if (value === "null") {
      setPlayerGames([]);
    } else {
      let aux = [];
      setPlayerGames(new Array(parseInt(value, 10)).fill("null"));
      for (let i = 0; i < parseInt(value, 10); i++) {
        if (power_of_2(i)) {
          if (i === 0) {
            aux.push(new Array(1).fill(null));
          } else aux.push(new Array(i).fill(null));
        }
      }
      setNextDates(aux.reverse());
    }
  };

  function power_of_2(n) {
    if (typeof n !== "number") return "Not a number";

    return n && (n & (n - 1)) === 0;
  }

  const handlePlayers = (index, value) => {
    let aux = playersGames.slice(0);
    if (value === "null") aux[index] = value;
    else aux[index] = { id: value };
    setPlayerGames(aux);
  };

  const isSelected = (id) => {
    for (let i = 0; i < playersGames.length; i++) {
      if (playersGames[i] === "null") continue;
      else {
        if (playersGames[i].id + "" === id + "") return "disabled";
      }
    }
    return false;
  };

  const getPlayerGames = (j, playersGames) => {
    return j < playersGames.length ? (
      <span>
        {
          users.find(
            (item2) => item2.userId + "" === playersGames[j - 1].id + ""
          ).userName
        }{" "}
        {
          users.find(
            (item2) => item2.userId + "" === playersGames[j - 1].id + ""
          ).userLastName
        }{" "}
        vs{" "}
        {
          users.find((item2) => item2.userId + "" === playersGames[j].id + "")
            .userName
        }{" "}
        {
          users.find((item2) => item2.userId + "" === playersGames[j].id + "")
            .userLastName
        }{" "}
      </span>
    ) : (
      "N/A"
    );
  };

  const getMatchs = () => {
    let j = 1;
    let i = 1;

    return (
      <div>
        {nextDates.map((item, index) => {
          return item.map((data, k) => {
            return (
              <div style={{ display: "flex", flexDirection: "column" }}>
                <span style={{ fontWeight: "bold", marginTop: "1em" }}>
                  {t("admin.user-match", "Match")} {i++}
                </span>

                {getPlayerGames(j, playersGames)}
                <div className="d-none">
                  {j++} {j++}
                </div>

                <span>{data ? data : format(new Date(), "yyyy-MM-dd")}</span>
              </div>
            );
          });
        })}
      </div>
    );
  };

  const isFullMatch = () => {
    for (let i = 0; i < playersGames.length; i++) {
      if (playersGames[i] === "null") return true;
    }

    return false;
  };

  const createTournament = async () => {
    setFetching(true);

    let matchs = [];
    let i = 0;
    let j = 0;
    let l = 0;

    let aux = nextDates.map((item, index) => {
      return item.map((data, k) => {
        if (j < playersGames.length)
          return {
            player1: playersGames[j++],
            player2: playersGames[j++],
            date1: data
              ? data + "T00:00:00"
              : format(new Date(), "yyyy-MM-dd") + "T00:00:00",
            match1: null,
            match2: null,
            seq: i++,
            isFinal: false,
            level: 1,
          };
        else if (k === item.length - 1)
          return {
            player1: null,
            player2: null,
            date1: data
              ? data + "T00:00:00"
              : format(new Date(), "yyyy-MM-dd") + "T00:00:00",
            match1: { seq: l++ },
            match2: { seq: l++ },
            seq: i++,
            isFinal: true,
            level: 1,
          };
        else
          return {
            player1: null,
            player2: null,
            date1: data
              ? data + "T00:00:00"
              : format(new Date(), "yyyy-MM-dd") + "T00:00:00",
            match1: { seq: l++ },
            match2: { seq: l++ },
            seq: i++,
            isFinal: false,
            level: 1,
          };
      });
    });

    for (let i = 0; i < aux.length; i++) {
      matchs = matchs.concat(aux[i]);
    }

    let data = {
      name: name,
      points: points,
      metricConf: { id: metricActive },
      conditionType: condition,
      playerQty: players,
      isActive: true,
      matches: matchs,
    };

    let status;

    await props.saveTournamentRequest(data).then((r) => (status = r));

    if (status === "ERROR") {
      setFetching(false);

      toastr.error(t("admin.mission-message-error", "An error has occurred"));
    } else {
      setFetching(false);
      setSteep(1);
      setMaxPlayer(null);
      setMetricActive(null);
      setUsers(null);
      setPlayers("null");
      setName("");
      setPoints("");
      setPlayerGames([]);
      setCondition("null");
    }
  };

  const handleCondition = (event) => {
    let value = event.target.value;
    setCondition(value);
  };

  const handleDate = (i, j, e) => {
    let aux = nextDates;
    aux[i][j] = e.target.value;
    setNextDates(aux);
    setForceUpdate("" + Math.random());
  };

  const getSteep = (steep) => {
    let count = 0;
    let localCount = -2;
    switch (steep) {
      case 1:
        return (
          <React.Fragment>
            <span className="tournament-input">
              <TextField
                inputProps={{ maxLength: 280 }}
                id="name"
                name="name"
                label={t("admin.user-panel-tournament", "Tournament name")}
                variant="outlined"
                fullWidth
                onChange={(e) => setName(e.target.value)}
                value={name}
              />
            </span>
            <span className="tournament-input">
              <TextField
                inputProps={{ maxLength: 280 }}
                id="points"
                name="points"
                label={t("admin.user-number-points", "Number of points")}
                variant="outlined"
                fullWidth
                onChange={(e) => {
                  if (/^\d+$/.test(e.target.value)) setPoints(e.target.value);
                }}
                value={points}
              />
            </span>
            <span style={{ display: "flex", justifyContent: "center" }}>
              <Button
                onClick={handleNext}
                style={{ width: "max-content" }}
                disabled={name === "" || points === ""}
              >
                {t("next.label", "Next")}
              </Button>
            </span>
          </React.Fragment>
        );
      case 2:
        return (
          <React.Fragment>
            {
              /*metricActive===null?
            ''
            :*/
              <span className="tournament-input">
                <span>{t("select-metric.label", "Select metric")}</span>
                {props.generalReducer.metrics_charging ? (
                  <span className="spinner-container">
                    <SpinnerCircular />
                  </span>
                ) : (
                  <select
                    className="select-css"
                    onChange={handleMetric}
                    value={metricActive}
                    disabled={fetchingUsers ? "disabled" : false}
                  >
                    <option value={"null"}>----</option>
                    {props.generalReducer.metrics.content.map((data, index) => {
                      return (
                        <option key={index} value={data.id}>
                          {data.name}
                        </option>
                      );
                    })}
                  </select>
                )}
              </span>
            }
            {metricActive !== null ? (
              <span>
                <span>{t("place-in-ranking.label", "Place in ranking")}</span>
                <select
                  className="select-css"
                  onChange={handleCondition}
                  value={condition}
                >
                  <option value={"null"}>----</option>
                  <option value={"GREAT_THAN"}>
                    {t("me-above.label", "Me above")}
                  </option>
                  <option value={"LESS_THAN"}>
                    {t("me-below.label", "Me below")}
                  </option>
                </select>
              </span>
            ) : (
              ""
            )}
            {metricActive !== null ? (
              <span className="tournament-input">
                <span>
                  {t(
                    "admin.user-number-of-players",
                    "Select number of players"
                  )}
                </span>
                {maxPlayer === null ? (
                  <span className="spinner-container">
                    <SpinnerCircular />
                  </span>
                ) : (
                  <select
                    className="select-css"
                    onChange={handleNumber}
                    value={players}
                  >
                    <option value={"null"}>----</option>
                    {options.map((data, index) => {
                      if (data.value <= maxPlayer)
                        return (
                          <option key={index} value={data.id}>
                            {data.value}
                          </option>
                        );
                    })}
                  </select>
                )}
              </span>
            ) : (
              ""
            )}
            <span style={{ display: "flex", justifyContent: "center" }}>
              <Button
                secondary
                onClick={handleBack}
                style={{ marginRight: "1em" }}
              >
                {t("back.label", "Back")}
              </Button>
              <Button
                onClick={handleNext}
                disabled={players === "null" || condition === "null"}
              >
                {t("next.label", "Next")}
              </Button>
            </span>
          </React.Fragment>
        );
      case 3:
        return (
          <React.Fragment>
            {fillType + "" === "false" ? (
              <Grid
                container
                spacing={1}
                className={
                  nextDates && nextDates.length === 1 ? "grid-container" : ""
                }
              >
                {nextDates.map((item, index) => {
                  return item.map((data, i) => {
                    count++;
                    if (index === 0)
                      return (
                        <Grid item xs={12} md={6} lg={6} key={index}>
                          <div className="div-custom">
                            <h3 style={{ fontWeight: "bold" }}>
                              {t("admin.user-match", "Match")} {count}
                            </h3>

                            <TextField
                              InputProps={{
                                inputProps: {
                                  min: format(new Date(), "yyyy-MM-dd"),
                                },
                              }}
                              onChange={(e) => {
                                handleDate(index, i, e);
                              }}
                              value={nextDates[index][i]}
                              defaultValue={format(new Date(), "yyyy-MM-dd")}
                              type="date"
                              name="date"
                              id="date"
                              label={t("date.label", "Date")}
                              variant="outlined"
                            />

                            <h5>
                              {t("admin.user-select-player", "Select player")}{" "}
                              {i * 2 + 1}
                            </h5>
                            <span
                              style={{
                                height: "0",
                                opacity: "0",
                                display: "flex",
                              }}
                            >
                              {forceUpdate}
                            </span>
                            <select
                              key={index}
                              className="select-css"
                              onChange={(e) => {
                                handlePlayers(i * 2, e.target.value);
                              }}
                              value={
                                playersGames[i * 2 + 1 - 1] !== "null"
                                  ? playersGames[i * 2 + 1 - 1].id
                                  : "null"
                              }
                            >
                              <option value={"null"}>----</option>
                              {users.map((data) => {
                                return (
                                  <option
                                    key={data.userId}
                                    value={data.userId}
                                    disabled={isSelected(data.userId)}
                                  >
                                    {data.userName} {data.userLastName}
                                  </option>
                                );
                              })}
                            </select>
                            <span>
                              {t("admin.user-select-player", "Select player")}{" "}
                              {i * 2 + 2}
                            </span>
                            <span
                              style={{
                                height: "0",
                                opacity: "0",
                                display: "flex",
                              }}
                            >
                              {forceUpdate}
                            </span>
                            <select
                              key={index}
                              className="select-css"
                              onChange={(e) => {
                                handlePlayers(i * 2 + 1, e.target.value);
                              }}
                              value={
                                playersGames[i * 2 + 2 - 1] !== "null"
                                  ? playersGames[i * 2 + 2 - 1].id
                                  : "null"
                              }
                            >
                              <option value={"null"}>----</option>
                              {users.map((data) => {
                                return (
                                  <option
                                    key={data.userId}
                                    value={data.userId}
                                    disabled={isSelected(data.userId)}
                                  >
                                    {data.userName} {data.userLastName}
                                  </option>
                                );
                              })}
                            </select>
                          </div>
                        </Grid>
                      );
                    else {
                      localCount += 2;
                      return (
                        <Grid item xs={12} md={6} lg={6}>
                          <div className="div-custom">
                            <h3 style={{ fontWeight: "bold" }}>
                              {t("admin.user-match", "Match")} {count}
                            </h3>
                            <h4>
                              {t("admin.user-match-winner", "Match winner")}{" "}
                              {localCount + 1} VS{" "}
                              {t("admin.user-match-winner", "Match winner")}{" "}
                              {localCount + 2}
                            </h4>

                            <TextField
                              InputProps={{
                                inputProps: {
                                  min: format(new Date(), "yyyy-MM-dd"),
                                },
                              }}
                              onChange={(e) => {
                                handleDate(index, i, e);
                              }}
                              value={nextDates[index][i]}
                              defaultValue={format(new Date(), "yyyy-MM-dd")}
                              type="date"
                              name="date"
                              id="date"
                              label={t("date.label", "Date")}
                              variant="outlined"
                            />
                          </div>
                        </Grid>
                      );
                    }
                  });
                })}
              </Grid>
            ) : (
              <span className="spinner-container">
                <SpinnerCircular />
              </span>
            )}
            <span
              style={{
                display: "flex",
                justifyContent: "center",
                marginTop: "2em",
              }}
            >
              <Button
                secondary
                onClick={handleBack}
                style={{ marginRight: "1em" }}
              >
                {t("back.label", "Back")}
              </Button>
              <Button
                onClick={handleNext}
                disabled={
                  fillType === "null" ||
                  (isFullMatch() && fillType + "" === "false")
                }
              >
                {t("next.label", "Next")}
              </Button>
            </span>
          </React.Fragment>
        );
      case 4:
        return (
          <React.Fragment>
            <h4>
              {t(
                "admin.user-finish-tournament",
                "Press finish to create the tournament"
              )}
            </h4>
            {fillType + "" === "false" ? getMatchs() : ""}
            <span style={{ display: "flex", justifyContent: "center" }}>
              <Button
                secondary
                onClick={handleBack}
                style={{ marginRight: "1em" }}
                disabled={fetching}
              >
                {t("back.label", "Back")}
              </Button>
              <Button onClick={handleNext} disabled={fetching}>
                {t("admin.user-finish", "Finish")}
              </Button>
            </span>
          </React.Fragment>
        );
      default:
        return "";
    }
  };

  const handleNext = () => {
    if (steep < 4) setSteep(steep + 1);
    if (steep === 4) createTournament();
  };

  const handleBack = () => {
    if (steep > 1) setSteep(steep - 1);
  };

  const handleDelete = async (id) => {
    if (!deleting) {
      let status;
      setDeleting(true);

      await props.deleteTournamentRequest(id).then((r) => (status = r));

      if (status === "SUCCESS") {
        setDeleting(false);
      } else if (status.error.status === 422) {
        toastr.error(t("error-delete-torunament-processed", "You cannot delete the tournament because it has already been processed."));
      } else {
        toastr.error(t("admin.mission-message-error", "An error has occurred"));
      }
    }
  };

  if (sesion !== null)
    return (
      <main className={classes.content}>
        <div className={classes.appBarSpacer} />
        <Container maxWidth="lg" className={classes.container}>
          <h3>{t("tournaments.label", "Tournaments")}</h3>
          <div className="steep-container tournament">
            <Stepper activeStep={steep - 1}>
              {steps.map((label, index) => {
                const stepProps = {};
                const labelProps = {};
                return (
                  <Step key={label} {...stepProps}>
                    <StepLabel {...labelProps}>{}</StepLabel>
                  </Step>
                );
              })}
            </Stepper>
            {getSteep(steep)}
          </div>
          {props.tournamentsReducer.tournaments !== null ? (
            <div className="tournament-table">
              <table className="pending-bets-table">
                <thead>
                  <tr>
                    <th>{t("name.label", "Name")}</th>
                    <th>
                      {t(
                        "menu.trivia-panel-dialog-add-test-select-players",
                        "Players"
                      )}
                    </th>
                    <th>{t("menu.metric-panel-title", "Metric")}</th>
                    <th>{t("place-in-ranking.label", "Place in ranking")}</th>
                    <th>{t("points.label", "Points")}</th>
                    <th></th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {props.tournamentsReducer.tournaments.map((data, index) => {
                    return (
                      <tr className="table-content" key={index}>
                        <td>{data.name}</td>
                        <td>{data.users.length}</td>
                        <td>{data.metricConf.name}</td>
                        <td>
                          {data.conditionType === "LESS_THAN"
                            ? t("me-below.label", "Me below")
                            : t("me-above.label", "Me above")}
                        </td>
                        <td>{data.points}</td>
                        <td>
                          <DetailsTournament data={data} />
                        </td>

                        <td
                          onClick={() => handleOpenEdit(data.id, data)}
                          className="pointer"
                        >
                          <EditIcon />
                        </td>
                        <td
                          onClick={() => {
                            handleDelete(data.id);
                          }}
                          className="pointer"
                        >
                          <DeleteIcon />
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          ) : (
            ""
          )}
          <EditTorunament
            open={openEdit}
            id={editID}
            onClose={() => handleOpenEdit(null, null)}
            data={editToutnament}
          />
        </Container>
      </main>
    );
  else return "";
}

const mapStateToProps = ({ generalReducer, tournamentsReducer }) => ({
  generalReducer,
  tournamentsReducer,
});

const mapDispatchToProps = {
  tournamentRequest,
  getTournamentsRequest,
  saveTournamentRequest,
  deleteTournamentRequest,
};

export default connect(mapStateToProps, mapDispatchToProps)(Tournament);
