import React from "react";
import { withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Container from "@material-ui/core/Container";
import Button from "@material-ui/core/Button";
import toastr from "toastr";
import { SpinnerCircular } from "spinners-react";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import InputLabel from "@material-ui/core/InputLabel";
import { withTranslation } from "react-i18next";

import "toastr/build/toastr.min.css";
/********Services***** */
import ThemeService from "../../Services/ThemeService";

/*********Icons********* */
import ViewListIcon from "@material-ui/icons/ViewList";

const styles = (theme) => ({
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
  },
  fixedHeight: {
    height: 240,
  },
  root: {
    "& > *": {
      marginTop: theme.spacing(2),
    },
  },
  margin: {
    margin: theme.spacing(1),
  },
  input: {
    display: "none",
  },
  formControl: {
    width: "100%",
    marginTop: "1rem",
    padding: "5px",
  },
});

class Links extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      links: [],
    };
  }

  componentDidMount() {
    this.getMenuLinks();
  }

  handleChange(e, i) {
    let menusArray = this.state.links;
    menusArray[i].isShow = e.target.value;
    this.setState((prevState) => ({ ...prevState, links: menusArray }));
  }

  async onFormSubmit() {
    this.setState({ loading: true });

    let json = this.state.links;
    ThemeService.updateLinks(json)
      .then(() => {
        toastr.success(this.props.t("link-saved", "Links saved successfully"));
        this.setState((prevState) => ({ ...prevState, loading: false }));
      })
      .catch(() => {
        toastr.error(
          this.props.t(
            "link-saved-save-message-error",
            "An error occurred while trying to save the links"
          )
        );
        this.setState({ loading: false });
      });
  }

  /***********GENERAL FUNCTIONS************* */

  getMenuLinks() {
    this.setState({ loading: true });
    ThemeService.getMenuLinks()
      .then((response) => {
        this.setState({ loading: false, links: response.data });
      })
      .catch(() => {
        toastr.error(
          this.props.logoutFunction()
        );
        this.setState({ loading: false, links: [] });
      });
  }

  render() {
    const { loading, links } = this.state;
    const { classes, t } = this.props;
    return (
      <main className={classes.content}>
        <div className={classes.appBarSpacer} />
        <Container maxWidth="lg" className={classes.container}>
          <Grid container spacing={1}>
            <Button
              className="button-table mr-1"
              variant="contained"
              color="secondary"
            >
              <ViewListIcon className="mr-1" />
              {t(
                "admin.header-dropdown-view-links-title",
                "Link configurations"
              )}
            </Button>
          </Grid>

          {loading && (
            <div className="div-spinner">
              <Grid container spacing={1}>
                <SpinnerCircular size={90} />
              </Grid>
            </div>
          )}

          {!loading && (
            <Grid container spacing={1}>
              <Grid container>
                {links.map((menu, index) => (
                  <Grid item xs={12} md={6} lg={6} key={index}>
                    <FormControl
                      variant="outlined"
                      className={classes.formControl}
                    >
                      <InputLabel id={`${menu.name}-select-outlined-label`}>
                        {t( menu.name , menu.name )}
                      </InputLabel>
                      <Select
                        labelId={menu.name}
                        id={menu.name}
                        name={menu.name}
                        label={menu.name}
                        value={menu.isShow}
                        onChange={(e) => this.handleChange(e, index)}
                      >
                        <MenuItem value={true}>
                          {t(
                            "admin.header-dropdown-view-links-menuName-input-yes",
                            "Yes"
                          )}{" "}
                        </MenuItem>
                        <MenuItem value={false}>
                          {t(
                            "admin.header-dropdown-view-links-menuName-input-no",
                            "No"
                          )}
                        </MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                ))}
              </Grid>

              <Grid item xs={12}>
                <Button
                  onClick={() => this.onFormSubmit()}
                  className="button-table mt-2"
                  color="primary"
                >
                  {t("admin.header-dropdown-dialog-actions-save", "Save")}
                </Button>
              </Grid>
            </Grid>
          )}
        </Container>
      </main>
    );
  }
}

export default withTranslation()(
  withStyles(styles, { withTheme: true })(Links)
);
