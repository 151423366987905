import * as usersTypes from "../types/usersTypes";

import UserService from "../components/app/admin/Services/UserService";

const {
  RESET_STATE_SPECIAL,
  USERS_LIST_REQUEST,
  USERS_LIST_SAVED,
  USERS_LIST_SAVE,
  USERS_LIST_UPDATE,
  USERS_LIST_DELETE,
  USERS_LIST_CHARGING,
  USERS_SAVE_CHARGING,
  USERS_LIST_ERROR,
  USERS_LIST_REQUESTED,
} = usersTypes;

export const getUsersRequest = (payload) => async (dispatch, getState) => {
  try {
    const { pages } = getState().usersReducer;
    if (!pages.includes(payload.number)) {
      dispatch({
        type: USERS_LIST_CHARGING,
      });
      let responseLogin;

      responseLogin = await UserService.getUsers(
        payload.number,
        payload.rowsPerPage,
        payload.order
      );

      dispatch({
        type: USERS_LIST_REQUEST,
        payload: { ...responseLogin.data, number: payload.number },
      });
    } else {
      dispatch({
        type: USERS_LIST_SAVED,
      });
    }
  } catch (error) {
    dispatch({
      type: USERS_LIST_ERROR,
      payload: error.response ? error.response.data : error,
    });
  }
};
export const getUsersActiveRequest = (payload) => async (dispatch, getState) => {
  try {
    const { pages } = getState().usersReducer;
    if (!pages.includes(payload.number)) {
      dispatch({
        type: USERS_LIST_CHARGING,
      });
      let responseLogin;

      responseLogin = await UserService.getUsersIsActive(
        payload.number,
        payload.rowsPerPage,
        payload.order
      );

      dispatch({
        type: USERS_LIST_REQUEST,
        payload: { ...responseLogin.data, number: payload.number },
      });
    } else {
      dispatch({
        type: USERS_LIST_SAVED,
      });
    }
  } catch (error) {
    dispatch({
      type: USERS_LIST_ERROR,
      payload: error.response ? error.response.data : error,
    });
  }
};
export const getUsersBlockedRequest = (payload) => async (dispatch, getState) => {
  try {
    const { pages } = getState().usersReducer;
    if (!pages.includes(payload.number)) {
      dispatch({
        type: USERS_LIST_CHARGING,
      });
      let responseLogin;

      responseLogin = await UserService.getUsersIsFalse(
        payload.number,
        payload.rowsPerPage,
        payload.order
      );

      dispatch({
        type: USERS_LIST_REQUEST,
        payload: { ...responseLogin.data, number: payload.number },
      });
    } else {
      dispatch({
        type: USERS_LIST_SAVED,
      });
    }
  } catch (error) {
    dispatch({
      type: USERS_LIST_ERROR,
      payload: error.response ? error.response.data : error,
    });
  }
};

export const getUsersFilteredRequest =
  (payload) => async (dispatch, getState) => {
    try {
      dispatch({
        type: USERS_LIST_CHARGING,
      });
        
      const responseLogin = await UserService.filterUsers(
        payload.number,
        payload.rowsPerPage,
        payload.filter,
        payload.order
      );

      dispatch({
        type: USERS_LIST_REQUEST,
        payload: { 
          ...responseLogin.data, 
          number: payload.number, 
          filter: payload.filter, 
          reset_pages: payload.number == 1,
          pages: [1]
        },
      });

    } catch (error) {
      dispatch({
        type: USERS_LIST_ERROR,
        payload: error.response ? error.response.data : error,
      });
    }
  };
export const getUsersFilteredRequest2 =
  (payload) => async (dispatch, getState) => {
    try {
      dispatch({
        type: USERS_LIST_CHARGING,
      });
        
      const responseLogin = await UserService.filterActiveUsers(
        payload.number,
        payload.rowsPerPage,
        payload.filter,
        payload.order
      );

      dispatch({
        type: USERS_LIST_REQUEST,
        payload: { 
          ...responseLogin.data, 
          number: payload.number, 
          filter: payload.filter, 
          reset_pages: payload.number == 1,
          pages: [1]
        },
      });

    } catch (error) {
      dispatch({
        type: USERS_LIST_ERROR,
        payload: error.response ? error.response.data : error,
      });
    }
  };
export const getUsersFilteredRequest3 =
  (payload) => async (dispatch, getState) => {
    try {
      dispatch({
        type: USERS_LIST_CHARGING,
      });
        
      const responseLogin = await UserService.filterBlockedUsers(
        payload.number,
        payload.rowsPerPage,
        payload.filter,
        payload.order
      );

      dispatch({
        type: USERS_LIST_REQUEST,
        payload: { 
          ...responseLogin.data, 
          number: payload.number, 
          filter: payload.filter, 
          reset_pages: payload.number == 1,
          pages: [1]
        },
      });

    } catch (error) {
      dispatch({
        type: USERS_LIST_ERROR,
        payload: error.response ? error.response.data : error,
      });
    }
  };

export const getUsersFilteredRequested =
  (payload) => async (dispatch, getState) => {
    try {
      dispatch({
        type: USERS_LIST_CHARGING,
      });

      let user_requested = payload.user_requested;

      if(payload.user_requested.pages.includes(payload.number) == false) {

        const responseLogin = await UserService.filterUsers(
          payload.number,
          payload.rowsPerPage,
          payload.filter,
          payload.order
        );

        user_requested.content = [ ...user_requested.content, ...responseLogin.data.content ]
        user_requested.pages = [ ...user_requested.pages, payload.number ]

      }

      dispatch({
        type: USERS_LIST_REQUESTED,
        payload: { ...user_requested, number: payload.number },
      });

    } catch (error) {
      dispatch({
        type: USERS_LIST_ERROR,
        payload: error.response ? error.response.data : error,
      });
    }
  };
export const getUsersFilteredRequested2 =
  (payload) => async (dispatch, getState) => {
    try {
      dispatch({
        type: USERS_LIST_CHARGING,
      });

      let user_requested = payload.user_requested;

      if(payload.user_requested.pages.includes(payload.number) == false) {

        const responseLogin = await UserService.filterActiveUsers(
          payload.number,
          payload.rowsPerPage,
          payload.filter,
          payload.order
        );

        user_requested.content = [ ...user_requested.content, ...responseLogin.data.content ]
        user_requested.pages = [ ...user_requested.pages, payload.number ]

      }

      dispatch({
        type: USERS_LIST_REQUESTED,
        payload: { ...user_requested, number: payload.number },
      });

    } catch (error) {
      dispatch({
        type: USERS_LIST_ERROR,
        payload: error.response ? error.response.data : error,
      });
    }
  };
export const getUsersFilteredRequested3 =
  (payload) => async (dispatch, getState) => {
    try {
      dispatch({
        type: USERS_LIST_CHARGING,
      });

      let user_requested = payload.user_requested;

      if(payload.user_requested.pages.includes(payload.number) == false) {

        const responseLogin = await UserService.filterBlockedUsers(
          payload.number,
          payload.rowsPerPage,
          payload.filter,
          payload.order
        );

        user_requested.content = [ ...user_requested.content, ...responseLogin.data.content ]
        user_requested.pages = [ ...user_requested.pages, payload.number ]

      }

      dispatch({
        type: USERS_LIST_REQUESTED,
        payload: { ...user_requested, number: payload.number },
      });

    } catch (error) {
      dispatch({
        type: USERS_LIST_ERROR,
        payload: error.response ? error.response.data : error,
      });
    }
  };

export const saveUserRequest = (payload) => async (dispatch, getState) => {
  dispatch({
    type: USERS_SAVE_CHARGING,
  });

  try {
    const responseLogin = await UserService.saveUser(payload);
    const { users } = getState().usersReducer;
    const usersUpdated = [responseLogin.data, ...users];

    dispatch({
      type: USERS_LIST_SAVE,
      payload: usersUpdated,
    });
    return { status: "SUCCESS" };
  } catch (error) {
    dispatch({
      type: USERS_LIST_ERROR,
      payload: error.response ? error.response.data : error,
    });
    return { status: "ERROR", error: error.response };
  }
};

export const updateUserRequest = (payload) => async (dispatch, getState) => {
  dispatch({
    type: USERS_SAVE_CHARGING,
  });

  try {
    await UserService.updateUser(payload);
    const { users } = getState().usersReducer;

    const usersUpdated = [...users];
    const findById = (user) => user.id === payload.id;
    const index = usersUpdated.findIndex(findById);
    usersUpdated[index] = {
      ...usersUpdated[index],
      ...payload,
    };

    dispatch({
      type: USERS_LIST_UPDATE,
      payload: usersUpdated,
    });
    return { status: "SUCCESS" };
  } catch (error) {
    dispatch({
      type: USERS_LIST_ERROR,
      payload: error.response ? error.response.data : error,
    });
    return { status: "ERROR", error: error.response };
  }
};

export const deleteUserRequest = (payload) => async (dispatch) => {
  dispatch({
    type: USERS_LIST_CHARGING,
  });

  try {
    await UserService.deleteUser(payload);

    dispatch({
      type: USERS_LIST_DELETE,
      payload: payload,
    });
    return "SUCCESS";
  } catch (error) {
    dispatch({
      type: USERS_LIST_ERROR,
      payload: error.response ? error.response.data : error,
    });
    return { error: error.response };
  }
};

export const disableUserRequest = (payload) => async (dispatch, getState) => {
  dispatch({
    type: USERS_LIST_CHARGING,
  });

  try {
    await UserService.deleteUser(payload);
    const { users } = getState().usersReducer;
    const usersUpdated = [...users];
    const findById = (user) => user.id === payload;
    const index = usersUpdated.findIndex(findById);
    usersUpdated[index] = {
      ...usersUpdated[index],
      isActive: false,
    };

    dispatch({
      type: USERS_LIST_UPDATE,
      payload: usersUpdated,
    });

    return "SUCCESS";
  } catch (error) {
    dispatch({
      type: USERS_LIST_ERROR,
      payload: error.response ? error.response.data : error,
    });
    return { error: error.response };
  }
};

export const resetState = () => async (dispatch) => {
  dispatch({
    type: RESET_STATE_SPECIAL,
  });
};
