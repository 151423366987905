import * as missionsTypes from "../types/missionsTypes";

import MissionService from "../components/app/admin/Services/MissionsService";

const {
  RESET_STATE,
  MISSIONS_LIST_REQUEST,
  MISSIONS_LIST_SAVED,
  MISSIONS_LIST_SAVE,
  MISSIONS_LIST_UPDATE,
  MISSIONS_LIST_DELETE,
  MISSIONS_LIST_CHARGING,
  MISSIONS_SAVE_CHARGING,
  MISSIONS_LIST_ERROR,
} = missionsTypes;

export const getMissionsRequest = (payload) => async (dispatch, getState) => {
  try {
    const { pages } = getState().missionsReducer;
    if (!pages.includes(payload)) {
      dispatch({
        type: MISSIONS_LIST_CHARGING,
      });
      const responseLogin = await MissionService.getMissions(payload);

      dispatch({
        type: MISSIONS_LIST_REQUEST,
        payload: { ...responseLogin.data, number: payload },
      });
    } else {
      dispatch({
        type: MISSIONS_LIST_SAVED,
      });
    }
  } catch (error) {
    dispatch({
      type: MISSIONS_LIST_ERROR,
      payload: error.response ? error.response.data : error,
    });
  }
};

export const saveMissionRequest = (payload) => async (dispatch, getState) => {
  dispatch({
    type: MISSIONS_SAVE_CHARGING,
  });

  try {
    const responseLogin = await MissionService.setMission(payload);
    const { missions } = getState().missionsReducer;

    const missionsUpdated = [
      { ...payload, ...responseLogin.data },
      ...missions,
    ];

    dispatch({
      type: MISSIONS_LIST_SAVE,
      payload: missionsUpdated,
    });
    return "SUCCESS";
  } catch (error) {
    dispatch({
      type: MISSIONS_LIST_ERROR,
      payload: error.response ? error.response.data : error,
    });
    return "ERROR";
  }
};

export const updateMissionRequest = (payload) => async (dispatch, getState) => {
  dispatch({
    type: MISSIONS_SAVE_CHARGING,
  });

  try {
    await MissionService.putMission(payload);
    const { missions } = getState().missionsReducer;

    const missionsUpdated = [...missions];
    const findById = (mission) => mission.id === payload.id;
    const index = missionsUpdated.findIndex(findById);
    missionsUpdated[index] = {
      ...missionsUpdated[index],
      ...payload,
    };

    dispatch({
      type: MISSIONS_LIST_UPDATE,
      payload: missionsUpdated,
    });
    return "SUCCESS";
  } catch (error) {
    dispatch({
      type: MISSIONS_LIST_ERROR,
      payload: error.response ? error.response.data : error,
    });
    return "ERROR";
  }
};

export const deleteMissionRequest = (payload) => async (dispatch) => {
  dispatch({
    type: MISSIONS_LIST_CHARGING,
  });

  try {
    await MissionService.deleteMission(payload);

    dispatch({
      type: MISSIONS_LIST_DELETE,
      payload: payload,
    });
    return "SUCCESS";
  } catch (error) {
    dispatch({
      type: MISSIONS_LIST_ERROR,
      payload: error.response ? error.response.data : error,
    });
    return { error: error.response };
  }
};

export const resetState = () => async (dispatch) => {
  dispatch({
    type: RESET_STATE,
  });
};
