export const RESET_STATE = "RESET_STATE";
export const RESET_STORE = "RESET_STORE";

export const METRICS_LIST_REQUEST = "METRICS_LIST_REQUEST";

export const METRICS_LIST_SAVED = "METRICS_LIST_SAVED";

export const METRICS_LIST_SAVE = "METRICS_LIST_SAVE";
export const METRICS_LIST_UPDATE = "METRICS_LIST_UPDATE";
export const METRICS_LIST_DELETE = "METRICS_LIST_DELETE";

export const METRICS_LIST_CHARGING = "METRICS_LIST_CHARGING";
export const METRICS_SAVE_CHARGING = "METRICS_SAVE_CHARGING";

export const METRICS_LIST_ERROR = "METRICS_LIST_ERROR";
