import React from "react";
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Typography from "@material-ui/core/Typography";
import Chip from "@material-ui/core/Chip";
import { SpinnerCircular } from "spinners-react";
import Grid from "@material-ui/core/Grid";
import environment from "../../../../../../libs/environment";
import { useTranslation } from "react-i18next";

const styles = (theme) => ({
	root: {
		margin: 0,
		padding: theme.spacing(2),
	},

	closeButton: {
		position: "absolute",
		right: theme.spacing(1),
		top: theme.spacing(1),
		color: theme.palette.grey[500],
	},

});

const DialogTitle = withStyles(styles)((props) => {

	const { children, classes, onClose, ...other } = props;

	return (
		<MuiDialogTitle disableTypography className={classes.root} {...other}>
			<Typography variant="h6">{children}</Typography>
			{onClose ? (
				<IconButton
					aria-label="close"
					className={classes.closeButton}
					onClick={onClose}
				>
					<CloseIcon />
				</IconButton>
			) : null}
		</MuiDialogTitle>
	);
});

const DialogContent = withStyles((theme) => ({
	root: {
		padding: theme.spacing(2),
	},
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
	root: {
		margin: 0,
		padding: theme.spacing(1),
	},
}))(MuiDialogActions);

export default function DialogComment({
	commentsList,
	isLoading,
	isOpen,
	handleClose
}) {

	const { t } = useTranslation();

	const displayUsername = ({ name, lastName, username }) => {
		const fullName = name ?
			lastName ?
				`${name} ${lastName}`
				: `${name} `
			: '';

		const displayName = fullName ?
			`${fullName} - ${username}`
			: `${username}`;

		return displayName;
	}

	return (
		<div>

			<Dialog
				maxWidth="sm"
				fullWidth={true}
				onClose={handleClose}
				aria-labelledby="customized-dialog-title"
				open={isOpen}
			>

				<DialogTitle id="customized-dialog-title" onClose={handleClose}>
					{t("menu.badge-panel-dialog-delivery-comments", "Comments")}
				</DialogTitle>

				<DialogContent dividers>
					<Grid container spacing={1}>
						<Grid item xs={12} md={12} lg={12}>
							{
								isLoading &&
								<div className="div-spinner">
									<Grid container spacing={1}>
										<SpinnerCircular />
									</Grid>
								</div>
							}
							{commentsList && commentsList.length > 0 ? (
								<>
									{commentsList.map((comment, index) => (
										<div className="w-100 d-flex-user-comment mb-2" key={index}>
											<div>
												<img
													src={
														comment.createdByData && comment.createdByData.image &&
														`${environment.motivarnosBackend}${comment.createdByData.image}`
													}
													alt={`user ${comment.createdByData.username}`}
													className="img-user-comment"
												/>
												{<div style={{textAlign: 'center'}}>
													{comment.createdByData ? comment.createdByData.name : "Sin nombre"}
													{/*comment.createdByData
																		? displayUsername(comment.createdByData)
												: "Sin nombre"*/}
													</div>}
											</div>

											<div className="ml-1">
												<h5 className="mb-0 mt-0">
													<Chip
														style={{height: 'fit-content'}}
														className="label-username"
														label={
															<span className="label-username">
																{/*comment.createdByData
																	? displayUsername(comment.createdByData)
														: "Sin nombre"*/}
																	{comment.comment}
															</span>
														}
														clickable
														color="primary"
													/>
												</h5>
												{/*<p>{comment.comment}</p>*/}
											</div>
										</div>
									))}
								</>
							) : (
								<h3 className="text-center">Sin comentarios</h3>
							)}
						</Grid>
					</Grid>

				</DialogContent>

				<DialogActions>

					<Button onClick={handleClose} color="secondary">
						{t("menu.trivia-panel-dialog-add-test-button-close", "Close")}
					</Button>

				</DialogActions>
			</Dialog>
		</div>
	);
}
