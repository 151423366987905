import { useEffect, useState } from "react";

const useMusic = (song, playSong = false) => {

    const [audio, setAudio] = useState(new Audio(song));

    const [isPlaying, setIsPlaying] = useState(playSong);

    const pause = () => audio.pause();

    const play = () => audio.play();

    const togglePlayMusic = () => {
        // Get state of song
        const isCurrentPlaying = isPlaying;
        console.log('toggle')
        // Pause the song if it is playing
        // Play the song if it is paused
        if (isCurrentPlaying) pause()
        else play()

        // Change the state of song
        setIsPlaying(prevState => !prevState)
    }

    // Config event 
    useEffect(() => {
        audio.addEventListener('ended', function() {
            play();
        });

        return () => {
            audio.removeEventListener('ended', function() {
                pause();
                setIsPlaying(false)
            });
        };
    }, []);

    return [isPlaying, togglePlayMusic, setAudio];
}

export default useMusic; 