import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import toastr from "toastr";
import Grid from "@material-ui/core/Grid";

import Avatar from "../../../../Avatar";
import Button from "../../../../Button";
import { SpinnerCircular } from "spinners-react";

import RankingServices from "../../../../../Services/Ranking/Player";
import RankingService from "../../Services/RankingService";

import { Box } from "@material-ui/core";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableContainer from "@material-ui/core/TableContainer";
import { NotRegistersFound } from "../../common/NotRegistersFound";
import Paper from "@material-ui/core/Paper";
import CloudDownloadIcon from "@material-ui/icons/CloudDownload";
import TextField from "@material-ui/core/TextField";

import { format } from "date-fns";

import { useTranslation } from "react-i18next";
import "./styles.scss";

const useStyles = makeStyles((theme) => ({
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    overflow: "auto",
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
  },
  fixedHeight: {
    height: 240,
  },
  root: {
    "& > *": {
      marginTop: theme.spacing(2),
    },
  },
}));

const UserRanking = (props) => {
  const [points, setPoints] = useState(0);
  const [coins, setCoins] = useState(0);
  const [updating, setUpdating] = useState(false);
  const { t } = useTranslation();

  const handlePoints = (event) => {
    let value = event.target.value;
    setPoints(value);
  };

  const handleCoins = (event) => {
    let value = event.target.value;
    setCoins(value);
  };

  /*const handleSumary = (event) => {
    let value = event.target.value;
    setSumary(value);
  };*/

  const handleUpdate = () => {
    props.onUpdate(true);
    setUpdating(true);
    RankingService.UpdateRanking({
      id: props.id,
      //sumary: sumary,
      amountCoin: coins,
      amountPoint: points,
      userId: props.userId,
    })
      .then(() => {
        setUpdating(false);
        props.onUpdated();
        props.onUpdate(false);
        //setSumary("");
      })
      .catch(() => {
        setUpdating(false);
        props.onUpdate(false);
        props.onError();
      });
  };

  useEffect(() => {
    setPoints(props.points);
    setCoins(props.coins);
  }, []);

  return (
    <>
      {updating ? (
        <TableRow>
          <span className="spinner-container-custom">
            <SpinnerCircular />
          </span>
        </TableRow>
      ) : (
        <TableRow>
       

          <TableCell align="left">
  <Box display="flex" alignItems="center">
    <Avatar size={"2em"} style={{ borderWidth: "2px", marginRight: "8px" }} />
    <Box>
      {props.name} 
    </Box>
  </Box>
</TableCell>

          <TableCell align="left">
            <span className={"points " + (props.editable ? "out" : "in")}>
              {props.coins}
            </span>
            <span className={"points " + (props.editable ? "in" : "out")}>
              <input disabled={updating} value={coins} onChange={handleCoins} />
            </span>
          </TableCell>

          <TableCell align="left">
            <span className={"points " + (props.editable ? "out" : "in")}>
              {props.points}
            </span>
            <span className={"points " + (props.editable ? "in" : "out")}>
              <input
                disabled={updating}
                value={points}
                onChange={handlePoints}
              />
            </span>
          </TableCell>

          <TableCell
            align="left"
            className="user-ranking-content d-flex-custom"
          >
            {!props.editable ? (
              <Button
                className={!props.editable ? "in" : "out"}
                onClick={() => {
                  props.onEdit(props.userId);
                }}
                disabled={props.disabled}
              >
                {t("admin.actions-edit", "Edit")}
              </Button>
            ) : (
              ""
            )}
            {props.editable ? (
              <Button
                disabled={updating}
                onClick={handleUpdate}
                className={props.editable ? "in" : "out"}
                style={{ marginRight: "1em" }}
              >
                {t("admin.header-dropdown-dialog-actions-save", "Save")}
              </Button>
            ) : (
              ""
            )}
            {props.editable ? (
              <Button
                disabled={updating}
                secondary
                onClick={() => {
                  props.onEdit(null);
                }}
                className={props.editable ? "in" : "out"}
              >
                {t("admin.header-dropdown-dialog-actions-cancel", "Cancel")}
              </Button>
            ) : (
              ""
            )}
          </TableCell>
        </TableRow>
      )}
    </>
  );
};
UserRanking.defaultProps = {
  points: 0,
  coins: 0,
  name: "",
};

export default function Ranking() {
  const [metricsRankings, setMetricRankings] = useState(null);
  const [badgeRankings, setBadgeRanking] = useState(null);
  const [mainRanking, setMainRankings] = useState(null);
  const [sesion, setSesion] = useState(null);
  const [editable, setEditable] = useState(null);
  const [disabled, setDisabled] = useState(false);
  const [error, setError] = useState(false);
  const [historicalRankings, setHistoricalRanking] = useState(null);
  const [dateTo, setDateTo] = useState(calculate_next_day());
  const [dateFrom, setDateFrom] = useState(format(new Date(), "yyyy-MM-dd"));
  const [downloadingPointsRanking, setDownloadingPointsRanking] = useState(false);
  const [downloadingInsigniasRanking, setDownloadingInsigniasRanking] = useState(false);
  const [leadersRanking, setLeadersRanking] = useState(null);
  const [agentsRanking, setAgentsRanking] = useState(null);
  const [othersRanking, setOthersRanking] = useState(null);

  const { t } = useTranslation();
  var timer;


  const getRankings = () => {
    var fechaInicial = new Date(new Date().getFullYear(), 0, 1);
    var fechaFinal = new Date(new Date().getFullYear() + 1, 0, 1);
  
    var año_inicial = fechaInicial.getFullYear();
    var año_final = fechaFinal.getFullYear();
    var mes = ('0' + (fechaInicial.getMonth() + 1)).slice(-2);
    var dia = ('0' + fechaInicial.getDate()).slice(-2);
    var fechaFormateadaInicial = año_inicial + '-' + mes + '-' + dia// + 'T00:00:00';
    var fechaFormateadaFinal = año_final + '-' + mes + '-' + dia// + 'T00:00:00';

    RankingService.GetMainRanking()
      .then((response) => {
        setMainRankings(response.data.content);
      })
      .catch(() => {
        setMainRankings(null);
        toastr.error(
          t(
            "admin.ranking-message-error-get-ranking",
            "An error occurred obtaining the ranking"
          )
        );
      });

      RankingService.GetSecondaryRanking('lideres', dateFrom, dateTo)
      .then((response) => {
        setLeadersRanking(response.data.content);
      })
      .catch(() => {
        setLeadersRanking(null);
        toastr.error(
          t(
            "admin.ranking-message-error-get-ranking",
            "An error occurred obtaining the ranking"
          )
        );
      });

    RankingService.GetSecondaryRanking('agentes', dateFrom, dateTo)
      .then((response) => {
        setAgentsRanking(response.data.content);
      })
      .catch(() => {
        setAgentsRanking(null);
        toastr.error(
          t(
            "admin.ranking-message-error-get-ranking",
            "An error occurred obtaining the ranking"
          )
        );
      });

    RankingService.GetSecondaryRanking('otrosroles', dateFrom, dateTo)
      .then((response) => {
        setOthersRanking(response.data.content);
      })
      .catch(() => {
        setOthersRanking(null);
        toastr.error(
          t(
            "admin.ranking-message-error-get-ranking",
            "An error occurred obtaining the ranking"
          )
        );
      });

    RankingService.getMetricRanking()
      .then((response) => {
        setMetricRankings(response.data);
      })
      .catch(() => {
        toastr.error(
          t(
            "admin.ranking-message-error-get-ranking",
            "An error occurred obtaining the ranking"
          )
        );
      });

    RankingServices.GetHistoricalRanking(fechaFormateadaInicial, fechaFormateadaFinal)
      .then((response) => {
        setHistoricalRanking(response.data.content);
      })
      .catch((error) => {
        console.log(error);
      });

    /*RankingServices.GetBadgeRanking()
      .then((response) => {
        setBadgeRanking(response.data.content);
      })
      .catch((error) => {
        setBadgeRanking([]);
      });*/
  };

  const getSecondaryRankings = (fromDate = '', toDate = '') => {
    setLeadersRanking(null);
    setAgentsRanking(null);
    setOthersRanking(null);

      RankingService.GetSecondaryRanking('lideres', fromDate, toDate)
        .then((response) => {
          setLeadersRanking(response.data.content);
        })
        .catch(() => {
          setLeadersRanking(null);
          toastr.error(
            t(
              "admin.ranking-message-error-get-ranking",
              "An error occurred obtaining the ranking"
            )
          );
        });
  
      RankingService.GetSecondaryRanking('agentes', fromDate, toDate)
        .then((response) => {
          setAgentsRanking(response.data.content);
        })
        .catch(() => {
          setAgentsRanking(null);
          toastr.error(
            t(
              "admin.ranking-message-error-get-ranking",
              "An error occurred obtaining the ranking"
            )
          );
        });
  
      RankingService.GetSecondaryRanking('otrosroles', fromDate, toDate)
        .then((response) => {
          setOthersRanking(response.data.content);
        })
        .catch(() => {
          setOthersRanking(null);
          toastr.error(
            t(
              "admin.ranking-message-error-get-ranking",
              "An error occurred obtaining the ranking"
            )
          );
        });
      
  }

  const handleEdit = (value) => {
    setEditable(value);
  };

  const handleDisabled = (value) => {
    setDisabled(value);
  };

  const handleUpdate = () => {
    setMainRankings([]);
    getRankings();
    setEditable(null);
  };

  const handleError = () => {
    setError(!error);
    toastr.error(
      t("admin.ranking-message-error-update", "An error occurred updating")
    );
    timer = setTimeout(() => {
      setError(() => {
        return false;
      });
    }, 5000);
  };

  const downloadPointsRanking = () => {
    setDownloadingPointsRanking(true);
    RankingService.downloadPointsRanking(dateFrom, dateTo)
      .then((response) => {
        var blob = response.data;
        var link = document.createElement("a");
        link.href = window.URL.createObjectURL(new Blob([blob]));
        link.download = "PointsRanking.csv";
        link.click();
        setDownloadingPointsRanking(false);
      })
      .catch(() => {
        toastr.error(
          this.props.t(
            "menu.points-coins-message-error-download-transactions",
            "An error occurred while trying to download transactions"
          )
        );
        setDownloadingPointsRanking(false);
      });
  }

  const downloadInsigniasRanking = () => {
    setDownloadingInsigniasRanking(true);
    RankingService.downloadInsigniasRanking(dateFrom, dateTo)
      .then((response) => {
        var blob = response.data;
        var link = document.createElement("a");
        link.href = window.URL.createObjectURL(new Blob([blob]));
        link.download = "InsigniasRanking.csv";
        link.click();
        setDownloadingInsigniasRanking(false);
      })
      .catch(() => {
        toastr.error(
          this.props.t(
            "menu.points-coins-message-error-download-transactions",
            "An error occurred while trying to download transactions"
          )
        );
        setDownloadingInsigniasRanking(false);
      });
  }

  function handleChangeDate({ target: { name, value } }) {
    if(name == "dateTo") {
      setDateTo(value);
      getSecondaryRankings(dateFrom, value);
    } 
    if(name == "dateFrom") {
      setDateFrom(value);
      getSecondaryRankings(value, dateTo);
    }
  }

  function calculate_next_day() {
    const today = new Date();
    let next_date = new Date(); 
    next_date.setDate(today.getDate() + 1);
    next_date = format(next_date, "yyyy-MM-dd");

    return next_date;
  }

  useEffect(() => {
    setSesion(JSON.parse(localStorage.getItem("sesion")));
    getRankings();
    return () => {
      window.clearInterval(timer);
    };
  }, []);

  const classes = useStyles();

  return (
    <main className={classes.content}>
      <div className={classes.appBarSpacer} />
      <Container maxWidth="lg" className={classes.container}>
        <Grid item xs={12} container>
          <Grid item xs={12} md={6} lg={3} style={{display: 'flex', minWidth: '300px'}}>
            <TextField
              onChange={handleChangeDate}
              value={dateFrom}
              type="date"
              name="dateFrom"
              id="dateFrom"
              label={t(
                "menu.trivia-panel-dialog-add-test-input-dateFrom",
                "Start date"
              )}
              variant="outlined"
              className="ml-1 mr-1"
              fullWidth
            />
          </Grid>

          <Grid item xs={12} md={6} lg={3} style={{display: 'flex', minWidth: '300px'}}>
            <TextField
              onChange={handleChangeDate}
              value={dateTo}
              type="date"
              name="dateTo"
              id="dateTo"
              label={t(
                "menu.trivia-panel-dialog-add-test-input-dateTo",
                "Ending date"
              )}
              variant="outlined"
              className="ml-1 mr-1"
              fullWidth
            />
          </Grid>
          <Grid item xs={12} md={6} lg={3} style={{minWidth: '300px'}}>
            {downloadingPointsRanking ? (
              <span className="spinner-container">
                <SpinnerCircular />
              </span>
            ) : (
            <Button
              className="button-table ml-1 mr-1"
              variant="contained"
              color="primary"
              onClick={() => downloadPointsRanking()}
            >
              <CloudDownloadIcon className="mr-1" />
              Descargar CSV Ranking de puntos
            </Button>
            )}
          </Grid>

          {/*<Grid item xs={12} md={6} lg={3} style={{minWidth: '300px'}}>
            {downloadingInsigniasRanking ? (
              <span className="spinner-container">
                <SpinnerCircular />
              </span>
            ) : (
            <Button
              className="button-table ml-1 mr-1"
              variant="contained"
              color="primary"
              onClick={() => downloadInsigniasRanking()}
            >
              <CloudDownloadIcon className="mr-1" />
              Descargar CSV Ranking de insignias
            </Button>
            )}
            </Grid>*/}
        </Grid>

        <div>
          {historicalRankings === null ? (
            <span className="spinner-container">
              <SpinnerCircular />
            </span>
          ) : (
            <>
              {historicalRankings.length !== 0 && (
                <span className="ranking-box">
                  <h3>{t("anual-ranking.label","Anual ranking")}</h3>
                  <TableContainer component={Paper} className="custom-height">
                    <Table aria-label="simple table">
                      <TableHead>
                        <TableRow>
                          <TableCell align="left">
                            {t("ranking.label", "Ranking")}
                          </TableCell>
                          <TableCell align="left">
                            {t("user.label", "User")}
                          </TableCell>
                          <TableCell align="left">
                            Equipo Secundario
                          </TableCell>
                          <TableCell align="left">
                            {t("points.label", "Points")}
                          </TableCell>
                        </TableRow>
                      </TableHead>

                      <TableBody>
                        {historicalRankings.map((ranking, index) => (
                          <TableRow key={index}>
                            <TableCell align="left">
                              {ranking.position && (
                                <span className="span-custom-position">
                                  {ranking.position}
                                </span>
                              )}
                            </TableCell>

                            <TableCell align="left">
                              <Box display="flex" alignItems="center">
                                <Avatar size={"2em"} style={{ borderWidth: "2px", marginRight: "8px" }} />
                                <Box>
                                  {ranking.user.name} {ranking.user.lastName}
                                </Box>
                              </Box>
                            </TableCell>
                            <TableCell align="left">
                              {ranking.user.secondaryTeamName}
                            </TableCell>
                            <TableCell align="left">
                              {ranking.pointBalance}
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </span>
              )}
            </>
          )}
        </div>

        <Grid container spacing={2}>
          <Grid item xs={12} md={12} lg={12}>
            <div>
              {leadersRanking === null ? (
                <span className="spinner-container">
                  <SpinnerCircular />
                </span>
              ) : (
                <>
                  <span className="ranking-box">
                    <h3>Lideres</h3>
                    <TableContainer component={Paper} className="custom-height">
                      <Table aria-label="simple table">
                        <TableHead>
                          <TableRow>
                            <TableCell align="left">
                              {t("ranking.label", "Ranking")}
                            </TableCell>
                            <TableCell align="left">
                              {t("user.label", "User")}
                            </TableCell>
                            <TableCell align="left">
                              Email
                            </TableCell>
                            <TableCell align="left">
                              Equipo
                            </TableCell>
                            <TableCell align="left">
                            Equipo Secundario
                            </TableCell>
                            <TableCell align="left">
                              {t("points.label", "Points")}
                            </TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {leadersRanking.map((ranking, index) => (
                            <TableRow key={index}>
                              {console.log(ranking)}
                              <TableCell align="left">
                                {ranking.position && (
                                  <span className="span-custom-position">
                                    {ranking.position}
                                  </span>
                                )}
                              </TableCell>
                              <TableCell align="left">
  <Box display="flex" alignItems="center">
    <Avatar size={"2em"} style={{ borderWidth: "2px", marginRight: "8px" }} />
    <Box>
      {ranking.user.name} {ranking.user.lastName}
    </Box>
  </Box>
</TableCell>

                              <TableCell align="left">
                                {ranking.user.email}
                              </TableCell>
                              <TableCell align="left">
                                {ranking.user.teamName}
                              </TableCell>
                              <TableCell align="left">
                                {ranking.user.secondaryTeamName}
                              </TableCell>
                              <TableCell align="left">
                                {ranking.pointBalance}
                              </TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </span>
                </>
              )}
            </div>
          </Grid>

          <Grid item xs={12} md={12} lg={12}>
            <div>
              {agentsRanking === null ? (
                <span className="spinner-container">
                  <SpinnerCircular />
                </span>
              ) : (
                <>
                <span className="ranking-box">
    <h3>Agentes</h3>
    <TableContainer component={Paper} className="custom-height">
      <Table aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell align="left">{t("ranking.label", "Ranking")}</TableCell>
            <TableCell align="left">{t("user.label", "User")}</TableCell>
            <TableCell align="left">Email</TableCell>
            <TableCell align="left">Equipo</TableCell>
            <TableCell align="left">Equipo Secundario</TableCell>
            <TableCell align="left">{t("points.label", "Points")}</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {agentsRanking.map((ranking, index) => (
            <TableRow key={index}>
              <TableCell align="left">
                {ranking.position && (
                  <span className="span-custom-position">
                    {ranking.position}
                  </span>
                )}
              </TableCell>
              <TableCell align="left">
  <Box display="flex" alignItems="center">
    <Avatar size={"2em"} style={{ borderWidth: "2px", marginRight: "8px" }} />
    <Box>
      {ranking.user.name} {ranking.user.lastName}
    </Box>
  </Box>
</TableCell>

              <TableCell align="left">{ranking.user.email}</TableCell>
              <TableCell align="left">{ranking.user.teamName}</TableCell>
              <TableCell align="left">{ranking.user.secondaryTeamName}</TableCell>
              <TableCell align="left">{ranking.pointBalance}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  </span>
                </>
              )}
            </div>
          </Grid>

          <Grid item xs={12} md={12} lg={12}>
            <div>
              {othersRanking === null ? (
                <span className="spinner-container">
                  <SpinnerCircular />
                </span>
              ) : (
                <>
                  <span className="ranking-box">
                    <h3>Otros</h3>
                    <TableContainer component={Paper} className="custom-height">
                      <Table >
                        <TableHead>
                          <TableRow>
                            <TableCell align="left">
                              {t("ranking.label", "Ranking")}
                            </TableCell>
                            <TableCell align="left">
                              {t("user.label", "User")}
                            </TableCell>
                            <TableCell align="left">
                              Email
                            </TableCell>
                            <TableCell align="left">
                              Equipo
                            </TableCell>
                            <TableCell align="left">
                              Equipo Secundario
                            </TableCell>
                            <TableCell align="left">
                              {t("points.label", "Points")}
                            </TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {othersRanking.map((ranking, index) => (
                            <TableRow key={index}>
                              <TableCell align="left">
                                {ranking.position && (
                                  <span className="span-custom-position">
                                    {ranking.position}
                                  </span>
                                )}
                              </TableCell>
                              <TableCell align="left">
  <Box display="flex" alignItems="center">
    <Avatar size={"2em"} style={{ borderWidth: "2px", marginRight: "8px" }} />
    <Box>
      {ranking.user.name} {ranking.user.lastName}
    </Box>
  </Box>
</TableCell>

                              <TableCell align="left">
                                {ranking.user.email}
                              </TableCell>
                              <TableCell align="left">
                                {ranking.user.teamName}
                              </TableCell>
                              <TableCell align="left">
                                {ranking.user.secondaryTeamName}
                              </TableCell>
                              <TableCell align="left">
                                {ranking.pointBalance}
                              </TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </span>
                </>
              )}
            </div>
          </Grid>
        </Grid>

        {mainRanking === null ? (
          <span className="spinner-container">
            <SpinnerCircular />
          </span>
        ) : (
          <>
            {mainRanking.length !== 0 && (
              <React.Fragment>
                <h3>{t("ranking.label", "Ranking")}</h3>

                <TableContainer
                  component={Paper}
                  className="ranking-list-content ranking-list-content-custom-height"
                >
                  <Table aria-label="simple table">
                    <TableHead>
                      <TableRow>
                        <TableCell align="left">
                          {t("menu.rigth-list-item-users", "Users")}
                        </TableCell>
                        <TableCell align="left">
                          {t("coins.label", "Coins")}
                        </TableCell>
                        <TableCell align="left">
                          {t("points.label", "Points")}
                        </TableCell>

                        <TableCell align="left">
                          {t("admin.user-panel-table-actions", "Actions")}
                        </TableCell>
                      </TableRow>
                    </TableHead>

                    <TableBody>
                      {mainRanking.map((data, index) => {
                        return (
                          <UserRanking
                            name={data.user.name}
                            points={data.pointBalance}
                            coins={data.coinBalance}
                            id={sesion.user.id}
                            userId={data.user.id}
                            key={data.user.id}
                            onEdit={handleEdit}
                            editable={data.user.id === editable}
                            onUpdate={handleDisabled}
                            onUpdated={handleUpdate}
                            disabled={disabled}
                            onError={handleError}
                            index
                          />
                        );
                      })}
                    </TableBody>
                  </Table>
                </TableContainer>
              </React.Fragment>
            )}
          </>
        )}

        {/*<div>
          {badgeRankings === null ? (
            <span className="spinner-container">
              <SpinnerCircular />
            </span>
          ) : (
            <>
              {badgeRankings.length !== 0 && (
                <span className="ranking-box">
                  <h3>{t("menu.rigth-list-item-badges", "Badges")}</h3>

                  <TableContainer component={Paper} className="custom-height">
                    <Table aria-label="simple table">
                      <TableHead>
                        <TableRow>
                          <TableCell align="left">
                            {t("ranking.label", "Ranking")}
                          </TableCell>
                          <TableCell align="left">
                            {t("user.label", "User")}
                          </TableCell>
                          <TableCell align="left">
                            {t("menu.rigth-list-item-badges", "Badges")}
                          </TableCell>
                        </TableRow>
                      </TableHead>

                      <TableBody>
                        {badgeRankings.map((ranking, index) => (
                          <TableRow key={index}>
                            <TableCell align="left">
                              {ranking.position && (
                                <span className="span-custom-position">
                                  {ranking.position}
                                </span>
                              )}
                            </TableCell>

                            <TableCell align="left" className="d-flex-custom">
                              <Avatar
                                size={"2em"}
                                style={{ borderWidth: "2px" }}
                              />{" "}
                              {ranking.user.name} {ranking.user.lastName}
                            </TableCell>

                            <TableCell align="left">
                              {ranking.insigniaCount
                                ? ranking.insigniaCount
                                : "N/A"}
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </span>
              )}
            </>
          )}
        </div>*/}

        <div>
          {metricsRankings === null ? (
            <span className="spinner-container">
              <SpinnerCircular />
            </span>
          ) : (
            <>
              {metricsRankings.length !== 0 && (
                metricsRankings.map((data, index) => (
                  <span className="ranking-box" key={index}>
                    <h3>{data.metricConf.name}</h3>

                    <TableContainer component={Paper} className="custom-height">
                      <Table aria-label="simple table">
                        <TableHead>
                          <TableRow>
                            <TableCell align="left">
                              {t("user.label", "User")}
                            </TableCell>
                            <TableCell align="left">
                              {t("points.label", "Points")}
                            </TableCell>
                          </TableRow>
                        </TableHead>

                        <TableBody>
                          {data.users.map((user, index) => (
                            <TableRow key={index}>
                              <TableCell align="left">
  <Box display="flex" alignItems="center">
    <Avatar size={"2em"} style={{ borderWidth: "2px", marginRight: "8px" }} />
    <Box>
      {user.user.name} {user.user.lastName}
    </Box>
  </Box>
</TableCell>

                              <TableCell align="left">{user.points}</TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </span>
                ))
              )}
            </>
          )}
        </div>
      </Container>
    </main>
  );
}
