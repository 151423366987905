import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { es } from "date-fns/locale";

import Button from "../../components/Button";
import Avatar from "../../components/Avatar";
import JSConfetti from 'js-confetti';
import money from "../../assets/img/money.svg";
import tunder from "../../assets/img/tunder.svg";
import EmptyMessage from "../../components/EmptyMessage";
import toastr from "toastr";
import { SpinnerCircular } from "spinners-react";

import ChallengeServices from "../../Services/Challenge/Player";
import GeneralFunctions from "components/app/admin/libs/GeneralFunctions";

import "./styles.scss";

const ChallengeCart = (props) => {
  const jsConfetti = new JSConfetti();
  const [fetching, setFetching] = useState(false);
  const [sesion, setSesion] = useState(null);
  const { t, i18n } = useTranslation();

  const handleReject = () => {
    let data = { id: props.data.id };
    setFetching(true);

    ChallengeServices.RejectChallenges(data)
      .then((resp) => {
        setFetching(false);
        if (props.onUpdate) props.onUpdate();
      })
      .catch((error) => {
        setFetching(false);
        toastr.error("Ha ocurrido un error.");
      });
  };

  const handleAccept = () => {
    let data = { id: props.data.id };
    setFetching(true);

    ChallengeServices.AcceptChallenges(data)
      .then(() => {
        jsConfetti.addConfetti({
          emojis: ['😀', '👍'],
          emojiSize: 40,
          confettiNumber: 40,
        })
        setFetching(false);
        if (props.onUpdate) props.onUpdate();
      })
      .catch(() => {
        setFetching(false);
        toastr.error("Ha ocurrido un error.");
      });
  };

  useEffect(() => {
    let userSesion = JSON.parse(localStorage.getItem("sesion"));
    setSesion(userSesion);
  }, []);

  if (sesion !== null)
 
    return (
      <div className="challenge-cart-wrapper">
  <h3 style={{ textTransform: "capitalize" }}>
    {props.data.challenge.name}
  </h3>
  <div className="challenge-cart-content">
    <div className="vs-container">
      <div className="avatar-wrapper">
        <Avatar size="10em" />
        <div className="username-wrapper">
          <h3 className="username">{props.data.challenger.username}</h3>
        </div>
      </div>
      <div className="tunder">
        <img src={tunder} alt="" />
      </div>
      <div className="avatar-wrapper">
        <Avatar size="10em" avatar={"/api" + sesion.user.image} />
        <div className="username-wrapper">
          <h3 className="username">{sesion.user.username}</h3>
        </div>
      </div>
    </div>
    <div className="bet-info">
      <span>
        <div className="points-container border-bottom border-right">
          <span className="points">{props.data.metricConf.name}</span>
          <span>{t("metrics.label")}</span>
        </div>
        <div className="points-container border-right">
          <span className="points">
            {props.data.conditionType === "LESS_THAN"
              ? t("me-below.label")
              : t("me-above.label")}
          </span>
          <span>{t("place-in-ranking.label")}</span>
        </div>
      </span>
      <span>
        <div className="points-container border-bottom">
          <span className="points">
            {GeneralFunctions.formatNewDate(props.data.dateTo, es)}
          </span>
          <span>{t("expiration-date.label")}</span>
        </div>
        <div className="points-container">
          <span className="points">{props.data.points}</span>
          <span>Puntos en Juego</span>
        </div>
      </span>
    </div>
  </div>
  <div className="button-container">
    <Button secondary disabled={fetching} onClick={handleReject}>
      {t("reject.label")}
    </Button>
    <Button
      style={{ marginRight: "1em" }}
      disabled={fetching}
      onClick={handleAccept}
    >
      {t("accept.label")}
    </Button>
  </div>
</div>

    );
  else return "";
};

const Challenges = () => {
  const { t, i18n } = useTranslation();

  const [sesion, setSesion] = useState(null);
  const [challenges, setChallenges] = useState(null);

  const getData = () => {
    setChallenges(null);
    ChallengeServices.getChallenges()
      .then((resp) => {
        console.log(resp.data.content);
        setChallenges(resp.data.content);
      })
      .catch(() => {
        toastr.error("Ha ocurrido un error.");
      });
  };

  const handleUpdate = () => {
    getData();
  };

  useEffect(() => {
    let userSesion = JSON.parse(localStorage.getItem("sesion"));
    setSesion(userSesion);
    getData();
  }, []);

  if (sesion !== null)
    return (
      <div className="challenges-wrapper">
       {/*  <div className="sum-points">
          <img src={money} alt="" /> {sesion.user.coinBalance}
        </div> */}
        <div className="challenges-title">{t("challenges.label.title")}</div>
        <div className="challenges-container">
          {challenges === null ? (
            <span className="spinner-container">
              <SpinnerCircular />
            </span>
          ) : (
            challenges.map((data, index) =>
              data.status === "PENDING" ? (
                <ChallengeCart
                  data={data}
                  key={index}
                  onUpdate={handleUpdate}
                />
              ) : null
            )
          )}
          {challenges !== null && challenges.length === 0 ? (
            <EmptyMessage />
          ) : (
            ""
          )}
        </div>
        <div className="challenges-title-report">{t("challenge-history.label")}</div>
        {challenges === null ? (
          <span className="spinner-container">
            <SpinnerCircular />
          </span>
        ) : challenges.length === 0 ? (
          <EmptyMessage />
        ) : (
          <div className="pending-bets">
            <table className="pending-bets-table">
              <thead>
                <tr>
                <th>Nombre del desafio</th>
                  <th>Desafiador</th>
                  <th>Oponente</th>
                  <th>{t("metrics.label")}</th>
                  
                  <th>Lugar en el ranking</th>
                  <th>Monedas</th>
                  <th>Fecha</th>
                  <th>Estado</th>
                  <th></th>
                </tr>
              </thead>
              {challenges.map((data, index) => {
                return data.status !== "PENDING" ? (
                  <tr className="table-content">
                  <td>{data.challenge.name}</td>
                    <td>
                      <span className="gambler">
                        <span className="icon">
                          <Avatar size={"2em"} style={{ borderWidth: "2px" }} />
                        </span>
                        {data.challenger.username}
                      </span>
                    </td>
                    <td>
                      <span className="opponent">
                        <span className="icon">
                          <Avatar size={"2em"} style={{ borderWidth: "2px" }} />
                        </span>
                        {data.user.username}
                      </span>
                    </td>
                    <td>{data.metricConf.name}</td>
                    
                    <td>
                      {data.conditionType === "LESS_THAN" ? "Menor" : "Mayor"}
                    </td>
                    <td>{data.points}</td>
                    <td>
                      {GeneralFunctions.formatNewDate(
                        data.dateTo,
                        es
                      )}
                    </td>
                    <td>
                      <span className="status pending">{data.status === "REJECTED"
                ? t("rejected.label", "RECHAZADO").toUpperCase()
                :data.status === "PENDING"
                ? t( "menu.trivia-panel-dialog-add-test-select-pending-challenge", "Pending").toUpperCase()
                :data.status === "ACHIEVED"
                ? t( "menu.trivia-panel-dialog-add-test-select-achieved-challenge", "Logrado",).toUpperCase()
                :data.status === "UNACHIEVED"
                ? t("menu.trivia-panel-dialog-add-test-select-unaachieved-challenge", "No logrado").toUpperCase()
                :data.status === "IN_PROGRESS"
                ? t("menu.trivia-panel-dialog-add-test-select-in-progress-challenge", "En_progreso").toUpperCase()
                :data.status === "CANCELLED"
                ? t( "menu.trivia-panel-dialog-add-test-select-cancel-challenge", "Cancelled").toUpperCase()
                : data.status}</span>
                    </td>
                    <td>
                      <span className="bet-crud"></span>
                    </td>
                  </tr>
                ) : null;
              })}
            </table>
          </div>
        )}
      </div>
    );
  else return null;
};

export default Challenges;

