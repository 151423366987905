import axios from "axios";
import environment from "../../../../libs/environment";
import { config } from "react-transition-group";

export default {
  getAccount: (number = 1, size = 5) =>
    axios({
      method: "GET",
      url: `${environment.motivarnosBackend}/account?_number=${number - 1
        }&_size=${size}`,
    }),

  filterAccount: (number = 1, size = 5, filter) =>
    axios({
      method: "GET",
      url: `${environment.motivarnosBackend}/account?_number=${number - 1
        }&_size=${size}&user.username_like=%25${filter}`,
    }),

  getAccountTransaction: (number = 1, size = 5) =>
  axios.get('/accounttransaction',  {params:{_number: number - 1, _size: size, status: 'COMPLETED'}}),

  downloadAccountTransaction: () =>
    axios({
      method: "GET",
      url: `${environment.motivarnosBackend}/accounttransaction?csv=true`,
    }),

    filterAccountTransaction: (number = 1, size = 5, filter) =>
      axios.get('/accounttransaction',  {
          params: {
              _number: number - 1,
              _size: size,
              "user.username_like": `%${filter}%`, // Aquí se ajusta el filtro para incluir cualquier parte del nombre de usuario
              status: 'COMPLETED'
          }
      }),
  

  getFeelings: (number = 1, size = 5) =>
    axios({
      method: "GET",
      url: `${environment.motivarnosBackend}/feelingshistoric?_number=${number - 1
        }&_size=${size}`,
    }),

  downloadFeelings: () =>
    axios({
      method: "GET",
      url: `${environment.motivarnosBackend}/feelingshistoric?csv=true`,
    }),

  filterFeelings: (number = 1, size = 5, filter) =>
    axios({
      method: "GET",
      url: `${environment.motivarnosBackend}/feelingshistoric?_number=${number - 1
        }&_size=${size}&user.username_like=%25${filter}`,
    }),

  getMissionVote: (number = 1, size = 5) =>
    axios({
      method: "GET",
      url: `${environment.motivarnosBackend}/missionuservote?_number=${number - 1
        }&_size=${size}`,
    }),

  getMissionVoteAll: () =>
    axios({
      method: "GET",
      url: `${environment.motivarnosBackend}/mission`,
    }),

  downloadMissionSelected: (id = 0) =>
    axios({
      method: "GET",
      url: `${environment.motivarnosBackend}/missionuservote?missionUser.mission.id=${id}&csv=true`,
    }),

  downloadMissionsVote: () =>
    axios({
      method: "GET",
      url: `${environment.motivarnosBackend}/missionuservote?csv=true`,
    }),

  downloadAccount: () =>
    axios({
      method: "GET",
      url: `${environment.motivarnosBackend}/account?csv=true`,
    }),

  downloadTransactionsReport: (users_in, dateFrom, dateTo) =>
    axios({
      method: "GET",
      url: `${environment.motivarnosBackend}/report/execute/MotivarnosAccountTransactions?csv=true&users_in=${users_in}${
        dateFrom != null ? `&created_ge=${dateFrom}` : ``}${
        dateTo != null ? `&created_le=${dateTo}` : ``}`,
    }),

  getNotifications: (number = 1, size = 5) =>
    axios({
      method: "GET",
      url: `${environment.motivarnosBackend}/notificationuser/public?_number=${number - 1
        }&_size=${size}`,
    }),

  downloadNotifications: () =>
    axios({
      method: "GET",
      url: `${environment.motivarnosBackend}/notificationuser/public?csv=true`,
    }),

  filterNotifications: (number = 1, size = 5, filter) =>
    axios({
      method: "GET",
      url: `${environment.motivarnosBackend}/notificationuser/public?_number=${number - 1
        }&_size=${size}&user.username_like=%25${filter}`,
    }),

  getNotificationsVote: (number = 1, size = 5) =>
    axios({
      method: "GET",
      url: `${environment.motivarnosBackend}/notificationvote?_number=${number - 1
        }&_size=${size}`,
    }),

  downloadNotificationsVote: () =>
    axios({
      method: "GET",
      url: `${environment.motivarnosBackend}/notificationvote?csv=true`,
    }),

  filterNotificationsVote: (number = 1, size = 5, filter) =>
    axios({
      method: "GET",
      url: `${environment.motivarnosBackend}/notificationvote/?_number=${number - 1
        }&_size=${size}&textSearch_like=%25${filter}`,
    }),
};
