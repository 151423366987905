import React, { useState } from "react";
import { FormattedMessage } from "react-intl";
import { useDispatch } from "react-redux";
import { Redirect } from "react-router-dom";

import Dialog from "@material-ui/core/Dialog";

import { useTranslation } from "react-i18next";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";

import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import { withStyles } from "@material-ui/core/styles";
import { SpinnerCircular } from "spinners-react";

import Typography from "@material-ui/core/Typography";

import { inputStyles } from "../../styles/styles";
import UserService from "../../admin/Services/UserService";

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

const RecoverPassword = (props) => {
  const [company, setCompany] = useState("");
  const [user, setUser] = useState("");
  const [team] = useState();
  const [redirect, setRedirect] = useState("");
  const isValid = user && company;
  const classesInput = inputStyles();
  const [userInfo, setUserInfo] = useState(null);
  const [token, setToken] = useState(null);
  const [warningMessage, setWarningMessage] = useState("");
  const [loading, setLoading] = useState(false);

  const { t } = useTranslation();

  return (
    <>
      {redirect !== "" ? <Redirect to={redirect}></Redirect> : null}

      <Dialog
        maxWidth="sm"
        fullWidth={true}
        aria-labelledby="customized-dialog-title"
        open={props.open}
        onClose={props.onClose}
      >
        <DialogTitle id="customized-dialog-title" onClose={props.onClose}>
          {t("forgot-your-password.label", "Forgot your password")}
        </DialogTitle>
        {loading ? (
          <div style={{ textAlign: "center" }}>
            <SpinnerCircular size={90} height={200} />
          </div>
        ) : (
          <>
            {warningMessage ? (
              <h3 style={{ textAlign: "center", color: "red" }}>
                <FormattedMessage
                  id={warningMessage}
                  defaultMessage={warningMessage}
                />
              </h3>
            ) : (
              <></>
            )}
            {!userInfo ? (
              <>
                <DialogContent dividers>
                  <TextField
                    label={
                      <FormattedMessage
                        id="login.companyLabel"
                        defaultMessage={t("company.label", "Company")}
                      />
                    }
                    className={classesInput.pinkInput}
                    value={company}
                    onChange={(e) => setCompany(e.target.value)}
                    style={{ marginBottom: 12, width: "100%" }}
                    variant="outlined"
                  />

                  <TextField
                    label={
                      <FormattedMessage
                        id="login.userLabel"
                        defaultMessage={t("user.label", "User")}
                      />
                    }
                    value={user}
                    className={classesInput.lightPinkInput}
                    onChange={(e) => setUser(e.target.value)}
                    style={{ marginBottom: 12, width: "100%" }}
                    variant="outlined"
                  />
                </DialogContent>
                <DialogActions>
                  <Button onClick={props.onClose} color="secondary">
                    {t("admin.header-dropdown-dialog-actions-cancel", "Cancel")}
                  </Button>
                  <Button
                    disabled={!isValid}
                    onClick={() => {
                      setWarningMessage(null);
                      setLoading(true);
                      UserService.recoverPassword(company, user)
                        .then((r) => {
                          //props.onClose();
                          setUserInfo(r.data.me);
                          setToken(r.data.token);
                        })
                        .catch((e) => {
                          if (e.response?.status == 422) {
                            setWarningMessage(e.response.data.message);
                          } else {
                            setWarningMessage(
                              "Error al intentar cambiar la contraseña."
                            );
                          }
                        })
                        .finally(() => {
                          setLoading(false);
                        });
                    }}
                    color="primary"
                  >
                    {t("confirm.label", "Confirm")}
                  </Button>
                </DialogActions>
              </>
            ) : (
              <>
                <DialogContent dividers>
                  <h3 style={{ textAlign: "center" }}>
                    <FormattedMessage
                      id="login.userLabel"
                      defaultMessage={t(
                        "reset.password.question",
                        "¿Confirmas que quieres reestablecer tu contraseña?"
                      )}
                    />
                  </h3>

                  <h3 style={{ textAlign: "center" }}>
                    {t(
                      "reset.password.message",
                      `Se envirá un mail con un link de confirmación a `
                    )}
                    <b>
                      {userInfo.email.replace(
                        /[a-z0-9\-_.]+@/gi,
                        (c) =>
                          c.substr(0, 3) +
                          c
                            .split("")
                            .slice(3, -1)
                            .map((v) => "*")
                            .join("") +
                          "@"
                      )}
                    </b>
                  </h3>
                </DialogContent>
                <DialogActions>
                  <Button onClick={props.onClose} color="secondary">
                    {t("admin.header-dropdown-dialog-actions-cancel", "Cancel")}
                  </Button>
                  <Button
                    disabled={!isValid}
                    onClick={() => {
                      setWarningMessage(null);
                      setLoading(true);
                      let options = { resetUserList: [userInfo], token: token };
                      UserService.requestRecoverEmailPassword(options)
                        .then((r) => {
                          props.onClose();
                        })
                        .catch((e) => {
                          if (e.response?.status == 422) {
                            setWarningMessage(e.response.data.message);
                          } else {
                            setWarningMessage(
                              "Error al intentar cambiar la contraseña."
                            );
                          }
                        })
                        .finally(() => {
                          setLoading(false);
                        });
                    }}
                    color="primary"
                  >
                    {t("confirm.label", "Confirm")}
                  </Button>
                </DialogActions>
              </>
            )}
          </>
        )}
      </Dialog>
    </>
  );
};
export default RecoverPassword;
