import { useEffect, useState } from "react";
import { useStore } from "react-redux";
import { injectAsyncReducer } from "../../redux/configureStore";

const useAsyncReducer = (reducers) => {
  const store = useStore();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    reducers.forEach((reducer) =>
      injectAsyncReducer(store, reducer.key, reducer.value)
    );

    setLoading(false);
    // eslint-disable-next-line
  }, [store, setLoading]);
  return loading;
};

export default useAsyncReducer;
