import React from "react";
import { es } from "date-fns/locale";
import { withStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import Container from "@material-ui/core/Container";
import Pagination from "@material-ui/lab/Pagination";
import toastr from "toastr";
import { SpinnerCircular } from "spinners-react";
import AddIcon from "@material-ui/icons/Add";
import DownloadIcon from "@material-ui/icons/CloudDownload";
import { Link } from "react-router-dom";
import Button from "@material-ui/core/Button";
import { withTranslation } from "react-i18next";

import Dialog from "@material-ui/core/Dialog";
import TextField from "@material-ui/core/TextField";

import EditIcon from "@material-ui/icons/Edit";
import CopyIcon from "@material-ui/icons/FileCopy";

import GeneralFunctions from "components/app/admin/libs/GeneralFunctions";
import environment from "../../libs/environment";
import Tooltip from "@material-ui/core/Tooltip";

import { format } from "date-fns";
import "toastr/build/toastr.min.css";

/********Dialogs** */
import AddQuestionsDialog from "components/app/admin/common/Dialogs/AddQuestions";
import { NotRegistersFound } from "components/app/admin/common/NotRegistersFound";

/********Services***** */
import TestService from "components/app/admin/Services/TestService";
/*********Dialog********* */
import Delete from "components/app/admin/common/Dialogs/Delete";
import DetailsQuestion from "components/app/admin/common/Dialogs/DetailsQuestion";

/******Icons***** */
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import FileService from "../../components/app/admin/Services/FileService";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";

import CircularProgress from "@material-ui/core/CircularProgress";



const styles = (theme) => ({
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
  },
  fixedHeight: {
    height: 240,
  },
  root: {
    "& > *": {
      marginTop: theme.spacing(2),
    },
  },
  margin: {
    margin: theme.spacing(1),
  },
  textField: {
    width: "50ch",
    marginBottom: "5px",
  },
  fileInput: {
    display: "none",
  },
});

const FOLDER = 'custom_files';

class ShareFile extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      rows: [],
      numberPages: 1,
      page: 1,
      detailId: null,
      deatilDescription: "",
      deatilName: "",
      detailExpirationDate: "",
      fileObject: null,
      loadProgress: 0,
      loadingFile: false,
      isOpenDetail: false,
    };
  }

  componentDidMount() {
    this.getFileTable();
  }

  handleChange(event, value) {
    this.setState({ page: value });
    this.getFileTable(value);
  }

  /***********GENERAL FUNCTIONS************* */

  getFileTable(number = 1) {
    this.setState({ loading: true });

    this.setState({ rows: [] });
    FileService.getTableContent(number, 10, FOLDER)
      .then((response) => {
        this.setState({ rows: response.data.content });
        this.setState({ numberPages: response.data.totalPages });
      })
      .catch((error) => {
        toastr.error("Ha ocurrido un error al intentar obtener los archivos.");
      })
      .finally(() => {
        this.setState({ loading: false });
      });
  }

  /***********CRUD FUNCTIONS************* */

  deleteFile(id) {
    this.setState({ loading: true });
    FileService.delete(id)
      .then(() => {
        toastr.success("El archivo fue eliminado con éxito");
        this.setState({ loading: false });
        if (this.state.rows.length - 1 === 0) {
          this.getFileTable(
            this.state.page - 1 === 0 ? 1 : this.state.page - 1
          );
          this.setState({
            page: this.state.page - 1 === 0 ? 1 : this.state.page - 1,
          });
        } else {
          this.getFileTable(this.state.page);
        }

        /******Verify Rows*** */
      })
      .catch((error) => {
        if (error.response.status === 400) {
          toastr.error("Esta pregunta ya fue usada, no puede se eliminada");
        } else {
          toastr.error("Ha ocurrido un error al eliminar el archivo.");
        }
        this.setState({ loading: false });
      });
  }

  openDetail(row) {
    let temp = row;
    if (!row) {
      temp = {
        description: null,
        originalName: "",
        expirationDate: null,
        id: null,
      };
    }
    this.setState({
      loadingFile: false,
      loadProgress: 0,
      isOpenDetail: true,
      deatilDescription: temp.description,
      detailId: temp.id,
      deatilName: temp.originalName,
      detailExpirationDate: temp.expirationDate
        ? format(new Date(temp.expirationDate), "yyyy-MM-dd")
        : null, //format(new Date(), "yyyy-MM-dd"),
    });
  }

  handleCloseDetail() {
    this.setState({ isOpenDetail: false });
  }

  handleSummit() {
    const { t } = this.props;

    if (this.state.fileObject) {
      const formData = new FormData();
      formData.append("file", this.state.fileObject);
      this.setState((p) => ({ ...p, loadingFile: true, loadProgress: 0 }));

      FileService.uploadFile(
        formData,
        FOLDER,
        null,
        this.state.deatilDescription,
        this.state.detailExpirationDate,
        this.state.detailId,
        (p) => this.setState({ loadProgress: p })
      )
        .then((response) => {
          console.info(response);
  
          this.getFileTable(1);
          this.handleCopyUrl(response.data.url)
          toastr.success(
            t(
              "menu.sherefile-add-file-ok",
              "El archivo se cargo con éxito"
            )
          );
  
          this.setState({ isOpenDetail: false, loadingFile: false, fileObject: null });
        })
        .catch((error) => {
          console.info(error);
          toastr.error(
            t(
              "menu.sherefile-add-file-error",
              "Error cargando el archivo."
            )
          );
        })
        .finally(() => {
          this.setState({ loadingFile: false, loadProgress: 0});
        });
    } else if (this.state.detailId) {
      const fileData = {
        id: this.state.detailId,
        description: this.state.deatilDescription,
        expirationDate: this.state.detailExpirationDate? this.state.detailExpirationDate + "T00:00:00": null,
      };
      this.setState({ loading: true });
      this.setState({ isOpenDetail: false, loadingFile: false });
      FileService.update(fileData)
      .then((response) => {
        
        this.getFileTable(1);
        toastr.success(
          t(
            "menu.fileshare-update-ok",
            "Registro actualizado."
          )
        );
      })
      .catch((error) => {
        console.info(error);
        this.setState({ loading: false, isOpenDetail: true, loadingFile: false });
        toastr.error(
          t(
            "menu.fileshare-update-error",
            "An error occurred while trying to add file"
          )
        );
      });
    } else {
      this.setState({ loading: false, isOpenDetail: true, loadingFile: false });
        toastr.error(
          t(
            "menu.fileshare-new-missing-file",
            "El archivo es obligatorio."
          )
        );
    }

    
  }

  handleChangeFile(e) {
    if (e.target && e.target.files && e.target.files[0]) {
      e.persist();
      this.setState((p) => ({
        ...p,
        fileObject: e.target.files[0],
        deatilName: e.target.files[0].name,
      }));
      console.info(e.target.files[0]);
    }
  }

  handleChangeDetail({ target: { name, value } }) {
    this.setState((prevState) => ({ ...prevState, [name]: value }));
    
  }

  handleCopyUrl(textToCopy) {
    
    const { t } = this.props;
    navigator.clipboard.writeText(textToCopy).then(
      () => {
        toastr.success(
          t(
            "menu.fileshare-copy-url-ok",
            "Se copio la url"
          )
        );
      },
      () => {
        toastr.error(
          t(
            "menu.fileshare-copy-url-error",
            "No se pudo copiar la url"
          )
        );
      }
    );
  }

  render() {
    const {
      loading,
      rows,
      numberPages,
      page,
      detailId,
      deatilDescription,
      deatilName,
      detailExpirationDate,
      loadProgress,
      loadingFile,
    } = this.state;
    const { classes, t } = this.props;

    return (
      <main className={classes.content}>
        <div className={classes.appBarSpacer} />
        <Container maxWidth="lg" className={classes.container}>
          {loading && (
            <div className="div-spinner">
              <Grid container spacing={1}>
                <SpinnerCircular size={90} />
              </Grid>
            </div>
          )}
          {!loading &&
            (rows && rows.length > 0 ? (
              <Grid container spacing={1}>
                <Link to="/">
                    <Button
                      className="button-table mr-1"
                      variant="contained"
                      color="secondary"
                    >
                      <ArrowBackIosIcon className="mr-1" />
                      {t(
                        "menu.trivia-panel-dialog-test-question-button-back",
                        "Back"
                      )}
                    </Button>
                  </Link>
                <Button
                  className="button-table mr-1"
                  variant="contained"
                  color="secondary"
                  onClick={() => this.openDetail()}
                >
                  <AddIcon />
                  {t("menu.sherefile-new", "Nuevo")}
                </Button>

                <TableContainer component={Paper}>
                  <Table className={classes.table} aria-label="simple table">
                    <TableHead>
                      <TableRow>
                        <TableCell align="left">
                          {t("menu.sharefile-tabla-nombre-archivo", "Nombre")}
                        </TableCell>
                        <TableCell align="left">
                          {t("menu.sharefile-tabla-description", "Descripción")}
                        </TableCell>
                        <TableCell align="left">
                          {t(
                            "menu.sharefile-tabla-fecha-vencimiento",
                            "F. Vencimiento"
                          )}
                        </TableCell>
                        <TableCell align="left">
                          {t("menu.sharefile-tabla-acciones", "Acciones")}
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {rows.map((row) => (
                        <TableRow key={row.id}>
                          <TableCell align="left">{row.originalName}</TableCell>
                          <TableCell align="left">
                            {row.description && row.description.length > 30
                              ? row.description.substring(0, 30) + "..."
                              : row.description}
                          </TableCell>

                          <TableCell align="left">
                            {}
                            {row.expirationDate
                              ? GeneralFunctions.formatNewDate(
                                  row.expirationDate,
                                  es
                                )
                              : null}
                          </TableCell>
                          <TableCell align="left">
                            <Tooltip
                              title={t(
                                "menu.sharefile-tabla-acciones-edit",
                                "Editar"
                              )}
                            >
                              <EditIcon
                                fontSize="small"
                                className="cursor-pointer"
                                onClick={() => this.openDetail(row)}
                              />
                            </Tooltip>

                            <Delete
                              title={t(
                                "menu.sharefile-tabla-acciones-delete",
                                "Borar"
                              )}
                              deleteMetric={() => this.deleteFile(row.id)}
                            />
                            <Tooltip
                              title={t(
                                "menu.sharefile-tabla-acciones-descargar",
                                "Descargar"
                              )}
                            >
                              <a
                                href={environment.motivarnosBackend + row.url}
                                target="_blank"
                              >
                                <DownloadIcon />
                              </a>
                            </Tooltip>

                            <Tooltip
                              title={t(
                                "menu.sharefile-tabla-acciones-copiar-url",
                                "Copiar URL"
                              )}
                            >
                              <CopyIcon
                                fontSize="small"
                                className="cursor-pointer"
                                onClick={() =>
                                  this.handleCopyUrl(
                                    environment.motivarnosBackend + row.url
                                  )
                                }
                              />
                            </Tooltip>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
                <div className={classes.root}>
                  <Pagination
                    count={numberPages}
                    page={page}
                    color="primary"
                    onChange={(event, value) => this.handleChange(event, value)}
                  />
                </div>
              </Grid>
            ) : (
              <>
                <Grid container spacing={1}>
                <Link to="/">
                    <Button
                      className="button-table mr-1"
                      variant="contained"
                      color="secondary"
                    >
                      <ArrowBackIosIcon className="mr-1" />
                      {t(
                        "menu.sherefile-button-back",
                        "Back"
                      )}
                    </Button>
                  </Link>
                <Button
                  className="button-table mr-1"
                  variant="contained"
                  color="secondary"
                  onClick={() => this.openDetail()}
                >
                  <AddIcon />
                  {t("menu.sherefile-new", "Nuevo")}
                </Button>
                  <Grid item xs={12} md={12} lg={12}>
                    <NotRegistersFound />
                  </Grid>
                </Grid>
              </>
            ))}
          <Dialog
            onClose={() => this.handleCloseDetail()}
            open={this.state.isOpenDetail}
            maxWidth="sm"
            fullWidth={true}
            aria-labelledby="customized-dialog-title"
          >
            <DialogTitle id="customized-dialog-title">
              {!detailId
                ? t("menu.sherefile-title-new", "Nuevo Archivo")
                : t("menu.sherefile-title-update", "Actualizar Archivo")}
            </DialogTitle>
            <DialogContent>
              <Container maxWidth="lg" className={classes.container}>
                {!loadingFile ? (
                  <Grid container spacing={1}>
                    <Grid item xs={12}>
                      <TextField
                        value={deatilName}
                        name="deatilName"
                        id="item-name"
                        label={t("menu.sharefile-detail-name", "Nombre")}
                        variant="outlined"
                        className="mt-2"
                        placeholder={t(
                          "menu.sharefile-detail-placeholder",
                          "Nombre del archivo a subir"
                        )}
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        value={deatilDescription}
                        name="deatilDescription"
                        id="item-description"
                        label={t(
                          "menu.sharefile-detail-description",
                          "Descripción"
                        )}
                        variant="outlined"
                        className="mt-2"
                        fullWidth
                        onChange={(e) => this.handleChangeDetail(e)} 
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        value={detailExpirationDate}
                        type="date"
                        name="detailExpirationDate"
                        id="detailExpirationDate"
                        label={t(
                          "menu.sharefile-detail-expirationdate",
                          "Fecha de vencimiento"
                        )}
                        variant="outlined"
                        className="mt-2"
                        fullWidth
                        onChange={(e) => this.handleChangeDetail(e)} 
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <input
                        accept="*/*"
                        className={classes.fileInput}
                        id="contained-button-file"
                        type="file"
                        onChange={(e) => this.handleChangeFile(e)}
                      />
                      <label htmlFor="contained-button-file">
                        <Button
                          variant="contained"
                          color="primary"
                          component="span"
                          className="button-table h-100 w-100 mt-2"
                        >
                          {!detailId
                            ? " " +
                              t("menu.sherefile-upload-new", "Subir Archivo")
                            : t(
                                "menu.sherefile-upload-update",
                                "Cambiar Archivo"
                              )}
                        </Button>
                      </label>
                    </Grid>
                  </Grid>
                ) : (
                  <Grid
                    container
                    style={{ textAlign: "center" }}
                    maxWidth="lg"
                    className={classes.container}
                  >
                    <Grid item xs={12}>
                      <h3>
                        {`${t(
                          "menu.sherefile-uploadprogress",
                          "Progreso"
                        )} ${loadProgress} %`}
                      </h3>
                    </Grid>
                    <Grid item xs={12}>
                      <CircularProgress
                        variant="determinate"
                        size={"6rem"}
                        color="primary"
                        value={loadProgress}
                      />
                    </Grid>
                  </Grid>
                )}
              </Container>
            </DialogContent>

            <DialogActions>
              <Button
                className="button-table mt-2 mr-1"
                onClick={() => this.handleCloseDetail()}
                color="secondary"
              >
                {t("menu.sherefile-button-close", "Cerrar")}
              </Button>

              <Button
                className="button-table mt-2"
                color="primary"
                onClick={() => this.handleSummit()}
              >
                {t("menu.sherefile-dialog-actions-save", "Guardar")}
              </Button>
            </DialogActions>
          </Dialog>
        </Container>
      </main>
    );
  }
}

export default withTranslation()(
  withStyles(styles, { withTheme: true })(ShareFile)
);
