import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import './TextInput.css';
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import FormControl from "@material-ui/core/FormControl";

class ClueInput extends PureComponent {
  static propTypes = {
    onEnterPressed: PropTypes.func,
    className: PropTypes.string,
  };

  static defaultProps = {
    onEnterPressed: () => { },
    className: '',
  };

  constructor(props) {
    super(props);
    this.state = {
      clue: '',
      word: '',
    };
  }

  handleChangeSugerencia = (e) => {
    this.setState({ clue: e.target.value });
  };
  handleChangePalabra = (e) => {
    this.setState({ word: e.target.value });
  };

  handleKeyDown = (e) => {
    if (e.which === 13) {
      this.props.onEnterPressed(e);
    }
  };

  render() {
    const { className, onEnterPressed, ...props } = this.props;
    return (
      <div className="input-group">
        <Grid item xs={12} md={7} lg={7}> 
        <input
          type="text"
          pattern='/^[a-z]+$/'
          className={`form-control TextInput ${className}`}
          clue={this.state.clue}
          onKeyDown={this.handleKeyDown}
          onChange={this.handleChangeSugerencia}
          {...props}
        />
        </Grid>
        <Grid item xs={6} md={6} lg={3}> 
        <input
          type="text"
          pattern='/^[a-z]+$/'
          className={`form-control TextInput ${className}`}
          word={this.state.word}
          onKeyDown={this.handleKeyDown}
          onChange={this.handleChangePalabra}
          {...props}
        />       
        </Grid>
        <Grid item xs={4} md={1} lg={1}>        
        {(this.state.clue==='' && this.state.word==='')? 
        <span className="input-group-btn">
          <button className="btn btn-info Button-add"  onClick={onEnterPressed(this.state.clue,this.state.word)}  type="button">
            <span className="glyphicon glyphicon-plus" />
            +
          </button>
        </span > : <></>} 
        </Grid>       
       
      </div >
    );
  }
}

export default ClueInput;
