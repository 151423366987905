import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import TimeObjective from './ObjectiveFields/TimeObjective';
import NumberObjective from './ObjectiveFields/NumberObjective';
import PercentageObjective from './ObjectiveFields/PercentageObjective';
import MultiplierObjective from './ObjectiveFields/MultiplierObjective';
import YesNoObjective from './ObjectiveFields/YesNoObjective';
const useReduxSelector = () =>
  useSelector((state) => ({
    name: state.metricsReducer.name, 
    description: state.metricsReducer.description, 
    frequency: state.metricsReducer.frequency, 
    type: state.metricsReducer.type, 
    points: state.metricsReducer.points
}));
const ObjectiveData = (props) => {
    const { name, description, frequency, type, points } = useReduxSelector();
    const objectiveScreens = {
        "SINO": <YesNoObjective></YesNoObjective>,
        "ENTERO": <NumberObjective></NumberObjective>,
        "TIEMPO": <TimeObjective></TimeObjective>,
        "PORCENTAJE": <PercentageObjective></PercentageObjective>,
        "MULTIPLICADOR": <MultiplierObjective></MultiplierObjective>,
    }
    return objectiveScreens[type];
}

export default ObjectiveData;