import React from "react";
import { withStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import Container from "@material-ui/core/Container";
import Button from "@material-ui/core/Button";
import toastr from "toastr";
import { SpinnerCircular } from "spinners-react";
import TextField from "@material-ui/core/TextField";
import { withTranslation } from "react-i18next";
import { es } from "date-fns/locale";
import GeneralFunctions from "../../libs/GeneralFunctions";

import TablePagination from "@material-ui/core/TablePagination";

import { connect } from "react-redux";

import {
  getNotificationsVoteRequest,
  getNotificationsVoteFilteredRequest,
} from "../../../../../actions/notificationsVoteActions";

import "toastr/build/toastr.min.css";
/********Dialogs***** */

import { NotRegistersFound } from "../../common/NotRegistersFound";

/*****Componentes */

/********Services***** */
import ReportsService from "../../Services/ReportsService";
/*********Icons********* */
import ViewListIcon from "@material-ui/icons/ViewList";
import CloudDownloadIcon from "@material-ui/icons/CloudDownload";

const styles = (theme) => ({
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
  },
  fixedHeight: {
    height: 240,
  },
  root: {
    "& > *": {
      marginTop: theme.spacing(2),
    },
  },
  margin: {
    margin: theme.spacing(1),
  },
  textField: {
    width: "50ch",
    marginBottom: "5px",
  },
});

class NotificationsVote extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      filter: "",
      page: 0,
      rowsPerPage: 5,
    };
  }

  componentDidMount() {
    this.props.getNotificationsVoteRequest({ number: 1, reload: false });
  }

  handleChange(event, value) {
    this.setState({ page: value });
    if (this.filter !== "" && this.filter) {
      this.props.getNotificationsVoteFilteredRequest({
        number: value + 1,
        filter: this.filter,
      });
    } else {
      this.props.getNotificationsVoteRequest({
        number: value + 1,
        reload: false,
      });
    }
  }

  filterValue(number, value) {
    this.setState({ filter: value });

    if (value && value.length > 2) {
      this.props.getNotificationsVoteFilteredRequest({
        number: number,
        filter: value,
      });
    }

    if (value === "") {
      this.props.getNotificationsVoteRequest({ number: 1, reload: true });
    }
  }

  downloadNotifications() {
    this.setState({ loading: true });
    ReportsService.downloadNotificationsVote()
      .then((response) => {
        var blob = response.data;
        var link = document.createElement("a");
        link.href = window.URL.createObjectURL(new Blob([blob]));
        link.download = "Notifications.csv";
        link.click();

        this.setState({ loading: false });
      })
      .catch(() => {
        toastr.error(
          this.props.t(
            "admin.user-panel-message-download-notifications",
            "An error occurred while trying to download notifications"
          )
        );
        this.setState({ loading: false });
      });
  }

  /***********GENERAL FUNCTIONS************* */

  render() {
    const { loading, filter, page, rowsPerPage } = this.state;
    const { classes, t, i18n } = this.props;

    return (
      <main className={classes.content}>
        <div className={classes.appBarSpacer} />
        <Container maxWidth="lg" className={classes.container}>
          <Grid container spacing={1}>
            <Button
              className="button-table mr-1"
              variant="contained"
              color="secondary"
            >
              <ViewListIcon className="mr-1" />
              {t(
                "menu.notification-third-vote-title",
                "Notification report-third party"
              )}
            </Button>

            <Button
              className="button-table mr-1"
              variant="contained"
              color="primary"
              onClick={() => this.downloadNotifications()}
            >
              <CloudDownloadIcon className="mr-1" />
              {t("menu.user-panel-button-download-csv", "Download CSV")}
            </Button>

            <TextField
              id="outlined-basic"
              label={t("menu.metric-panel-search", "Search")}
              variant="outlined"
              value={filter}
              name="filter"
              className={classes.textField}
              onChange={(e) => this.filterValue(1, e.target.value)}
            />
          </Grid>

          {(loading ||
            this.props.notificationsVoteReducer.notificationsVote_charging) && (
            <div className="div-spinner">
              <Grid container spacing={1}>
                <SpinnerCircular size={90} />
              </Grid>
            </div>
          )}

          {!loading &&
            !this.props.notificationsVoteReducer.notificationsVote_charging &&
            (this.props.notificationsVoteReducer.notificationsVote &&
            this.props.notificationsVoteReducer.notificationsVote.length > 0 ? (
              <Grid container spacing={1}>
                <TableContainer component={Paper}>
                  <Table className={classes.table} aria-label="simple table">
                    <TableHead>
                      <TableRow>
                        <TableCell align="left">
                          {t("menu.notification-vote-issuing-user", "Issuing")}
                        </TableCell>
                        <TableCell align="left">
                          {t(
                            "menu.notification-vote-receiving-user",
                            "Receiving"
                          )}
                        </TableCell>
                        <TableCell align="left">
                          {t("type.label", "Type")}
                        </TableCell>

                        <TableCell align="left">
                          {t("date.label", "Date")}
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {this.props.notificationsVoteReducer.notificationsVote
                        .slice(
                          page * rowsPerPage,
                          page * rowsPerPage + rowsPerPage
                        )
                        .map((row) => (
                          <>
                          { row.notificationUser !== null &&
                          <TableRow key={row.id}>
                            <TableCell align="left">
                              {row.voter.username}
                            </TableCell>
                            <TableCell align="left">
                              {row.notificationUser.user.username}
                            </TableCell>
                            <TableCell align="left">
                              {row.notificationUser.notification
                                ? row.notificationUser.notification.type
                                : "N/A"}
                            </TableCell>

                            <TableCell align="left">
                              {GeneralFunctions.formatNewDate(
                                row.created,
                                es
                              )}
                            </TableCell>
                          </TableRow>
                          }
                          </>
                        ))}
                    </TableBody>
                  </Table>
                  <TablePagination
                    className="mt-1"
                    rowsPerPageOptions={[5]}
                    component="div"
                    count={this.props.notificationsVoteReducer.totalElements}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={(event, value) =>
                      this.handleChange(event, value)
                    }
                  />
                </TableContainer>
              </Grid>
            ) : (
              <>
                <Grid container spacing={1}>
                  <Grid item xs={12} md={12} lg={12}>
                    <NotRegistersFound />
                  </Grid>
                </Grid>
              </>
            ))}
        </Container>
      </main>
    );
  }
}

const mapStateToProps = (notificationsVoteReducer) => notificationsVoteReducer;
const mapDispatchToProps = {
  getNotificationsVoteRequest,
  getNotificationsVoteFilteredRequest,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  withTranslation()(withStyles(styles, { withTheme: true })(NotificationsVote))
);
