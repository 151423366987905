import User from "../api/UserPanelApi";

const buildUserList = (listOfGroups = []) => {
  let userList = [];
  console.log(listOfGroups);
  listOfGroups.forEach((group) => {
    group.listausuarios.forEach((users) => {
      userList.push({
        id: users.id,
        user: users.username,
        name: users.nombre,
        lastname: users.apellido,
        email: users.email,
        role: users.tipoderol,
        team: group.gruponombre,
      });
    });
  });
  return userList;
};

const obtainUserList = async (token) => {
  let listOfGroups = await User(token).retreiveUsers();
  let userList = buildUserList(listOfGroups);
  return userList;
};

export default obtainUserList;
