import axios from "axios";
import { logout } from "components/app/public/auth/AuthenticationActions";
import { useDispatch } from "react-redux";

function AxiosConf() {
  const dispatch = useDispatch();

  axios.defaults.baseURL = process.env.REACT_APP_MOTIVARNOS_BACKEND;
  axios.defaults.headers["Content-Type"] = "application/json";

  axios.interceptors.request.use(
    (config) => {
      const sesion = JSON.parse(localStorage.getItem("sesion"));
      // Edit request config
      config.headers.get["Content-Type"] = "application/json";
      if (sesion && sesion.token) {
        //add token
        const token = sesion.token;
        config.headers.token = token ? `${token}` : "";
      }
      return config;
    },
    (error) => {
      console.log(error);
      return Promise.reject(error);
    }
  );

  axios.interceptors.response.use(
    (response) => {
      // Edit response config
      return response;
    },
    (error) => {
      if (
        (error.response.status === 403||error.response.status === 401) &&
        error.response.config.url !== "/login"
      ) {
        dispatch(logout());
      } else {
        return Promise.reject(error);
      }
      return Promise.reject(error);
    }
  );
}
export default AxiosConf;
