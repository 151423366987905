import * as testsTypes from "../types/testsTypes";

import TestService from "../components/app/admin/Services/TestService";

const {
  RESET_STATE,
  TESTS_LIST_REQUEST,
  TESTS_LIST_SAVED,
  TESTS_LIST_SAVE,
  TESTS_LIST_UPDATE,
  TESTS_LIST_DELETE,
  TESTS_LIST_CHARGING,
  TESTS_SAVE_CHARGING,
  TESTS_LIST_ERROR,
} = testsTypes;

export const getTestsRequest = (payload) => async (dispatch, getState) => {
  try {
    const { pages } = getState().testsReducer;
    if (!pages.includes(payload)) {
      dispatch({
        type: TESTS_LIST_CHARGING,
      });
      const responseLogin = await TestService.getTests(payload);
      dispatch({
        type: TESTS_LIST_REQUEST,
        payload: { ...responseLogin.data, number: payload },
      });
    } else {
      dispatch({
        type: TESTS_LIST_SAVED,
      });
    }
  } catch (error) {
    dispatch({
      type: TESTS_LIST_ERROR,
      payload: error.response ? error.response.data : error,
    });
  }
};

export const saveTestRequest = (payload) => async (dispatch, getState) => {
  dispatch({
    type: TESTS_SAVE_CHARGING,
  });

  try {
    const responseLogin = await TestService.saveTest(payload);
    const { tests } = getState().testsReducer;
    const testsUpdated = [responseLogin.data, ...tests];

    dispatch({
      type: TESTS_LIST_SAVE,
      payload: testsUpdated,
    });
    return { status: "SUCCESS" };
  } catch (error) {
    dispatch({
      type: TESTS_LIST_ERROR,
      payload: error.response ? error.response.data : error,
    });
    return { status: "ERROR", error: error.response };
  }
};

export const updateTestRequest = (payload) => async (dispatch, getState) => {
  dispatch({
    type: TESTS_SAVE_CHARGING,
  });

  try {
    await TestService.updateTest(payload);
    const { tests } = getState().testsReducer;

    const testsUpdated = [...tests];
    const findById = (test) => test.id === payload.id;
    const index = testsUpdated.findIndex(findById);
    testsUpdated[index] = {
      ...testsUpdated[index],
      ...payload,
    };

    dispatch({
      type: TESTS_LIST_UPDATE,
      payload: testsUpdated,
    });
    return { status: "SUCCESS" };
  } catch (error) {
    dispatch({
      type: TESTS_LIST_ERROR,
      payload: error.response ? error.response.data : error,
    });
    return { status: "ERROR", error: error.response };
  }
};

export const deleteTestRequest = (payload) => async (dispatch) => {
  dispatch({
    type: TESTS_LIST_CHARGING,
  });

  try {
    await TestService.deleteTest(payload);

    dispatch({
      type: TESTS_LIST_DELETE,
      payload: payload,
    });
    return "SUCCESS";
  } catch (error) {
    dispatch({
      type: TESTS_LIST_ERROR,
      payload: error.response ? error.response.data : error,
    });
    return { error: error.response };
  }
};

export const resetState = () => async (dispatch) => {
  dispatch({
    type: RESET_STATE,
  });
};
