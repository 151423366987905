const { sassFalse } = require("sass");

;(function() {
    /*var LETTERS = 'abcdefghijklmnopqrstuvwxyz'; // letters used for filler
    var WORD_RE = /^[a-z]+$/;                   // what a valid word looks like
    var MAXATTEMPTS = 20;  */                     // maximum amount of times to place a word
  
    /**
     * wordsearch
     *
     * generate a wordsearch puzzle
     */
function wordsearch(words, width, height, opts) {
    var LETTERS = 'abcdefghijklmnopqrstuvwxyz'; // letters used for filler
  var WORD_RE = /^[a-z]+$/;                   // what a valid word looks like
  var MAXATTEMPTS = 100;                       // maximum amount of times to place a word
  var dirRedimensionada = 1;

    if (!words || !words.length) return false;
    width = +width || 10;
    height = +height || 10;
    opts = opts || {};
    opts.backwards = opts.hasOwnProperty('backwards') ? opts.backwards : 0.5;
    opts.letters = opts.letters || LETTERS;

    // filter out any non-words
    words = words.filter(function(a) {
      return WORD_RE.test(a);
    });

    // sort the words by length (biggest first)
    words.sort(function(a, b) {
      return a.length < b.length ? -1 : 1;
    });

    // populate the grid with empty arrays
    var grid = new Array(height);
    for (var i = 0; i < grid.length; i++)
      grid[i] = new Array(width);
    
    var unplaced = [];
    let placeAllWords = false;
    do {
      
      // loop the words
       var colorno = 0;
       var auxPack = placeWords(grid, words, opts,unplaced, MAXATTEMPTS,colorno,placeAllWords=false)
       // end word loop
      
       words = auxPack.unplaced;
       grid = auxPack.grid;
       unplaced = auxPack.unplaced;
       placeAllWords = auxPack.placeAllWords;

       width+=1;
       height+=1;
      if( placeAllWords) grid = redimensionar(grid,width,height);
      
    } while (words.length>0);   

    // the solved grid... XXX I hate this
    var solved = JSON.parse(JSON.stringify(grid));
    // put in filler characters
    grid = filler(grid, opts, solved); 
    // give the user some stuff
    console.log("grid->");
    console.log(grid);
    return {
      grid: grid,
      solved: solved,
      unplaced: unplaced
    };
  }

  /**
   * given an integer that represents a direction,
   * return an object with boundary information
   * and velocity
   */
  function directioninfo(word, direction, width, height) {
    // determine the bounds
    var minx = 0, miny = 0;
    var maxx = width - 1;
    var maxy = height - 1;
    var dx = 0, dy = 0;
    switch (direction) {
      case 0: // up-right
        maxy = height - 1;
        miny = word.length - 1;
        dy = -1;
        maxx = width - word.length;
        minx = 0;
        dx = 1;
        break;
      case 1: // right
        maxx = width - word.length;
        minx = 0;
        dx = 1;
        break;
      case 2: // down-right
        miny = 0;
        maxy = height - word.length;
        dy = 1;
        maxx = width - word.length;
        minx = 0;
        dx = 1;
        break;
      case 3: // down
        miny = 0;
        maxy = height - word.length;
        dy = 1;
        break;
      default: /* NOTREACHED */
        break;
    }
    return {
      maxx: maxx,
      maxy: maxy,
      minx: minx,
      miny: miny,
      dx: dx,
      dy: dy
    }
  }

  function redimensionar(grid,width, height){
     if(grid.length){
        var auxgrid = new Array(width);
        for (var i = 0; i < auxgrid.length; i++){
            auxgrid[i] = new Array(height);
        }   
        for (var i = 0; i < width-1; i++){
           for (var j = 0; j < height-1; j++){
              auxgrid[i][j] = grid[i][j] ;
           }  
        }   

    }
    /*;console.log("auxgrid");
       console.log(auxgrid[i]);*/
    return auxgrid;
  }

  function filler(grids, opts1,sol){
    // put in filler characters
     for (var i = 0; i < grids.length; i++)
       for (var j = 0; j < grids[i].length; j++)
          if (!grids[i][j]) {
             sol[i][j] = ' ';
             grids[i][j] = opts1.letters.charAt(
                 Math.floor(Math.random() * opts1.letters.length)
              );
          }
      return grids;
   }

 /* for (var i = 0; i < words.length; i++) {
       var originalword = words[i];
      var word = originalword;

      // reverse the word if needed
      if (Math.random() < opts.backwards)
        word = word.split('').reverse().join('');

      // pick a random spot
      // try to place the word in the grid
      var attempts = 0;
      while (attempts < MAXATTEMPTS) {
        // determine the direction (up-right, right, down-right, down)
        var direction = Math.floor(Math.random() * 4);
        var info = directioninfo(word, direction, width, height);

        // word is too long, bail out
        if (info.maxx < 0 || info.maxy < 0 || info.maxy < info.miny || info.maxx < info.minx) {
         // unplaced.push(originalword);
          width+=1;
          height+=1;
          grid =  redimensionar(grid,width,height);
          attempts = 0;
          direction = dirRedimensionada === 3 ? 1 : 3;
          info = directioninfo(word, direction, width, height);
          //break;
        }

        // random starting point
        var ox = Math.round(Math.random() * (info.maxx - info.minx) + info.minx);
        var x = ox;
        var oy = Math.round(Math.random() * (info.maxy - info.miny) + info.miny);
        var y = oy;

        // check to make sure there are no collisions
        var placeable = true;
        var count = 0;
        for (var l = 0; l < word.length; l++) {
          var charingrid = grid[y][x];

          if (charingrid) { // check if there is a character in the grid
            if (charingrid !== word.charAt(l)) {
              // not the same latter, try again
              placeable = false; // :(
              break;
            } else {
              // same letter! count it
              count++;
            }
          }
          // keep trying!
          y += info.dy;
          x += info.dx;
        }
        if (!placeable || count >= word.length) {
          attempts++;
          continue;
        }

        // the word was placeable if we make it here!
        // reset x and y and place it
        x = ox;
        y = oy;
        for (var l = 0; l < word.length; l++) {
          grid[y][x] = word.charAt(l);
          if (opts.color) grid[y][x] = '[' + (colorno + 41) + 'm' + grid[y][x] + '[0m';

          y += info.dy;
          x += info.dx;
        }
        break;
      } // end placement while loop

      if (attempts >= MAXATTEMPTS) unplaced.push(originalword);
      colorno = (colorno + 1) % 6;
    } */

    //loop words
  function placeWords(grid, words, opts,unplaced, MAXATTEMPTS, colorno,place=false){
     unplaced = [];
     for (var i = 0; i < words.length; i++) {
        var originalword = words[i];
        var word = originalword;
 
        // reverse the word if needed
        if (Math.random() < opts.backwards)
          word = word.split('').reverse().join('');
 
       // pick a random spot
       // try to place the word in the grid
       var attempts = 0;
       while (attempts < MAXATTEMPTS) {
         // determine the direction (up-right, right, down-right, down)
         var direction = 0;
         var info = 0;
         //if(!place){
           direction = Math.floor(Math.random() * 4);
           info = directioninfo(word, direction, grid.length, grid[0].length);
         //}else{
          // direction = Math.floor(Math.random() * 4);
          // direction = direction === 0 ? 1 : 3;
         //}
 
         // word is too long, bail out
         if (info.maxx < 0 || info.maxy < 0 || info.maxy < info.miny || info.maxx < info.minx) {
          unplaced.push(originalword);
         /*  width+=1;
           height+=1;
           grid =  redimensionar(grid,width,height);
           attempts = 0;
           direction = dirRedimensionada === 3 ? 1 : 3;
           info = directioninfo(word, direction, width, height);*/
           break;
         }
 
         // random starting point
         var ox = Math.round(Math.random() * (info.maxx - info.minx) + info.minx);
         var x = ox;
         var oy = Math.round(Math.random() * (info.maxy - info.miny) + info.miny);
         var y = oy;
 
         // check to make sure there are no collisions
         var placeable = true;
         var count = 0;
         for (var l = 0; l < word.length; l++) {
           var charingrid = grid[y][x];
 
           if (charingrid) { // check if there is a character in the grid
             if (charingrid !== word.charAt(l)) {
               // not the same latter, try again
               placeable = false; // :(
               break;
             } else {
               // same letter! count it
               count++;
             }
           }
           // keep trying!
           y += info.dy;
           x += info.dx;
         }
         if (!placeable || count >= word.length) {
           attempts++;
           continue;
         }
 
         // the word was placeable if we make it here!
         // reset x and y and place it
         x = ox;
         y = oy;
         for (var l = 0; l < word.length; l++) {
           grid[y][x] = word.charAt(l);
           if (opts.color) grid[y][x] = '[' + (colorno + 41) + 'm' + grid[y][x] + '[0m';
 
           y += info.dy;
           x += info.dx;
         }
         break;
       } // end placement while loop 
       if (attempts >= MAXATTEMPTS) unplaced.push(originalword);
          colorno = (colorno + 1) % 6;
      }
      return {
        grid: grid,
        unplaced: unplaced,
        placeAllWords: (unplaced.length>0) ? true : false
      };
    }
  
    // export the function
    if (typeof exports === 'undefined')
       window.wordsearch = wordsearch;
    else
      module.exports = wordsearch;
})();
