import { Button, Container, Slider, TextField } from "@material-ui/core";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { buttonStyles } from "../../../../../styles/styles";
import { changedPointsYesNo, postMetricType } from "../store/MetricsReducer";
import { useTranslation } from "react-i18next";

const YesNoObjective = (props) => {
  const dispatch = useDispatch();
  const buttonClasses = buttonStyles();
  const [pointYes, setPointsYes] = useState(0);
  const dispatchRanges = () => {
    dispatch(changedPointsYesNo({ points: pointYes }));
    dispatch(
      postMetricType({
        metricType: "booleano",
      })
    );
  };
  const { t } = useTranslation();
  return (
    <>
      <Container
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
        }}
      >
        <Container
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
          }}
        >
          <Slider
            value={100}
            unselectable={true}
            marks={[
              { value: 0, label: "Yes" },
              { value: 100, label: "No" },
            ]}
            style={{ width: "20%", marginRight: "1%" }}
          ></Slider>
          <br></br>
          <Container style={{ display: "flex", flexDirection: "row" }}>
            <h4>{`Points for Yes`}</h4>
            <TextField
              style={{ width: "8%", marginLeft: "1%" }}
              value={pointYes}
              onChange={(e) => setPointsYes(e.target.valueAsNumber)}
              type="number"
            ></TextField>
          </Container>
        </Container>
        <Button onClick={dispatchRanges} className={buttonClasses.buttonPink}>
          {t("confirm.label", "Confirm")}
        </Button>
      </Container>
    </>
  );
};

export default YesNoObjective;
