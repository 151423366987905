import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import Pagination from "@material-ui/lab/Pagination";
import "../../scss/TableDashboard.scss";
import Grid from "@material-ui/core/Grid";
import Container from "@material-ui/core/Container";
import CarouselKeys from "../CarouselKeys";

const useStyles = makeStyles((theme) => ({
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    height: "100vh",
    overflow: "auto",
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },

  paper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
  },
  root: {
    "& > *": {
      marginTop: theme.spacing(2),
    },
  },
}));

function createData(tournament, result) {
  return { tournament, result };
}

const rows = [
  createData("José Ortega", "Raul Rodrigo", "José Ortega"),
  createData("Luz Blanco", "José Ortega", "José Ortega"),
  createData("Edit Blanco", "Luz Blanco", "Edit Blanco"),
  createData("Victor Angel", "Edith Blanco", "Victor Angel"),
  createData("Lilimarian", "Vitermiro", "Lilimarian"),
];

export default function TableDetailFixture() {
  const classes = useStyles();

  return (
    <Container maxWidth="lg" className={classes.container}>
      <Grid container spacing={1}>
        <TableContainer component={Paper}>
          <Table className={classes.table} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell align="left">Usuario 1</TableCell>
                <TableCell align="left">Usuario 2</TableCell>
                <TableCell align="left">Ganador</TableCell>
                <TableCell align="left">Acciones</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map((row) => (
                <TableRow key={row.tournament}>
                  <TableCell align="left">{row.tournament}</TableCell>
                  <TableCell align="left">{row.result}</TableCell>
                  <TableCell align="left">{row.result}</TableCell>
                  <TableCell align="left">
                    <CarouselKeys />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <div className={classes.root}>
          <Pagination count={10} color="primary" />
        </div>
      </Grid>
    </Container>
  );
}
