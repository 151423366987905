import { handleActions } from "redux-actions";

import { createActions } from "redux-actions";
import Metric from "../api/Metrics";

const {
  postedBasicData,
  postObjective,
  postMetricType,
  changedLowPointRange,
  changedMediumPointRange,
  changedHighPointRange,
  changedPointsYesNo,
  postFullMetric,
} = createActions({
  postedBasicData: (data) => data,
  postObjective: (data) => data,
  postMetricType: (data) => data,
  changedLowPointRange: (data) => data,
  changedMediumPointRange: (data) => data,
  changedHighPointRange: (data) => data,
  changedPointsYesNo: (data) => data,
  postFullMetric: (data) => data,
});

const dispatchLoadForMetric = (data) => {
  return (dispatch, getState) => {
    Metric(data.token)
      .searchMetric(data.metricId)
      .then((response) => {
        dispatch(postFullMetric(response));
      });
  };
};

var defaultState = {};

const metricsReducer = handleActions(
  {
    [postFullMetric]: (state, action) => {
      console.log(state);
      return action.payload;
    },
    [postedBasicData]: (state, action) => {
      return {
        name: action.payload.name,
        description: action.payload.description,
        frequency: action.payload.frequency,
        type: action.payload.type,
        points: action.payload.points,
        active: action.payload.active,
      };
    },
    [postedBasicData]: (state, action) => {
      return {
        name: action.payload.name,
        description: action.payload.description,
        frequency: action.payload.frequency,
        type: action.payload.type,
        points: action.payload.points,
        active: action.payload.active,
      };
    },
    [postObjective]: (state, action) => {
      return { ...state, objective: action.payload.objective };
    },
    [postMetricType]: (state, action) => {
      return { ...state, metricType: action.payload.metricType };
    },
    [changedLowPointRange]: (state, action) => {
      const range = state.lowRange ? state.lowRange : {};
      if (action.payload.from) {
        range.from = action.payload.from;
      }

      if (action.payload.to) {
        range.to = action.payload.to;
      }

      if (action.payload.pointsRange) {
        range.points = action.payload.pointsRange;
      }

      if (action.payload.color) {
        range.color = action.payload.color;
      }
      console.log(range);
      return { ...state, lowRange: range };
    },
    [changedMediumPointRange]: (state, action) => {
      const range = state.mediumRange ? state.mediumRange : {};
      if (action.payload.from) {
        range.from = action.payload.from;
      }

      if (action.payload.to) {
        range.to = action.payload.to;
      }

      if (action.payload.pointsRange) {
        range.points = action.payload.pointsRange;
      }

      if (action.payload.color) {
        range.color = action.payload.color;
      }

      return { ...state, mediumRange: range };
    },
    [changedHighPointRange]: (state, action) => {
      const range = state.highRange ? state.highRange : {};
      if (action.payload.from) {
        range.from = action.payload.from;
      }

      if (action.payload.to) {
        range.to = action.payload.to;
      }

      if (action.payload.pointsRange) {
        range.points = action.payload.pointsRange;
      }

      if (action.payload.color) {
        range.color = action.payload.color;
      }

      return { ...state, highRange: range };
    },
    [changedPointsYesNo]: (state, action) => {
      const range = state.YesNoRange ? state.YesNoRange : {};

      if (action.payload.from) {
        range.points = action.payloadpoints;
      }

      return { ...state, YesNoRange: range };
    },
  },
  defaultState
);

const exposedMetricsReducer = {
  key: "metricsReducer",
  value: metricsReducer,
};

export {
  postedBasicData,
  postObjective,
  postMetricType,
  changedLowPointRange,
  changedMediumPointRange,
  changedHighPointRange,
  changedPointsYesNo,
  dispatchLoadForMetric,
  exposedMetricsReducer,
};
