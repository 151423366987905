import React, { useState, useEffect } from "react";
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Typography from "@material-ui/core/Typography";
import toastr from "toastr";
import { SpinnerCircular } from "spinners-react";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@material-ui/core/styles";
import FileCopyIcon from "@material-ui/icons/FileCopy";
import Tooltip from "@material-ui/core/Tooltip";

/********Services********* */
import GeneralService from "../../Services/GeneralService";

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
  },
  margin: {
    margin: theme.spacing(1),
  },
  withoutLabel: {
    marginTop: theme.spacing(3),
  },
  formControl: {
    width: "100%",
    marginTop: "1rem",
  },
}));

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

export default function MetricClientSecret(props) {
  const classes = useStyles();

  const [open, setOpen] = useState(false);
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);

  /************General functions********** */
  const [values, setValues] = useState({
    responseApi: null,
  });

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    props.handleClose();
    setOpen(false);
  };

  useEffect(() => {
    setLoading(true);
    GeneralService.getCredentials()
      .then((response) => {
        setValues({ responseApi: response.data });
        setLoading(false);
      })
      .catch(() => {
        toastr.error(
          t(
            "admin.header-dropdown-dialog-get-credentials-error",
            "There was an error getting the credentials"
          )
        );
        setLoading(false);
      });
      // eslint-disable-next-line
  }, []);

  const submitEnableAuth = () => {
    setLoading(true);
    GeneralService.setCredentials()
      .then((response) => {
        setValues({ responseApi: response.data });
        toastr.success(
          t(
            "admin.header-dropdown-dialog-credentials-success",
            "Credentials saved successfully"
          )
        );
        setLoading(false);
      })
      .catch(() => {
        toastr.error(
          t(
            "admin.header-dropdown-dialog-credentials-error",
            "An error occurred while trying to save the credentials"
          )
        );
        setLoading(false);
      });
  };

  const submitChangeAuth = () => {
    setLoading(true);
    GeneralService.setChangeCredentials()
      .then((response) => {
        setValues({ responseApi: response.data });
        toastr.success(
          t(
            "admin.header-dropdown-dialog-credentials-change-success",
            "Credentials changed successfully"
          )
        );
        setLoading(false);
      })
      .catch(() => {
        toastr.error(
          t(
            "admin.header-dropdown-dialog-credentials-change-error",
            "An error occurred while trying to change the credentials"
          )
        );
        setLoading(false);
      });
  };

  return (
    <>
      <div className="p-0 m-0" onClick={handleClickOpen}>
        {/*- {t("admin.header-dropdown-dialog-preference-title", "Preferences")} */}
        - {t("credentials", "Credentials")}
      </div>

      <Dialog
        maxWidth="sm"
        fullWidth={true}
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <DialogTitle id="customized-dialog-title" onClose={handleClose}>
          {t("credentials", "Credentials")}
        </DialogTitle>
        <DialogContent dividers>
          {loading && (
            <div className="div-spinner-modal">
              <Container maxWidth="lg" className={classes.container}>
                <Grid container spacing={1}>
                  <SpinnerCircular size={90} />
                </Grid>
              </Container>
            </div>
          )}

          {!loading && (
            <>
              {values.responseApi &&
              values.responseApi.clientID &&
              values.responseApi.clientSecret ? (
                <>
                  <h3 className="mb-0 mt-0">{t("name.label", "Name")}</h3>
                  <p className="mb-0 mt-0"> {values.responseApi.name}</p>
                  <h3 className="mb-0">{t("client-id", "Client ID")}</h3>
                  <Tooltip title={t("copy", "Copy")}>
                    <p
                      className="mb-0 mt-0 pointer font-custom"
                      onClick={() => {
                        navigator.clipboard.writeText(
                          values.responseApi.clientID
                        );
                        toastr.success(t("copied", "Copied"));
                      }}
                    >
                      {values.responseApi.clientID} <FileCopyIcon />
                    </p>
                  </Tooltip>

                  <h3 className="mb-0">
                    {t("secret-password", "Secret password")}
                  </h3>

                  <Tooltip title={t("copy", "Copy")}>
                    <p
                      className="mb-0 mt-0 pointer font-custom"
                      onClick={() => {
                        navigator.clipboard.writeText(
                          values.responseApi.clientSecret
                        );
                        toastr.success(t("copied", "Copied"));
                      }}
                    >
                      {values.responseApi.clientSecret} <FileCopyIcon />
                    </p>
                  </Tooltip>

                  <Button
                    variant="contained"
                    color="secondary"
                    className="mt-2"
                    onClick={submitChangeAuth}
                    fullWidth={true}
                  >
                    {t("change-secret-password", "Change secret password")}
                  </Button>
                </>
              ) : (
                <>
                  <Button
                    onClick={submitEnableAuth}
                    variant="contained"
                    color="primary"
                    fullWidth={true}
                  >
                    {t("enable-auth", "Enable Oauth2")}
                  </Button>
                </>
              )}
            </>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="secondary">
            {t("menu.trivia-panel-dialog-add-test-button-close", "Close")}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
