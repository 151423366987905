import React from "react";
import { format } from "date-fns";
import { withStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import Container from "@material-ui/core/Container";
import Button from "@material-ui/core/Button";
import Pagination from "@material-ui/lab/Pagination";
import toastr from "toastr";
import { SpinnerCircular } from "spinners-react";
import TextField from "@material-ui/core/TextField";
import CloudDownloadIcon from "@material-ui/icons/CloudDownload";
import "toastr/build/toastr.min.css";
/********Dialogs***** */

import { NotRegistersFound } from "../../common/NotRegistersFound";

/*****Componentes */

/********Services***** */
import ReportsService from "../../Services/ReportsService";
/*********Icons********* */
import ViewListIcon from "@material-ui/icons/ViewList";

const styles = (theme) => ({
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
  },
  fixedHeight: {
    height: 240,
  },
  root: {
    "& > *": {
      marginTop: theme.spacing(2),
    },
  },
  margin: {
    margin: theme.spacing(1),
  },
  textField: {
    width: "50ch",
    marginBottom: "5px",
  },
});

class Notifications extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      rows: [],
      numberPages: 1,
      filter: "",
      page: 1,
    };
  }

  componentDidMount() {
    this.getNotifications();
  }

  handleChange(event, value) {
    this.setState({ page: value });
    this.getNotifications(value);
  }

  /***********GENERAL FUNCTIONS************* */

  getNotifications(number = 1, filter = "") {
    this.setState({ filter: filter });
    function createData(
      company_name,
      company_Id,
      user,
      notification,
      readed,
      id,
      isPublic,
      votes,
      isVoted,
      createdBy,
      updatedBy,
      updated,
      created,
      isActive
    ) {
      return {
        company_name,
        company_Id,
        user,
        notification,
        readed,
        id,
        isPublic,
        votes,
        isVoted,
        createdBy,
        updatedBy,
        updated,
        created,
        isActive,
      };
    }
    if (filter === "" || !filter) {
      this.setState({ loading: true });
      ReportsService.getNotifications(number)
        .then((response) => {
          this.setState({ rows: [] });
          let rows = [];
          response.data.content.forEach((notification) =>
            rows.push(
              createData(
                notification.company_name,
                notification.company_Id,
                notification.user,
                notification.notification,
                notification.readed,
                notification.id,
                notification.isPublic,
                notification.votes,
                notification.isVoted,
                notification.createdBy,
                notification.updatedBy,
                notification.updated,
                notification.created,
                notification.isActive
              )
            )
          );
          this.setState({ rows: rows });
          this.setState({ numberPages: response.data.totalPages });
          this.setState({ loading: false });
        })
        .catch((error) => {
          toastr.error(
            "Ha ocurrido un error al intentar obtener los notificaciones."
          );
          this.setState({ loading: false });
        });
    } else {
      if (filter.length > 3) {
        this.setState({ loading: true });
        ReportsService.filterNotifications(number, 5, filter)
          .then((response) => {
            this.setState({ rows: [] });
            let rows = [];
            response.data.content.forEach((notification) =>
              rows.push(
                createData(
                  notification.company_name,
                  notification.company_Id,
                  notification.user,
                  notification.notification,
                  notification.readed,
                  notification.id,
                  notification.isPublic,
                  notification.votes,
                  notification.isVoted,
                  notification.createdBy,
                  notification.updatedBy,
                  notification.updated,
                  notification.created,
                  notification.isActive
                )
              )
            );
            this.setState({ rows: rows });
            this.setState({ numberPages: response.data.totalPages });
            this.setState({ loading: false });
          })
          .catch((error) => {
            toastr.error(
              "Ha ocurrido un error al intentar obtener los notificaciones."
            );
            this.setState({ loading: false });
          });
      }
    }
  }

  downloadNotifications() {
    this.setState({ loading: true });
    ReportsService.downloadNotifications()
      .then((response) => {
        var blob = response.data;
        var link = document.createElement("a");
        link.href = window.URL.createObjectURL(new Blob([blob]));
        link.download = "Notifications.csv";
        link.click();

        this.setState({ loading: false });
      })
      .catch((error) => {
        toastr.error(
          "Ha ocurrido un error al intentar descargar los notificaciones."
        );
        this.setState({ loading: false });
      });
  }

  render() {
    const { loading, rows, numberPages, page, filter } = this.state;
    const { classes } = this.props;

    return (
      <main className={classes.content}>
        <div className={classes.appBarSpacer} />
        <Container maxWidth="lg" className={classes.container}>
          <Grid container spacing={1}>
            <Button
              className="button-table mr-1"
              variant="contained"
              color="secondary"
            >
              <ViewListIcon className="mr-1" />
              Reporte de notificaciones
            </Button>

            <Button
              className="button-table ml-1 mr-1"
              variant="contained"
              color="primary"
              onClick={() => this.downloadNotifications()}
            >
              <CloudDownloadIcon className="mr-1" />
              Descarga CSV
            </Button>

            <TextField
              id="outlined-basic"
              label="Busqueda"
              variant="outlined"
              value={filter}
              name="filter"
              className={classes.textField}
              onChange={(e) => this.getNotifications(1, e.target.value)}
            />
          </Grid>

          {loading && (
            <div className="div-spinner">
              <Grid container spacing={1}>
                <SpinnerCircular size={90} />
              </Grid>
            </div>
          )}

          {!loading &&
            (rows && rows.length > 0 ? (
              <Grid container spacing={1}>
                <TableContainer component={Paper}>
                  <Table className={classes.table} aria-label="simple table">
                    <TableHead>
                      <TableRow>
                        <TableCell align="left">Creado por</TableCell>
                        <TableCell align="left">Usuario</TableCell>
                        <TableCell align="left">Compañia</TableCell>
                        <TableCell align="left">Notificación</TableCell>
                        <TableCell align="left">Creado</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {rows.map((row) => (
                        <TableRow key={row.id}>
                          <TableCell align="left">{row.createdBy}</TableCell>
                          <TableCell align="left">
                            {row.user.username}
                          </TableCell>
                          <TableCell align="left">{row.company_name}</TableCell>
                          <TableCell align="left">
                            {row.notification.message &&
                            row.notification.message.length > 50
                              ? row.notification.message.substr(0, 50) + "..."
                              : row.notification.message}
                          </TableCell>
                          <TableCell align="left">
                            {`${format(
                              new Date(row.created),
                              "dd-MM-yyyy - HH:mm:ss"
                            )}`}
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
                <div className={classes.root}>
                  <Pagination
                    count={numberPages}
                    page={page}
                    color="primary"
                    onChange={(event, value) => this.handleChange(event, value)}
                  />
                </div>
              </Grid>
            ) : (
              <>
                <Grid container spacing={1}>
                  <Grid item xs={12} md={12} lg={12}>
                    <NotRegistersFound />
                  </Grid>
                </Grid>
              </>
            ))}
        </Container>
      </main>
    );
  }
}

export default withStyles(styles, { withTheme: true })(Notifications);
