import React from "react";
import { withStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import Container from "@material-ui/core/Container";
import Pagination from "@material-ui/lab/Pagination";
import toastr from "toastr";
import { SpinnerCircular } from "spinners-react";
import TextField from "@material-ui/core/TextField";
import { withTranslation } from "react-i18next";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import InputLabel from "@material-ui/core/InputLabel";
import { format } from "date-fns";

import "toastr/build/toastr.min.css";

/********Dialogs** */
import { NotRegistersFound } from "../../common/NotRegistersFound";

/********Services***** */
import UserServices from "../../Services/UserService";
import TeamService from "../../Services/TeamService";

const styles = (theme) => ({
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
  },
  fixedHeight: {
    height: 240,
  },
  root: {
    "& > *": {
      marginTop: theme.spacing(2),
    },
  },
  margin: {
    margin: theme.spacing(1),
  },
  textField: {
    width: "33ch",
    marginBottom: "5px",
    marginRight: "1rem",
  },
});

class UserLoginByDate extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      loadingPage: false,
      rows: [],
      rowsTeams: [],
      numberPages: 1,
      page: 1,
      filterDateOne: "",
      filterDateTwo: "",
      teams: [],
    };
  }

  componentDidMount() {
    this.getAllTeams();
  }

  /*********PAGINATION***** */

  handleChange(event, value) {
    this.setState({ page: value });
    var teams = false;
    if (this.state.teams.length > 0) {
      teams = [...this.state.teams].join(",");
    } else {
      teams = false;
    }
    this.getLoginService(
      value,
      this.state.filterDateOne,
      this.state.filterDateTwo,
      teams
    );
  }

  /***********GENERAL FUNCTIONS AND SELECT************* */

  getAllTeams() {
    this.setState({ loadingPage: true });
    TeamService.getTeams()
      .then((response) => {
        this.setState({ rowsTeams: response.data.content });
        this.setState({ loadingPage: false });
      })
      .catch(() => {
        toastr.error(
          this.props.t(
            "menu.trivia-panel-message-error-get-teams",
            "An error occurred while trying to get the teams"
          )
        );
        this.setState({ loadingPage: false });
      });
  }

  getLoginService(number = 1, filterDateOne, filterDateTwo, teams = false) {
    function createData(date, qty) {
      return {
        date,
        qty,
      };
    }
    this.setState({ loading: true });
    UserServices.getLoginByDate(number, 5, filterDateOne, filterDateTwo, teams)
      .then((response) => {
        this.setState({ rows: [] });
        let rowLogins = [];
        response.data.content.forEach((login) =>
          rowLogins.push(createData(login.date, login.qty))
        );
        this.setState({ rows: rowLogins });
        this.setState({ numberPages: response.data.totalPages });
        this.setState({ loading: false });
      })
      .catch(() => {
        toastr.error(
          this.props.t(
            "admin.ranking-message-error-get-login-by-day",
            "An error occurred obtaining the logins by day"
          )
        );
        this.setState({ loading: false });
      });
  }

  async getLogins(type, filter = "") {
    if (type === "filterDateOne") {
      await this.setState({ filterDateOne: filter });
    } else if (type === "filterDateTwo") {
      await this.setState({ filterDateTwo: filter });
    } else {
      await this.setState((prevState) => ({ ...prevState, teams: filter }));
    }
    var teams = false;
    if (this.state.teams.length > 0) {
      teams = [...this.state.teams].join(",");
    } else {
      teams = false;
    }

    this.setState({ page: 1 });

    if (this.state.filterDateOne && this.state.filterDateTwo) {
      this.getLoginService(
        1,
        this.state.filterDateOne,
        this.state.filterDateTwo,
        teams
      );
    }
  }
  /***********CRUD FUNCTIONS************* */

  render() {
    const {
      loading,
      loadingPage,
      teams,
      rows,
      rowsTeams,
      numberPages,
      page,
      filterDateOne,
      filterDateTwo,
    } = this.state;
    const { classes, t } = this.props;

    return (
      <main className={classes.content}>
        <Container maxWidth="lg">
          {loadingPage && (
            <div className="div-spinner">
              <Grid container spacing={1}>
                <SpinnerCircular size={90} />
              </Grid>
            </div>
          )}

          {!loadingPage && (
            <>
              <h4 className="titleTableCustom">
                {t("login-by-days", "Login by days")}
              </h4>
              <Grid container spacing={1}>
                <>
                  <TextField
                    id="outlined-basic"
                    variant="outlined"
                    value={filterDateOne}
                    type="date"
                    name="filter"
                    className={classes.textField}
                    onChange={(e) =>
                      this.getLogins("filterDateOne", e.target.value)
                    }
                  />

                  <TextField
                    id="outlined-basic"
                    variant="outlined"
                    value={filterDateTwo}
                    type="date"
                    name="filter"
                    className={classes.textField}
                    onChange={(e) =>
                      this.getLogins("filterDateTwo", e.target.value)
                    }
                  />

                  {rowsTeams && rowsTeams.length > 0 && (
                    <FormControl
                      variant="outlined"
                      className="form-control-custom"
                    >
                      <InputLabel id="teams-select-outlined-label">
                        {t(
                          "menu.trivia-panel-dialog-test-consolidated-select-group",
                          "Select a group"
                        )}
                      </InputLabel>
                      <Select
                        labelId="team-mutiple-name-label"
                        id="team"
                        name="team"
                        value={teams}
                        multiple
                        onChange={(e) =>
                          this.getLogins("teams", e.target.value)
                        }
                        label={t(
                          "menu.trivia-panel-dialog-test-consolidated-select-group",
                          "Select a group"
                        )}
                      >
                        {rowsTeams.map((team) => (
                          <MenuItem key={team.id} value={team.id}>
                            {team.name
                              ? team.name
                              : t(
                                  "admin.user-panel-user-dialog-input-select-without-name",
                                  "Without name"
                                )}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  )}
                </>
              </Grid>

              {/***************************TABLE ONE***************************** */}

              {!loading ? (
                rows && rows.length > 0 ? (
                  <Grid container spacing={1} className="mt-2">
                    <TableContainer component={Paper}>
                      <Table
                        className={classes.table}
                        aria-label="simple table"
                      >
                        <TableHead>
                          <TableRow>
                            <TableCell align="left">
                              {t("date.label", "Date")}
                            </TableCell>

                            <TableCell align="left">
                              {t("quantity", "Quantity")}
                            </TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {rows.map((row, index) => (
                            <TableRow key={index}>
                              <TableCell align="left">
                                {`${format(new Date(row.date), "dd-MM-yyyy")}`}
                              </TableCell>

                              <TableCell align="left">{row.qty}</TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                    <div className={classes.root}>
                      <Pagination
                        className="pagination-custom-margin"
                        count={numberPages}
                        page={page}
                        color="primary"
                        onChange={(event, value) =>
                          this.handleChange(event, value)
                        }
                      />
                      {/**  onChange={(event, value) => this.handleChange(event, value)} */}
                    </div>
                  </Grid>
                ) : (
                  <>
                    <>
                      <Grid item xs={12} md={12} lg={12}>
                        <NotRegistersFound
                          title={t(
                            "menu.trivia-panel-dialog-test-consolidated-table-not-registers",
                            "No records for trivia"
                          )}
                        />
                      </Grid>
                    </>
                  </>
                )
              ) : (
                <div className="div-spinner-consolidated">
                  <Grid container spacing={1}>
                    <SpinnerCircular size={90} />
                  </Grid>
                </div>
              )}
            </>
          )}
        </Container>
      </main>
    );
  }
}

export default withTranslation()(
  withStyles(styles, { withTheme: true })(UserLoginByDate)
);
