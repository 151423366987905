import React, {useState} from "react";
import Button from "@material-ui/core/Button/Button";
import { Container, Slide, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import pxToRem from "../../../../utils/pxToRem";
import { FormattedMessage } from "react-intl";
import MotivationalPhrases from "../../../../common/controls/MotivationalPhrases";


const ThankYou = (props) => {
    const className = useStyles();
    return( 
        <Slide direction="down" appear in={true} timeout={3000}>
            <Container style={{display:"flex", flexDirection:"column"}}>
                <h2 className={className.title} style={{textAlign:"center"}}>
                    <FormattedMessage
                        id="thankyou.Title"
                        defaultMessage="Welcome to motivarnos!"
                    />
                </h2>
                <h4 className={className.body} style={{textAlign:"center"}}>
                    <FormattedMessage
                        id="thankyou.body1"
                        defaultMessage="Thank you for trusting in us!"
                    />
                </h4>
                <h4 className={className.body} style={{textAlign:"center"}}>
                    <FormattedMessage
                        id="thankyou.body2"
                        defaultMessage="Let's start creating the groups. And remember:"
                    />
                </h4>
                <Typography className={className.motivational} >
                    <MotivationalPhrases></MotivationalPhrases>
                </Typography>
            </Container>        
        </Slide>
    )
}

const useStyles = makeStyles((theme) => ({
    motivational:{
        color:"black",
        letterSpacing: 6,
        fontSize: pxToRem(20),
        fontWeight:"light",
        fontStyle:"bold"
    },
    body: {
        color: "black",
        letterSpacing: 6,
        lineHeight: 1.1,
        fontSize: pxToRem(30),
        marginBottom: pxToRem(20),
        fontWeight: "bold"
    },
    title: {
        color: "black",
        letterSpacing: 6,
        lineHeight: 1.1,
        fontSize: pxToRem(50),
        marginBottom: pxToRem(20),
        fontWeight: "bold"
    }
}));
export default ThankYou;