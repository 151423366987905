import React, { useState, useEffect } from "react";
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import Typography from "@material-ui/core/Typography";
import toastr from "toastr";
import { SpinnerCircular } from "spinners-react";
import Grid from "@material-ui/core/Grid";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import { makeStyles } from "@material-ui/core/styles";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";

import {
  saveUserRequest,
  updateUserRequest,
} from "../../../../../actions/usersActions";

import {
  saveTeamRequest,
  saveSecondaryTeamRequest,
} from "../../../../../actions/generalActions";

/*********Icons********* */
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import CloudDownloadIcon from "@material-ui/icons/CloudDownload";
/*******Services******* */
import ReportsService from "../../Services/ReportsService";


import "../../Styles/UserDialog.scss";

const useStyles = makeStyles((theme) => ({
  formControl: {
    width: "100%",
    marginTop: "1rem",
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  textField: {
    width: "50ch",
    marginBottom: "5px",
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

function User(props) {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [loading, setLoading] = useState(false);
  const [missions, setMissions] = useState(null);
  const [missionSelected, setMissionSelected] = useState(0);

  const { t } = useTranslation();
  /*
  useEffect(() => {
    if(missions == null){
      console.log('se hace la pregunta')
      ReportsService.getMissionVoteAll()
        .then((response) => {
          console.log(response.data.content);        
          
          setMissions(response.data.content)
          
          //setLoading(false);
        })
        .catch(() => {
          toastr.error(
            this.props.t(
              "menu.new-panel-message-error-votes",
              "An error occurred while trying to get the votes"
            )
          );
          //setLoading(false)
        });
    }
  }, []);
  */

  const handleClickOpen = () => {
    setMissionSelected(0)

    if(missions == null){
      console.log('se hace la pregunta')
      ReportsService.getMissionVoteAll()
        .then((response) => {
          console.log(response.data.content);        
          
          setMissions(response.data.content)
          
          //setLoading(false);
        })
        .catch(() => {
          toastr.error(
            t(
              "menu.new-panel-message-error-votes",
              "An error occurred while trying to get the votes"
            )
          );
          //setLoading(false)
        });
    }

    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };


  function handleMissionSelectedChange({ target: { name, value } }) {
    setMissionSelected(value)
  }

  function downloadFile({ data, fileName, fileType }) {
    const blob = new Blob([data], { type: fileType })
    const a = document.createElement('a');
    a.download = fileName;
    a.href = URL.createObjectURL(blob)

    const clickEvt = new MouseEvent('click', {
      view: window,
      bubbles: true,
      cancelable: true,
    })
    a.dispatchEvent(clickEvt);
    URL.revokeObjectURL(a.href);
    a.remove()
  }

  async function onFormSubmit() {
    console.log(missionSelected);
    setLoading(true);

    ReportsService.downloadMissionSelected(missionSelected)
      .then((response) => {
        console.log(response.data.toString().replace(/,/g, '\t'));
        downloadFile({
          data: response.data,
          fileName: 'notifications_vote.csv', 
          fileType: 'text/csv;charset=utf-8;'
        })
        setLoading(false);
      })
      .catch((error) => {
        console.log(error)
        toastr.error(
          this.props.t(
            "menu.new-panel-message-error-votes",
            "An error occurred while trying to get the votes"
          )
        );
      });
  }

  return (
    <>
      <>
        <Button
          className="button-table"
          variant="contained"
          color="secondary"
          onClick={handleClickOpen}
        >
          <CloudDownloadIcon className="mr-1" />
          {t("download-mission-report", "Download Mission Report")}
        </Button>

        <Dialog
          maxWidth="sm"
          fullWidth={true}
          onClose={handleClose}
          aria-labelledby="customized-dialog-title"
          open={open}
        >
          <DialogTitle id="customized-dialog-title" onClose={handleClose}>
            {t("download-mission-report", "Download Mission Report")}
          </DialogTitle>

          {missions == null || loading ? (
            <div className="div-spinner-modal">
              <DialogContent dividers>
                <Grid container spacing={1}>
                  <SpinnerCircular size={90} />
                </Grid>
              </DialogContent>
            </div>
            ) : (
            <>
              <DialogContent dividers>
                <FormControl variant="outlined" className={classes.formControl}>
                  <InputLabel>
                    {t("menu.points-coins-table-mission", "Mission")}
                  </InputLabel>
                  <Select
                    labelId="missionSelected"
                    id="missionSelected"
                    value={missionSelected}
                    name="missionSelected"
                    label={t("enabled-locked", "Habilitado/bloqueado")}
                    onChange={handleMissionSelectedChange}
                  >
                    {missions.map((elem) => 
                      <MenuItem value={elem.id}>
                        {elem.name}
                      </MenuItem>
                    )}
                  </Select>
                </FormControl>
              </DialogContent>
              <DialogActions>
                <Button onClick={handleClose} color="secondary">
                  {t("admin.header-dropdown-dialog-actions-cancel", "Cancel")}
                </Button>

                <Button onClick={onFormSubmit} color="primary">
                {t(
                  "menu.trivia-panel-dialog-test-analytic-download",
                  "Download"
                )}
                </Button>
              </DialogActions>
            </>
          )}
        </Dialog>
      </>
    </>
  );
}

const mapDispatchToProps = {
  saveUserRequest,
  updateUserRequest,
  saveTeamRequest,
  saveSecondaryTeamRequest,
};

export default connect(null, mapDispatchToProps)(User);
