import React, { useEffect } from "react";
import environment from "libs/environment";

//loads the Google JavaScript Library
const loadGoogleScript = () => {
  //prevent script from loading twice
  if (document.getElementById('google-gsi-js')) { return; }

  //we have at least one script (React)
  const firstJs = document.getElementsByTagName('script')[0];
  const js = document.createElement('script'); 
  js.id = 'google-gsi-js';
  js.src = 'https://accounts.google.com/gsi/client';
  js.async = true;
  js.defer = true;
  js.onload = window.onGoogleLibraryLoad; 
  firstJs.parentNode.insertBefore(js, firstJs);     
}

const GoogleLoginButton = ({
  fromModal,
  locale,
  loginModalOpen,
  logo_alignment,
  onSuccess,
  reloadGoogleLogin,
  reloadGoogleTabs,
  setReloadGoogleLogin,
  shape,
  size,
  text,
  type,
  theme,
  width,
  company
}) => {
  useEffect(() => {
    if (fromModal) {
      window.onGoogleLibraryLoad = () => {
        window.google.accounts.id.initialize({
          client_id: `${environment.googleClientID}`,
          callback: onSuccess
        });
        window.google.accounts.id.prompt();
        window.google.accounts.id.renderButton(
          document.getElementById("gsi-button"),
          {
            locale,
            logo_alignment,
            shape,
            size,
            text,
            type,
            theme,
            width
          }  // customization attributes
        ); //google.accounts.id.prompt(); // also display the One Tap dialog
      }

      //ensure everything is set before loading the script
      if (loginModalOpen) {
        //Modal open, load script
        loadGoogleScript();
      } else {
        //Modal close, remove script
        document.getElementById('google-gsi-js').remove();
      }

      //When an errors occurs, reload script
      if (reloadGoogleLogin) {
        //remove script
        document.getElementById('google-gsi-js').remove();
        //load script
        loadGoogleScript();
        setReloadGoogleLogin(false);
      }

    } else {
      loadGoogleScript();
      window.onGoogleLibraryLoad = () => {
        window.google.accounts.id.initialize({
          client_id: `${environment.googleClientID}`,
          callback: onSuccess
        });
        window.google.accounts.id.prompt();
        window.google.accounts.id.renderButton(
          document.getElementById("gsi-button"),
          {
            locale,
            logo_alignment,
            shape,
            size,
            text,
            type,
            theme,
            width
          }  // customization attributes
        ); //google.accounts.id.prompt(); // also display the One Tap dialog
      }

      //Swichting between login and signup
      if (reloadGoogleTabs) {
        //remove script
        document.getElementById('google-gsi-js').remove();
        //load script
        loadGoogleScript();
      }
    }

  }, [fromModal, loginModalOpen, locale, logo_alignment, reloadGoogleLogin, reloadGoogleTabs, shape, size, text, type, theme, width]);

  const styleButton = (company) => {
    if (company === '') {
      return { pointerEvents: 'none', opacity: '0.7' , marginBottom: '12px', justifyContent: 'center', display: 'flex'};
    }
    return {marginBottom: '12px', justifyContent: 'center', display: 'flex'};
  };

  return <div style={styleButton(company)} id="gsi-button"></div>
}

export default GoogleLoginButton;
