
import CircularProgress from "@material-ui/core/CircularProgress/CircularProgress";
import React, { useState, useContext } from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
const Loading = (props) => {
    return <Dialog open={props.open}>
          <DialogContent>
            <CircularProgress
                  style={{ alignSelf: "center", justifySelf: "center" }}
                  color="primary"
                />
          </DialogContent>
        </Dialog>
}
export default Loading;