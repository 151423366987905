export const RESET_STATE = "RESET_STATE";
export const RESET_STORE = "RESET_STORE";

export const TESTS_LIST_REQUEST = "TESTS_LIST_REQUEST";
export const TESTS_LIST_SAVED = "TESTS_LIST_SAVED";

export const TESTS_LIST_SAVE = "TESTS_LIST_SAVE";
export const TESTS_LIST_UPDATE = "TESTS_LIST_UPDATE";
export const TESTS_LIST_DELETE = "TESTS_LIST_DELETE";

export const TESTS_LIST_CHARGING = "TESTS_LIST_CHARGING";
export const TESTS_SAVE_CHARGING = "TESTS_SAVE_CHARGING";

export const TESTS_LIST_ERROR = "TESTS_LIST_ERROR";
