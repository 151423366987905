import React from "react";
import { Grid } from "@material-ui/core";
import Badge from "./Badge";
import environment from "../../../libs/environment";


const GridBadges = (props) => {
    return (
        <Grid container spacing={2} className="mt-1 badges-grid" style={{width: '100%'}}>
            {props.badgesList.map((item, index) => (
                <>
                    {((index < 3 && props.seeAllBadges == false) || props.seeAllBadges == true)  && (
                        <Grid item xs={12} sm={6} md={4} lg={4} key={index}>
                            <Badge
                                icon={<img src={environment.motivarnosBackend + item.image}/>}
                                title={item.name}
                                isDelivered={item.isDelivered}
                                description={item.comment}
                                points={item.points}
                                type={item.type}
                                count={item.count}
                                owned={item.isComplete}
                                isPointsGranted={item.isPointsGranted}
                                handleOpenDialogComments={() => props.openComments(item)}
                                lengthBadgeTitle={props.lengthBadgeTitle}
								lengthBadgeDescription={props.lengthBadgeDescription}
                            />
                        </Grid>
                    )}
                </>
            ))}
        </Grid>
    )
}

export default GridBadges;