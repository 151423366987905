import { handleActions } from "redux-actions";
import { createActions } from "redux-actions";

const mapCSV = (data) => {
  const keys = data[1].data;
  const registries = data.slice(3, data[0].length);
  const body = [];

  registries.map((registry) => {
    var index = 0;
    const item = {};

    keys.map((key) => {
      if (registry.data[index]) {
        item[key] = registry.data[index];
      }

      index++;
    });

    if (Object.keys(item).length > 0) {
      body.push(item);
    }
  });

  return body;
};

const { importCSVAction, deleteCSVBody } = createActions({
  importCSVAction: (data) => ({ dataToMap: data }),
  deleteCSVBody: () => ({}),
});

var defaultState = {};

const importCSVReducer = handleActions(
  {
    [importCSVAction]: (state, action) => {
      const body = mapCSV(action.payload.dataToMap);
      return { mappedBody: body };
    },
    [deleteCSVBody]: (state, action) => {
      return {};
    },
  },
  defaultState
);

const exposedCSVReducer = {
  key: "importCSVReducer",
  value: importCSVReducer,
};

export { importCSVAction, deleteCSVBody, exposedCSVReducer };
