import React, { useState, useEffect } from "react";
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Paper from "@material-ui/core/Paper";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import toastr from "toastr";
import { SpinnerCircular } from "spinners-react";
import Grid from "@material-ui/core/Grid";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import { makeStyles } from "@material-ui/core/styles";
import Tooltip from "@material-ui/core/Tooltip";
import { useTranslation } from "react-i18next";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import { connect } from "react-redux";

import {
  saveUserRequest,
  updateUserRequest,
} from "../../../../../actions/usersActions";

import {
  saveTeamRequest,
  saveSecondaryTeamRequest,
  secondaryTeamsRequest,
} from "../../../../../actions/generalActions";

/*********Icons********* */
import AddIcon from "@material-ui/icons/Add";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import AddLocationIcon from "@material-ui/icons/AddLocation";
/*******Services******* */
import TeamService from "../../Services/TeamService";

import "../../Styles/UserDialog.scss";

const useStyles = makeStyles((theme) => ({
  formControl: {
    width: "100%",
    marginTop: "1rem",
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  textField: {
    width: "50ch",
    marginBottom: "5px",
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

function SecondaryGroup(props) {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [loading, setLoading] = useState(false);

  const [usernameError, setUsernameError] = useState(false);
  const [emailError] = useState(false);
  const [secTeam, setSecTeam] = useState(null);
  const [secRolTeam, setSecRolTeam] = useState([]);

  const { t } = useTranslation();

  const [
    {
      name,
      isActive,
    },
    setState,
  ] = useState({
    //username: props.username ? props.username : "",
    name: props.name ? props.name : "",
    //lastName: props.lastName ? props.lastName : "",
    //email: props.email ? props.email : "",
    //role: 3,
    //team: { id: "NONE" },
    //secondaryTeam: { id: "NONE" },
    //secondaryTeams: props.secondaryTeams,
    //teams: props.teams,
    //roles: props.roles ? props.roles : [],
    //addTeam: "",
    isActive: props.isActive,
  });

  useEffect(() => {
    setState((prevState) => ({
      ...prevState,
      teams: props.teams,
      secondaryTeams: props.secondaryTeams,
    }));
  }, [props.teams, props.secondaryTeams]);

  useEffect(() => {
    const secondaryTeamFromProps =
      props.roles && props.roles.length > 0 && props.roles[0].secondaryTeam;
    if (secondaryTeamFromProps) {
      setState((prevState) => ({
        ...prevState,
        secondaryTeam: secondaryTeamFromProps,
      }));
      setSecTeam(secondaryTeamFromProps);
    }
  }, [props.roles]);

  const handleClickOpen = () => {
    setOriginalState();

    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  function setOriginalState() {
    setState((prevState) => ({
      ...prevState,
      //username: props.username ? props.username : "",
      name: props.name ? props.name : "",
      //lastName: props.lastName ? props.lastName : "",
      //email: props.email ? props.email : "",
      //role: 3,
      //team: { id: "NONE" },
      //secondaryTeam: { id: "NONE" },
      //secondaryTeams: props.secondaryTeams,
      //teams: props.teams,
      //roles: props.roles ? props.roles : [],
      //addTeam: "",
      isActive: props.isActive,
    }));
  }

  /***********Data Binding Form******* */

  function handleTextFieldChange({ target: { name, value } }) {
    setState((prevState) => ({ ...prevState, [name]: value }));
  }

  async function onFormSubmit() {
    /*********Remove Styles**** */

    setUsernameError(false);

    /*if (username === "") {
      setUsernameError(true);
      toastr.error(
        t(
          "admin.user-panel-user-dialog-username-input-error",
          "Username is required"
        )
      );
      return;
    }

    if (roles === "" || roles.length === 0) {
      toastr.error(
        t(
          "admin.user-panel-user-dialog-role-input-error",
          "The role is required"
        )
      );
      return;
    }*/
    setLoading(true);

    let status;

    if (props.typeModal === "modalEdit") {
      await props
        .updateUserRequest({
          id: props.id,
          //username,
          name,
          //lastName,
          //email,
          //roles,
          //secondaryTeam:
            //secRolTeam && secRolTeam.length > 0 ? { id: secTeam.id } : null,
          isActive,
        })
        .then((r) => (status = r));
    } else {
      await props
        .saveUserRequest({
          //username,
          name,
          //lastName,
          //email,
          //roles,
          //secondaryTeam:
            //secRolTeam && secRolTeam.length > 0 ? { id: secTeam.id } : null,
          isActive,
        })
        .then((r) => (status = r));
    }

    if (status.status === "ERROR") {
      if (
        status.error &&
        status.error.data.messageCode === "invalid.existuseremail"
      ) {
        toastr.error(
          t(
            "admin.user-panel-user-dialog-message-error-user-exists-edit",
            "The email already exists in the database"
          )
        );
      } else if (
        status.error &&
        status.error.data.messageCode === "invalid.existusername"
      ) {
        toastr.error(
          t(
            "admin.user-panel-user-dialog-message-error-username-exists",
            "A user with this username already exists"
          )
        );
      } else {
        toastr.error(
          t(
            "admin.user-panel-user-dialog-message-error-user-save",
            "An error occurred while trying to save the user"
          )
        );
      }
      setLoading(false);
    } else {
      toastr.success(
        t(
          "admin.user-panel-user-dialog-message-success-user-save",
          "User saved successfully"
        )
      );
      setLoading(false);
      handleClose();
    }
  }

  return (
    <>
      <>
        {props.typeModal === "modalEdit" ? (
          <Tooltip title={t("admin.actions-edit", "Edit")}>
            <IconButton aria-label="edit-user" onClick={handleClickOpen}>
              <EditIcon fontSize="small" />
            </IconButton>
          </Tooltip>
        ) : (
          <Button
            className="button-table"
            variant="contained"
            color="secondary"
            onClick={handleClickOpen}
          >
            <AddIcon />
            {t("menu.user-panel-button-secondary-groups", "Secondary Teams")}
          </Button>
        )}

        <Dialog
          maxWidth="sm"
          fullWidth={true}
          onClose={handleClose}
          aria-labelledby="customized-dialog-title"
          open={open}
        >
          <DialogTitle id="customized-dialog-title" onClose={handleClose}>
            {t("admin.user-panel-secondary-group-dialog-title", "Secondary Team Management")}
          </DialogTitle>

          {loading && (
            <div className="div-spinner-modal">
              <DialogContent dividers>
                <Grid container spacing={1}>
                  <SpinnerCircular size={90} />
                </Grid>
              </DialogContent>
            </div>
          )}
          {!loading && (
            <>
              <DialogContent dividers>
                {/*<TextField
                  error={usernameError}
                  value={username}
                  name="username"
                  onChange={handleTextFieldChange}
                  id="username"
                  label={t(
                    "admin.user-panel-user-dialog-input-username",
                    "Username"
                  )}
                  variant="outlined"
                  className="mt-2"
                  fullWidth
                />*/}

                <TextField
                  value={name}
                  name="name"
                  onChange={handleTextFieldChange}
                  id="name"
                  label={t("admin.user-panel-user-dialog-input-name", "Name")}
                  variant="outlined"
                  className="mt-2"
                  fullWidth
                />

                {/*<TextField
                  value={lastName}
                  name="lastName"
                  onChange={handleTextFieldChange}
                  id="lastName"
                  label={t(
                    "admin.user-panel-user-dialog-input-lastname",
                    "Last name"
                  )}
                  variant="outlined"
                  className="mt-2"
                  fullWidth
                />

                <TextField
                  error={emailError}
                  value={email}
                  name="email"
                  onChange={handleTextFieldChange}
                  id="email"
                  label={t("admin.user-panel-table-email", "Email")}
                  variant="outlined"
                  className="mt-2"
                  fullWidth
                />*/}

                <FormControl variant="outlined" className={classes.formControl}>
                  <InputLabel id="isActive-select-outlined-label">
                    {t("enabled-locked", "Habilitado/bloqueado")}
                  </InputLabel>
                  <Select
                    labelId="isActive"
                    id="isActive"
                    value={isActive}
                    name="isActive"
                    label={t("enabled-locked", "Habilitado/bloqueado")}
                    onChange={handleTextFieldChange}
                  >
                    <MenuItem value={true}>
                      {t(
                        "admin.header-dropdown-dialog-notifications-input-item-yes",
                        "Yes"
                      )}
                    </MenuItem>
                    <MenuItem value={false}>
                      {t(
                        "admin.header-dropdown-dialog-notifications-input-item-no",
                        "No"
                      )}
                    </MenuItem>
                  </Select>
                </FormControl>
              </DialogContent>
              <DialogActions>
                <Button onClick={handleClose} color="secondary">
                  {t("admin.header-dropdown-dialog-actions-cancel", "Cancel")}
                </Button>

                <Button onClick={onFormSubmit} color="primary">
                  {t("admin.header-dropdown-dialog-actions-save", "Save")}
                </Button>
              </DialogActions>
            </>
          )}
        </Dialog>
      </>
    </>
  );
}

const mapDispatchToProps = {
  saveUserRequest,
  updateUserRequest,
  saveTeamRequest,
  saveSecondaryTeamRequest,
};

export default connect(null, mapDispatchToProps)(SecondaryGroup);
