import React from "react";
import { Alert } from '@material-ui/lab';
import { FormattedMessage } from "react-intl";

const ErrorHandler = (props) => {
    const message = props.message;
    return <Alert style={props.style} severity="error">
                <FormattedMessage id={message} />
              </Alert>
}

export default ErrorHandler;