import axios from "axios";
import environment from "../../../libs/environment";

export default {
  getMetrics: () =>
    axios({
      method: "GET",
      url: `${environment.motivarnosBackend}/metricconf/userreport`,
    }),
  getMetricData: (id, dateFrom, dateTo) =>
    axios({
      method: "GET",
      url: `${environment.motivarnosBackend}/metricdata?metricConf.id=${id}&date1_ge=${dateFrom}&date1_le=${dateTo}&_sort=date1_asc`,
    }),
  getMetric: (id) =>
    axios({
      method: "GET",
      url: `${environment.motivarnosBackend}/metricconf/${id}`,
    }),
  getMetricRelated: () =>
    axios({
      method: "GET",
      url: `${environment.motivarnosBackend}/metricconf/usersummary`,
    }),

  getCollaboratorsByLeaders: (
    number = 0,
    size = 7,
    order = 'desc', // To Team manager
    userId
  ) =>
    axios({
      method: 'GET',
      url: `${environment.motivarnosBackend}/user?position_in=3&teamLeader.id=${userId}&_number=${number}&_size=${size}&_sort=created_${order}`
    }),
};
