import React, { useState, useContext } from "react";

import TextField from "@material-ui/core/TextField/TextField";
import { FormattedMessage } from "react-intl";
import { useTranslation } from 'react-i18next';

const ValidateName = (name) => name && name.length <= 30;
const Name = (props) =>{

    const [name, setName] = useState(props.name);
    
    const changeName = (name) => {
        if(name && !ValidateName(name))
            return;
        setName(name);
        if(props.onChange) {
            props.onChange(name);
        }
    }
    const { t, i18n } = useTranslation();

    return <TextField
                label={
                    <FormattedMessage
                        id="landing.nameLabel"
                        defaultMessage={t('name.label','Label')}
                    />
                }
                error={name && !ValidateName(name)}
                value={name}
                className={props.className}
                onChange={(e) => changeName(e.target.value)}
                style={{ marginBottom: 12 }}
                variant="outlined"
            />
}

export default Name;