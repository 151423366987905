import axios from "axios";
import environment from "../../../../libs/environment";

export default {
  setTeam: (data) =>
    axios({
      method: "POST",
      url: `${environment.motivarnosBackend}/team`,
      data: data,
    }),

  getImportUsers: (number = 1, size = 5) =>
    axios({
      method: "GET",
      url: `${environment.motivarnosBackend}/team?_number=${
        number - 1
      }&_size=${size}`,
    }),

  updateImportUser: (data) =>
    axios({
      method: "PUT",
      url: `${environment.motivarnosBackend}/team`,
      data: data,
    }),

  deleteImportUser: (id) =>
    axios({
      method: "DELETE",
      url: `${environment.motivarnosBackend}/team/${id}`,
    }),

  getTeams: () =>
    axios({
      method: "GET",
      url: `${environment.motivarnosBackend}/team?_size=${999}`,
    }),

  setSecondaryTeam: (data) =>
    axios({
      method: "POST",
      url: `${environment.motivarnosBackend}/secondaryteam`,
      data: data,
    }),

  updateTeam: (data) =>
    axios({
      method: "PUT",
      url: `${environment.motivarnosBackend}/team`,
      data: data,
    }),
  
  updateSecondaryTeam: (data) =>
    axios({
      method: "PUT",
      url: `${environment.motivarnosBackend}/secondaryteam`,
      data: data,
    }),

  deleteTeam: ({ id, isSecondaryTeam = false }) =>
    axios({
      method: 'DELETE',
      url: `${environment.motivarnosBackend}/${isSecondaryTeam ? 'secondaryteam' : 'team'}/${id}`
    }),

  getSecondaryTeams: () =>
    axios({
      method: "GET",
      url: `${environment.motivarnosBackend}/secondaryteam?_size=${999}`,
    }),
};
