import React from "react";
import { withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Container from "@material-ui/core/Container";
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';

import toastr from "toastr";
import { SpinnerCircular } from "spinners-react";

import BadgeService from "../../components/app/admin/Services/BadgeService";

import AssignBadge from "../../components/app/admin/common/Dialogs/AssignBadge";
import DialogComment from "../../components/app/admin/common/Dialogs/DialogComment/DialogComment";

import EmptyMessage from "../../components/EmptyMessage";
import Sparks from "../../components/Sparks";
import GridBadges from "./components/GridBadges";
import BadgeItemCard from "./components/BadgeItemCard";

import "toastr/build/toastr.min.css";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import "./styles.scss";

const styles = (theme) => ({
	appBarSpacer: theme.mixins.toolbar,
	content: {
		flexGrow: 1,
	},
	container: {
		paddingTop: theme.spacing(4),
		paddingBottom: theme.spacing(4),
	},
	paper: {
		padding: theme.spacing(2),
		display: "flex",
		overflow: "auto",
		flexDirection: "column",
	},
	fixedHeight: {
		height: 240,
	},
	root: {
		"& > *": {
			marginTop: theme.spacing(2),
		},
	},
	margin: {
		margin: theme.spacing(1),
	},
	textField: {
		width: "50ch",
		marginBottom: "5px",
	},
});

class Badges extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			loading: false,
			loadingBadges: false,
			badges: [],
			badgesContent: [],
			badgesUnassigned: [],
			comments: [],
			sesion: null,
			page: 1,
			openCommentModal: false,
			loadingComments: false,
			deliveredBadges: [],
			loadingDeliveredBadges: false,
			showSparks: false,
			totalPoints: 0,
			seeAllBadges: false,
			lengthBadgeTitle: 30,
			lengthBadgeDescription: 57
		};
	}
	timer;

	componentDidMount() {
		let userSesion = JSON.parse(localStorage.getItem("sesion"));
		this.setState({ sesion: userSesion });
		this.setState({ totalPoints: userSesion.user.coinBalance });
		this.getAllAssignBadges(this.page, true);
		this.getAllDeliveredBadges();

		const { innerWidth: width, _ } = window

		if (width < 400) {
			this.setState({ lengthBadgeTitle: 24 });
		}
	}

	componentWillUnmount() {
		clearTimeout(this.timer);
	}

	updateInformationUpdated() {
		this.getUsers();
	}

	async getBadgeComments(badgeId) {
		try {
			const response = await BadgeService.getBadgeComments(badgeId, this.state.sesion.user.id);
			this.setState({ comments: response.data.content, loadingComments: false });
		} catch (error) {
			toastr.error("Ha ocurrido un error al intentar obtener las insignias.");
			console.log(error)
		}
	}

	openCommentBadgeModal(badge) {
		this.setState({ openCommentModal: true, loadingComments: true });
		this.getBadgeComments(badge.insigniaId);
	}

	closeCommentBadgeModal() {
		this.setState({ openCommentModal: false });
	}

	seeAllBadges() {
		this.setState({ seeAllBadges: this.state.seeAllBadges ? false : true });
	}

	getAllAssignBadges(page = 1, type = false) {

		if (type) {
			this.setState({ loadingBadges: true });
		}

		BadgeService.getMyAssignedBadges(page, 4)

			.then((response) => {
				this.setState({
					badges: this.state.badges.concat(response.data.content),
				});
				this.setState({ badgesContent: response.data.totalElements });
				this.setState({ loadingBadges: false });
				this.setState({ page: this.state.page + 1 });
				const insignias_recibidas_id = response.data.content.map((elem => elem.id));
				BadgeService.getBadges(1, 999)
					.then((response) => {
						this.setState({ badgesUnassigned: response.data.content.filter(elem => insignias_recibidas_id.indexOf(elem.id) == -1) });
					})
					.catch((error) => {
						toastr.error("Ha ocurrido un error al intentar obtener las insignias.");
						this.setState({ loading: false });
					});
			})

			.catch((error) => {
				toastr.error("Ha ocurrido un error al intentar obtener las insignias.");
				this.setState({ loading: false });
			});
	}

	fireworks() {
		this.setState({ showSparks: true });
		this.timer = setTimeout(() => this.setState({ showSparks: false }), 5000);
	}

	getAllDeliveredBadges(isDelivered) {
		this.setState({ loadingDeliveredBadges: true });

		BadgeService.getMyDeliveredBadges()

			.then((response) => {
				this.setState({
					//deliveredBadges: this.state.deliveredBadges.concat(response.data.content),
					deliveredBadges: response.data.content,
				});
				this.setState({ loadingDeliveredBadges: false });
				if (isDelivered) {
					this.fireworks()
				}
			})

			.catch((error) => {
				toastr.error("Ha ocurrido un error al intentar obtener las insignias Entregadas.");
				this.setState({ loading: false });
			});
	}


	render() {
		const {
			badges,
			sesion,
			loadingBadges,
			openCommentModal,
			comments,
			loadingComments,
			loadingDeliveredBadges,
			deliveredBadges,
			showSparks,
			seeAllBadges,
			lengthBadgeTitle,
			lengthBadgeDescription,
			badgesUnassigned
		} = this.state;

		return (
			<>
				<div className="insignias-wrapper">
					<Container maxWidth="lg" className={'insignias-wrapper-container'}>
						<>
							{showSparks &&
								<div style={{ zIndex: "40" }}>
									<Sparks show={true} />
								</div>
							}
							<div className="badges-wrapper">
								<div className="badges-wrapper-insignias-recibidas-title" style={{display: "flex", justifyContent: "space-between"}}>
									<div>INSIGNIAS RECIBIDAS</div>
									<div className="insignias-recibidas-title-ver-todas insignias-recibidas-title-ver-todas-header" onClick={() => this.seeAllBadges()}><div><AddCircleOutlineIcon/></div> VER TODAS</div>
								</div>
								{loadingBadges ? (
									<div className="div-spinner">
										<Grid container spacing={1}>
											<SpinnerCircular size={90} />
										</Grid>
									</div>
								) : (
									<>
										{badges !== null && badges.length === 0 ? (
											<EmptyMessage />
										) : (
											<GridBadges
												seeAllBadges={seeAllBadges}
												badgesList={[...badges, ...badgesUnassigned]}
												badgesUnassignedList={badgesUnassigned}
												openComments={(badge) => this.openCommentBadgeModal(badge)}
												lengthBadgeTitle={lengthBadgeTitle}
												lengthBadgeDescription={lengthBadgeDescription}
											/>
										)}
									</>
								)}
								<div className="badges-wrapper-insignias-recibidas-title badges-wrapper-footer-insignias-recibidas" style={{display: "flex", justifyContent: "space-between"}}>
									<div className="insignias-recibidas-title-ver-todas" onClick={() => this.seeAllBadges()}><div><AddCircleOutlineIcon/></div> VER TODAS</div>
								</div>
							</div>
						</>
						<Grid container style={{ paddingTop: '2em' }} className="insignias-entregadas-wrapper">
							<div className="badges-wrapper-insignias-entregadas-title">
								<div>INSIGNIAS ENTREGADAS</div>
								<div className="insignias-asignadas-title-asignar-div">
									<AssignBadge
										playerSection
										callbackDeliveredBadge={(isDelivered) => this.getAllDeliveredBadges(isDelivered)}
									/>
								</div>
							</div>
							{loadingDeliveredBadges && (
								<div className="div-spinner">
									<Grid container spacing={1}>
										<SpinnerCircular size={90} />
									</Grid>
								</div>
							)}
							{deliveredBadges.length >= 1 && (
								<Grid xs={12} sm={12} md={12} lg={12}  container spacing={2} className="insignias-entregadas-items-cards">
									{deliveredBadges.map((row, index) =>
										<BadgeItemCard
											badge={row}
											key={index}
											senderPlayer={sesion.user}
											handleOpenDialogComments={() => this.openCommentBadgeModal(row)}
											lengthBadgeTitle={lengthBadgeTitle}
											lengthBadgeDescription={lengthBadgeDescription}
										/>
									)}
								</Grid>
							)}
							<div className="badges-wrapper-insignias-entregadas-title">
								<div className="insignias-asignadas-title-asignar insignias-asignadas-title-asignar-footer">
									<AssignBadge
										playerSection
										callbackDeliveredBadge={(isDelivered) => this.getAllDeliveredBadges(isDelivered)}
									/>
								</div>
							</div>
						</Grid>
					</Container>
					<DialogComment
						isOpen={openCommentModal}
						handleClose={() => this.closeCommentBadgeModal()}
						isLoading={loadingComments}
						commentsList={comments}
					/>
				</div>
			</>
		);
	}
}

export default withStyles(styles, { withTheme: true })(Badges);
