import React from "react";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import TableHead from "@material-ui/core/TableHead";
import { useTranslation } from "react-i18next";

export default function TableDetailMetric({ data }) {
  const { t } = useTranslation();

  return (
    <div className="card-model table-detail-metric">
      <div className="table-detail-metric-title">
        DATOS GENERALES
      </div>
      <TableContainer component={Paper} className="mt-2 table-detail-metric-container">
        <Table className="table-detail-metric-container-table" aria-label="custom pagination table">
          <TableHead>
            <TableRow className="table-detail-metric-container-fecha-row">
              <TableCell align="left">
                {t(
                  "date.label",
                  "Fecha"
                )}
              </TableCell>
              {data.map((data, index) => {
                return (<TableCell key={index} align="left" className="regular-head-cell">{data.date1.substring(8, 10)}</TableCell>)
              })}
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell align="left">
                {t(
                  "values.label",
                  "Valores"
                )}
              </TableCell>
              {data.map((elem, index) => (
                <TableCell key={index} align="left" className="regular-body-cell">
                  <div>
                    <div style={{backgroundColor: `${elem.color}`}}>
                      {elem.value}
                    </div>
                  </div>
                </TableCell>
              ))}
            </TableRow>
            <TableRow>
              <TableCell align="left">
                {t(
                  "points.label",
                  "Puntos"
                )}
              </TableCell>
              {data.map((elem, index) => (
                <TableCell key={index} align="left" className="regular-body-cell">
                  <div>
                    <div style={{backgroundColor: `${elem.color}`}}>
                      {elem.points}
                    </div>
                  </div>
                </TableCell>
              ))}
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
}
