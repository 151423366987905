import React from "react";
import { useTranslation } from "react-i18next";

import "./styles.scss";

const Error500 = () => {
  const { t } = useTranslation();

	return (
		<div className="error-500">
			<p className="info">¡D’Ough!</p>
			<p className="info">{t("server-down.message", "We are reconnecting our servers to reality. They come back in 5 or 6 minutes.")}</p>
			<h1 className="internal">
				<span className="five">5</span>
				<span className="zero">0</span>
				<span className="zero">0</span>
			</h1>
		</div>
   )
}

export default Error500;
