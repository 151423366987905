export const RESET_STATE = "RESET_STATE";
export const RESET_STORE = "RESET_STORE";

export const LETTERSOUP_LIST_REQUEST = "LETTERSOUP_LIST_REQUEST";
export const LETTERSOUP_LIST_SAVED = "LETTERSOUP_LIST_SAVED";

export const LETTERSOUP_LIST_SAVE = "LETTERSOUP_LIST_SAVE";
export const LETTERSOUP_LIST_UPDATE = "LETTERSOUP_LIST_UPDATE";
export const LETTERSOUP_LIST_DELETE = "LETTERSOUP_LIST_DELETE";

export const LETTERSOUP_LIST_CHARGING = "LETTERSOUP_LIST_CHARGING";
export const LETTERSOUP_SAVE_CHARGING = "LETTERSOUP_SAVE_CHARGING";

export const LETTERSOUP_LIST_ERROR = "LETTERSOUP_LIST_ERROR";
