import React, { useState } from "react";
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import CloseIcon from "@material-ui/icons/Close";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@material-ui/core/styles";
import Checkbox from '@material-ui/core/Checkbox';
import FormControl from "@material-ui/core/FormControl";
import Grid from "@material-ui/core/Grid";
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";
import { SpinnerCircular } from "spinners-react";
import CloudDownloadIcon from "@material-ui/icons/CloudDownload";
import toastr from "toastr";

import UserService from "../../Services/UserService";
import ReportsService from "../../Services/ReportsService";
import InputAdornment from '@material-ui/core/InputAdornment';
import CalendarTodayIcon from '@material-ui/icons/CalendarToday';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import environment from "../../../../../libs/environment";

import Popover from '@material-ui/core/Popover';
import {  format, startOfMonth, endOfMonth, eachDayOfInterval, subMonths, addMonths } from 'date-fns';

const useStyles2 = makeStyles(theme => ({
	label: {
	  transform: 'translate(0px, -6px) scale(0.75) !important'
	},
	root: {
		'& .MuiInput-underline:before': {
		  display: 'none',
		},
		'& .MuiInput-underline:after': {
		  display: 'none',
		},
		'& .MuiInputBase-root': {
		  borderRadius: '4px',
		  backgroundColor: '#fff',
		  border: '2px solid #ccc',
		}
	  }
  }));

function DatePicker({label, name, onChange}) {
	const classes = useStyles2();

	const [anchorEl, setAnchorEl] = useState(null);
	const [selectedDate, setSelectedDate] = useState('');
	const [currentMonth, setCurrentMonth] = useState(new Date());

	const handleOpen = (event) => {
	  	setAnchorEl(event.currentTarget);
	};
  
	const handleClose = () => {
	  	setAnchorEl(null);
	};
  
	const handleDateSelect = (date) => {
		const selectedDate = format(date, 'dd/MM/yyyy');
		setSelectedDate(selectedDate);
		onChange({ target: { name: name, value: selectedDate } });
		handleClose();
	};
  
	const handleMonthChange = (direction) => {
	  	setCurrentMonth(direction === 'next' ? addMonths(currentMonth, 1) : subMonths(currentMonth, 1));
	};
  
	const daysInMonth = () => {
		const start = startOfMonth(currentMonth);
		const end = endOfMonth(currentMonth);
		return eachDayOfInterval({ start, end });
	};
  
	const open = Boolean(anchorEl);
	const id = open ? 'simple-popover' : undefined;
  
	return (
	  <div>
		<TextField
		  label={label}
		  className={classes.root} 
		  value={selectedDate}
		  onClick={handleOpen}
		  placeholder="dd/mm/aaaa"
		  variant="standard"
		  fullWidth
		  InputLabelProps={{
			shrink: true,
			className: classes.label,
		  }}
		  InputProps={{
			endAdornment: (
				<InputAdornment>
				  <CalendarTodayIcon />
				</InputAdornment>
			),
			style:{
				paddingLeft: '10px'
			}
		  }}
		/>
		<Popover
		  id={id}
		  open={open}
		  anchorEl={anchorEl}
		  onClose={handleClose}
		  anchorOrigin={{
			vertical: 'bottom',
			horizontal: 'left',
			width: '300px'
		  }}
		  transformOrigin={{
			vertical: 'top',
			horizontal: 'left',
			width: '300px'

		  }}
		  PaperProps={{
			style: { width: '252px' }
		  }}

		>
		  <div style={{ display: 'flex', justifyContent: 'center', padding: 10}}>
			<IconButton onClick={() => handleMonthChange('previous')}>
				<ArrowUpwardIcon />
			</IconButton>
			<span style={{ margin: '0 10px', alignSelf: 'center' }}>{format(currentMonth, 'MMMM yyyy')}</span>
			<IconButton onClick={() => handleMonthChange('next')}>
			  <ArrowDownwardIcon />
			</IconButton>
		  </div>
		  <div style={{ padding: 10 }}>
			{daysInMonth().map((day, index) => (
			  <Button key={index} onClick={() => handleDateSelect(day)} style={{ margin: 2, minWidth: '36px' }}>
				{format(day, 'd')}
			  </Button>
			))}
		  </div>
		</Popover>
	  </div>
	);
  }


const styles = (theme) => ({
	root: {
		margin: 0,
		padding: theme.spacing(2),
		display: 'flex',
	},
	closeButton: {
		position: "absolute",
		right: theme.spacing(1),
		top: theme.spacing(1),
		color: theme.palette.grey[500],
	},
	textField: {
		width: "50ch",
		marginBottom: "5px",
	},
});

const useStyles = makeStyles((theme) => ({
	dialogTransactionsCsv: {
		display: 'none !important'
	},
	menuContainerDialogContent: {
		display: 'flex',
		width: '800px'	
	},
	menuContainerLeftSide: {
		width: '60%',
		borderStyle: 'solid',
		borderColor: '#BCB8B8',
		borderWidth: '0px 2px 0px 0px'
	},
	menuContainerRightSide: {
		width: '40%',
		paddingLeft: '20px'
	},
	menuContainerDialog: {
		'& .MuiPaper-root': {
			width: '100%',
			maxWidth: '800px', // Set your width here
		},
	},
	usersContainer: {
		marginTop: '20px',
		overflowY: 'auto',
    	height: '300px',
	},
	menuContainerRightSideTitle: {
		fontWeight: '700'
	},
	menuItemsContainerRightSide: {
		marginTop: '20px',
		height: '340px',
    	overflowY: 'auto',
	},
	menuItemsRightSide: {
		backgroundColor: '#EAF0F6',
		borderStyle: 'solid',
		borderWidth: '1px',
		borderColor: '#CBD6E2',
		borderRadius: '4px',
		justifyContent: 'space-between',
    	display: 'flex',
    	padding: '8px 10px',
		marginBottom: '15px',
		marginRight: '20px',
	},
	menuItemsRight: {
		color: '#7D98B6',
		width: '20px',
    	textAlign: 'center',
    	cursor: 'pointer',
	},
	formControl: {
		width: "90%",
		marginTop: "1rem",
	  },
}));

const DialogTitle = withStyles(styles)((props) => {
	const { children, classes, onClose, ...other } = props;
	return (
		<MuiDialogTitle disableTypography className={classes.root} {...other}>
			<Typography variant="h6">{children}</Typography>
			{onClose ? (
				<IconButton
					aria-label="close"
					className={classes.closeButton}
					onClick={onClose}
				>
					<CloseIcon />
				</IconButton>
			) : null}
		</MuiDialogTitle>
	);
});

const DialogContent = withStyles((theme) => ({
	root: {
		padding: theme.spacing(2),
		width: '800px'
	},
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
	root: {
		margin: 0,
		padding: theme.spacing(1),
	},
}))(MuiDialogActions);

export default function DownloadTransactionsReportDialog() {

	const [open, setOpen] = useState(false);
	const [rows, setRows] = useState([]);
	const [users, setUsers] = useState([]);
    const [openSearch, setOpenSearch] = useState(false);
    const [userSelected, setUserSelected] = useState(null);
    const [searchUserInput, setSearchUserInput] = useState('');
    const [loading, setLoading] = useState(false);
	const [dateTo, setDateTo] = useState(null);
	const [dateFrom, setDateFrom] = useState(null);

    const displayUsernameOnAutocomplete = ({ name, lastName, username }) => {

        const fullName = `${name} ${lastName}`;
        const usernameOption = (fullName === ' ') ?
            username
            : (username) ? `${fullName} - ${username}` : fullName;

        return usernameOption;
    }

	const classes = useStyles();

	const { t } = useTranslation();
	
	function handleChangeUsers(event, value) {
        handleChange(value)
        setUserSelected(value);
    }

	const handleClickOpen = () => {
		setOpen(true);

		UserService.getUserAllCompany()
			.then(response => {
				setUsers(response.data.content)
			})

	};
	const handleClose = () => {
		setOpen(false);
	};


	/***********Data Binding Form******* */

	function handleChange(value) {
		value.active = true;
		let valueArray = [...rows];
			
		let indice = valueArray.findIndex((elem) => (elem.id == value.id));

		if(indice == -1) {
			valueArray = [...rows, value];
		}

		setRows([...valueArray]);
	}

	function changeChecked(id) {
		if (id) {
			let valueArray = [...rows];

			let indice = valueArray.findIndex((elem) => (elem.id == id));

			if (indice != -1) {
				valueArray[indice] = {
					id: valueArray[indice].id,
					name: valueArray[indice].name,
					username: valueArray[indice].username,
					active: valueArray[indice].active ? false : true
				};  
			}

			setRows([...valueArray]);

		}
	}

	function handleChangeDate({ target: { name, value } }) {
		if(name == "dateTo") {
		  setDateTo(value)
		} 
		if(name == "dateFrom") {
		  setDateFrom(value)
		}
	  }

	function handleDownloadCSV() {
		let users_in = '';

		rows.filter((elem) => elem.active == true).forEach((elem) => {users_in = users_in + (users_in == '' ? '' : ',') + elem.id});

		
		setLoading(true)


		const ejecutar = new Promise(function(resolve, reject) {
			try {
				let params = {  };
				params._format =  "XLSX";
				params.created_ge = dateFrom;
				params.created_le = dateTo;
				params.users_in = users_in? users_in: null;

				const sesion = JSON.parse(localStorage.getItem("sesion"));
				if (sesion && sesion.token) {
				//add token
				params._token = sesion.token;
				}
				console.info(params);
				let query = Object.keys(params)
				.filter((k) => params[k])
				.map(function (key) {
					return encodeURIComponent(key) + "=" + encodeURIComponent(params[key]);
				})
				.join("&");
				window.open(
				`${environment.motivarnosBackend}/report/execute/MotivarnosAccountTransactions?${query}`
				);
				resolve();
			} catch (error) {
				reject();
			}
		})

		ejecutar
		  .then((response) => {
			
			setOpen(false);
		  })
		  .catch(() => {
			toastr.error(
			  this.props.t(
				"menu.points-coins-message-error-download-transactions",
				"An error occurred while trying to download transactions"
			  )
			);
		  }).finally(()=>{
			setLoading(false)
		  });
		  
	}

	return (
		<>
			<Button
				className="button-table"
				variant="contained"
				color="primary"
				onClick={handleClickOpen}
				style={{width: '100%'}}
			>
			<CloudDownloadIcon className="mr-1" />
				Descargar Transacciones
			</Button>
		 	<Grid item xs={12} md={6} lg={6} className={classes.dialogTransactionsCsv}>
              	<>
					<Dialog
						disableEscapeKeyDown
						open={open}
						onClose={(event, reason) => {
							if (reason !== "backdropClick") {
								handleClose();
							}
						}}
						className={classes.menuContainerDialog}
					>
					
						<DialogTitle id="customized-dialog-title" onClose={handleClose}>
							{t("search.label", "Search")}
						</DialogTitle>

						<DialogContent className={classes.menuContainerDialogContent}>
						{(users.length == 0 || loading) ? (
							<div style={{width: '100%'}}>
								<Grid container spacing={1} style={{justifyContent: "center"}}>
									<SpinnerCircular size={90} />
								</Grid>
							</div>
						) : (
							<>
							<div className={classes.menuContainerLeftSide}>
								<FormControl
									variant="outlined"
									className={classes.formControl}
								>
									<Autocomplete
										filterSelectedOptions
										id="users"
										name="users"
										style={{marginBottom: '30px'}}
										onChange={
											(event, value) =>
												handleChangeUsers(event, value)
										}
										value={userSelected}
										options={users}
										getOptionLabel={(option) =>
											displayUsernameOnAutocomplete(option) ?
												displayUsernameOnAutocomplete(option)
												: t(
													"admin.user-panel-user-dialog-input-select-without-name",
													"Without name"
												)
										}
										open={openSearch}
										onOpen={() => {
											// only open when in focus and searchUserInput great than 3
											if (searchUserInput.length > 2) setOpenSearch(true);
										}}
										onClose={() => {
											if (searchUserInput.length < 3) setOpenSearch(false);
											if (userSelected) setOpenSearch(false);
										}}
										inputValue={searchUserInput}
										onInputChange={(e, value, reason) => {
											setSearchUserInput((prev) => value);
											// only open when searchUserInput is not empty 
											// after the user typed something
											if (value.length < 3 && openSearch) setOpenSearch(false);
											if (value.length > 2) setOpenSearch(true);
										}}
										renderInput={(params) => (
											<TextField {...params}
												label={
													t(
														"menu.trivia-panel-dialog-add-test-select-players",
														"Players"
													)
												}
												placeholder={t("write-search.label", "Write your search")}
												variant="outlined"
											/>
										)}
									/>
									<Grid container spacing={1} style={{justifyContent: "center"}}>
										<Grid item xs={6} style={{display: 'flex'}}>
											<DatePicker label="Fecha de comienzo" name="dateFrom" onChange={handleChangeDate}/>
										</Grid>
										<Grid item xs={6} style={{display: 'flex'}}>
											<DatePicker label="Fecha de fin" name="dateTo" onChange={handleChangeDate}/>
										</Grid>
									</Grid>
								</FormControl>
							</div>
							<div className={classes.menuContainerRightSide}>
								<div className={classes.menuContainerRightSideTitle}>SELECCIONADOS ({rows.length})</div>
								{rows.length > 0 && (
									<div className={classes.menuItemsContainerRightSide}>
										{rows.filter((elem) => (elem.active == true)).map((elem) => (
											<div className={classes.menuItemsRightSide}>
												<div>{elem.username}</div>
												<div className={classes.menuItemsRight} onClick={() => changeChecked(elem.id)}>x</div>
											</div>	
										))}
									</div>
								)}
							</div>
							</>)}
						</DialogContent>
						<DialogActions>
							<Button onClick={handleDownloadCSV} color="secondary">
								Descargar
							</Button>
							<Button onClick={handleClose} color="secondary">
								{t("menu.trivia-panel-dialog-add-test-button-close", "Close")}
							</Button>
						</DialogActions>

					</Dialog>
			  	</>
        	</Grid>
		</>
	);
}
