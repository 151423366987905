import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Slide from "@material-ui/core/Slide";
import Dialog from "@material-ui/core/Dialog";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import TablePagination from "@material-ui/core/TablePagination";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import { Box } from "@material-ui/core";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import Container from "@material-ui/core/Container";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import DownloadAllImagesButton from './DownloadAllImagesButton';  // Importar el nuevo componente
import { SpinnerCircular } from "spinners-react";
import Button from "../../../../../Button";
import EmptyMessage from "../../../../../EmptyMessage";
import { useTranslation } from "react-i18next";
import { AppWrapper } from "../../../common/GlobalStyles";
import MissionFileItem from "./MissionFileItem";
import MissionFileDownloader from "./MissionFileDownloader";

import MissionsService from "../../../Services/MissionsService";
import environment from "../../../../../../../src/libs/environment";

import "./styles.scss";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const useStyles = makeStyles((theme) => ({
  appBarSpacer: theme.mixins.toolbar,
  appBar: {
    position: "relative",
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
  container: {
    marginTop: "2rem",
  },
}));

const MissionDetail = (props) => {
  const [fetching, setFetching] = useState(false);
  const classes = useStyles();
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [users, setUsers] = useState(null);
  const [filter, setFilter] = useState("");
  const [userSelected, setUserSelected] = useState(null);
  const [openUser, setOpenUser] = useState(false);
  const [fetchingModal, setFetchingModal] = useState(false);
  const [page, setPage] = useState(0);
  const [totalElements, setTotalElements] = useState(0);
  const [status, setStatus] = useState(null);  
  const [timer, setTimer] = useState(null);
  const [zipUrl, setZipUrl] = useState(null);

  const { t } = useTranslation();

  const STATUS_OPTION = ["TODO", "IN_PROGRESS", "COMPLETED", "ACHIEVED", "UNACHIEVED"]

  const getfileType = (fileContentType) => {
    if (!fileContentType) {return;}
    const fileTypeString = fileContentType.split("/")[0];
    return fileTypeString;
  }

  useEffect(() => {
    if (props.data) {
      setStatus(null);
      setData(props.data);
      if (props.open) {
        openNewMission(); 
      }
      const sesion = JSON.parse(localStorage.getItem("sesion"));
      setZipUrl(`${environment.motivarnosBackend}/mission/downloadfiles/${props.data.id}?_token=${sesion.token}`)

      MissionsService.getUsersMissions(props.data.id, 10, 0)
        .then((resp) => {
          setUsers(resp.data.content);
          setTotalElements(resp.data.totalElements);
        })
        .catch((error) => {
          setFetching(false);
        });
    }
  }, [props.data]);

  const getUserMissions = () => {
    MissionsService.getUsersMissions(props.data.id, 10, 0, '', status)
      .then((resp) => {
        setUsers(resp.data.content);
        setTotalElements(resp.data.totalElements);
      })
      .catch((error) => {
        setFetching(false);
      });
  };

  const getUserMissionsFilter = (filter0 = "") => {
    setFilter(filter0);
    
    clearTimeout(timer);

    const delayDebounceFn = setTimeout(() => {

      setLoading(true);
      
      MissionsService.getUsersMissions(props.data.id, 10, 0, filter0, status)
        .then((resp) => {
          setUsers(resp.data.content);
          setTotalElements(resp.data.totalElements)
          setPage(0)
          setLoading(false);
        })
        .catch((error) => {
          setFetching(false);
          setLoading(false);
        });    }, 1000)
      setTimer(delayDebounceFn);
      
  };

  const handleChange = (event, value) => {
    setPage(value);
    setLoading(true);

    MissionsService.getUsersMissions(props.data.id, 10, value, filter, status)
      .then((resp) => {
        setUsers(resp.data.content);
        setTotalElements(resp.data.totalElements);
        setLoading(false);
      })
      .catch((error) => {
        setFetching(false);
      });
  };


  const handleChangeStatus = (event, { props: { value }}) => {
    setStatus(value);
    setLoading(true);

    MissionsService.getUsersMissions(props.data.id, 10, 0, filter, value)
      .then((resp) => {
        setUsers(resp.data.content);
        setTotalElements(resp.data.totalElements);
        setLoading(false);
      })
      .catch((error) => {
        setFetching(false);
      });
  };

  const handleOpenUser = (data) => {
    if (!openUser) setUserSelected(data);
    else setUserSelected(null);
    setOpenUser(!openUser);
  };
  const downloadFile = (url, filename) => {
    fetch(url)
      .then(response => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.blob();
      })
      .then(blob => {
        const fileExtension = blob.type.split('/')[1];
        const objectUrl = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = objectUrl;
        link.setAttribute('download', `${filename}.${fileExtension}`);
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
      })
      .catch(error => {
        console.error('There was an error downloading the file:', error);
        alert('There was an error downloading the file.');
      });
  };
  
  
  
  
  
  const achieveMission = (data) => {
    let body = { id: data.id };
    setFetchingModal(true);
    
    MissionsService.missionAchieve(body)
      .then(() => {
        setFetchingModal(false);
        setOpenUser(!openUser);
        getUserMissions();
      })
      .catch(() => {
        setFetching(false);
        setFetchingModal(false);
      });
  };

  const unachieveMission = (data) => {
    let body = { id: data.id };
    setFetchingModal(true);
    
    MissionsService.missionUnachieve(body)
      .then(() => {
        setFetchingModal(false);
        setOpenUser(!openUser);
        getUserMissions();
      })
      .catch(() => {
        setFetching(false);
        setFetchingModal(false);
      });
  };
  const resetMission = (data) => {
    let body = { id: data.id };
    setFetchingModal(true);
    MissionsService.resetMission(body)
      .then(() => {
        setFetchingModal(false);
        setOpenUser(!openUser);
        getUserMissions();
      })
      .catch(() => {
        setFetching(false);
        setFetchingModal(false);
      });
  };

  const openNewMission = () => {
    setPage(0);
    setFilter("")
  };
  
  const handleClose = () => {
    setPage(0);
    setFilter("")
  };
  
  return (
    <Dialog
      open={props.open}
      fullScreen
      TransitionComponent={Transition}
      className="new-mission-wrapper"
      onClose={handleClose}
    >
      <AppWrapper>
        <AppBar className={classes.appBar}>
          <Toolbar>
            <IconButton
              edge="start"
              onClick={() => {
                if (!fetching) props.onClose();
                setUsers(null);
                setData(null);
              }}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography variant="h6" className={classes.title}>
              {props.title}
            </Typography>
          </Toolbar>
        </AppBar>
        {data ? (
          <div>
            {users ? (
              <Container maxWidth="lg" className={classes.container}>
              <Grid container spacing={3}>
      <Box display="flex" alignItems="center" width="100%">
        <TextField
          id="outlined-basic"
          label={t("menu.metric-panel-search-name", "Search by name")}
          variant="outlined"
          value={filter}
          name="filter"
          style={{ width: '70%' }}
          defaultValue=""
          onChange={(e) => getUserMissionsFilter(e.target.value)}
        />
        <FormControl variant="outlined" style={{ width: '20%', marginLeft: '2%' }}>
          <InputLabel id="type-select-outlined-label">
            {t("admin.header-dropdown-view-conditions-table-state", "State")}
          </InputLabel>
          <Select
            onChange={handleChangeStatus}
            label={t("menu.trivia-panel-dialog-add-test-select-questions", "Questions")}
          >
            {STATUS_OPTION.map((elem) => (
              <MenuItem value={elem} key={elem}>
                {t("admin.mission-status-" + elem, elem)}{" "}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        {users && users.length > 0 && (
          <a href={zipUrl}>

          <Button
          color="secondary"
          style={{ marginLeft: "1em" }}          
          className="button-table mr-1"
          variant="contained"
          >
            Descargar Imagenes
          </Button>
            </a> // Añadir el botón para descargar todas las imágenes
        )}
      </Box>
                  {loading ? (
                    <span className="spinner-container">
                      <SpinnerCircular />
                    </span>
                  ) : (
                    <TableContainer component={Paper} className="mt-2">
                      <Table aria-label="simple table">
                        <TableHead>
                          <TableRow>
                            <TableCell align="left">
                              {t("user.label", "User")}
                            </TableCell>
                            <TableCell align="left">
                              {t(
                                "admin.header-dropdown-view-conditions-table-state",
                                "State"
                              )}
                            </TableCell>                           
                          </TableRow>
                        </TableHead>

                        <TableBody>
                          {users.map((data, index) => (
                            <TableRow
                              key={index}
                              onClick={() => handleOpenUser(data)}
                            >
                              <TableCell align="left">
                                {data.user.username}
                              </TableCell>

                              <TableCell align="left">{t(`admin.mission-status-${data.status}`,data.status)}</TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                      <TablePagination
                        className="mt-1"
                        rowsPerPageOptions={[5]}
                        component="div"
                        count={totalElements}
                        rowsPerPage={10}
                        page={page} 
                        onPageChange={(event, value) => handleChange(event, value)}
/>
                    </TableContainer>
                  )}
                </Grid>
              </Container>
            ) : (
              <span className="spinner-container">
                <SpinnerCircular />
              </span>
            )}
          </div>
        ) : (
          <span className="spinner-container">
            <SpinnerCircular />
          </span>
        )}
        <Dialog onClose={handleOpenUser} open={openUser}>
          {userSelected ? (
            <div
              style={!userSelected.fileContentType ?
                {height:"600px", width: "600px"}: null}
              className="user-detail-wrapper">
              {userSelected.file ? (
                <>
                <MissionFileDownloader 
                  missionId={userSelected.mission.id}
                  userId={userSelected.user.id}
                  fileURL={userSelected.file} 
                  fileContentType={getfileType(userSelected.fileContentType)} 
                />
                  {/*  <Button
            onClick={() => downloadFile(userSelected.file, 'mission-file')}
            style={{ marginBottom: '10px' }}
          ></Button> */}
                  <div class="mt-2 mb-2">
                    <span>{userSelected.comment}</span>
                  </div>

                  {userSelected.isCompleted?(
                    <div>
                    <Button
                      secondary
                      onClick={() => resetMission(userSelected)}
                      style={{ backgroundColor: "red" }}
                      disabled={fetchingModal}
                    >
                      Resetear
                    </Button>
                    </div>
                  ):(<></>)}
                  {!userSelected.isProcessed?(
                    <>
                  <div>
                  <Button
                    secondary
                    onClick={() => unachieveMission(userSelected)}
                    style={{ backgroundColor: "red" }}
                    disabled={fetchingModal}
                    className="mt-2 mb-2"
                  >
                    {t(
                      "admin.mission-user-mark-unsuccessful",
                      "Mark as unsuccessful"
                    )}
                  </Button>
                  </div>

                  <div>
                  <Button
                    primary
                    onClick={() => achieveMission(userSelected)}
                    disabled={fetchingModal}
                    style={{ backgroundColor: "green" }}
                  >
                    {t(
                      "admin.mission-user-mark-accomplished",
                      "Mark as accomplished"
                    )}
                  </Button>
                  </div>
                  </>
                  ):(<></>)}

                </>
              ) : (
                <>
                  <EmptyMessage />
                  <h3>
                    {t(
                      "admin.mission-user-mark-not-completed",
                      "Mission not completed yet"
                    )}
                  </h3>
                  <Button
                    primary
                    onClick={() => handleOpenUser()}
                    style={{ backgroundColor: "green" }}
                  >
                    {t(
                      "menu.trivia-panel-dialog-add-test-button-close",
                      "Close"
                    )}
                  </Button>
                </>
              )}
            </div>
          ) : (
            <span className="spinner-container">
              <SpinnerCircular />
            </span>
          )}
        </Dialog>
      </AppWrapper>
    </Dialog>
  );
};

MissionDetail.defaultProps = {
  onClose: () => {},
  data: null,
  onUpdate: () => {},
  open: false,
  onUpdate: () => {},
};

export default MissionDetail;
