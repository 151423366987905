import * as usersTypes from "../types/usersTypes";

const {
  RESET_STATE,
  RESET_STATE_SPECIAL,
  RESET_STORE,
  USERS_LIST_REQUEST,
  USERS_LIST_SAVED,
  USERS_LIST_SAVE,
  USERS_LIST_UPDATE,
  USERS_LIST_DELETE,
  USERS_LIST_CHARGING,
  USERS_SAVE_CHARGING,
  USERS_LIST_ERROR,
  USERS_LIST_REQUESTED,
} = usersTypes;

const INITIAL_STATE = {
  users: [],
  error_users: false,
  users_charging: false,
  users_save_charging: false,
  totalElements: 0,
  totalPages: 1,
  pages: [],
  users_requested: [],
  last_name_filtered: '',
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case USERS_LIST_REQUEST:
      return {
        ...state,
        last_name_filtered: action.payload.filter,
        users_charging: false,
        users_requested: (action.payload.filter != undefined)
          ? [...state.users_requested, action.payload]
          : [...state.users_requested],
        users: (([...state.pages].includes(action.payload.number) && action.payload.reset_pages == false) )
          ? [...state.users]
          : action.payload.filter == state.last_name_filtered ? [...state.users, ...action.payload.content] : [...action.payload.content],
        //users: [...state.users, ...action.payload.content],
        totalElements: action.payload.totalElements,
        totalPages: action.payload.totalPages,
        pages: [...state.pages].includes(action.payload.number)
          ? [...state.pages]
          : [...state.pages, action.payload.number],
        error_users: false,
      };

    case USERS_LIST_REQUESTED:
      return {
        ...state,
        users_charging: false,
        users_requested: [...state.users_requested],
        users: [...action.payload.content],
        totalElements: action.payload.totalElements,
        totalPages: action.payload.totalPages,
        pages: [...state.pages].includes(action.payload.number)
          ? [...state.pages]
          : [...state.pages, action.payload.number],
        error_users: false,
      };

    case USERS_LIST_SAVE:
      return {
        ...state,
        users: action.payload,
        error_users: false,
        users_save_charging: false,
        users_charging: false,
      };
    case USERS_LIST_UPDATE:
      return {
        ...state,
        users: action.payload,
        error_users: false,
        users_save_charging: false,
        users_charging: false,
      };
    case USERS_LIST_DELETE:
      return {
        ...state,
        users: [...state.users].filter((user) => user.id !== action.payload),
        users_charging: false,
        totalElements: state.totalElements - 1,
        error_users: false,
      };

    case USERS_LIST_CHARGING:
      return { ...state, users_charging: true, error_users: false };

    case USERS_SAVE_CHARGING:
      return { ...state, users_save_charging: true, error_users: false };

    case USERS_LIST_ERROR:
      return {
        ...state,
        error_users: action.payload,
        users_charging: false,
        users_save_charging: false,
      };

    case USERS_LIST_SAVED:
      return { ...state, users_charging: false, error_users: false };

    case RESET_STATE:
      return {
        ...state,
        error_users: false,
        users_charging: false,
        users_save_charging: false,
      };

    case RESET_STATE_SPECIAL:
      return {
        ...state,
        users: [],
        error_users: false,
        users_charging: false,
        users_save_charging: false,
        totalElements: 0,
        totalPages: 1,
        pages: [],
      };

    case RESET_STORE:
      return {
        ...state,
        users: [],
        error_users: false,
        users_charging: false,
        users_save_charging: false,
        totalElements: 0,
        totalPages: 1,
        pages: [],
      };

    default:
      return state;
  }
};
