import * as feelingsTypes from "../types/feelingsTypes";

const {
  RESET_STATE,
  RESET_STORE,
  FEELINGS_LIST_REQUEST,
  FEELINGS_LIST_SAVED,
  FEELINGS_LIST_CHARGING,
  FEELINGS_LIST_ERROR,
} = feelingsTypes;

const INITIAL_STATE = {
  feelings: [],
  error_feelings: false,
  feelings_charging: false,
  filter: "",
  totalElements: 0,
  totalPages: 1,
  pages: [],
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case FEELINGS_LIST_REQUEST:
      return {
        ...state,
        feelings_charging: false,
        feelings:
          state.filter !== action.payload.filter
            ? [...action.payload.content]
            : [...state.pages].includes(action.payload.number)
            ? [...state.feelings]
            : [...state.feelings, ...action.payload.content],
        totalElements: action.payload.totalElements,
        totalPages: action.payload.totalPages,
        filter: action.payload.filter,
        pages: [...state.pages].includes(action.payload.number)
          ? [...state.pages]
          : [...state.pages, action.payload.number],
        error_feelings: false,
      };

    case FEELINGS_LIST_CHARGING:
      return { ...state, feelings_charging: true, error_feelings: false };

    case FEELINGS_LIST_ERROR:
      return {
        ...state,
        error_feelings: action.payload,
        feelings_charging: false,
        feelings_save_charging: false,
      };

    case FEELINGS_LIST_SAVED:
      return {
        ...state,
        feelings_charging: false,
        error_feelings: false,
      };

    case RESET_STATE:
      return {
        ...state,
        error_feelings: false,
        feelings_charging: false,
        feelings_save_charging: false,
      };

    case RESET_STORE:
      return {
        ...state,
        feelings: [],
        error_feelings: false,
        feelings_charging: false,
        feelings_save_charging: false,
        totalElements: 0,
        totalPages: 1,
        pages: [],
      };

    default:
      return state;
  }
};
