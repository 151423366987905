import React, { useState } from "react";
import toastr from "toastr";
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import CloseIcon from "@material-ui/icons/Close";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import FormControl from "@material-ui/core/FormControl";
import { SpinnerCircular } from "spinners-react";
import Container from "@material-ui/core/Container";
import { useTranslation } from "react-i18next";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import InputLabel from "@material-ui/core/InputLabel";
import Tooltip from "@material-ui/core/Tooltip";
/*********Icons*** */
import IconButton from "@material-ui/core/IconButton";
import AddIcon from "@material-ui/icons/Add";
import EditIcon from "@material-ui/icons/Edit";
import LinkIcon from "@material-ui/icons/Link";
/*******Services******* */
import TestService from "../../Services/TestService";
import FileService from "../../Services/FileService";
/*********Dialog******** */

import DeleteQuestion from "../../common/Dialogs/DeleteQuestion";
import environment from "../../../../../libs/environment";

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  textField: {
    width: "50ch",
    marginBottom: "5px",
  },
  formControl: {
    width: "100%",
    marginTop: "1rem",
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

export default function AddQuestionsDialog(props) {
  const [open, setOpen] = React.useState(false);
  const [loading, setLoading] = useState(false);
  const [textError, setTextError] = useState(false);
  const [isMandatoryError, setIsMandatoryError] = useState(false);
  const [hintError, setHintError] = useState(false);
  const [showHintError] = useState(false);
  const [textAnswerError, setTextAnswerError] = useState(false);
  const [isCorrectAnswerError, setIsCorrectAnswerError] = useState(false);
  const [responseTypeError, setResponseTypeError] = useState(false);
  const [skillAnswerError, setSkillAnswerError] = useState(false);
  const [addSkill, setAddSkill] = useState(false);
  const [poinstAnswerError, setPoinstAnswerError] = useState(false);
  const [imageObject, setImageObject] = useState(null);
  const [pdfObject, setPdfObject] = useState(null);

  const [addSkillError, setAddSkillError] = useState(false);
  const [imageObjectFromDialog, setImageObjectFromDialog] = useState(true);

  const { t } = useTranslation();

  const [
    {
      text,
      isMandatory,
      hint,
      showHint,
      image,
      mediaType,
      videoUrl,
      mediaLink,
      textAnswer,
      isCorrectAnswer,
      isFileAnswer,
      isFreeTextAnswer,
      responseType,
      skillAnswer,
      poinstAnswer,
      arrayAnswers,
      rowsSkills,
      addSkillValue,
    },
    setState,
  ] = useState({
    text: props.text ? props.text : "",
    isMandatory: props.isMandatory ? props.isMandatory : true,
    hint: props.hint ? props.hint : "",
    showHint: props.showHint ? props.showHint : false,
    image: props.hintMediaType === "IMAGE_URL" ? props.hintMediaLink : "",
    mediaType: props.mediaType ? props.mediaType : "",
    videoUrl: props.hintMediaType === "VIMEO_VIDEO" ? props.hintMediaLink : "",
    mediaLink: props.mediaLink ? props.mediaLink : "",
    textAnswer: "",
    isCorrectAnswer: false,
    isFileAnswer: false,
    isFreeTextAnswer: true,
    responseType: 0,
    skillAnswer: "",
    poinstAnswer: "",
    arrayAnswers: props.answers
      ? props.answers.map((a) => {
          a.responseType = a.isCorrectAnswer ? 2 : 3;
          return a;
        })
      : [],
    rowsSkills:
      props.rowsSkills && props.rowsSkills.length > 0 ? props.rowsSkills : [],
    addSkillValue: "",
  });
//
  const handleClickOpen = () => {
    setOpen(true);
    setState((prevState) => ({
      ...prevState,
      responseType: 0,
      //text: "",
      //isMandatory: true,
      //hint: "",
      //showHint: false,
      //image: "",
      //mediaType: "",
      //videoUrl: "",
      //mediaLink: "",
      //textAnswer: "",
      //isCorrectAnswer: false,
      //isFileAnswer: false,
      //isFreeTextAnswer: true,
      //skillAnswer: "",
      //poinstAnswer: "",
      //arrayAnswers: [],
      //skill: "",
      //addSkillValue: "",
    }));
  };
  const handleClose = () => {
    setOriginalState();
  };

  function setOriginalState(type = "close") {
    setState((prevState) => ({
      ...prevState,
      text: props.text ? props.text : "",
      isMandatory: props.isMandatory ? props.isMandatory : true,
      hint: props.hint ? props.hint : "",
      showHint: props.showHint ? props.showHint : false,
      image: props.hintMediaType === "IMAGE_URL" ? props.hintMediaLink : "",
      mediaType: props.mediaType ? props.mediaType : "",
      videoUrl:
        props.hintMediaType === "VIMEO_VIDEO" ? props.hintMediaLink : "",
      mediaLink: props.mediaLink ? props.mediaLink : "",
      textAnswer: "",
      isCorrectAnswer: false,
      isFileAnswer: false,
      isFreeTextAnswer: true,
      skillAnswer: "",
      poinstAnswer: "",
      arrayAnswers: props.answers ? props.answers : [],
      skill: props.skill ? props.skill.id : "",
      addSkillValue: "",
    }));

    if (type === "close") {
      setOpen(false);
    }
  }

  /*************General functions******* */

  function handleSkill({ target: { name, value } }) {
    setState((prevState) => ({ ...prevState, [name]: value }));
  }

  function handleChange({ target: { name, value } }) {
    //setState((prevState) => ({ ...prevState, [name]: value }));

    if (name === "videoUrl") {
      setState((prevState) => ({ ...prevState, image: "" }));
      setImageObject(null);
    }

    if (name === "mediaLink") {
      setState((prevState) => ({ ...prevState, image: "", videoUrl: "" }));
      setImageObject(null);
    }

    if (name === "isCorrectAnswer" && value === "") {
      setState((prevState) => ({
        ...prevState,
        isCorrectAnswer: "",
        skillAnswer: "",
        poinstAnswer: "",
      }));
    } 

    if (name === "responseType") {
      console.log("responseType");
      console.log(responseType);
      console.log(value);
      setState((prevState) => ({
        ...prevState,
        isFreeTextAnswer: value === 0 ? true : false,
        isFileAnswer: value === 1 ? true : false,
        isCorrectAnswer: value === 2 ? true : false,
        skillAnswer: value === 3 ? "" : skillAnswer,
        poinstAnswer: value === 4 ? "" : poinstAnswer,
      }));
    }

    setState((prevState) => ({ ...prevState, [name]: value }));
  }

  function handleChangeImage(e) {
    if (
      e.target.files[0] &&
      e.target.files[0].size < 1000000 &&
      (e.target.files[0].type === "image/jpeg" ||
        e.target.files[0].type === "image/jpg" ||
        e.target.files[0].type === "image/png")
    ) {
      setState((prevState) => ({
        ...prevState,
        image: e.target.files[0],
        videoUrl: "",
        mediaLink: "",
      }));
      setImageObject(e.target.files[0]);
      setImageObjectFromDialog(false);
      readURL(e);
    } else {
      toastr.error(
        t(
          "image-not-allowed",
          "The image does not meet the requirements, less than 1mb (jpg, jpeg or png)"
        )
      );
    }
  }

  function handleChangePdf(e) {
    if (
      e.target.files[0] &&
      e.target.files[0].size < 1000000 &&
      e.target.files[0].type === "application/pdf"
    ) {
      setState((prevState) => ({
        ...prevState,
        mediaType: e.target.files[0],
        image: "",
        videoUrl: "",
      }));
      setPdfObject(e.target.files[0]);
      setImageObjectFromDialog(false);
    } else {
      toastr.error(
        t(
          "pdf-not-allowed",
          "The pdf does not meet the requirements, less than 1mb (pdf)"
        )
      );
    }
  }

  function readURL(e) {
    if (e.target.files && e.target.files[0]) {
      let reader = new FileReader();

      reader.onload = function (e) {
        document
          .getElementById("img-designed")
          .setAttribute("src", e.target.result);
      };
      reader.readAsDataURL(e.target.files[0]);
    }
  }

  function addAnswer() {
    setTextAnswerError(false);
    setIsCorrectAnswerError(false);
    setPoinstAnswerError(false);

    if (!isFreeTextAnswer && !isFileAnswer) {
      if (textAnswer === "") {
        setTextAnswerError(true);
        toastr.error(
          t(
            "menu.trivia-panel-dialog-test-question-add-dialog-message-error-text",
            "You must enter a text"
          )
        );
        return;
      }
    }

    if (isCorrectAnswer === "") {
      setIsCorrectAnswerError(true);
      toastr.error(
        t(
          "menu.trivia-panel-dialog-test-question-add-dialog-message-error-correct",
          "You must enter if it is correct or not"
        )
      );
      return;
    }

     if (isCorrectAnswer === true) {
      if (skillAnswer === "") {
        setSkillAnswerError(true);
        toastr.error(
          t(
            "menu.trivia-panel-dialog-test-question-add-dialog-message-error-skill",
            "You must enter a skill"
          )
        );
        return;
      }

      if (poinstAnswer === "") {
        setPoinstAnswerError(true);
        toastr.error(
          t(
            "menu.trivia-panel-dialog-test-question-add-dialog-message-error-score",
            "You must enter the score"
          )
        );
        return;
      }
    } 

    arrayAnswers.push({
      text: textAnswer,
      isCorrectAnswer: isCorrectAnswer,
      isFileAnswer,
      isFreeTextAnswer,
      responseType,
      points: poinstAnswer,
      skill: skillAnswer && skillAnswer !== "" ? { id: skillAnswer } : null,
    });

    setState((prevState) => ({
      ...prevState,
      textAnswer: "",
      isFileAnswer: false,
      //isFreeTextAnswer: true,
      //isCorrectAnswer: false,
      responseType,
      poinstAnswer: "",
      skillAnswer: "",
    }));

    toastr.success(
      t(
        "menu.trivia-panel-dialog-test-question-add-dialog-message-success-added-answer",
        "Added answer"
      )
    );
  }

  function deleteAnswer(index) {
    arrayAnswers.splice(index, 1);
    setState((prevState) => ({
      ...prevState,
      arrayAnswers: arrayAnswers,
    }));
  }
  /***********Data Binding Form******* */

  function saveSkill() {
    setAddSkillError(false);
    if (addSkillValue === "" || !addSkillValue) {
      setAddSkillError(true);
      toastr.error(
        t(
          "menu.trivia-panel-dialog-test-question-add-dialog-message-error-group",
          "Group name is required"
        )
      );
      return;
    }
    setLoading(true);
    TestService.saveSkill({
      name: addSkillValue,
    })
      .then((response) => {
        let responseData = response.data;
        setState((prevState) => ({
          ...prevState,
          skillAnswer: responseData.id,
          addSkillValue: "",
          rowsSkills: rowsSkills.concat(responseData),
        }));

        toastr.success(
          t(
            "menu.trivia-panel-dialog-test-question-add-dialog-message-success-skill-saved",
            "Skill saved successfully"
          )
        );
        setLoading(false);
        setAddSkill(!addSkill);
      })
      .catch((error) => {
        if (error.response && error.response.status === 422) {
          toastr.error(error.response.data.message);
        }
        toastr.error(
          t(
            "menu.trivia-panel-dialog-test-question-add-dialog-message-error-skill-saved",
            "An error occurred while trying to save the skill"
          )
        );
        setLoading(false);
      });
  }

  function handleChangeSelect(e, index, answer) {
    let vals = arrayAnswers;
    let nameIndex = e.target.name;
    const valueIndex = e.target.value;

    if (nameIndex === "testAnswerQuestions") {
      vals[index] = { ...answer, text: valueIndex };
    } else if (nameIndex === "typeReponseAnswerQuestions") {
      //
      vals[index] = {
        ...answer,
        responseType: valueIndex,
        isFreeTextAnswer: valueIndex === 0 ? true : false,
        isFileAnswer: valueIndex === 1 ? true : false,
        isCorrectAnswer: valueIndex === 2 ? true : false,
      };
      console.log("vals[index]");
      console.log(vals[index]);
    } else if (nameIndex === "numberQuestions") {
      vals[index] = { ...answer, points: valueIndex };
    } else {
      vals[index] = { ...answer, skill: { id: valueIndex } };
    }

    setState((prevState) => ({
      ...prevState,
      arrayAnswers: vals,
    }));
  }

  async function onFormSubmit() {
    setTextError(false);
    setIsMandatoryError(false);
    setHintError(false);
    setResponseTypeError(false);
    if (responseType === 0 && props.typeModal !== "modalEdit") {
      setResponseTypeError(true);
      toastr.error(
        t(
          "menu.trivia-panel-dialog-test-question-add-dialog-message-error-answer",
          "You must enter an answer"
        )
      );
      return;
    }

    if (text === "") {
      setTextError(true);
      document.getElementById("text").focus();
      toastr.error(
        t(
          "menu.trivia-panel-dialog-test-question-add-dialog-message-error-question",
          "You must enter a question"
        )
      );
      return;
    }

    if (isMandatory === "") {
      setIsMandatoryError(true);
      toastr.error(
        t(
          "menu.trivia-panel-dialog-test-question-add-dialog-message-error-correct",
          "You must enter if it is correct or not"
        )
      );
      return;
    }

    if (!isFreeTextAnswer && !isFileAnswer) {
      if (arrayAnswers.length === 0) {
        setHintError(true);
        toastr.error(
          t(
            "menu.trivia-panel-dialog-test-question-add-dialog-message-error-add-response",
            "You must enter at least one answer"
          )
        );
        return;
      }
    }

    setLoading(true);
    let urlImage = "";
    let urlPdf = "";

    if (imageObject) {
      const formData = new FormData();
      formData.append("file", imageObject);
      await FileService.uploadFile(formData, "trivia")
        .then((response) => {
          urlImage = response.data.url;
          toastr.success(
            t(
              "menu.trivia-panel-dialog-test-question-add-dialog-message-success-add-image",
              "Image added successfully"
            )
          );
        })
        .catch((error) => {
          toastr.error(
            t(
              "menu.trivia-panel-dialog-test-question-add-dialog-message-error-add-image",
              "An error occurred while trying to add the image"
            )
          );
        });
    }

    if (pdfObject) {
      const formData = new FormData();
      formData.append("file", pdfObject);
      await FileService.uploadFile(formData, "trivia")
        .then((response) => {
          urlPdf = response.data.url;
          toastr.success(
            t(
              "menu.trivia-panel-dialog-test-question-add-dialog-message-success-add-pdf",
              "PDF added successfully"
            )
          );
        })
        .catch(() => {
          toastr.error(
            t(
              "menu.trivia-panel-dialog-test-question-add-dialog-message-error-add-pdf",
              "An error occurred while trying to add the pdf"
            )
          );
        });
    }

    let json = {
      text: text,
      isMandatory: isMandatory,
      hint: hint,
      answers: arrayAnswers,
      hintMediaLink:
        urlPdf || mediaLink
          ? ""
          : urlImage && urlImage !== ""
          ? urlImage
          : videoUrl && videoUrl !== ""
          ? videoUrl
          : props.hintMediaLink,
      hintMediaType: urlPdf
        ? "PDF"
        : mediaLink
        ? "URL"
        : urlImage
        ? "IMAGE_URL"
        : videoUrl
        ? "VIMEO_VIDEO"
        : "NONE",
      showHint: hint && hint !== "" ? showHint : "",
      mediaLink: urlPdf ? urlPdf : mediaLink ? mediaLink : "",
      mediaType: urlPdf
        ? "PDF"
        : mediaLink
        ? "URL"
        : urlImage
        ? "IMAGE_URL"
        : videoUrl
        ? "VIMEO_VIDEO"
        : "NONE",
      trivia: {
        id: props.typeModal === "modalEdit" ? props.trivia.id : props.id,
      },
    };

    if (props.typeModal === "modalEdit") {
      TestService.updateTestQuestion({ ...json, id: props.id })
        .then(() => {
          setLoading(false);
          toastr.success(
            t(
              "menu.trivia-panel-dialog-test-question-add-dialog-message-success-add-question",
              "Question saved successfully"
            )
          );
          props.getTestsQuestions();
          handleClose();
        })
        .catch((error) => {
          if (error.response.data.message === "invalid.duplicatedname") {
            toastr.error(
              t(
                "menu.trivia-panel-dialog-test-question-add-dialog-message-error-add-question-one",
                "There is already a question with this name"
              )
            );
          } else {
            toastr.error(
              t(
                "menu.trivia-panel-dialog-test-question-add-dialog-message-error-add-question-two",
                "An error occurred while trying to save the question"
              )
            );
          }
          setLoading(false);
        });
    } else {
      TestService.saveTestQuestion(json)
        .then(() => {
          toastr.success(
            t(
              "menu.trivia-panel-dialog-test-question-add-dialog-message-error-add-question",
              "Question saved successfully"
            )
          );
          setLoading(false);
          setOriginalState("notClose");
          if(props.typeView== "addQuestion") {
            props.getTestsQuestions();
          }
        })
        .catch((error) => {
          if (error.response.data.message === "invalid.duplicatedname") {
            toastr.error(
              t(
                "menu.trivia-panel-dialog-test-question-add-dialog-message-error-add-question-one",
                "There is already a question with this name"
              )
            );
          } else {
            toastr.error(
              t(
                "menu.trivia-panel-dialog-test-question-add-dialog-message-error-add-question-two",
                "An error occurred while trying to save the question"
              )
            );
          }
          setLoading(false);
          setOriginalState();
        });
    }
  }

  return (
    <>
      <>
        {props.typeView === "testQuestions" && (
          <Tooltip
            title={t(
              "menu.trivia-panel-table-actions-edit-question",
              "Edit question"
            )}
          >
            <EditIcon
              fontSize="small"
              className="cursor-pointer"
              onClick={handleClickOpen}
            />
          </Tooltip>
        )}

        {props.typeView === "addQuestion" && (
          <Button
            className="button-table mt-2"
            variant="contained"
            color="primary"
            onClick={handleClickOpen}
          >
            {t(
              "menu.trivia-panel-table-actions-add-question",
              "Add question"
            )}
          </Button>
        )}

        {!props.typeView && (
          <Tooltip
            title={t(
              "menu.trivia-panel-table-actions-add-question",
              "Add question"
            )}
          >
            <AddIcon
              fontSize="small"
              className="cursor-pointer"
              onClick={handleClickOpen}
            />
          </Tooltip>
        )}

        <Dialog
          maxWidth="sm"
          fullWidth={true}
          onClose={handleClose}
          aria-labelledby="customized-dialog-title"
          open={open}
        >
          <DialogTitle id="customized-dialog-title" onClose={handleClose}>
            {props.typeModal === "modalEdit"
              ? t(
                  "menu.trivia-panel-dialog-test-question-add-dialog-edit-title",
                  "Edit question"
                )
              : t(
                  "menu.trivia-panel-dialog-test-question-add-dialog-add-title",
                  "Add question"
                )}
          </DialogTitle>

          <>
            <DialogContent dividers>
              {loading && (
                <div className="div-spinner-modal">
                  <Container maxWidth="lg">
                    {" "}
                    <Grid container spacing={1}>
                      <SpinnerCircular size={90} />
                    </Grid>
                  </Container>
                </div>
              )}

              {!loading && (
                <Grid container spacing={1} className="grid-test">
                  <h3 className="title-modal">
                    {t(
                      "menu.trivia-panel-dialog-test-question-add-dialog-content-subtitle",
                      "Content"
                    )}
                  </h3>
                  <Grid
                    item
                    xs={12}
                    md={12}
                    lg={12}
                    className="padding-custom-button"
                  >
                    <Grid container spacing={1}>
                      <Grid item xs={4} md={8} lg={8}>
                        <div className="h-100">
                          <input
                            id="contained-button-file"
                            className="contained-button-file"
                            type="file"
                            onChange={(e) => handleChangeImage(e)}
                          />
                          <label htmlFor="contained-button-file">
                            <Button
                              variant="contained"
                              color="primary"
                              component="span"
                              className="button-table h-100 w-100"
                            >
                              {image === ""
                                ? t(
                                    "menu.trivia-panel-dialog-add-test-input-image-upload",
                                    "Upload image"
                                  )
                                : t(
                                    "menu.trivia-panel-dialog-add-test-input-image-change",
                                    "Change image"
                                  )}
                            </Button>
                          </label>
                        </div>
                      </Grid>

                      <Grid
                        item
                        xs={12}
                        md={4}
                        lg={4}
                        className="div-img-designed"
                      >
                        {image && image !== "" && !imageObjectFromDialog && (
                          <img
                            id="img-designed"
                            className="img-predesigned"
                            src=""
                            alt=""
                          />
                        )}
                        {image && image !== "" && imageObjectFromDialog && (
                          <img
                            id="img-designed"
                            className="img-predesigned"
                            src={`${environment.motivarnosBackend}${image}`}
                            alt=""
                          />
                        )}
                      </Grid>
                    </Grid>
                  </Grid>

                  <Grid
                    item
                    xs={12}
                    md={12}
                    lg={12}
                    className="padding-custom-button"
                  >
                    <Grid container spacing={1}>
                      <Grid item xs={12} md={10} lg={10}>
                        <div className="h-100">
                          <input
                            id="contained-button-file-pdf"
                            className="contained-button-file"
                            type="file"
                            onChange={(e) => handleChangePdf(e)}
                          />
                          <label htmlFor="contained-button-file-pdf">
                            <Button
                              variant="contained"
                              color="secondary"
                              component="span"
                              className="button-table h-100 w-100"
                            >
                              {mediaType === "NONE" || mediaType === ""
                                ? t(
                                    "menu.trivia-panel-dialog-add-test-input-pdf-upload",
                                    "Upload pdf"
                                  )
                                : t(
                                    "menu.trivia-panel-dialog-add-test-input-pdf-change",
                                    "Change pdf"
                                  )}
                            </Button>
                          </label>
                        </div>
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        md={2}
                        lg={2}
                        className="div-img-designed d-flex"
                      >
                        {mediaType && mediaType !== "" && !pdfObject && (
                          <CheckCircleIcon className="check-icon-pdf" />
                        )}
                        {mediaType && mediaType !== "" && pdfObject && (
                          <CheckCircleIcon className="check-icon-pdf" />
                        )}
                      </Grid>
                    </Grid>
                  </Grid>

                  <Grid item xs={12} md={12} lg={12} className="d-flex">
                    <TextField
                      onChange={handleChange}
                      value={videoUrl}
                      id="videoUrl"
                      name="videoUrl"
                      label={t(
                        "menu.trivia-panel-dialog-test-question-add-dialog-input-video-url",
                        "Video url"
                      )}
                      variant="outlined"
                      className="mt-2"
                      fullWidth
                    />

                    {videoUrl && (
                      <Tooltip
                        title={t(
                          "menu.trivia-panel-dialog-test-question-add-dialog-input-go-url-video",
                          "Go to video"
                        )}
                      >
                        <a
                          target="_blank"
                          without
                          rel="noopener noreferrer"
                          href={videoUrl}
                          className="d-flex mt-2"
                        >
                          <LinkIcon />
                        </a>
                      </Tooltip>
                    )}
                  </Grid>

                  <Grid item xs={12} md={12} lg={12} className="d-flex">
                    <TextField
                      onChange={handleChange}
                      value={mediaLink}
                      id="mediaLink"
                      name="mediaLink"
                      label={t(
                        "menu.trivia-panel-dialog-test-question-add-dialog-input-url",
                        "Url"
                      )}
                      variant="outlined"
                      className="mt-2"
                      fullWidth
                    />

                    {mediaLink && (
                      <Tooltip
                        title={t(
                          "menu.trivia-panel-dialog-test-question-add-dialog-input-go-url",
                          "Go to link"
                        )}
                      >
                        <a
                          target="_blank"
                          without
                          rel="noopener noreferrer"
                          href={mediaLink}
                          className="d-flex mt-2"
                        >
                          <LinkIcon />
                        </a>
                      </Tooltip>
                    )}
                  </Grid>

                  <hr className="hr-styles" />

                  <Grid item xs={12} md={12} lg={12}>
                    <TextField
                      onChange={handleChange}
                      rows={3}
                      multiline
                      inputProps={{ maxLength: 512 }}
                      value={text}
                      error={textError}
                      id="text"
                      name="text"
                      label={t(
                        "menu.trivia-panel-dialog-test-question-table-question",
                        "Question"
                      )}
                      variant="outlined"
                      className="mt-2"
                      fullWidth
                    />
                  </Grid>

                  <Grid item xs={12} md={12} lg={12}>
                    <FormControl variant="outlined">
                      <InputLabel id="isMandatory-select-outlined-label">
                        {t(
                          "menu.trivia-panel-dialog-test-question-add-dialog-input-obligatory",
                          "Obligatory"
                        )}
                      </InputLabel>
                      <Select
                        onChange={handleChange}
                        error={isMandatoryError}
                        value={isMandatory}
                        labelId="isMandatory"
                        id="isMandatory"
                        name="isMandatory"
                        label={t(
                          "menu.trivia-panel-dialog-test-question-add-dialog-input-obligatory",
                          "Obligatory"
                        )}
                      >
                        <MenuItem value={true}>
                          {t(
                            "admin.header-dropdown-dialog-notifications-input-item-yes",
                            "Yes"
                          )}{" "}
                        </MenuItem>
                        <MenuItem value={false}>
                          {t(
                            "admin.header-dropdown-dialog-notifications-input-item-no",
                            "No"
                          )}
                        </MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>

                  <Grid item xs={12} md={12} lg={12}>
                    <TextField
                      onChange={handleChange}
                      rows={3}
                      multiline
                      inputProps={{ maxLength: 280 }}
                      value={hint}
                      error={hintError}
                      id="hint"
                      name="hint"
                      label={t(
                        "menu.trivia-panel-dialog-test-question-table-clue",
                        "Clue"
                      )}
                      variant="outlined"
                      className="mt-2"
                      fullWidth
                    />
                  </Grid>

                  <Grid item xs={12} md={12} lg={12}>
                    <FormControl variant="outlined">
                      <InputLabel id="showHint-select-outlined-label">
                        {t(
                          "menu.trivia-panel-dialog-test-question-add-dialog-input-show-clue",
                          "Show clue"
                        )}
                      </InputLabel>
                      <Select
                        onChange={handleChange}
                        error={showHintError}
                        value={showHint}
                        labelId="showHint"
                        id="showHint"
                        name="showHint"
                        label={t(
                          "menu.trivia-panel-dialog-test-question-add-dialog-input-show-clue",
                          "Show clue"
                        )}
                      >
                        <MenuItem value={true}>
                          {t(
                            "admin.header-dropdown-dialog-notifications-input-item-yes",
                            "Yes"
                          )}{" "}
                        </MenuItem>
                        <MenuItem value={false}>
                          {t(
                            "admin.header-dropdown-dialog-notifications-input-item-no",
                            "No"
                          )}
                        </MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>

                  <hr className="hr-styles" />

                  <Grid item xs={12} md={12} lg={12}>
                    <FormControl variant="outlined">
                      <InputLabel id="responseType-select-outlined-label">
                        Tipo de respuesta
                      </InputLabel>
                      <Select
                        onChange={handleChange}
                        value={responseType}
                        error={responseTypeError}
                        labelId="responseType"
                        id="responseType"
                        name="responseType"
                        label="Tipo de respuesta"
                      >
                        {/*  <MenuItem value={0}>
                          Texto Libre{" "}
                        </MenuItem>
                        <MenuItem value={1}>
                          Archivo
                        </MenuItem> */}
                        <MenuItem value={2}>correcta</MenuItem>  
                        <MenuItem value={3}>incorrecta</MenuItem>
                                           
                      </Select>
                    </FormControl>
                  </Grid>

                  {isFreeTextAnswer ||
                    (!isFileAnswer && (
                      <Grid item xs={12} md={12} lg={12}>
                        <TextField
                          onChange={handleChange}
                          rows={3}
                          multiline
                          inputProps={{ maxLength: 280 }}
                          value={textAnswer}
                          error={textAnswerError}
                          id="textAnswer"
                          name="textAnswer"
                          label={t(
                            "menu.trivia-panel-dialog-test-question-add-dialog-input-text-answer",
                            "Answer"
                          )}
                          variant="outlined"
                          className="mt-2"
                          fullWidth
                        />
                      </Grid>
                    ))}

                  {isCorrectAnswer &&
                    (!addSkill ? (
                      <Grid item xs={12} md={12} lg={12}>
                        <Button
                          variant="contained"
                          color="primary"
                          className="button-group"
                          onClick={() => setAddSkill(!addSkill)}
                        >
                          <AddIcon />
                          {t(
                            "menu.trivia-panel-dialog-test-question-add-dialog-input-text-skill",
                            "Skill"
                          )}
                        </Button>
                        <FormControl variant="outlined">
                          <InputLabel id="skillAnswer-select-outlined-label">
                            {t(
                              "menu.trivia-panel-dialog-test-question-add-dialog-input-text-skill",
                              "Skill"
                            )}
                          </InputLabel>
                          <Select
                            onChange={handleChange}
                            value={skillAnswer}
                            error={skillAnswerError}
                            labelId="skillAnswer"
                            id="skillAnswer"
                            name="skillAnswer"
                            label={t(
                              "menu.trivia-panel-dialog-test-question-add-dialog-input-text-skill",
                              "Skill"
                            )}
                          >
                            {rowsSkills.map((skill) => (
                              <MenuItem key={skill.id} value={skill.id}>
                                {skill.name
                                  ? skill.name
                                  : t(
                                      "admin.user-panel-user-dialog-input-select-without-name",
                                      "Without name"
                                    )}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </Grid>
                    ) : (
                      <>
                        <TextField
                          error={addSkillError}
                          value={addSkillValue}
                          name="addSkillValue"
                          id="addSkillValue"
                          label={t(
                            "menu.trivia-panel-dialog-test-question-add-dialog-input-text-skill-name",
                            "Skill name"
                          )}
                          variant="outlined"
                          className="mt-2"
                          fullWidth
                          onChange={handleSkill}
                        />

                        <Button
                          variant="contained"
                          color="secondary"
                          className="button-table button-group"
                          onClick={() => setAddSkill(!addSkill)}
                        >
                          {t(
                            "admin.header-dropdown-dialog-actions-cancel",
                            "Cancel"
                          )}
                        </Button>

                        <Button
                          variant="contained"
                          color="primary"
                          className="button-table button-group ml-1"
                          onClick={saveSkill}
                        >
                          {t(
                            "admin.header-dropdown-dialog-actions-save",
                            "Save"
                          )}
                        </Button>
                      </>
                    ))}

                  {isCorrectAnswer && (
                    <Grid item xs={12} md={12} lg={12}>
                      <TextField
                        onChange={handleChange}
                        value={poinstAnswer}
                        error={poinstAnswerError}
                        id="poinstAnswer"
                        name="poinstAnswer"
                        label={t("points.label", "Points")}
                        type="number"
                        variant="outlined"
                        className="mt-2"
                        fullWidth
                      />
                    </Grid>
                  )}

                  <Button
                    variant="contained"
                    color="secondary"
                    className="mt-2"
                    onClick={() => addAnswer()}
                  >
                    {t(
                      "menu.trivia-panel-table-actions-add-answer",
                      "Add answer"
                    )}
                  </Button>

                  {arrayAnswers && arrayAnswers.length > 0 && (
                    <>
                      <hr className="hr-styles" />
                      {arrayAnswers.map((answer, index) => (
                        <Grid
                          container
                          spacing={1}
                          className="grid-test"
                          key={index}
                        >
                          <Grid item xs={6} md={6} lg={6}>
                            <TextField
                              onChange={(e) =>
                                handleChangeSelect(e, index, answer)
                              }
                              value={answer.text}
                              id={`testAnswerQuestions${index}`}
                              name="testAnswerQuestions"
                              label={t(
                                "menu.trivia-panel-dialog-test-question-table-question",
                                "Question"
                              )}
                              type="text"
                              variant="outlined"
                              className="mt-2"
                              fullWidth
                            />
                          </Grid>

                          <Grid item xs={6} md={6} lg={6}>
                            <FormControl variant="outlined">
                              <InputLabel id="typeReponseAnswerQuestions-select-outlined-label">
                                Tipo de respuesta
                              </InputLabel>
                              <Select
                                labelId="typeReponseAnswerQuestions"
                                id="typeReponseAnswerQuestions"
                                name="typeReponseAnswerQuestions"
                                label="Tipo de respuesta"
                                value={answer.responseType}
                                onChange={(e) =>
                                  handleChangeSelect(e, index, answer)
                                }
                              >
                                {/*  <MenuItem value={0}>
                                  Texto Libre{" "}
                                </MenuItem>
                                <MenuItem value={1}>
                                  Archivo
                                </MenuItem> */}
                                <MenuItem value={2}>correcta</MenuItem>
                                <MenuItem value={3}>incorrecta</MenuItem>
                                
                              </Select>
                            </FormControl>
                          </Grid>
                          {answer.isCorrectAnswer && (
                            <>
                              {" "}
                              <Grid item xs={12} md={6} lg={6}>
                                <FormControl variant="outlined">
                                  <InputLabel id="skillAnswerQuestion-select-outlined-label">
                                    {t(
                                      "menu.trivia-panel-dialog-test-question-add-dialog-input-text-skill",
                                      "Skill"
                                    )}
                                  </InputLabel>
                                  <Select
                                    onChange={(e) =>
                                      handleChangeSelect(e, index, answer)
                                    }
                                    value={
                                      answer.skill && answer.skill.id
                                        ? answer.skill.id
                                        : ""
                                    }
                                    labelId="skillAnswerQuestion"
                                    id="skillAnswerQuestion"
                                    name="skillAnswerQuestion"
                                    label={t(
                                      "menu.trivia-panel-dialog-test-question-add-dialog-input-text-skill",
                                      "Skill"
                                    )}
                                  >
                                    {rowsSkills.map((skill) => (
                                      <MenuItem key={skill.id} value={skill.id}>
                                        {skill.name
                                          ? skill.name
                                          : t(
                                              "admin.user-panel-user-dialog-input-select-without-name",
                                              "Without name"
                                            )}
                                      </MenuItem>
                                    ))}
                                  </Select>
                                </FormControl>
                              </Grid>
                              <Grid item xs={6} md={6} lg={6}>
                                <TextField
                                  id="numberQuestions"
                                  name="numberQuestions"
                                  type="number"
                                  label={t("points.label", "Points")}
                                  variant="outlined"
                                  className="mt-2"
                                  onChange={(e) =>
                                    handleChangeSelect(e, index, answer)
                                  }
                                  value={
                                    answer.points
                                      ? answer.points
                                      : answer.poinstAnswer
                                      ? answer.poinstAnswer
                                      : 0
                                  }
                                  fullWidth
                                />
                              </Grid>
                            </>
                          )}

                          <Grid item xs={12} md={12} lg={12}>
                            <DeleteQuestion
                              answer={answer}
                              deleteQuestion={() => deleteAnswer(index)}
                            />
                          </Grid>

                          <hr className="hr-styles" />
                        </Grid>
                      ))}
                    </>
                  )}
                </Grid>
              )}
            </DialogContent>
            <DialogActions>
              <Button
                className="button-table mt-2 mr-1"
                onClick={handleClose}
                color="secondary"
              >
                {t("menu.trivia-panel-dialog-add-test-button-close", "Close")}
              </Button>

              <Button
                className="button-table mt-2"
                color="primary"
                onClick={() => onFormSubmit()}
              >
                {t("admin.header-dropdown-dialog-actions-save", "Save")}
              </Button>
            </DialogActions>
          </>
        </Dialog>
      </>
    </>
  );
}
