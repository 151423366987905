export const RESET_STATE = "RESET_STATE";
export const RESET_STORE = "RESET_STORE";
export const TEAMS_REQUEST = "TEAMS_REQUEST";
export const TEAMS_CHARGING = "TEAMS_CHARGING";
export const TEAMS_ERROR = "TEAMS_ERROR";

export const AWARDS_REQUEST = "AWARDS_REQUEST";
export const AWARDS_CHARGING = "AWARDS_CHARGING";
export const AWARDS_ERROR = "AWARDS_ERROR";

export const IMAGES_REQUEST = "IMAGES_REQUEST";
export const IMAGES_CHARGING = "IMAGES_CHARGING";
export const IMAGES_ERROR = "IMAGES_ERROR";

export const SKILLS_REQUEST = "SKILLS_REQUEST";
export const SKILLS_CHARGING = "SKILLS_CHARGING";
export const SKILLS_ERROR = "SKILLS_ERROR";

export const SECONDARY_TEAMS_REQUEST = "SECONDARY_TEAMS_REQUEST";
export const SECONDARY_TEAMS_CHARGING = "SECONDARY_TEAMS_CHARGING";
export const SECONDARY_TEAMS_ERROR = "SECONDARY_TEAMS_ERROR";

export const USERS_CHARGING = "USERS_CHARGING";
export const USERS_ERROR = "USERS_ERROR";
export const USERS_REQUEST = "USERS_REQUEST";
export const METRICS_CHARGING = "METRICS_CHARGING";
export const METRICS_ERROR = "METRICS_ERROR";
export const METRICS_REQUEST = "METRICS_REQUEST";
export const TESTS_CHARGING = "TESTS_CHARGING";
export const TESTS_ERROR = "TESTS_ERROR";
export const TESTS_REQUEST = "TESTS_REQUEST";

export const BADGES_CHARGING = "BADGES_CHARGING";
export const BADGES_ERROR = "BADGES_ERROR";
export const BADGES_REQUEST = "BADGES_REQUEST";

export const ASSIGN_BADGES_CHARGING = "ASSIGN_BADGES_CHARGING";
export const ASSIGN_BADGES_ERROR = "ASSIGN_BADGES_ERROR";
export const ASSIGN_BADGES_REQUEST = "ASSIGN_BADGES_REQUEST";

export const LETTERSOUP_ERROR = "LETTERSOUP_ERROR";
export const LETTERSOUP_CHARGING = "LETTERSOUP_CHARGING";
export const LETTERSOUP_REQUEST = "SLETTERSOUP_REQUEST";
