import React from "react";

import "./styles.scss";

const AnimateBG = (props) => {
  return (
    <div className={props.playerRoute ? "bg" : "bg bg-custom-padding"}>
      {props.children}
    </div>
  );
};

export default AnimateBG;
