import React, { useEffect, useState } from "react";
import { BrowserRouter, Switch, Route, Redirect } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Grid from "@material-ui/core/Grid";

import { useAuth } from "../public/auth/useAuth";
import StartupModal from "./common/StartupModal/StartupModal";
import ThankYou from "./sections/pages/thankyou/Thankyou";
import UserStepper from "./sections/pages/userEdit/UserStepper";
//import ConfigMenu from "./sections/pages/configMenu/ConfigMenu";
import MetricsModal from "./sections/pages/metrics/MetricsModal";
import AnimateBG from "../../AnimateBG";
import { SpinnerCircular } from "spinners-react";

import Layout from "./components/Layout";
/***********Users********** */
import UserPanel from "./components/Users/UserPanel";
import HallFame from "./components/HallFame/HallFame";

import UserPanelReport from "./components/Users/UserPanelReport";

import Supervisors from "./components/Users/Supervisors";

import UserPanelConditions from "./components/Users/UserPanelConditions";
import ConditionsReport from "./components/Users/ConditionsReport";

import ConfigurationPlayer from "./components/ConfigurationPlayer";

/************Trivia**** */
import TriviaEdit from "../../../Services/Quizzes/Admin/TriviaEdit";
import Report from "./components/Trivia/Report";

/****************Configuration************* */
import Design from "./components/Configuration/Design";

/***********Insignia******* */
import InsigniaReport from "./components/Insignia/InsigniaReport";

/****************Games******************** */
import Fixture from "./components/Games/Fixture";

/*************AloneViews */
import Points from "./components/AloneViews/Points";
import Coins from "./components/AloneViews/Coins";

/**************Ranking************ */
import Ranking from "./components/Ranking/Ranking";

/**************Bets************ */
import Bets from "./components/Bets/Bets";

/**************Metrics************ */
import Metrics from "./components/Metrics/Metrics";
import MetricsReport from "./components/Metrics/MetricsReport";
import MetricsReportCreated from "./components/Metrics/MetricsReportCreated";

//import Landing from "../public/landing/Landing";

/**************Awards************ */
import Awards from "./components/Awards/Awards";
import AwardsApproval from "./components/Awards/AwardsApproval";
import AwardsReport from "./components/Awards/AwardsReport";

/**************Badges************ */
import Badges from "./components/Badges/Badges";
import BadgesReport from "./components/Badges/BadgesReport";

/**************News************ */
import News from "./components/News/News";

/**************Challenges************ */
import Challenges from "./components/Challenges";
import ChallengeUser from "./components/Challenges/ChallengeUser.js";


/**************Challenges************ */
import Missions from "./components/Missions";

/**************Challenges************ */
import Tournament from "./components/Tournament";

/**************Avatar************ */
import Avatar from "./components/Avatar";

/**************Reports************ */
import PointsCoins from "./components/Reports/PointsCoins";
import Feelings from "./components/Reports/Feelings";
import Readed from "./components/News/Readed";

import Notifications from "./components/Reports/Notifications";
import NotificationsVote from "./components/Reports/NotificationsVote";
import MissionsVote from "./components/Reports/MissionsVote";

/**************Test************ */
import Tests from "./components/Tests/Tests";
/************SuggestionInbox**** */
import SuggestionInbox from "./components/SuggestionInbox/SuggestionInbox";

import ResetTest from "./components/Tests/ResetTest";

import TestsQuestions from "./components/Tests/TestsQuestions";
import TestsAnalytic from "./components/Tests/TestsAnalytic";
import TestsConsolidated from "./components/Tests/TestsConsolidated";
import TestSkills from "./components/Tests/TestSkills";

/**************LetterSoup************ */
import LetterSoupPanel from "./components/LetterSoup/LetterSoupPanel";

/*****************Styles****** */
import Styles from "./components/PersonalTheme/Styles";
import Links from "./components/PersonalTheme/Links";

import Dashboard from "./components/Dashboard";

/********Services***** */
import ThemeService from "./Services/ThemeService";
import GeneralService from "./Services/GeneralService";

/********Misc***** */
import styled from "styled-components";
import toastr from "toastr";
import Groups from "./components/Groups/Groups";
import SecondaryGroup from "./components/SecondaryGroup/SecondaryGroup";
import ShareFile from "../../../Pages/ShareFile";

const AppWrapper = styled.div(
  ({ styles }) => `--warm-purple: #fa0050;
--main-blue: #100423;
--light-purple: #00d9fc;
--primary-green: #27a832;
--warm-red: #f84461;
--primary-btn-bg: #fa0050;
--tertiary-color: #0f0422;
--secondary-btn-bg:linear-gradient(180deg, #f947d3 0%, #f94a02 100%);
--main-bg: ${
    styles.backgroundImage
      ? "("
      : "linear-gradient(45deg," +
        (styles && styles.backgroundColor1
          ? styles.backgroundColor1
          : " #ffffff") +
        "," +
        (styles && styles.backgroundColor2
          ? styles.backgroundColor2
          : " #ffffff")
  });

  
.MuiInputBase-formControl{
  background-color: white !important;
  margin-top: 5px !important;
};

.bg-podium-number  { background-color: ${
    styles && styles.primaryColor ? styles.primaryColor : "#fa0050"
  } !important }; 
    
  background-image: url(${styles.backgroundImage});
  background-repeat: no-repeat;
  background-size: cover;

  span ,a ,p ,h1 ,h2 ,h3 ,h4 ,h5 ,label ,li, div  { color: ${
    styles && styles.textColor ? styles.textColor : "#000000"
  } !important };
  
  path  { color: ${
    styles && styles.iconColor ? styles.iconColor : "black"
  } !important };
  `
);

const AdminRoutes = () => {
  const { session } = useAuth();
  const [styles, setStyles] = useState(false);
  const [preferences, setPreferences] = useState(false);

  const { i18n } = useTranslation();

  useEffect(() => {
    getTheme();
    // eslint-disable-next-line
  }, []);

  const getTheme = () => {
    ThemeService.getThemePublic()
      .then((response) => {
        if (response.data && response.data) {
          window.localStorage.setItem("theme", JSON.stringify(response.data));
          setStyles(response.data);
        }
        getPreferences();
      })
      .catch(() => {
        toastr.error("Ha ocurrido un error al intentar obtener el diseño.");
      });
  };

  const getPreferences = () => {
    GeneralService.getPreferences()
      .then((response) => {
        i18n.changeLanguage(response.data.lang ? response.data.lang : "es");
        setPreferences(true);
      })
      .catch(() => {
        toastr.error("Hubo un error al obtener las preferencias.");
      });
  };

  let redirect;
  if (session.algunusuarioflag === 0 && preferences === false) {
    redirect = <Redirect to="/thankyou" />;
  } else if (preferences) {
    return (
      <AppWrapper styles={styles}>
        <AnimateBG styles={styles}>
          <span className="admin-wrapper">
            <BrowserRouter>
              <Layout>
                <Switch>
                  <Route
                    key="inicio"
                    exact
                    path="/"
                    component={Dashboard}
                  ></Route>{" "}
                  <Route
                    key="inicio"
                    exact
                    path="/admin"
                    component={Dashboard}
                  ></Route>{" "}
                  <Route
                    key="UserPanel"
                    exact
                    path="/userPanel"
                    component={UserPanel}
                  ></Route>
                  <Route
                    key="Groups"
                    exact
                    path="/groups"
                    component={Groups}
                  ></Route>
                  <Route
                    key="SecondaryGroups"
                    exact
                    path="/secondarygroups"
                    component={SecondaryGroup}
                  ></Route>
                  <Route
                    key="HallFame"
                    exact
                    path="/hall-fame"
                    component={HallFame}
                  ></Route>
                  <Route
                    key="UserPanelReport"
                    exact
                    path="/report/userPanel"
                    component={UserPanelReport}
                  ></Route>
                  <Route
                    key="Supervisors"
                    exact
                    path="/supervisors"
                    component={Supervisors}
                  ></Route>
                  <Route
                    key="configuration-player"
                    exact
                    path="/configuration/player"
                    component={ConfigurationPlayer}
                  ></Route>
                  <Route
                    key="TriviaTable"
                    exact
                    path="/trivia/report"
                    component={Report}
                  ></Route>
                  <Route
                    key="configuration-design"
                    exact
                    path="/configuration/design"
                    component={Design}
                  ></Route>
                  {/************Insignias***************** */}
                  <Route
                    key="insignia-report"
                    exact
                    path="/insignia/report"
                    component={InsigniaReport}
                  ></Route>
                  {/************Fixture***************** */}
                  <Route
                    key="insignia-report"
                    exact
                    path="/games/fixture"
                    component={Fixture}
                  ></Route>
                  {/************Alone Views***************** */}
                  <Route
                    key="points-accumulated"
                    exact
                    path="/points-accumulated"
                    component={Points}
                  ></Route>
                  <Route
                    key="coins"
                    exact
                    path="/coins"
                    component={Coins}
                  ></Route>
                  {/************Ranking***************** */}
                  <Route
                    key="ranking"
                    exact
                    path="/ranking"
                    component={Ranking}
                  ></Route>
                  {/************Test**************** */}
                  <Route
                    key="test"
                    exact
                    path="/test/panel"
                    component={Tests}
                  ></Route>
                  <Route
                    key="testQuestion"
                    exact
                    path="/test/question"
                    component={TestsQuestions}
                  ></Route>
                  <Route
                    key="shareFile"
                    exact
                    path="/sharefile"
                    component={ShareFile}
                  ></Route>
                  <Route
                    key="testAnalytic"
                    exact
                    path="/test/analytic"
                    component={TestsAnalytic}
                  ></Route>
                  <Route
                    key="testConsolidated"
                    exact
                    path="/test/consolidated"
                    component={TestsConsolidated}
                  ></Route>
                  <Route
                    key="testSkills"
                    exact
                    path="/test/skills"
                    component={TestSkills}
                  ></Route>
                  <Route
                    key="testReset"
                    exact
                    path="/reset/test"
                    component={ResetTest}
                  ></Route>
                  {/************LetterSoup**************** */}
                  <Route
                    key="letter-soup"
                    exact
                    path="/letter/soup"
                    component={LetterSoupPanel}
                  ></Route>
                  {/************Metrics**************** */}
                  <Route
                    key="metrics"
                    exact
                    path="/metrics/panel"
                    component={Metrics}
                  ></Route>
                  <Route
                    key="metricsReport"
                    exact
                    path="/metrics/report"
                    component={MetricsReport}
                  ></Route>
                  <Route
                    key="metricsReportCreated"
                    exact
                    path="/metrics/reportCreated"
                    component={MetricsReportCreated}
                  ></Route>
                  {/************Awards**************** */}
                  <Route
                    key="awards"
                    exact
                    path="/awards/panel"
                    component={Awards}
                  ></Route>
                  <Route
                    key="awardsApproval"
                    exact
                    path="/awards/approval"
                    component={AwardsApproval}
                  ></Route>
                  <Route
                    key="awardsReport"
                    exact
                    path="/awards/report"
                    component={AwardsReport}
                  ></Route>
                  {/************Badges**************** */}
                  <Route
                    key="badges"
                    exact
                    path="/badges/panel"
                    component={Badges}
                  ></Route>
                  <Route
                    key="badgesReport"
                    exact
                    path="/badges/report"
                    component={BadgesReport}
                  ></Route>
                  {/************Ranking**************** */}
                  <Route
                    key="awards"
                    exact
                    path="/ranking/panel"
                    component={Ranking}
                  ></Route>
                  {/************Bets**************** */}
                  <Route
                    key="awards"
                    exact
                    path="/bets/panel"
                    component={Bets}
                  ></Route>
                  {/************Challenges**************** */}
                  <Route
                    key="awards"
                    exact
                    path="/challenges/panel"
                    component={Challenges}
                  ></Route>
                  <Route
                    key="awards"
                    exact
                    path="/challengeuser"
                    component={ChallengeUser}
                  ></Route>
            
                  {/************Challenges**************** */}
                  <Route
                    key="awards"
                    exact
                    path="/missions/panel"
                    component={Missions}
                  ></Route>
                  {/************Conditions**************** */}
                  <Route
                    key="awards"
                    exact
                    path="/terms_and_conditions"
                    component={UserPanelConditions}
                  ></Route>
                  <Route
                    key="userConditions"
                    exact
                    path="/user/conditions"
                    component={ConditionsReport}
                  ></Route>
                  {/************Tournament**************** */}
                  {/************Reports**************** */}
                  <Route
                    key="report-points-coins"
                    exact
                    path="/report/points-coins"
                    component={PointsCoins}
                  ></Route>
                  <Route
                    key="report-feelings"
                    exact
                    path="/report/feelings"
                    component={Feelings}
                  ></Route>
                  <Route
                    key="news-readed"
                    exact
                    path="/news/readed"
                    component={Readed}
                  ></Route>
                  <Route
                    key="report-notifications"
                    exact
                    path="/report/notifications"
                    component={Notifications}
                  ></Route>
                  <Route
                    key="report-notifications-third"
                    exact
                    path="/report/notifications-third"
                    component={NotificationsVote}
                  ></Route>
                  <Route
                    key="suggestion-inbox"
                    exact
                    path="/suggestion-inbox"
                    component={SuggestionInbox}
                  ></Route>
                  <Route
                    key="report-votations"
                    exact
                    path="/report/votations"
                    component={MissionsVote}
                  ></Route>
                  {/************Reports**************** */}
                  {/************News**************** */}
                  <Route key="news" exact path="/news" component={News}></Route>
                  {/************News**************** */}
                  {/***************************PERSONAL STYLES***************** */}
                  <Route
                    key="personal-styles"
                    exact
                    path="/personal-styles"
                    component={Styles}
                  ></Route>
                  <Route
                    key="personal-links"
                    exact
                    path="/personal-links"
                    component={Links}
                  ></Route>
                  {/***************************PERSONAL STYLES***************** */}
                  <Route
                    key="awards"
                    exact
                    path="/tournament/panel"
                    component={Tournament}
                  ></Route>
                  <Route
                    key="awards"
                    exact
                    path="/avatar"
                    component={Avatar}
                  ></Route>
                  <Route key="MetricsModal" exact path="/metricsPanel/creation">
                    <MetricsModal open={true}></MetricsModal>
                  </Route>
                  <Route key="UserStepper" exact path="/userPanel/edition">
                    <UserStepper open={true}></UserStepper>
                  </Route>
                  <Route key="ThankYou" exact path="/thankyou">
                    <StartupModal nextModal="/userPanel/edition" open={true}>
                      <ThankYou></ThankYou>
                    </StartupModal>
                  </Route>
                  <Route key="TriviaTable" exact path="/triviaEdit">
                    <TriviaEdit open={true}></TriviaEdit>
                  </Route>
                </Switch>
              </Layout>
            </BrowserRouter>
          </span>
        </AnimateBG>
      </AppWrapper>
    );
  } else {
    return (
      <div className="div-spinner">
        <Grid container spacing={1}>
          <SpinnerCircular size={90} />
        </Grid>
      </div>
    );
  }
};

export default AdminRoutes;
