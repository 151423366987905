import React, { useState, useEffect } from "react";
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import environment from "../../../../../libs/environment";

import trivia_logo from "../../../../../assets/img/trivia-logo.png";
import novedad_logo from "../../../../../assets/img/novedad-logo.png";
import mision_logo from "../../../../../assets/img/mision-logo.png";

import Grid from "@material-ui/core/Grid";

import { useTranslation } from "react-i18next";

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

export default function ShowImageDialog({
  openModal,
  openModalFunction,
  imageModal,
  videoModal,
  videoFromYoutube
}) {
  const [open, setOpen] = useState(openModal);
  const [width, setWidth] = useState(0);
  const [height, setHeight] = useState(0);

  const { t } = useTranslation();

  const handleClose = () => {
    setOpen(false);
    openModalFunction(false);
  };

  useEffect(() => {
    if(imageModal != 'trivia_logo' && imageModal != 'novedad_logo' && imageModal != 'mision_logo' && videoModal == false) {
      const img = new Image();
      img.src = environment.motivarnosBackend + imageModal;
      setWidth(img.naturalWidth);
      setHeight(img.naturalHeight);
    }
  },[])

  return (
    <div>
      <Dialog
        maxWidth="sm"
        fullWidth={true}
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <DialogContent dividers>
          <Grid container spacing={1}>
            <Grid item xs={12} md={12} lg={12} className="text-center">
              {imageModal == 'trivia_logo' && videoModal == false &&
                <img
                  className="img-new-modal"
                  src={trivia_logo}
                  alt="New"
                />
              }
              {imageModal == 'novedad_logo' && videoModal == false &&
                <img
                  className="img-new-modal"
                  src={novedad_logo}
                  alt="New"
                />
              }
              {imageModal == 'mision_logo' && videoModal == false &&
                <img
                  className="img-new-modal"
                  src={mision_logo}
                  alt="New"
                />
              }
              {imageModal != 'trivia_logo' && imageModal != 'novedad_logo' && imageModal != 'mision_logo' &&  videoModal == false &&
                <img
                  className="img-new-modal"
                  src={environment.motivarnosBackend + imageModal}
                  style={{width: width >= height ? "100%" : "none" }}
                  alt="New"
                />
              }
              {videoModal == true &&
              <>
                {videoFromYoutube ? (
                  <iframe width="420" height="315" src={imageModal}></iframe>
                ) : (
                  <video width="320" height="240" controls>
                    <source src={imageModal} type="video/mp4" />
                  </video>
                )}
               </>
              }
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="secondary">
            {t("menu.trivia-panel-dialog-add-test-button-close", "Close")}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
