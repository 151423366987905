import React, { useState } from "react";
import Button from "@material-ui/core/Button/Button";
import DialogTitle from "../../../common/controls/DialogTitle";
import { Dialog, DialogContent, DialogActions, Container } from "@material-ui/core";
import { FormattedMessage } from "react-intl";
import { buttonStyles } from "../../../styles/styles"
import { Redirect } from "react-router-dom";

const StartupModal = (props) => {
    const [open,setOpen] = useState(true);
    const [redirect,setRedirect] = useState("");

    var buttonClasses = buttonStyles();
    return (
        <>
        {redirect !== "" ? <Redirect to={redirect}></Redirect>: null}
      <Dialog open={open} fullScreen={true}>
          <DialogTitle>
          </DialogTitle>
          <DialogContent>
            {props.children}
          </DialogContent>
          <DialogActions>
            <Container style={{display:"flex", flexDirection:"row", justifyContent:"space-between"}}>

                <Button className={buttonClasses.buttonRed} onClick={()=> setRedirect("/admin")}>
                    <FormattedMessage
                        id="startupModal.close"
                        defaultMessage="Close"
                    />
                </Button>

                <Button className={buttonClasses.buttonPurple} onClick={(e)=> {
                    setOpen(false);
                    setRedirect(props.nextModal);
                }}>
                    <FormattedMessage
                        id="startupModal.continue"
                        defaultMessage="Let's Go!"
                    />
                </Button>
            </Container>  
            
          </DialogActions>
      </Dialog>
      </>
    )
  }

  export default StartupModal;