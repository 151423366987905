import React from "react";
import { es } from "date-fns/locale";
import { withStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import Container from "@material-ui/core/Container";
import Button from "@material-ui/core/Button";
import Pagination from "@material-ui/lab/Pagination";
import toastr from "toastr";
import { SpinnerCircular } from "spinners-react";
import { withTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import CloudDownloadIcon from "@material-ui/icons/CloudDownload";
import DownloadReportMission from "../../common/Dialogs/DownloadReportMission";

import "toastr/build/toastr.min.css";

import GeneralFunctions from "../../libs/GeneralFunctions";

/********Dialogs***** */
import { NotRegistersFound } from "../../common/NotRegistersFound";

/*****Componentes */

/********Services***** */
import ReportsService from "../../Services/ReportsService";
/*********Icons********* */
import ViewListIcon from "@material-ui/icons/ViewList";

const styles = (theme) => ({
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
  },
  fixedHeight: {
    height: 240,
  },
  root: {
    "& > *": {
      marginTop: theme.spacing(2),
    },
  },
  margin: {
    margin: theme.spacing(1),
  },
  textField: {
    width: "50ch",
    marginBottom: "5px",
  },
});

class MissionsVote extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      rows: [],
      numberPages: 1,
      filter: "",
      page: 1,
    };
  }

  componentDidMount() {
    this.getMissionsVote();
  }

  handleChange(event, value) {
    this.setState({ page: value });
    this.getMissionsVote(value);
  }

  /***********GENERAL FUNCTIONS************* */

  getMissionsVote(number = 1, filter = "") {
    this.setState({ filter: filter });
    function createData(id, missionUser, voter, created) {
      return {
        id,
        missionUser,
        voter,
        created,
      };
    }
    this.setState({ loading: true });
    ReportsService.getMissionVote(number)
      .then((response) => {
        this.setState({ rows: [] });
        let rows = [];
        response.data.content.forEach((vote) =>
          rows.push(
            createData(vote.id, vote.missionUser, vote.voter, vote.created)
          )
        );
        this.setState({ rows: rows });
        this.setState({ numberPages: response.data.totalPages });
        this.setState({ loading: false });
      })
      .catch(() => {
        toastr.error(
          this.props.t(
            "menu.new-panel-message-error-votes",
            "An error occurred while trying to get the votes"
          )
        );
        this.setState({ loading: false });
      });
  }

  handleDownloadNotificationsVote() {
    ReportsService.downloadMissionsVote()
      .then((response) => {
        console.log(response.data.toString().replace(/,/g, '\t'))
        this.downloadFile({
          data: response.data,
          fileName: 'notifications_vote.csv', 
          fileType: 'text/csv;charset=utf-8;'//text/xls;charset=utf-8;
        })
      })
      .catch((error) => {
        console.log(error)
        toastr.error(
          this.props.t(
            "menu.new-panel-message-error-votes",
            "An error occurred while trying to get the votes"
          )
        );
      });
  }


  downloadFile({ data, fileName, fileType }) {
    const blob = new Blob([data], { type: fileType })
    const a = document.createElement('a');
    a.download = fileName;
    a.href = URL.createObjectURL(blob)

    const clickEvt = new MouseEvent('click', {
      view: window,
      bubbles: true,
      cancelable: true,
    })
    a.dispatchEvent(clickEvt);
    URL.revokeObjectURL(a.href);
    a.remove()
  }

  getBase64 = (file) => {
    let documentBase64 = '';
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onloadend = () => {
      console.log(reader.result)
      documentBase64 = reader.result;
      // handleDocument(reader.result);
    };
    reader.onerror = error => { };
    return documentBase64;
  };

  render() {
    const { loading, rows, numberPages, page } = this.state;
    const { classes, t, i18n } = this.props;

    return (
      <main className={classes.content}>
        <div className={classes.appBarSpacer} />
        <Container maxWidth="lg" className={classes.container}>
          <Grid container spacing={1}>
            <Link to="/missions/panel">
              <Button
                className="button-table mr-1"
                variant="contained"
                color="secondary"
              >
                <ArrowBackIosIcon className="mr-1" />
                {t(
                  "menu.trivia-panel-dialog-test-question-button-back",
                  "Back"
                )}
              </Button>
            </Link>

            <Button
              className="button-table mr-1"
              variant="contained"
              color="secondary"
            >
              <ViewListIcon className="mr-1" />
              {t("voting-missions", "Voting in Missions")}
            </Button>

            <Button
              className="button-table mr-1"
              variant="contained"
              href=""
              color="secondary"
              onClick={evt => this.handleDownloadNotificationsVote(evt)}
            >
              <CloudDownloadIcon className="mr-1" />
              {t(
                "menu.trivia-panel-dialog-test-analytic-download",
                "Download"
              )}
            </Button>

            <DownloadReportMission/>

          </Grid>

          {loading && (
            <div className="div-spinner">
              <Grid container spacing={1}>
                <SpinnerCircular size={90} />
              </Grid>
            </div>
          )}
          {!loading &&
            (rows && rows.length > 0 ? (
              <Grid container spacing={1} className="mt-1">
                <TableContainer component={Paper}>
                  <Table className={classes.table} aria-label="simple table">
                    <TableHead>
                      <TableRow>
                        <TableCell align="left">
                          {t("votes", "Votes")}
                        </TableCell>
                        <TableCell align="left">
                          {t("name.label", "Name")}
                        </TableCell>

                        <TableCell align="left">
                          {t("user.label", "User")}
                        </TableCell>

                        <TableCell align="left">
                          {t("voter.label", "Voter")}
                        </TableCell>

                        <TableCell align="left">
                          {t(
                            "admin.user-panel-user-dialog-input-secondary-team",
                            "Secondary group"
                          )}
                        </TableCell>

                        <TableCell align="left">
                          {t("menu.points-coins-table-create", "Created")}
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {rows.map((row) => (
                        <TableRow key={row.id}>
                          <TableCell align="left">
                            {row.missionUser.votes
                              ? row.missionUser.votes
                              : "N/A"}
                          </TableCell>

                          <TableCell align="left">
                            {row.missionUser && row.missionUser.mission
                              ? row.missionUser.mission.name
                              : "N/A"}
                          </TableCell>

                          <TableCell align="left">
                            {row.missionUser && row.missionUser.user
                              ? row.missionUser.user.name +
                              " " +
                              row.missionUser.user.lastName
                              : "N/A"}
                          </TableCell>

                          <TableCell align="left">
                            {row.voter
                              ? row.voter.name + " " + row.voter.lastName
                              : "N/A"}
                          </TableCell>

                          <TableCell align="left">
                            {row.missionUser &&
                              row.missionUser.mission &&
                              row.missionUser.mission.secondaryTeams
                              ? row.missionUser.mission.secondaryTeams.map(
                                (team) =>
                                  (team.name
                                    ? team.name
                                    : t(
                                      "admin.user-panel-user-dialog-input-select-without-name",
                                      "Without name"
                                    )) + " "
                              )
                              : "N/A"}
                          </TableCell>

                          <TableCell align="left">
                            {GeneralFunctions.formatNewDate(
                              row.created,
                              es
                            )}
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
                <div className={classes.root}>
                  <Pagination
                    count={numberPages}
                    page={page}
                    color="primary"
                    onChange={(event, value) => this.handleChange(event, value)}
                  />
                </div>
              </Grid>
            ) : (
              <>
                <Grid container spacing={1}>
                  <Grid item xs={12} md={12} lg={12}>
                    <NotRegistersFound />
                  </Grid>
                </Grid>
              </>
            ))}
        </Container>
      </main>
    );
  }
}

export default withTranslation()(
  withStyles(styles, { withTheme: true })(MissionsVote)
);
