import React, { useState } from "react";

import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Typography from "@material-ui/core/Typography";
import SearchIcon from "@material-ui/icons/Search";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TablePagination from "@material-ui/core/TablePagination";
import Grid from "@material-ui/core/Grid";
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import Tooltip from "@material-ui/core/Tooltip";
import { format } from "date-fns";

import { SpinnerCircular } from "spinners-react";
import { useTranslation } from "react-i18next";

import ImportUserService from "../../Services/ImportUserService";

import "toastr/build/toastr.min.css";

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },

  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

const ImportsUser = () => {
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(true);
  const [{
    page,
    rowsPerPage,
    totalElements,
    imports,
  }, setPage] = useState({
    page: 0,
    rowsPerPage: 5,
    imports: [],
    totalElements: 0,
  })

  const { t } = useTranslation();

  const getImports = (page) => {
    setLoading(true)
    ImportUserService.getUsersImported(page, rowsPerPage).then((resp) => {
      setPage((state) => ({
        ...state,
        page: page,
        imports: resp.data.content,
        totalElements: resp.data.totalElements,
      }))
      setLoading(false)
    })
    .catch((error) => {});
  }

  const handleClickOpen = () => {
		setOpen(true);
    getImports(page)
	};

	const handleClose = () => {
		setOpen(false);
	};

  function handleChange(event, value) {
    getImports(value)
  }

  return (
    <div>
      <Button
				className="button-table mr-1"
        variant="contained"
        color="secondary"
				onClick={handleClickOpen}
			>
				<SearchIcon />
        {t("imports", "Imports")}
			</Button>
      
      <Dialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
        scroll="paper"
      >
        <DialogTitle id="customized-dialog-title" onClose={handleClose} style={{minWidth: '300px'}}>
          {t("imports", "Imports")}
        </DialogTitle>
        {loading ? (
          <div className="div-spinner-modal">
            <DialogContent dividers>
              <Grid container spacing={1} style={{height: 'unset'}}>
                <SpinnerCircular size={90} />
              </Grid>
            </DialogContent>
          </div>
        ) : (
          <DialogContent dividers style={{overflow: 'hidden', minWidth: '450px'}} >
            <Grid container spacing={5} className="dialog-mission-custom">
              <TableContainer style={{overflow: 'hidden'}}>
                <Table aria-label="simple table">
                  <TableBody>
                    {imports.map((row) => (
                      <TableRow key={row.id}>
                        <TableCell align="left"  fontSize="small">
                          <div style={{display: "flex",
                                      flexDirection: "column"}}>
                            
                            {row.fileInput? (<a href={row.fileInput} >Archivo Cargado <IconButton
                                aria-label="delete-user"
                              >
                                <CloudDownloadIcon fontSize="small" />
                              </IconButton></a>):(<div/>)}
                          {row.fileOutput? (<a href={row.fileOutput} >Archivo Resultado  <IconButton
                                aria-label="delete-user"
                              >
                                <CloudDownloadIcon fontSize="small" />
                              </IconButton></a>):(<div/>)}
                          <a>Creado por: {row.createdByData.name? `${row.createdByData.name} ${row.createdByData.lastName}` : row.createdByData.username} {format(new Date(row.created), "dd/MM/yyyy HH:mm:ss")}</a>
                          </div>
                        </TableCell>
                      </TableRow>
                      
                    ))}         
                  </TableBody>
                </Table>
                
              </TableContainer>
            </Grid>
          </DialogContent>
        )}
        <DialogActions>
        <div style={{display: "flex",
                                      flexDirection: "column"}}>
        <TablePagination
                  className="mt-1"
                  rowsPerPageOptions={[5]}
                  component="div"
                  count={totalElements}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={(event, value) =>
                    handleChange(event, value)
                  }
                />
          <Button onClick={handleClose} color="secondary">
            {t("menu.trivia-panel-dialog-add-test-button-close", "Close")}
          </Button></div>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default ImportsUser;