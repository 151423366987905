import React, { useEffect, useState } from "react";
import { Button } from '@material-ui/core';
import environment from '../../../../../../libs/environment';

const MissionFileDownloader = ({ fileURL, fileContentType, missionId, userId }) => {
  const [zipUrl, setZipUrl] = useState(null);

  useEffect(() => {
    const sesion = JSON.parse(localStorage.getItem("sesion"));
    setZipUrl(`${environment.motivarnosBackend}/mission/downloadfile/${missionId}/${userId}?_token=${sesion.token}`)
  }, []);

  return (
    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
      {fileContentType === 'image' && (
        <img
          src={environment.motivarnosBackend + fileURL}
          alt="mission-user-answer"
          style={{ width: '100%', marginBottom: '10px' }}
        />
      )}
      {fileContentType === 'audio' && (
        <audio
          controls
          src={environment.motivarnosBackend + fileURL}
          style={{ width: '100%', marginBottom: '10px' }}
        />
      )}
      {fileContentType === 'video' && (
        <video
          controls
          src={environment.motivarnosBackend + fileURL}
          style={{ width: '100%', marginBottom: '10px' }}
        />
      )}
      <a href={zipUrl}>
      <Button
        variant="contained"
        color="primary"
        style={{ marginTop: '10px' }}
      >
        Descargar imágen
      </Button>
      </a>
    </div>
  );
};

export default MissionFileDownloader;
