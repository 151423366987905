import React from "react";
import { withStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import Container from "@material-ui/core/Container";
import toastr from "toastr";
import { SpinnerCircular } from "spinners-react";
import { Link } from "react-router-dom";
import Button from "@material-ui/core/Button";
import "toastr/build/toastr.min.css";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import TablePagination from "@material-ui/core/TablePagination";

import {
  teamsRequest,
  awardsRequest,
  usersRequest,
  secondaryTeamsRequest,
} from "../../../../../actions/generalActions";

import {
  getAwardsRequest,
  deleteAwardRequest,
} from "../../../../../actions/awardsActions";

/*********Dialog********* */
import AwardDialog from "../../common/Dialogs/Award";
import AssignAwardDialog from "../../common/Dialogs/AssignAward";

/*******Icons */
import ViewListIcon from "@material-ui/icons/ViewList";

import environment from "../../../../../libs/environment";
import Delete from "../../common/Dialogs/Delete";
import { NotRegistersFound } from "../../common/NotRegistersFound";

const styles = (theme) => ({
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
  },
  fixedHeight: {
    height: 240,
  },
  root: {
    "& > *": {
      marginTop: theme.spacing(2),
    },
  },
  margin: {
    margin: theme.spacing(1),
  },
  textField: {
    width: "50ch",
    marginBottom: "5px",
  },
});

class Awards extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      rowsPendingAwards: [],
      rowsPerPage: 5,
      page: 0,
    };
  }

  componentDidMount() {
    if (!this.props.generalReducer.users) {
      this.props.usersRequest();
    }
    if (!this.props.generalReducer.teams) {
      this.props.teamsRequest();
    }
    if (!this.props.generalReducer.secondary_teams) {
      this.props.secondaryTeamsRequest();
    }

    if (!this.props.generalReducer.awards) {
      this.props.awardsRequest();
    }

    this.props.getAwardsRequest(1);
  }

  handleChange(event, value) {
    this.setState({ page: value });
    this.props.getAwardsRequest(value + 1);
  }

  /***********CRUD FUNCTIONS************* */

  async deleteAward(id) {
    let status;
    await this.props.deleteAwardRequest(id).then((r) => (status = r));

    if (status === "SUCCESS") {
      toastr.success(
        this.props.t(
          "menu.award-panel-message-success-delete-awards",
          "Award successfully removed"
        )
      );
      return;
    }

    if (status.error && status.error.status === 400) {
      toastr.error(
        this.props.t(
          "menu.award-panel-message-error-delete-awards-one",
          "This award is being used and cannot be removed"
        )
      );
    } else {
      toastr.error(
        this.props.t(
          "menu.award-panel-message-error-delete-awards-two",
          "An error occurred while removing the prize"
        )
      );
    }
  }

  render() {
    const { rowsPerPage, page } = this.state;
    const { classes, t } = this.props;

    return (
      <main className={classes.content}>
        <div className={classes.appBarSpacer} />
        <Container maxWidth="lg" className={classes.container}>
          {(this.props.generalReducer.tests_charging ||
            this.props.generalReducer.secondary_teams_charging ||
            this.props.generalReducer.users_charging ||
            this.props.generalReducer.awards_charging ||
            this.props.awardsReducer.awards_charging) && (
            <div className="div-spinner">
              <Grid container spacing={1}>
                <SpinnerCircular size={90} />
              </Grid>
            </div>
          )}
          {!this.props.generalReducer.tests_charging &&
            !this.props.generalReducer.secondary_teams_charging &&
            !this.props.generalReducer.users_charging &&
            !this.props.generalReducer.awards_charging &&
            !this.props.awardsReducer.awards_charging &&
            (this.props.awardsReducer.awards &&
            this.props.awardsReducer.awards.length > 0 ? (
              <Grid container spacing={1}>
                {this.props.generalReducer.teams &&
                  this.props.generalReducer.users &&
                  this.props.generalReducer.secondary_teams && (
                    <AwardDialog
                      rowTeams={this.props.generalReducer.teams.content}
                      rowSecondaryTeams={
                        this.props.generalReducer.secondary_teams.content
                      }
                      rowsUsers={this.props.generalReducer.users.content}
                      getAwards={() => this.getAwards(page, true)}
                    />
                  )}

                {this.props.generalReducer.users &&
                  this.props.generalReducer.awards &&
                  this.props.generalReducer.awards.content.length > 0 && (
                    <AssignAwardDialog
                      rowsAwards={this.props.generalReducer.awards.content}
                      rowsUsers={this.props.generalReducer.users.content}
                    />
                  )}

                <Link to="/awards/approval">
                  <Button
                    className="button-table mr-1"
                    variant="contained"
                    color="secondary"
                  >
                    <ViewListIcon className="mr-1" />
                    {t("approval.label", "Approval")}/
                    {t("reject.label", "Reject")}
                  </Button>
                </Link>

                <Link to="/awards/report">
                  <Button
                    className="button-table mr-1"
                    variant="contained"
                    color="secondary"
                  >
                    <ViewListIcon className="mr-1" />
                    {t("report.label", "Report")}
                  </Button>
                </Link>

                <TableContainer component={Paper}>
                  <Table className={classes.table} aria-label="simple table">
                    <TableHead>
                      <TableRow>
                        <TableCell align="left">
                          {t("name.label", "Name")}
                        </TableCell>
                        <TableCell align="left">
                          {t(
                            "menu.trivia-panel-table-description",
                            "Description"
                          )}
                        </TableCell>
                        <TableCell align="left">
                          {t("points.label", "Points")}
                        </TableCell>
                        <TableCell align="left">
                          {t("menu.award-panel-table-stock", "Stock")}
                        </TableCell>
                        <TableCell align="left">
                          {t(
                            "menu.award-panel-table-actual-stock",
                            "Actual stock"
                          )}
                        </TableCell>

                        <TableCell align="left">
                          {t(
                            "admin.header-dropdown-view-conditions-table-state",
                            "State"
                          )}
                        </TableCell>
                        <TableCell align="left">
                          {t("image.label", "Image")}
                        </TableCell>
                        <TableCell align="left">
                          {t("admin.user-panel-table-actions", "Actions")}
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {this.props.awardsReducer.awards
                        .slice(
                          page * rowsPerPage,
                          page * rowsPerPage + rowsPerPage
                        )
                        .map((row) => (
                          <TableRow key={row.id}>
                            <TableCell align="left">
                              {row.name.length > 30
                                ? row.name.substring(0, 30) + "..."
                                : row.name}
                            </TableCell>

                            <TableCell align="left">
                              {row.description.length > 30
                                ? row.description.substring(0, 30) + "..."
                                : row.description}
                            </TableCell>

                            <TableCell align="left">{row.price}</TableCell>
                            <TableCell align="left">
                              {row.isStock
                                ? t("menu.award-panel-table-limited", "Limited")
                                : t(
                                    "menu.award-panel-table-unlimited",
                                    "Unlimited"
                                  )}
                            </TableCell>

                            <TableCell align="left">
                              {row.actualStock}
                            </TableCell>
                            <TableCell align="left">
                              {row.isActive
                                ? t(
                                    "admin.header-dropdown-view-conditions-table-active-state",
                                    "Active"
                                  )
                                : t(
                                    "menu.trivia-panel-table-inactive",
                                    "Inactive"
                                  )}
                            </TableCell>
                            <TableCell align="left">
                              {row.image && row.image !== "" ? (
                                <img
                                  className="img-rounded"
                                  src={`${environment.motivarnosBackend}${row.image}`}
                                  alt="Awards"
                                />
                              ) : (
                                "N/A"
                              )}
                            </TableCell>

                            <TableCell align="left">
                              {this.props.generalReducer.teams &&
                                this.props.generalReducer.users &&
                                this.props.generalReducer.secondary_teams && (
                                  <AwardDialog
                                    rowTeams={
                                      this.props.generalReducer.teams.content
                                    }
                                    rowSecondaryTeams={
                                      this.props.generalReducer.secondary_teams
                                        .content
                                    }
                                    rowsUsers={
                                      this.props.generalReducer.users.content
                                    }
                                    {...row}
                                    getAwards={() => this.getAwards(page, true)}
                                    typeModal="modalEdit"
                                  />
                                )}

                              <Delete
                                title={t(
                                  "menu.award-panel-award-management",
                                  "Award management"
                                )}
                                deleteMetric={() => this.deleteAward(row.id)}
                              />
                            </TableCell>
                          </TableRow>
                        ))}
                    </TableBody>
                  </Table>
                  <TablePagination
                    className="mt-1"
                    rowsPerPageOptions={[5]}
                    component="div"
                    count={this.props.awardsReducer.totalElements}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={(event, value) =>
                      this.handleChange(event, value)
                    }
                  />
                </TableContainer>
              </Grid>
            ) : (
              <>
                <Grid container spacing={1}>
                  <Grid item xs={12} md={12} lg={12}>
                    {this.props.generalReducer.teams &&
                      this.props.generalReducer.users &&
                      this.props.generalReducer.secondary_teams && (
                        <AwardDialog
                          rowTeams={this.props.generalReducer.teams.content}
                          rowSecondaryTeams={
                            this.props.generalReducer.secondary_teams.content
                          }
                          rowsUsers={this.props.generalReducer.users.content}
                          getAwards={() => this.getAwards(page, true)}
                        />
                      )}

                    <NotRegistersFound />
                  </Grid>
                </Grid>
              </>
            ))}
        </Container>
      </main>
    );
  }
}

const mapStateToProps = ({ generalReducer, awardsReducer }) => ({
  generalReducer,
  awardsReducer,
});
const mapDispatchToProps = {
  teamsRequest,
  usersRequest,
  awardsRequest,
  secondaryTeamsRequest,
  getAwardsRequest,
  deleteAwardRequest,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(withStyles(styles, { withTheme: true })(Awards)));
