import axios from "axios";
import environment from "../../../libs/environment";

export default {
  SetFeeling: (data) =>
    axios({
      method: "POST",
      url: `${environment.motivarnosBackend}/feelingshistoric`,
      data: data,
    }),
};
