import React from "react";
import Button from "@material-ui/core/Button";
import Popper from "@material-ui/core/Popper";
import { Link } from "react-router-dom";
import MenuItem from "@material-ui/core/MenuItem";
import MenuList from "@material-ui/core/MenuList";
import Paper from "@material-ui/core/Paper";
import Grow from "@material-ui/core/Grow";
import gear from "../../../../assets/img/gear.svg";
import { useTranslation } from "react-i18next";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import toastr from "toastr";
import store from "store2";

/*******************Dialog************** */
import MetricPassword from "./Dialogs/MetricPassword";
import MetricPreference from "./Dialogs/MetricPreference";
import MetricClientSecret from "./Dialogs/MetricClientSecret";

/********Services******** */
import UserServices from "../Services/UserService";
import "toastr/build/toastr.min.css";

const TOKEN_LIFE = 2 * 60 * 60 * 1000;

export const DropDownFunctions = () => {
  /**********DropDown User logic************ */

  const [openDropDown, setOpenDropDown] = React.useState(false);
  const anchorRef = React.useRef(null);
  const { t } = useTranslation();

  const handleToggle = () => {
    setOpenDropDown((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (
      event &&
      anchorRef.current &&
      anchorRef.current.contains(event.target)
    ) {
      return;
    }

    setOpenDropDown(false);
  };

  const changeToPlayer = () => {
    UserServices.goToPlayer()
      .then((response) => {
        store.clearAll();
        localStorage.setItem(
          "sesion",
          JSON.stringify({
            ...response.data,
            expiresAt: new Date().getTime() + TOKEN_LIFE,
          })
        );
        console.warn("CAMBIO ROL")
        console.warn(JSON.stringify({
          ...response.data,
          expiresAt: new Date().getTime() + TOKEN_LIFE,
        }))
        store("token", response.data.token);
        store("loggedAs", "USER");
        window.location.replace("/");
      })
      .catch((error) => {
        if (error.response.status === 422) {
          toastr.error(
            t(
              "admin.user-panel-user-profile-player-error",
              "You do not have a gamer profile"
            )
          );
        } else {
          toastr.error(
            t(
              "admin.user-panel-user-profile-player",
              "An error occurred while trying to switch players"
            )
          );
        }

        handleClose();
      });
  };

  const handleClickAway = (e) => {
    if (e && e.target.nodeName !== "BODY") {
      setOpenDropDown(false);
    }
  };

  function handleListKeyDown(event) {
    if (event.key === "Tab") {
      event.preventDefault();
      setOpenDropDown(false);
    }
  }

  // return focus to the button when we transitioned from !open -> open
  const prevOpen = React.useRef(openDropDown);
  React.useEffect(() => {
    if (prevOpen.current === true && openDropDown === false) {
      anchorRef.current.focus();
    }

    prevOpen.current = openDropDown;
  }, [openDropDown]);

  /**********DropDown User logic************ */

  return (
    <>
      <Button
        ref={anchorRef}
        aria-controls={openDropDown ? "menu-list-grow" : undefined}
        aria-haspopup="true"
        onClick={handleToggle}
        className="button-dropDown"
      >
        <span className="icon" style={{ width: "1.5em" }}>
          <img src={gear} alt="" />
        </span>
      </Button>

      <Popper
        open={openDropDown}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === "bottom" ? "center top" : "center bottom",
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={(e) => handleClickAway(e)}>
                <MenuList
                  autoFocusItem={openDropDown}
                  id="menu-list-grow"
                  onKeyDown={handleListKeyDown}
                >
                  <Link to="/personal-styles">
                    <MenuItem onClick={handleClose}>
                      - {t("admin.header-dropdown-design", "Design")}
                    </MenuItem>
                  </Link>

                  <Link to="/personal-links">
                    <MenuItem onClick={handleClose}>
                      - {t("admin.header-dropdown-links", "Links")}
                    </MenuItem>
                  </Link>

                  <MenuItem onClick={changeToPlayer}>
                    -{" "}
                    {t(
                      "admin.user-panel-user-switch-player",
                      "Switch to player"
                    )}
                  </MenuItem>

                  <MenuItem id="metricPassword">
                    <MetricPassword handleClose={() => handleClose()} />
                  </MenuItem>

                  <MenuItem>
                    <MetricClientSecret handleClose={() => handleClose()} />
                  </MenuItem>
                  <MenuItem>
                    <MetricPreference handleClose={() => handleClose()} />
                  </MenuItem>
                  <Link to="/supervisors">
                    <MenuItem onClick={handleClose}>
                      -{" "}
                      {t(
                        "admin.header-dropdown-dialog-supervisors-title",
                        "Supervisors"
                      )}
                    </MenuItem>
                  </Link>

                  <Link to="/terms_and_conditions">
                    <MenuItem onClick={handleClose}>
                      -{" "}
                      {t(
                        "admin.header-dropdown-terms-conditions",
                        "Terms and conditions"
                      )}
                    </MenuItem>
                  </Link>
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </>
  );
};
