export const setClassPosition = (positionIndex) => {
  if (positionIndex === 1) {
    return "first-position";
  } else if (positionIndex === 2) {
    return "second-position";
  } else if (positionIndex === 3) {
    return "third-position";
  } else {
    return "";
  }
};

export const hoursToSeconds = (hours) => {
  let hour = hours.split(":");

  let seconds =
    parseInt(hour[0]) * 3600 + parseInt(hour[1]) * 60 + parseInt(hour[2]);

  return seconds;
};

export const getTime = (triviaAnswerTimeStr) => {
  let hours = triviaAnswerTimeStr.substring(0, 2);
  let minutes = triviaAnswerTimeStr.substring(3, 5);
  let seconds = triviaAnswerTimeStr.substring(6, 8);

  let time = '';
  if(hours != '00') {
    time += hours + ' horas, ';
  }

  if(minutes != '00') {
    time += (minutes[0] == '0' ? minutes[1] : minutes) + ' minutos';
  }

  if(seconds != '00') {
    time += (minutes != '00' ? ' y ': '') + (seconds[0] == '0' ? seconds[1] : seconds) + ' segundos';
  }

  return time
};