import * as metricsTypes from "../types/metricsTypes";

const {
  RESET_STATE,
  RESET_STORE,
  METRICS_LIST_REQUEST,
  METRICS_LIST_SAVED,
  METRICS_LIST_SAVE,
  METRICS_LIST_UPDATE,
  METRICS_LIST_DELETE,
  METRICS_LIST_CHARGING,
  METRICS_SAVE_CHARGING,
  METRICS_LIST_ERROR,
} = metricsTypes;

const INITIAL_STATE = {
  metrics: [],
  error_metrics: false,
  metrics_charging: false,
  metrics_save_charging: false,
  filter: "",
  totalElements: 0,
  totalPages: 1,
  pages: [],
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case METRICS_LIST_REQUEST:
      return {
        ...state,
        metrics_charging: false,
        metrics:
          state.filter !== action.payload.filter
            ? [...action.payload.content]
            : [...state.pages].includes(action.payload.number)
            ? [...state.metrics]
            : [...state.metrics, ...action.payload.content],
        totalElements: action.payload.totalElements,
        totalPages: action.payload.totalPages,
        filter: action.payload.filter,
        pages: [...state.pages].includes(action.payload.number)
          ? [...state.pages]
          : [...state.pages, action.payload.number],
        error_metrics: false,
      };

    case METRICS_LIST_SAVE:
      return {
        ...state,
        metrics: action.payload,
        error_metrics: false,
        metrics_save_charging: false,
      };
    case METRICS_LIST_UPDATE:
      return {
        ...state,
        metrics: action.payload,
        error_metrics: false,
        metrics_save_charging: false,
      };
    case METRICS_LIST_DELETE:
      return {
        ...state,
        metrics: [...state.metrics].filter(
          (test) => test.id !== action.payload
        ),
        metrics_charging: false,
        totalElements: state.totalElements - 1,
        error_metrics: false,
      };

    case METRICS_LIST_CHARGING:
      return { ...state, metrics_charging: true, error_metrics: false };

    case METRICS_SAVE_CHARGING:
      return { ...state, metrics_save_charging: true, error_metrics: false };

    case METRICS_LIST_ERROR:
      return {
        ...state,
        error_metrics: action.payload,
        metrics_charging: false,
        metrics_save_charging: false,
      };

    case METRICS_LIST_SAVED:
      return { ...state, metrics_charging: false, error_metrics: false };

    case RESET_STATE:
      return {
        ...state,
        error_metrics: false,
        metrics_charging: false,
        metrics_save_charging: false,
      };

    case RESET_STORE:
      return {
        ...state,
        metrics: [],
        error_metrics: false,
        metrics_charging: false,
        metrics_save_charging: false,
        totalElements: 0,
        totalPages: 1,
        pages: [],
      };

    default:
      return state;
  }
};
