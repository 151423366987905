import { Button, Container, Dialog, DialogActions, DialogContent, FormControlLabel, makeStyles, Switch } from "@material-ui/core";
import React, { useState } from "react";
import { FormattedMessage } from "react-intl";
import DialogTitle from "../common/controls/DialogTitle";
import { buttonStyles } from "../styles/styles";

const MessageBox = ({open, onClose, title, message}) => {
    const className = useStyles();
    const buttonClasses = buttonStyles();
    return <Dialog open={open} onClose={onClose}>
        <DialogTitle onClose={onClose}>
            <h2 style={{ textAlign: "center" }}>
                {title}
            </h2>
        </DialogTitle>
        <DialogContent>
            <h3 className={className.title} style={{textAlign:"center"}}>
                {message}
            </h3>
        </DialogContent>
        <DialogActions>
            {onClose && <Button 
                        onClick={onClose}
                        className={buttonClasses.buttonPink}>
                    Close
                </Button>}
        </DialogActions>
    </Dialog>
}

const useStyles = makeStyles((theme) => ({

    title: {
        color: "black",
        letterSpacing: 6,
        lineHeight: 1.1,
        fontWeight: "bold"
    }
}));

export default MessageBox;