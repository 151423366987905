import React, { useState } from "react";
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import Typography from "@material-ui/core/Typography";
import toastr from "toastr";
import { SpinnerCircular } from "spinners-react";
import Grid from "@material-ui/core/Grid";
import ReactQuill from "react-quill";
import { modulesQuill, formatsQuill } from "../../libs/CustomConst";

import Tooltip from "@material-ui/core/Tooltip";
import { useTranslation } from "react-i18next";

/*********Icons********* */
import AddIcon from "@material-ui/icons/Add";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import EditIcon from "@material-ui/icons/Edit";

/*******Services******* */
import UserServices from "../../Services/UserService";

import "../../Styles/UserDialog.scss";

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  textField: {
    width: "50ch",
    marginBottom: "5px",
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

export default function TermConditions(props) {
  const [open, setOpen] = React.useState(false);
  const [loading, setLoading] = useState(false);
  const [text, setText] = useState(false);
  const { t } = useTranslation();

  const handleClickOpen = () => {
    setOpen(true);
    getTermsConditions();
  };
  const handleClose = () => {
    setOpen(false);
  };

  /***********ADD Group********** */
  /***********Data Binding Form******* */

  function handleTextFieldChange(e) {
    setText(e);
  }

  function onFormSubmit() {
    if (text === "" || text === "<p><br></p>") {
      toastr.error(
        t(
          "terms-and-conditions-message-input.label",
          "You must enter terms and conditions"
        )
      );
      return;
    }

    setLoading(true);
    UserServices.saveTermsConditions({
      text,
    })
      .then(() => {
        toastr.success(
          t(
            "terms-and-conditions-message-added.label",
            "Added terms and conditions"
          )
        );
        setLoading(false);
        handleClose();
      })
      .catch(() => {
        toastr.error(
          t(
            "terms-and-conditions-message-error-save.label",
            "An error occurred while saving the terms and conditions"
          )
        );
        setLoading(false);
      });
  }

  function getTermsConditions() {
    setLoading(true);
    UserServices.getTermsConditions()
      .then((response) => {
        setText(response.data.text);
        setLoading(false);
      })
      .catch(() => {
        toastr.error(
          t(
            "terms-and-conditions-message-error-get.label",
            "There was an error getting the terms and conditions"
          )
        );
        setLoading(false);
      });
  }

  return (
    <>
      <>
        {props.typeModal === "modalEdit" ? (
          <Tooltip title={t("admin.actions-edit", "Edit")}>
            <EditIcon
              fontSize="small"
              onClick={handleClickOpen}
              className="cursor-pointer"
            />
          </Tooltip>
        ) : (
          <Button
            className="button-table"
            variant="contained"
            color="secondary"
            onClick={handleClickOpen}
          >
            <AddIcon />
            {t("terms-and-conditions.label", "Terms and conditions")}
          </Button>
        )}

        <Dialog
          maxWidth="sm"
          fullWidth={true}
          onClose={handleClose}
          aria-labelledby="customized-dialog-title"
          open={open}
        >
          <DialogTitle id="customized-dialog-title" onClose={handleClose}>
            {t("terms-and-conditions.label", "Terms and conditions")}
          </DialogTitle>

          {loading && (
            <div className="div-spinner-modal">
              <DialogContent dividers>
                <Grid container spacing={1}>
                  <SpinnerCircular size={90} />
                </Grid>
              </DialogContent>
            </div>
          )}
          {!loading && (
            <>
              <DialogContent dividers>
                <ReactQuill
                  theme="snow"
                  modules={modulesQuill}
                  formats={formatsQuill}
                  placeholder="Terminos y condiciones"
                  onChange={handleTextFieldChange}
                  value={text}
                />
              </DialogContent>
              <DialogActions>
                <Button onClick={handleClose} color="secondary">
                  {t("admin.header-dropdown-dialog-actions-cancel", "Cancel")}
                </Button>

                <Button onClick={onFormSubmit} color="primary">
                  {t("admin.header-dropdown-dialog-actions-save", "Save")}
                </Button>
              </DialogActions>
            </>
          )}
        </Dialog>
      </>
    </>
  );
}
