import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import ViewListIcon from "@material-ui/icons/ViewList";
import Button from "@material-ui/core/Button";
import { useTranslation } from "react-i18next";
import Dialog from "@material-ui/core/Dialog";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import { FormControl } from "@material-ui/core";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";

import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import { AppWrapper } from "../GlobalStyles";
import CloseIcon from "@material-ui/icons/Close";
import IconButton from "@material-ui/core/IconButton";
import InputLabel from "@material-ui/core/InputLabel";

import environment from "../../../../../libs/environment";

const useStyles = makeStyles((theme) => ({
  appBar: {
    position: "relative",
  },
  formControl: {
    width: "100%",
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  title: {
    marginLeft: theme.spacing(2),
    color: "black",
    flex: 1,
  },
  root: {
    "& > *": {
      margin: theme.spacing(1),
    },
  },
}));

function ReportButton(props) {
  const classes = useStyles();
  const { t } = useTranslation();
  const [open, setOpen] = React.useState(false);
  const [format, setFormat] = React.useState();

  const handleMisionUserReportOpen = () => {
    setOpen(true);
  };

  const onChangeFormat = (value) => {
    console.info(value);
    setFormat(value.target.value);
  };

  const ejecutar = () => {
    let params = { ...props.params };
    params._format = format ? format : "XLSX";

    const sesion = JSON.parse(localStorage.getItem("sesion"));
    if (sesion && sesion.token) {
      //add token
      params._token = sesion.token;
    }
    console.info(params);
    let query = Object.keys(params)
      .filter((k) => params[k])
      .map(function (key) {
        return encodeURIComponent(key) + "=" + encodeURIComponent(params[key]);
      })
      .join("&");
    window.open(
      `${environment.motivarnosBackend}/report/execute/${props.reportName}?${query}`
    );
    setOpen(false);
  };

  return (
    <>
      <Dialog open={open}>
        <AppWrapper>
          <AppBar className={classes.appBar}>
            <Toolbar>
              <IconButton
                edge="start"
                onClick={() => {
                  setOpen(false);
                }}
                aria-label="close"
              >
                <CloseIcon />
              </IconButton>
              <Typography variant="h6" className={classes.title}>
                {props.title}
              </Typography>
            </Toolbar>
          </AppBar>

          <Container maxWidth="lg" className={classes.container}>
            <Grid container spacing={5}>
              <Grid item xs={12}>
                {props.children}
              </Grid>
              <Grid item xs={12}>
                <FormControl variant="outlined" className={classes.formControl}>
                  <InputLabel id="report-format-label">Formato</InputLabel>
                  <Select
                    error={false}
                    id="format"
                    name="format"
                    fullWidth
                    defaultValue={"XLSX"}
                    onChange={onChangeFormat}
                    labelId="report-format-label"
                  >
                    <MenuItem value={"XLSX"}>
                      {t("admin-reports-format-xlsx", "Excel (*.xlsx)")}
                    </MenuItem>
                    <MenuItem value={"PDF"}>
                      {t("admin-reports-format-xlsx", "Archivo PDF (*.pdf)")}
                    </MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <Button
                  className="button-table mr-1"
                  variant="contained"
                  color="secondary"
                  onClick={ejecutar}
                  fullWidth
                >
                  <ViewListIcon className="mr-1" />
                  Generar Reporte
                </Button>
              </Grid>
            </Grid>
          </Container>
        </AppWrapper>
      </Dialog>

      <Button
        className="button-table mr-1"
        variant="contained"
        color="secondary"
        onClick={handleMisionUserReportOpen}
      >
        <ViewListIcon className="mr-1" />
        {props.title}
      </Button>
    </>
  );
}

export default ReportButton;
