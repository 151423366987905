import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Slide from "@material-ui/core/Slide";
import Dialog from "@material-ui/core/Dialog";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";

import { SpinnerCircular } from "spinners-react";
//import { Dialog as DialogComponent } from '../../../../../Dialog';
import Button from "../../../../Button";
import EmptyMessage from "../../../../EmptyMessage";
import { useTranslation } from "react-i18next";
import { AppWrapper } from "../../common/GlobalStyles";

import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import Container from "@material-ui/core/Container";
import TestService from "../../Services/TestService";

import "./testdetails.scss";
import MissionFileItem from "../Missions/MisionUser/MissionFileItem";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const useStyles = makeStyles((theme) => ({
  appBarSpacer: theme.mixins.toolbar,
  appBar: {
    position: "relative",
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
  container: {
    marginTop: "2rem",
  },
}));

const TestDetail = (props) => {
  const [fetching, setFetching] = useState(false);
  const classes = useStyles();
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [users, setUsers] = useState(null);
  const [filter, setFilter] = useState("");
  const [userSelected, setUserSelected] = useState(null);
  const [openUser, setOpenUser] = useState(false);
  const [fetchingModal, setFetchingModal] = useState(false);

  const { t } = useTranslation();

  const getfileType = (fileContentType) => {
    if (!fileContentType) {return;}
    const fileTypeString = fileContentType.split("/")[0];
    return fileTypeString;
  }

  useEffect(() => {
    if (props.testID) {
      TestService.getTest(props.testID)
        .then((resp) => {
          //setUsers(resp.data.content);
          console.log("resp", resp);
        })
        .catch((error) => {
          console.error(error);
          //setFetching(false);
        });
    }
  }, [props.testID]);

  /*useEffect(() => {
    if (props.data) {
      setData(props.data);

      TestService.getTest(props.testId)
        .then((resp) => {
          setUsers(resp.data.content);
        })
        .catch((error) => {
          setFetching(false);
        });
    }
  }, [props.data]);*/

  /*const getUserMissions = () => {
    TestService.getUsersMissions(props.data.id)
      .then((resp) => {
        setUsers(resp.data.content);
      })
      .catch((error) => {
        setFetching(false);
      });
  };

  const getUserMissionsFilter = (filter = "") => {
    setFilter(filter);
    if (filter && filter.length > 3) {
      setLoading(true);
      TestService.getUsersMissions(props.data.id, filter)
        .then((resp) => {
          setUsers(resp.data.content);
          setLoading(false);
        })
        .catch((error) => {
          setFetching(false);
          setLoading(false);
        });
    } else if (filter.length === 0) {
      getUserMissions();
    }
  };*/

  const handleOpenUser = (data) => {
    if (!openUser) setUserSelected(data);
    else setUserSelected(null);
    setOpenUser(!openUser);
  };

  /*const achieveMission = (data) => {
    let body = { id: data.id };
    setFetchingModal(true);
    TestService.missionAchieve(body)
      .then(() => {
        setFetchingModal(false);
        setOpenUser(!openUser);
        getUserMissions();
      })
      .catch(() => {
        setFetching(false);
        setFetchingModal(false);
      });
  };

  const unachieveMission = (data) => {
    let body = { id: data.id };
    setFetchingModal(true);
    TestService.missionUnachieve(body)
      .then(() => {
        setFetchingModal(false);
        setOpenUser(!openUser);
        getUserMissions();
      })
      .catch(() => {
        setFetching(false);
        setFetchingModal(false);
      });
  };*/
  return (
    <Dialog
      fullScreen 
      TransitionComponent={Transition}
      className="new-mission-wrapper"
      open={props.open}
    >
      <AppWrapper>
        <AppBar className={classes.appBar}>
          <Toolbar>
            <IconButton
              edge="start"
              onClick={() => {
                if (!fetching) props.handleClose();
                setUsers(null);
                setData(null);
              }}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography variant="h6" className={classes.title}>
              {props.title}
            </Typography>
          </Toolbar>
        </AppBar>

        <div>
          <Container maxWidth="lg" className={classes.container}>
            <Grid container spacing={3}>
              <TextField
                id="outlined-basic"
                label={t("menu.metric-panel-search", "Search")}
                variant="outlined"
                value={filter}
                name="filter"
                className="w-100"
                defaultValue=""
                onChange={(e) => console.log(e.target.value)}
              />
                <TableContainer component={Paper} className="mt-2">
                  <Table aria-label="simple table">
                    <TableHead>
                      <TableRow>
                        <TableCell align="left">
                          {t("user.label", "User")}
                        </TableCell>
                        <TableCell align="left">
                          {t(
                            "admin.header-dropdown-view-conditions-table-state",
                            "State"
                          )}
                        </TableCell>
                      </TableRow>
                    </TableHead>

                    <TableBody>
                      <TableRow onClick={() => handleOpenUser(data)}>
                        <TableCell align="left">
                          USERNAME
                        </TableCell>

                        <TableCell align="left">STATUS</TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
            </Grid>
          </Container>
        </div>

        {/*data ? (
          <div>
            {users ? (
              <Container maxWidth="lg" className={classes.container}>
                <Grid container spacing={3}>
                  <TextField
                    id="outlined-basic"
                    label={t("menu.metric-panel-search", "Search")}
                    variant="outlined"
                    value={filter}
                    name="filter"
                    className="w-100"
                    defaultValue=""
                    onChange={(e) => getUserMissionsFilter(e.target.value)}
                  />

                  {loading ? (
                    <span className="spinner-container">
                      <SpinnerCircular />
                    </span>
                  ) : (
                    <TableContainer component={Paper} className="mt-2">
                      <Table aria-label="simple table">
                        <TableHead>
                          <TableRow>
                            <TableCell align="left">
                              {t("user.label", "User")}
                            </TableCell>
                            <TableCell align="left">
                              {t(
                                "admin.header-dropdown-view-conditions-table-state",
                                "State"
                              )}
                            </TableCell>
                          </TableRow>
                        </TableHead>

                        <TableBody>
                          {users.map((data, index) => (
                            <TableRow
                              key={index}
                              onClick={() => handleOpenUser(data)}
                            >
                              <TableCell align="left">
                                {data.user.username}
                              </TableCell>

                              <TableCell align="left">{data.status}</TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  )}
                </Grid>
              </Container>
            ) : (
              <span className="spinner-container">
                <SpinnerCircular />
              </span>
            )}
          </div>
        ) : (
          <span className="spinner-container">
            <SpinnerCircular />
          </span>
        )*/}
        <Dialog onClose={handleOpenUser} open={openUser}>
          {userSelected ? (
            <div
              style={!userSelected.fileContentType ?
                {height:"500px", width: "600px"}: null}
              className="user-detail-wrapper">
              {userSelected.file ? (
                <>
                  <MissionFileItem  fileURL={userSelected.file} 
                    fileContentType={getfileType(userSelected.fileContentType)} 
                  />

                  <div>
                  <Button
                    secondary
                    onClick={() => console.log(userSelected)}
                    style={{ backgroundColor: "red" }}
                    disabled={fetchingModal}
                  >
                    {t(
                      "admin.mission-user-mark-unsuccessful",
                      "Mark as unsuccessful"
                    )}
                  </Button>
                  </div>

                  <div>
                  <Button
                    primary
                    onClick={() => console.log(userSelected)}
                    disabled={fetchingModal}
                    style={{ backgroundColor: "green" }}
                    className="mt-2"
                  >
                    {t(
                      "admin.mission-user-mark-accomplished",
                      "Mark as accomplished"
                    )}
                  </Button>
                  </div>
                </>
              ) : (
                <>
                  <EmptyMessage />
                  <h3>
                    {t(
                      "admin.mission-user-mark-not-completed",
                      "Mission not completed yet"
                    )}
                  </h3>
                  <Button
                    primary
                    onClick={() => handleOpenUser()}
                    style={{ backgroundColor: "green" }}
                  >
                    {t(
                      "menu.trivia-panel-dialog-add-test-button-close",
                      "Close"
                    )}
                  </Button>
                </>
              )}
            </div>
          ) : (
            <span className="spinner-container">
              <SpinnerCircular />
            </span>
          )}
        </Dialog>
      </AppWrapper>
    </Dialog>
  )
}

/*
  return (
    <Dialog
      open={props.open}
      fullScreen
      TransitionComponent={Transition}
      className="new-mission-wrapper"
    >
      <AppWrapper>
        <AppBar className={classes.appBar}>
          <Toolbar>
            <IconButton
              edge="start"
              onClick={() => {
                if (!fetching) props.onClose();
                setUsers(null);
                setData(null);
              }}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography variant="h6" className={classes.title}>
              {props.title}
            </Typography>
          </Toolbar>
        </AppBar>
        {data ? (
          <div>
            {users ? (
              <Container maxWidth="lg" className={classes.container}>
                <Grid container spacing={3}>
                  <TextField
                    id="outlined-basic"
                    label={t("menu.metric-panel-search", "Search")}
                    variant="outlined"
                    value={filter}
                    name="filter"
                    className="w-100"
                    defaultValue=""
                    onChange={(e) => getUserMissionsFilter(e.target.value)}
                  />

                  {loading ? (
                    <span className="spinner-container">
                      <SpinnerCircular />
                    </span>
                  ) : (
                    <TableContainer component={Paper} className="mt-2">
                      <Table aria-label="simple table">
                        <TableHead>
                          <TableRow>
                            <TableCell align="left">
                              {t("user.label", "User")}
                            </TableCell>
                            <TableCell align="left">
                              {t(
                                "admin.header-dropdown-view-conditions-table-state",
                                "State"
                              )}
                            </TableCell>
                          </TableRow>
                        </TableHead>

                        <TableBody>
                          {users.map((data, index) => (
                            <TableRow
                              key={index}
                              onClick={() => handleOpenUser(data)}
                            >
                              <TableCell align="left">
                                {data.user.username}
                              </TableCell>

                              <TableCell align="left">{data.status}</TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  )}
                </Grid>
              </Container>
            ) : (
              <span className="spinner-container">
                <SpinnerCircular />
              </span>
            )}
          </div>
        ) : (
          <span className="spinner-container">
            <SpinnerCircular />
          </span>
        )}
        <Dialog onClose={handleOpenUser} open={openUser}>
          {userSelected ? (
            <div
              style={!userSelected.fileContentType &&
                {height:"500px", width: "600px"}}
              className="user-detail-wrapper">
              {userSelected.file ? (
                <>
                  <MissionFileItem  fileURL={userSelected.file} 
                    fileContentType={getfileType(userSelected.fileContentType)} 
                  />

                  <div>
                  <Button
                    secondary
                    onClick={() => unachieveMission(userSelected)}
                    style={{ backgroundColor: "red" }}
                    disabled={fetchingModal}
                  >
                    {t(
                      "admin.mission-user-mark-unsuccessful",
                      "Mark as unsuccessful"
                    )}
                  </Button>
                  </div>

                  <div>
                  <Button
                    primary
                    onClick={() => achieveMission(userSelected)}
                    disabled={fetchingModal}
                    style={{ backgroundColor: "green" }}
                    className="mt-2"
                  >
                    {t(
                      "admin.mission-user-mark-accomplished",
                      "Mark as accomplished"
                    )}
                  </Button>
                  </div>
                </>
              ) : (
                <>
                  <EmptyMessage />
                  <h3>
                    {t(
                      "admin.mission-user-mark-not-completed",
                      "Mission not completed yet"
                    )}
                  </h3>
                  <Button
                    primary
                    onClick={() => handleOpenUser()}
                    style={{ backgroundColor: "green" }}
                  >
                    {t(
                      "menu.trivia-panel-dialog-add-test-button-close",
                      "Close"
                    )}
                  </Button>
                </>
              )}
            </div>
          ) : (
            <span className="spinner-container">
              <SpinnerCircular />
            </span>
          )}
        </Dialog>
      </AppWrapper>
    </Dialog>
  );
};

TestDetail.defaultProps = {
  onClose: () => {},
  data: null,
  onUpdate: () => {},
  open: false,
  onUpdate: () => {},
};*/

export default TestDetail;
