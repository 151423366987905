import { handleActions } from "redux-actions";

import { createActions } from "redux-actions";
import importCsv from "../services/UserService";

const { createUserFromCSVAction, processStarted } = createActions({
  createUserFromCSVAction: (data) => data,
  processStarted: () => ({}),
});

const createUserFromCSV = (data) => {
  return async (dispatch, getState) => {
    try {
      console.log(data);
      importCsv(
        data.entries,
        data.session.empresa.nombre,
        data.session.token
      ).then((data) => {
        console.log(data);
        dispatch(
          createUserFromCSVAction({
            data: data,
          })
        );
      });
    } catch (error) {
      console.error(error);
    }
  };
};

var defaultState = {};

const usersReducer = handleActions(
  {
    [createUserFromCSVAction]: (state, action) => {
      return { dataToExport: action.payload.data, finishedProcess: true };
    },
    [processStarted]: async (state, action) => {
      return { processStarted: true };
    },
  },
  defaultState
);

const exposedUserReducer = {
  key: "usersReducer",
  value: usersReducer,
};

export {
  createUserFromCSVAction,
  processStarted,
  exposedUserReducer,
  createUserFromCSV,
};
