import axios from "axios";
import environment from "../../../../libs/environment";

export default {
  getTournament: (number = 1, size = 5) =>
    axios({
      method: "GET",
      url: `${environment.motivarnosBackend}/tournament?_number=${
        number - 1
      }&_size=${size}`,
    }),
  getUsers: (id) =>
    axios({
      method: "GET",
      url: `${environment.motivarnosBackend}/metricconf/userreport?metricConf.id=${id}&user.roles.role=3`,
    }),
  getMetrics: () =>
    axios({
      method: "GET",
      url: `${environment.motivarnosBackend}/metricconf?isActive=true`,
    }),
  createTournament: (body) =>
    axios({
      method: "POST",
      url: `${environment.motivarnosBackend}/tournament`,
      data: body,
    }),
  editTournament: (body) =>
    axios({
      method: "PUT",
      url: `${environment.motivarnosBackend}/tournament`,
      data: body,
    }),
  deleteTournament: (id) =>
    axios({
      method: "DELETE",
      url: `${environment.motivarnosBackend}/tournament/${id}`,
    }),
};
