import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Slide from "@material-ui/core/Slide";
import Dialog from "@material-ui/core/Dialog";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Typography from "@material-ui/core/Typography";
import { SpinnerCircular } from "spinners-react";
import TournamentServices from "../../../Services/TournamentService";
import Button from "../../../../../Button";
import { AppWrapper } from "../../../common/GlobalStyles";
import { format } from "date-fns";
import TextField from "@material-ui/core/TextField";
import { connect } from "react-redux";

import { updateTournamentRequest } from "../../../../../../actions/tournamentsActions";

import toastr from "toastr";

import { useTranslation } from "react-i18next";

import "./styles.scss";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const useStyles = makeStyles((theme) => ({
  appBarSpacer: theme.mixins.toolbar,
  appBar: {
    position: "relative",
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
    color: "black",
  },
}));

const EditTorunament = (props) => {
  const [fetching, setFetching] = useState(false);
  const classes = useStyles();
  const [users, setUsers] = useState(null);
  const [playersGames, setPlayerGames] = useState([]);
  const [data, setData] = useState(null);
  const [nextDates, setNextDates] = useState([]);
  const [forceUpdate, setForceUpdate] = useState("");
  const { t } = useTranslation();

  const handleDate = (i, e) => {
    let aux = nextDates;
    aux[i] = e.target.value;
    setNextDates(aux);
    setForceUpdate("" + Math.random());
  };

  const getUsers = (value) => {
    let aux = [];
    let auxDates = [];

    for (let i = 0; i < props.data.matches.length; i++) {
      aux.push(props.data.matches[i].player1);
      aux.push(props.data.matches[i].player2);

      auxDates.push(
        format(new Date(props.data.matches[i].date1), "yyyy-MM-dd")
      );
    }
    setNextDates(auxDates);
    setPlayerGames(aux);
    if (!users) {
      TournamentServices.getUsers(value)
        .then((resp) => {
          //console.log("USERS", resp.data.content);
          setUsers(resp.data.content);
        })
        .catch(() => {});

      //console.log(props.data);
      setData(props.data);
    }
  };

  const handlePlayers = (index, value) => {
    let aux = playersGames.slice(0);
    if (value === "null") aux[index] = value;
    else aux[index] = { id: value };
    setPlayerGames(aux);
  };

  const isSelected = (id) => {
    if (id === "null") return false;
    for (let i = 0; i < playersGames.length; i++) {
      if (!playersGames[i]) return false;
      if (playersGames[i] === "null") continue;
      else {
        if (playersGames[i].id + "" === id + "") return "disabled";
      }
    }
    return false;
  };

  function replaceAll(string, search, replace) {
    if (!string) return "";
    return string.split(search).join(replace);
  }

  useEffect(() => {
    if (props.data) getUsers(props.data.metricConf.id);
    // eslint-disable-next-line
  }, [props.open]);

  const editTournament = async () => {
    setFetching(true);
    let body;
    let matches = [];
    let j = 0;
    for (let i = 0; i < data.matches.length; i++) {
      matches.push({
        id: data.matches[i].id,
        player1: playersGames[j] ? { id: playersGames[j++].id } : null,
        player2: playersGames[j] ? { id: playersGames[j++].id } : null,
        date1: nextDates[i] + "T00:00:00",
      });
    }

    body = {
      id: data.id,
      matches,
    };

    let status;

    await props.updateTournamentRequest(body).then((r) => (status = r));

    if (status === "ERROR") {
      setFetching(false);

      toastr.error(t("admin.mission-message-error", "An error has occurred"));
    } else {
      setFetching(false);
      setData(null);
      setUsers(null);
      props.onClose();
    }
  };

  return (
    <Dialog
      open={props.open}
      fullScreen
      TransitionComponent={Transition}
      className="new-mission-wrapper"
    >
      <AppWrapper>
        <AppBar className={classes.appBar}>
          <Toolbar>
            <IconButton
              edge="start"
              onClick={() => {
                if (!fetching) props.onClose();
                setData(null);
                setUsers(null);
              }}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography variant="h6" className={classes.title}>
              {t("tournaments.label", "Tournaments")}
            </Typography>
          </Toolbar>
        </AppBar>

        <div style={{ padding: "1em" }}>
          {data ? (
            <React.Fragment>
              <h3>{data.name}</h3>
              {data.matches.map((data, index) => {
                let regex = /T(.)*/;
                let date = replaceAll(data.date1, "-", "/").replace(regex, "");
                let flag = new Date() > new Date(date);
                return (
                  <span key={index}>
                    <span className="date-wrapper">
                      <TextField
                        InputProps={{
                          inputProps: {
                            min: format(new Date(), "yyyy-MM-dd"),
                          },
                        }}
                        onChange={(e) => {
                          handleDate(index, e);
                        }}
                        value={nextDates[index]}
                        defaultValue={format(new Date(), "yyyy-MM-dd")}
                        type="date"
                        name="date"
                        id="date"
                        label={t("date.label", "Date")}
                        variant="outlined"
                        disabled={flag}
                      />
                    </span>

                    {playersGames[index * 2 + 1 - 1] != null &&
                    playersGames[index * 2 + 2 - 1] != null ? (
                      <>
                        {users ? (
                          <div>
                            <span>
                              {t("admin.user-select-player", "Select player")}{" "}
                              {index * 2 + 1}
                            </span>
                            <span
                              style={{
                                height: "0",
                                opacity: "0",
                                display: "flex",
                              }}
                            >
                              {forceUpdate}
                            </span>
                            <select
                              disabled={flag ? "disabled" : ""}
                              className="select-css"
                              onChange={(e) => {
                                handlePlayers(index * 2, e.target.value);
                              }}
                              value={
                                playersGames[index * 2 + 1 - 1]
                                  ? playersGames[index * 2 + 1 - 1] !== "null"
                                    ? playersGames[index * 2 + 1 - 1].id
                                    : "null"
                                  : "null"
                              }
                            >
                              {/*<option value={'null'}>----</option>*/}
                              {users.map((data) => {
                                return (
                                  <option
                                    key={data.userId}
                                    value={data.userId}
                                    disabled={isSelected(data.userId)}
                                  >
                                    {data.user.username}
                                  </option>
                                );
                              })}
                            </select>
                          </div>
                        ) : (
                          <span className="spinner-container">
                            <SpinnerCircular />
                          </span>
                        )}

                        {users ? (
                          <div>
                            <span>
                              {t("admin.user-select-player", "Select player")}{" "}
                              {index * 2 + 2}
                            </span>
                            <select
                              disabled={flag ? "disabled" : ""}
                              className="select-css"
                              onChange={(e) => {
                                handlePlayers(index * 2 + 1, e.target.value);
                              }}
                              value={
                                playersGames[index * 2 + 2 - 1]
                                  ? playersGames[index * 2 + 2 - 1] !== "null"
                                    ? playersGames[index * 2 + 2 - 1].id
                                    : "null"
                                  : "null"
                              }
                            >
                              {/*<option value={'null'}>----</option>*/}
                              {users.map((data) => {
                                return (
                                  <option
                                    key={data.userId}
                                    value={data.userId}
                                    disabled={isSelected(data.userId)}
                                  >
                                    {data.user.username}
                                  </option>
                                );
                              })}
                            </select>
                          </div>
                        ) : (
                          <span className="spinner-container">
                            <SpinnerCircular />
                          </span>
                        )}
                      </>
                    ) : (
                      <div className="ml-1">
                        {t(
                          "menu.trivia-panel-dialog-add-test-select--without-players",
                          "Without Players"
                        )}
                      </div>
                    )}
                  </span>
                );
              })}
            </React.Fragment>
          ) : (
            <span className="spinner-container">
              <SpinnerCircular />
            </span>
          )}
          <Button onClick={editTournament} disabled={fetching}>
            {t("admin.actions-edit", "Edit")}
          </Button>
        </div>
      </AppWrapper>
    </Dialog>
  );
};

EditTorunament.defaultProps = {
  onClose: () => {},
  data: null,
  onUpdate: () => {},
};

const mapDispatchToProps = {
  updateTournamentRequest,
};

export default connect(null, mapDispatchToProps)(EditTorunament);
