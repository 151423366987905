import { format } from "date-fns";

export default {
  validateEmail(valor) {
    if (
      /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i.test(
        valor
      )
    ) {
      return true;
    } else {
      return false;
    }
  },

  deleteDuplicates(arr, prop) {
    var newArray = [];
    var lookup = {};

    for (var i in arr) {
      lookup[arr[i][prop]] = arr[i];
    }

    for (i in lookup) {
      newArray.push(lookup[i]);
    }

    return newArray;
  },

  addZero(i) {
    if (i < 10) {
      i = "0" + i;
    }
    return i;
  },

  formatDate(date) {
    let hours = this.addZero(date.getHours());
    let minutes = this.addZero(date.getMinutes());
    let seconds = this.addZero(date.getSeconds());
    return `${hours}:${minutes}:${seconds}`;
  },

  formatNewDate(date, lang) {
    if (date) {
      return format(new Date(date), "dd/MMM/yyyy", {
        locale: lang,
      });
    } else {
      return "N/A";
    }
  },

  compareDateWithActualDate(date) {
    var d1 = new Date();
    var d2 = new Date(date);

    if (d2 < d1) {
      return false;
    }

    return true;
  },
};
