import axios from "axios";
import environment from "../../../../libs/environment";

export default {
  setMission: (body) =>
    axios({
      method: "POST",
      url: `${environment.motivarnosBackend}/mission`,
      data: body,
    }),
  
  getMission: (id) =>
    axios({
      method: "GET",
      url: `${environment.motivarnosBackend}/mission/${id}`,
    }),

  getUsers: () =>
    axios({
      method: "GET",
      url: `${environment.motivarnosBackend}/user`,
    }),

  getMissions: (number = 1, size = 5) =>
    axios({
      method: "GET",
      url: `${environment.motivarnosBackend}/mission/compact?_number=${
        number - 1
      }&_size=${size}`,
    }),

  putMission: (body) =>
    axios({
      method: "PUT",
      url: `${environment.motivarnosBackend}/mission`,
      data: body,
    }),
  
  getUsersMissions: (id, size=10, number=0, userFilter = "", status) =>{

      if (userFilter) {
        userFilter = `%${userFilter}%`
      } else {
        userFilter = null;
      }
  
      status = (status && status!='TODO')? status: null;
      
      return axios.get("/missionuser",{params: 
        {
          "mission.id": id,
          _size: size,
          _number: number,
          _sort: "priority_asc",
          "user.textSearch_like": userFilter,
          status: status
        }
      });
    },
  
  resetMission: (body) => axios.put("/missionuser/reset", body),
  
  missionAchieve: (body) =>
    axios({
      method: "PUT",
      url: `${environment.motivarnosBackend}/missionuser/achieve`,
      data: body,
    }),

  missionUnachieve: (body) =>
    axios({
      method: "PUT",
      url: `${environment.motivarnosBackend}/missionuser/unachieve`,
      data: body,
    }),

  deleteMission: (id) =>
    axios({
      method: "DELETE",
      url: `${environment.motivarnosBackend}/mission/${id}`,
    }),

  getMissionForSelect: () => axios.get("/mission/compact")
};
