import {
  Button,
  Container,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Slider,
  TextField,
} from "@material-ui/core";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { buttonStyles } from "../../../../../styles/styles";
import {
  changedLowPointRange,
  postObjective,
  postMetricType,
  changedMediumPointRange,
  changedHighPointRange,
} from "../store/MetricsReducer";
import { useTranslation } from "react-i18next";

const NumberObjective = (props) => {
  const dispatch = useDispatch();
  const buttonClasses = buttonStyles();
  const { t } = useTranslation();

  const [lowLow, setLowLow] = useState(0);
  const [highLow, setHighLow] = useState();

  const [lowMedium, setLowMedium] = useState();
  const [highMedium, setHighMedium] = useState();

  const [lowHigh, setLowHigh] = useState();
  const [highHigh, setHighHigh] = useState();

  const [objective, setObjective] = useState(0);

  const [pointsLow, setPointsLow] = useState(0);
  const [pointsMedium, setPointsMedium] = useState(0);
  const [pointsHigh, setPointsHigh] = useState(0);

  const [colorLow, setColorLow] = useState("#e75a4e");
  const [colorMedium, setColorMedium] = useState("#e6e74e");
  const [colorHigh, setColorHigh] = useState("#5ee74e");

  const dispatchRanges = () => {
    if (
      objective &&
      lowLow &&
      highLow &&
      colorLow &&
      lowMedium &&
      highMedium &&
      colorMedium &&
      lowHigh &&
      highHigh &&
      colorHigh
    ) {
      dispatch(
        postObjective({
          objective: objective,
        })
      );
      dispatch(
        postMetricType({
          metricType: "decimal",
        })
      );
      dispatch(
        changedLowPointRange({
          from: lowLow,
          to: highLow,
          pointsRange: pointsLow,
          color: colorLow,
        })
      );
      dispatch(
        changedMediumPointRange({
          from: lowMedium,
          to: highMedium,
          pointsRange: pointsMedium,
          color: colorMedium,
        })
      );
      dispatch(
        changedHighPointRange({
          from: lowHigh,
          to: highHigh,
          pointsRange: pointsHigh,
          color: colorHigh,
        })
      );
    }
  };

  return (
    <>
      <Container style={{ display: "flex", flexDirection: "column" }}>
        <Container style={{ display: "flex", flexDirection: "row" }}>
          <h4>{`Objective`}</h4>
          <TextField
            type="number"
            onChange={(e) => {
              setObjective(e.target.value);
            }}
            value={objective}
            style={{ marginLeft: "1%", width: "12%" }}
          ></TextField>
        </Container>

        <br></br>
        <Container
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
          }}
        >
          <Container
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            <FormControl style={{ width: "12%", marginRight: "2%" }}>
              <InputLabel id="demo-simple-select-label">Color</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={colorLow}
                onChange={(e) => {
                  setColorLow(e.target.value);
                }}
              >
                <MenuItem value={"#e75a4e"}>Red</MenuItem>
                <MenuItem value={"#e6e74e"}>Yellow</MenuItem>
                <MenuItem value={"#5ee74e"}>Green</MenuItem>
              </Select>
            </FormControl>
            <TextField
              style={{ width: "10%", marginRight: "1%" }}
              value={lowLow}
              onChange={(e) => {
                if (e.target.valueAsNumber >= 0) {
                  setLowLow(e.target.valueAsNumber);
                  setHighLow(e.target.valueAsNumber);
                  setLowMedium(e.target.valueAsNumber);
                }
              }}
              type="number"
            ></TextField>
            <Slider
              value={100}
              unselectable={true}
              style={{ width: "20%", color: colorLow, marginRight: "1%" }}
            ></Slider>
            <TextField
              style={{ width: "10%", marginRight: "1%" }}
              value={highLow}
              onChange={(e) => {
                if (e.target.valueAsNumber >= 0) {
                  setHighLow(e.target.valueAsNumber);
                  setLowMedium(e.target.valueAsNumber);
                }
              }}
              type="number"
            ></TextField>
            <Container style={{ display: "flex", flexDirection: "row" }}>
              <h4>{`Points`}</h4>
              <TextField
                style={{ width: "8%", marginLeft: "1%" }}
                value={pointsLow}
                onChange={(e) => setPointsLow(e.target.valueAsNumber)}
                type="number"
              ></TextField>
            </Container>
          </Container>
          <Container
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            <FormControl style={{ width: "12%", marginRight: "2%" }}>
              <InputLabel id="demo-simple-select-label">Color</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={colorMedium}
                onChange={(e) => {
                  setColorMedium(e.target.value);
                }}
              >
                <MenuItem value={"#e75a4e"}>Red</MenuItem>
                <MenuItem value={"#e6e74e"}>Yellow</MenuItem>
                <MenuItem value={"#5ee74e"}>Green</MenuItem>
              </Select>
            </FormControl>
            <TextField
              style={{ width: "10%", marginRight: "1%" }}
              value={lowMedium}
              onChange={(e) => {
                if (e.target.valueAsNumber >= 0) {
                  setLowMedium(e.target.valueAsNumber);
                  setHighMedium(e.target.valueAsNumber);
                  setLowHigh(e.target.valueAsNumber);
                }
              }}
              type="number"
            ></TextField>
            <Slider
              value={100}
              unselectable={true}
              style={{ width: "20%", color: colorMedium, marginRight: "1%" }}
            ></Slider>
            <TextField
              style={{ width: "10%", marginRight: "1%" }}
              value={highMedium}
              onChange={(e) => {
                if (e.target.valueAsNumber >= 0) {
                  setHighMedium(e.target.valueAsNumber);
                  setLowHigh(e.target.valueAsNumber);
                }
              }}
              type="number"
            ></TextField>
            <Container style={{ display: "flex", flexDirection: "row" }}>
              <h4>{`Points`}</h4>
              <TextField
                style={{ width: "8%", marginLeft: "1%" }}
                value={pointsMedium}
                onChange={(e) => setPointsMedium(e.target.valueAsNumber)}
                type="number"
              ></TextField>
            </Container>
          </Container>
          <Container
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            <FormControl style={{ width: "12%", marginRight: "2%" }}>
              <InputLabel id="demo-simple-select-label">Color</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={colorHigh}
                onChange={(e) => {
                  setColorHigh(e.target.value);
                }}
              >
                <MenuItem value={"#e75a4e"}>Red</MenuItem>
                <MenuItem value={"#e6e74e"}>Yellow</MenuItem>
                <MenuItem value={"#5ee74e"}>Green</MenuItem>
              </Select>
            </FormControl>
            <TextField
              style={{ width: "10%", marginRight: "1%" }}
              value={lowHigh}
              onChange={(e) => {
                if (e.target.valueAsNumber >= 0) {
                  setLowHigh(e.target.valueAsNumber);
                  setHighHigh(e.target.valueAsNumber);
                }
              }}
              type="number"
            ></TextField>
            <Slider
              value={100}
              unselectable={true}
              style={{ width: "20%", color: colorHigh, marginRight: "1%" }}
            ></Slider>
            <TextField
              style={{ width: "10%", marginRight: "1%" }}
              value={highHigh}
              onChange={(e) => {
                if (e.target.valueAsNumber >= 0) {
                  setHighHigh(e.target.valueAsNumber);
                }
              }}
              type="number"
            ></TextField>
            <Container style={{ display: "flex", flexDirection: "row" }}>
              <h4>{`Points`}</h4>
              <TextField
                style={{ width: "8%", marginLeft: "1%" }}
                value={pointsHigh}
                onChange={(e) => setPointsHigh(e.target.valueAsNumber)}
                type="number"
              ></TextField>
            </Container>
          </Container>
          <Button onClick={dispatchRanges} className={buttonClasses.buttonPink}>
            {t("confirm.label", "Confirm")}
          </Button>
        </Container>
      </Container>
    </>
  );
};

export default NumberObjective;
