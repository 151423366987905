import React, { useState } from "react";
import { format } from "date-fns";
import { makeStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import Slide from "@material-ui/core/Slide";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import InputLabel from "@material-ui/core/InputLabel";
import toastr from "toastr";
import Button from "@material-ui/core/Button";
import Tooltip from "@material-ui/core/Tooltip";
import { useTranslation } from "react-i18next";
import { SpinnerCircular } from "spinners-react";
import { connect } from "react-redux";

import {
  saveLetterSoupRequest,
  updateLetterSoupRequest,
} from "../../../../../actions/letterSoupActions";

/*********Icons********* */
import AddIcon from "@material-ui/icons/Add";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import EditIcon from "@material-ui/icons/Edit";
/********Services********* */
import environment from "../../../../../libs/environment";
import FileService from "../../Services/FileService";
import { AppWrapper } from "../GlobalStyles";
/**********Hooks********** */
import { useFormatProps } from "../../hooks/useFormatProps";

/*********Dialog********* */
import ImageDialog from "../../common/Dialogs/ImageDialog";
import FindRegistersDialog from "../../common/Dialogs/FindRegistersDialog";

import WordList from "../../components/LetterSoup/WordList";

import wordsearch from '../../../../../Pages/LetterSoup/WordSearchFuntions';


const useStyles = makeStyles((theme) => ({
  appBar: {
    position: "relative",
  },
  formControl: {
    width: "100%",
    marginTop: "1rem",
  },
  container: {
    paddingTop: theme.spacing(4),
  },
  title: {
    marginLeft: theme.spacing(2),
    color: "black",
    flex: 1,
  },
  root: {
    "& > *": {
      margin: theme.spacing(1),
    },
  },
  input: {
    display: "none",
  },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function LetterSoupDialog(props) {
  /***************Styles************ */
  const classes = useStyles();
  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    getContentAnchorEl: () => null,
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };
  /***************Styles************ */

  /***********General functions and constants******** */

  const [open, setOpen] = React.useState(false);
  const [loading, setLoading] = useState(false);
  const [nameError, setNameError] = useState(false);
  const [descriptionError, setDescriptionError] = useState(false);
  const [qtyWordsError, setqtyWordsError] = useState(false);
  const [timeInSecError, settimeInSecError] = useState(false);
  const [lettersoupTypeError, setLettersoupTypeError] = useState(false);
  const [teamsError, setTeamsError] = useState(false);
  const [usersError, setUsersError] = useState(false);
  const [isActiveError, setIsActiveError] = useState(false);
  const [isSortedError, setisSortedError] = useState(false);
  const [pointsForFirstError, setPointsForFirstError] = useState(false);
  const [pointsForCompleteError, setPointsForCompleteError] = useState(false);
  const [dateFromError, setDateFromError] = useState(false);
  const [dateToError, setDateToError] = useState(false);
  const [imageObject, setImageObject] = useState(null);
  const [imageObjectFromDialog, setImageObjectFromDialog] = useState(true);

  const [wordsNotAdded, setWordsNotAdded] = useState([]);
  const [statewordsFinded, setStatewordsFinded] = useState([]);

  const { t } = useTranslation();

  const [teamsHooks, rowsHooks, secondaryTeamsHooks] = useFormatProps(
    props.rowTeams ? props.rowTeams : [],
    props.rowsUsers ? props.rowsUsers : [],
    props.rowSecondaryTeams ? props.rowSecondaryTeams : []
  );

  function formatPropsEdit(array) {
    let arrayFormatted = [];
    array.forEach((element) => {
      arrayFormatted.push(element.id);
    });
    return arrayFormatted;
  }
  const [
    {
      name,
      description,
      lettersoupType,
      timeInSec,
      qtyWords,
      words,
      pointsForFoundWords,
      teams,
      secondaryTeams,
      users,
      teamsProps,
      rowsUsers,
      rowSecondaryTeams,
      hideOnDate,
      dateFrom,
      dateTo,
      isActive,
      image,
    },
    setState,
  ] = useState({
    name: props.name ? props.name : "",
    description: props.description ? props.description : "",
    lettersoupType: props.timeInSec ? "USE_TIME" : "SIMPLE",
    timeInSec: props.timeInSec ? props.timeInSec : 0,
    qtyWords: props.qtyWords ? props.qtyWords : "",
    words: props.words ? props.words.split(", ") : [] ,
    pointsForFoundWords: props.pointsForFoundWords ? props.pointsForFoundWords : 20,
    teams: formatPropsEdit(props.teams ? props.teams : []),
    secondaryTeams: formatPropsEdit(
      props.secondaryTeams ? props.secondaryTeams : []
    ),

    users: formatPropsEdit(props.users ? props.users : []),
    teamsProps: teamsHooks && teamsHooks.length > 0 ? teamsHooks : [],
    rowsUsers: rowsHooks && rowsHooks.length > 0 ? rowsHooks : [],
    rowSecondaryTeams:
      secondaryTeamsHooks && secondaryTeamsHooks.length > 0
        ? secondaryTeamsHooks
        : [],

    hideOnDate: props.hideOnDate
      ? format(new Date(props.hideOnDate), "yyyy-MM-dd")
      : format(new Date(), "yyyy-MM-dd"),
    dateFrom: props.dateFrom
      ? format(new Date(props.dateFrom), "yyyy-MM-dd")
      : format(new Date(), "yyyy-MM-dd"),
    dateTo: props.dateTo
      ? format(new Date(props.dateTo), "yyyy-MM-dd")
      : format(new Date(), "yyyy-MM-dd"),
    isActive: props.isActive ? props.isActive : false,
    image: props.image ? props.image : props.firstImage,
  });

  const handleClickOpen = () => {
    setOriginalState();
    setOpen(true);
  };

  function handleChange({ target: { name, value } }) {
    setState((prevState) => ({ ...prevState, [name]: value }));
    if (name === "lettersoupType") {
      setState((prevState) => ({
        ...prevState,
        timeInSec: props.timeInSec ? props.timeInSec : 60,
      }));
    }
   
  }

  /***********************New functions********************* */
    // Inicio Funciones desde Editor
    const handleWordAdded = async (word) => {
      //const wordsaux = (statewords.length) ? statewords.slice() : [];
      const wordsaux = (words.length) ? words.slice() : [];
      if (word.trim().length > 1 && word.trim().length <= 10 && wordsaux.indexOf(word) === -1) {
        wordsaux.push(word.toLowerCase().replace(/\s/g, ''));
        const finded = (statewordsFinded.length) ? statewordsFinded.slice() : [];
        finded.push(false);
        setState((prevState) => ({ ...prevState, words: wordsaux }));
       // setStatewords(wordsaux);
        setStatewordsFinded(finded);
      }
    };

    const handleWordRemoved = (word) => {
      //const wordsaux = (statewords.length) ? statewords.slice() : [];
      const wordsaux = (words.length) ? words.slice() : [];
      if (wordsaux.indexOf(word) !== -1) {
        let indix = wordsaux.indexOf(word);
        wordsaux.splice(indix, 1);
        //const finded = (statewords.length) ? statewords.slice() : [];
        const finded = (words.length) ? words.slice() : [];
        finded.splice(indix, 1);
        //setStatewords(wordsaux);
        setState((prevState) => ({ ...prevState, words: wordsaux }));
        setStatewordsFinded(finded);
      }
    };

  /***********************New functions********************* */

  function handleChangeImage(e) {
    if (e.target.files && e.target.files[0]) {
      setState((prevState) => ({ ...prevState, image: e.target.files[0] }));
      setImageObject(e.target.files[0]);
      setImageObjectFromDialog(false);
      readURL(e);
    }
  }

  function handleSimular() {
    let aux = simular() ; 
    if(aux.length) {
      setWordsNotAdded(aux);
    } 
     console.log("aux palabras no ubicadas");
     console.log(aux);
  }

  function readURL(e) {
    if (e.target.files && e.target.files[0]) {
      var reader = new FileReader();

      reader.onload = function (e) {
        document
          .getElementById("img-designed")
          .setAttribute("src", e.target.result);
      };
      reader.readAsDataURL(e.target.files[0]);
    }
  }

  function setImageDialog(e) {
    setImageObjectFromDialog(e);
    setState((prevState) => ({ ...prevState, image: e }));
  }

  const handleClose = () => {
    setOriginalState();
  };

  function setOriginalState() {
    setState((prevState) => ({
      ...prevState,
      name: props.name ? props.name : "",
      description: props.description ? props.description : "",
      timeInSec: props.timeInSec ? props.timeInSec : 0,
      words: props.words ? props.words.split(", ") : [],
      hideOnDate: props.hideOnDate
        ? format(new Date(props.hideOnDate), "yyyy-MM-dd")
        : format(new Date(), "yyyy-MM-dd"),
      pointsForFoundWords: props.pointsForFoundWords ? props.pointsForFoundWords : 20,
      teams: formatPropsEdit(props.teams ? props.teams : []),
      secondaryTeams: formatPropsEdit(
        props.secondaryTeams ? props.secondaryTeams : []
      ),
      users: formatPropsEdit(props.users ? props.users : []),
      dateFrom: props.dateFrom
        ? format(new Date(props.dateFrom), "yyyy-MM-dd")
        : format(new Date(), "yyyy-MM-dd"),
      dateTo: props.created
        ? format(new Date(props.created), "yyyy-MM-dd")
        : format(new Date(), "yyyy-MM-dd"),
      isActive: props.isActive ? props.isActive : false,
      image: props.image ? props.image : "",
    }));
    setOpen(false);
  }
  function simular(){
    let ws = (wordsearch(words, 10, 10, { backwards: 0.25 }));
    return ws.unplaced;
  }

  
  function setSecondaryTeams(secondaryTeams) {
    setState((prevState) => ({ ...prevState, 'secondaryTeams': secondaryTeams }));
  }

  function setTeams(teams) {
    setState((prevState) => ({ ...prevState, 'teams': teams }));
  }

  function setUsers(users) {
    setState((prevState) => ({ ...prevState, 'users': users }));
  }

  function setName(option) {
    return (option.name == '' || option.name  == null) ? t(
      "admin.user-panel-user-dialog-input-select-without-name",
      "Without name") : option.name;
  }
  
  /***********General functions and constants******** */

  /***************************CRUD functions*************** */
  async function onFormSubmit() {
    setNameError(false);
    setDescriptionError(false);
    setqtyWordsError(false);
    settimeInSecError(false);
    setLettersoupTypeError(false);
    setTeamsError(false);
    setUsersError(false);
    setIsActiveError(false);
    setisSortedError(false);
    setPointsForFirstError(false);
    setPointsForCompleteError(false);
    setDateFromError(false);
    setDateToError(false);

    if (name === "") {
      setNameError(true);
      toastr.error(
        t(
          "admin.lettersoup-panel-dialog-add-message-error-name",
          "The name is required"
        )
      );
      return;
    }

    if (description === "") {
      setDescriptionError(true);
      toastr.error(
        t(
          "admin.lettersoup-panel-dialog-add-message-error-description",
          "Description is required"
        )
      );
      return;
    }

    if (words === []) {
      setqtyWordsError(true);
      toastr.error(
        t(
          "admin.lettersoup-panel-dialog-add-message-error-words",
          "You must enter some words"
        )
      );
      return;
    }

    if (timeInSec === "") {
        settimeInSecError(true);
        toastr.error(
          t(
            "admin.lettersoup-panel-dialog-add-message-error-time",
            "You must enter a time"
          )
        );
        return;
      }

    if (pointsForFoundWords === "") {
      setPointsForFirstError(true);
      toastr.error(
        t(
          "admin.lettersoup-panel-dialog-add-message-error-number-points",
          "You must enter a number of points for found words"
        )
      );
      return;
    }

    if (
      (teams === "" || teams.length === 0 || !teams) &&
      (users === "" || users.length === 0 || !users)
    ) {
      setTeamsError(true);
      setUsersError(true);

      toastr.error(
        t(
          "admin.lettersoup-panel-dialog-add-message-error-add-group",
          "You must add groups or players"
        )
      );
      return;
    }

    if (dateFrom === "") {
      setDateFromError(true);
      toastr.error(
        t(
          "admin.lettersoup-panel-dialog-add-message-error-start-date",
          "You must enter a start date"
        )
      );
      return;
    }

    if (dateTo === "") {
      setDateToError(true);
      toastr.error(
        t(
          "admin.lettersoup-panel-dialog-add-message-error-end-date",
          "You must enter an end date"
        )
      );
      return;
    }

    if (isActive === "") {
      setIsActiveError(true);
      toastr.error(
        t(
          "admin.lettersoup-panel-dialog-add-message-error-active",
          "You must choose if it is active or not"
        )
      );
      return;
    }

    /***********Format variables****** */
    let teamsFormatted = [];
    teams.forEach((element) => {
      teamsFormatted.push({
        id: element,
      });
    });

    let usersFormatted = [];
    users.forEach((element) => {
      usersFormatted.push({
        id: element,
      });
    });

    let secondaryTeamsFormatted = [];
    secondaryTeams.forEach((element) => {
      secondaryTeamsFormatted.push({
        id: element,
      });
    });

    /***********Format variables****** */
    let urlImage =
      image && image !== "" && !imageObjectFromDialog
        ? !imageObjectFromDialog
          ? ""
          : imageObjectFromDialog
        : image;

        if (image && image !== "") {
          if (imageObject && !imageObjectFromDialog) {
            const formData = new FormData();
            formData.append("file", imageObject);
            await FileService.uploadFile(formData, "trivia")
              .then((response) => {
                urlImage = response.data.url;
                toastr.success(
                  t(
                    "menu.trivia-panel-dialog-add-test-message-success-add-image",
                    "Image added successfully"
                  )
                );
              })
          .catch(() => {
            toastr.error(
              t(
                "admin.lettersoup-panel-dialog-add-message-error-add-image",
                "An error occurred while trying to add the image"
              )
            );
          });
      }
    }

    let json =
    {
      "name": name,
      "description": description,
      "isHasHideOnDate": true,
      "hideOnDate": hideOnDate + "T00:00:00",
      "teams": teamsFormatted,
      "users": usersFormatted,
      "secondaryTeams": secondaryTeamsFormatted,
      "image": urlImage,
      "timeInSec": timeInSec,
      "dimension": 10,
      "words": words.join(', '),
      "pointsForFoundWords": pointsForFoundWords,
      "isActive": isActive,
      "dateFrom": dateFrom + "T00:00:00",
      "expirationDate": dateTo + "T00:00:00"
  }
 
    setLoading(true);
    let status;

    if (props.typeModal === "modalEdit") {

      await props
        .updateLetterSoupRequest({ ...json, "id": props.id })
        .then((r) => (status = r));
    } else {
      await props.saveLetterSoupRequest(json).then((r) => (status = r));
     
    
    }

    if (status === "ERROR") {
      if (
        status.error &&
        status.error.response.data.message === "invalid.duplicatedname"
      ) {
        
        toastr.error(
          t(
            "admin.lettersoup-panel-dialog-add-message-error-add-one",
            "There is already a lettersoup with this name"
          )
        );
      } else if(status.error !== "undefined") {
        toastr.error(
          t(
            "admin.lettersoup-panel-dialog-add-message-error-add-two",
            "An error occurred while trying to save the lettersoup"
          )
        );
      }else {
        toastr.success(
          t(
            "admin.lettersoup-panel-dialog-add-message-success-add-lettersoup",
            "Letter Soup saved successfully"
          )
        );
        setLoading(false);
        handleClose();
      }
      setLoading(false);
    } else {
      toastr.success(
        t(
          "admin.lettersoup-panel-dialog-add-message-success-add-lettersoup",
          "Letter lettersoup saved successfully"
        )
      );
      setLoading(false);
      handleClose();
    }
  }
  return (
    <>
      {props.typeModal === "modalEdit" ? (
        <Tooltip title={t("admin.actions-edit", "Edit")}>
          <EditIcon
            onClick={handleClickOpen}
            fontSize="small"
            className="cursor-pointer"
          />
        </Tooltip>
      ) : (
        <Button
          className="button-table mr-1"
          variant="contained"
          color="secondary"
          onClick={handleClickOpen}
        >
          <AddIcon />
          {t("admin.lettersoup-panel-button-lettersoup-name", "LetterSoup")}
        </Button>
      )}

      <Dialog
        fullScreen
        open={open}
        onClose={handleClose}
        TransitionComponent={Transition}
        className="dialog-div-custom"
      >
        <AppWrapper>
          <AppBar className={classes.appBar}>
            <Toolbar>
              <IconButton edge="start" onClick={handleClose} aria-label="close">
                <CloseIcon />
              </IconButton>
              <Typography variant="h6" className={classes.title}>
                {t(
                  "admin.lettersoup-panel-dialog-add-title",
                  "LetterSoup management"
                )}
              </Typography>
            </Toolbar>
          </AppBar>

          {loading && (
            <div className="div-spinner-modal">
              <Container maxWidth="lg" className={classes.container}>
                {" "}
                <Grid container spacing={1}>
                  <SpinnerCircular size={90} />
                </Grid>
              </Container>
            </div>
          )}

          {!loading && (
            <Container maxWidth="lg" className={classes.container}>
              <Grid container spacing={1}>
                <Grid item xs={12} md={6} lg={6}>
                  <TextField
                    onChange={handleChange}
                    error={nameError}
                    value={name}
                    name="name"
                    id="name"
                    label={t(
                      "admin.lettersoup-panel-dialog-add-input-name",
                      "Letter soup name"
                    )}
                    variant="outlined"
                    className="mt-2"
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} md={6} lg={6}>
                  <TextField
                    onChange={handleChange}
                    error={descriptionError}
                    value={description}
                    name="description"
                    id="description"
                    label={t(
                      "admin.lettersoup-panel-dialog-add-input-description",
                      "Description of the letter lettersoup"
                    )}
                    variant="outlined"
                    className="mt-2"
                    fullWidth
                  />
                </Grid>
              </Grid>
              <Grid container spacing={1}>
                <Grid item xs={12} md={6} lg={6}>
                  <FormControl
                    variant="outlined"
                    className={classes.formControl}
                  >
                  <WordList
                    title={t("admin.words", "Words")}
                    list_words={words }
                    emptyMessage={t("admin.there-no-word", "There are no words yet, add some!")} //"No hay palabras aún&mdash; agregue alguna!"
                    onWordAdded={handleWordAdded}
                    onWordRemoved={handleWordRemoved}
                    editable
                    isFinded={statewordsFinded}
                    placeholder={t("admin.lettersoup-there-no-word", "Add word and press enter")}
                  />
                  </FormControl>
                </Grid>

                <Grid item xs={12} md={6} lg={6}>
                  <FormControl
                    variant="outlined"
                    className={classes.formControl}
                  >
                    <InputLabel id="LettersoupType-select-outlined-label">
                      {t(
                        "menu.Lettersoup-panel-dialog-add-test-select-Lettersoup-type",
                        "Letter soup type"
                      )}
                    </InputLabel>
                    <Select
                      onChange={handleChange}
                      error={lettersoupTypeError}
                      value={lettersoupType}
                      labelId="lettersoupType"
                      id="lettersoupType"
                      name="lettersoupType"
                      label="Tipo de letter soup"
                    >
                      <MenuItem value={"SIMPLE"}>
                        {t(
                          "menu.lettersoup-panel-dialog-add-test-select-lettersoup-type-simple",
                          "Simple"
                        )}{" "}
                      </MenuItem>
                      <MenuItem value={"USE_TIME"}>
                        {t(
                          "menu.lettersoup-panel-dialog-add-test-select-lettersoup-type-of-time",
                          "Of time"
                        )}{" "}
                      </MenuItem>
                    </Select>
                  </FormControl>
                </Grid>

                {/** simular */}
              {/*<Grid
                  item
                  xs={12}
                  md={6}
                  lg={6}
                  className="padding-custom-button"
                >
                  <Grid container spacing={1}>
                    <Grid item xs={3} md={3} lg={3}>
                      <div className="h-100">
                        <input
                          accept="image/*"
                          className={classes.input}
                          id="contained-button-file"
                          type="file"
                          onChange={(e) => handleSimular(e)}
                        />
                        <label htmlFor="contained-button-file">
                        <Button
                          className="button-table ml-1 mt-2"
                          variant="contained"
                          color="primary"
                          onClick={() => handleSimular()}
                        >
                          { " " +
                                t(
                                  "admin.letterSoup-dialog-add-simulate",
                                  "Simulate"
                                )
                            }
                          </Button>
                        </label>
                      </div>
                    </Grid>
                    <Grid
                      item
                      xs={8}
                      md={8}
                      lg={8}
                      className="div-img-designed"
                    >
                      {image && image !== "" && imageObjectFromDialog && (
                       <WordList
                         title={t("admin.words", "Words")}
                         list_words={wordsNotAdded }
                         emptyMessage={t("admin.there-no-word", "There are no words yet, add some!")} //"No hay palabras aún&mdash; agregue alguna!"
                         onWordAdded={handleWordAdded}
                         onWordRemoved={handleWordRemoved}
                         editable={false}
                         isFinded={statewordsFinded}
                         placeholder={t("admin.lettersoup-there-no-word", "Add word and press enter")}
                        />
                      )}
                    </Grid>
                  </Grid>
                </Grid>*/}
                {/** End simular */}

                {lettersoupType === "USE_TIME" && (
                  <Grid item xs={12} md={6} lg={6}>
                    <TextField
                      onChange={handleChange}
                      error={timeInSecError}
                      value={timeInSec}
                      name="timeInSec"
                      id="timeInSec"
                      type="number"
                      label={t(
                        "menu.letterSoup-panel-dialog-add-input-timeInSec",
                        "Letter letterSoup time in seconds"
                      )}
                      variant="outlined"
                      className="mt-2"
                      fullWidth
                    />
                  </Grid>
                )}
               

                <Grid item xs={12} md={6} lg={6}>
                <TextField
                      onChange={handleChange}
                      error={pointsForFirstError}
                      value={pointsForFoundWords}
                      name="pointsForFoundWords"
                      id="pointsForFoundWords"
                      type="number"
                      label={t(
                        "menu.letterSoup-panel-dialog-add-select-pointsForFoundWords",
                        "Points for found word"
                      )}
                      variant="outlined"
                      className="mt-2"
                      fullWidth
                    />
                </Grid>

                <Grid
                  item
                  xs={12}
                  md={6}
                  lg={6}
                  className="padding-custom-button"
                >
                  <Grid container spacing={1}>
                    <Grid item xs={5} md={5} lg={5}>
                      <div className="h-100">
                        <input
                          accept="image/*"
                          className={classes.input}
                          id="contained-button-file"
                          type="file"
                          onChange={(e) => handleChangeImage(e)}
                        />
                        <label htmlFor="contained-button-file">
                          <Button
                            variant="contained"
                            color="primary"
                            component="span"
                            className="button-table h-100 w-100"
                          >
                            {image === ""
                              ? " " +
                                t(
                                  "menu.letterSoup-panel-dialog-add-input-image-upload",
                                  "Upload image"
                                )
                              : t(
                                  "menu.letterSoup-panel-dialog-add-input-image-change",
                                  "Change image"
                                )}
                          </Button>
                        </label>
                      </div>
                    </Grid>

                    <Grid item xs={5} md={5} lg={5}>
                      <ImageDialog setImageDialog={(e) => setImageDialog(e)} />
                    </Grid>
                    <Grid
                      item
                      xs={2}
                      md={2}
                      lg={2}
                      className="div-img-designed"
                    >
                      {image && image !== "" && !imageObjectFromDialog && (
                        <img
                          id="img-designed"
                          className="img-predesigned"
                          src=""
                          alt=""
                        />
                      )}
                      {image && image !== "" && imageObjectFromDialog && (
                        <img
                          id="img-designed"
                          className="img-predesigned"
                          src={`${environment.motivarnosBackend}${image}`}
                          alt=""
                        />
                      )}
                    </Grid>
                  </Grid>
                </Grid>

                {rowSecondaryTeams && rowSecondaryTeams.length > 0 && (
                  <FindRegistersDialog
                    setValue={setSecondaryTeams}
                    setName={setName}
                    label={t(
                      "admin.header-dropdown-dialog-secondary-teams-input",
                      "Secondary Teams"
                    )}
                    rowsSelected={secondaryTeams}
                    rows={rowSecondaryTeams}
                  />
                )}

                {teamsProps && teamsProps.length > 0 && (
                  <FindRegistersDialog
                    setValue={setTeams}
                    setName={setName}
                    label={t(
                      "admin.user-panel-user-dialog-input-groups",
                      "Groups"
                    )}
                    rowsSelected={teams}
                    rows={teamsProps}
                  />
                )}

                {rowsUsers && rowsUsers.length > 0 && (
                  <FindRegistersDialog
                    setValue={setUsers}
                    label={t(
                      "menu.trivia-panel-dialog-add-test-select-players",
                      "Players"
                    )}
                    rowsSelected={users}
                    rows={rowsUsers}
                  />
                )}

                <Grid item xs={12} md={6} lg={6}>
                  <TextField
                    onChange={handleChange}
                    error={dateFromError}
                    value={dateFrom}
                    type="date"
                    name="dateFrom"
                    id="dateFrom"
                    label={t(
                      "menu.letterSoup-panel-dialog-add-input-dateFrom",
                      "Start date"
                    )}
                    variant="outlined"
                    className="mt-2"
                    fullWidth
                  />
                </Grid>

                <Grid item xs={12} md={6} lg={6}>
                  <TextField
                    onChange={handleChange}
                    error={dateToError}
                    value={dateTo}
                    type="date"
                    name="dateTo"
                    id="dateTo"
                    label={t(
                      "menu.letterSoup-panel-dialog-add-input-dateTo",
                      "Ending date"
                    )}
                    variant="outlined"
                    className="mt-2"
                    fullWidth
                  />
                </Grid>

                <Grid item xs={12} md={6} lg={6}>
                  <TextField
                    onChange={handleChange}
                    value={hideOnDate}
                    type="date"
                    name="hideOnDate"
                    id="hideOnDate"
                    label={t("hide-date.label", "Hide date")}
                    variant="outlined"
                    className="mt-2"
                    fullWidth
                  />
                </Grid>

                <Grid item xs={12} md={6} lg={6}>
                  <FormControl
                    variant="outlined"
                    className={classes.formControl}
                  >
                    <InputLabel id="isActive-select-outlined-label">
                      {t(
                        "admin.header-dropdown-view-conditions-table-state",
                        "State"
                      )}{" "}
                    </InputLabel>
                    <Select
                      onChange={handleChange}
                      error={isActiveError}
                      value={isActive}
                      labelId="isActive"
                      id="isActive"
                      name="isActive"
                      label={t(
                        "admin.header-dropdown-view-conditions-table-state",
                        "State"
                      )}
                    >
                      <MenuItem value={true}>
                        {t(
                          "admin.header-dropdown-view-conditions-table-active-state",
                          "Active"
                        )}{" "}
                      </MenuItem>
                      <MenuItem value={false}>
                        {t(
                          "admin.header-dropdown-view-conditions-table-blocked-state",
                          "Blocked"
                        )}
                      </MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>

              <Button
                className="button-table mt-2 mr-1"
                onClick={handleClose}
                variant="contained"
                color="secondary"
              >
                {t("menu.letterSoup-panel-dialog-add-button-close", "Close")}
              </Button>

              <Button
                className="button-table mt-2"
                variant="contained"
                color="primary"
                onClick={() => onFormSubmit()}
              >
                {t(
                  "menu.letterSoup-panel-dialog-add-button-generate-letterSoup",
                  "Generate letterSoup"
                )}
              </Button>
            </Container>
          )}
        </AppWrapper>
      </Dialog>
    </>
  );
}

const mapDispatchToProps = {
  saveLetterSoupRequest,
  updateLetterSoupRequest,
};

export default connect(null, mapDispatchToProps)(LetterSoupDialog);
