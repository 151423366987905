import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Container, Slide } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';
import pxToRem from '../../../../utils/pxToRem';
import DownloadTemplateButton from "../../../common/CSVImporter/DownloadTemplate"
import CopyFromDrive from "../../../common/CSVImporter/CopyFromDrive"

const DownloadTemplate = (props) => {
    const className = useStyles();
    return( 
            <Container style={{display:"flex", flexDirection:"column"}}>
                <h4 className={className.body} style={{textAlign:"center"}}>
                    <FormattedMessage
                        id="thankyou.body1"
                        defaultMessage="To start adding or modifying the groups and the users, you'll have to download the template from here"
                    />
                </h4>
                <DownloadTemplateButton style={{alignSelf:"center"}}></DownloadTemplateButton>
                <h4 className={className.body} style={{textAlign:"center"}}>
                    <FormattedMessage 
                        id="thankyou.body1"
                        defaultMessage="Or you can copy the template to your google drive from here"
                    />
                </h4>
                <CopyFromDrive style={{alignSelf:"center"}}></CopyFromDrive>
            </Container>    
    )
}

const useStyles = makeStyles((theme) => ({
    motivational:{
        color:"black",
        letterSpacing: 6,
        fontSize: pxToRem(20),
        fontWeight:"light",
        fontStyle:"bold"
    },
    body: {
        color: "black",
        letterSpacing: 6,
        lineHeight: 1.1,
        fontSize: pxToRem(30),
        marginBottom: pxToRem(20),
        fontWeight: "bold"
    }
}));
export default DownloadTemplate;