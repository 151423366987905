import UserModel from "../../../../../models/UserModel";
import User from "../api/User";
const validAction = { create: true, update: true, delete: true };

const orderByGroup = (listData = []) => {
  const mapByGroup = {};
  listData.forEach((valact) => {
    if (mapByGroup[valact.Team]) {
      mapByGroup[valact.Team].push(valact);
    } else {
      mapByGroup[valact.Team] = [valact];
    }
  });
  var orderedByGroup = [];
  Object.entries(mapByGroup).forEach((valact) => {
    orderedByGroup.push(valact[1]);
  });

  return orderedByGroup;
};

const createPayloadForAction = (listData = []) => {
  var calls = {
    create: { listausuarios: [] },
    update: { listausuarios: [] },
    delete: { listausuarios: [] },
    errors: [],
  };

  listData.forEach((val) => {
    console.log(val);
    val.company = listData.company;
    var user = new UserModel();
    user.setModelFromCSV(val);

    if (!validAction[val.Action.toLowerCase()]) {
      calls.errors.push({
        ...val,
        error: "Action not allowed",
      });
    } else {
      var payload = calls[val.Action.toLowerCase()];
      var errorPlayer = user.ValidateCSV();

      console.log(errorPlayer);
      if (errorPlayer.importation.length > 0) {
        calls.errors.push({ ...errorPlayer });
      } else {
        if (!payload["gruponombre"]) payload["gruponombre"] = val.Team;

        payload["listausuarios"].push(user.model);
      }
    }
  });
  return calls;
};
const importUsersCSV = async (data, token) => {
  console.log(data);
  var errorList = [];
  var usersImported = [];
  try {
    var response = await User(token).createUser({
      gruponombre: data.gruponombre,
      listausuarios: data.listausuarios,
    });
    response.respuestas.forEach((respuesta) => {
      var user = data.listausuarios.find(
        (usuario) => usuario.username === respuesta.valor1
      );
      if (respuesta.valor2 !== "Ok") {
        errorList.push({ ...user, importation: respuesta.valor2 });
      } else {
        usersImported.push({ ...user, importation: respuesta.valor2 });
      }
    });
  } catch (e) {
    data.listausuarios.forEach((data) => {
      errorList.push({
        ...data,
        importation: e.message,
      });
    });
  }

  return {
    usersImported: usersImported,
    errorList: errorList,
  };
};
const modifyUsersFromCSV = async (data, token) => {
  var errorList = [];
  var usersImported = [];
  try {
    var response = await User(token).modifyUser({
      gruponombre: data.gruponombre,
      listausuarios: data.listausuarios,
    });
    response.respuestas.forEach((respuesta) => {
      var user = data.listausuarios.find(
        (usuario) => usuario.username === respuesta.valor1
      );
      if (respuesta.valor2 !== "UPDATE_FINISHED") {
        errorList.push({ ...user, importation: respuesta.valor2 });
      } else {
        usersImported.push({ ...user, importation: respuesta.valor2 });
      }
    });
  } catch (e) {
    data.listausuarios.forEach((data) => {
      errorList.push({
        ...data,
        importation: e.message,
      });
    });
  }
  return {
    usersImported: usersImported,
    errorList: errorList,
  };
};
const deleteUsersFromCSV = async (data, token) => {
  let errorList = [];
  let usersImported = [];
  try {
    var usersToDelete = [];
    data.listausuarios.forEach((data) => {
      usersToDelete.push(data.username);
    });
    var response = await User(token).deleteUser(usersToDelete);
    response.respuestas.forEach((respuesta) => {
      var user = data.listausuarios.find(
        (usuario) => usuario.username === respuesta.valor1
      );
      if (respuesta.valor2 !== "DELETE OK") {
        errorList.push({ ...user, importation: respuesta.valor2 });
      } else {
        usersImported.push({ ...user, importation: respuesta.valor2 });
      }
    });
  } catch (e) {
    data.listausuarios.forEach((data) => {
      errorList.push({
        ...data,
        importation: e.message,
      });
    });
  }
  return {
    usersImported: usersImported,
    errorList: errorList,
  };
};
const importCsv = async (data, empresa, token) => {
  console.log(data);
  const listByGroup = orderByGroup(data);
  var errorList = [];
  var usersImported = [];

  for (const val of listByGroup) {
    val.company = empresa;
    console.log(val);
    var calls = createPayloadForAction(val);

    if (calls.errors) errorList = errorList.concat(calls.errors);

    if (calls.create.listausuarios && calls.create.listausuarios.length > 0) {
      var response = await importUsersCSV(calls.create, token);
      errorList = errorList.concat(response.errorList);
      usersImported = usersImported.concat(response.usersImported);
      console.log(errorList, usersImported);
    }

    if (calls.update.listausuarios && calls.update.listausuarios.length > 0) {
      response = await modifyUsersFromCSV(calls.update, token);
      errorList = errorList.concat(response.errorList);
      usersImported = usersImported.concat(response.usersImported);
    }

    if (calls.delete.listausuarios && calls.delete.listausuarios.length > 0) {
      response = await deleteUsersFromCSV(calls.delete, token);
      errorList = errorList.concat(response.errorList);
      usersImported = usersImported.concat(response.usersImported);
    }
  }
  console.log(usersImported);
  console.log(errorList);
  return {
    usersImported: usersImported,
    errors: errorList,
  };
};

const generateBodyForCSV = (entries) => {
  var body = [];
  console.log(entries);
  entries.forEach((data) => {
    var member = {};
    if (data.username) {
      member["User"] = data.username;
      member["Role"] = data.rol;
      member["Team"] = data.gruponombre;
      member["Name"] = data.nombre;
      member["Last Name"] = data.apellido;
      member["Email"] = data.email;
      member["Importation"] = data.importation;
      body.push(member);
    }
  });
  return body;
};

export default importCsv;
export { importCsv, generateBodyForCSV };
