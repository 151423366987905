import * as badgesTypes from "../types/badgesTypes";

import BadgeService from "../components/app/admin/Services/BadgeService";

const {
  RESET_STATE,
  BADGES_LIST_REQUEST,
  BADGES_LIST_SAVED,
  BADGES_LIST_SAVE,
  BADGES_LIST_UPDATE,
  BADGES_LIST_DELETE,
  BADGES_LIST_CHARGING,
  BADGES_SAVE_CHARGING,
  BADGES_LIST_ERROR,
} = badgesTypes;

export const getBadgesRequest = (payload) => async (dispatch, getState) => {
  try {
    const { pages } = getState().badgesReducer;
    if (!pages.includes(payload)) {
      dispatch({
        type: BADGES_LIST_CHARGING,
      });
      const responseLogin = await BadgeService.getBadges(payload);
      dispatch({
        type: BADGES_LIST_REQUEST,
        payload: { ...responseLogin.data, number: payload },
      });
    } else {
      dispatch({
        type: BADGES_LIST_SAVED,
      });
    }
  } catch (error) {
    dispatch({
      type: BADGES_LIST_ERROR,
      payload: error.response ? error.response.data : error,
    });
  }
};

export const saveBadgeRequest = (payload) => async (dispatch, getState) => {
  dispatch({
    type: BADGES_SAVE_CHARGING,
  });

  try {
    const responseLogin = await BadgeService.saveBadge(payload);
    const { badges } = getState().badgesReducer;
    const badgesUpdated = [responseLogin.data, ...badges];

    dispatch({
      type: BADGES_LIST_SAVE,
      payload: badgesUpdated,
    });
    return { status: "SUCCESS" };
  } catch (error) {
    dispatch({
      type: BADGES_LIST_ERROR,
      payload: error.response ? error.response.data : error,
    });
    return { status: "ERROR", error: error.response };
  }
};

export const updateBadgeRequest = (payload) => async (dispatch, getState) => {
  dispatch({
    type: BADGES_SAVE_CHARGING,
  });

  try {
    await BadgeService.updateBadge(payload);
    const { badges } = getState().badgesReducer;

    const badgesUpdated = [...badges];
    const findById = (badge) => badge.id === payload.id;
    const index = badgesUpdated.findIndex(findById);
    badgesUpdated[index] = {
      ...badgesUpdated[index],
      ...payload,
    };

    dispatch({
      type: BADGES_LIST_UPDATE,
      payload: badgesUpdated,
    });
    return { status: "SUCCESS" };
  } catch (error) {
    dispatch({
      type: BADGES_LIST_ERROR,
      payload: error.response ? error.response.data : error,
    });
    return { status: "ERROR", error: error.response };
  }
};

export const deleteBadgeRequest = (payload) => async (dispatch) => {
  dispatch({
    type: BADGES_LIST_CHARGING,
  });

  try {
    await BadgeService.deleteBadge(payload);

    dispatch({
      type: BADGES_LIST_DELETE,
      payload: payload,
    });
    return "SUCCESS";
  } catch (error) {
    dispatch({
      type: BADGES_LIST_ERROR,
      payload: error.response ? error.response.data : error,
    });
    return { error: error.response };
  }
};

export const resetState = () => async (dispatch) => {
  dispatch({
    type: RESET_STATE,
  });
};
