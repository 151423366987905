export const RESET_STATE = "RESET_STATE";
export const RESET_STORE = "RESET_STORE";

export const BADGES_LIST_REQUEST = "BADGES_LIST_REQUEST";
export const BADGES_LIST_SAVED = "BADGES_LIST_SAVED";

export const BADGES_LIST_SAVE = "BADGES_LIST_SAVE";
export const BADGES_LIST_UPDATE = "BADGES_LIST_UPDATE";
export const BADGES_LIST_DELETE = "BADGES_LIST_DELETE";

export const BADGES_LIST_CHARGING = "BADGES_LIST_CHARGING";
export const BADGES_SAVE_CHARGING = "BADGES_SAVE_CHARGING";

export const BADGES_LIST_ERROR = "BADGES_LIST_ERROR";
