import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { mediaBreakpoints } from "../../../../assets/publicTheme";
import Container from "@material-ui/core/Container/Container";
import Typography from "@material-ui/core/Typography";
import pxToRem from "../../utils/pxToRem";
import { useTranslation } from "react-i18next";

const DevelopedBy = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  return (
    <div className={classes.columnLeft}>
      <Typography className={classes.developByMessage} variant="h6">
        {t("developed-by.message", "Developed with ")}
        &hearts; 
        {t("by-gamifica.message", " by Gamifica")}
      </Typography>
    </div>
  );
};

export default DevelopedBy;

const useStyles = makeStyles((theme) => ({
  columnLeft: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    [mediaBreakpoints.mobile]: { width: "100%" },
    [mediaBreakpoints.desktop]: { width: "70%", paddingLeft: pxToRem(47) },
  },
  developByMessage: {
    color: "white",
  },
}));
