import axios from "axios";
import environment from "../../../libs/environment";

export default {
  findAll: (query) =>
    axios({
      method: "GET",
      url: `${environment.motivarnosBackend}/trivia/own`,
    }),
  findById: (data) =>
    axios({
      method: "GET",
      url: `${environment.motivarnosBackend}/trivia/${data.id}`,
    }),
  Apply: (data) =>
    axios({
      method: "POST",
      url: `${environment.motivarnosBackend}/trivia/apply`,
      data: data,
    }),
  getScore: (id) =>
    axios({
      method: "GET",
      url: `${environment.motivarnosBackend}/triviauserscore/` + id,
    }),
  getUser: (id) =>
    axios({
      method: "GET",
      url: `${environment.motivarnosBackend}/user/${id}`,
    }),
  expireTrivia: (id) => axios.post('triviauserscore/expire', {id: id})
};
