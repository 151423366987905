import * as tournamentsTypes from "../types/tournamentsTypes";

const {
  RESET_STATE,
  RESET_STORE,
  TOURNAMENTS_LIST_REQUEST,
  TOURNAMENTS_LIST_SAVED,
  TOURNAMENTS_LIST_SAVE,
  TOURNAMENTS_LIST_UPDATE,
  TOURNAMENTS_LIST_DELETE,
  TOURNAMENTS_LIST_CHARGING,
  TOURNAMENTS_SAVE_CHARGING,
  TOURNAMENTS_LIST_ERROR,
} = tournamentsTypes;

const INITIAL_STATE = {
  tournaments: [],
  error_tournaments: false,
  tournaments_charging: false,
  tournaments_save_charging: false,
  totalElements: 0,
  totalPages: 1,
  pages: [],
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case TOURNAMENTS_LIST_REQUEST:
      return {
        ...state,
        tournaments_charging: false,
        tournaments: [...state.pages].includes(action.payload.number)
          ? [...state.tournaments]
          : [...state.tournaments, ...action.payload.content],
        totalElements: action.payload.totalElements,
        totalPages: action.payload.totalPages,
        pages: [...state.pages].includes(action.payload.number)
          ? [...state.pages]
          : [...state.pages, action.payload.number],
        error_tournaments: false,
      };

    case TOURNAMENTS_LIST_SAVE:
      return {
        ...state,
        tournaments: action.payload,
        error_tournaments: false,
        tournaments_save_charging: false,
      };
    case TOURNAMENTS_LIST_UPDATE:
      return {
        ...state,
        tournaments: action.payload,
        error_tournaments: false,
        tournaments_save_charging: false,
      };
    case TOURNAMENTS_LIST_DELETE:
      return {
        ...state,
        tournaments: [...state.tournaments].filter(
          (tournament) => tournament.id !== action.payload
        ),
        tournaments_charging: false,
        totalElements: state.totalElements - 1,
        error_tournaments: false,
      };

    case TOURNAMENTS_LIST_CHARGING:
      return { ...state, tournaments_charging: true, error_tournaments: false };

    case TOURNAMENTS_SAVE_CHARGING:
      return {
        ...state,
        tournaments_save_charging: true,
        error_tournaments: false,
      };

    case TOURNAMENTS_LIST_ERROR:
      return {
        ...state,
        error_tournaments: action.payload,
        tournaments_charging: false,
        tournaments_save_charging: false,
      };

    case TOURNAMENTS_LIST_SAVED:
      return {
        ...state,
        tournaments_charging: false,
        error_tournaments: false,
      };

    case RESET_STATE:
      return {
        ...state,
        error_tournaments: false,
        tournaments_charging: false,
        tournaments_save_charging: false,
      };

    case RESET_STORE:
      return {
        ...state,
        tournaments: [],
        error_tournaments: false,
        tournaments_charging: false,
        tournaments_save_charging: false,
        totalElements: 0,
        totalPages: 1,
        pages: [],
      };

    default:
      return state;
  }
};
